import { formatNumber } from '../general';
import { MAX_END_WEIGHT_IN_LB, MAX_START_WEIGHT_IN_LB, MIN_END_WEIGHT_IN_LB, MIN_START_WEIGHT_IN_LB } from './rations/stages';

const ERRORS = {
	CREATE_CATTLE_YARD: {
		DEFAULT: 'An error occurred while adding the Yard',
	},
	UPDATE_CATTLE_YARD: {
		DEFAULT: 'An error occurred while updating the Yard',
	},
	DELETE_CATTLE_YARD: {
		DEFAULT: 'An error occurred while deleting the Yard',
	},
	CREATE_CATTLE_RATION: {
		DEFAULT: 'An error occurred while adding the Ration',
	},
	UPDATE_CATTLE_RATION: {
		DEFAULT: 'An error occurred while updating the Ration',
	},
	DELETE_CATTLE_RATION: {
		DEFAULT: 'An error occurred while deleting the Ration',
	},
	CREATE_CATTLE_LOT: {
		DEFAULT: 'An error occurred while adding the Lot',
	},
	UPDATE_CATTLE_LOT: {
		DEFAULT: 'An error occurred while updating the Lot',
	},
	DELETE_CATTLE_LOT: {
		DEFAULT: 'An error occurred while deleting the Lot',
	},
	CREATE_CATTLE_FEED_RATION_STAGE: {
		MISSING_STAGE_NAME: 'Stage name is required',
		INVALID_START_WEIGHT: 'Start weight must be a valid number',
		MISSING_START_WEIGHT: 'Start weight is required',
		START_WEIGHT_TOO_SMALL: `Start weight must be greater than or equal to ${formatNumber(MIN_START_WEIGHT_IN_LB)}`,
		START_WEIGHT_TOO_LARGE: `Start weight must be less than or equal to ${formatNumber(MAX_START_WEIGHT_IN_LB)}`,
		INVALID_END_WEIGHT: 'End weight must be a valid number',
		MISSING_END_WEIGHT: 'End weight is required',
		END_WEIGHT_TOO_SMALL: `End weight must be greater than or equal to ${formatNumber(MIN_END_WEIGHT_IN_LB)}`,
		END_WEIGHT_TOO_LARGE: `End weight must be less than or equal to ${formatNumber(MAX_END_WEIGHT_IN_LB)}`,
		END_WEIGHT_NOT_GT_START_WEIGHT: 'End weight must be greater than start weight',
		DEFAULT: 'An error occurred while adding the Feed Ration Stage',
	},
	UPDATE_CATTLE_FEED_RATION_STAGE: {
		MISSING_STAGE_NAME: 'Stage name is required',
		INVALID_START_WEIGHT: 'Start weight must be a valid number',
		MISSING_START_WEIGHT: 'Start weight is required',
		START_WEIGHT_TOO_SMALL: `Start weight must be greater than or equal to ${formatNumber(MIN_START_WEIGHT_IN_LB)}`,
		START_WEIGHT_TOO_LARGE: `Start weight must be less than or equal to ${formatNumber(MAX_START_WEIGHT_IN_LB)}`,
		INVALID_END_WEIGHT: 'End weight must be a valid number',
		MISSING_END_WEIGHT: 'End weight is required',
		END_WEIGHT_TOO_SMALL: `End weight must be greater than or equal to ${formatNumber(MIN_END_WEIGHT_IN_LB)}`,
		END_WEIGHT_TOO_LARGE: `End weight must be less than or equal to ${formatNumber(MAX_END_WEIGHT_IN_LB)}`,
		END_WEIGHT_NOT_GT_START_WEIGHT: 'End weight must be greater than start weight',
		DEFAULT: 'An error occurred while updating the Feed Ration Stage',
	},
	DELETE_CATTLE_FEED_RATION_STAGE: {
		DEFAULT: 'An error occurred while deleting the Feed Ration Stage',
	},
	CREATE_CATTLE_FEED_RATION_STAGE_INGREDIENT: {
		MISSING_INGREDIENT_ID: 'Ingredient is required',
		INVALID_USAGE: 'Usage must be greater than or equal to 0',
		DEFAULT: 'An error occurred while adding the Stage Ingredient',
	},
	UPDATE_CATTLE_FEED_RATION_STAGE_INGREDIENT: {
		INVALID_USAGE: 'Usage must be greater than or equal to 0',
		DEFAULT: 'An error occurred while updating the Stage Ingredient',
	},
	DELETE_CATTLE_FEED_RATION_STAGE_INGREDIENT: {
		DEFAULT: 'An error occurred while deleting the Stage Ingredient',
	},
} as const;

export { ERRORS };
