import { assert } from '@ember/debug';
import { task } from 'ember-concurrency';
import BaseDeleteModalComponent from 'vault-client/components/base-delete-modal';
import { CattleLot } from 'vault-client/types/graphql-types';
import { ERRORS } from 'vault-client/utils/cattle/errors';
import { useDeleteCattleLotMutation } from 'vault-client/utils/cattle/lots/delete-cattle-lot';
import { service } from '@ember/service';
import type SuccessHandler from 'vault-client/services/success-handler';

export interface CattleLotDeleteCattleLotModalSignature {
	// The arguments accepted by the component
	Args: {
		itemToDelete: Pick<CattleLot, 'id' | 'name'>;
		closeConfirmation: () => void;
	};
	// Any blocks yielded by the component
	Blocks: {
		default: [];
	};
	// The element to which `...attributes` is applied in the component template
	Element: null;
}

export default class CattleLotsDeleteCattleLotModalComponent extends BaseDeleteModalComponent<
	CattleLotDeleteCattleLotModalSignature['Args']
> {
	@service declare successHandler: SuccessHandler;

	deleteItem = task({ drop: true }, async () => {
		const id = this.args.itemToDelete?.id;

		assert('No id provided to Delete Cattle Lot Component', id);

		await useDeleteCattleLotMutation(this, {
			onError: (error) => {
				console.error('error', error.message);
				this.errorMessage = ERRORS.DELETE_CATTLE_LOT.DEFAULT;
			},
			onComplete: async () => {
				await this.successHandler.showSuccess('Lot deleted successfully');
				this.args.closeConfirmation();
			},
		}).mutate({ id });
	});

	get lot() {
		return this.args.itemToDelete as Pick<CattleLot, 'id' | 'name'>;
	}

	get titleText() {
		return 'Delete Lot';
	}

	get bodyText() {
		return 'Are you sure you want to delete this lot?';
	}

	get cancelButtonText() {
		return 'Cancel';
	}

	get deleteButtonText() {
		return 'Delete';
	}

	get label(): string {
		return this.lot.name;
	}
}
