import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield this.processedModel}}", {"contents":"{{yield this.processedModel}}","moduleName":"vault-client/components/model-processor.hbs","parseOptions":{"srcName":"vault-client/components/model-processor.hbs"}});
import { destroy, registerDestructor } from '@ember/destroyable';
import { getOwner } from '@ember/owner';
import Component from '@glimmer/component';
import type { Owner } from 'vault-client';

export type ModelProcessorTransform<TModel, TReturn = unknown> = (context: { owner: Owner; model: TModel }) => TReturn;
export interface ModelProcessorSignature<TModel, TReturn = TModel> {
	// The arguments accepted by the component
	Args: {
		model: TModel;
		transform?: ModelProcessorTransform<TModel, TReturn>;
	};
	// Any blocks yielded by the component
	Blocks: {
		default: [TReturn | TModel];
	};
	// The element to which `...attributes` is applied in the component template
	Element: never;
}

export default class ModelProcessorComponent<TModel, TReturn = unknown> extends Component<ModelProcessorSignature<TModel, TReturn>> {
	// Owner is guaranteed to be set on a component. This should be fixed when we switch to native types.
	// TODO: Remove bang when we switch to native types.
	owner = getOwner(this)!;
	_processedModel: TReturn | TModel | null = null;

	constructor(owner: Owner, args: ModelProcessorSignature<TModel, TReturn>['Args']) {
		super(owner, args);

		registerDestructor(this, () => {
			this.cleanupProcessedModel();
		});
	}

	get processedModel() {
		return this.processModel();
	}

	processModel() {
		this.cleanupProcessedModel();
		if (!this.args.transform) {
			this._processedModel = this.args.model;
		} else {
			this._processedModel = this.args.transform({ owner: this.owner, model: this.args.model });
		}

		return this._processedModel;
	}

	cleanupProcessedModel() {
		if (!this._processedModel) {
			return;
		}

		destroy(this._processedModel);
	}
}
