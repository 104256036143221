type ExposureProduct = {
	id?: string;
	name?: string;
	slug: string;
	type: string[];
	code?: string | null;
	endorsementFilterField?: string | null;
};

const InsuranceProducts: ExposureProduct[] = [
	{
		name: 'Lean Hogs',
		slug: 'livestock-lean-hogs',
		type: ['lgm', 'lrp'],
		code: '0815',
		endorsementFilterField: null,
	},
	{ name: 'Feeder Cattle', slug: 'livestock-feeder-cattle', type: ['lgm', 'lrp'], code: '0801', endorsementFilterField: null },
	{ name: 'Live Cattle', slug: 'livestock-live-cattle', type: ['lgm', 'lrp'], code: '0802', endorsementFilterField: null },
	{ name: 'Class III', slug: 'us-dairy-class-iii', type: ['drp', 'lgm'], code: null, endorsementFilterField: 'classIiiGrossProtection' },
	{ name: 'Class IV', slug: 'us-dairy-class-iv', type: ['drp'], code: null, endorsementFilterField: 'classIvGrossProtection' },
	{ name: 'Cheese', slug: 'us-dairy-cheese', type: ['drp'], code: null, endorsementFilterField: 'cheeseGrossProtection' },
	{ name: 'Butter', slug: 'us-dairy-butter', type: ['drp'], code: null, endorsementFilterField: 'butterGrossProtection' },
];

export { InsuranceProducts, ExposureProduct };
