import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { LedgerRevenueCategoryCreateDTO, Mutation_createLedgerRevenueCategoryArgs, type Maybe } from 'vault-client/types/graphql-types';
import { gql, useMutation } from 'glimmer-apollo';
import { ModelFrom } from 'vault-client/utils/type-utils';
import BusinessesBusinessBusinessSettingsRoute from 'vault-client/routes/businesses/business/business-settings';
import BusinessesBusinessRevenuesRoute from 'vault-client/routes/businesses/business/revenues';
import { getLedgerCalculationType, getSelectableUnits, getSupportedBusinessRole, type DynamicUnit } from 'vault-client/utils/operations';

interface AddLedgerRevenueCategoryButtonArgs {
	businessId: string;
	model: ModelFrom<BusinessesBusinessBusinessSettingsRoute> | ModelFrom<BusinessesBusinessRevenuesRoute>['getRevenues'];
}

const CREATE_LEDGER_REVENUE_CATEGORY = gql`
	mutation createLedgerRevenueCategory($data: LedgerRevenueCategoryCreateDTO!) {
		createLedgerRevenueCategory(data: $data) {
			id
			name
		}
	}
`;

type CreateLedgerRevenueCategoryMutation = {
	__typename?: 'Mutation';

	createLedgerRevenueCategory?: {
		data: LedgerRevenueCategoryCreateDTO;
	} | null;
};

export default class AddLedgerRevenueCategoryButton extends Component<AddLedgerRevenueCategoryButtonArgs> {
	@tracked showModal = false;
	@tracked categoryName: string = '';

	@tracked dynamicUnit: Maybe<DynamicUnit> = null;

	createLedgerRevenueCategory = useMutation<CreateLedgerRevenueCategoryMutation, Mutation_createLedgerRevenueCategoryArgs>(this, () => [
		CREATE_LEDGER_REVENUE_CATEGORY,
		{
			/* options */
			update: (cache) => {
				cache.evict({ fieldName: 'AggregateLedgerEntries' });
				cache.evict({ fieldName: 'LedgerEntry' });
				cache.evict({ fieldName: 'LedgerEntries' });
				cache.evict({ fieldName: 'LedgerEntryCount' });

				cache.evict({ fieldName: 'LedgerCategory' });
				cache.evict({ fieldName: 'LedgerCategories' });
				cache.evict({ fieldName: 'LedgerCategoryCount' });

				cache.evict({ fieldName: 'LedgerRevenueCategory' });
				cache.evict({ fieldName: 'LedgerRevenueCategories' });
				cache.evict({ fieldName: 'LedgerRevenueCategoryCount' });
				cache.gc();
			},
		},
	]);

	get businessRole() {
		return getSupportedBusinessRole(this.args.model.data?.Customer?.businessRoles ?? []);
	}

	get selectableUnits() {
		return getSelectableUnits(this.businessRole);
	}

	@action
	openModal() {
		this.showModal = true;
	}

	@action
	closeModal() {
		this.showModal = false;
		this.categoryName = '';
		this.dynamicUnit = null;
	}

	get hasValidInput() {
		return this.categoryName.trim();
	}

	@action
	async submit() {
		await this.createLedgerRevenueCategory.mutate({
			data: {
				name: this.categoryName,
				customerId: this.args.businessId,
				//If left null, all entries are assumed to be "static" values.
				calculationType: this.ledgerType,
			},
		});

		await this.args.model.refetch();

		this.closeModal();
	}

	get ledgerType() {
		return getLedgerCalculationType(this.businessRole, this.dynamicUnit);
	}
}
