import Service from '@ember/service';
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';

interface LotRow {
	lotName: string;
	yard: string;
	startWeight: number;
	startDate: string;
	targetWeight: number;
	targetDate: string;
	daysOnFeed: number;
	ration: string;
	revenue: number;
	expenses: number;
	netPL: number;
}

interface YardRow {
	yardName: string;
	yardDetails: string;
}

interface Ingredient {
	id: string;
	name: string;
}

interface RationIngredient {
	name: string;
	lbs_per_day_head: number;
}

interface Stage {
	name: string;
	start_weight: number;
	end_weight: number;
	ration_ingredients: RationIngredient[];
}

export interface Ration {
	id: string;
	name: string;
	notes: string;
	stages: Stage[];
}

interface PaginatedResponse<T> {
	data: T[];
	meta: {
		total: number;
		page: number;
		size: number;
		startIndex: number;
		endIndex: number;
	};
}

const YARDS_STORAGE_KEY = 'vault-client-yards';
const RATIONS_STORAGE_KEY = 'vault-client-rations';
const LOTS_STORAGE_KEY = 'vault-client-lots';

export default class CattleMockService extends Service {
	private mockLots: LotRow[] = [
		{
			lotName: 'Lot Name',
			yard: 'Yard Name',
			startWeight: 200,
			startDate: '05/23/2024',
			targetWeight: 175.0,
			targetDate: '05/23/2024',
			daysOnFeed: 2015,
			ration: 'Ration Name',
			revenue: 26400.0,
			expenses: -26400.0,
			netPL: 26400.0,
		},
		{
			lotName: 'Lot Name',
			yard: 'Yard Name',
			startWeight: 200,
			startDate: '05/23/2024',
			targetWeight: 175.0,
			targetDate: '05/23/2024',
			daysOnFeed: 2015,
			ration: 'Ration Name',
			revenue: 26400.0,
			expenses: -26400.0,
			netPL: 26400.0,
		},
	];

	private mockRations: Ration[] = [];

	private mockIngredients: Ingredient[] = [
		{ id: '1', name: 'Corn' },
		{ id: '2', name: 'Hay' },
		{ id: '3', name: 'Silage' },
		{ id: '4', name: 'Grain' },
		{ id: '5', name: 'Protein Supplement' },
	];

	private getStoredYards(): YardRow[] {
		const storedYards = localStorage.getItem(YARDS_STORAGE_KEY);
		return storedYards ? JSON.parse(storedYards) : [];
	}

	private setStoredYards(yards: YardRow[]): void {
		localStorage.setItem(YARDS_STORAGE_KEY, JSON.stringify(yards));
	}

	private getStoredLots(): LotRow[] {
		const storedLots = localStorage.getItem(LOTS_STORAGE_KEY);
		return storedLots ? JSON.parse(storedLots) : this.mockLots;
	}

	private setStoredLots(lots: LotRow[]): void {
		localStorage.setItem(LOTS_STORAGE_KEY, JSON.stringify(lots));
	}

	getMockLots() {
		return this.getStoredLots();
	}

	getMockYards() {
		return this.getStoredYards();
	}

	addYard(yard: YardRow) {
		const yards = this.getStoredYards();
		yards.push(yard);
		this.setStoredYards(yards);
	}

	updateYard(yardName: string, updatedYard: YardRow) {
		const yards = this.getStoredYards();
		const index = yards.findIndex((y) => y.yardName === yardName);
		if (index !== -1) {
			yards[index] = updatedYard;
			this.setStoredYards(yards);
		}
	}

	deleteYard(yardName: string) {
		const yards = this.getStoredYards();
		const filteredYards = yards.filter((y) => y.yardName !== yardName);
		this.setStoredYards(filteredYards);
	}

	private getStoredRations(): Ration[] {
		const storedRations = localStorage.getItem(RATIONS_STORAGE_KEY);
		return storedRations ? JSON.parse(storedRations) : this.mockRations;
	}

	private setStoredRations(rations: Ration[]): void {
		localStorage.setItem(RATIONS_STORAGE_KEY, JSON.stringify(rations));
	}

	getRations(): Ration[] {
		return this.getStoredRations();
	}

	getRationById(id: string): Ration | undefined {
		return this.getStoredRations().find((r) => r.id === id);
	}

	addRation(ration: Omit<Ration, 'id'>): Ration {
		const newRation = { ...ration, id: uuidv4() };
		const rations = this.getStoredRations();
		rations.push(newRation);
		this.setStoredRations(rations);
		return newRation;
	}

	updateRation(id: string, updatedRation: Ration): void {
		const rations = this.getStoredRations();
		const index = rations.findIndex((r) => r.id === id);
		if (index !== -1) {
			rations[index] = updatedRation;
			this.setStoredRations(rations);
		}
	}

	deleteRation(id: string): void {
		const rations = this.getStoredRations();
		const filteredRations = rations.filter((r) => r.id !== id);
		this.setStoredRations(filteredRations);
	}

	getPaginatedLots(page: number, size: number): PaginatedResponse<LotRow> {
		const startIndex = page * size;
		const endIndex = Math.min(startIndex + size, this.mockLots.length);

		return {
			data: this.mockLots.slice(startIndex, endIndex),
			meta: {
				total: this.mockLots.length,
				page,
				size,
				startIndex: startIndex + 1,
				endIndex,
			},
		};
	}

	getPaginatedYards(page: number, size: number): PaginatedResponse<YardRow> {
		const yards = this.getStoredYards();
		const startIndex = page * size;
		const endIndex = Math.min(startIndex + size, yards.length);

		return {
			data: yards.slice(startIndex, endIndex),
			meta: {
				total: yards.length,
				page,
				size,
				startIndex: startIndex + 1,
				endIndex,
			},
		};
	}

	getPaginatedRations(page: number, size: number): PaginatedResponse<Ration> {
		const rations = this.getStoredRations();
		const startIndex = page * size;
		const endIndex = Math.min(startIndex + size, rations.length);

		return {
			data: rations.slice(startIndex, endIndex),
			meta: {
				total: rations.length,
				page,
				size,
				startIndex: startIndex + 1,
				endIndex,
			},
		};
	}

	createStage(rationId: string, stageData: { name: string; startWeight: string; endWeight: string }): void {
		const rations = this.getStoredRations();
		const rationIndex = rations.findIndex((r) => r.id === rationId);

		if (rationIndex !== -1) {
			const newStage: Stage = {
				name: stageData.name,
				start_weight: parseFloat(stageData.startWeight),
				end_weight: parseFloat(stageData.endWeight),
				ration_ingredients: [],
			};

			rations[rationIndex].stages.push(newStage);
			this.setStoredRations(rations);
		}
	}

	deleteStage(rationId: string, stageIndex: number): void {
		const rations = this.getStoredRations();
		const rationIndex = rations.findIndex((r) => r.id === rationId);

		if (rationIndex !== -1) {
			rations[rationIndex].stages.splice(stageIndex, 1);
			this.setStoredRations(rations);
		}
	}

	getIngredients(): Ingredient[] {
		return this.mockIngredients;
	}

	addLot(lot: LotRow) {
		const lots = this.getStoredLots();
		lots.push(lot);
		this.setStoredLots(lots);
	}

	updateLot(lotName: string, updatedLot: LotRow) {
		const lots = this.getStoredLots();
		const index = lots.findIndex((l) => l.lotName === lotName);
		if (index !== -1) {
			lots[index] = updatedLot;
			this.setStoredLots(lots);
		}
	}

	deleteLot(lotName: string) {
		const lots = this.getStoredLots();
		const filteredLots = lots.filter((l) => l.lotName !== lotName);
		this.setStoredLots(filteredLots);
	}

	getProjectedPnlData() {
		const months = [];
		const now = DateTime.now();

		// Generate 13 months of data (current + 12 future)
		for (let i = 0; i < 13; i++) {
			const month = now.plus({ months: i });
			const baseValue = 100000 + Math.random() * 50000;

			months.push({
				date: month.toFormat('MMM yyyy'),
				netPL: baseValue * (1 + Math.random() * 0.4 - 0.2), // +/- 20%
				revenue: baseValue * 1.5 * (1 + Math.random() * 0.2), // Higher than base, +/- 10%
				hedgePL: baseValue * 0.2 * (1 + Math.random() * 0.6 - 0.3), // +/- 30%
				expenses: -baseValue * 0.7 * (1 + Math.random() * 0.3), // Lower than base, +/- 15%
			});
		}

		return months;
	}

	getLotsForRation(rationName: string): LotRow[] {
		const lots = this.getStoredLots();
		return lots.filter((lot) => lot.ration === rationName);
	}

	getActiveLots(rationName: string): LotRow[] {
		const lots = this.getLotsForRation(rationName);
		const currentDate = DateTime.now();

		return lots.filter((lot) => {
			const startDate = DateTime.fromFormat(lot.startDate, 'MM/dd/yyyy');
			const targetDate = DateTime.fromFormat(lot.targetDate, 'MM/dd/yyyy');
			return currentDate >= startDate && currentDate <= targetDate;
		});
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
	interface Registry {
		'cattle-mock': CattleMockService;
	}
}
