import { debug } from '@ember/debug';
import getCSSVariable from './get-css-variable';
const defaultChartColors = [
	'--brand-interactive-blue-70',
	'--brand-orange-50',
	'--brand-lime-50',
	'--brand-lemon-60',
	'--brand-teal-60',
	'--brand-purple-50',
].map((color) => getCSSVariable(color));

function mapToDefaultChartColors<T extends string | number | symbol>(keys: Readonly<T[]>): Record<T, string> {
	return keys.reduce(
		(acc, key, index) => {
			if (index >= defaultChartColors.length) {
				debug(`Not enough default chart colors for ${String(key)}. Wrapping around to the start of the color palette.`);
			}
			acc[key] = defaultChartColors[index % defaultChartColors.length];
			return acc;
		},
		{} as Record<T, string>,
	);
}

export { defaultChartColors, mapToDefaultChartColors };
