import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='mt-3 ml-1' id={{this.legendId}}></div>\n<div id='{{@id}}-container' class='relative h-80' ...attributes>\n\t<BarChart @id={{@id}} @options={{this.chartOptions}} @data={{@data}} @chartData={{this.chartData}} @plugins={{this.plugins}} />\n</div>", {"contents":"<div class='mt-3 ml-1' id={{this.legendId}}></div>\n<div id='{{@id}}-container' class='relative h-80' ...attributes>\n\t<BarChart @id={{@id}} @options={{this.chartOptions}} @data={{@data}} @chartData={{this.chartData}} @plugins={{this.plugins}} />\n</div>","moduleName":"vault-client/components/harvest-pnl-bar-chart/net-pnl.hbs","parseOptions":{"srcName":"vault-client/components/harvest-pnl-bar-chart/net-pnl.hbs"}});
import { guidFor } from '@ember/object/internals';
import { getCustomLegend } from 'vault-client/utils/chart-utils';
import Component from '@glimmer/component';
import type { ChartData, ChartDataset, ChartOptions } from 'chart.js';
import { mapToDefaultChartColors } from 'vault-client/utils/chart';
import type { CropGroup } from 'vault-client/utils/grain/crop';
import type { GenerateChartOptions, GenerateTooltipOptions } from '../harvest-pnl-bar-chart';
import {
	projectedNetPnlKeys,
	projectedNetPnlKeyToLabel,
	type ProjectedNetPnl,
	type ProjectedNetPnlKey,
} from 'vault-client/utils/grain/dashboard';

type ProjectedNetPnlChartKey = 'Business' | CropGroup;
type ProjectedNetPnlChartRawData = Partial<Record<ProjectedNetPnlChartKey, ProjectedNetPnl>>;

export type { ProjectedNetPnlChartRawData };

export interface HarvestPnlBarChartNetPnlSignature {
	// The arguments accepted by the component
	Args: {
		id: string;
		data: ProjectedNetPnlChartRawData;
		generateTooltipOptions: GenerateTooltipOptions;
		generateChartOptions: GenerateChartOptions;
	};
	// Any blocks yielded by the component
	Blocks: {
		default: [];
	};
	// The element to which `...attributes` is applied in the component template
	Element: HTMLDivElement;
}

export default class HarvestPnlBarChartNetPnlComponent extends Component<HarvestPnlBarChartNetPnlSignature> {
	guid = guidFor(this);

	get legendId() {
		return `${this.args.id}-legend-${this.guid}`;
	}

	get labels() {
		return Object.keys(this.args.data);
	}

	get values() {
		return Object.values(this.args.data);
	}

	get dataKeys() {
		return projectedNetPnlKeys;
	}

	get colorMap() {
		return mapToDefaultChartColors(this.dataKeys);
	}

	get chartData(): ChartData<'bar' | 'line'> {
		const { colorMap, labels, getChartType } = this;

		const datasets: ChartDataset<'bar' | 'line'>[] = this.dataKeys.map((key) => {
			const data = this.values.map((value) => value[key] ?? 0);
			const label = projectedNetPnlKeyToLabel[key];

			return {
				label,
				type: getChartType(key),
				data,
				backgroundColor: colorMap[key],
			};
		});

		return {
			labels,
			datasets,
		};
	}

	get plugins() {
		const { id } = this.args;
		const { legendId } = this;
		return [
			{
				afterUpdate: getCustomLegend(id, legendId),
			},
		];
	}

	get chartOptions(): ChartOptions<'bar'> {
		return this.args.generateChartOptions({
			tooltipOptions: this.args.generateTooltipOptions(),
		});
	}

	getChartType = (key: ProjectedNetPnlKey) => {
		return key === 'netPnl' ? 'line' : 'bar';
	};
}
