import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import GET_CUSTOMER from 'vault-client/graphql/queries/customer-get.graphql';
import { useQuery } from 'glimmer-apollo';
import { tracked } from '@glimmer/tracking';

export interface BusinessesBusinessRouteParams {
	business_id: string;
}

type GetCustomerQuery = {
	Customer: {
		id: string;
		averageFinishAgeInWeeks: number | null;
		averageFinishWeightInLbs: number | null;
		name: string;
		type: string;
		isVgs: boolean;
		organizationId: string;
		businessRoles: string[];
		ActiveProducts: Array<{
			name: string;
			slug: string;
		}>;
		Organization: {
			id: string;
			name: string;
		};
		CurrentUserPermissions: {
			canWriteOperations: boolean;
		};
		DrpPolicies: Array<{
			id: string;
		}>;
		LrpPolicies: Array<{
			id: string;
		}>;
		LgmPolicies: Array<{
			id: string;
		}>;
	};
};

type GetCustomerQueryVariables = {
	id: string;
};

export default class BusinessesBusinessRoute extends Route {
	@service applicationScope: any;
	@tracked variables?: GetCustomerQueryVariables;

	getCustomer = useQuery<GetCustomerQuery, GetCustomerQueryVariables>(this, () => [
		GET_CUSTOMER,
		{
			variables: this.variables,
			skip: !this.variables,
		},
	]);

	async model(params: BusinessesBusinessRouteParams) {
		this.variables = { id: params.business_id };
		await this.getCustomer.promise;

		const organizationId = this.getCustomer.data?.Customer?.Organization?.id ?? null;

		this.applicationScope.globalCustomerId = params.business_id;

		if (organizationId) {
			this.applicationScope.organizationId = organizationId;
		}

		return { getCustomer: this.getCustomer, businessId: params.business_id };
	}
}
