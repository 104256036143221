import { v5 as uuid } from 'uuid';
const UUID_NAMESPACE = '74cb5b97-8dbe-492a-9901-a2a4a21f2362';

/**
 *
 * @param value
 * @returns A UUID specific to value. A given value will always return the same UUID.
 */
export function getStableId(value: string) {
	return uuid(value, UUID_NAMESPACE);
}
