import { gql } from 'glimmer-apollo';
import { Query, Query_CattleYardsArgs } from 'vault-client/types/graphql-types';

const YARDS_OVERVIEW_QUERY = gql`
	query YardsOverview(
		$distinctOn: CattleYardDistinctOnDTO
		$limit: Float
		$offset: Float
		$orderBy: CattleYardSortByDTO
		$pagination: PaginationInput
		$scopeId: String
		$sortBy: [CattleYardSortByDTO!]
		$where: CattleYardFilterDTO
	) {
		CattleYards(
			distinctOn: $distinctOn
			limit: $limit
			offset: $offset
			orderBy: $orderBy
			pagination: $pagination
			scopeId: $scopeId
			sortBy: $sortBy
			where: $where
		) {
			id
			name
			description
		}
		CattleYardCount(distinctOn: $distinctOn, limit: $limit, scopeId: $scopeId, where: $where) {
			count
		}
	}
`;

type YardsOverviewQuery = {
	CattleYards: Pick<Query['CattleYards'][number], 'id' | 'name' | 'description'>[];
	CattleYardCount: Pick<Query['CattleYardCount'], 'count'>;
};

type YardsOverviewQueryVariables = Query_CattleYardsArgs;

export { YARDS_OVERVIEW_QUERY, type YardsOverviewQuery, type YardsOverviewQueryVariables };
