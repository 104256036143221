import { MutationOptions, useMutation } from 'glimmer-apollo';
import { DELETE_CATTLE_YARD } from 'vault-client/graphql/mutations/cattle/yards';
import { Mutation, Mutation_deleteCattleYardArgs } from 'vault-client/types/graphql-types';

const RELATED_FIELD_NAMES = [
	'CattleYards',
	'CattleYard',
	'CattleYardCount',
	'CattleLots',
	'CattleLot',
	'CattleLotCount',
	'AggregateCattleLots',
] as const;

const defaultOptions: MutationOptions<Mutation['deleteCattleYard'], Mutation_deleteCattleYardArgs> = {
	update: (cache) => {
		RELATED_FIELD_NAMES.forEach((fieldName) => {
			cache.evict({ fieldName });
		});
		cache.gc();
	},
};

export function useDeleteCattleYardMutation(
	context: object,
	options?: MutationOptions<Mutation['deleteCattleYard'], Mutation_deleteCattleYardArgs>,
) {
	return useMutation<Mutation['deleteCattleYard'], Mutation_deleteCattleYardArgs>(context, () => [
		DELETE_CATTLE_YARD,
		{
			...defaultOptions,
			...options,
		},
	]);
}
