import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import { DateTime } from 'luxon';
import PositionsShowController from 'vault-client/controllers/positions/show';
import { Query, Query_CurrentPositionArgs } from 'vault-client/types/graphql-types';

const GET_CURRENT_POSITION = gql`
	query CurrentPosition($id: String!, $startDate: String!, $endDate: String!) {
		PositionComponentAllocations(where: { positionId: { equals: $id }, effectiveHedgeDate: { gte: $startDate, lte: $endDate } }) {
			id
			effectiveHedgeDate
			price
			shortContractQuantity
			longContractQuantity
			netContractQuantity
		}
		AggregateCurrentAllocationPositions(
			calc: { sum: { grossPnl: true, contractQuantity: true } }
			groupBy: { effectiveHedgeDate: true }
			where: { positionId: { equals: $id }, effectiveHedgeDate: { gte: $startDate, lte: $endDate } }
		) {
			id
			effectiveHedgeDate
			sum {
				grossPnl
				contractQuantity
			}
		}
		CurrentPosition(id: $id) {
			id
			displayExpiresAt
			quantity
			commissionTotal
			feeTotal
			realizedPnl
			unrealizedPnl
			grossPnl
			Instrument {
				id
				name
				type
				SymbolGroup {
					id
					fractionDigits
					displayFactor
				}
				Product {
					id
					slug
					name
					optionsUnitValue
					pointValue
					StandardProductLotSpecification {
						pointValue
					}
				}
				... on Future {
					expiresAt
					displayExpiresAt
					barchartSymbol
					exchangeSymbol
				}
				... on Option {
					optionType
					strike
					expiresAt
					displayExpiresAt
					barchartSymbol
					exchangeSymbol
					UnderlyingInstrument {
						id
						barchartSymbol
					}
				}
				... on Swap {
					expiresAt
					displayExpiresAt
					PriceInstrument {
						id
						type
						Product {
							id
							slug
							name
							optionsUnitValue
							pointValue
							StandardProductLotSpecification {
								pointValue
							}
						}
						... on Future {
							displayExpiresAt
							barchartSymbol
							SymbolGroup {
								id
								displayFactor
								fractionDigits
							}
						}
					}
				}
				... on Swaption {
					expiresAt
					displayExpiresAt
					strike
					optionType
					SettlementInstrument {
						id
						barchartSymbol
						expiresAt
					}
					PriceInstrument {
						id
						type
						Product {
							id
							slug
							name
							optionsUnitValue
							pointValue
							StandardProductLotSpecification {
								pointValue
							}
						}
						... on Future {
							displayExpiresAt
							barchartSymbol
							SymbolGroup {
								id
								displayFactor
								fractionDigits
							}
						}
					}
				}
			}
			Transactions {
				id
				contractQuantity
				unitQuantity
				price
				tradeDate
				feeTotal
				commissionTotal
				commissionAndFeeTotal
				Account {
					id
					accountNumber
					name
					... on BrokerageAccount {
						salesCode
					}
				}
				Instrument {
					id
					name
					type
					SymbolGroup {
						fractionDigits
						displayFactor
					}
					Product {
						id
						name
						displayFactor
						fractionDigits
						optionsUnitValue
						pointValue
						StandardProductLotSpecification {
							pointValue
						}
						velaRootSymbol
					}
					... on Option {
						optionType
						strike
						expiresAt
						displayExpiresAt
						barchartSymbol
						exchangeSymbol
						UnderlyingInstrument {
							id
							barchartSymbol
						}
					}
					... on Future {
						expiresAt
						displayExpiresAt
						barchartSymbol
						exchangeSymbol
					}
					... on Swap {
						expiresAt
						displayExpiresAt
						PriceInstrument {
							id
							... on Future {
								barchartSymbol
							}
						}
					}
					... on Swaption {
						expiresAt
						displayExpiresAt
						strike
						optionType
						PriceInstrument {
							id
							... on Future {
								barchartSymbol
							}
						}
					}
				}
			}
		}
	}
`;

interface ModelParams {
	position_id: string;
	allocationsStartMonth: string;
	allocationsEndMonth: string;
}

interface GetCurrentPositionArgs extends Query_CurrentPositionArgs {
	startDate: string;
	endDate: string;
}

export default class PositionsShowRoute extends Route {
	@service router: RouterService | undefined;

	handleTransition = (transition: Transition) => {
		// eslint-disable-next-line ember/no-controller-access-in-routes
		const controller = this.controllerFor(this.routeName) as PositionsShowController;
		if (transition.to?.name !== this.routeName) {
			if (controller.transition === null) {
				transition.abort();
				controller.transition = transition;
				controller.showConfirmationIfNeededAndTransitionFn();
			}
		}
	};

	activate(transition: Transition<unknown>): void {
		super.activate(transition);
		this.router?.on('routeWillChange', this.handleTransition);
	}

	deactivate(transition: Transition<unknown>): void {
		super.deactivate(transition);
		this.router?.off('routeWillChange', this.handleTransition);
	}

	queryParams = {
		allocationsStartMonth: {
			refreshModel: true,
		},
		allocationsEndMonth: {
			refreshModel: true,
		},
	};
	templateName: string = 'positions/show';

	@tracked variables: GetCurrentPositionArgs = {
		id: '',
		startDate: '',
		endDate: '',
	};

	getCurrentPosition = useQuery<
		{
			CurrentPosition: Query['CurrentPosition'];
			PositionComponentAllocations: Query['PositionComponentAllocations'];
			AggregateCurrentAllocationPositions: Query['AggregateCurrentAllocationPositions'];
		},
		GetCurrentPositionArgs
	>(this, () => [
		GET_CURRENT_POSITION,
		{
			variables: this.variables,
		},
	]);

	async model(params: ModelParams) {
		this.variables = { id: params.position_id, startDate: params.allocationsStartMonth, endDate: params.allocationsEndMonth };
		await this.getCurrentPosition.promise;
		return {
			getCurrentPosition: this.getCurrentPosition,
			updatedAt: DateTime.now().toISO(),
		};
	}

	resetController(controller: PositionsShowController, isExiting: boolean, transition: Transition<unknown>) {
		super.resetController(controller, isExiting, transition);
		if (isExiting) {
			controller.resetDataFn(null);
			controller.allAllocationsDataMap = new Map();
		}
	}
}
