import { gql } from 'glimmer-apollo';
import { DateTime } from 'luxon';
import BaseDeleteModalComponent from 'vault-client/components/base-delete-modal';
import { PhysicalCropTransaction } from 'vault-client/types/graphql-types';

const DELETE_PHYSICAL_CROP_TRANSACTION = gql`
	mutation DeletePhysicalCropTransaction($id: String!) {
		deletePhysicalCropTransaction(id: $id) {
			id
		}
	}
`;

export default class DeletePhysicalCropTransactionModalComponent extends BaseDeleteModalComponent {
	mutation = DELETE_PHYSICAL_CROP_TRANSACTION;
	evictionFields: string[] = [
		'PhysicalCropTransactions',
		'PhysicalCropTransactionsCount',
		'CropTransaction',
		'CropTransactions',
		'CropTransactionCount',
		'CropIngredientConsumedAndPurchasedVolumes',
		'AggregateCropIngredientConsumedAndPurchasedVolumes',
	];

	get cropContract() {
		return this.args.itemToDelete as PhysicalCropTransaction | undefined;
	}

	get titleText() {
		return 'Remove Crop Contract';
	}

	get bodyText() {
		return 'Are you sure you want to remove this Crop Contract?';
	}

	get deleteButtonText() {
		return 'Remove Crop Contract';
	}

	get label() {
		if (!this.cropContract) return '';

		const { Crop, deliveryStartDate, deliveryEndDate } = this.cropContract;

		const formattedStartDate = DateTime.fromISO(deliveryStartDate).toLocaleString(DateTime.DATE_MED);
		const formattedEndDate = DateTime.fromISO(deliveryEndDate).toLocaleString(DateTime.DATE_MED);
		return `${Crop?.name}: ${formattedStartDate} to ${formattedEndDate}`;
	}
}
