import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type { CattleFeedRation } from 'vault-client/types/graphql-types';
import { hasError as validationStateHasError } from 'vault-client/utils/forms/validation-state/has-error';
import { ModelFrom } from 'vault-client/utils/type-utils';
import BusinessesBusinessCattleRationsIndexRoute from 'vault-client/routes/businesses/business/cattle/rations/index';
import { service } from '@ember/service';
import FormStateService from 'vault-client/services/form-state';
import type RouterService from '@ember/routing/router-service';
import type SuccessHandler from 'vault-client/services/success-handler';
import type { CattleFeedRationCreateResponse } from 'vault-client/types/cattle/ration';
import { getRationsTableColumns } from 'vault-client/utils/components/cattle/rations/rations-table';
import CattleRation from 'vault-client/models/cattle/cattle-ration';

type SidePanelState = 'CREATE_RATION' | 'EDIT_RATION' | null;

export default class BusinessesBusinessCattleRationsIndexController extends Controller {
	rationsTableRoute = 'businesses.business.cattle.rations-table';
	rationsTableId = 'rations-table';
	createRationFormId = 'create-ration-form';
	editRationFormId = 'edit-ration-form';
	queryParams = ['ingredientIds'];
	declare model: ModelFrom<BusinessesBusinessCattleRationsIndexRoute>;

	@tracked ingredientIds: string[] = [];
	@tracked rationToEdit: CattleFeedRation | null = null;
	@tracked rationToDelete: CattleFeedRation | null = null;
	@tracked sidePanelState: SidePanelState = null;

	@service('form-state') declare formStateService: FormStateService;
	@service declare router: RouterService;
	@service('success-handler') declare successHandler: SuccessHandler;

	get businessId() {
		return this.model.businessId;
	}

	get rations() {
		const rations = this.model.rationsQuery.data?.CattleFeedRations ?? [];
		return this.itemsFn(rations as CattleFeedRation[]);
	}

	get isSidePanelOpen() {
		return this.sidePanelState !== null;
	}

	get formId() {
		switch (this.sidePanelState) {
			case 'CREATE_RATION':
				return this.createRationFormId;
			case 'EDIT_RATION':
				return this.editRationFormId;
			default:
				return null;
		}
	}

	get formState() {
		if (!this.formId) return undefined;
		return this.formStateService.getState(this.formId);
	}

	get submitButtonText(): string {
		switch (this.sidePanelState) {
			case 'CREATE_RATION':
				return this.formState?.submissionState?.isPending ? 'Creating...' : 'Create Ration';
			case 'EDIT_RATION':
				return this.formState?.submissionState?.isPending ? 'Saving...' : 'Save Changes';
			default:
				return 'Submit';
		}
	}

	get panelTitle() {
		switch (this.sidePanelState) {
			case 'CREATE_RATION':
				return 'Create Ration';
			case 'EDIT_RATION':
				return 'Edit Ration';
			default:
				return '';
		}
	}

	get isSubmitDisabled(): boolean {
		if (!this.formState?.validationState) return true;
		return validationStateHasError(this.formState.validationState) || !!this.formState.submissionState?.isPending;
	}

	get rationsTableColumns() {
		return getRationsTableColumns({
			editRation: this.editRation,
			deleteRation: this.openDeleteConfirmation,
		});
	}

	@action
	submitForm() {
		this.formState?.submit?.();
	}

	@action
	closeSidePanel() {
		this.setSidePanelState({ state: null });
	}

	@action
	createRation() {
		this.setSidePanelState({ state: 'CREATE_RATION' });
	}

	@action
	editRation(ration: CattleFeedRation) {
		this.setSidePanelState({ state: 'EDIT_RATION', rationToEdit: ration });
	}

	@action
	openDeleteConfirmation(ration: CattleFeedRation) {
		this.rationToDelete = ration;
	}

	@action
	closeDeleteConfirmation() {
		this.rationToDelete = null;
	}

	@action
	async handleCreateSuccess(result: CattleFeedRationCreateResponse) {
		const resolvedResult = await Promise.resolve(result);
		const ration = resolvedResult.createCattleFeedRation;
		if (ration?.id) {
			this.successHandler.showSuccess('Ration created successfully');
			this.closeSidePanel();
			await this.router.transitionTo('businesses.business.cattle.rations.show', ration.id);
		}
	}

	@action
	async handleEditSuccess() {
		this.successHandler.showSuccess('Ration updated successfully');
		this.closeSidePanel();
	}

	@action
	setSidePanelState(nextState: { state: 'CREATE_RATION' } | { state: 'EDIT_RATION'; rationToEdit: CattleFeedRation } | { state: null }) {
		if (nextState.state === 'EDIT_RATION') {
			this.rationToEdit = nextState.rationToEdit;
		} else {
			this.rationToEdit = null;
		}
		this.sidePanelState = nextState.state;
	}

	@action
	setIngredientIds(ingredientIds: string[]) {
		this.ingredientIds = ingredientIds;
	}

	itemsFn = (rows: CattleFeedRation[]) => {
		return rows.map((row) => {
			return new CattleRation(row);
		});
	};
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'businesses/business/cattle/rations/index': BusinessesBusinessCattleRationsIndexController;
	}
}
