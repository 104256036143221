import Route from '@ember/routing/route';
import {
	GET_CATTLE_FEED_RATION,
	type CattleFeedRationQueryVariables,
} from 'vault-client/graphql/queries/businesses/business/cattle/ration-detail';
import type { BusinessesBusinessRouteParams } from 'vault-client/routes/businesses/business';
import { useRationDetailQuery } from 'vault-client/utils/cattle/rations/use-ration-detail-query';
import { NullableSortByDirection } from 'vault-client/types/graphql-types';
import BusinessesBusinessCattleRationsShowController from 'vault-client/controllers/businesses/business/cattle/rations/show';
import Transition from '@ember/routing/transition';

interface Params {
	ration_id: string;
}

export default class BusinessesBusinessCattleRationsShowRoute extends Route {
	async model(params: Params) {
		const { business_id: businessId } = this.paramsFor('businesses.business') as BusinessesBusinessRouteParams;
		const variables: CattleFeedRationQueryVariables = {
			id: params.ration_id,
			stagesOrderBy: {
				startWeightInLb: NullableSortByDirection.Asc,
			},
		};

		const rationDetailQuery = useRationDetailQuery(this, {
			variables,
		});

		await rationDetailQuery.promise;

		return {
			rationDetailQuery,
			variables,
			query: GET_CATTLE_FEED_RATION,
			businessId,
		};
	}

	resetController(controller: BusinessesBusinessCattleRationsShowController, isExiting: boolean, transition: Transition) {
		super.resetController(controller, isExiting, transition);
		if (isExiting) {
			controller.setSidePanelState(null);
		}
	}
}
