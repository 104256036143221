import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { cached, tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { TrackedObject } from 'tracked-built-ins/.';
import BusinessesBusinessCropsDashboardRoute from 'vault-client/routes/businesses/business/crops-dashboard';
import { getInvalidElements, isFormValid } from 'vault-client/utils/form-validation';
import {
	calculateCropLedgerEntriesTotalValue,
	cornCategorySlugs,
	createCrop,
	CreateCropData,
	getAllCropTransactionsByCrop,
	getAllCropTransactionsByType,
	getAvgPriceForBasisOnlyCropTransactions,
	getAvgPriceForFlatCropTransactions,
	getAvgPriceForHTACropTransactions,
	getBrokeragePnL,
	getCropMarketPricePerUnit,
	getCropPriceForHarvestYear,
	getFieldLevelExpenseComponents,
	getFieldLevelRevenueComponents,
	getFuturesPriceInDollarsPerUnit,
	getHarvestedAcres,
	getHarvestYearFuture,
	getMostCurrentFuture,
	getTotalBushelsForPricedCropTransactions,
	getTotalPriceForPricedCropTransactions,
	getUniqueProductSlugs,
	parseCropData,
	soyBeanCategorySlugs,
	TYPE_OF_CROP_PRICING_LABELS,
	wheatCategorySlugs,
} from 'vault-client/utils/grain-utils';
import { isDefined, isProductSlug, ModelFrom } from 'vault-client/utils/type-utils';
import {
	Crop,
	CropCategory,
	CropPricingMethodology,
	Future,
	CropTransaction,
	Product,
	CurrentAllocationPosition,
	TypeOfInstrument,
	Swaption,
	Option,
	Side,
	CropLedgerEntryPerHarvestYear,
	TypeOfCropFieldLedger,
	AggregateCurrentAllocationPositionDTO,
	ProductLotSpecification,
	type CurrentPosition,
	type AggregateCurrentPositionDTO,
} from 'vault-client/types/graphql-types';
import { service } from '@ember/service';
import MarketDataService from 'vault-client/services/market-data';
import { DateTime } from 'luxon';
import { UiDateFilterOption } from 'vault-client/components/vault/ui-date-filter';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import { guidFor } from '@ember/object/internals';
import { ProductSlug } from 'vault-client/types/vault-client';
import checkStorageAvailable from 'vault-client/utils/check-storage-available';
import { getPositionsTotal, itemsFn } from 'vault-client/utils/position/position-utils';
import { getOwner } from '@ember/application';
import { getBusinessProjectedRevenue } from 'vault-client/utils/grain/dashboard/get-business-projected-revenue';
import {
	getAdditionalRevenue,
	getBrokeragePnl,
	type BusinessProjectedExpenses,
	type BusinessProjectedRevenue,
} from 'vault-client/utils/grain/dashboard';
import {
	getProjectedRevenueByCropGroup,
	type ProjectedRevenueByCropGroup,
} from 'vault-client/utils/grain/dashboard/get-projected-revenue-by-crop-group';
import { chartTypes as harvestPnlChartTypes, type ChartType as HarvestPnlChartType } from 'vault-client/components/harvest-pnl-bar-chart';
import { safeDivideZero } from 'vault-client/utils/precision-math';
import {
	getProjectedExpensesByCropGroup,
	type ProjectedExpensesByCropGroup,
} from 'vault-client/utils/grain/dashboard/get-projected-expenses-by-crop-group';
import { getBusinessProjectedExpenses, getBusinessProjectedExpensesAsAbsoluteValue } from 'vault-client/utils/grain/dashboard/get-business-projected-expenses';
import type { ProjectedExpensesChartRawData } from 'vault-client/components/harvest-pnl-bar-chart/expenses';
import { getProjectedNetPnlByCropGroup } from 'vault-client/utils/grain/dashboard/get-projected-net-pnl-by-crop-group';
import { getBusinessProjectedNetPnl } from 'vault-client/utils/grain/dashboard/get-business-projected-net-pnl';
import type { ProjectedNetPnlChartRawData } from 'vault-client/components/harvest-pnl-bar-chart/net-pnl';
import type { ProjectedRevenueChartRawData } from 'vault-client/components/harvest-pnl-bar-chart/revenues';

type harvestDetailRow = {
	label: string;
	harvestMonth: string;
	acres: number;
	//TODO: When the data is ready on the API surface, check if the 5 fields below should be nullable or not if harvest is available - Hassan
	yieldPerAcre: number | string | null;
	percentSold: number | string | null;
	revenue: number | string | null;
	expenses: number | string | null;
	netPnl: number | string | null;
};

type CropDetailRow = {
	id: string;
	label: string;
	harvestMonth?: string | null;
	acres?: number | null;
	yieldPerAcre?: number | string | null;
	percentSold?: number | string | null;
	revenue?: number | string | null;
	expenses?: number | string | null;
	netPnl?: number | string | null;
	isCollapsed?: boolean;
	children: harvestDetailRow[] | [];
	rollupValues?: {
		revenue: number;
		expenses: number;
		bushelsSold: number;
	};
};

type ChartDataParams = {
	cropTransactions: CropTransaction[];
	futuresTransactions: CurrentAllocationPosition[];
	optionsTransactions: CurrentAllocationPosition[];
	totalProduction: number;
	averageCashPrice: number;
	averageHtaPrice: number;
	averageBasisPrice: number;
	markToMarketPrice: number;
};

type AggregateBrokeragePositionsBySlug = Partial<Record<ProductSlug, AggregateCurrentAllocationPositionDTO>>;

const isLongPut = (position: CurrentAllocationPosition) =>
	position.instrumentType === 'Option' && position.optionType === 'Put' && position.positionSide === Side.Long;

const isLongPutSwaption = (position: CurrentAllocationPosition) =>
	position.instrumentType === 'Swaption' && position.optionType === 'Put' && position.positionSide === Side.Long;

const isSwap = (position: CurrentAllocationPosition) => position.instrumentType === TypeOfInstrument.Swap;

const isLongPutOrLongPutSwaption = (position: CurrentAllocationPosition) => isLongPut(position) || isLongPutSwaption(position);

const isShortPosition = (position: CurrentAllocationPosition) => position.positionSide === Side.Short;

const isFuture = (position: CurrentAllocationPosition) => position.instrumentType === TypeOfInstrument.Future;

const isFutureOrSwap = (position: CurrentAllocationPosition) => isFuture(position) || isSwap(position);

const calculateAveragePrice = ({ totalValue, totalVolume }: { totalValue: number; totalVolume: number }) => {
	if (totalVolume === 0) return 0;
	return totalValue / totalVolume;
};

export default class BusinessesBusinessCropsDashboardController extends Controller {
	declare model: ModelFrom<BusinessesBusinessCropsDashboardRoute>;
	positionDetailRoute: string = 'businesses.business.position';

	@service declare marketData: MarketDataService;

	@tracked activeChart: HarvestPnlChartType = 'Revenues';
	@tracked startDate: string = DateTime.now().startOf('year').toISODate();
	@tracked endDate: string = DateTime.now().endOf('year').toISODate();
	@tracked isSidePanelOpen = false;
	@tracked showCornPercentHedgedTable = false;
	@tracked showSoybeanPercentHedgedTable = false;
	@tracked showWheatPercentHedgedTable = false;
	@tracked createCropFormData: CreateCropData = new TrackedObject({
		businessId: this.model?.businessId ?? '',
		categoryId: '',
		cropCategory: null as CropCategory | null,
		name: '',
		defaultPrice: '',
		error: '',
		cropPriceType: TYPE_OF_CROP_PRICING_LABELS[CropPricingMethodology.Flat],
	}) as CreateCropData;

	queryParams = ['startDate', 'endDate'];
	harvestPnlRevenuesChartId = 'harvest-pnl-revenues-chart';
	harvestPnlExpensesChartId = 'harvest-pnl-expenses-chart';
	harvestNetPnlChartId = 'harvest-net-pnl-chart';
	harvestPnlChartTypes = harvestPnlChartTypes;

	id = guidFor(this);

	timePeriodOptions = [
		{
			displayName: 'Current Harvest Year',
			startDate: DateTime.now().startOf('year').toISODate(),
			endDate: DateTime.now().endOf('year').toISODate(),
		},
		{
			displayName: 'Harvest Year '.concat(DateTime.now().startOf('year').plus({ year: 1 }).get('year').toString()),
			startDate: DateTime.now().startOf('year').plus({ year: 1 }).toISODate(),
			endDate: DateTime.now().endOf('year').plus({ year: 1 }).toISODate(),
		},
		{
			displayName: 'Harvest Year '.concat(DateTime.now().startOf('year').plus({ year: 2 }).get('year').toString()),
			startDate: DateTime.now().startOf('year').plus({ year: 2 }).toISODate(),
			endDate: DateTime.now().endOf('year').plus({ year: 2 }).toISODate(),
		},
		{
			displayName: 'Harvest Year '.concat(DateTime.now().startOf('year').plus({ year: 3 }).get('year').toString()),
			startDate: DateTime.now().startOf('year').plus({ year: 3 }).toISODate(),
			endDate: DateTime.now().endOf('year').plus({ year: 3 }).toISODate(),
		},
	];

	get cropHarvestYears() {
		return this.model.getHarvestYears.data?.CropHarvestYears ?? [];
	}

	get harvestYear() {
		return this.model.harvestYear;
	}

	get currentTimePeriodOption() {
		return (
			this.timePeriodOptions.find((option) => option.startDate === this.startDate && option.endDate === this.endDate) ?? {
				startDate: this.startDate,
				endDate: this.endDate,
			}
		);
	}

	filterCropsBySlugs(crops: Crop[], categorySlugs: string[]) {
		return crops?.filter((crop) => categorySlugs.includes(crop.Category.HedgeProduct?.slug || '')) ?? [];
	}

	generateDetailRows(crops: Crop[]): CropDetailRow[] {
		return crops?.map((crop) => {
			const hasHarvest = crop?.CropHarvests && crop?.CropHarvests.length > 0;
			const harvestsArray = [];
			const cropTotals = {
				acres: 0,
				yieldPerAcre: 0,
				revenue: 0,
				expenses: 0,
				bushelsSold: 0,
				netPnl: 0,
			};

			let totalAcres = 0;
			let totalYieldPerHarvest = 0;
			if (hasHarvest) {
				let bushelsSold = 0;
				this.model.getDashboardData.data?.CropTransactions?.forEach((transaction: CropTransaction) => {
					if (crop.name === transaction.Crop.name) {
						bushelsSold += transaction.bushels;
					}
				});

				//Calculate expenses
				const totalFlatFieldExpenses = crop.ExpensesForHarvestYear?.totalUsdFromFieldFlatValues ?? 0;
				const totalVariableFieldExpenses = crop.ExpensesForHarvestYear?.totalUsdFromFieldPerAcreValues ?? 0;
				const totalFlatCropExpenses = crop.ExpensesForHarvestYear?.totalUsdFromCropFlatValues ?? 0;
				const totalVariableCropExpenses = crop.ExpensesForHarvestYear?.totalUsdFromCropPerAcreValues ?? 0;

				//Calculate revenues
				const totalFlatFieldRevenues = crop.RevenuesForHarvestYear?.totalUsdFromFieldFlatValues ?? 0;
				const totalVariableFieldRevenues = crop.RevenuesForHarvestYear?.totalUsdFromFieldPerAcreValues ?? 0;
				const totalFlatCropRevenues = crop.RevenuesForHarvestYear?.totalUsdFromCropFlatValues ?? 0;
				const totalVariableCropRevenues = crop.RevenuesForHarvestYear?.totalUsdFromCropPerAcreValues ?? 0;

				const physicalSales = this.soldUnitsTotalPrice(crop) ?? 0;
				const markToMarketUnsold = this.unsoldMarkToMarketValue(crop) ?? 0;

				const totalHarvestExpenses =
					totalFlatFieldExpenses + totalVariableFieldExpenses + totalFlatCropExpenses + totalVariableCropExpenses;
				const totalHarvestRevenues =
					totalFlatFieldRevenues +
					totalVariableFieldRevenues +
					totalFlatCropRevenues +
					totalVariableCropRevenues +
					physicalSales +
					markToMarketUnsold;

				cropTotals.expenses += totalHarvestExpenses;
				cropTotals.revenue += totalHarvestRevenues;

				for (const [_index, harvest] of crop.CropHarvests.entries()) {
					const formattedDate = DateTime.fromISO(harvest.forecastedHarvestDate).toFormat('MMM yyyy');
					cropTotals.acres += harvest.acres;
					cropTotals.yieldPerAcre += harvest.yieldPerAcre;

					totalAcres += harvest.acres;
					totalYieldPerHarvest += (harvest.acres ?? 0) * (harvest.yieldPerAcre ?? 0);

					cropTotals.bushelsSold = bushelsSold;

					// harvest level row data
					harvestsArray.push({
						label: ' ',
						harvestMonth: formattedDate,
						acres: harvest.acres,
						yieldPerAcre: new Intl.NumberFormat(undefined, {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						}).format(harvest.yieldPerAcre),
						revenue: null,
						expenses: null,
						netPnl: null,
						percentSold: null,
						rollupValues: {
							revenue: totalHarvestRevenues,
							expenses: totalHarvestExpenses,
							bushelsSold: bushelsSold,
						},
					});
				}
			}

			const percentSold = cropTotals.bushelsSold / totalYieldPerHarvest;
			const weightAverageOfYieldPerAcre = totalAcres === 0 ? 0 : totalYieldPerHarvest / totalAcres;
			return {
				id: crop.id,
				label: crop.name,
				children: harvestsArray,
				...(hasHarvest
					? {
							isCollapsed: true,
							acres: cropTotals.acres,
							harvestMonth: ' ',
							yieldPerAcre: new Intl.NumberFormat(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							}).format(weightAverageOfYieldPerAcre),
							percentSold: new Intl.NumberFormat('en-US', {
								style: 'percent',
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							}).format(percentSold),
							revenue: new Intl.NumberFormat('en-US', {
								style: 'currency',
								currency: 'USD',
								currencySign: 'accounting',
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							}).format(cropTotals.revenue),
							expenses: new Intl.NumberFormat('en-US', {
								style: 'currency',
								currency: 'USD',
								currencySign: 'accounting',
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							}).format(cropTotals.expenses),
							netPnl: new Intl.NumberFormat('en-US', {
								style: 'currency',
								currency: 'USD',
								currencySign: 'accounting',
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							}).format(cropTotals.revenue - cropTotals.expenses),
							rollupValues: {
								revenue: cropTotals.revenue,
								expenses: cropTotals.expenses,
								bushelsSold: cropTotals.bushelsSold,
								totalYieldPerHarvest: totalYieldPerHarvest,
							},
						}
					: {}),
			};
		});
	}

	percentHedgedTableRows(
		cropTransactions: CropTransaction[],
		futuresTransactions: CurrentAllocationPosition[],
		optionsTransactions: CurrentAllocationPosition[],
		averageCashPrice: number,
		averageBasisPrice: number,
		averageHtaPrice: number,
		totalProduction: number,
		markToMarketPrice: number,
	) {
		const flatPhysicalAmount = (['Flat'] as const)
			.flatMap((type) => getAllCropTransactionsByType(cropTransactions, type))
			.reduce((sum, transaction) => sum + (transaction.bushels ?? 0), 0);

		const htaPhysicalAmount = (['HTA'] as const)
			.flatMap((type) => getAllCropTransactionsByType(cropTransactions, type))
			.reduce((sum, transaction) => sum + (transaction.bushels ?? 0), 0);

		const basisPhysicalAmount = (['Basis'] as const)
			.flatMap((type) => getAllCropTransactionsByType(cropTransactions, type))
			.reduce((sum, transaction) => sum + (transaction.bushels ?? 0), 0);

		// Futures amount and avgPrice should only include net short positions
		const shortFuturesOrSwapTransactions = futuresTransactions.filter(isFutureOrSwap).filter(isShortPosition);
		const futuresAmount = shortFuturesOrSwapTransactions.reduce((sum, { unitQuantity }) => sum + Math.abs(unitQuantity), 0);
		const futuresAvgPrice = this.calculateAveragePriceForFuturesCurrentAllocationPositions(shortFuturesOrSwapTransactions);

		// Options amount should only include long puts/put swaptions
		const longPutsAndLongPutSwaptions = optionsTransactions.filter(isLongPutOrLongPutSwaption);
		const optionsAmount = longPutsAndLongPutSwaptions.reduce((sum, { unitQuantity }) => sum + unitQuantity, 0);
		const optionsAvgPrice = this.calculateAverageOptionsPrice(longPutsAndLongPutSwaptions);

		const totalHedgedAmount = flatPhysicalAmount + htaPhysicalAmount + basisPhysicalAmount + futuresAmount + optionsAmount;
		const unhedgedAmount = Math.max(totalProduction - totalHedgedAmount, 0);

		return [
			{
				description: 'Flat',
				bushels: flatPhysicalAmount,
				percentHedged: flatPhysicalAmount / (totalHedgedAmount + unhedgedAmount),
				avgPrice: averageCashPrice,
			},
			{
				description: 'HTA',
				bushels: htaPhysicalAmount,
				percentHedged: htaPhysicalAmount / (totalHedgedAmount + unhedgedAmount),
				avgPrice: averageHtaPrice,
			},
			{
				description: 'Basis',
				bushels: basisPhysicalAmount,
				percentHedged: basisPhysicalAmount / (totalHedgedAmount + unhedgedAmount),
				avgPrice: averageBasisPrice,
			},
			{
				description: 'Futures',
				bushels: futuresAmount,
				percentHedged: futuresAmount / (totalHedgedAmount + unhedgedAmount),
				avgPrice: futuresAvgPrice,
			},
			{
				description: 'Options',
				bushels: optionsAmount,
				percentHedged: optionsAmount / (totalHedgedAmount + unhedgedAmount),
				avgPrice: optionsAvgPrice,
			},
			{
				description: 'Unhedged',
				bushels: unhedgedAmount,
				percentHedged: unhedgedAmount / (totalHedgedAmount + unhedgedAmount),
				avgPrice: markToMarketPrice,
			},
		];
	}

	get percentHedgedSoybeanTableRows() {
		return this.percentHedgedTableRows(
			this.soybeanCropTransactions,
			this.soybeanFuturesTransactions,
			this.soybeanOptionsTransactions,
			this.soybeanAverageCashPrice,
			this.soybeanAverageBasisPrice,
			this.soybeanAverageHtaPrice,
			this.soybeanTotalProduction,
			this.soybeanMarkToMarketPrice,
		);
	}

	get percentHedgedWheatTableRows() {
		return this.percentHedgedTableRows(
			this.wheatCropTransactions,
			this.wheatFuturesTransactions,
			this.wheatOptionsTransactions,
			this.wheatAverageCashPrice,
			this.wheatAverageBasisPrice,
			this.wheatAverageHtaPrice,
			this.wheatTotalProduction,
			this.wheatMarkToMarketPrice,
		);
	}

	get percentHedgedCornTableRows() {
		return this.percentHedgedTableRows(
			this.cornCropTransactions,
			this.cornFuturesTransactions,
			this.cornOptionsTransactions,
			this.cornAverageCashPrice,
			this.cornAverageBasisPrice,
			this.cornAverageHtaPrice,
			this.cornTotalProduction,
			this.cornMarkToMarketPrice,
		);
	}

	get percentHedgedCornTableFooterRows() {
		const cornTableRows = this.percentHedgedCornTableRows;
		return this.buildFooterRow(cornTableRows);
	}

	get percentHedgedSoybeanTableFooterRows() {
		const soybeanTableRows = this.percentHedgedSoybeanTableRows;
		return this.buildFooterRow(soybeanTableRows);
	}

	get percentHedgedWheatTableFooterRows() {
		const wheatTableRows = this.percentHedgedWheatTableRows;
		return this.buildFooterRow(wheatTableRows);
	}

	buildFooterRow(tableRows: { description: string; bushels: number }[]) {
		const totalProduction = tableRows.reduce((sum, row) => sum + (row.bushels ?? 0), 0);
		return [
			{
				description: 'Total',
				bushels: totalProduction,
			},
		];
	}

	get percentHedgedTableColumns() {
		return [
			{
				id: '8413885f-0e78-4b17-954d-7c5e4040afbe',
				name: '',
				valuePath: 'description',
				width: 100,
				cellComponent: CellComponents.String,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				isTotaled: false,
			},
			{
				id: '1ba5f321-4233-4045-8766-67c4c9c11cdf',
				name: 'Bushels',
				valuePath: 'bushels',
				width: 100,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
			},
			{
				id: 'f0afb872-901b-4dbc-bb8a-65088832fd95',
				name: 'Percent Hedged',
				valuePath: 'percentHedged',
				width: 150,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'percent',
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				isTotaled: false,
			},
			{
				id: 'bb72f1f9-f7c5-4b49-9b9e-6f2a24ad5b94',
				name: 'Avg Price',
				valuePath: 'avgPrice',
				width: 150,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				isTotaled: false,
			},
		];
	}

	generateTotalRows(cropRows: CropDetailRow[], nonCommoditySpecialHandling: boolean = false) {
		if (cropRows.length == 0) return [];
		const rowsTotal = {
			label: 'Total',
			acres: 0,
			totalYield: 0,
			yieldPerAcre: 0,
			revenue: 0,
			expenses: 0,
			netPnl: 0,
			bushelsSold: 0,
			totalYieldPerHarvest: 0,
		};

		cropRows.forEach((crop) => {
			rowsTotal.acres += crop.acres ?? 0;
			rowsTotal.revenue += crop.rollupValues?.revenue ?? 0;
			rowsTotal.expenses += crop.rollupValues?.expenses ?? 0;
			rowsTotal.netPnl += (crop.rollupValues?.revenue ?? 0) - (crop.rollupValues?.expenses ?? 0);
			rowsTotal.totalYield += Number(crop.yieldPerAcre ?? 0) * (crop.acres ?? 0);
			rowsTotal.bushelsSold += crop.rollupValues?.bushelsSold ?? 0;
		});
		rowsTotal.yieldPerAcre = rowsTotal.acres === 0 ? 0 : rowsTotal.totalYield / rowsTotal.acres;

		const percentSold = rowsTotal.totalYield === 0 ? 0 : rowsTotal.bushelsSold / rowsTotal.totalYield;
		return [
			{
				label: 'Total',
				acres: rowsTotal.acres,
				percentSold: new Intl.NumberFormat('en-US', {
					style: 'percent',
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				}).format(percentSold),
				totalYield: new Intl.NumberFormat(undefined, {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				}).format(rowsTotal.totalYield),
				yieldPerAcre: nonCommoditySpecialHandling
					? 0
					: new Intl.NumberFormat(undefined, {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						}).format(rowsTotal.yieldPerAcre),
				revenue: new Intl.NumberFormat('en-US', {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				}).format(rowsTotal.revenue),
				expenses: new Intl.NumberFormat('en-US', {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				}).format(rowsTotal.expenses),
				netPnl: new Intl.NumberFormat('en-US', {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				}).format(rowsTotal.netPnl),
			},
		];
	}

	get addCropFormId() {
		return `add-crop-form`;
	}

	getFirstProductSlug(crops: Crop[]) {
		return crops[0]?.Category?.HedgeProduct?.slug;
	}

	get soybeanProductSlug() {
		return this.getFirstProductSlug(this.allSoyBeanCrops);
	}

	get wheatProductSlug() {
		return this.getFirstProductSlug(this.allWheatCrops);
	}

	cropTransactionsForSlugs(slugs: string[]) {
		return (
			this.model.getDashboardData.data?.CropTransactions?.filter((transaction) =>
				slugs.includes(transaction.Crop.Category.HedgeProduct?.slug ?? ''),
			) ?? []
		);
	}

	get isFoundationsCustomer() {
		return this.model.getCustomer.data?.Customer?.isVgs;
	}

	get allCurrentCrops() {
		return this.model.getDashboardData.data?.AllCrops ?? [];
	}

	get cornBreakevenPrice() {
		return this.calculateBreakevenPrice(this.allCornCrops);
	}

	get soybeanBreakevenPrice() {
		return this.calculateBreakevenPrice(this.allSoyBeanCrops);
	}

	get wheatBreakevenPrice() {
		return this.calculateBreakevenPrice(this.allWheatCrops);
	}

	calculateBreakevenPrice(cropsBreakeven: Crop[]) {
		let expenses = 0;
		let production = 0;
		cropsBreakeven.forEach((crop: Crop) => {
			//Calculate expenses
			const totalFlatFieldExpenses = crop.ExpensesForHarvestYear?.totalUsdFromFieldFlatValues ?? 0;
			const totalVariableFieldExpenses = crop.ExpensesForHarvestYear?.totalUsdFromFieldPerAcreValues ?? 0;
			const totalFlatCropExpenses = crop.ExpensesForHarvestYear?.totalUsdFromCropFlatValues ?? 0;
			const totalVariableCropExpenses = crop.ExpensesForHarvestYear?.totalUsdFromCropPerAcreValues ?? 0;

			//Sum of all expenses
			expenses += totalFlatFieldExpenses + totalVariableFieldExpenses + totalFlatCropExpenses + totalVariableCropExpenses;
			crop.CropHarvests?.forEach((harvest) => {
				production += (harvest.acres ?? 0) * (harvest.yieldPerAcre ?? 0);
			});
		});
		return expenses === 0 || production === 0 ? 0 : expenses / production;
	}

	// Corn
	get cornCropTransactions() {
		return this.cropTransactionsForSlugs(cornCategorySlugs);
	}

	get cornAverageCashPrice() {
		const flatPriceContracts = getAllCropTransactionsByType(this.cornCropTransactions, 'Flat');
		return getAvgPriceForFlatCropTransactions(flatPriceContracts);
	}

	get cornAverageHtaPrice() {
		const cornHtaTransactions = getAllCropTransactionsByType(this.cornCropTransactions, 'HTA');
		return this.calculateAverageHTAPriceFromTransactions(cornHtaTransactions);
	}

	get cornAverageBasisPrice() {
		const cornBasisTransactions = getAllCropTransactionsByType(this.cornCropTransactions, 'Basis');
		return this.calculateAverageBasisPriceFromTransactions(cornBasisTransactions);
	}

	get aggregateBrokeragePositions() {
		return this.model.getDashboardData.data?.AggregateCurrentAllocationPositions ?? [];
	}

	get aggregateBrokeragePositionsBySlug(): AggregateBrokeragePositionsBySlug {
		const { aggregateBrokeragePositions } = this;
		return aggregateBrokeragePositions.reduce<AggregateBrokeragePositionsBySlug>((acc, position) => {
			const slug = position.Product?.slug ?? '';
			if (isProductSlug(slug)) {
				acc[slug] = position;
			}

			return acc;
		}, {});
	}

	get cornNetPnlPerUnit() {
		return this.calculateNetPnlPerUnit(this.allCornCrops);
	}

	// Soybean
	get soybeanCropTransactions() {
		return this.cropTransactionsForSlugs(soyBeanCategorySlugs);
	}

	get soybeanAverageCashPrice() {
		const flatPriceContracts = getAllCropTransactionsByType(this.soybeanCropTransactions, 'Flat');
		return getAvgPriceForFlatCropTransactions(flatPriceContracts);
	}

	get soybeanAverageHtaPrice() {
		const soybeanHtaTransactions = getAllCropTransactionsByType(this.soybeanCropTransactions, 'HTA');
		return this.calculateAverageHTAPriceFromTransactions(soybeanHtaTransactions);
	}

	get soybeanAverageBasisPrice() {
		const soybeanBasisTransactions = getAllCropTransactionsByType(this.soybeanCropTransactions, 'Basis');
		return this.calculateAverageBasisPriceFromTransactions(soybeanBasisTransactions);
	}

	get soybeanNetPnlPerUnit() {
		return this.calculateNetPnlPerUnit(this.allSoyBeanCrops);
	}

	// Wheat
	get wheatCropTransactions() {
		return this.cropTransactionsForSlugs(wheatCategorySlugs);
	}

	get wheatAverageCashPrice() {
		const flatPriceContracts = getAllCropTransactionsByType(this.wheatCropTransactions, 'Flat');
		return getAvgPriceForFlatCropTransactions(flatPriceContracts);
	}

	get wheatAverageHtaPrice() {
		const wheatHTATransactions = getAllCropTransactionsByType(this.wheatCropTransactions, 'HTA');
		return this.calculateAverageHTAPriceFromTransactions(wheatHTATransactions);
	}

	get wheatAverageBasisPrice() {
		const wheatBasisTransactions = getAllCropTransactionsByType(this.wheatCropTransactions, 'Basis');
		return this.calculateAverageBasisPriceFromTransactions(wheatBasisTransactions);
	}

	get wheatNetPnlPerUnit() {
		return this.calculateNetPnlPerUnit(this.allWheatCrops);
	}

	calculateAverageHTAPriceFromTransactions(cropTransactions: CropTransaction[]) {
		const transactionValues: { totalPrice: number; totalVolume: number }[] = [];
		cropTransactions.forEach((transaction: CropTransaction) => {
			const totalPrice = getAvgPriceForHTACropTransactions([transaction], transaction.Crop.price, transaction.Crop.pricingMethodology);
			transactionValues.push({ totalPrice, totalVolume: transaction.bushels });
		});

		let totalSum: number = 0,
			totalVolumeSum: number = 0;
		for (const transaction of transactionValues) {
			totalSum += (transaction.totalPrice ?? 0) * (transaction.totalVolume ?? 0);
			totalVolumeSum += transaction.totalVolume ?? 0;
		}
		return totalSum === 0 ? 0 : totalSum / totalVolumeSum;
	}

	calculateAverageBasisPriceFromTransactions(cropTransactions: CropTransaction[]) {
		const transactionValues: { totalPrice: number; totalVolume: number }[] = [];
		cropTransactions.forEach((transaction: CropTransaction) => {
			const symbol =
				transaction.Crop?.CropHarvestYears[0]?.ContractMonthInstrument?.barchartSymbol ??
				transaction.Crop?.Category?.HedgeProduct?.MostCurrentFuture?.barchartSymbol ??
				null;
			const futuresPrice = symbol ? this.marketData.getLatestPrice(symbol) ?? 0 : 0;
			const productLotSpec: ProductLotSpecification =
				transaction.Crop.Category.HedgeProduct?.StandardProductLotSpecification ??
				({ lotSize: 0, pointValue: 0 } as ProductLotSpecification);
			const totalPrice = getAvgPriceForBasisOnlyCropTransactions(
				[transaction],
				productLotSpec.pointValue,
				productLotSpec.lotSize,
				futuresPrice,
			);
			transactionValues.push({ totalPrice, totalVolume: transaction.bushels });
		});

		let totalSum: number = 0,
			totalVolumeSum: number = 0;
		for (const transaction of transactionValues) {
			totalSum += (transaction.totalPrice ?? 0) * (transaction.totalVolume ?? 0);
			totalVolumeSum += transaction.totalVolume ?? 0;
		}
		return totalSum === 0 ? 0 : totalSum / totalVolumeSum;
	}

	calculateTotalValueAndTotalVolumeForFlatCropTransactionsFuturesPriceOnly = (
		flatCropTransactions: CropTransaction[],
	): { totalValue: number; totalVolume: number } => {
		// Physical flat contracts (only include the futures price portion of the contract not basis.).
		// If the futures price is not provided, the contract is excluded from the calculation.

		return flatCropTransactions.reduce(
			({ totalVolume, totalValue }, { futuresPrice, bushels }) => {
				let volume = 0;
				let value = 0;

				// Only include flat contract if futurePrice is set
				if (futuresPrice != undefined) {
					volume = bushels;
					value = bushels * futuresPrice;
				}

				return {
					totalVolume: totalVolume + volume,
					totalValue: totalValue + value,
				};
			},
			{ totalValue: 0, totalVolume: 0 },
		);
	};

	calculateAvgPriceForFlatCropTransactionsFuturesPriceOnly(flatCropTransactions: CropTransaction[]) {
		const { totalValue, totalVolume } = this.calculateTotalValueAndTotalVolumeForFlatCropTransactionsFuturesPriceOnly(flatCropTransactions);

		if (totalVolume === 0) return 0;

		return totalValue / totalVolume;
	}

	calculateTotalValueAndTotalVolumeForHTACropTransactionsFuturesPriceOnly = (
		htaCropTransactions: CropTransaction[],
	): { totalValue: number; totalVolume: number } => {
		// Only include futuresPrice not estimated basis

		return htaCropTransactions.reduce(
			({ totalVolume, totalValue }, { futuresPrice, bushels }) => {
				let volume = 0;
				let value = 0;

				// Only include flat contract if futurePrice is set
				if (futuresPrice != undefined) {
					volume = bushels;
					value = bushels * futuresPrice;
				}

				return {
					totalVolume: totalVolume + volume,
					totalValue: totalValue + value,
				};
			},
			{ totalValue: 0, totalVolume: 0 },
		);
	};

	get allCrops() {
		return this.model.getDashboardData.data?.AllCrops ?? [];
	}

	get cropsWithHarvests() {
		return this.allCrops.filter((crop) => !!crop.CropHarvests?.length);
	}

	get nonCommodityCrops() {
		return this.allCrops?.filter((crop) => !crop.Category.HedgeProduct?.slug) ?? [];
	}

	get allCornCrops() {
		return this.getCropsForSlugs(cornCategorySlugs);
	}

	get allSoyBeanCrops() {
		return this.getCropsForSlugs(soyBeanCategorySlugs);
	}

	get allWheatCrops() {
		return this.getCropsForSlugs(wheatCategorySlugs);
	}

	getCropsForSlugs(slugs: ProductSlug[]) {
		return (
			this.allCrops?.filter((crop) => {
				const slug = crop.Category.HedgeProduct?.slug ?? '';
				return isProductSlug(slug) && slugs.includes(slug);
			}) ?? []
		);
	}

	get cornDetailRows() {
		return this.generateDetailRows(this.allCornCrops);
	}

	get cornDetailColumnTotals() {
		return this.generateTotalRows(this.cornDetailRows, false);
	}

	get soybeanDetailRows() {
		return this.generateDetailRows(this.allSoyBeanCrops);
	}

	get soybeanDetailColumnTotals() {
		return this.generateTotalRows(this.soybeanDetailRows, false);
	}

	get wheatDetailRows() {
		return this.generateDetailRows(this.allWheatCrops);
	}

	get wheatDetailColumnTotals() {
		return this.generateTotalRows(this.wheatDetailRows, false);
	}

	get nonCommodityDetailRows() {
		return this.generateDetailRows(this.nonCommodityCrops);
	}

	get nonCommodityDetailColumnTotals() {
		return this.generateTotalRows(this.nonCommodityDetailRows, true);
	}

	get positionColumns(): TableColumn[] {
		return [
			{
				id: '321493aa-ab99-4e5e-b2c6-f2c33adaec27',
				name: 'Month',
				valuePath: 'displayExpiresAt',
				cellComponent: CellComponents.MonthFormat,
				minWidth: 80,
				textAlign: 'left',
				dark: true,
				isSortable: true,
				isFixed: '',
				isVisible: true,
				isTotaled: false,
			},
			{
				id: 'a31b3e3d-7e9f-44d3-9f4f-989f8a58b6cb',
				name: 'Account Name',
				valuePath: 'Account.name',
				minWidth: 120,
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
				isTotaled: false,
			},
			{
				id: 'adb53425-073a-4c25-9dc9-b4ca05c22bfe',
				name: 'Quantity',
				valuePath: 'quantityInContracts',
				cellComponent: CellComponents.String,
				minWidth: 80,
				textAlign: 'right',
				dark: true,
				isSortable: true,
				isFixed: '',
				isVisible: true,
				linkRoute: this.positionDetailRoute,
				linkModelPath: 'positionId',
				footerIsString: true,
				isTotaled: true,
			},
			{
				id: 'c290d213-5769-4847-822d-98d8be569578',
				name: 'Commodity',
				valuePath: 'Instrument.Product.name',
				minWidth: 120,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: false,
				isTotaled: false,
			},
			{
				id: '50fc2915-7be9-4dd9-835a-0b3e6612fd16',
				name: 'Side',
				valuePath: 'side',
				cellComponent: CellComponents.String,
				minWidth: 80,
				textAlign: 'left',
				dark: true,
				isSortable: true,
				isFixed: '',
				isVisible: true,
				isTotaled: false,
			},
			{
				id: 'bb725ece-739d-4868-9453-69c653c2d593',
				name: 'Type',
				valuePath: 'instrumentType',
				cellComponent: CellComponents.String,
				minWidth: 80,
				textAlign: 'left',
				dark: true,
				isSortable: true,
				isFixed: '',
				isVisible: true,
				isTotaled: false,
			},
			{
				id: 'c5adee17-8308-4f1f-a3e9-0089f427fe62',
				name: 'Symbol',
				valuePath: 'Instrument.exchangeSymbol',
				cellComponent: CellComponents.String,
				minWidth: 80,
				textAlign: 'left',
				dark: true,
				isSortable: true,
				isFixed: '',
				isVisible: true,
				isTotaled: false,
			},
			{
				id: '344a8a55-c5da-4460-bf82-76df47329b62',
				name: 'Strike',
				valuePath: 'Instrument.strike',
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'Instrument.symbolGroup.fractionDigits',
					displayFactorPath: 'Instrument.symbolGroup.displayFactor',
				},
				minWidth: 80,
				textAlign: 'right',
				dark: true,
				isSortable: true,
				isFixed: '',
				isVisible: true,
				isTotaled: false,
			},
			{
				id: '5e42e24d-4759-4ebb-9bcf-dfa567513954',
				name: 'Avg. Trade Price',
				valuePath: 'currentWeightedAveragePrice',
				minWidth: 140,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'Instrument.symbolGroup.fractionDigits',
					displayFactorPath: 'Instrument.symbolGroup.displayFactor',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				isTotaled: false,
			},
			{
				id: '4dcd735b-0361-45f0-b5e7-b5a9febf36a2',
				name: 'Last Price',
				valuePath: 'marketDataInstrument',
				minWidth: 120,
				cellComponent: CellComponents.MarketPriceFormat,
				componentArgs: {
					fractionDigitsPath: 'Instrument.symbolGroup.fractionDigits',
					displayFactorPath: 'Instrument.symbolGroup.displayFactor',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: false,
				isTotaled: false,
			},
			{
				id: 'd6d0e4f0-4c36-4f66-b4b4-2942e1b29a93',
				name: 'Session Change',
				valuePath: 'marketDataInstrument',
				minWidth: 150,
				cellComponent: CellComponents.SessionChangeFormat,
				componentArgs: {
					fractionDigitsPath: 'Instrument.symbolGroup.fractionDigits',
					displayFactorPath: 'Instrument.symbolGroup.displayFactor',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: false,
				isTotaled: false,
			},
			{
				id: 'bb34a490-1ad3-43fb-86e6-26515adee7da',
				name: 'Unrealized P/L (EOD)',
				valuePath: 'unrealizedPl',
				minWidth: 180,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
				footerIsString: false,
			},
			{
				id: 'e2558e75-7567-44ab-8352-148b27374d6f',
				name: 'Realized P/L (EOD)',
				valuePath: 'realizedPl',
				minWidth: 160,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
				footerIsString: false,
			},
			{
				id: '06146803-5674-4cca-be58-f89d9082586e',
				name: 'Net P/L (EOD)',
				valuePath: 'netPl',
				minWidth: 120,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
				footerIsString: false,
			},
		];
	}

	get cropDetailColumns(): TableColumn[] {
		return [
			{
				id: 'aef7abb1-2df0-41a6-bee6-11867972738a',
				name: 'Crop',
				valuePath: 'label',
				cellComponent: CellComponents.String,
				linkModelPath: 'id',
				minWidth: 130,
				textAlign: 'left',
				dark: true,
				isSortable: true,
				isFixed: '',
				isVisible: true,
				footerIsString: true,
				linkRoute: 'businesses.business.crop-detail',
				linkQuery: {
					startDate: {
						staticValue: this.startDate,
					},
					endDate: {
						staticValue: this.endDate,
					},
				},
			},
			{
				id: 'df8fa3be-9871-4325-8864-d187f2c88084',
				name: 'Harvest Month',
				valuePath: 'harvestMonth',
				cellComponent: CellComponents.String,
				minWidth: 130,
				textAlign: 'left',
				light: true,
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '45710749-ac85-4c4d-b0b1-27c9de671d85',
				name: 'Acres',
				valuePath: 'acres',
				cellComponent: CellComponents.String,
				minWidth: 130,
				textAlign: 'right',
				light: true,
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'a0ed6d0d-5aa4-41ac-993e-1f2da0ae3155',
				name: 'Production/Ac',
				valuePath: 'yieldPerAcre',
				cellComponent: CellComponents.String,
				minWidth: 130,
				textAlign: 'right',
				light: true,
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '41ef56dc-e250-48e9-9ade-8b0c8b833542',
				name: '% Sold',
				valuePath: 'percentSold',
				cellComponent: CellComponents.String,
				minWidth: 130,
				textAlign: 'right',
				light: true,
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '465dde1f-49f9-4f5b-9fff-9f18dd1e0965',
				name: 'Overall Revenue',
				valuePath: 'revenue',
				cellComponent: CellComponents.String,
				minWidth: 130,
				textAlign: 'right',
				light: true,
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'b52947a7-0dd1-4c1b-b597-efb5d6878eef',
				name: 'Expenses',
				valuePath: 'expenses',
				cellComponent: CellComponents.String,
				minWidth: 130,
				textAlign: 'right',
				light: true,
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '6e2df29d-6f0a-46d1-8019-4cc45b78d124',
				name: 'Net P/L',
				valuePath: 'netPnl',
				cellComponent: CellComponents.String,
				minWidth: 130,
				textAlign: 'right',
				light: true,
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
		];
	}

	get currentPositions(): CurrentPosition[] {
		return this.model.getCurrentPositions.data?.CurrentPositions ?? [];
	}

	get aggregateCurrentPositions(): AggregateCurrentPositionDTO[] {
		return this.model.getCurrentPositions.data?.AggregateCurrentPositions ?? [];
	}

	get cornPositionRows() {
		const cornPositions = this.getCurrentPositionsForSlugs(this.currentPositions, cornCategorySlugs);
		return itemsFn(cornPositions, getOwner(this));
	}

	get cornPositionFooterRows() {
		const aggregateCurrentPositions = this.getAggregateCurrentPositionsForSlugs(this.aggregateCurrentPositions, cornCategorySlugs);
		return [getPositionsTotal(aggregateCurrentPositions)];
	}

	get soybeanPositionRows() {
		const soybeanPositions = this.getCurrentPositionsForSlugs(this.currentPositions, soyBeanCategorySlugs);
		return itemsFn(soybeanPositions, getOwner(this));
	}

	get soybeanPositionFooterRows() {
		const aggregateCurrentPositions = this.getAggregateCurrentPositionsForSlugs(this.aggregateCurrentPositions, soyBeanCategorySlugs);
		return [getPositionsTotal(aggregateCurrentPositions)];
	}

	get wheatPositionRows() {
		const wheatPositions = this.getCurrentPositionsForSlugs(this.currentPositions, wheatCategorySlugs);
		return itemsFn(wheatPositions, getOwner(this));
	}

	get wheatPositionFooterRows() {
		const aggregateCurrentPositions = this.getAggregateCurrentPositionsForSlugs(this.aggregateCurrentPositions, wheatCategorySlugs);
		return [getPositionsTotal(aggregateCurrentPositions)];
	}

	get isSubmitting(): boolean {
		return this.submitCreateCropForm.isRunning;
	}

	get disableSubmitButton(): boolean {
		const { cropCategory, name, defaultPrice } = this.createCropFormData;
		return this.isSubmitting || cropCategory === null || name === '' || defaultPrice === null || defaultPrice.trim() === '';
	}

	submitCreateCropForm = task({ drop: true }, async () => {
		if (!isFormValid(document)) {
			getInvalidElements(document);
			return;
		}

		let validatedData;

		try {
			validatedData = parseCropData(this.createCropFormData);
			await createCrop(this, { data: validatedData });
			this.closeSidePanel();
		} catch (error) {
			set(this.createCropFormData, 'error', error.message);
			return;
		}
	});

	// Show specific subset of commodities
	// For soybeans, corn, and soybean oil, show most current future and last for the year
	readonly marketPriceGroupCommodities = {
		ShowMostCurrentFuture: [
			'grain-chicago-soft-red-winter-wheat',
			'grain-hard-red-spring-wheat',
			'grain-hrwi-hard-red-winter-wheat',
			'grain-kansas-city-hard-red-wheat',
		],
		ShowMostCurrentFutureAndLastForYear: ['grain-soybeans', 'grain-soybean-oil', 'grain-corn'],
	};

	get marketPriceGroups() {
		const futures: Future[] = [];

		this.marketPriceGroupCommodities.ShowMostCurrentFuture.forEach((slug) => {
			const mostCurrentFuture = this.getMostCurrentFutureForSlug(slug);
			if (mostCurrentFuture) {
				futures.push(mostCurrentFuture);
			}
		});

		this.marketPriceGroupCommodities.ShowMostCurrentFutureAndLastForYear.forEach((slug) => {
			const mostCurrentFuture = this.getMostCurrentFutureForSlug(slug);
			if (mostCurrentFuture) {
				futures.push(mostCurrentFuture);
			}

			const lastFutureForYear = this.getLastFutureOfThisYearForSlug(slug);
			if (lastFutureForYear) {
				futures.push(lastFutureForYear);
			}
		});

		return [
			{
				name: 'Futures',
				prices: futures.sort(
					(
						{ displayExpiresAt: aDisplayExpiresAt, Product: { name: aProductName } },
						{ displayExpiresAt: bDisplayExpiresAt, Product: { name: bProductName } },
					) =>
						// Sort by product name ascending, then displayExpiresAt ascending
						aProductName.localeCompare(bProductName)
							? aProductName.localeCompare(bProductName)
							: aDisplayExpiresAt.localeCompare(bDisplayExpiresAt),
				),
			},
		];
	}

	calculateTotalProduction(crops: Crop[]): number {
		return crops.reduce((total, crop) => {
			const harvestTotal =
				crop.CropHarvests?.reduce((harvestSum, harvest) => {
					return harvestSum + harvest.acres * harvest.yieldPerAcre;
				}, 0) ?? 0;
			return total + harvestTotal;
		}, 0);
	}

	get cornTotalProduction(): number {
		return this.calculateTotalProduction(this.allCornCrops);
	}

	get soybeanTotalProduction(): number {
		return this.calculateTotalProduction(this.allSoyBeanCrops);
	}

	get wheatTotalProduction(): number {
		return this.calculateTotalProduction(this.allWheatCrops);
	}

	getCurrentPositionsForSlugs(currentPositions: CurrentPosition[], slugs: string[]): CurrentPosition[] {
		return currentPositions.filter((position) => slugs.includes(position.Instrument.Product.slug));
	}

	getAggregateCurrentPositionsForSlugs(
		aggregateCurrentPositions: AggregateCurrentPositionDTO[],
		slugs: string[],
	): AggregateCurrentPositionDTO[] {
		return aggregateCurrentPositions.filter((position) => {
			const positionSlug = position.Instrument?.Product?.slug;
			return positionSlug && slugs.includes(positionSlug);
		});
	}

	generateChartData(params: ChartDataParams) {
		const {
			cropTransactions,
			futuresTransactions,
			optionsTransactions,
			totalProduction,
			averageCashPrice,
			averageHtaPrice,
			averageBasisPrice,
			markToMarketPrice,
		} = params;

		// Physical amount should include both HTA and flat price contracts
		const flatPhysicalAmount = (['Flat'] as const)
			.flatMap((type) => getAllCropTransactionsByType(cropTransactions, type))
			.reduce((sum, transaction) => sum + (transaction.bushels ?? 0), 0);

		const htaPhysicalAmount = (['HTA'] as const)
			.flatMap((type) => getAllCropTransactionsByType(cropTransactions, type))
			.reduce((sum, transaction) => sum + (transaction.bushels ?? 0), 0);

		const basisPhysicalAmount = (['Basis'] as const)
			.flatMap((type) => getAllCropTransactionsByType(cropTransactions, type))
			.reduce((sum, transaction) => sum + (transaction.bushels ?? 0), 0);

		// Futures amount and avgPrice should only include net short positions
		const shortFuturesOrSwapTransactions = futuresTransactions.filter(isFutureOrSwap).filter(isShortPosition);
		const futuresAmount = shortFuturesOrSwapTransactions.reduce((sum, { unitQuantity }) => sum + Math.abs(unitQuantity), 0);
		const futuresAvgPrice = this.calculateAveragePriceForFuturesCurrentAllocationPositions(shortFuturesOrSwapTransactions);

		// Options amount should only include long puts/put swaptions
		const longPutsAndLongPutSwaptions = optionsTransactions.filter(isLongPutOrLongPutSwaption);
		const optionsAmount = longPutsAndLongPutSwaptions.reduce((sum, { unitQuantity }) => sum + unitQuantity, 0);
		const optionsAvgPrice = this.calculateAverageOptionsPrice(longPutsAndLongPutSwaptions);

		const totalHedgedAmount = flatPhysicalAmount + htaPhysicalAmount + basisPhysicalAmount + futuresAmount + optionsAmount;
		const unhedgedAmount = Math.max(totalProduction - totalHedgedAmount, 0);

		return [
			{
				type: 'Flat',
				amount: flatPhysicalAmount,
				avgPrice: averageCashPrice,
			},
			{
				type: 'HTA',
				amount: htaPhysicalAmount,
				avgPrice: averageHtaPrice,
			},
			{
				type: 'Basis',
				amount: basisPhysicalAmount,
				avgPrice: averageBasisPrice,
			},
			{
				type: 'Futures',
				amount: futuresAmount,
				avgPrice: futuresAvgPrice,
			},
			{
				type: 'Options',
				amount: optionsAmount,
				avgPrice: optionsAvgPrice,
			},
			{
				type: 'Unhedged',
				amount: unhedgedAmount,
				avgPrice: markToMarketPrice,
			},
		];
	}

	forecastedProduction(crop: Crop): number {
		return (
			this.model.getDashboardData.data?.CropHarvestedAndSoldVolumes.find((cropVolume) => cropVolume.cropId === crop.id)
				?.forecastedProductionInBu ?? 0
		);
	}

	@action
	soldUnitsTotal(crop: Crop): number {
		return getTotalBushelsForPricedCropTransactions(
			getAllCropTransactionsByCrop(crop, this.model.getDashboardData.data?.CropTransactions ?? []),
		);
	}

	@action
	unsoldUnitsTotal(crop: Crop): number {
		const unsoldUnits = this.forecastedProduction(crop) - this.soldUnitsTotal(crop);
		return unsoldUnits > 0 ? unsoldUnits : 0;
	}

	standardProductLotSpecification(hedgeProduct: Product) {
		return hedgeProduct?.StandardProductLotSpecification;
	}

	pointValue(product: Product): number {
		return this.standardProductLotSpecification(product)?.pointValue ?? 0;
	}

	lotSize(product: Product) {
		return this.standardProductLotSpecification(product)?.lotSize ?? 0;
	}

	getCropPrice(crop: Crop) {
		const { harvestYear } = this;
		const { price: defaultPrice, CropPrices = [] } = crop;

		return getCropPriceForHarvestYear(CropPrices, harvestYear)?.price ?? defaultPrice;
	}

	getCropPricingFuture(crop: Crop): Future | undefined {
		// Prefer harvestYearFuture, fallback to MostCurrentFuture
		const { cropHarvestYears } = this;
		return getHarvestYearFuture(crop, cropHarvestYears) ?? getMostCurrentFuture(crop);
	}

	getCropPricingFuturePrice(crop: Crop): number | null {
		const symbol = this.getCropPricingFuture(crop)?.barchartSymbol;

		if (!symbol) return null;

		return this.marketData.getLatestPrice(symbol);
	}

	getMarkToMarketPrice(crop: Crop): number | null {
		if (!crop || !crop.pricingMethodology) return null;
		return getCropMarketPricePerUnit(
			crop,
			crop.Category.HedgeProduct ? this.pointValue(crop.Category.HedgeProduct) : 0,
			crop.Category.HedgeProduct ? this.lotSize(crop.Category.HedgeProduct) : 0,
			this.getCropPricingFuturePrice(crop),
			this.harvestYear,
		);
	}

	@action
	unsoldMarkToMarketValue(crop: Crop): number | null {
		if (!crop || !crop.pricingMethodology) return null;
		const unsoldUnits = this.unsoldUnitsTotal(crop);
		const cropPricePerUnit = this.getMarkToMarketPrice(crop);

		if (cropPricePerUnit == null) return null;

		return unsoldUnits * cropPricePerUnit;
	}

	@action
	getWeightedAvgMarkToMarketPrice(crops: Crop[]): number {
		const { totalUnsoldUnits, totalValue } = crops.reduce(
			(acc, crop) => {
				acc.totalUnsoldUnits += this.unsoldUnitsTotal(crop);
				acc.totalValue += this.unsoldMarkToMarketValue(crop) ?? 0;
				return acc;
			},
			{
				totalUnsoldUnits: 0,
				totalValue: 0,
			},
		);
		return calculateAveragePrice({ totalValue, totalVolume: totalUnsoldUnits });
	}

	@action
	soldUnitsTotalPrice(crop: Crop): number {
		return getTotalPriceForPricedCropTransactions(
			getAllCropTransactionsByCrop(crop, this.model.getDashboardData.data?.CropTransactions ?? []),
			this.getCropPrice(crop),
			crop.pricingMethodology,
			crop.Category.HedgeProduct ? this.pointValue(crop.Category.HedgeProduct) : 0,
			crop.Category.HedgeProduct ? this.lotSize(crop.Category.HedgeProduct) : 0,
			this.getCropPricingFuturePrice(crop),
		);
	}

	get cornChartData() {
		return this.generateChartData({
			cropTransactions: this.cornCropTransactions,
			futuresTransactions: this.cornFuturesTransactions,
			optionsTransactions: this.cornOptionsTransactions,
			totalProduction: this.cornTotalProduction,
			averageCashPrice: this.cornAverageCashPrice,
			averageHtaPrice: this.cornAverageHtaPrice,
			averageBasisPrice: this.cornAverageBasisPrice,
			markToMarketPrice: this.cornMarkToMarketPrice,
		});
	}

	get soybeanChartData() {
		return this.generateChartData({
			cropTransactions: this.soybeanCropTransactions,
			futuresTransactions: this.soybeanFuturesTransactions,
			optionsTransactions: this.soybeanOptionsTransactions,
			totalProduction: this.soybeanTotalProduction,
			averageCashPrice: this.soybeanAverageCashPrice,
			averageHtaPrice: this.soybeanAverageHtaPrice,
			averageBasisPrice: this.soybeanAverageBasisPrice,
			markToMarketPrice: this.soybeanMarkToMarketPrice,
		});
	}

	get wheatChartData() {
		return this.generateChartData({
			cropTransactions: this.wheatCropTransactions,
			futuresTransactions: this.wheatFuturesTransactions,
			optionsTransactions: this.wheatOptionsTransactions,
			totalProduction: this.wheatTotalProduction,
			averageCashPrice: this.wheatAverageCashPrice,
			averageHtaPrice: this.wheatAverageHtaPrice,
			averageBasisPrice: this.wheatAverageBasisPrice,
			markToMarketPrice: this.wheatMarkToMarketPrice,
		});
	}

	get mostCurrentFutures(): Record<string, Future | undefined | null> {
		if (!this.model.getDashboardData.data?.MostCurrentFutures) return {};

		return {
			canola: this.model.getDashboardData.data?.MostCurrentFutures.find((product: Product) => product.slug === 'grain-canola')
				?.MostCurrentFuture,
			chicagoSoftRedWinterWheat: this.model.getDashboardData.data?.MostCurrentFutures.find(
				(product: Product) => product.slug === 'grain-chicago-soft-red-winter-wheat',
			)?.MostCurrentFuture,
			corn: this.model.getDashboardData.data?.MostCurrentFutures.find((product: Product) => product.slug === 'grain-corn')
				?.MostCurrentFuture,
			hardRedSpringWheat: this.model.getDashboardData.data?.MostCurrentFutures.find(
				(product: Product) => product.slug === 'grain-hard-red-spring-wheat',
			)?.MostCurrentFuture,
			hrwiHardRedWinterWheat: this.model.getDashboardData.data?.MostCurrentFutures.find(
				(product: Product) => product.slug === 'grain-hrwi-hard-red-winter-wheat',
			)?.MostCurrentFuture,
			kcHardRedWheat: this.model.getDashboardData.data?.MostCurrentFutures.find(
				(product: Product) => product.slug === 'grain-kansas-city-hard-red-wheat',
			)?.MostCurrentFuture,
			soybeanMeal: this.model.getDashboardData.data?.MostCurrentFutures.find((product: Product) => product.slug === 'grain-soybean-meal')
				?.MostCurrentFuture,
			soybeanOil: this.model.getDashboardData.data?.MostCurrentFutures.find((product: Product) => product.slug === 'grain-soybean-oil')
				?.MostCurrentFuture,
			soybeans: this.model.getDashboardData.data?.MostCurrentFutures.find((product: Product) => product.slug === 'grain-soybeans')
				?.MostCurrentFuture,
		};
	}

	getTransactionsByType(params: { categorySlugs: string[]; instrumentTypes: string[] }): CurrentAllocationPosition[] {
		const { categorySlugs, instrumentTypes } = params;

		const transactions =
			this.model.getDashboardData.data?.CurrentAllocationPositions?.filter(
				(position) => categorySlugs.includes(position.Product?.slug ?? '') && instrumentTypes.includes(position.Instrument.type),
			) ?? [];

		return transactions;
	}

	get cornFuturesTransactions() {
		return this.getTransactionsByType({
			categorySlugs: cornCategorySlugs,
			instrumentTypes: ['Future'],
		});
	}

	get cornOptionsTransactions() {
		return this.getTransactionsByType({
			categorySlugs: cornCategorySlugs,
			instrumentTypes: ['Option', 'Swaption', 'Swap'],
		});
	}

	get soybeanFuturesTransactions() {
		return this.getTransactionsByType({
			categorySlugs: soyBeanCategorySlugs,
			instrumentTypes: ['Future'],
		});
	}

	get soybeanOptionsTransactions() {
		return this.getTransactionsByType({
			categorySlugs: soyBeanCategorySlugs,
			instrumentTypes: ['Option', 'Swaption', 'Swap'],
		});
	}

	get wheatFuturesTransactions() {
		return this.getTransactionsByType({
			categorySlugs: wheatCategorySlugs,
			instrumentTypes: ['Future'],
		});
	}

	get wheatOptionsTransactions() {
		return this.getTransactionsByType({
			categorySlugs: wheatCategorySlugs,
			instrumentTypes: ['Option', 'Swaption', 'Swap'],
		});
	}

	getMostCurrentBarchartSymbol(categorySlugs: string[]): string | null {
		return (
			this.model.getDashboardData.data?.MostCurrentFutures?.find((product: Product) => categorySlugs.includes(product.slug))
				?.MostCurrentFuture?.barchartSymbol ?? null
		);
	}

	get cornMostCurrentBarchartSymbol() {
		return this.getMostCurrentBarchartSymbol(cornCategorySlugs);
	}

	get cornMarkToMarketPrice() {
		return this.getWeightedAvgMarkToMarketPrice(this.allCornCrops);
	}

	get soybeanMostCurrentBarchartSymbol() {
		return this.getMostCurrentBarchartSymbol(soyBeanCategorySlugs);
	}

	get soybeanMarkToMarketPrice() {
		return this.getWeightedAvgMarkToMarketPrice(this.allSoyBeanCrops);
	}

	get wheatMostCurrentBarchartSymbol() {
		return this.getMostCurrentBarchartSymbol(wheatCategorySlugs);
	}

	get wheatMarkToMarketPrice() {
		return this.getWeightedAvgMarkToMarketPrice(this.allWheatCrops);
	}

	calculateAverageOptionsPrice(optionsTransactions: CurrentAllocationPosition[]): number {
		const { totalValue, totalVolume } = optionsTransactions.filter(isLongPutOrLongPutSwaption).reduce(
			({ totalValue, totalVolume }, position) => {
				const { Instrument, unitQuantity, Product, openWeightedAveragePrice, positionSide } = position;

				// isShort should always be false. Added for the sake of completeness
				const isShort = positionSide === Side.Short;
				const lotSize = this.lotSize(Product);
				const pointValue = this.pointValue(Product);
				const strike = (Instrument as Option | Swaption).strike;
				const strikeInDollarsPerUnit =
					getFuturesPriceInDollarsPerUnit({
						futuresPrice: strike,
						lotSize,
						pointValue,
					}) ?? 0;
				const premium = openWeightedAveragePrice;
				const premiumInDollarsPerUnit =
					getFuturesPriceInDollarsPerUnit({
						futuresPrice: premium,
						lotSize,
						pointValue,
					}) ?? 0;

				// If short, the premium was received so we flip the sign
				const adjustedPremiumInDollarsPerUnit = isShort ? -premiumInDollarsPerUnit : premiumInDollarsPerUnit;
				const volume = unitQuantity;
				const value = (strikeInDollarsPerUnit - adjustedPremiumInDollarsPerUnit) * unitQuantity;

				return {
					totalValue: totalValue + value,
					totalVolume: totalVolume + volume,
				};
			},
			{ totalValue: 0, totalVolume: 0 },
		);

		return calculateAveragePrice({ totalValue, totalVolume });
	}

	getFuturesPriceForCurrentAllocationPosition(position: CurrentAllocationPosition): number | null {
		const { instrumentType, openWeightedAveragePrice } = position;
		if (instrumentType !== TypeOfInstrument.Future) return null;

		const { barchartSymbol } = position.Instrument as Future;

		if (openWeightedAveragePrice != undefined) return openWeightedAveragePrice;

		if (barchartSymbol != undefined && barchartSymbol.length) {
			return this.marketData.getEODPrice(barchartSymbol) ?? null;
		}

		return null;
	}

	calculateTotalValueAndTotalVolumeForFuturesCurrentAllocationPositions(futurePositions: CurrentAllocationPosition[]): {
		totalValue: number;
		totalVolume: number;
	} {
		// Should only include short futures and swap positions
		return futurePositions
			.filter(isFutureOrSwap)
			.filter(isShortPosition)
			.reduce(
				({ totalVolume, totalValue }, position) => {
					const { unitQuantity, Product } = position;
					const lotSize = this.lotSize(Product);
					const pointValue = this.pointValue(Product);
					const futuresPrice = this.getFuturesPriceForCurrentAllocationPosition(position);
					const futuresPriceInDollarsPerUnit = getFuturesPriceInDollarsPerUnit({
						futuresPrice,
						lotSize,
						pointValue,
					});

					// Take absolute value of quantity to handle short positions
					const volume = Math.abs(unitQuantity);
					const value = (futuresPriceInDollarsPerUnit ?? 0) * volume;

					return {
						totalVolume: totalVolume + volume,
						totalValue: totalValue + value,
					};
				},
				{ totalValue: 0, totalVolume: 0 },
			);
	}

	calculateAveragePriceForFuturesCurrentAllocationPositions(futurePositions: CurrentAllocationPosition[]) {
		return calculateAveragePrice(this.calculateTotalValueAndTotalVolumeForFuturesCurrentAllocationPositions(futurePositions));
	}

	/**
	 * @description
	 *
	 * - Flat Crop Transactions
	 * 	- Only include the futures price portion of the contract not basis
	 * 	- If the futures price is not provided, the contract is excluded from the calculation.
	 * - HTA Crop Transactions
	 * 	- Only include futures not estimated basis)
	 * - Futures
	 */
	calculateAverageFuturesPrice({
		futuresTransactions,
		flatCropTransactions,
		htaCropTransactions,
	}: {
		futuresTransactions: CurrentAllocationPosition[];
		flatCropTransactions: CropTransaction[];
		htaCropTransactions: CropTransaction[];
	}) {
		const { totalValue: futuresTotalValue, totalVolume: futuresTotalVolume } =
			this.calculateTotalValueAndTotalVolumeForFuturesCurrentAllocationPositions(futuresTransactions);

		const { totalValue: flatContractsTotalValue, totalVolume: flatContractsTotalVolume } =
			this.calculateTotalValueAndTotalVolumeForFlatCropTransactionsFuturesPriceOnly(flatCropTransactions);

		const { totalValue: htaContractsTotalValue, totalVolume: htaContractsTotalVolume } =
			this.calculateTotalValueAndTotalVolumeForHTACropTransactionsFuturesPriceOnly(htaCropTransactions);

		const totalValue = futuresTotalValue + flatContractsTotalValue + htaContractsTotalValue;
		const totalVolume = futuresTotalVolume + flatContractsTotalVolume + htaContractsTotalVolume;

		return calculateAveragePrice({ totalValue, totalVolume });
	}

	get soybeanAverageFuturesPrice() {
		const { soybeanFuturesTransactions, soybeanCropTransactions } = this;

		return this.calculateAverageFuturesPrice({
			futuresTransactions: soybeanFuturesTransactions,
			flatCropTransactions: getAllCropTransactionsByType(soybeanCropTransactions, 'Flat'),
			htaCropTransactions: getAllCropTransactionsByType(soybeanCropTransactions, 'HTA'),
		});
	}

	get wheatAverageFuturesPrice() {
		const { wheatFuturesTransactions, wheatCropTransactions } = this;

		return this.calculateAverageFuturesPrice({
			futuresTransactions: wheatFuturesTransactions,
			flatCropTransactions: getAllCropTransactionsByType(wheatCropTransactions, 'Flat'),
			htaCropTransactions: getAllCropTransactionsByType(wheatCropTransactions, 'HTA'),
		});
	}

	get cornAverageFuturesPrice() {
		const { cornFuturesTransactions, cornCropTransactions } = this;

		return this.calculateAverageFuturesPrice({
			futuresTransactions: cornFuturesTransactions,
			flatCropTransactions: getAllCropTransactionsByType(cornCropTransactions, 'Flat'),
			htaCropTransactions: getAllCropTransactionsByType(cornCropTransactions, 'HTA'),
		});
	}

	get projectedPnlChartRawData(): ProjectedNetPnlChartRawData {
		const { projectedRevenueChartRawData, projectedExpensesChartRawData, businessProjectedRevenue, businessProjectedExpensesAsAbsoluteValue } = this;

		return Object.assign(
			{},
			getProjectedNetPnlByCropGroup({
				projectedRevenueByCropGroup: projectedRevenueChartRawData,
				projectedExpensesByCropGroup: projectedExpensesChartRawData,
			}),
			{
				Business: getBusinessProjectedNetPnl({
					businessProjectedRevenue,
					businessProjectedExpenses: businessProjectedExpensesAsAbsoluteValue,
				}),
			},
		);
	}

	get businessProjectedRevenue(): BusinessProjectedRevenue {
		return getBusinessProjectedRevenue(this.model.getDashboardData.data?.AggregateRevenueLedgerEntries ?? []);
	}

	get projectedRevenueByCropGroup(): ProjectedRevenueByCropGroup {
		const { aggregateBrokeragePositions, cropsWithHarvests } = this;

		return getProjectedRevenueByCropGroup(cropsWithHarvests, {
			getBrokeragePnl: (slugs: ProductSlug[]) => getBrokeragePnl(aggregateBrokeragePositions, slugs),
			getPhysicalCropSales: this.soldUnitsTotalPrice,
			getUnsoldMarkToMarket: (crop: Crop) => this.unsoldMarkToMarketValue(crop) ?? 0,
			getAdditionalRevenue: (crop: Crop) => getAdditionalRevenue(crop, this.revenueAndExpensesPerType(crop, TypeOfCropFieldLedger.Revenue)),
		});
	}

	// Cached due to projectedRevenueByCropGroup being a bit expensive to compute
	@cached
	get projectedRevenueChartRawData(): ProjectedRevenueChartRawData {
		const { projectedRevenueByCropGroup, businessProjectedRevenue } = this;

		return Object.assign({}, projectedRevenueByCropGroup, {
			Business: businessProjectedRevenue,
		});
	}

	revenueAndExpensesPerType(crop: Crop, type: TypeOfCropFieldLedger): CropLedgerEntryPerHarvestYear[] {
		const cropRevenueAndExpenses: CropLedgerEntryPerHarvestYear[] =
			this.model.cropLedgerEntries.data?.CropLedgerEntriesPerHarvestYear.filter((entry: CropLedgerEntryPerHarvestYear) => {
				return entry.cropId === crop.id && entry.CropFieldLedgerCategory.type === type;
			}) ?? [];
		return cropRevenueAndExpenses;
	}

	allExpenseCategories(): Record<string, number[]> {
		let data: Record<string, number[]> = {};

		this.model.getDashboardData.data?.AllCrops.forEach((crop: Crop) => {
			const cropExpenses = this.revenueAndExpensesPerType(crop, TypeOfCropFieldLedger.Expense);

			cropExpenses.forEach((entry: CropLedgerEntryPerHarvestYear) => {
				if (!data[entry.CropFieldLedgerCategory.name]) {
					data[entry.CropFieldLedgerCategory.name] = new Array(this.model.getDashboardData.data?.AllCrops.length ?? 0).fill(0);
				}
			});
		});

		if (Object.keys(data).length > 5) {
			data = Object.keys(data)
				.slice(0, 5)
				.reduce((result: Record<string, number[]>, key) => {
					result[key] = data[key];
					return result;
				}, {});
			data['Other'] = new Array(this.model.getDashboardData.data?.AllCrops.length ?? 0).fill(0);
		}

		return data;
	}

	get businessProjectedExpenses(): BusinessProjectedExpenses {
		return getBusinessProjectedExpenses(this.model.getDashboardData.data?.AggregateExpenseLedgerEntries ?? []);
	}

	get businessProjectedExpensesAsAbsoluteValue(): BusinessProjectedExpenses {
		return getBusinessProjectedExpensesAsAbsoluteValue(this.model.getDashboardData.data?.AggregateExpenseLedgerEntries ?? []);
	}

	get projectedExpensesByCropGroup(): ProjectedExpensesByCropGroup {
		const { cropsWithHarvests } = this;

		return getProjectedExpensesByCropGroup(cropsWithHarvests, {
			getExpenseLedgerEntries: (crop: Crop) => this.revenueAndExpensesPerType(crop, TypeOfCropFieldLedger.Expense),
		});
	}

	get projectedExpensesChartRawData(): ProjectedExpensesChartRawData {
		const { projectedExpensesByCropGroup, businessProjectedExpensesAsAbsoluteValue } = this;

		return Object.assign({}, projectedExpensesByCropGroup, {
			Business: businessProjectedExpensesAsAbsoluteValue,
		});
	}

	@action
	openSidePanel() {
		this.isSidePanelOpen = true;
	}

	@action
	closeSidePanel() {
		this.emptyFormData();
		this.isSidePanelOpen = false;
	}

	@action
	emptyFormData() {
		this.createCropFormData = new TrackedObject({
			businessId: this.model?.businessId ?? '',
			categoryId: '',
			cropCategory: null as CropCategory | null,
			name: '',
			defaultPrice: '',
			error: '',
			cropPriceType: TYPE_OF_CROP_PRICING_LABELS[CropPricingMethodology.Flat],
		}) as CreateCropData;
	}

	@action
	updateCreateCropFormData(key: keyof CreateCropData, value: CreateCropData[keyof CreateCropData]) {
		set(this.createCropFormData, key, value);
		return;
	}

	@action
	toggleCornTable() {
		this.showCornPercentHedgedTable = !this.showCornPercentHedgedTable;

		if (checkStorageAvailable('localStorage')) {
			window.localStorage.setItem(`feed-overview-hedgedCornChart.${this.model.businessId}`, this.showCornPercentHedgedTable.toString());
		}
	}

	@action
	toggleSoybeanTable() {
		this.showSoybeanPercentHedgedTable = !this.showSoybeanPercentHedgedTable;

		if (checkStorageAvailable('localStorage')) {
			window.localStorage.setItem(
				`feed-overview-hedgedSoybeanChart.${this.model.businessId}`,
				this.showSoybeanPercentHedgedTable.toString(),
			);
		}
	}

	@action
	toggleWheatTable() {
		this.showWheatPercentHedgedTable = !this.showWheatPercentHedgedTable;

		if (checkStorageAvailable('localStorage')) {
			window.localStorage.setItem(`feed-overview-hedgedWheatChart.${this.model.businessId}`, this.showWheatPercentHedgedTable.toString());
		}
	}

	@action
	setTimePeriod(option: UiDateFilterOption) {
		this.startDate = option.startDate;
		this.endDate = option.endDate;
	}

	getMostCurrentFutureForSlug(productSlug: string) {
		return this.model.getDashboardData.data?.MostCurrentFutures.find((p) => p.slug === productSlug)?.MostCurrentFuture;
	}

	getLastFutureOfThisYearForSlug(productSlug: string) {
		const lastDayOfThisYear = DateTime.now().endOf('year').toISODate();
		const sortedFutures = [
			...(this.model.getDashboardData.data?.MostCurrentFutures.find((p) => p.slug === productSlug)?.CurrentFutures ?? []),
		].sort((a, b) => (a.displayExpiresAt < b.displayExpiresAt ? -1 : 1));

		// Find the first future of next year
		const firstFutureOfNextYearIndex = sortedFutures.findIndex((future) => future.displayExpiresAt > lastDayOfThisYear);

		// Return the future before the first future of next year.
		// If firstFutureOfNextYearIndex is 0, return undefined
		return sortedFutures[firstFutureOfNextYearIndex - 1];
	}

	/**
	 * Calculates the net P/L for a crop or array of crops, excluding brokerage P/L.
	 * Revenue (Flat + HTA + Basis + MTM + Additional) - Expenses (Crop + Field)
	 * @param crop - The crop or array of crops to calculate net P/L for
	 * @returns The total net P/L value excluding brokerage P/L
	 */
	calculateCropNetPnlWithoutBrokerage(crop: Crop): number;
	calculateCropNetPnlWithoutBrokerage(crop: Crop[]): number;
	calculateCropNetPnlWithoutBrokerage(crop: Crop | Crop[]): number;
	calculateCropNetPnlWithoutBrokerage(crop: Crop | Crop[]): number {
		// Handle array of crops
		if (Array.isArray(crop)) {
			return crop.reduce((sum, c) => sum + this.calculateCropNetPnlWithoutBrokerage(c), 0);
		}

		const harvestedAcres = getHarvestedAcres(crop);

		// Calculate additional revenue from ledger entries
		const cropRevenues = this.revenueAndExpensesPerType(crop, TypeOfCropFieldLedger.Revenue);
		const additionalRevenue = calculateCropLedgerEntriesTotalValue(cropRevenues, harvestedAcres);

		// Calculate additional expenses from ledger entries
		const cropExpenses = this.revenueAndExpensesPerType(crop, TypeOfCropFieldLedger.Expense);
		const additionalExpenses = calculateCropLedgerEntriesTotalValue(cropExpenses, harvestedAcres);

		// Calculate sales revenue
		const soldUnitsTotalPrice = this.soldUnitsTotalPrice(crop);

		// Calculate unsold inventory value at market price
		const markToMarketValue = this.unsoldMarkToMarketValue(crop) ?? 0;

		// Calculate field level P/L
		const { totalRevenue: fieldLevelRevenue } = getFieldLevelRevenueComponents(crop);
		const { totalExpenses: fieldLevelExpenses } = getFieldLevelExpenseComponents(crop);

		// Calculate total revenue and expenses
		const totalRevenue = markToMarketValue + soldUnitsTotalPrice + additionalRevenue + fieldLevelRevenue;
		const totalExpenses = additionalExpenses + fieldLevelExpenses;

		// Return net P/L
		return totalRevenue - totalExpenses;
	}

	/**
	 * Calculates the net P/L for a crop or array of crops, including brokerage P/L and field level P/L.
	 * calculateNetPnlPerUnitWithoutBrokerage + Brokerage P/L
	 * @param crop - The crop or array of crops to calculate net P/L for
	 * @returns The total net P/L in dollars including brokerage P/L
	 */
	calculateNetPnl(crop: Crop): number;
	calculateNetPnl(crop: Crop[]): number;
	calculateNetPnl(crop: Crop | Crop[]): number;
	calculateNetPnl(crop: Crop | Crop[]): number {
		// Transform crop to array
		const crops = Array.isArray(crop) ? crop : [crop];

		if (!crops.length) return 0;

		// Calculate net P/L without brokerage
		const netPnlWithoutBrokerage = this.calculateCropNetPnlWithoutBrokerage(crops);

		// Calculate brokerage P/L
		const productSlugs = getUniqueProductSlugs(crops);
		const brokeragePnl = this.getBrokeragePnL(productSlugs);

		// Return net P/L
		return netPnlWithoutBrokerage + brokeragePnl;
	}

	/**
	 * Calculates the net P/L per unit for a crop or array of crops.
	 * @param crop - The crop or array of crops to calculate net P/L per unit for
	 * @returns The total net P/L per unit in dollars
	 */
	calculateNetPnlPerUnit(crop: Crop): number;
	calculateNetPnlPerUnit(crop: Crop[]): number;
	calculateNetPnlPerUnit(crop: Crop | Crop[]): number;
	calculateNetPnlPerUnit(crop: Crop | Crop[]): number {
		// Transform crop to array
		const crops = Array.isArray(crop) ? crop : [crop];

		if (!crops.length) return 0;

		const totalNetPnl = this.calculateNetPnl(crops);
		const totalProduction = this.calculateTotalProduction(crops);

		return safeDivideZero(totalNetPnl, totalProduction);
	}

	getBrokeragePnL(slugs: ProductSlug[]) {
		const { aggregateBrokeragePositionsBySlug } = this;
		const aggregateBrokeragePositions = slugs.flatMap((slug) => aggregateBrokeragePositionsBySlug[slug]).filter(isDefined);
		return getBrokeragePnL(aggregateBrokeragePositions);
	}
}
