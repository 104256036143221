import type Owner from '@ember/owner';
import FuturePosition from 'vault-client/models/future-position';
import OptionPosition from 'vault-client/models/option-position';
import Position from 'vault-client/models/position';
import SwapPosition from 'vault-client/models/swap-position';
import SwaptionPosition from 'vault-client/models/swaption-position';
import {
	CurrentPosition,
	Future,
	Option,
	Swap,
	Swaption,
	type AggregateCurrentPositionDTO,
	type Maybe,
} from 'vault-client/types/graphql-types';
import { safeSum } from '../precision-math';

type InstrumentWithTypename = (Future | Option | Swap | Swaption) & {
	__typename: 'Future' | 'Option' | 'Swap' | 'Swaption';
};

const positionTotalKeys = ['quantityInContracts', 'unrealizedPl', 'realizedPl', 'netPl'] as const satisfies (keyof Position)[];
type PositionsTotal = {
	[key in (typeof positionTotalKeys)[number]]: Maybe<number>;
};

function itemsFn(rows: CurrentPosition[], owner: Owner): Position[] {
	return rows
		.filter(
			(row): row is CurrentPosition & { Instrument: InstrumentWithTypename } =>
				row.Instrument !== null && row.Instrument !== undefined && '__typename' in row.Instrument,
		)
		.map((row) => {
			switch (row.Instrument.__typename) {
				case 'Future':
					return new FuturePosition(owner, row);
				case 'Option':
					return new OptionPosition(owner, row);
				case 'Swap':
					return new SwapPosition(owner, row);
				case 'Swaption':
					return new SwaptionPosition(owner, row);
				default:
					return undefined;
			}
		})
		.filter((position): position is FuturePosition | OptionPosition | SwapPosition | SwaptionPosition => position !== undefined);
}

function getPositionsTotal(aggregateCurrentPositions: AggregateCurrentPositionDTO[]): PositionsTotal {
	return aggregateCurrentPositions.reduce<PositionsTotal>(
		(total, { sum: { quantity, unrealizedPnl, realizedPnl, feeTotal, commissionTotal } }) => {
			const netPnl = safeSum(unrealizedPnl, realizedPnl, feeTotal, commissionTotal);
			total.quantityInContracts = safeSum(total.quantityInContracts, quantity);
			total.unrealizedPl = safeSum(total.unrealizedPl, unrealizedPnl);
			total.realizedPl = safeSum(total.realizedPl, realizedPnl);
			total.netPl = safeSum(total.netPl, netPnl);
			return total;
		},
		positionTotalKeys.reduce(
			(acc, key) => {
				acc[key] = null;
				return acc;
			},
			{} as Record<keyof PositionsTotal, Maybe<number>>,
		),
	);
}

export { itemsFn, getPositionsTotal };
