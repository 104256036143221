import CattleController from 'vault-client/controllers/cattle/index';
import BusinessesBusinessRoute from 'vault-client/routes/businesses/business';
import { ModelFrom } from 'vault-client/utils/type-utils';

export default class BusinessesBusinessCattleController extends CattleController {
	lotOverviewPath = 'businesses.business.cattle.lots.index';
	yardOverviewPath = 'businesses.business.cattle.yards';
	rationOverviewPath = 'businesses.business.cattle.rations.index';

	declare model: ModelFrom<BusinessesBusinessRoute>;

	get businessName() {
		return this.model.getCustomer.data?.Customer?.name;
	}

	get pageTitle() {
		return `Cattle | ${this.businessName}`;
	}
}
