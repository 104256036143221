import { gql } from 'glimmer-apollo';
import type {
	CurrentAllocationPositionAggregateDTO,
	CurrentAllocationPositionGroupByDTO,
	CurrentAllocationPositionFilterDTO,
	Query,
} from 'vault-client/types/graphql-types';

const GET_AGGREGATE_CURRENT_ALLOCATION_POSITIONS = gql`
	query GetAggregateCurrentAllocationPositions(
		$allocationPositionsCalc: CurrentAllocationPositionAggregateDTO!
		$allocationPositionsGroupBy: CurrentAllocationPositionGroupByDTO!
		$allocationPositionsWhere: CurrentAllocationPositionFilterDTO!
	) {
		AggregateCurrentAllocationPositions(
			calc: $allocationPositionsCalc
			groupBy: $allocationPositionsGroupBy
			where: $allocationPositionsWhere
		) {
			sum {
				grossPnl
				unitQuantity
			}
			Product {
				id
				slug
			}
		}
	}
`;

type GetAggregateCurrentAllocationPositionsVariables = {
	allocationPositionsCalc: CurrentAllocationPositionAggregateDTO;
	allocationPositionsGroupBy: CurrentAllocationPositionGroupByDTO;
	allocationPositionsWhere: CurrentAllocationPositionFilterDTO;
};

type GetAggregateCurrentAllocationPositionsQuery = {
	AggregateCurrentAllocationPositions: Query['AggregateCurrentAllocationPositions'];
};

export {
	GET_AGGREGATE_CURRENT_ALLOCATION_POSITIONS,
	type GetAggregateCurrentAllocationPositionsVariables,
	type GetAggregateCurrentAllocationPositionsQuery,
};
