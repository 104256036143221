import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import { DateTime } from 'luxon';
import {
	CurrentAllocationPosition,
	CurrentAllocationPositionFilterDTO,
	CurrentPositionAggregateDTO,
	CurrentPositionFilterDTO,
	CurrentPositionGroupByDTO,
	Future,
	FutureFilterDTO,
	InsuranceEndorsementAllocationRatioFilterDTO,
	Option,
	Query,
	Swap,
	Swaption,
	SwineLivestockPopulationChangeFilterDTO,
	TypeOfInstrument,
	TypeOfInsuranceEndorsement,
	TypeOfLivestockPopulationChangeReason,
} from 'vault-client/types/graphql-types';
import Route from '@ember/routing/route';
import { formatISO, subWeeks, startOfWeek, parseISO } from 'date-fns';
import HypotheticalPosition, { HypotheticalPositionJsonFormat } from 'vault-client/models/hypothetical-position';
import checkStorageAvailable from 'vault-client/utils/check-storage-available';
import { getOwner } from '@ember/application';
import { calculateRealizedPnlAndUnrealizedComponents } from 'vault-client/utils/price-scenarios-utils';
import { TrackedArray } from 'tracked-built-ins';
import type BusinessesBusinessRoute from '../businesses/business';
import type { ModelFrom } from 'vault-client/utils/type-utils';
import ENV from 'vault-client/config/environment';

const GET_SWINE_PRICE_SCENARIOS_DATA = gql`
	query PriceScenarios(
		$scopeId: String!
		$futuresWhere: FutureFilterDTO
		$startDate: String
		$endDate: String
		$swineSalesPurchasesAndProducedWhere: SwineLivestockPopulationChangeFilterDTO
		$allocatedFuturePositionsWhere: CurrentAllocationPositionFilterDTO
		$allocatedOptionPositionsWhere: CurrentAllocationPositionFilterDTO
		$allocatedSwapPositionsWhere: CurrentAllocationPositionFilterDTO
		$allocatedSwaptionPositionsWhere: CurrentAllocationPositionFilterDTO
		$insuranceWhere: InsuranceEndorsementAllocationRatioFilterDTO
	) {
		Customer(id: $scopeId) {
			id
			averageFinishAgeInWeeks
			averageFinishWeightInLbs
		}
		Futures(where: $futuresWhere, orderBy: { displayExpiresAt: Asc }) {
			id
			displayExpiresAt
			barchartSymbol
			SymbolGroup {
				id
				fractionDigits
				displayFactor
			}
			Product {
				id
				name
				slug
				StandardProductLotSpecification {
					id
					lotSize
					pointValue
				}
			}
		}
		AllocatedFuturePositions: CurrentAllocationPositions(
			where: $allocatedFuturePositionsWhere
			limit: 2000
			orderBy: { effectiveHedgeDate: Asc }
		) {
			id
			effectiveHedgeDate
			contractQuantity
			instrumentType
			optionType
			grossPnl
			lifetimeWeightedAveragePrice
			Product {
				id
				slug
				ProductLotSpecifications {
					id
					lotSize
					pointValue
				}
			}
			Instrument {
				... on Future {
					id
					displayExpiresAt
					barchartSymbol
					productLotSpecificationId
				}
			}
			PositionComponentAllocations {
				id
				netContractQuantity
				longContractQuantity
				shortContractQuantity
				price
				effectiveHedgeDate
			}
		}
		AllocatedOptionPositions: CurrentAllocationPositions(
			where: $allocatedOptionPositionsWhere
			limit: 2000
			orderBy: { effectiveHedgeDate: Asc }
		) {
			id
			effectiveHedgeDate
			contractQuantity
			instrumentType
			optionType
			grossPnl
			lifetimeWeightedAveragePrice
			Product {
				id
				slug
				ProductLotSpecifications {
					id
					lotSize
					pointValue
				}
			}
			Instrument {
				... on Option {
					id
					optionType
					strike
					productLotSpecificationId
					UnderlyingInstrument {
						id
						displayExpiresAt
						barchartSymbol
					}
				}
			}
			PositionComponentAllocations {
				id
				netContractQuantity
				longContractQuantity
				shortContractQuantity
				price
				effectiveHedgeDate
			}
		}
		AllocatedSwapPositions: CurrentAllocationPositions(
			where: $allocatedSwapPositionsWhere
			limit: 2000
			orderBy: { effectiveHedgeDate: Asc }
		) {
			id
			effectiveHedgeDate
			contractQuantity
			instrumentType
			optionType
			grossPnl
			lifetimeWeightedAveragePrice
			Product {
				id
				slug
				ProductLotSpecifications {
					id
					lotSize
					pointValue
				}
			}
			Instrument {
				... on Swap {
					id
					productLotSpecificationId
					PriceInstrument {
						... on Future {
							id
							displayExpiresAt
							barchartSymbol
						}
					}
				}
			}
			PositionComponentAllocations {
				id
				netContractQuantity
				longContractQuantity
				shortContractQuantity
				price
				effectiveHedgeDate
			}
		}
		AllocatedSwaptionPositions: CurrentAllocationPositions(
			where: $allocatedSwaptionPositionsWhere
			limit: 2000
			orderBy: { effectiveHedgeDate: Asc }
		) {
			id
			effectiveHedgeDate
			contractQuantity
			instrumentType
			optionType
			grossPnl
			lifetimeWeightedAveragePrice
			Product {
				id
				slug
				ProductLotSpecifications {
					id
					lotSize
					pointValue
				}
			}
			Instrument {
				... on Swaption {
					id
					optionType
					strike
					productLotSpecificationId
					PriceInstrument {
						... on Future {
							id
							displayExpiresAt
							barchartSymbol
						}
					}
				}
			}
			PositionComponentAllocations {
				id
				netContractQuantity
				longContractQuantity
				shortContractQuantity
				price
				effectiveHedgeDate
			}
		}
		AggregateExpenseLedgerEntries: AggregateLedgerEntries(
			calc: { sum: { calculatedAmount: true } }
			groupBy: { month: true, year: true, id: true }
			where: {
				entityId: { equals: $scopeId }
				LedgerCategory: { type: { equals: Expense } }
				AND: [{ date: { gte: $startDate, lte: $endDate } }]
			}
		) {
			id
			sum {
				calculatedAmount
			}
			month
			year
			type
		}
		AggregateRevenueLedgerEntries: AggregateLedgerEntries(
			calc: { sum: { calculatedAmount: true, calculationQuantity: true } }
			groupBy: { month: true, year: true }
			where: {
				entityId: { equals: $scopeId }
				LedgerCategory: { type: { equals: Revenue } }
				AND: [{ date: { gte: $startDate } }, { date: { lte: $endDate } }]
			}
		) {
			sum {
				calculatedAmount
				calculationQuantity
			}
			month
			year
			calculatedAmount
			calculationQuantity
			type
		}

		InsuranceEndorsementAllocationRatios(scopeId: $scopeId, where: $insuranceWhere, limit: 2000) {
			id
			insuranceEndorsementId
			allocationRatio
			effectiveHedgeDate
			insurancePolicyId
			RatioAdjustedInsuranceEndorsement {
				id
				InsurancePolicy {
					id
					producerName
				}

				... on LgmInsuranceEndorsement {
					RmaType {
						id
						typeName
						typeCode
					}
					id
					perMonthData
					type
					coverageMonths
					grossMarginGuarantee
					totalTarget
					totalPremiumAmount
					totalExpectedGrossMargin
					pnl
					producerPremiumAmount
					deductibleAmount
					salesEffectiveDate
					revenueHedgeProductId
				}
				... on LrpInsuranceEndorsement {
					InsurancePolicy {
						id
						producerName
					}
					RmaCommodity {
						id
						commodityName
					}
					coverageEndDate
					coveragePrice
					salesEffectiveDate
					type
					totalPremiumAmount
					pnl
					commodityPrice
					insuredValue
					headCount
					revenueHedgeProductId
					targetWeightCwt
				}
			}
		}
		SwineSalesPurchasesAndProduced: AggregateSwineLivestockPopulationChanges(
			calc: { sum: { quantity: true, totalValue: true } }
			groupBy: { date: true, reasonType: true, valueType: true, dob: true }
			where: $swineSalesPurchasesAndProducedWhere
		) {
			date
			dob
			reasonType
			valueType
			totalValue
			count
			quantity
			valuePerHead
			sum {
				quantity
				totalValue
			}
		}
		# How do we change this with feed?
		AggregateForecastedSwineLivestockFeedUsageAtFinish(
			calc: { sum: { quantityInLbs: true, totalExpenseInUsd: true, quantityInTons: true } }
			groupBy: { firstDateOfMonth: true }
			scopeId: $scopeId
		) {
			firstDateOfMonth
			sum {
				quantityInLbs
				quantityInTons
				totalExpenseInUsd
			}
		}
	}
`;

type GetSwinePriceScenariosDataQueryVariables = {
	futuresWhere?: FutureFilterDTO;
	scopeId?: string;
	startDate?: string;
	endDate?: string;
	swineSalesPurchasesAndProducedWhere?: SwineLivestockPopulationChangeFilterDTO;
	aggregateCurrentPositionsCalc?: CurrentPositionAggregateDTO;
	aggregateCurrentPositionsGroupBy?: CurrentPositionGroupByDTO;
	aggregateCurrentPositionsWhere?: CurrentPositionFilterDTO;
	allocatedFuturePositionsWhere?: CurrentAllocationPositionFilterDTO;
	allocatedOptionPositionsWhere?: CurrentAllocationPositionFilterDTO;
	allocatedSwapPositionsWhere?: CurrentAllocationPositionFilterDTO;
	allocatedSwaptionPositionsWhere?: CurrentAllocationPositionFilterDTO;
	insuranceWhere?: InsuranceEndorsementAllocationRatioFilterDTO;
};

type GetSwinePriceScenariosDataQuery = {
	__typename?: 'Query';
	Futures: Query['Futures'];
	Customer: Query['Customer'];
	CurrentAllocationPositions: Query['CurrentAllocationPositions'];
	AllocatedFuturePositions: Query['CurrentAllocationPositions'];
	AllocatedOptionPositions: Query['CurrentAllocationPositions'];
	AllocatedSwapPositions: Query['CurrentAllocationPositions'];
	AllocatedSwaptionPositions: Query['CurrentAllocationPositions'];
	AggregateExpenseLedgerEntries: Query['AggregateLedgerEntries'];
	AggregateRevenueLedgerEntries: Query['AggregateLedgerEntries'];
	AggregateLrpInsuranceEndorsements: Query['AggregateLrpInsuranceEndorsements'];
	SwineSalesPurchasesAndProduced: Query['AggregateSwineLivestockPopulationChanges'];
	AggregateForecastedSwineLivestockFeedUsageAtFinish: Query['AggregateForecastedSwineLivestockFeedUsageAtFinish'];
	InsuranceEndorsementAllocationRatios: Query['InsuranceEndorsementAllocationRatios'];
};

type ModelParams = {
	startDate: string;
	endDate: string;
};

export default class BusinessBusinessToolsSwinePriceScenariosRoute extends Route {
	@tracked variables: GetSwinePriceScenariosDataQueryVariables = {};

	getSwinePriceScenariosData = useQuery<GetSwinePriceScenariosDataQuery, GetSwinePriceScenariosDataQueryVariables>(this, () => [
		GET_SWINE_PRICE_SCENARIOS_DATA,
		{ variables: this.variables },
	]);

	async model(params: ModelParams) {
		const startDate = params.startDate ?? DateTime.fromISO(DateTime.now().toISODate()).startOf('month').toISODate();
		const endDate = params.endDate ?? DateTime.now().plus({ months: 12 }).toISODate();
		const businessParams = this.paramsFor('businesses.business') as { business_id: string };
		const { getCustomer } = this.modelFor('businesses.business') as ModelFrom<BusinessesBusinessRoute>;
		const averageFinishAgeInWeeks =
			getCustomer.data?.Customer?.averageFinishAgeInWeeks ?? (ENV.APP.DEFAULT_AVG_SWINE_FINISH_AGE_IN_WEEKS as number);

		const insuranceWhere: InsuranceEndorsementAllocationRatioFilterDTO = {
			effectiveHedgeDate: { gte: startDate, lte: endDate },
			InsuranceEndorsement: {
				type: {
					in: [TypeOfInsuranceEndorsement.Lgm, TypeOfInsuranceEndorsement.Lrp],
				},
				OR: [
					{
						AsLrpInsuranceEndorsement: {
							RmaType: {
								RmaCommodity: {
									commodityName: {
										equals: 'Swine',
									},
								},
							},
						},
					},
					{
						AsLgmInsuranceEndorsement: {
							RmaType: {
								RmaCommodity: {
									commodityName: {
										equals: 'Swine',
									},
								},
							},
						},
					},
				],
			},
		};

		this.variables = {
			scopeId: businessParams.business_id,
			futuresWhere: this.generateFuturesWhere(startDate, endDate, ['livestock-lean-hogs', 'grain-corn', 'grain-soybean-meal']),
			swineSalesPurchasesAndProducedWhere: this.generateSwineSalesPurchasesAndProducedWhere(
				businessParams.business_id,
				startDate,
				endDate,
				averageFinishAgeInWeeks,
			),
			startDate: startDate,
			endDate: endDate,
			allocatedFuturePositionsWhere: this.generateAllocatedFuturePositionsWhere(startDate, endDate, businessParams.business_id),
			allocatedOptionPositionsWhere: this.generateAllocatedOptionPositionsWhere(startDate, endDate, businessParams.business_id),
			allocatedSwapPositionsWhere: this.generateAllocatedSwapPositionsWhere(startDate, endDate, businessParams.business_id),
			allocatedSwaptionPositionsWhere: this.generateAllocatedSwaptionPositionsWhere(startDate, endDate, businessParams.business_id),
			insuranceWhere: insuranceWhere,
		};

		await this.getSwinePriceScenariosData.promise;

		return {
			getSwinePriceScenariosData: this.getSwinePriceScenariosData,
			entityId: businessParams.business_id,
			averageFinishAgeInWeeks: averageFinishAgeInWeeks,
		};
	}

	createCurrentPositions(
		allocatedFuturePositions: CurrentAllocationPosition[],
		allocatedOptionPositions: CurrentAllocationPosition[],
		allocatedSwapPositions: CurrentAllocationPosition[],
		allocatedSwaptionPositions: CurrentAllocationPosition[],
	) {
		const currentPositions: HypotheticalPosition[] = new TrackedArray([]);

		const addPosition = (obj: HypotheticalPositionJsonFormat) => {
			currentPositions.push(HypotheticalPosition.fromJson(obj, getOwner(this)));
		};

		allocatedFuturePositions.forEach((position) => {
			const date = position.effectiveHedgeDate;
			const instrument = position.Instrument as Future;
			const components = position.PositionComponentAllocations;
			const productLotSpecificationId = instrument.productLotSpecificationId;
			const ProductLotSpecification = position.Product.ProductLotSpecifications.find((spec) => spec.id === productLotSpecificationId);
			const pointValue = ProductLotSpecification?.pointValue ?? 0;
			const lotSize = ProductLotSpecification?.lotSize ?? 0;
			const instrumentType = TypeOfInstrument.Future;
			const optionType = null;
			const strike = null;
			const futureDisplayExpiresAt = instrument?.displayExpiresAt;
			const barchartSymbol = instrument?.barchartSymbol;
			const productSlug = position.Product.slug;
			const { realizedPl, unrealizedAvgPrice, unrealizedTotalContracts } = calculateRealizedPnlAndUnrealizedComponents(
				components,
				pointValue,
			);

			if (!date || !futureDisplayExpiresAt || !productSlug || !barchartSymbol) {
				console.error(`Not all data could be retrieved for: ` + JSON.stringify(position));
				return;
			}

			addPosition({
				date,
				futureDisplayExpiresAt,
				productSlug,
				instrumentType,
				barchartSymbol,
				pointValue,
				lotSize,
				quantity: unrealizedTotalContracts,
				price: unrealizedAvgPrice,
				commissionAndFeeTotal: 0,
				realizedPnl: realizedPl,
				optionType,
				strike,
			});
		});

		allocatedOptionPositions.forEach((position) => {
			const date = position.effectiveHedgeDate;
			const instrument = position.Instrument as Option;
			const components = position.PositionComponentAllocations;
			const productLotSpecificationId = instrument.productLotSpecificationId;
			const ProductLotSpecification = position.Product.ProductLotSpecifications.find((spec) => spec.id === productLotSpecificationId);
			const pointValue = ProductLotSpecification?.pointValue ?? 0;
			const lotSize = ProductLotSpecification?.lotSize ?? 0;
			const instrumentType = TypeOfInstrument.Option;
			const optionType = instrument.optionType;
			const strike = instrument.strike;
			const futureDisplayExpiresAt = instrument?.UnderlyingInstrument?.displayExpiresAt;
			const barchartSymbol = instrument?.UnderlyingInstrument?.barchartSymbol;
			const productSlug = position.Product.slug;

			const { realizedPl, unrealizedAvgPrice, unrealizedTotalContracts } = calculateRealizedPnlAndUnrealizedComponents(
				components,
				pointValue,
			);

			if (!date || !futureDisplayExpiresAt || !productSlug || !barchartSymbol) {
				console.error(`Not all data could be retrieved for: ` + JSON.stringify(position));
				return;
			}
			addPosition({
				date,
				futureDisplayExpiresAt,
				productSlug,
				instrumentType,
				barchartSymbol,
				pointValue,
				lotSize,
				quantity: unrealizedTotalContracts,
				price: unrealizedAvgPrice,
				commissionAndFeeTotal: 0,
				realizedPnl: realizedPl,
				optionType,
				strike,
			});
		});

		allocatedSwapPositions.forEach((position) => {
			const instrumentType = TypeOfInstrument.Swap;
			const instrument = position.Instrument as Swap;
			const components = position.PositionComponentAllocations;
			const optionType = null;
			const strike = null;
			const date = position.effectiveHedgeDate;
			const futureDisplayExpiresAt = (instrument.PriceInstrument as Future | null)?.displayExpiresAt;
			const productSlug = position.Product.slug;
			const productLotSpecificationId = instrument.productLotSpecificationId;
			const ProductLotSpecification = position.Product.ProductLotSpecifications.find((spec) => spec.id === productLotSpecificationId);
			const pointValue = ProductLotSpecification?.pointValue ?? 0;
			const lotSize = ProductLotSpecification?.lotSize ?? 0;
			const barchartSymbol = (instrument?.PriceInstrument as Future | null)?.barchartSymbol;

			if (!pointValue || !date || !futureDisplayExpiresAt || !productSlug || !barchartSymbol) {
				console.error(`Not all data could be retrieved for Swap: ` + JSON.stringify(position));
				return;
			}

			const { realizedPl, unrealizedAvgPrice, unrealizedTotalContracts } = calculateRealizedPnlAndUnrealizedComponents(
				components,
				pointValue,
			);

			addPosition({
				date,
				futureDisplayExpiresAt,
				productSlug,
				instrumentType,
				barchartSymbol,
				pointValue,
				lotSize,
				quantity: unrealizedTotalContracts,
				price: unrealizedAvgPrice,
				commissionAndFeeTotal: 0,
				realizedPnl: realizedPl,
				optionType,
				strike,
			});
		});

		allocatedSwaptionPositions.forEach((position) => {
			const instrumentType = position.instrumentType;
			const instrument = position.Instrument as Swaption;
			const components = position.PositionComponentAllocations;
			const optionType = position.optionType;
			const strike = (position.Instrument as Swaption).strike;
			const date = position.effectiveHedgeDate;
			const futureDisplayExpiresAt = (instrument?.PriceInstrument as Future | null)?.displayExpiresAt;
			const productSlug = position.Product.slug;
			const productLotSpecificationId = instrument.productLotSpecificationId;
			const ProductLotSpecification = position.Product.ProductLotSpecifications.find((spec) => spec.id === productLotSpecificationId);
			const pointValue = ProductLotSpecification?.pointValue ?? 0;
			const lotSize = ProductLotSpecification?.lotSize ?? 0;
			const barchartSymbol = (instrument?.PriceInstrument as Future | null)?.barchartSymbol;

			if (!date || !optionType || !strike || !futureDisplayExpiresAt || !productSlug || !barchartSymbol) {
				console.error(`Not all data could be retrieved for Swaption: ` + JSON.stringify(position));
				return;
			}

			const { realizedPl, unrealizedAvgPrice, unrealizedTotalContracts } = calculateRealizedPnlAndUnrealizedComponents(
				components,
				pointValue,
			);

			addPosition({
				date,
				futureDisplayExpiresAt,
				productSlug,
				instrumentType,
				barchartSymbol,
				pointValue,
				lotSize,
				quantity: unrealizedTotalContracts,
				price: unrealizedAvgPrice,
				commissionAndFeeTotal: 0,
				realizedPnl: realizedPl,
				optionType,
				strike,
			});
		});

		return currentPositions;
	}

	retrieveAddedHypotheticalPositionsFromStorage(
		entityId: string | null,
		hypotheticalPositions: HypotheticalPosition[],
	): HypotheticalPosition[] {
		if (!entityId || !checkStorageAvailable('localStorage')) return hypotheticalPositions;

		const localStorageString = `price-scenarios-hypothetical-positions.${entityId}`;

		const temp = window.localStorage.getItem(localStorageString);
		if (!temp) return hypotheticalPositions;

		const hypotheticalPositionObjects = JSON.parse(temp) as HypotheticalPositionJsonFormat[];

		hypotheticalPositionObjects.forEach((obj: HypotheticalPositionJsonFormat) => {
			if (obj) {
				hypotheticalPositions.push(HypotheticalPosition.fromJson(obj, getOwner(this)));
			}
		});

		return hypotheticalPositions;
	}

	generateFuturesWhere(startDate: string, endDate: string, productSlugs: string[]): FutureFilterDTO {
		const futuresStartEndDate = DateTime.fromISO(startDate).minus({ month: 1 }).startOf('month').toISODate();
		const futuresEndDate = DateTime.fromISO(endDate).plus({ months: 12 }).startOf('month').toISODate();
		return {
			AND: [
				{ displayExpiresAt: { gte: futuresStartEndDate } },
				{ displayExpiresAt: { lte: futuresEndDate } },
				{ isStandardContractSize: { equals: true } },
			],
			Product: {
				slug: {
					in: productSlugs,
				},
			},
			isStandardContractSize: { equals: true },
		};
	}

	generateAllocatedFuturePositionsWhere(
		startDate: string | null,
		endDate: string | null,
		entityId: string,
	): CurrentAllocationPositionFilterDTO {
		const where = this.generateAllocatedPositionsWhere(startDate, endDate, entityId);
		where.instrumentType = { equals: TypeOfInstrument.Future };
		return where;
	}

	generateAllocatedOptionPositionsWhere(
		startDate: string | null,
		endDate: string | null,
		entityId: string,
	): CurrentAllocationPositionFilterDTO {
		const where = this.generateAllocatedPositionsWhere(startDate, endDate, entityId);
		where.instrumentType = { equals: TypeOfInstrument.Option };
		return where;
	}

	generateAllocatedSwapPositionsWhere(
		startDate: string | null,
		endDate: string | null,
		entityId: string,
	): CurrentAllocationPositionFilterDTO {
		const where = this.generateAllocatedPositionsWhere(startDate, endDate, entityId);
		where.instrumentType = { equals: TypeOfInstrument.Swap };
		return where;
	}

	generateAllocatedSwaptionPositionsWhere(
		startDate: string | null,
		endDate: string | null,
		entityId: string,
	): CurrentAllocationPositionFilterDTO {
		const where = this.generateAllocatedPositionsWhere(startDate, endDate, entityId);
		where.instrumentType = { equals: TypeOfInstrument.Swaption };
		return where;
	}

	generateAllocatedPositionsWhere(startDate: string | null, endDate: string | null, entityId: string): CurrentAllocationPositionFilterDTO {
		const where: CurrentAllocationPositionFilterDTO = {
			entityId: {
				equals: entityId,
			},
			effectiveHedgeDate: {
				gte: startDate,
				lte: endDate,
			},
			Product: {
				slug: {
					in: ['livestock-lean-hogs', 'grain-corn', 'grain-soybean-meal'],
				},
			},
		};

		return where;
	}

	generateSwineSalesPurchasesAndProducedWhere(
		customerId: string,
		startDate: string,
		endDate: string,
		averageFinishAgeInWeeks: number,
	): SwineLivestockPopulationChangeFilterDTO {
		return {
			businessId: { equals: customerId },
			OR: [
				{
					reasonType: {
						equals: TypeOfLivestockPopulationChangeReason.Sale,
					},
					date: {
						gte: startDate,
						lte: endDate,
					},
				},
				{
					reasonType: {
						in: [TypeOfLivestockPopulationChangeReason.Purchase, TypeOfLivestockPopulationChangeReason.Birth],
					},
					dob: {
						gte: formatISO(subWeeks(startOfWeek(parseISO(startDate), { weekStartsOn: 0 }), averageFinishAgeInWeeks), {
							representation: 'date',
						}),
						lte: formatISO(subWeeks(startOfWeek(parseISO(endDate), { weekStartsOn: 0 }), averageFinishAgeInWeeks), {
							representation: 'date',
						}),
					},
				},
			],
		};
	}
}
