import { gql } from 'glimmer-apollo';

export const CREATE_CATTLE_FEED_RATION_STAGE = gql`
	mutation CreateCattleFeedRationStage($data: CattleFeedRationStageCreateDTO!) {
		createCattleFeedRationStage(data: $data) {
			id
			name
			startWeightInLb
			endWeightInLb
		}
	}
`;

export const UPDATE_CATTLE_FEED_RATION_STAGE = gql`
	mutation UpdateCattleFeedRationStage($id: String!, $data: CattleFeedRationStageUpdateDTO!) {
		updateCattleFeedRationStage(id: $id, data: $data) {
			id
			name
			startWeightInLb
			endWeightInLb
		}
	}
`;

export const DELETE_CATTLE_FEED_RATION_STAGE = gql`
	mutation DeleteCattleFeedRationStage($id: String!) {
		deleteCattleFeedRationStage(id: $id) {
			id
		}
	}
`;
