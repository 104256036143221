import Controller from '@ember/controller';
import { action } from '@ember/object';
import { ModelFrom } from 'vault-client/utils/type-utils';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { queryManager } from 'ember-apollo-client';
import searchQuery from 'vault-client/graphql/queries/search.graphql';
import accountFuzzyQuery from 'vault-client/graphql/queries/account-fuzzy-match.graphql';
import getFilterDisplayProperty from 'vault-client/utils/get-filter-display-property';
import instrumentOptionTypes from 'vault-client/utils/instrument-option-types';
import MarketDataService from 'vault-client/services/market-data';
import FutureTransaction from 'vault-client/models/future-transaction';
import OptionTransaction from 'vault-client/models/option-transaction';
import SwapTransaction from 'vault-client/models/swap-transaction';
import SwaptionTransaction from 'vault-client/models/swaption-transaction';
import { getOwner } from '@ember/application';
import { DateTime } from 'luxon';
import BusinessesBusinessHedgeStrategiesEditBrokerageTransactionsIndexRoute from 'vault-client/routes/businesses/business/hedge-strategies/edit-brokerage-transactions';
import { TransactionQuery, modeledTransaction } from '../hedge-strategy';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import ApplicationScope from 'vault-client/services/application-scope';
import { Account, TypeOfAccount } from 'vault-client/types/graphql-types';
import { getStableId } from 'vault-client/utils/get-stable-id';

export default class BusinessesBusinessHedgeStrategiesEditBrokerageTransactionsIndexRouteController extends Controller {
	@queryManager apollo: any;
	@service marketData!: MarketDataService;
	@service declare applicationScope: ApplicationScope;
	declare model: ModelFrom<BusinessesBusinessHedgeStrategiesEditBrokerageTransactionsIndexRoute>;

	brokerageRoute = 'businesses.business.accounts';

	queryParams = [
		'accountId',
		'instrumentId',
		'customerId',
		'productId',
		'instrumentType',
		'optionType',
		'tradeDateStartDate',
		'tradeDateEndDate',
		'expMonthStartDate',
		'expMonthEndDate',
		'side',
		'page',
		'sorts',
		'size',
	];

	@tracked page = 0;
	@tracked size = 50;
	@tracked accountId: string | undefined;
	@tracked productId: string | undefined;
	@tracked instrumentId: string | undefined;
	@tracked instrumentType = null;
	@tracked optionType = null;
	@tracked side = null;
	@tracked tradeDateStartDate = '1900-01-01';
	@tracked tradeDateEndDate = '2999-12-31';
	@tracked expMonthStartDate = '1900-01-01';
	@tracked expMonthEndDate = '2999-12-31';
	@tracked currentScope = '';
	@tracked sorts = [{ valuePath: 'tradeDate', isAscending: false }];
	@tracked instrumentOptionTypes = instrumentOptionTypes;

	tradeDateRangeOptions = [
		{ displayName: 'All Days', startDate: '1900-01-01', endDate: '2999-12-31' },
		{
			displayName: 'Previous 7 Days',
			startDate: DateTime.local().minus({ weeks: 1 }).toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Previous 30 Days',
			startDate: DateTime.local().minus({ months: 1 }).toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Previous 90 Days',
			startDate: DateTime.local().minus({ months: 3 }).toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Previous 12 Months',
			startDate: DateTime.local().minus({ months: 12 }).startOf('month').toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Previous 24 Months',
			startDate: DateTime.local().minus({ months: 24 }).startOf('month').toISODate(),
			endDate: DateTime.local().toISODate(),
		},
	];

	expMonthRangeOptions = [
		{ displayName: 'All Months', startDate: '1900-01-01', endDate: '2999-12-31' },
		{
			displayName: 'Last Month',
			startDate: DateTime.local().minus({ months: 1 }).startOf('month').toISODate(),
			endDate: DateTime.local().minus({ months: 1 }).endOf('month').toISODate(),
		},
		{
			displayName: 'This Month',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().endOf('month').toISODate(),
		},
		{
			displayName: 'Next Month',
			startDate: DateTime.local().plus({ months: 1 }).startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 1 }).endOf('month').toISODate(),
		},
	];

	getBrokerageColumns(tableType: 'selected' | 'available'): TableColumn[] {
		const baseColumns = [
			{
				id: getStableId(`${tableType}-accountName`),
				name: 'Account Name',
				valuePath: 'Account.name',
				cellComponent: CellComponents.String,
				width: 140,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: getStableId(`${tableType}-salesCode`),
				name: 'Sales Code',
				valuePath: 'Account.salesCode',
				cellComponent: CellComponents.String,
				width: 100,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: getStableId(`${tableType}-accountNumber`),
				name: 'Account',
				valuePath: 'Account.accountNumber',
				cellComponent: CellComponents.String,
				width: 100,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: getStableId(`${tableType}-tradeDate`),
				name: 'Trade Day',
				valuePath: 'tradeDate',
				cellComponent: CellComponents.IntlDateTimeFormat,
				componentArgs: { day: 'numeric', month: 'numeric', year: 'numeric' },
				width: 130,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: getStableId(`${tableType}-productName`),
				name: 'Commodity',
				valuePath: 'Instrument.Product.name',
				width: 150,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: getStableId(`${tableType}-contractQuantity`),
				name: 'Contracts',
				valuePath: 'contractQuantity',
				linkRoute: 'businesses.business.transaction',
				linkModelPath: 'id',
				width: 95,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			// when adding delta quantity column add it here. Check this: https://linear.app/ever-ag/issue/VF-2803/hedge-strategy-brokerage-table-default-column-order
			{
				id: getStableId(`${tableType}-absQuantityInContracts`),
				name: 'ABS Quantity',
				valuePath: 'absQuantityInContracts',
				cellComponent: CellComponents.String,
				width: 120,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: getStableId(`${tableType}-instrumentType`),
				name: 'Type',
				valuePath: 'Instrument.instrumentType',
				cellComponent: CellComponents.String,
				width: 80,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: getStableId(`${tableType}-expirationMonth`),
				name: 'Exp Month',
				valuePath: 'Instrument.displayExpiresAt',
				width: 120,
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: getStableId(`${tableType}-tradePrice`),
				name: 'Trade Price',
				valuePath: 'price',
				width: 120,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'Instrument.symbolGroup.fractionDigits',
					displayFactorPath: 'Instrument.symbolGroup.displayFactor',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: getStableId(`${tableType}-side`),
				name: 'B / S',
				valuePath: 'side',
				cellComponent: CellComponents.String,
				width: 70,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: getStableId(`${tableType}-symbol`),
				name: 'Symbol',
				valuePath: 'Instrument.exchangeSymbol',
				cellComponent: CellComponents.String,
				width: 100,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: getStableId(`${tableType}-strike`),
				name: 'Strike',
				valuePath: 'Instrument.strike',
				width: 80,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'Instrument.symbolGroup.fractionDigits',
					displayFactorPath: 'Instrument.symbolGroup.displayFactor',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: getStableId(`${tableType}-commission`),
				name: 'Commission',
				valuePath: 'commission',
				width: 110,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: getStableId(`${tableType}-fees`),
				name: 'Fees',
				valuePath: 'fees',
				width: 90,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: getStableId(`${tableType}-grossPl`),
				name: 'Gross P/L',
				valuePath: 'grossPl',
				width: 110,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: getStableId(`${tableType}-lastPrice`),
				name: 'Last Price',
				valuePath: 'lastPrice',
				minWidth: 120,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'Instrument.symbolGroup.fractionDigits',
					displayFactorPath: 'Instrument.symbolGroup.displayFactor',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: getStableId(`${tableType}-netPl`),
				name: 'Net P/L',
				valuePath: 'netPl',
				minWidth: 120,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'b1fe3436-6593-4c60-9b99-1aa950b23d78',
				name: '',
				valuePath: 'isAdded',
				minWidth: 125,
				maxWidth: 125,
				width: 125,
				cellComponent: CellComponents.UpdateHedgeStrategyItemsButton,
				componentArgs: {
					hedgeStrategyId: this.hedgeStrategyId,
					updateBrokerageTransactions: this.updateBrokerageTransactions,
					tableType,
				},
				textAlign: 'center',
				isSortable: false,
				isFixed: 'right',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get selectedBrokerageColumns(): TableColumn[] {
		return this.getBrokerageColumns('selected');
	}

	get availableBrokerageColumns(): TableColumn[] {
		return this.getBrokerageColumns('available');
	}

	buildBrokerageRows(data: TransactionQuery[]) {
		const owner = getOwner(this);

		if (!data) return null;
		return data.map(({ ...transactionData }) => {
			let modeledTransaction: modeledTransaction = {};
			const marketData = this.marketData.getMarketDatum(transactionData.Instrument.PriceInstrument?.barchartSymbol);
			if (transactionData.Instrument.type === 'Future') {
				modeledTransaction = new FutureTransaction(owner, transactionData, transactionData.Instrument, null);
			}

			if (transactionData.Instrument.type === 'Option') {
				modeledTransaction = new OptionTransaction(owner, transactionData, transactionData.Instrument, null);
			}

			if (transactionData.Instrument.type === 'Swap') {
				modeledTransaction = new SwapTransaction(owner, transactionData, transactionData.Instrument, null);
			}

			if (transactionData.Instrument.type === 'Swaption') {
				modeledTransaction = new SwaptionTransaction(owner, transactionData, transactionData.Instrument, null);
			}

			const isAdded = transactionData.hedgeStrategyId === this.model.hedgeStrategy.id;

			return {
				...transactionData,
				accountName: transactionData.Account.name,
				Account: modeledTransaction.Account,
				Instrument: modeledTransaction.Instrument,
				marketDataInstrument: transactionData.Instrument.PriceInstrument?.barchartSymbol
					? transactionData.Instrument.PriceInstrument
					: transactionData.Instrument,
				side: modeledTransaction?.side,
				grossPl: modeledTransaction?.grossPl,
				netPl: modeledTransaction?.netPl,
				absQuantityInContracts: modeledTransaction?.absQuantityInContracts,
				commission: modeledTransaction?._commissionTotal,
				fees: modeledTransaction?._feeTotal,
				isAdded,
				lastPrice: marketData?.lastPrice,
			};
		});
	}
	get modelLastUpdatedAt() {
		try {
			return DateTime.fromISO(this.model.lastUpdatedAt).toLocaleString(DateTime.DATETIME_FULL);
		} catch {
			return '';
		}
	}

	get availableBrokerageRows() {
		const data = this.model.getBrokerageTransactions.data?.Transactions as TransactionQuery[] | undefined;
		if (!data) return null;
		return this.buildBrokerageRows(data);
	}

	get selectedBrokerageRows() {
		const data = this.model.selectedBrokerage?.data?.HedgeStrategy?.Transactions;
		if (!data) return null;
		return this.buildBrokerageRows(data);
	}

	get totalNumTransactions(): number {
		return this.model.getBrokerageTransactions.data?.TransactionCount?.count ?? 0;
	}

	get hedgeStrategyName() {
		return this.model.hedgeStrategy?.name || '';
	}

	get hedgeStrategyId() {
		return this.model.hedgeStrategy?.id || '';
	}
	get currentPage() {
		return this.page;
	}

	set currentPage(page) {
		this.page = page;
	}

	get sortParams() {
		return this.sorts;
	}

	set sortParams(sorts) {
		this.sorts = sorts;
	}

	get searchFilterQueryParams() {
		const obj: {
			[key: string]: any;
		} = {};
		const searchQueryParams = ['customerId', 'accountId', 'instrumentId', 'productId'];
		searchQueryParams.forEach((param) => {
			// if applicable setting filter for customerId for customer scope
			// @ts-ignore
			const value = param === 'customerId' && this.applicationScope.globalCustomerId ? this.applicationScope.globalCustomerId : this[param];

			if (value) {
				obj[param] = {
					filterRule: 'equals',
					filterValue: value,
				};

				// set filterComponent property to specify component for custom display extended from search-filter
				const filterDisplayObj = getFilterDisplayProperty(param);
				if (filterDisplayObj && filterDisplayObj.customComponent) {
					obj[param].filterComponent = filterDisplayObj.customComponent;
				}
				// set filterLabel property to specify custom label for filter - default would be filterIdentifier (matches queryParam)
				if (filterDisplayObj && filterDisplayObj.label) {
					obj[param].filterLabel = filterDisplayObj.label;
				}
			}
		});

		return obj;
	}

	get scopedSearchFilterQueryParams() {
		if (this.applicationScope.globalCustomerId) {
			// @ts-ignore
			// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
			const { customerId, ...rest } = this.searchFilterQueryParams;
			return rest;
		} else {
			return this.searchFilterQueryParams;
		}
	}

	get currentInstrumentOptionType() {
		return this.instrumentOptionTypes.find((type) => type.instrumentType === this.instrumentType && type.optionType === this.optionType);
	}

	get tradeDateQueryParam() {
		return {
			startDate: this.tradeDateStartDate,
			endDate: this.tradeDateEndDate,
		};
	}

	get expMonthQueryParam() {
		return {
			startDate: this.expMonthStartDate,
			endDate: this.expMonthEndDate,
		};
	}

	@action
	async fetchSearchResults(searchText: string) {
		const variables = {
			query: searchText,
			typesToInclude: [
				'BasisInstrument',
				'CashInstrument',
				'CommitmentInstrument',
				'Future',
				'Option',
				'PhysicalInstrument',
				'SpreadInstrument',
				'SwapInstrument',
				'SwaptionInstrument',
				'Product',
			],
		};
		let scopedAccountResults = [];

		if (!this.applicationScope.globalCustomerId) {
			variables.typesToInclude = [...variables.typesToInclude, 'BrokerageAccount', 'SwapAccount'];
		} else {
			const accountVariables = {
				where: {
					customerId: { equals: this.applicationScope.globalCustomerId },
					OR: [
						{
							accountNumber: { contains: searchText },
						},
						{
							name: { contains: searchText },
						},
						{
							id: { contains: searchText },
						},
					],
				},
			};

			scopedAccountResults = (
				await this.apollo.watchQuery({
					query: accountFuzzyQuery,
					variables: accountVariables,
				})
			).Accounts.map((account: Account) => {
				const newObj = {
					id: account.id,
					name: account.name,
					// does this encapsulate all accounts where type is different than would see in search api?
					type: account.type === TypeOfAccount.Brokerage || account.type === TypeOfAccount.Swap ? account.__typename : account.type,
					accountNumber: account.accountNumber,
				};
				return newObj;
			});
		}

		const searchApiResults = (await this.apollo.watchQuery({ query: searchQuery, variables })).Search;

		return [...searchApiResults, ...scopedAccountResults].sort((a, b) => {
			if (a.type < b.type) {
				return -1;
			} else if (a.type > b.type) {
				return 1;
			} else {
				return 0;
			}
		});
	}

	@action
	structureSearchResults(searchResults: any) {
		const map = new Map();

		// Move products to the front of the array so they are displayed above the other types
		const sortedSearchResults = searchResults.slice().sort((a: any, b: any) => {
			if (a.type === 'Product') {
				return -1;
			} else if (b.type === 'Product') {
				return 1;
			}
			return 0;
		});

		sortedSearchResults.forEach((item: any) => {
			if (map.has(item.type)) {
				map.get(item.type).push({
					id: item.id,
					name: item.name,
					type: item.type,
					accountNumber: item.accountNumber ?? null,
				});
			} else {
				map.set(item.type, [
					{
						id: item.id,
						name: item.name,
						type: item.type,
						accountNumber: item.accountNumber ?? null,
					},
				]);
			}
		});

		return map;
	}

	@action
	setSearchFilterQueryParam(searchResult: any) {
		const mappedSearchFilter = this.mapSearchResult(searchResult);
		//@ts-ignore
		this[mappedSearchFilter.filterIdentifier] = mappedSearchFilter.filterValue;
		this.setTablePageState();
	}

	mapSearchResult(searchResult: any) {
		let filterIdentifier;

		switch (searchResult.type) {
			case 'BasisInstrument':
				filterIdentifier = 'instrumentId';
				break;
			case 'BrokerageAccount':
				filterIdentifier = 'accountId';
				break;
			case 'CashInstrument':
				filterIdentifier = 'instrumentId';
				break;
			case 'CommitmentInstrument':
				filterIdentifier = 'instrumentId';
				break;
			case 'Future':
				filterIdentifier = 'instrumentId';
				break;
			case 'Option':
				filterIdentifier = 'instrumentId';
				break;
			case 'PhysicalInstrument':
				filterIdentifier = 'instrumentId';
				break;
			case 'SpreadInstrument':
				filterIdentifier = 'instrumentId';
				break;
			case 'SwapAccount':
				filterIdentifier = 'accountId';
				break;
			case 'SwapInstrument':
				filterIdentifier = 'instrumentId';
				break;
			case 'SwaptionInstrument':
				filterIdentifier = 'instrumentId';
				break;
			case 'Product':
				filterIdentifier = 'productId';
				break;
		}

		return {
			filterIdentifier,
			filterValue: searchResult.id,
		};
	}

	@action
	clearSearchFilterQueryParam(filterIdentifier: string) {
		//@ts-ignore
		this[`${filterIdentifier}`] = null;
		this.setTablePageState();
	}

	@action
	setTradeDateQueryParam(value: { startDate: string; endDate: string }) {
		this.tradeDateStartDate = value.startDate;
		this.tradeDateEndDate = value.endDate;
		this.setTablePageState();
	}

	@action
	setExpMonthQueryParam(value: { startDate: string; endDate: string }) {
		this.expMonthStartDate = value.startDate;
		this.expMonthEndDate = value.endDate;
		this.setTablePageState();
	}

	@action
	setInstrumentOptionType(instrumentType: any, optionType: any, callback: any) {
		callback();
		this.instrumentType = instrumentType;
		this.optionType = optionType;
	}

	@action
	setTransactionsSideSelect(transactionsSideType: any, callback: any) {
		callback();
		this.side = transactionsSideType;
	}

	@action
	setTablePageState(newPageVal = 0) {
		this.currentPage = newPageVal;
		resetVaultTableScroll('available-brokerage-transactions-table');
	}

	@action
	updateBrokerageTransactions(productId: string | undefined) {
		if (productId) {
			this.productId = productId;
		}
	}
}
