import { QueryOptions, useQuery } from 'glimmer-apollo';
import {
	LotsOverviewQuery,
	LotsOverviewQueryVariables,
	LotsSearchQuery,
	LotsSearchQueryVariables,
	GET_CATTLE_LOTS_QUERY,
	GET_CATTLE_LOTS_SEARCH,
	GET_CATTLE_LOTS_COUNT_BY_BUSINESS,
} from 'vault-client/graphql/queries/businesses/business/cattle/lots';

export function useLotsOverviewQuery(context: object, options: QueryOptions<LotsOverviewQuery, LotsOverviewQueryVariables>) {
	return useQuery<LotsOverviewQuery, LotsOverviewQueryVariables>(context, () => [GET_CATTLE_LOTS_QUERY, options]);
}

export function useLotsSearchQuery(context: object, options: QueryOptions<LotsSearchQuery, LotsSearchQueryVariables>) {
	return useQuery<LotsSearchQuery, LotsSearchQueryVariables>(context, () => [GET_CATTLE_LOTS_SEARCH, options]);
}

export function useLotsOverviewCountByBusiness(context: object, options: QueryOptions<LotsOverviewQuery, LotsOverviewQueryVariables>) {
	return useQuery<LotsOverviewQuery, LotsOverviewQueryVariables>(context, () => [GET_CATTLE_LOTS_COUNT_BY_BUSINESS, options]);
}
