import { Big, BigSource } from 'big.js';
import type { Maybe } from 'vault-client/types/graphql-types';

function formatNumber(value: number): number {
	const bigValue = new Big(value);
	return bigValue.toNumber();
}

/**
 * Sums an array of values.
 * @throws {Error} If inputs cannot be converted to valid numbers
 */
function sum(...values: BigSource[]): number {
	return values.reduce<Big>((sum, value) => sum.plus(new Big(value)), new Big(0)).toNumber();
}
/**
 * Adds two Maybe<number> values, coercing null and undefined values to 0
 * @throws {Error} If inputs cannot be converted to valid numbers
 * @returns {number} The sum of the values, or 0 if all inputs are null or undefined
 */
const safeSum = (...values: (Maybe<number> | undefined)[]): number => {
	return (
		values.reduce((acc, value) => {
			const bigAcc = new Big(acc ?? 0);
			const bigValue = new Big(value ?? 0);
			return bigAcc.plus(bigValue).toNumber();
		}, 0) ?? 0
	);
};

/**
 * Sums an array of Maybe<number> or undefined values, returning null if all values are null
 * @param values - An array of Maybe<number> or undefined values
 * @returns The sum of the values, or null if all values are null
 */
const safeSumNull = (...values: (Maybe<number> | undefined)[]): Maybe<number> => {
	return (
		values
			.reduce<Maybe<Big>>((acc, value) => {
				// If the value is null or undefined, return the accumulator
				if (value == null) return acc;
				const bigAcc = acc ? acc : new Big(0);
				return bigAcc.plus(value ?? 0);
			}, null)
			?.toNumber() ?? null
	);
};

/**
 * Subtracts `b` from `a`.
 *  @throws {Error} If inputs cannot be converted to valid numbers
 */
function subtract(a: BigSource, b: BigSource): number {
	const bigA = new Big(a);
	const bigB = new Big(b);
	const result = bigA.minus(bigB);
	return formatNumber(parseFloat(result.toString()));
}

function gte(a: number, b: number): boolean {
	const bigA = new Big(a);
	const bigB = new Big(b);
	return bigA.gte(bigB);
}

function gt(a: number, b: number): boolean {
	const bigA = new Big(a);
	const bigB = new Big(b);
	return bigA.gt(bigB);
}

/**
 * Calculates the weighted arithmetic mean of the given values and weights.
 * @param values - The array of values.
 * @param weights - The array of weights corresponding to the values.
 * @returns The weighted arithmetic mean. Returns 0 if the arrays are empty or if the total weight is 0.
 * @throws {Error} If the lengths of values and weights do not match or if any value is an invalid BigSource.
 */
function weightedMean(values: BigSource[], weights: BigSource[]): number {
	if (values.length !== weights.length) {
		throw new Error('The lengths of values and weights must match');
	}

	// Handle empty arrays explicitly
	if (values.length === 0) {
		return 0;
	}

	let totalWeight = new Big(0);
	let weightedSum = new Big(0);

	for (let i = 0; i < values.length; i++) {
		const value = new Big(values[i]);
		const weight = new Big(weights[i]);

		totalWeight = totalWeight.plus(weight);
		weightedSum = weightedSum.plus(value.times(weight));
	}

	return safeDivideZero(weightedSum, totalWeight);
}

/**
 * Subtracts values from an array, returning null if all values are null or undefined
 * @param values - An array of values where the first value is the base and subsequent values are subtracted
 * @returns The result of the subtraction, or null if all values are null or undefined
 */
function safeSubNull(...values: (Maybe<number> | undefined)[]): Maybe<number> {
	return (
		values
			.reduce<Maybe<Big>>((acc, value) => {
				// If the value is null or undefined, return the accumulator
				if (value == null) return acc;

				// For the first non-null value, initialize the accumulator
				if (acc === null) return new Big(value ?? 0);

				// For subsequent values, subtract from the accumulator
				return acc.minus(value ?? 0);
			}, null)
			?.toNumber() ?? null
	);
}

/**
 * Divides `a` by `b`, throws an error if `b` is an invalid divisor
 * @throws {Error} If inputs cannot be converted to valid numbers
 */
function divide(a: BigSource, b: BigSource): number {
	const bigA = new Big(a);
	const bigB = new Big(b);
	return bigA.div(bigB).toNumber();
}

/**
 * Divides `a` by `b`, returns `null` if `b` is an invalid divisor
 * @throws {Error} If inputs cannot be converted to valid numbers
 */
function safeDivideNull(a: BigSource, b: BigSource): number | null {
	if (_isInvalidDivisor(b)) return null;
	return divide(a, b);
}

/**
 * Divides `a` by `b`, returns `0` if `b` is an invalid divisor
 * @throws {Error} If inputs cannot be converted to valid numbers
 */
function safeDivideZero(a: BigSource, b: BigSource): number {
	if (_isInvalidDivisor(b)) return 0;
	return divide(a, b);
}

export { subtract, divide, safeDivideNull, safeDivideZero, gte, gt, sum, safeSum, safeSumNull, weightedMean, safeSubNull };

/**
 * Checks if `x` is an invalid divisor (zero or invalid number)
 */
function _isInvalidDivisor(x: BigSource): boolean {
	try {
		return new Big(x).eq(0);
	} catch (e) {
		return true;
	}
}
