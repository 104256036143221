import { gql } from 'glimmer-apollo';

export const GET_CATTLE_DASHBOARD = gql`
	query GetCattleDashboard(
		$productSlugs: [String!]!
		$customerId: String!
		$startDate: String!
		$endDate: String!
		$cattleLotsWhere: CattleLotFilterDTO!
		$feedRationUsageWhere: CattleLotFeedRationUsagePerMonthFilterDTO
		$insuranceEndorsementWhere: InsuranceEndorsementAllocationRatioFilterDTO
	) {
		Products(where: { slug: { in: $productSlugs } }) {
			id
			slug
			CurrentFutures {
				id
				displayExpiresAt
				barchartSymbol
				productId
				name
				SymbolGroup {
					id
					displayFactor
					fractionDigits
				}
			}
		}
		FeedIngredientConsumedAndPurchasedVolumes(
			where: {
				startDate: $startDate
				endDate: $endDate
				FeedIngredient: { FeedCategory: { HedgeProduct: { slug: { in: $productSlugs } } } }
			}
			orderBy: { monthStartDate: Asc }
			scopeId: $customerId
			limit: 2000
		) {
			id
			feedIngredientId
			monthStartDate
			purchasedInTons
			totalPurchasedCostInUsd
			forecastedConsumptionInTons
			volumePurchasedWithBasisPriceOnlyInTons
			volumePurchasedWithFlatPriceInTons
			volumePurchasedWithFuturesPriceOnlyInTons
			volumePurchasedWithoutPriceInTons
			FeedIngredient {
				id
				name
				cmePercentageBasis
				cmeUsdBasis
				updatedAt
				flatPricePerTon
				FeedCategory {
					id
					name
					updatedAt
					defaultFlatPricePerTon
					defaultCmePercentageBasis
					defaultCmeUsdBasis
					HedgeProduct {
						id
						slug
					}
				}
			}
		}
		AggregateAllocatedForecastedHedgedAndCappedVolumes(
			calc: { sum: { naturallyShortHedged: true, naturallyLongHedged: true } }
			groupBy: { Product: { slug: true }, date: true, instrumentType: true, optionType: true }
			where: {
				date: { gte: $startDate, lte: $endDate }
				hedgeType: { equals: Brokerage }
				instrumentType: { in: [Future, Option, Swap, Swaption] }
				Product: { slug: { in: $productSlugs } }
				entityId: { equals: $customerId }
			}
			orderBy: { date: Asc }
		) {
			date
			Product {
				slug
			}
			instrumentType
			optionType
			sum {
				naturallyShortHedged
				naturallyLongHedged
			}
		}
		Futures(
			where: {
				Product: { slug: { in: $productSlugs } }
				isStandardContractSize: { equals: true }
				displayExpiresAt: { gte: $startDate, lte: $endDate }
			}
			orderBy: { displayExpiresAt: Asc }
		) {
			id
			name
			barchartSymbol
			displayExpiresAt
			SymbolGroup {
				id
				displayFactor
				fractionDigits
			}
			Product {
				id
				slug
				StandardProductLotSpecification {
					id
					pointValue
					lotSize
				}
			}
		}
		AggregateCurrentAllocationPositions(
			scopeId: $customerId
			calc: { sum: { grossPnl: true, contractQuantity: true } }
			groupBy: { effectiveHedgeDate: true, instrumentId: true, instrumentType: true, optionType: true, Product: { slug: true } }
			orderBy: { effectiveHedgeDate: Asc }
			where: { Product: { slug: { in: $productSlugs } } }
		) {
			effectiveHedgeDate
			instrumentType
			optionType
			contractQuantity
			Product {
				slug
			}
			sum {
				grossPnl
				contractQuantity
			}
		}
		CurrentAllocationPositions(
			scopeId: $customerId
			where: {
				effectiveHedgeDate: { gte: $startDate, lte: $endDate }
				Product: { slug: { in: $productSlugs } }
				OR: [{ optionType: { equals: Call } }, { optionType: { equals: null } }]
			}
			orderBy: { effectiveHedgeDate: Asc }
		) {
			id
			effectiveHedgeDate
			instrumentType
			contractQuantity
			grossPnl
			lifetimeWeightedAveragePrice
			optionType
			effectiveHedgeDate
			Product {
				id
				slug
				StandardProductLotSpecification {
					id
					pointValue
				}
			}
			Instrument {
				type
				SymbolGroup {
					id
					fractionDigits
					displayFactor
				}
				... on Future {
					id
					displayExpiresAt
				}
				... on Swap {
					id
					displayExpiresAt
				}
				... on Option {
					id
					strike
					optionType
					displayExpiresAt
				}
				... on Swaption {
					id
					optionType
					displayExpiresAt
					strike
				}
			}
		}
		AllocatedLrpInsuranceEndorsements: InsuranceEndorsementAllocationRatios(
			scopeId: $customerId
			orderBy: { effectiveHedgeDate: Asc }
			where: {
				effectiveHedgeDate: { gte: $startDate, lte: $endDate }
				InsuranceEndorsement: { AsLrpInsuranceEndorsement: { commodityCode: { in: ["0801", "0802"] } } }
			}
			limit: 2000
		) {
			id
			effectiveHedgeDate
			RatioAdjustedInsuranceEndorsement {
				id
				... on LrpInsuranceEndorsement {
					pnl
				}
			}
		}
		AllocatedLgmInsuranceEndorsements: InsuranceEndorsementAllocationRatios(
			scopeId: $customerId
			orderBy: { effectiveHedgeDate: Asc }
			where: {
				effectiveHedgeDate: { gte: $startDate, lte: $endDate }
				InsuranceEndorsement: {
					AsLgmInsuranceEndorsement: { Product: { slug: { in: ["livestock-live-cattle", "livestock-feeder-cattle"] } } }
				}
			}
			limit: 2000
		) {
			id
			effectiveHedgeDate
			RatioAdjustedInsuranceEndorsement {
				id
				... on LgmInsuranceEndorsement {
					pnl
				}
			}
		}
		AggregateExpenseLedgerEntries: AggregateLedgerEntries(
			calc: { sum: { calculatedAmount: true } }
			groupBy: { month: true, year: true }
			where: {
				entityId: { equals: $customerId }
				LedgerCategory: { type: { equals: Expense } }
				AND: [{ date: { gte: $startDate } }, { date: { lte: $endDate } }]
			}
		) {
			sum {
				calculatedAmount
			}
			month
			year
		}
		AggregateRevenueLedgerEntries: AggregateLedgerEntries(
			calc: { sum: { calculatedAmount: true } }
			groupBy: { month: true, year: true }
			where: {
				entityId: { equals: $customerId }
				LedgerCategory: { type: { equals: Revenue } }
				AND: [{ date: { gte: $startDate } }, { date: { lte: $endDate } }]
			}
		) {
			sum {
				calculatedAmount
			}
			month
			year
		}
		AggregateCattleLots(
			calc: {
				sum: {
					purchasePriceInUsd: true
					cattleLotTotalExpensesInUsd: true
					cattleLotInputExpensesInUsd: true
					forecastedSalesRevenueInUsd: true
					numberOfCattle: true
				}
			}
			groupBy: { activeEndMonthStartDate: true }
			where: $cattleLotsWhere
		) {
			activeEndMonthStartDate
			sum {
				purchasePriceInUsd
				cattleLotTotalExpensesInUsd
				cattleLotInputExpensesInUsd
				forecastedSalesRevenueInUsd
				numberOfCattle
			}
			Business {
				id
				name
			}
		}
		AggregateCattleLotsForFeederHedged: AggregateCattleLots(
			calc: { sum: { numberOfCattle: true } }
			groupBy: { activeEndMonthStartDate: true }
			where: {
				PurchaseHedgeProduct: { slug: { equals: "livestock-feeder-cattle" } }
				OR: [{ activeEndDate: { gte: $startDate } }, { activeEndDate: { lte: $endDate } }]
			}
			scopeId: $customerId
		) {
			activeEndMonthStartDate
			sum {
				numberOfCattle
			}
		}
		CattleLots(scopeId: $customerId, where: $cattleLotsWhere) {
			id
			numberOfCattle
			startWeightInLb
			targetWeightInLb
			activeStartDate
			activeEndDate
			forecastedSalesRevenueInUsd
		}
		AggregateCattleLotFeedRationUsagePerMonths(
			calc: { sum: { totalExpenseInUsd: true } }
			groupBy: { CattleLot: { activeEndMonthStartDate: true } }
			where: $feedRationUsageWhere
		) {
			CattleLot {
				activeEndMonthStartDate
			}
			feedIngredientId
			sum {
				totalExpenseInUsd
			}
		}
		EntityAllocatedExposureRatios(
			where: { startDate: $startDate, endDate: $endDate, Product: { slug: { in: $productSlugs } }, entityId: { equals: $customerId } }
			orderBy: { date: Asc }
		) {
			Product {
				id
				slug
			}
			date
			lrpVolumeHedged
			lgmVolumeHedged
			totalPercentVolumeHedged
			percentLgmVolumeHedged
			percentLrpVolumeHedged
			percentPhysicalVolumeHedged
			percentBrokerageVolumeHedged
			physicalVolumeHedged
			totalVolumeHedged
			netProductionExposure
		}
		InsuranceEndorsementAllocationRatios(where: $insuranceEndorsementWhere) {
			id
			insuranceEndorsementId
		}
		Customer(id: $customerId) {
			id
			businessRoles
		}
	}
`;
