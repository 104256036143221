import { setOwner } from '@ember/application';
import { Owner } from 'vault-client';

export default class CollapsedRowManager {
	#defaultIsCollapsed: boolean;
	#data = new Map<string, boolean>();

	constructor(owner: Owner, defaultIsCollapsed = false) {
		setOwner(this, owner);
		this.#defaultIsCollapsed = defaultIsCollapsed;
	}

	augment<T>(id: string, target: T) {
		const [getIsCollapsed, setIsCollapsed] = this.createGetterAndSetter(id);

		return Object.defineProperty({ ...target }, 'isCollapsed', {
			get() {
				return getIsCollapsed();
			},
			set(isCollapsed: boolean) {
				setIsCollapsed(isCollapsed);
			},
		}) as T & { isCollapsed: boolean };
	}

	createGetterAndSetter(id: string) {
		this.#add(id);
		const getter = () => this.#data.get(id) ?? this.#defaultIsCollapsed;
		const setter = (isCollapsed: boolean) => {
			this.#data.set(id, isCollapsed);
		};
		return [getter, setter] as const;
	}

	#add(id: string) {
		if (!this.#data.has(id)) {
			this.#data.set(id, this.#defaultIsCollapsed);
		}
	}
}
