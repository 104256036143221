import { DateTime } from 'luxon';

export interface DateRangeOption {
	displayName: string;
	startDate: string;
	endDate: string;
}

export const DATE_RANGE_OPTIONS = [
	{
		displayName: 'Next 24 Months',
		startDate: DateTime.local().startOf('month').toISODate(),
		endDate: DateTime.local().plus({ months: 24 }).endOf('month').toISODate(),
	},
	{
		displayName: 'Previous 24 Months',
		startDate: DateTime.local().minus({ months: 24 }).startOf('month').toISODate(),
		endDate: DateTime.local().endOf('month').toISODate(),
	},
	{
		displayName: 'Next 12 Months',
		startDate: DateTime.local().startOf('month').toISODate(),
		endDate: DateTime.local().plus({ months: 12 }).endOf('month').toISODate(),
	},
	{
		displayName: 'Previous 12 Months',
		startDate: DateTime.local().minus({ months: 12 }).startOf('month').toISODate(),
		endDate: DateTime.local().endOf('month').toISODate(),
	},
	{
		displayName: `Calendar Year (${DateTime.local().year})`,
		startDate: DateTime.local().startOf('year').toISODate(),
		endDate: DateTime.local().endOf('year').toISODate(),
	},
] as const;
