import { BusinessEntityRole, type Maybe } from 'vault-client/types/graphql-types';
import type { SelectableUnit, SupportedBusinessRole } from './operations';

export function getSelectableUnits(role: Maybe<SupportedBusinessRole>): SelectableUnit[] {
	switch (role) {
		case BusinessEntityRole.DairyProducer:
		case BusinessEntityRole.LiveCattleProducer:
		case BusinessEntityRole.FeederCattleProducer:
			return ['Static', 'Per Head', 'Per CWT'];
		case BusinessEntityRole.HogProducer:
			return ['Static', 'Per Head'];
		default:
			return [];
	}
}
