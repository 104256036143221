import { DisplayUnits } from 'vault-client/types/cattle/display-units';
import { TableColumn, CellComponents } from 'vault-client/types/vault-table';
import { getStableId } from 'vault-client/utils/get-stable-id';

function getCattleDashboardPnlTableColumns(selectedDisplayUnit: 'Total' | 'Per Head' | 'Per CWT'): TableColumn[] {
	return [
		{
			id: '8109668f-e206-48f7-b1c5-00ab74b1aee4',
			name: 'Date',
			valuePath: 'date',
			cellComponent: CellComponents.MonthFormat,
			textAlign: 'left',
			isSortable: false,
			isFixed: '',
			isVisible: true,
			componentArgs: {
				footerContent: selectedDisplayUnit === DisplayUnits.Total ? 'Total' : 'Average',
			},
		},
		{
			id: '2792e94a-bc5f-482e-a196-c123fe960f52',
			name: 'Cattle Sales',
			valuePath: 'cattleSalesRevenue',
			cellComponent: CellComponents.IntlNumberFormat,
			componentArgs: {
				style: 'currency',
				currency: 'USD',
				currencySign: 'accounting',
				minimumFractionDigits: selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
				maximumFractionDigits: selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
			},
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '94ad1ca6-cf0b-4ca3-bbc0-1ea3d8f25ba5',
			name: 'CME (Cattle)',
			valuePath: 'cmeCattleRevenue',
			cellComponent: CellComponents.IntlNumberFormat,
			componentArgs: {
				style: 'currency',
				currency: 'USD',
				currencySign: 'accounting',
				minimumFractionDigits: selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
				maximumFractionDigits: selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
			},
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: 'b75f45b1-6c5a-4485-a863-a00c0272f105',
			name: 'Other Revenue',
			valuePath: 'otherRevenue',
			cellComponent: CellComponents.IntlNumberFormat,
			componentArgs: {
				style: 'currency',
				currency: 'USD',
				currencySign: 'accounting',
				minimumFractionDigits: selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
				maximumFractionDigits: selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
			},
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '22f7e690-b1ca-441d-a8c4-faaef1ec9eaa',
			name: 'Insurance',
			valuePath: 'insurance',
			cellComponent: CellComponents.IntlNumberFormat,
			componentArgs: {
				style: 'currency',
				currency: 'USD',
				currencySign: 'accounting',
				minimumFractionDigits: selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
				maximumFractionDigits: selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
			},
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '0c8cdfac-e9c9-44cb-90eb-e278a78f4111',
			name: 'Lot Expenses',
			valuePath: 'lotExpenses',
			cellComponent: CellComponents.IntlNumberFormat,
			componentArgs: {
				style: 'currency',
				currency: 'USD',
				currencySign: 'accounting',
				minimumFractionDigits: selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
				maximumFractionDigits: selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
			},
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '13a95e7d-a0a8-4c79-b9fc-f1ead22c7427',
			name: 'Feed Expenses',
			valuePath: 'feedExpenses',
			cellComponent: CellComponents.IntlNumberFormat,
			componentArgs: {
				style: 'currency',
				currency: 'USD',
				currencySign: 'accounting',
				minimumFractionDigits: selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
				maximumFractionDigits: selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
			},
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: 'c9964c50-7844-46aa-9048-b1e0cda098ea',
			name: 'CME (Feed)',
			valuePath: 'cmeFeedRevenue',
			cellComponent: CellComponents.IntlNumberFormat,
			componentArgs: {
				style: 'currency',
				currency: 'USD',
				currencySign: 'accounting',
				minimumFractionDigits: selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
				maximumFractionDigits: selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
			},
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: 'd9964c50-7844-46aa-9048-b1e0cda098ea',
			name: 'Other Expenses',
			valuePath: 'otherExpenses',
			cellComponent: CellComponents.IntlNumberFormat,
			componentArgs: {
				style: 'currency',
				currency: 'USD',
				currencySign: 'accounting',
				minimumFractionDigits: selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
				maximumFractionDigits: selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
			},
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '71f731bc-14cc-4dfb-9ddc-75cc50796a61',
			name: 'Net P/L',
			valuePath: 'netPnl',
			cellComponent: CellComponents.IntlNumberFormat,
			componentArgs: {
				style: 'currency',
				currency: 'USD',
				currencySign: 'accounting',
				minimumFractionDigits: selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
				maximumFractionDigits: selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
			},
			textAlign: 'right',
			isSortable: false,
			isFixed: 'right',
			isVisible: true,
		},
	];
}
// Feeder Cattle and Live Cattle share the same table columns when the cattle is a revenue source
function getCattleAsRevenuePercentHedgedTableColumns(tableType: 'live-cattle' | 'feeder-cattle'): TableColumn[] {
	return [
		{
			id: getStableId(`${tableType}-month`),
			name: 'Month',
			valuePath: 'month',
			cellComponent: CellComponents.MonthFormat,
			textAlign: 'left',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: getStableId(`${tableType}-production`),
			name: 'Production (Lbs)',
			valuePath: 'production',
			cellComponent: CellComponents.IntlNumberFormat,
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: getStableId(`${tableType}-futures-hedged`),
			name: 'Futures Hedged',
			cellComponent: CellComponents.String,
			subcolumns: [
				{
					id: getStableId(`${tableType}-futures-hedged-percent`),
					name: '%',
					valuePath: 'futuresHedged',
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'percent',
						minimumFractionDigits: 0,
						maximumFractionDigits: 0,
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: getStableId(`${tableType}-futures-hedged-lbs`),
					name: 'Lbs',
					valuePath: 'futuresHedgedLbs',
					cellComponent: CellComponents.IntlNumberFormat,
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
			],
			textAlign: 'center',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: getStableId(`${tableType}-puts-hedged`),
			name: 'Puts Hedged',
			cellComponent: CellComponents.String,
			subcolumns: [
				{
					id: getStableId(`${tableType}-puts-hedged-percent`),
					name: '%',
					valuePath: 'putsHedged',
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'percent',
						minimumFractionDigits: 0,
						maximumFractionDigits: 0,
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: getStableId(`${tableType}-puts-hedged-lbs`),
					name: 'Lbs',
					valuePath: 'putsHedgedLbs',
					cellComponent: CellComponents.IntlNumberFormat,
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
			],
			textAlign: 'center',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: getStableId(`${tableType}-lrp-insurance`),
			name: 'LRP Insurance',
			cellComponent: CellComponents.String,
			subcolumns: [
				{
					id: getStableId(`${tableType}-lrp-insurance-percent`),
					name: '%',
					valuePath: 'lrpInsurance',
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'percent',
						minimumFractionDigits: 0,
						maximumFractionDigits: 0,
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: getStableId(`${tableType}-lrp-insurance-lbs`),
					name: 'Lbs',
					valuePath: 'lrpInsuranceLbs',
					cellComponent: CellComponents.IntlNumberFormat,
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
			],
			textAlign: 'center',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: getStableId(`${tableType}-lgm-insurance`),
			name: 'LGM Insurance',
			cellComponent: CellComponents.String,
			subcolumns: [
				{
					id: getStableId(`${tableType}-lgm-insurance-percent`),
					name: '%',
					valuePath: 'lgmInsurance',
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'percent',
						minimumFractionDigits: 0,
						maximumFractionDigits: 0,
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: getStableId(`${tableType}-lgm-insurance-lbs`),
					name: 'Lbs',
					valuePath: 'lgmInsuranceLbs',
					cellComponent: CellComponents.IntlNumberFormat,
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
			],
			textAlign: 'center',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
	];
}

function feederForLiveCattlePercentHedgedColumns(): TableColumn[] {
	return [
		{
			id: 'b65a35d9-562a-44d5-9ebd-f2aaec32fbee',
			name: 'Month',
			valuePath: 'month',
			cellComponent: CellComponents.MonthFormat,
			textAlign: 'left',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '9837c41c-87d5-415d-ab26-187789aead32',
			name: 'Animals Needed (Lbs)',
			valuePath: 'animalsNeeded',
			cellComponent: CellComponents.IntlNumberFormat,
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: 'bf64130d-e252-49bf-b022-621e5a0968aa',
			name: 'Futures Hedged',
			cellComponent: CellComponents.String,
			subcolumns: [
				{
					id: 'bf92774d-7246-4d55-a11e-782fdc5b6d1c',
					name: '%',
					valuePath: 'futuresHedged',
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'percent',
						minimumFractionDigits: 0,
						maximumFractionDigits: 0,
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: 'b31da204-828b-4be4-bb79-9e43bad00f76',
					name: 'Lbs',
					valuePath: 'futuresHedgedLbs',
					cellComponent: CellComponents.IntlNumberFormat,
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
			],
			textAlign: 'center',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '31017f5d-1327-4599-a101-690aa05ac10f',
			name: 'Calls Hedged',
			cellComponent: CellComponents.String,
			subcolumns: [
				{
					id: 'b1e0c47b-cc4d-43ee-8bed-fa37c0392d7e',
					name: '%',
					valuePath: 'callsHedged',
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'percent',
						minimumFractionDigits: 0,
						maximumFractionDigits: 0,
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: 'a43804f3-dfdb-4640-b4cc-908d783aaefe',
					name: 'Lbs',
					valuePath: 'callsHedgedLbs',
					cellComponent: CellComponents.IntlNumberFormat,
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
			],
			textAlign: 'center',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
	];
}

function cornPercentHedgedColumns(): TableColumn[] {
	return [
		{
			id: '8cd4f336-d960-4229-8152-0c4dafe8247d',
			name: 'Month',
			valuePath: 'month',
			cellComponent: CellComponents.MonthFormat,
			textAlign: 'left',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: 'b2e5b3ec-399a-4d02-b70c-2177628feb4f',
			name: 'Usage DMI Tons',
			valuePath: 'usage',
			cellComponent: CellComponents.IntlNumberFormat,
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '02842cfc-f622-4cf1-9931-663ac864189e',
			name: 'Futures Hedged',
			cellComponent: CellComponents.String,
			subcolumns: [
				{
					id: '155764d9-9c97-4813-984d-7efc31f60dd0',
					name: '%',
					valuePath: 'futuresHedged',
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'percent',
						minimumFractionDigits: 0,
						maximumFractionDigits: 0,
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: '6e482e75-bde1-4f46-b331-26cc19e27896',
					name: 'Tons',
					valuePath: 'futuresHedgedTons',
					cellComponent: CellComponents.IntlNumberFormat,
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
			],
			textAlign: 'center',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: 'd0aa5a67-9608-4023-8f83-765cd20e64a8',
			name: 'Calls Hedged',
			cellComponent: CellComponents.String,
			subcolumns: [
				{
					id: '8d6d4a15-b706-4c7d-9f04-bc48d8825fa2',
					name: '%',
					valuePath: 'callsHedged',
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'percent',
						minimumFractionDigits: 0,
						maximumFractionDigits: 0,
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: 'fa25b04a-0dbf-4aaf-b289-238adcbc4d81',
					name: 'Tons',
					valuePath: 'callsHedgedTons',
					cellComponent: CellComponents.IntlNumberFormat,
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
			],
			textAlign: 'center',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '6e8f60d5-8aa9-4a88-b2fc-2967ccc0e295',
			name: 'Physical Purchases',
			cellComponent: CellComponents.IntlNumberFormat,
			subcolumns: [
				{
					id: 'df20d81b-8858-4a49-813a-69bf00caa309',
					name: '%',
					valuePath: 'physicalPurchases',
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'percent',
						minimumFractionDigits: 0,
						maximumFractionDigits: 0,
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: '5a5d489e-24b1-4a95-8089-4cea2675975a',
					name: 'Tons',
					valuePath: 'physicalPurchasesTons',
					cellComponent: CellComponents.IntlNumberFormat,
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
			],
			textAlign: 'center',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
	];
}

export {
	getCattleDashboardPnlTableColumns,
	getCattleAsRevenuePercentHedgedTableColumns,
	cornPercentHedgedColumns,
	feederForLiveCattlePercentHedgedColumns,
};
