import ReportsIndexController, {
	ButterInventoryHedgesReport,
	ButterSbmReport,
	ButterSimReport,
	// CalendarMonthReport,
	CattleHistoricalPercentilesReport,
	CheeseBlockSbmReport,
	CheeseBlockSimReport,
	CheeseSbmReport,
	ClassIiiSbmReport,
	ClassIvSbmReport,
	DairyHistoricalPercentilesReport,
	DairyMarginReport,
	DryWheySbmReport,
	DryWheySimReport,
	LrpPaymentCalculatorReport,
	NonfatDryMilkSbmReport,
	NonfatDryMilkSimReport,
	PriceScenariosReport,
	Report,
} from 'vault-client/controllers/reports';
import { BusinessEntityRole } from 'vault-client/types/graphql-types';
import { inject as service } from '@ember/service';
import { ModelFrom } from 'vault-client/utils/type-utils';
import BusinessesBusinessRoute from 'vault-client/routes/businesses/business';

export default class BusinessesBusinessReports extends ReportsIndexController {
	@service permissions: any;
	declare model: ModelFrom<BusinessesBusinessRoute>;

	get businessRoles() {
		return this.model.getCustomer.data?.Customer?.businessRoles ?? [];
	}

	get rows() {
		const { businessRoles } = this;
		const permissionedBusinessReports: Report[] = [];
		// permissionedBusinessReports.push(CalendarMonthReport);
		if (businessRoles.find((role: BusinessEntityRole) => role === BusinessEntityRole.DairyProducer)) {
			permissionedBusinessReports.push(
				DairyMarginReport,
				DairyHistoricalPercentilesReport,
				CattleHistoricalPercentilesReport,
				LrpPaymentCalculatorReport,
				PriceScenariosReport,
			);
		}

		if (businessRoles.find((role: BusinessEntityRole) => role === BusinessEntityRole.DairyProcessor)) {
			permissionedBusinessReports.push(
				ButterInventoryHedgesReport,
				ButterSimReport,
				DryWheySimReport,
				NonfatDryMilkSbmReport,
				NonfatDryMilkSimReport,
				CheeseBlockSimReport,
				DairyMarginReport,
				DairyHistoricalPercentilesReport,
				PriceScenariosReport,
				ButterSbmReport,
				CheeseBlockSbmReport,
				CheeseSbmReport,
				ClassIiiSbmReport,
				ClassIvSbmReport,
				DryWheySbmReport,
			);
		}

		if (businessRoles.find((role: BusinessEntityRole) => role === BusinessEntityRole.Consumer)) {
			permissionedBusinessReports.push(
				DairyMarginReport,
				DairyHistoricalPercentilesReport,
				ButterSbmReport,
				NonfatDryMilkSbmReport,
				ClassIiiSbmReport,
				ClassIvSbmReport,
				DryWheySbmReport,
				CheeseSbmReport,
				CheeseBlockSbmReport,
			);
		}

		if (
			businessRoles.find(
				(role: BusinessEntityRole) => role === BusinessEntityRole.FeederCattleProducer || role === BusinessEntityRole.LiveCattleProducer,
			)
		) {
			permissionedBusinessReports.push(CattleHistoricalPercentilesReport, LrpPaymentCalculatorReport);
		}

		if (businessRoles.find((role: BusinessEntityRole) => role === BusinessEntityRole.HogProducer)) {
			permissionedBusinessReports.push(LrpPaymentCalculatorReport);
		}

		const _rows = [...this.availableRows, ...permissionedBusinessReports].sortBy('name');
		const uniqueRows = Array.from(new Set(_rows));
		const permissionedReports = uniqueRows.filter((row) => {
			return row.access != 'Internal Only' || this.permissions.isInternal;
		});
		return permissionedReports.map((row) => {
			row.routePath = 'businesses.business.' + row.routePathRoot;
			return row;
		});
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/reports': BusinessesBusinessReports;
	}
}
