import InsuranceIndexRoute, { InsuranceIndexRouteQuery } from 'vault-client/routes/insurance';
import { ModelFrom } from 'vault-client/utils/type-utils';
import BusinessesBusinessRoute from 'vault-client/routes/businesses/business';

export default class BusinessesBusinessInsuranceRoute extends InsuranceIndexRoute {
	async model() {
		const businessParams = this.paramsFor('businesses.business') as { business_id: string };
		const { getCustomer } = this.modelFor('businesses.business') as ModelFrom<BusinessesBusinessRoute>;
		const variables = {
			endorsementWhere: this.generateEndorsementWhere(),
			productionWhere: this.generateProductionWhere(),
			entityId: businessParams.business_id,
		};

		const response = (await this.apollo.watchQuery({ query: this.query, variables })) as InsuranceIndexRouteQuery;

		return {
			...response,
			getCustomer,
			variables,
		};
	}
}
