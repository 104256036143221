import { helper } from '@ember/component/helper';
import { FormError } from 'vault-client/utils/forms/form-error';

export default helper(function formsGetErrorMessages([error] /*, named*/ : [error?: unknown]): string[] {
	if (error instanceof FormError) {
		return error.messages;
	} else if (error instanceof Error) {
		return [error.message];
	} else if (typeof error === 'object' && error !== null && 'message' in error && typeof error.message === 'string') {
		return [error.message];
	} else if (typeof error === 'string') {
		return [error];
	}

	return [];
});
