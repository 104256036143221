import type { AggregateLedgerForecastedEntryDTO } from 'vault-client/types/graphql-types';
import type { BusinessProjectedExpenses } from '../dashboard';

/**
 * Get the projected expenses for a business. Caller must ensure that only expense ledger entries are passed in.
 * @param aggregateExpenseLedgerEntries - The aggregate ledger entries for the business.
 * @returns The projected expenses for the business
 */
function getBusinessProjectedExpenses(aggregateExpenseLedgerEntries: AggregateLedgerForecastedEntryDTO[]): BusinessProjectedExpenses {
	const businessExpenses = aggregateExpenseLedgerEntries.reduce((acc, entry) => {
		return acc + (entry.sum?.amount ?? 0);
	}, 0);

	return {
		'Business Expenses': businessExpenses,
		totalExpenses: businessExpenses,
	};
}

function getBusinessProjectedExpensesAsAbsoluteValue(aggregateRevenueLedgerEntries: AggregateLedgerForecastedEntryDTO[]): BusinessProjectedExpenses {
	const businessExpenses = getBusinessProjectedExpenses(aggregateRevenueLedgerEntries);
	return {
		'Business Expenses': Math.abs(businessExpenses['Business Expenses'] ?? 0),
		totalExpenses: Math.abs(businessExpenses.totalExpenses ?? 0),
	};
}

export { getBusinessProjectedExpenses, getBusinessProjectedExpensesAsAbsoluteValue };
