import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import PermissionsService from 'vault-client/services/permissions';
import { CellComponents } from 'vault-client/types/vault-table';
import Target from 'vault-client/models/vgs/target';
import { GrainCropPlan, GrainTargetOrder } from 'vault-client/types/graphql-types';
import { ModelFrom } from 'vault-client/utils/type-utils';
import OrganizationsCropTargets from 'vault-client/routes/organizations/organization/crop-targets';
import BusinessesCropTargets from 'vault-client/routes/businesses/business/crop-targets';
import { GET_TARGETS } from 'vault-client/routes/crop-targets';
import { getGrainOrderStatusDisplayValue, getSalesTypeDisplayValue } from 'vault-client/utils/vgs-utils';

export default class CropTargetsIndexController extends Controller {
	@service declare permissions: PermissionsService;
	declare model: ModelFrom<OrganizationsCropTargets> | ModelFrom<BusinessesCropTargets>;

	queryParams = ['customerId', 'page', 'sorts', 'size'];

	itemsFn = (rows: any) => {
		return rows.map((row: GrainTargetOrder) => {
			const plan = row.Plan as GrainCropPlan;
			return new Target(row, plan.acres, plan.aph);
		});
	};

	get columns() {
		const baseColumns = [
			// Show VGS Targets link for GMAs and BR Users
			...(this.permissions.showVGS
				? [
						{
							id: 'f360d314-59ee-4b84-a6d7-e1f75b3b5775',
							name: '',
							width: 100,
							textAlign: '',
							isSortable: false,
							cellComponent: CellComponents.Button,
							componentArgs: {
								size: 'xs',
								style: 'plain',
								text: 'Details',
								fn: () => {},
							},
							isFixed: 'left',
							isVisible: true,
							linkRoute: 'vgs.grain-services.targets.show',
							linkModelPath: 'id',
						},
				  ]
				: []),
			{
				id: 'ee3581ad-786f-4e51-8c69-d1e5d853935b',
				name: 'Business',
				valuePath: 'Plan.Customer.name',
				width: 200,
				textAlign: '',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: 'left',
				isVisible: true,
				linkRoute: 'businesses.business.dashboard',
				linkModelPath: 'Plan.Customer.id',
			},
			{
				id: '54f145ef-0da4-4485-b429-d2b29ea2b72b',
				name: 'Crop Year',
				valuePath: 'Plan.CropYear.year',
				width: 100,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '39c32baf-ef95-46a8-905d-9c293d9ba276',
				name: 'Crop',
				valuePath: 'Plan.Crop.name',
				width: 100,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '24a9951e-4740-404d-9367-ba59cda13a39',
				name: 'Updated At',
				valuePath: 'StatusUpdates.updatedAt',
				width: 160,
				cellComponent: CellComponents.IntlDateTimeFormat,
				componentArgs: {
					day: 'numeric',
					month: 'numeric',
					year: 'numeric',
					hour: 'numeric',
					minute: 'numeric',
				},
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '12d7bcda-85ea-45e2-b69c-54e5da7446e6',
				name: 'Status',
				valuePath: 'status',
				width: 150,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.CustomFormat,
				componentArgs: {
					formatter: getGrainOrderStatusDisplayValue,
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: '4595c7af-dbaf-4241-827c-db9222da4bc3',
				name: 'Expires At',
				valuePath: 'expirationDate',
				width: 100,
				cellComponent: CellComponents.IntlDateTimeFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'ae18c053-f087-4462-8577-82e0f203a63a',
				name: 'Bushels',
				valuePath: 'bushels',
				width: 110,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '4082fff9-a324-4bb9-93c0-fe37a05cc449',
				name: 'Futures Month',
				valuePath: 'futuresMonth',
				width: 140,
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '525f27d4-95fd-4b7a-9802-4c87c1288d0b',
				name: 'Delivery Start Date',
				valuePath: 'deliveryStartDate',
				width: 180,
				cellComponent: CellComponents.IntlDateTimeFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '5ad59a7e-f415-495d-afa3-9341bffa2746',
				name: 'Delivery End Date',
				valuePath: 'deliveryEndDate',
				width: 180,
				cellComponent: CellComponents.IntlDateTimeFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'e9771fc7-9586-4deb-b5f9-7ecebb26b682',
				name: 'Sales Type',
				valuePath: 'salesType',
				width: 100,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.CustomFormat,
				componentArgs: {
					formatter: getSalesTypeDisplayValue,
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: '638c81de-9f80-4c9e-b837-71a73bdaae9e',
				name: 'Futures Price',
				valuePath: 'futurePrice',
				width: 140,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'ad43235b-11af-4f37-9f5e-23cdf479a58c',
				name: 'Fees',
				valuePath: 'fees',
				width: 140,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'f662e1dc-255f-4e37-83b2-4b556f0dfa05',
				name: 'Spread Gain',
				valuePath: 'spread',
				width: 140,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'af95be47-11b0-4f10-9497-6f5384545f1c',
				name: 'Basis',
				valuePath: 'basis',
				width: 140,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '425ed254-1d15-4bc3-bf75-e17a89468f86',
				name: 'Buyer',
				valuePath: 'Buyer.name',
				width: 180,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '97e5796d-c1e3-4730-8f8e-339c57d4c62f',
				name: 'Location',
				valuePath: 'Location.name',
				width: 180,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'b03601c2-1bc1-4a8a-a45c-125202f1686f',
				name: 'Delivery',
				valuePath: 'DeliveryLocation.name',
				width: 180,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '170b9163-67f9-4537-ad9b-c41ed0846fc4',
				name: 'Contract Number',
				valuePath: 'contractNumber',
				width: 200,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get sortParams() {
		return this.sorts;
	}

	set sortParams(sorts) {
		this.sorts = sorts;
	}

	get currentPage() {
		return this.page;
	}

	set currentPage(page) {
		this.page = page;
	}

	@tracked size = 100;
	@tracked page = 0;
	@tracked sorts = [];

	get targetsData() {
		return this.itemsFn(this.model.getTargets.data?.GrainTargetOrders ?? []);
	}

	get totalNumTargets() {
		return this.model.getTargets.data?.GrainTargetOrderCount.count ?? null;
	}

	get query() {
		return GET_TARGETS;
	}

	@action
	setTablePageState(newPageVal = 0) {
		this.currentPage = newPageVal;
		resetVaultTableScroll('crop-targets-index-table');
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'crop-targets-index': CropTargetsIndexController;
	}
}
