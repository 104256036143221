import Controller from '@ember/controller';
import { CellComponents } from 'vault-client/types/vault-table';

export default class CattleController extends Controller {
	lotOverviewPath = '';
	yardOverviewPath = '';
	rationOverviewPath = '';

	get columns() {
		const baseColumns = [
			{
				id: 'e53fe8fe-b4f3-42d4-b009-00441f69394a',
				name: 'Name',
				valuePath: 'name',
				minWidth: 225,
				textAlign: 'left',
				headerTextAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				linkRoutePath: 'linkRoute',
			},
		];

		return baseColumns;
	}

	get rows() {
		return [
			{
				name: 'Lot Overview',
				linkRoute: this.lotOverviewPath,
			},
			{
				name: 'Yard Overview',
				linkRoute: this.yardOverviewPath,
			},
			{
				name: 'Ration Overview',
				linkRoute: this.rationOverviewPath,
			},
		];
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'cattle/index': CattleController;
	}
}
