import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { TrackedMap } from 'tracked-built-ins/-private/map';
import { FormState } from 'vault-client/types/forms';

export default class FormStateService extends Service {
	@tracked formStates = new TrackedMap<string, FormState>();

	updateState = (formId: string, { submit, validationState, submissionState, isDirty }: FormState) => {
		this.formStates.set(formId, {
			submit,
			validationState,
			submissionState,
			isDirty,
		});
	};

	getState = (formId: string) => {
		return this.formStates.get(formId);
	};

	clearState = (formId: string) => {
		this.formStates.delete(formId);
	};

	clearAllStates = () => {
		this.formStates.clear();
	};
}

declare module '@ember/service' {
	interface Registry {
		'form-state': FormStateService;
	}
}
