import { helper } from '@ember/component/helper';
import { CellComponents } from 'vault-client/types/vault-table';

export function columnCustomizeName([name, secondaryDisplayName, componentType, componentArgs]: [string, string, CellComponents, Record<string, unknown> | undefined]) {
	if (name === '' || name === undefined) {
		if (componentType === CellComponents.String) {
			return secondaryDisplayName || 'Row Header';
		} else if (componentType === CellComponents.Button) {
			return componentArgs?.text || secondaryDisplayName || 'Button';
		} else if (componentType === CellComponents.Details) {
			return secondaryDisplayName || 'Details';
		} else {
			return secondaryDisplayName || '-';
		}
	} else {
		return name;
	}
}

export default helper(columnCustomizeName);
