import Big from 'big.js';
import { v5 as uuid } from 'uuid';
import {
	FeedIngredient,
	Future,
	Mutation_createPhysicalFeedTransactionArgs,
	Mutation_deleteFeedIngredientArgs,
	Mutation_updatePhysicalFeedTransactionArgs,
	PhysicalFeedTransactionCreateDTO,
	PhysicalFeedTransactionUpdateDTO,
	Mutation_setFeedIngredientForecastedUsageByMonthArgs,
	Mutation_setLivestockGroupFeedUsagesForBusinessArgs,
	FeedIngredientPricingMethodology,
	FeedIngredientPrice,
	Mutation_setFeedIngredientPriceByMonthArgs,
	FeedIngredientConsumedAndPurchasedVolume,
	PhysicalFeedTransaction,
	FeedTransaction,
} from 'vault-client/types/graphql-types';
import { gql, useMutation } from 'glimmer-apollo';
import { CreateEditFeedIngredientData } from 'vault-client/components/create-edit-feed-ingredient-form';
import {
	FeedCategory,
	Mutation,
	Mutation_createFeedIngredientArgs,
	Mutation_updateFeedIngredientArgs,
} from 'vault-client/types/graphql-types';
import checkStorageAvailable from './check-storage-available';
import { set } from '@ember/object';
import { TrackedObject } from 'tracked-built-ins/.';
import { analyticsCustomTracking } from './analytics-tracking';
import productSlugToPricingUnit, { PricingUnit } from './product-slug-to-pricing-unit';
import { AddEditPhysicalFeedTransactionFormData } from 'vault-client/components/add-edit-physical-feed-transaction-form';
import { UiDateFilterOption } from 'vault-client/components/vault/ui-date-filter';
import { DateTime } from 'luxon';
import { roundTo } from './round-to';
import { debug } from '@ember/debug';

const UUID_NAMESPACE = '3de788db-4a95-4523-ba2b-c437b471ea9e';

type FeedPriceType = 'Spot Price' | 'CME Basis (USD)' | 'CME Basis (%)';

type DefaultPricing = { feedPriceType: FeedPriceType; value: number | null };

type UnitOfMeasure = 'Tons' | 'Bushels' | 'LBS';

type PhysicalContractType = 'Flat Contracts' | 'HTA Contracts' | 'Basis Contracts' | 'No Price Contracts';

const LBS_PER_TON = 2000;

const EMPTY_PHYSICAL_FEED_TRANSACTION_DATA: AddEditPhysicalFeedTransactionFormData = {
	contractNumber: null,
	feedIngredient: null,
	tons: null,
	deliveryStartDate: null,
	deliveryEndDate: null,
	flatPrice: null,
	futuresPrice: null,
	basisPrice: null,
	deliveryTerms: null,
	seller: null,
	location: null,
};

const CURRENT_FEED_YEAR_DATE_TIME = {
	// If the current month is earlier than Oct, we are in the previous year's feed year
	startDate: DateTime.now()
		.set({ month: 10 })
		.minus({ year: DateTime.now().month < 10 ? 1 : 0 })
		.startOf('month'),
	endDate: DateTime.now()
		.set({ month: 9 })
		.plus({ year: DateTime.now().month < 10 ? 0 : 1 })
		.endOf('month'),
};

const CURRENT_FEED_YEAR = {
	startDate: CURRENT_FEED_YEAR_DATE_TIME.startDate.toISODate(),
	endDate: CURRENT_FEED_YEAR_DATE_TIME.endDate.toISODate(),
};

const FEED_YEAR_OPTIONS: { NUMBER: UiDateFilterOption[]; RELATIVE: UiDateFilterOption[] } = {
	// label: Feed Year 20xx
	NUMBER: [
		{
			displayName: `Feed Year ${CURRENT_FEED_YEAR_DATE_TIME.startDate.minus({ year: 1 }).year}`,
			startDate: CURRENT_FEED_YEAR_DATE_TIME.startDate.minus({ year: 1 }).startOf('month').toISODate(),
			endDate: CURRENT_FEED_YEAR_DATE_TIME.endDate.minus({ year: 1 }).endOf('month').toISODate(),
		},
		{
			displayName: `Feed Year ${CURRENT_FEED_YEAR_DATE_TIME.startDate.year}`,
			startDate: CURRENT_FEED_YEAR_DATE_TIME.startDate.toISODate(),
			endDate: CURRENT_FEED_YEAR_DATE_TIME.endDate.toISODate(),
		},
		{
			displayName: `Feed Year ${CURRENT_FEED_YEAR_DATE_TIME.startDate.plus({ year: 1 }).year}`,
			startDate: CURRENT_FEED_YEAR_DATE_TIME.startDate.plus({ year: 1 }).startOf('month').toISODate(),
			endDate: CURRENT_FEED_YEAR_DATE_TIME.endDate.plus({ year: 1 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 12 Months',
			startDate: DateTime.now().startOf('month').toISODate(),
			endDate: DateTime.now().plus({ months: 11 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 24 Months',
			startDate: DateTime.now().startOf('month').toISODate(),
			endDate: DateTime.now().plus({ months: 23 }).endOf('month').toISODate(),
		},
	],
	// label: Current Feed Year
	RELATIVE: [
		{
			displayName: 'Last Feed Year',
			startDate: CURRENT_FEED_YEAR_DATE_TIME.startDate.minus({ year: 1 }).startOf('month').toISODate(),
			endDate: CURRENT_FEED_YEAR_DATE_TIME.endDate.minus({ year: 1 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Current Feed Year',
			startDate: CURRENT_FEED_YEAR_DATE_TIME.startDate.toISODate(),
			endDate: CURRENT_FEED_YEAR_DATE_TIME.endDate.toISODate(),
		},
		{
			displayName: 'Next Feed Year',
			startDate: CURRENT_FEED_YEAR_DATE_TIME.startDate.plus({ year: 1 }).startOf('month').toISODate(),
			endDate: CURRENT_FEED_YEAR_DATE_TIME.endDate.plus({ year: 1 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 12 Months',
			startDate: DateTime.now().startOf('month').toISODate(),
			endDate: DateTime.now().plus({ months: 11 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 24 Months',
			startDate: DateTime.now().startOf('month').toISODate(),
			endDate: DateTime.now().plus({ months: 23 }).endOf('month').toISODate(),
		},
	],
};

function getPoundsPerBushel(productSlug: string | null) {
	switch (productSlug) {
		case 'grain-corn':
			return 56;
		case 'grain-oats':
			return 38;
		case 'grain-soybeans':
			return 60;
		case 'grain-chicago-soft-red-winter-wheat':
			return 60;
		case 'grain-srwi-soft-red-winter-wheat':
			return 60;
		case 'grain-kansas-city-hard-red-wheat':
			return 60;
		case 'grain-hard-red-spring-wheat':
			return 60;
		case 'grain-hrwi-hard-red-winter-wheat':
			return 60;
	}

	throw new Error('Unsupported productSlug passed to poundsPerBushel: ' + productSlug);
}

function getFuturePricingUnit(productSlug?: string | null): PricingUnit {
	// This view currently only supports corn, soybean meal, and non-product specific ingredients
	if (productSlug === 'grain-corn') {
		return 'Bushel';
	} else {
		return 'Ton';
	}
}

function createTrackedEmptyPhysicalFeedTransactionData() {
	return new TrackedObject(EMPTY_PHYSICAL_FEED_TRANSACTION_DATA);
}

function parseHedgeProductSlugFromFeedCategory(feedCategory?: FeedCategory | null) {
	return feedCategory?.HedgeProduct?.slug;
}

function parseHedgeProductSlugFromFeedIngredient(feedIngredient?: FeedIngredient | null) {
	return feedIngredient?.HedgeProduct?.slug ?? parseHedgeProductSlugFromFeedCategory(feedIngredient?.FeedCategory);
}

function validateEditPhysicalFeedTransactionFormData(
	data: AddEditPhysicalFeedTransactionFormData,
	originalEditValues: AddEditPhysicalFeedTransactionFormData | null,
	inputUoM?: UnitOfMeasure,
): PhysicalFeedTransactionUpdateDTO | false {
	if (!data.contractNumber || !data.tons || !data.deliveryStartDate || !data.deliveryEndDate) return false;

	const valueUpdated = (key: keyof AddEditPhysicalFeedTransactionFormData) => {
		return originalEditValues?.[key] !== data[key];
	};
	const hedgeProductSlug = parseHedgeProductSlugFromFeedIngredient(data.feedIngredient);

	const flatPriceUpdated = valueUpdated('flatPrice');
	const basisPriceUpdated = valueUpdated('basisPrice');
	const futuresPriceUpdated = valueUpdated('futuresPrice');

	const flatPriceExplicitlySet = flatPriceUpdated && !!data.flatPrice;
	const basisOrFuturesPriceExplicitlySet = (basisPriceUpdated || futuresPriceUpdated) && !!(data.basisPrice || data.futuresPrice);
	const futurePricingUOM = mapPricingUnitToUnitOfMeasure(getFuturePricingUnit(hedgeProductSlug));

	// Only send fields that have been updated
	return {
		...(valueUpdated('contractNumber') && { contractIdentifier: data.contractNumber }),
		...(valueUpdated('tons') && {
			tons: convertUnitOfMeasureToUnitOfMeasure(parseFloat(data.tons), inputUoM ?? futurePricingUOM, 'Tons', hedgeProductSlug ?? null),
		}),
		...(valueUpdated('deliveryStartDate') && { deliveryStartDate: data.deliveryStartDate }),
		...(valueUpdated('deliveryEndDate') && { deliveryEndDate: data.deliveryEndDate }),
		...(valueUpdated('deliveryTerms') && { deliveryTerms: data.deliveryTerms }),
		...(valueUpdated('seller') && { seller: data.seller }),
		...(valueUpdated('location') && { location: data.location }),
		...(flatPriceExplicitlySet && {
			flatPrice: data.flatPrice
				? convertPerUnitOfMeasureToPerUnitOfMeasure(parseFloat(data.flatPrice), inputUoM ?? futurePricingUOM, 'Tons')
				: null,
			futuresPrice: null,
			basisPrice: null,
		}),
		...(basisOrFuturesPriceExplicitlySet && {
			flatPrice: null,
			futuresPrice: data.futuresPrice
				? convertPerUnitOfMeasureToPerUnitOfMeasure(parseFloat(data.futuresPrice), inputUoM ?? futurePricingUOM, 'Tons')
				: null,
			basisPrice: data.basisPrice
				? convertPerUnitOfMeasureToPerUnitOfMeasure(
						parseFloat(data.basisPrice),
						inputUoM ?? mapPricingUnitToUnitOfMeasure(getFuturePricingUnit(hedgeProductSlug)),
						'Tons',
					)
				: null,
		}),
	};
}

function validateAddFormData(
	data: AddEditPhysicalFeedTransactionFormData,
	inputUoM?: UnitOfMeasure,
): PhysicalFeedTransactionCreateDTO | false {
	if (!data.contractNumber || !data.feedIngredient || !data.tons || !data.deliveryStartDate || !data.deliveryEndDate) return false;

	const hedgeProductSlug = parseHedgeProductSlugFromFeedIngredient(data.feedIngredient);
	const futurePricingUOM = mapPricingUnitToUnitOfMeasure(getFuturePricingUnit(hedgeProductSlug));
	return {
		contractIdentifier: data.contractNumber,
		feedIngredientId: data.feedIngredient.id,
		tons: convertUnitOfMeasureToUnitOfMeasure(parseFloat(data.tons), inputUoM ?? futurePricingUOM, 'Tons', hedgeProductSlug ?? null),
		deliveryStartDate: data.deliveryStartDate,
		deliveryEndDate: data.deliveryEndDate,
		...(data.flatPrice && {
			flatPrice: data.flatPrice
				? convertPerUnitOfMeasureToPerUnitOfMeasure(parseFloat(data.flatPrice), inputUoM ?? futurePricingUOM, 'Tons')
				: null,
		}),
		...(data.futuresPrice && {
			futuresPrice: data.futuresPrice
				? convertPerUnitOfMeasureToPerUnitOfMeasure(parseFloat(data.futuresPrice), inputUoM ?? futurePricingUOM, 'Tons')
				: null,
		}),
		...(data.basisPrice && {
			basisPrice: data.basisPrice
				? convertPerUnitOfMeasureToPerUnitOfMeasure(parseFloat(data.basisPrice), inputUoM ?? futurePricingUOM, 'Tons')
				: null,
		}),
		...(data.deliveryTerms && { deliveryTerms: data.deliveryTerms }),
		...(data.seller && { seller: data.seller }),
		...(data.location && { location: data.location }),
	};
}

function getFeedIngredientMarketPrice(
	feedIngredient: FeedIngredient,
	futurePrice: number | null,
	displayFactor: number | null,
	month?: string,
) {
	// Month Values
	let monthFlat: number | undefined;
	let monthCmeUsdBasis: number | undefined;
	let monthCmePercentageBasis: number | undefined;

	const { FeedIngredientPrices } = feedIngredient;

	// Cast used to handle FeedIngredientPrices not being provided
	const monthPrice = month ? (FeedIngredientPrices as FeedIngredientPrice[] | undefined)?.find((price) => price.date === month) : undefined;

	switch (monthPrice?.pricingMethodology) {
		case FeedIngredientPricingMethodology.Flat:
			monthFlat = monthPrice.price;
			break;
		case FeedIngredientPricingMethodology.CmeUsdBasis:
			monthCmeUsdBasis = monthPrice.price;
			break;
		case FeedIngredientPricingMethodology.CmePercentBasis:
			monthCmePercentageBasis = monthPrice.price;
			break;
	}

	// Ingredient Values
	const {
		flatPricePerTon: ingredientFlat,
		cmeUsdBasis: ingredientCmeUsdBasis,
		cmePercentageBasis: ingredientCmePercentageBasis,
	} = feedIngredient;

	// Category Values
	const {
		defaultFlatPricePerTon: categoryFlat,
		defaultCmeUsdBasis: categoryCmeUsdBasis,
		defaultCmePercentageBasis: categoryCmePercentageBasis,
	} = feedIngredient.FeedCategory;

	let spotPrice;
	let cmeUsdBasis;
	let cmePercentageBasis;

	// Pricing Priority is: Month -> Ingredient -> Category
	if (monthFlat != undefined || monthCmeUsdBasis != undefined || monthCmePercentageBasis != undefined) {
		spotPrice = monthFlat;
		cmeUsdBasis = monthCmeUsdBasis;
		cmePercentageBasis = monthCmePercentageBasis;
	} else if (ingredientFlat != undefined || ingredientCmeUsdBasis != undefined || ingredientCmePercentageBasis != undefined) {
		spotPrice = ingredientFlat;
		cmeUsdBasis = ingredientCmeUsdBasis;
		cmePercentageBasis = ingredientCmePercentageBasis;
	} else {
		spotPrice = categoryFlat;
		cmeUsdBasis = categoryCmeUsdBasis;
		cmePercentageBasis = categoryCmePercentageBasis;
	}

	if (spotPrice != undefined) {
		return spotPrice;
	}

	// Require futurePrice and displayFactor for basis prices
	if (futurePrice == undefined || displayFactor == undefined) {
		return null;
	}

	if (cmeUsdBasis != undefined) {
		return (futurePrice + cmeUsdBasis) * displayFactor;
	}

	if (cmePercentageBasis != undefined) {
		return futurePrice * cmePercentageBasis * displayFactor;
	}

	return null;
}

function getPricingUnitForFeedIngredient(feedIngredient: FeedIngredient): PricingUnit {
	const isCornIngredient = parseHedgeProductSlugFromFeedIngredient(feedIngredient) === 'grain-corn';

	const flatPricePerTon = feedIngredient?.flatPricePerTon;
	const cmeUsdBasis = feedIngredient?.cmeUsdBasis;
	const cmePercentageBasis = feedIngredient?.cmePercentageBasis;

	const defaultFlatPricePerTon = feedIngredient?.FeedCategory?.defaultFlatPricePerTon;

	// Flat priced if flatPricePerTon is set or if no basis is set and defaultFlatPricePerTon is set
	const isFlatPricedPerTon =
		flatPricePerTon != null || (cmeUsdBasis == null && cmePercentageBasis == null && defaultFlatPricePerTon != null);

	// If the ingredient is flatPricedPerTon, return Tons regardless of hedge product
	if (isFlatPricedPerTon) {
		return 'Ton';
	}

	if (isCornIngredient) {
		return 'Bushel';
	}

	// All ingredients that are not corn are priced per ton
	return 'Ton';
}

function convertTonsToPricingUnit(feedIngredient: FeedIngredient, tons: number, productSlug: string | null) {
	// Convert tons to the relevant pricing unit for the feed ingredient. Ingredient price settings determine the pricing unit.

	const ingredientPricingUnit = getPricingUnitForFeedIngredient(feedIngredient);

	if (ingredientPricingUnit === 'Bushel') {
		return new Big(tons).times(LBS_PER_TON).div(getPoundsPerBushel(productSlug)).toNumber();
	} else {
		return tons; // Soybean meal, flat priced corn, and other ingredients are priced per short ton
	}
}

function convertUnitOfMeasureToUnitOfMeasure(
	value: number,
	initialUnitOfMeasure: UnitOfMeasure,
	finalUnitOfMeasure: UnitOfMeasure,
	productSlug: string | null,
) {
	if (initialUnitOfMeasure === 'Bushels') {
		switch (finalUnitOfMeasure) {
			case 'Bushels':
				return value;
			case 'Tons':
				return Big(value).times(getPoundsPerBushel(productSlug)).div(LBS_PER_TON).toNumber();
			case 'LBS':
				return Big(value).times(getPoundsPerBushel(productSlug)).toNumber();
		}
	} else if (initialUnitOfMeasure === 'Tons') {
		switch (finalUnitOfMeasure) {
			case 'Bushels':
				return Big(value).times(LBS_PER_TON).div(getPoundsPerBushel(productSlug)).toNumber();
			case 'Tons':
				return value;
			case 'LBS':
				return Big(value).times(LBS_PER_TON).toNumber();
		}
	} else if (initialUnitOfMeasure === 'LBS') {
		switch (finalUnitOfMeasure) {
			case 'Bushels':
				return Big(value).div(getPoundsPerBushel(productSlug)).toNumber();
			case 'Tons':
				return Big(value).div(LBS_PER_TON).toNumber();
			case 'LBS':
				return value;
		}
	}

	console.warn(`Unsupported conversion from ${initialUnitOfMeasure} to ${finalUnitOfMeasure}. Initial value returned`);
	return value;
}

function convertPerPricingUnitToPerUnitOfMeasure(
	value: number,
	pricingUnit: PricingUnit,
	unitOfMeasure: UnitOfMeasure,
	productSlug: string | null,
) {
	// Converts a per pricing unit value to a per unit of measure value
	// Example: Convert $/Bushel to $/Ton

	if (pricingUnit !== 'Bushel' && pricingUnit !== 'Ton' && pricingUnit !== 'Pound') {
		throw new Error('Unsupported pricing unit passed. Please update convertPerPricingUnitToPerUnitOfMeasure function');
	}

	const lbsPerTon = LBS_PER_TON;

	// Bushel conversions
	if (pricingUnit === 'Bushel') {
		if (unitOfMeasure === 'Tons') {
			return Big(value).div(getPoundsPerBushel(productSlug)).times(lbsPerTon).toNumber();
		} else if (unitOfMeasure === 'LBS') {
			return Big(value).div(getPoundsPerBushel(productSlug)).toNumber();
		} else {
			return value;
		}
	}

	// Ton conversions
	if (pricingUnit === 'Ton') {
		if (unitOfMeasure === 'Bushels') {
			return Big(value).div(lbsPerTon).times(getPoundsPerBushel(productSlug)).toNumber();
		} else if (unitOfMeasure === 'LBS') {
			return Big(value).div(lbsPerTon).toNumber();
		} else {
			return value;
		}
	}

	// Pricing unit is assumed pounds due to initial check
	// Pound conversions
	if (unitOfMeasure === 'Tons') {
		return Big(value).times(lbsPerTon).toNumber();
	} else if (unitOfMeasure === 'Bushels') {
		return Big(value).times(getPoundsPerBushel(productSlug)).toNumber();
	} else {
		return value;
	}
}

function convertPerUnitOfMeasureToPerUnitOfMeasure(value: number, initialUnitOfMeasure: UnitOfMeasure, finalUnitOfMeasure: UnitOfMeasure) {
	// Converts the per initialUnitOfMeasure value to a per finalUnitOfMeasure value
	// Example: Convert $/Bushel to $/Ton

	const lbsPerBushel = 56;
	const lbsPerTon = 2000;

	// Bushel conversions
	if (initialUnitOfMeasure === 'Bushels') {
		if (finalUnitOfMeasure === 'Tons') {
			return Big(value).div(lbsPerBushel).times(lbsPerTon).toNumber();
		} else if (finalUnitOfMeasure === 'LBS') {
			return Big(value).div(lbsPerBushel).toNumber();
		} else {
			return value;
		}
	}

	// Ton conversions
	if (initialUnitOfMeasure === 'Tons') {
		if (finalUnitOfMeasure === 'Bushels') {
			return Big(value).div(lbsPerTon).times(lbsPerBushel).toNumber();
		} else if (finalUnitOfMeasure === 'LBS') {
			return Big(value).div(lbsPerTon).toNumber();
		} else {
			return value;
		}
	}

	// initialUnitOfMeasure is assumed pounds
	// Pound conversions
	if (finalUnitOfMeasure === 'Tons') {
		return Big(value).times(lbsPerTon).toNumber();
	} else if (finalUnitOfMeasure === 'Bushels') {
		return Big(value).times(lbsPerBushel).toNumber();
	} else {
		return value;
	}
}

const CREATE_FEED_INGREDIENT_MUTATION = gql`
	mutation CreateFeedIngredient($data: FeedIngredientCreateDTO!) {
		createFeedIngredient(data: $data) {
			FeedIngredients {
				id
				feedIngredientId
				dryMatterPercent
				name
				FeedCategory {
					id
				}
			}
			Version {
				id
				name
			}
		}
	}
`;

const UPDATE_FEED_INGREDIENT_MUTATION = gql`
	mutation UpdateFeedIngredient($data: FeedIngredientUpdateDTO!, $id: String!) {
		updateFeedIngredient(data: $data, id: $id) {
			FeedIngredients {
				id
				name
				description
				dryMatterPercent
				flatPricePerTon
				cmeUsdBasis
				cmePercentageBasis
				FeedCategory {
					id
					name
					defaultFlatPricePerTon
					defaultCmePercentageBasis
					defaultCmeUsdBasis
					HedgeProduct {
						id
						slug
						name
						CurrentFutures {
							id
							type
							barchartSymbol
							displayExpiresAt
							SymbolGroup {
								displayFactor
								fractionDigits
							}
						}
					}
				}
			}
			Version {
				id
				name
			}
		}
	}
`;

const DELETE_FEED_INGREDIENT_MUTATION = gql`
	mutation DeleteFeedIngredient($id: String!) {
		deleteFeedIngredient(id: $id) {
			FeedIngredients {
				id
				name
			}
			Version {
				id
				name
			}
		}
	}
`;

const ADD_PHYSICAL_FEED_TRANSACTION = gql`
	mutation CreatePhysicalFeedTransaction($data: PhysicalFeedTransactionCreateDTO!) {
		createPhysicalFeedTransaction(data: $data) {
			id
		}
	}
`;

const UPDATE_PHYSICAL_FEED_TRANSACTION = gql`
	mutation UpdatePhysicalFeedTransaction($id: String!, $data: PhysicalFeedTransactionUpdateDTO!) {
		updatePhysicalFeedTransaction(id: $id, data: $data) {
			id
		}
	}
`;

const SET_FEED_INGREDIENT_FORECASTED_USAGE_BY_MONTH = gql`
	mutation SetFeedIngredientForecastedUsageByMonth($data: FeedIngredientUsageSetByMonthInput!) {
		setFeedIngredientForecastedUsageByMonth(data: $data)
	}
`;

const SET_LIVESTOCK_GROUP_FEED_USAGE_FOR_BUSINESS = gql`
	mutation SetLivestockGroupFeedUsagesForBusiness($data: LivestockGroupFeedUsageSet!) {
		setLivestockGroupFeedUsagesForBusiness(data: $data) {
			currentVersion {
				id
				isCurrent
				createdAt
			}
		}
	}
`;

const SET_FEED_INGREDIENT_PRICE_BY_MONTH = gql`
	mutation SetFeedIngredientPriceByMonth($data: FeedIngredientPriceByMonthSetDTO!) {
		setFeedIngredientPriceByMonth(data: $data)
	}
`;

// To maintain the proper `this` context to glimmer-apollo, we need to pass `this` as the first argument to the CRUD functions.
//
async function createFeedIngredient(context: object, variables: Mutation_createFeedIngredientArgs, customerRole?: string) {
	const mutation = useMutation<{ createFeedIngredient: Mutation['createFeedIngredient'] }, Mutation_createFeedIngredientArgs>(
		context,
		() => [
			CREATE_FEED_INGREDIENT_MUTATION,
			{
				onError: (error): void => {
					console.error(error);
					throw new Error('Unable to create feed ingredient');
				},
				onComplete: () => {
					analyticsCustomTracking('Feed Ingredient Created', { 'Ingredient Added': variables.data.name, 'Business Role': customerRole });
				},
				update: (cache) => {
					cache.evict({ fieldName: 'LedgerFeedCategory' });
					cache.evict({ fieldName: 'LedgerFeedCategories' });
					cache.evict({ fieldName: 'FeedIngredients' });
					cache.evict({ fieldName: 'FeedIngredient' });
					cache.evict({ fieldName: 'FeedIngredientConsumedAndPurchasedVolumes' });
					cache.evict({ fieldName: 'AggregateFeedIngredientConsumedAndPurchasedVolumes' });
					cache.gc();
				},
			},
		],
	);

	await mutation.mutate(variables);
	return mutation.data;
}

async function updateFeedIngredient(context: object, variables: Mutation_updateFeedIngredientArgs, customerRole?: string) {
	const mutation = useMutation<{ updateFeedIngredient: Mutation['updateFeedIngredient'] }, Mutation_updateFeedIngredientArgs>(
		context,
		() => [
			UPDATE_FEED_INGREDIENT_MUTATION,
			{
				onError: (error): void => {
					console.error(error);
					throw new Error('Unable to update feed ingredient');
				},
				onComplete: () => {
					analyticsCustomTracking('Feed Ingredient Edited', { 'Ingredient Edited': variables.data.name, 'Business Role': customerRole });
				},
				update: (cache) => {
					cache.evict({ fieldName: 'LedgerFeedCategory' });
					cache.evict({ fieldName: 'LedgerFeedCategories' });
					cache.evict({ fieldName: 'FeedIngredients' });
					cache.evict({ fieldName: 'FeedIngredient' });
					cache.evict({ fieldName: 'FeedIngredientConsumedAndPurchasedVolumes' });
					cache.evict({ fieldName: 'AggregateFeedIngredientConsumedAndPurchasedVolumes' });
					cache.gc();
				},
			},
		],
	);

	await mutation.mutate(variables);
	return mutation.data;
}

async function deleteFeedIngredient(context: object, variables: Mutation_deleteFeedIngredientArgs, customerRole?: string) {
	const mutation = useMutation<{ updateFeedIngredient: Mutation['deleteFeedIngredient'] }, Mutation_deleteFeedIngredientArgs>(
		context,
		() => [
			DELETE_FEED_INGREDIENT_MUTATION,
			{
				onError: (error): void => {
					console.error(error);
					throw new Error('Unable to delete feed ingredient');
				},
				onComplete: () => {
					analyticsCustomTracking('Feed Ingredient Deleted', { 'Ingredient Delete': variables.id, 'Business Role': customerRole });
				},
				update: (cache) => {
					cache.evict({ fieldName: 'LedgerFeedCategory' });
					cache.evict({ fieldName: 'LedgerFeedCategories' });
					cache.evict({ fieldName: 'FeedIngredients' });
					cache.evict({ fieldName: 'FeedIngredient' });
					cache.evict({ fieldName: 'FeedIngredientConsumedAndPurchasedVolumes' });
					cache.evict({ fieldName: 'AggregateFeedIngredientConsumedAndPurchasedVolumes' });
					cache.gc();
				},
			},
		],
	);

	await mutation.mutate(variables);
	return mutation.data;
}

async function updatePhysicalFeedTransaction(context: object, variables: Mutation_updatePhysicalFeedTransactionArgs) {
	const mutation = useMutation<
		{ updatePhysicalFeedTransaction: Mutation['updatePhysicalFeedTransaction'] },
		Mutation_updatePhysicalFeedTransactionArgs
	>(context, () => [
		UPDATE_PHYSICAL_FEED_TRANSACTION,
		{
			onError: (error): void => {
				console.error(error);
				throw new Error('Unable to update physical feed transaction');
			},
			update: (cache) => {
				cache.evict({ fieldName: 'PhysicalFeedTransactions' });
				cache.evict({ fieldName: 'PhysicalFeedTransactionsCount' });
				cache.evict({ fieldName: 'FeedTransaction' });
				cache.evict({ fieldName: 'FeedTransactions' });
				cache.evict({ fieldName: 'FeedTransactionCount' });
				cache.evict({ fieldName: 'FeedIngredientConsumedAndPurchasedVolumes' });
				cache.evict({ fieldName: 'AggregateFeedIngredientConsumedAndPurchasedVolumes' });
				cache.gc();
			},
		},
	]);

	await mutation.mutate(variables);
}

async function addPhysicalFeedTransaction(context: object, variables: Mutation_createPhysicalFeedTransactionArgs) {
	const mutation = useMutation<
		{ createPhysicalFeedTransaction: Mutation['createPhysicalFeedTransaction'] },
		Mutation_createPhysicalFeedTransactionArgs
	>(context, () => [
		ADD_PHYSICAL_FEED_TRANSACTION,
		{
			onError: (error): void => {
				console.error(error);
				throw new Error('Unable to add physical feed transaction');
			},
			update: (cache) => {
				cache.evict({ fieldName: 'PhysicalFeedTransactions' });
				cache.evict({ fieldName: 'PhysicalFeedTransactionsCount' });
				cache.evict({ fieldName: 'FeedTransaction' });
				cache.evict({ fieldName: 'FeedTransactions' });
				cache.evict({ fieldName: 'FeedTransactionCount' });
				cache.evict({ fieldName: 'FeedIngredientConsumedAndPurchasedVolumes' });
				cache.evict({ fieldName: 'AggregateFeedIngredientConsumedAndPurchasedVolumes' });
				cache.gc();
			},
		},
	]);

	await mutation.mutate(variables);
}

async function setFeedIngredientForecastedUsageByMonth(context: object, variables: Mutation_setFeedIngredientForecastedUsageByMonthArgs) {
	const mutation = useMutation<Mutation['setFeedIngredientForecastedUsageByMonth'], Mutation_setFeedIngredientForecastedUsageByMonthArgs>(
		context,
		() => [
			SET_FEED_INGREDIENT_FORECASTED_USAGE_BY_MONTH,
			{
				onError: (error): void => {
					console.error(error);
					throw new Error('Unable to update feed ingredient usage');
				},
				update: (cache) => {
					cache.evict({ fieldName: 'FeedIngredientConsumedAndPurchasedVolumes' });
					cache.evict({ fieldName: 'AggregateFeedIngredientConsumedAndPurchasedVolumes' });
					cache.evict({ fieldName: 'AggregateFeedIngredientForecastedUsages' });
					cache.evict({ fieldName: 'FeedIngredientForecastedUsages' });
					cache.evict({ fieldName: 'FeedIngredientConsumedAndPurchasedVolumes' });
					cache.evict({ fieldName: 'AggregateFeedIngredientConsumedAndPurchasedVolumes' });
					cache.gc();
				},
			},
		],
	);

	await mutation.mutate(variables);
	return mutation.data;
}

async function setLivestockGroupFeedUsageForBusiness(context: object, variables: Mutation_setLivestockGroupFeedUsagesForBusinessArgs) {
	const mutation = useMutation<Mutation['setLivestockGroupFeedUsagesForBusiness'], Mutation_setLivestockGroupFeedUsagesForBusinessArgs>(
		context,
		() => [
			SET_LIVESTOCK_GROUP_FEED_USAGE_FOR_BUSINESS,
			{
				onError: (error): void => {
					console.error(error);
					throw new Error('Unable to update livestock feed ingredient usage');
				},
				update: (cache) => {
					cache.evict({ fieldName: 'SwineLivestockGroupFeedUsages' });
					cache.evict({ fieldName: 'SwineLivestockGroupFeedUsageCount' });

					cache.evict({ fieldName: 'LivestockGroupFeedUsages' });
					cache.evict({ fieldName: 'LivestockGroupFeedUsageCount' });
					cache.evict({ fieldName: 'FeedIngredientConsumedAndPurchasedVolumes' });
					cache.evict({ fieldName: 'AggregateFeedIngredientConsumedAndPurchasedVolumes' });
					cache.evict({ fieldName: 'AggregateFeedIngredientForecastedUsages' });
					cache.evict({ fieldName: 'FeedIngredientForecastedUsages' });
				},
			},
		],
	);

	await mutation.mutate(variables);
	return mutation.data;
}

async function editFeedIngredientPrice(context: object, variables: Mutation_setFeedIngredientPriceByMonthArgs) {
	const mutation = useMutation<Mutation['setFeedIngredientPriceByMonth'], Mutation_setFeedIngredientPriceByMonthArgs>(context, () => [
		SET_FEED_INGREDIENT_PRICE_BY_MONTH,
		{
			onError: (error): void => {
				console.error(error);
				throw new Error('Unable to edit feed ingredient price');
			},
			update: (cache) => {
				cache.evict({ fieldName: 'LedgerFeedCategory' });
				cache.evict({ fieldName: 'LedgerFeedCategories' });
				cache.evict({ fieldName: 'FeedIngredients' });
				cache.evict({ fieldName: 'FeedIngredient' });
				cache.evict({ fieldName: 'FeedIngredientConsumedAndPurchasedVolumes' });
				cache.evict({ fieldName: 'AggregateFeedIngredientConsumedAndPurchasedVolumes' });
				cache.gc();
			},
		},
	]);

	await mutation.mutate(variables);
	return mutation.data;
}

function getDefaultPricing(feedCategory: FeedCategory | null | undefined): DefaultPricing {
	const defaultFlatPricePerTon = feedCategory?.defaultFlatPricePerTon;
	const defaultCmeUsdBasis = feedCategory?.defaultCmeUsdBasis;
	const defaultCmePercentageBasis = feedCategory?.defaultCmePercentageBasis;

	if (defaultFlatPricePerTon != null) {
		return {
			feedPriceType: 'Spot Price',
			value: defaultFlatPricePerTon,
		};
	} else if (defaultCmeUsdBasis != null) {
		return {
			feedPriceType: 'CME Basis (USD)',
			value: defaultCmeUsdBasis,
		};
	} else if (defaultCmePercentageBasis != null) {
		return {
			feedPriceType: 'CME Basis (%)',
			value: defaultCmePercentageBasis,
		};
	}

	return {
		feedPriceType: 'Spot Price',
		value: null,
	};
}

function getDefaultIngredientPricing(feedIngredient: FeedIngredient): DefaultPricing {
	// Return the default price for the feed ingredient, falling back to the feed category default pricing if the ingredient does not have it set

	if (feedIngredient.flatPricePerTon != null) {
		return {
			feedPriceType: 'Spot Price',
			value: feedIngredient.flatPricePerTon,
		};
	} else if (feedIngredient.cmePercentageBasis != null) {
		return {
			feedPriceType: 'CME Basis (%)',
			value: feedIngredient.cmePercentageBasis,
		};
	} else if (feedIngredient.cmeUsdBasis != null) {
		return {
			feedPriceType: 'CME Basis (USD)',
			value: feedIngredient.cmeUsdBasis,
		};
	} else if (feedIngredient.FeedCategory.defaultFlatPricePerTon != null) {
		return {
			feedPriceType: 'Spot Price',
			value: feedIngredient.FeedCategory.defaultFlatPricePerTon,
		};
	} else if (feedIngredient.FeedCategory.defaultCmePercentageBasis != null) {
		return {
			feedPriceType: 'CME Basis (%)',
			value: feedIngredient.FeedCategory.defaultCmePercentageBasis,
		};
	} else if (feedIngredient.FeedCategory.defaultCmeUsdBasis != null) {
		return {
			feedPriceType: 'CME Basis (USD)',
			value: feedIngredient.FeedCategory.defaultCmeUsdBasis,
		};
	}

	return {
		feedPriceType: 'Spot Price',
		value: null,
	};
}

function parseCreateEditFeedIngredientData(feedIngredientData: CreateEditFeedIngredientData, unitOfMeasure?: UnitOfMeasure) {
	const dmiPercentage = parseFloat(feedIngredientData.dmiPercentage.trim());
	const ingredientName = feedIngredientData.ingredientName.trim();
	const displayFactor = feedIngredientData.feedCategory?.HedgeProduct?.MostCurrentFuture?.SymbolGroup?.displayFactor ?? 1;
	const feedCategoryId = feedIngredientData.feedCategory?.id;
	const hedgeProductSlug = parseHedgeProductSlugFromFeedCategory(feedIngredientData.feedCategory) ?? null;
	const pricingUnit: PricingUnit = hedgeProductSlug ? productSlugToPricingUnit[hedgeProductSlug]?.pricingUnit ?? 'Ton' : 'Ton';
	const finalBasisUnitOfMeasure = mapPricingUnitToUnitOfMeasure(pricingUnit);

	if (dmiPercentage > 100) {
		throw new Error('DMI Percentage cannot be greater than 100%');
	}

	if (dmiPercentage < 0) {
		throw new Error('DMI Percentage cannot be less than 0%');
	}

	if (!feedCategoryId) {
		throw new Error('Feed Category could not be found');
	}

	const data: {
		feedCategoryId: string;
		dryMatterPercent: number;
		name: string;
		flatPricePerTon: number | null;
		cmeUsdBasis: number | null;
		cmePercentageBasis: number | null;
	} = {
		feedCategoryId,
		dryMatterPercent: dmiPercentage / 100,
		name: ingredientName,
		flatPricePerTon: null,
		cmeUsdBasis: null,
		cmePercentageBasis: null,
	};

	if (feedIngredientData.feedPriceType === 'Spot Price') {
		let parsedFlatPricePerTon: number | undefined = undefined;

		if (unitOfMeasure) {
			const flatPricePerUOM = feedIngredientData.flatPricePerTon?.trim().length
				? parseFloat(feedIngredientData.flatPricePerTon.trim())
				: undefined;
			parsedFlatPricePerTon =
				flatPricePerUOM != undefined ? convertPerUnitOfMeasureToPerUnitOfMeasure(flatPricePerUOM, unitOfMeasure, 'Tons') : undefined;
		} else {
			parsedFlatPricePerTon = feedIngredientData.flatPricePerTon?.trim().length
				? parseFloat(feedIngredientData.flatPricePerTon.trim())
				: undefined;
		}

		if (parsedFlatPricePerTon && parsedFlatPricePerTon < 0) {
			throw new Error('Spot Price cannot be a negative number');
		}

		data.flatPricePerTon = parsedFlatPricePerTon ?? null;
	} else if (feedIngredientData.feedPriceType === 'CME Basis (USD)') {
		let parsedCmeUsdBasis: number | undefined = undefined;

		if (unitOfMeasure) {
			const parsedCmeUsdBasisPerUOM = feedIngredientData.cmeBasisUsd?.trim().length
				? parseFloat(feedIngredientData.cmeBasisUsd.trim())
				: undefined;
			parsedCmeUsdBasis =
				parsedCmeUsdBasisPerUOM != undefined
					? convertPerUnitOfMeasureToPerUnitOfMeasure(parsedCmeUsdBasisPerUOM, unitOfMeasure, finalBasisUnitOfMeasure)
					: undefined;
		} else {
			parsedCmeUsdBasis = feedIngredientData.cmeBasisUsd?.trim().length ? parseFloat(feedIngredientData.cmeBasisUsd.trim()) : undefined;
		}
		const unadjustedBasis = parsedCmeUsdBasis ?? null;

		data.cmeUsdBasis = unadjustedBasis !== null ? unadjustedBasis / displayFactor : null;
	} else if (feedIngredientData.feedPriceType === 'CME Basis (%)') {
		const parsedCmePercentageBasis = feedIngredientData.cmeBasisPercentage?.trim().length
			? parseFloat(feedIngredientData.cmeBasisPercentage.trim()) / 100
			: undefined;

		if (parsedCmePercentageBasis == 0) {
			throw new Error('CME Basis (%) cannot be zero');
		}

		if (parsedCmePercentageBasis && parsedCmePercentageBasis < 0) {
			throw new Error('CME Basis (%) cannot be a negative number');
		}

		data.cmePercentageBasis = parsedCmePercentageBasis ?? null;
	}

	// cmePercentageBasis cannot be zero, but cmeUsdBasis and flatPricePerTon can
	// Apply default pricing if no price is set
	if (!data.cmePercentageBasis && data.flatPricePerTon === null && data.cmeUsdBasis === null) {
		const defaultPricing = getDefaultPricing(feedIngredientData.feedCategory);
		const defaultPricingValue = defaultPricing.value;

		// Check that a default price value exists. If not, show an error and require user to input a value.
		if (defaultPricingValue === null) {
			throw new Error('Feed Price Type could not be set. Please ensure it is included');
		}

		if (defaultPricing.feedPriceType === 'Spot Price') {
			data.flatPricePerTon = defaultPricingValue;
		} else if (defaultPricing.feedPriceType === 'CME Basis (USD)') {
			data.cmeUsdBasis = defaultPricingValue;
		} else if (defaultPricing.feedPriceType === 'CME Basis (%)') {
			data.cmePercentageBasis = defaultPricingValue;
		}
	}

	if ((data.cmeUsdBasis !== null || data.cmePercentageBasis !== null) && !feedIngredientData.feedCategory?.HedgeProduct) {
		throw new Error('CME Basis can only be set on ingredients whose category has a hedge product');
	}

	return data;
}

function clearCreateEditFeedIngredientData(feedIngredientData: CreateEditFeedIngredientData) {
	feedIngredientData.error = '';
	feedIngredientData.ingredientName = '';
	feedIngredientData.dmiPercentage = '100';
	feedIngredientData.feedPriceType = 'Spot Price';
	feedIngredientData.flatPricePerTon = '';
	feedIngredientData.cmeBasisUsd = '';
	feedIngredientData.cmeBasisPercentage = '';
	feedIngredientData.feedCategory = null;
}

function findNearestFuture(futures: Future[], productSlug: string, month: string) {
	// We sort futures asc by display expires at, allowing a >= check for finding the nearest future
	// If the date is too far in the future to be priced, use the last future
	const relevantFutures = futures.filter((future) => future.Product.slug === productSlug) ?? [];
	const sortedRelevantFutures = relevantFutures.sortBy('displayExpiresAt');
	return sortedRelevantFutures?.find((future) => future.displayExpiresAt >= month) ?? futures?.[futures?.length - 1] ?? null;
}

function isUnitOfMeasure(unitOfMeasure: unknown): unitOfMeasure is UnitOfMeasure {
	if (typeof unitOfMeasure !== 'string') return false;
	return ['Tons', 'Bushels', 'LBS'].includes(unitOfMeasure);
}

function getDefaultUnitOfMeasure(hedgeProductSlug: string | null | undefined): UnitOfMeasure {
	// Default to Tons for all products except corn
	if (hedgeProductSlug === 'grain-corn') {
		return 'Bushels';
	} else {
		return 'Tons';
	}
}

function isAppropriateUnitOfMeasure(hedgeProductSlug: string | null, unitOfMeasure: UnitOfMeasure): boolean {
	if (hedgeProductSlug === 'grain-corn') {
		return ['Bushels', 'LBS', 'Tons'].includes(unitOfMeasure);
	} else {
		return ['LBS', 'Tons'].includes(unitOfMeasure);
	}
}

function getBusinessUOMLocalStorageKey(businessId: string): string {
	return businessId ? `defaultUnitsOfMeasure-${businessId}` : '';
}

function getLocallyStoredUnitOfMeasure(ingredientId: string, businessId: string): UnitOfMeasure | null {
	if (!checkStorageAvailable('localStorage')) return null;

	const localStorageBusinessObjectKey = getBusinessUOMLocalStorageKey(businessId);
	if (!localStorageBusinessObjectKey) return null;

	const localStorageBusinessObject = localStorage.getItem(localStorageBusinessObjectKey);
	const localStorageObject = localStorageBusinessObject ? JSON.parse(localStorageBusinessObject) : {};

	const value = localStorageObject[ingredientId];

	return isUnitOfMeasure(value) ? value : null;
}

function setLocallyStoredUnitOfMeasure(ingredientId: string | null, businessId: string | null, value: UnitOfMeasure | null) {
	if (!ingredientId || !businessId || !value) return;

	if (!checkStorageAvailable('localStorage')) return;

	const localStorageBusinessObjectKey = getBusinessUOMLocalStorageKey(businessId);
	if (!localStorageBusinessObjectKey) return;

	const localStorageBusinessObject = localStorage.getItem(localStorageBusinessObjectKey);
	const localStorageObject = localStorageBusinessObject ? JSON.parse(localStorageBusinessObject) : {};

	localStorageObject[ingredientId] = value;

	localStorage.setItem(localStorageBusinessObjectKey, JSON.stringify(localStorageObject));
}

function getCurrentUnitOfMeasure(ingredientId: string | null, hedgeProductSlug: string | null, businessId: string | null) {
	let unitOfMeasure = getDefaultUnitOfMeasure(hedgeProductSlug);

	if (!ingredientId || !businessId) return unitOfMeasure;

	const locallyStoredUnitOfMeasure = getLocallyStoredUnitOfMeasure(ingredientId, businessId);
	if (locallyStoredUnitOfMeasure && isAppropriateUnitOfMeasure(hedgeProductSlug, locallyStoredUnitOfMeasure)) {
		unitOfMeasure = locallyStoredUnitOfMeasure;
	}

	return unitOfMeasure;
}

function getBusinessFeedIngredientLocalStorageKey(businessId: string): string {
	return businessId ? `feed-ingredient-${businessId}` : '';
}

function getLocallyStoredFeedIngredientValue<T = unknown>(
	property: string,
	ingredientId: string | null,
	businessId: string | null,
): T | null {
	if (!checkStorageAvailable('localStorage') || !ingredientId || !businessId) return null;

	const localStorageBusinessObjectKey = getBusinessFeedIngredientLocalStorageKey(businessId);
	if (!localStorageBusinessObjectKey) return null;

	const localStorageBusinessObject = localStorage.getItem(localStorageBusinessObjectKey);
	const localStorageObject = localStorageBusinessObject ? JSON.parse(localStorageBusinessObject) : {};

	const ingredientStateObject = localStorageObject[ingredientId] as Record<string, T> | undefined;

	const storedPropertyValue = ingredientStateObject?.[property] ?? null;

	return storedPropertyValue;
}

function setLocallyStoredFeedIngredientValue<T = unknown>(
	value: T,
	property: string,
	ingredientId: string | null,
	businessId: string | null,
) {
	if (!checkStorageAvailable('localStorage') || !ingredientId || !businessId) return;

	const localStorageBusinessObjectKey = getBusinessFeedIngredientLocalStorageKey(businessId);

	const localStorageBusinessObject = localStorage.getItem(localStorageBusinessObjectKey);
	const localStorageObject = localStorageBusinessObject ? JSON.parse(localStorageBusinessObject) : {};

	if (!localStorageObject[ingredientId]) {
		localStorageObject[ingredientId] = {};
	}

	const ingredientStateObject = localStorageObject[ingredientId] as Record<string, unknown>;
	ingredientStateObject[property] = value;

	localStorage.setItem(localStorageBusinessObjectKey, JSON.stringify(localStorageObject));
}

function updateCreateEditFeedIngredientFormData(
	createFeedIngredientFormData: CreateEditFeedIngredientData,
	key: keyof CreateEditFeedIngredientData,
	value: CreateEditFeedIngredientData[keyof CreateEditFeedIngredientData],
) {
	if (
		typeof value === 'string' &&
		(key === 'cmeBasisPercentage' ||
			key === 'cmeBasisUsd' ||
			key === 'flatPricePerTon' ||
			key === 'dmiPercentage' ||
			key === 'error' ||
			key === 'ingredientName')
	) {
		set(createFeedIngredientFormData, key, value);
		return;
	}

	if (key === 'feedPriceType' && (value === 'Spot Price' || value === 'CME Basis (USD)' || value === 'CME Basis (%)')) {
		set(createFeedIngredientFormData, key, value);
		return;
	}

	if (key === 'feedCategory' && (value == null || typeof value === 'object')) {
		set(createFeedIngredientFormData, key, value);
		return;
	}

	throw new Error(`Invalid key/value pair: ${key}/${value}`);
}

function getCurrentFeedPriceType(feedIngredient: FeedIngredient | null): FeedPriceType {
	if (feedIngredient?.flatPricePerTon != null) {
		return 'Spot Price';
	} else if (feedIngredient?.cmeUsdBasis != null) {
		return 'CME Basis (USD)';
	} else if (feedIngredient?.cmePercentageBasis != null) {
		return 'CME Basis (%)';
	}

	return 'Spot Price';
}

function generateCreateEditFeedIngredientData(
	feedIngredient: FeedIngredient | null,
	unitOfMeasure?: UnitOfMeasure,
): CreateEditFeedIngredientData {
	const displayFactor = feedIngredient?.FeedCategory?.HedgeProduct?.MostCurrentFuture?.SymbolGroup.displayFactor ?? 1;

	let flatPricePerTon = feedIngredient?.flatPricePerTon;
	let cmeUsdBasis = feedIngredient?.cmeUsdBasis;
	const pricingUnit = feedIngredient ? getPricingUnitForFeedIngredient(feedIngredient) : 'Ton';
	const pricingUnitOfMeasure = mapPricingUnitToUnitOfMeasure(pricingUnit);

	if (unitOfMeasure) {
		flatPricePerTon = flatPricePerTon ? convertPerUnitOfMeasureToPerUnitOfMeasure(flatPricePerTon, 'Tons', unitOfMeasure) : flatPricePerTon;
		cmeUsdBasis = cmeUsdBasis ? convertPerUnitOfMeasureToPerUnitOfMeasure(cmeUsdBasis, pricingUnitOfMeasure, unitOfMeasure) : cmeUsdBasis;
	}

	return new TrackedObject({
		error: '',
		dmiPercentage: feedIngredient?.dryMatterPercent ? (feedIngredient.dryMatterPercent * 100).toString() : '',
		feedCategory: feedIngredient?.FeedCategory ?? null,
		ingredientName: feedIngredient?.name ?? '',
		feedPriceType: getCurrentFeedPriceType(feedIngredient ?? null),
		flatPricePerTon: flatPricePerTon != null ? flatPricePerTon.toString() : '',
		cmeBasisUsd: cmeUsdBasis != null ? Big(cmeUsdBasis).times(displayFactor).toString() : '',
		cmeBasisPercentage: feedIngredient?.cmePercentageBasis ? (feedIngredient.cmePercentageBasis * 100).toString() : '',
	});
}

function getIngredientTableId(feedIngredient: FeedIngredient) {
	// Use the feed ingredient Id if it exists, otherwise create a UUID based on the name
	// This ensures state is consistent between ingredient versions
	return feedIngredient.id ?? uuid(feedIngredient.name, UUID_NAMESPACE);
}

function convertTonsToUnitOfMeasure(tons: number, unitOfMeasure: UnitOfMeasure, productSlug: string | null) {
	if (unitOfMeasure === 'Tons') {
		return tons;
	} else if (unitOfMeasure === 'LBS') {
		return tons * LBS_PER_TON;
	} else if (unitOfMeasure === 'Bushels') {
		return Big(tons).times(LBS_PER_TON).div(getPoundsPerBushel(productSlug)).toNumber();
	}
	return tons;
}

function convertUnitsOfMeasureToTons(value: number, unitOfMeasure: UnitOfMeasure, productSlug: string | null) {
	if (unitOfMeasure === 'LBS') {
		return Big(value).div(LBS_PER_TON).toNumber();
	} else if (unitOfMeasure === 'Bushels') {
		return Big(value).times(getPoundsPerBushel(productSlug)).div(LBS_PER_TON).toNumber();
	} else {
		return value;
	}
}

function convertUnitOfMeasureToPricingUnit(uom: UnitOfMeasure): PricingUnit {
	switch (uom) {
		case 'Bushels':
			return 'Bushel';
		case 'Tons':
			return 'Ton';
		case 'LBS':
			return 'Pound';
	}
}

function mapPricingUnitToUnitOfMeasure(pricingUnit: PricingUnit): UnitOfMeasure {
	switch (pricingUnit) {
		case 'Bushel':
			return 'Bushels';
		case 'Ton':
			return 'Tons';
		case 'Pound':
			return 'LBS';
		default:
			throw new Error('Unsupported pricing unit was passed to mapPricingUnitToUnitOfMeasure: ' + pricingUnit);
	}
}

function isMarketFeedPriceType(feedPriceType: FeedPriceType) {
	return feedPriceType === 'CME Basis (USD)' || feedPriceType === 'CME Basis (%)';
}

function isMarketPricedIngredient(feedIngredient: FeedIngredient) {
	return isMarketFeedPriceType(getDefaultIngredientPricing(feedIngredient).feedPriceType);
}

type FutureInfo = {
	price: number | null;
	displayFactor: number;
};
function getPurchasedAndEstimatedFeedCosts(
	consumedAndPurchasedVolumes: FeedIngredientConsumedAndPurchasedVolume[],
	cornFutureInfo: FutureInfo,
	soybeanMealFutureInfo: FutureInfo,
): { feedNotPurchasedTotalCostInUsd: Big.Big | null; feedPurchasedTotalCostInUsd: Big.Big | null } {
	if (consumedAndPurchasedVolumes.length === 0) return { feedNotPurchasedTotalCostInUsd: null, feedPurchasedTotalCostInUsd: null };

	// Calculate purchased costs (Feed Fills) and forecasted purchase costs at market price
	return consumedAndPurchasedVolumes.reduce(
		(acc, volume) => {
			// This future price is going to be based on the hedge product of the current ingredient. Corn, Soybean Meal, or null (for non-market hedged ingredients)
			let futurePrice: number | null = null;
			let displayFactor = 1;
			const productSlug: string | null = parseHedgeProductSlugFromFeedIngredient(volume.FeedIngredient) ?? null;

			if (productSlug === 'grain-corn') {
				futurePrice = cornFutureInfo.price;
				displayFactor = cornFutureInfo.displayFactor;
			} else if (productSlug === 'grain-soybean-meal') {
				futurePrice = soybeanMealFutureInfo.price;
				displayFactor = soybeanMealFutureInfo.displayFactor;
			}

			const month = volume.monthStartDate;
			const notPurchasedInTons = Math.max((volume.forecastedConsumptionInTons ?? 0) - (volume.purchasedInTons ?? 0), 0);
			const notPurchasedInPricingUnits = convertTonsToPricingUnit(volume.FeedIngredient, notPurchasedInTons, productSlug);
			const feedMarketPrice = getFeedIngredientMarketPrice(volume.FeedIngredient, futurePrice, displayFactor, month);
			const notPurchasedForecastedCost = Big(notPurchasedInPricingUnits).times(feedMarketPrice ?? 0);

			acc.feedNotPurchasedTotalCostInUsd = acc.feedNotPurchasedTotalCostInUsd.plus(notPurchasedForecastedCost);
			acc.feedPurchasedTotalCostInUsd = acc.feedPurchasedTotalCostInUsd.plus(volume.totalPurchasedCostInUsd ?? 0);

			return acc;
		},
		{ feedNotPurchasedTotalCostInUsd: Big(0), feedPurchasedTotalCostInUsd: Big(0) },
	);
}

function parseEditPhysicalFeedTransactionFormDataFromTransaction(
	feedTransaction: PhysicalFeedTransaction,
	desiredUoM?: UnitOfMeasure,
): AddEditPhysicalFeedTransactionFormData {
	const hedgeProductSlug = parseHedgeProductSlugFromFeedIngredient(feedTransaction.FeedIngredient) ?? null;
	const futurePricingUOM = mapPricingUnitToUnitOfMeasure(getFuturePricingUnit(hedgeProductSlug));

	const data = {
		id: feedTransaction.id,
		contractNumber: feedTransaction.contractIdentifier ?? null,
		feedIngredient: feedTransaction.FeedIngredient,
		tons: roundTo(
			convertUnitOfMeasureToUnitOfMeasure(feedTransaction.tons, 'Tons', desiredUoM ?? futurePricingUOM, hedgeProductSlug ?? null),
			5,
		).toString(),
		deliveryStartDate: feedTransaction.deliveryStartDate,
		deliveryEndDate: feedTransaction.deliveryEndDate,
		flatPrice: feedTransaction.flatPrice
			? roundTo(convertPerUnitOfMeasureToPerUnitOfMeasure(feedTransaction.flatPrice, 'Tons', desiredUoM ?? futurePricingUOM), 5).toString()
			: null,
		futuresPrice: feedTransaction.futuresPrice
			? roundTo(
					convertPerUnitOfMeasureToPerUnitOfMeasure(feedTransaction.futuresPrice, 'Tons', desiredUoM ?? futurePricingUOM),
					5,
				).toString()
			: null,
		basisPrice: feedTransaction.basisPrice
			? roundTo(convertPerUnitOfMeasureToPerUnitOfMeasure(feedTransaction.basisPrice, 'Tons', desiredUoM ?? futurePricingUOM), 5).toString()
			: null,
		deliveryTerms: feedTransaction.deliveryTerms ?? null,
		seller: feedTransaction.seller ?? null,
		location: feedTransaction.location ?? null,
	};

	return data;
}

function convertPerPricingUnitInPointsToPerTonInDollars(params: {
	valuePerPricingUnitInPoints: number | null;
	pricingUnit: PricingUnit;
	pointValue: number;
	lotSize: number;
	productSlug?: string | null;
}) {
	const { valuePerPricingUnitInPoints: value, pricingUnit, pointValue, lotSize, productSlug } = params;

	if (value == null) return null;

	const valuePerPricingUnitInDollars = Big(value).times(Big(pointValue).div(lotSize)).toNumber();
	const valuePerTonInDollars = convertPerPricingUnitToPerUnitOfMeasure(
		valuePerPricingUnitInDollars,
		pricingUnit,
		'Tons',
		productSlug ?? null,
	);

	return valuePerTonInDollars;
}

function getFeedContractTonsForMonth(contract: Partial<FeedTransaction>, month: string) {
	const { perMonthData } = contract;
	const tonsForMonth = perMonthData?.find((monthData) => monthData.date === month)?.tons as number | undefined;

	if (tonsForMonth == undefined) {
		debug(`perMonthData not found for ${month}`);
	}

	return tonsForMonth;
}

function getFeedContractMonthOrTotalTons(contract: Partial<FeedTransaction>, month?: string) {
	// Return per month data if month is supplied, otherwise return total contract tons
	return month ? getFeedContractTonsForMonth(contract, month) : contract.tons;
}

function calculateFlatFeedContractPrice(contract: Partial<FeedTransaction>, month?: string) {
	const { flatPrice: flatPricePerTon } = contract;

	if (flatPricePerTon == null) {
		debug('contract.flatPrice was not provided. It is required');
		return null;
	}

	const tons = getFeedContractMonthOrTotalTons(contract, month);
	if (tons == null) {
		debug('Tons could not be determined. Pass contract.tons or contract.perMonthData and a month');
		return null;
	}

	return Big(flatPricePerTon).times(tons).toNumber();
}

function calculateBasisOnlyFeedContractPrice(params: {
	contract: Partial<FeedTransaction>;
	futuresPriceInPointsPerPricingUnit: number;
	pricingUnit: PricingUnit;
	pointValue: number;
	lotSize: number;
	productSlug?: string | null;
	month?: string;
}) {
	const {
		contract,
		contract: { basisPrice: basisInDollarsPerTon },
		futuresPriceInPointsPerPricingUnit: futuresPrice,
		pricingUnit,
		pointValue,
		lotSize,
		productSlug,
		month,
	} = params;

	const tons = getFeedContractMonthOrTotalTons(contract, month);
	if (tons == null) {
		debug('Tons could not be determined. Pass contract.tons or contract.perMonthData and a month');
		return null;
	}

	if (tons === 0) return 0;

	if (basisInDollarsPerTon == undefined) {
		debug('contract.basisPrice was not provided. It is required');
		return null;
	}

	const futuresPriceInDollarsPerTon = convertPerPricingUnitInPointsToPerTonInDollars({
		valuePerPricingUnitInPoints: futuresPrice,
		pricingUnit,
		pointValue,
		lotSize,
		productSlug,
	});

	if (futuresPriceInDollarsPerTon == null) return 0;

	const pricePerTon = futuresPriceInDollarsPerTon + basisInDollarsPerTon;
	return pricePerTon * tons;
}

function calculateFuturesOnlyFeedContractPrice(params: {
	contract: Partial<FeedTransaction>;
	pricingUnit: PricingUnit;
	pointValue: number;
	lotSize: number;
	cmeUsdBasisPerPricingUnitInPoints?: number | null;
	cmePercentageBasis?: number | null;
	productSlug?: string | null;
	month?: string;
}) {
	const {
		contract,
		contract: { futuresPrice: futuresPriceInDollarsPerTon },
		pricingUnit,
		pointValue,
		lotSize,
		cmeUsdBasisPerPricingUnitInPoints: cmeUsdBasis,
		cmePercentageBasis,
		productSlug,
		month,
	} = params;

	const tons = getFeedContractMonthOrTotalTons(contract, month);
	if (tons == null) {
		debug('Tons could not be determined. Pass contract.tons or contract.perMonthData and a month');
		return null;
	}

	if (tons === 0) return 0;

	if (futuresPriceInDollarsPerTon == null) {
		debug('contract.futuresPrice is undefined. It is required for FuturesOnly FeedTransactions.');
		return null;
	}

	let pricePerTon: Big.Big;

	if (cmeUsdBasis != null) {
		const cmeUsdBasisPerTonInDollars = convertPerPricingUnitInPointsToPerTonInDollars({
			valuePerPricingUnitInPoints: cmeUsdBasis,
			pricingUnit,
			pointValue,
			lotSize,
			productSlug,
		});

		if (cmeUsdBasisPerTonInDollars == null) {
			debug('Conversion of cmeUsdBasis to cmeUsdBasisPerTonInDollars failed');
			return null;
		}

		pricePerTon = Big(futuresPriceInDollarsPerTon).plus(cmeUsdBasisPerTonInDollars);
	} else if (cmePercentageBasis != null) {
		pricePerTon = Big(futuresPriceInDollarsPerTon).times(cmePercentageBasis);
	} else {
		debug('Basis missing. Either a USD or percentage basis must be provided');
		return null;
	}

	return pricePerTon.times(tons).toNumber();
}

export {
	createFeedIngredient,
	updateFeedIngredient,
	deleteFeedIngredient,
	getDefaultPricing,
	parseCreateEditFeedIngredientData,
	clearCreateEditFeedIngredientData,
	getFeedIngredientMarketPrice,
	convertTonsToPricingUnit,
	getPricingUnitForFeedIngredient,
	findNearestFuture,
	isUnitOfMeasure,
	getDefaultUnitOfMeasure,
	isAppropriateUnitOfMeasure,
	getBusinessUOMLocalStorageKey,
	getCurrentUnitOfMeasure,
	getLocallyStoredUnitOfMeasure,
	setLocallyStoredUnitOfMeasure,
	updateCreateEditFeedIngredientFormData,
	getCurrentFeedPriceType,
	generateCreateEditFeedIngredientData,
	getIngredientTableId,
	convertTonsToUnitOfMeasure,
	convertUnitsOfMeasureToTons,
	convertUnitOfMeasureToUnitOfMeasure,
	convertPerPricingUnitToPerUnitOfMeasure,
	createTrackedEmptyPhysicalFeedTransactionData,
	updatePhysicalFeedTransaction,
	addPhysicalFeedTransaction,
	validateEditPhysicalFeedTransactionFormData,
	validateAddFormData,
	setFeedIngredientForecastedUsageByMonth,
	setLivestockGroupFeedUsageForBusiness,
	getLocallyStoredFeedIngredientValue,
	setLocallyStoredFeedIngredientValue,
	convertUnitOfMeasureToPricingUnit,
	convertPerUnitOfMeasureToPerUnitOfMeasure,
	getDefaultIngredientPricing,
	mapPricingUnitToUnitOfMeasure,
	editFeedIngredientPrice,
	getPurchasedAndEstimatedFeedCosts,
	getFuturePricingUnit,
	parseEditPhysicalFeedTransactionFormDataFromTransaction,
	parseHedgeProductSlugFromFeedCategory,
	parseHedgeProductSlugFromFeedIngredient,
	isMarketFeedPriceType,
	isMarketPricedIngredient,
	calculateFlatFeedContractPrice,
	calculateBasisOnlyFeedContractPrice,
	calculateFuturesOnlyFeedContractPrice,
	getFeedContractMonthOrTotalTons,
	FeedPriceType,
	DefaultPricing,
	UnitOfMeasure,
	PhysicalContractType,
	FEED_YEAR_OPTIONS,
	CURRENT_FEED_YEAR,
};
