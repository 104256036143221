import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div {{this.registerStickyElements}} data-test-ember-table-overflow class='ember-table-overflow' id='{{this.elementId}}-overflow'>\n\t<table>\n\t\t{{yield\n\t\t\t(hash\n\t\t\t\tapi=this.api\n\t\t\t\thead=(component 'ember-thead' api=this.api)\n\t\t\t\tbody=(component 'ember-tbody' api=this.api)\n\t\t\t\tfoot=(component 'ember-tfoot' api=this.api)\n\t\t\t\tloadingMore=(component 'ember-table-loading-more' api=this.api)\n\t\t\t)\n\t\t}}\n\t</table>\n</div>\n<EmberTablePrivate::ScrollIndicators @api={{this.api}} />", {"contents":"<div {{this.registerStickyElements}} data-test-ember-table-overflow class='ember-table-overflow' id='{{this.elementId}}-overflow'>\n\t<table>\n\t\t{{yield\n\t\t\t(hash\n\t\t\t\tapi=this.api\n\t\t\t\thead=(component 'ember-thead' api=this.api)\n\t\t\t\tbody=(component 'ember-tbody' api=this.api)\n\t\t\t\tfoot=(component 'ember-tfoot' api=this.api)\n\t\t\t\tloadingMore=(component 'ember-table-loading-more' api=this.api)\n\t\t\t)\n\t\t}}\n\t</table>\n</div>\n<EmberTablePrivate::ScrollIndicators @api={{this.api}} />","moduleName":"vault-client/components/ember-table.hbs","parseOptions":{"srcName":"vault-client/components/ember-table.hbs"}});
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/require-tagless-components */

// Converting Ember Table to a glimmer component is likely not a worthwhile endeavor.
// We should consider using a more modern table library
import { htmlSafe } from '@ember/template';
import { setupTableStickyPolyfill, teardownTableStickyPolyfill } from 'vault-client/utils/ember-table/table-sticky-polyfill';
import Component from '@ember/component';
import { modifier } from 'ember-modifier';

/**
  The primary Ember Table component. This component represents the root of the
  table, and manages high level state of all of its subcomponents. It does not
  have any arguments or actions itself - instead, all of those concerns are
  delegated to its children, who communicate to each other via the API.

  ```hbs
  <EmberTable as |t|>
    <t.head @columns={{this.columns}} />
    <t.body @rows={{this.rows}} />
    <t.foot @rows={{this.footerRows}} />
  </EmberTable>
  ```

  @yield {object} table - the API object yielded by the table
  @yield {Component} table.head - The table header component
  @yield {Component} table.body - The table body component
  @yield {Component} table.foot - The table footer component
  @class <EmberTable />
  @public
*/
export default class EmberTable extends Component {
	classNames = ['ember-table'];
	attributeBindings = ['dataTestEmberTable:data-test-ember-table'];
	dataTestEmberTable = true;

	registerStickyElements = modifier((element) => {
		let thead = element.querySelector('thead');
		let tfoot = element.querySelector('tfoot');

		if (thead) {
			setupTableStickyPolyfill(thead);
		}
		if (tfoot) {
			setupTableStickyPolyfill(tfoot);
		}

		return () => {
			if (thead) {
				teardownTableStickyPolyfill(thead);
			}
			if (tfoot) {
				teardownTableStickyPolyfill(tfoot);
			}
		};
	});

	get tableStyle() {
		return htmlSafe(`width: ${this.tableWidth}px;`);
	}

	get api() {
		return {
			columns: null,
			registerColumnTree: this.registerColumnTree.bind(this),
			tableId: `${this.elementId}-overflow`,
		};
	}

	registerColumnTree(columnTree) {
		this.set('api.columnTree', columnTree);
	}
}
