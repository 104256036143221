import type { AggregateCurrentAllocationPositionDTO, Crop, CropLedgerEntryPerHarvestYear } from 'vault-client/types/graphql-types';
import type { ProductSlug } from 'vault-client/types/vault-client';
import { isProductSlug } from '../type-utils';
import { calculateCropFieldLedgerTypeForHarvestYear, getHarvestedAcres } from '../grain-utils';

const cropGroupProjectedRevenueKeys = [
	'physicalCropSales',
	'unsoldMarkToMarket',
	'additionalRevenue',
	'totalRevenue',
	'brokeragePnl',
] as const;
type CropGroupProjectedRevenueKey = (typeof cropGroupProjectedRevenueKeys)[number];

const businessProjectedRevenueKeys = ['businessRevenue', 'totalRevenue'] as const;
type BusinessProjectedRevenueKey = (typeof businessProjectedRevenueKeys)[number];

const projectedRevenueKeys = [...cropGroupProjectedRevenueKeys, ...businessProjectedRevenueKeys] as const;
type ProjectedRevenueKey = (typeof projectedRevenueKeys)[number];

type CropGroupProjectedRevenue = Partial<Record<CropGroupProjectedRevenueKey, number>>;

type BusinessProjectedRevenue = Partial<Record<BusinessProjectedRevenueKey, number>>;

type ProjectedRevenue = CropGroupProjectedRevenue & BusinessProjectedRevenue;

const projectedRevenueKeyToLabel = {
	businessRevenue: 'Business Revenue',
	brokeragePnl: 'Brokerage P/L',
	physicalCropSales: 'Physical Crop Sales',
	unsoldMarkToMarket: 'Unsold - M2M',
	additionalRevenue: 'Additional Revenue',
	totalRevenue: 'Total Revenue',
} as const satisfies Record<ProjectedRevenueKey, string>;

type ProjectedRevenueLabel = (typeof projectedRevenueKeyToLabel)[ProjectedRevenueKey];

const cropGroupProjectedExpensesDefinedKeys = ['Other', 'totalExpenses'] as const;
type CropGroupProjectedExpensesDefinedKey = (typeof cropGroupProjectedExpensesDefinedKeys)[number];
type CropGroupProjectedExpensesUndefinedKey = string;
type CropGroupProjectedExpensesKey = CropGroupProjectedExpensesDefinedKey | CropGroupProjectedExpensesUndefinedKey;
type CropGroupProjectedExpenses = Partial<Record<CropGroupProjectedExpensesKey, number> & { Other: number }>;

const businessProjectedExpensesDefinedKeys = ['Business Expenses', 'totalExpenses'] as const;
type BusinessProjectedExpensesDefinedKey = (typeof businessProjectedExpensesDefinedKeys)[number];
type BusinessProjectedExpensesKey = BusinessProjectedExpensesDefinedKey;
type BusinessProjectedExpenses = Partial<Record<BusinessProjectedExpensesKey, number>>;

type ProjectedExpenses = CropGroupProjectedExpenses & BusinessProjectedExpenses;
type ProjectedExpensesDefinedKey = CropGroupProjectedExpensesDefinedKey | BusinessProjectedExpensesDefinedKey;
type ProjectedExpensesKey = CropGroupProjectedExpensesKey | BusinessProjectedExpensesKey;

const projectedNetPnlKeys = ['totalRevenue', 'totalExpenses', 'netPnl'] as const;
type ProjectedNetPnlKey = (typeof projectedNetPnlKeys)[number];

const projectedNetPnlKeyToLabel = {
	totalRevenue: 'Total Revenue',
	totalExpenses: 'Total Expenses',
	netPnl: 'Net P/L',
} as const satisfies Record<ProjectedNetPnlKey, string>;
type ProjectedNetPnlLabel = (typeof projectedNetPnlKeyToLabel)[ProjectedNetPnlKey];

type ProjectedNetPnl = Record<ProjectedNetPnlKey, number>;

export type {
	ProjectedRevenue,
	ProjectedRevenueKey,
	CropGroupProjectedRevenue,
	BusinessProjectedRevenue,
	ProjectedRevenueLabel,
	ProjectedExpenses,
	CropGroupProjectedExpenses,
	BusinessProjectedExpenses,
	ProjectedExpensesKey,
	ProjectedExpensesDefinedKey,
	BusinessProjectedExpensesDefinedKey,
	ProjectedNetPnl,
	ProjectedNetPnlKey,
	ProjectedNetPnlLabel,
};

function getBrokeragePnl(aggregateCurrentAllocationPositions: AggregateCurrentAllocationPositionDTO[], slugs: ProductSlug[]): number {
	return aggregateCurrentAllocationPositions.reduce((acc, position) => {
		const slug = position.Product?.slug ?? '';
		if (isProductSlug(slug) && slugs.includes(slug)) {
			return acc + (position.sum?.grossPnl ?? 0);
		}
		return acc;
	}, 0);
}

function getAdditionalRevenue(crop: Crop, cropLedgerRevenueEntries: CropLedgerEntryPerHarvestYear[]): number {
	const harvestedAcres = getHarvestedAcres(crop);
	return cropLedgerRevenueEntries.reduce((acc, entry) => {
		return acc + calculateCropFieldLedgerTypeForHarvestYear(harvestedAcres, entry);
	}, 0);
}

function getAdditionalExpensesByLedgerCategory(
	crop: Crop,
	cropLedgerExpenseEntries: CropLedgerEntryPerHarvestYear[],
): Array<[{ id: string; name: string }, number]> {
	const harvestedAcres = getHarvestedAcres(crop);
	return cropLedgerExpenseEntries.map((entry) => {
		return [
			{ id: entry.CropFieldLedgerCategory.id, name: entry.CropFieldLedgerCategory.name },
			calculateCropFieldLedgerTypeForHarvestYear(harvestedAcres, entry),
		];
	});
}

export {
	projectedRevenueKeys,
	projectedRevenueKeyToLabel,
	projectedNetPnlKeys,
	projectedNetPnlKeyToLabel,
	getBrokeragePnl,
	calculateCropFieldLedgerTypeForHarvestYear,
	getAdditionalRevenue,
	getAdditionalExpensesByLedgerCategory,
};
