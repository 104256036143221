import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<VaultTable\n\t@id={{@id}}\n\t@tableRoute={{@tableRoute}}\n\t@columns={{this.columns}}\n\t@rows={{@rows}}\n\t@sorts={{@sorts}}\n\t@setSorts={{@setSorts}}\n\t@setTablePageState={{@setTablePageState}}\n\t@columnControlTrigger='cattle-lots-table-column-control-trigger'\n\t@emptyStateMinHeight={{if (gt @rows.length 0) '' 'min-h-64'}}\n\t@footerRows={{@footerRows}}\n\t...attributes\n\tas |vt|\n>\n\t<vt.emptyStateWell class='mt-4'>\n\t\t<:headerMessage as |default|>{{default}}</:headerMessage>\n\t\t<:bodyMessage>\n\t\t\tNo lots match the selected filters.\n\t\t</:bodyMessage>\n\t</vt.emptyStateWell>\n</VaultTable>", {"contents":"<VaultTable\n\t@id={{@id}}\n\t@tableRoute={{@tableRoute}}\n\t@columns={{this.columns}}\n\t@rows={{@rows}}\n\t@sorts={{@sorts}}\n\t@setSorts={{@setSorts}}\n\t@setTablePageState={{@setTablePageState}}\n\t@columnControlTrigger='cattle-lots-table-column-control-trigger'\n\t@emptyStateMinHeight={{if (gt @rows.length 0) '' 'min-h-64'}}\n\t@footerRows={{@footerRows}}\n\t...attributes\n\tas |vt|\n>\n\t<vt.emptyStateWell class='mt-4'>\n\t\t<:headerMessage as |default|>{{default}}</:headerMessage>\n\t\t<:bodyMessage>\n\t\t\tNo lots match the selected filters.\n\t\t</:bodyMessage>\n\t</vt.emptyStateWell>\n</VaultTable>","moduleName":"vault-client/components/cattle/lots/lots-table.hbs","parseOptions":{"srcName":"vault-client/components/cattle/lots/lots-table.hbs"}});
import Component from '@glimmer/component';
import { SortObj } from 'vault-client/types/vault-table';
import { getLotsTableColumns, LotsTableRow } from 'vault-client/utils/components/cattle/lots/lots-table';

export interface CattleLotsLotsTableSignature {
	// The arguments accepted by the component
	Args: {
		id: string;
		downloadTableInCsvButtonId?: string;
		editLot: (lot: LotsTableRow) => void;
		deleteLot: (lot: LotsTableRow) => void;
		sorts: SortObj[];
		setSorts: (sorts: SortObj[]) => void;
		setTablePageState: (page: number) => void;
		rows: LotsTableRow[];
		footerRows: LotsTableRow;
	};
	// Any blocks yielded by the component
	Blocks: {
		default: [];
	};
	// The element to which `...attributes` is applied in the component template
	Element: null;
}

export default class CattleLotsLotsTableComponent extends Component<CattleLotsLotsTableSignature> {
	get columns() {
		return getLotsTableColumns({
			editLot: this.args.editLot,
			deleteLot: this.args.deleteLot,
		});
	}
}
