import { CattleYardsYardsTableRow } from 'vault-client/components/cattle/yards/yards-table';
import { TableColumn, CellComponents } from 'vault-client/types/vault-table';

export function getYardsTableColumns(args: {
	editYard: (yard: CattleYardsYardsTableRow) => void;
	deleteYard: (yard: CattleYardsYardsTableRow) => void;
}): TableColumn[] {
	return [
		{
			id: '024dc122-8d96-4799-b5d9-1b70644e6522',
			name: 'Yard Name',
			valuePath: 'name',
			cellComponent: CellComponents.String,
			width: 250,
			textAlign: 'left',
			isSortable: true,
			isFixed: '',
			isVisible: true,
		},
		{
			id: 'f1409271-11b3-46df-84a0-2b48188a256a',
			name: 'Yard Details',
			valuePath: 'description',
			cellComponent: CellComponents.String,
			width: 300,
			textAlign: 'left',
			isSortable: true,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '52d4afd3-5566-4c35-96bd-29940767da64',
			name: '',
			maxWidth: 80,
			cellComponent: CellComponents.Button,
			componentArgs: {
				style: 'plain',
				iconOnlyButton: true,
				fn: args.editYard,
				centerIconHref: '/icons/Edit-Outline.svg#edit-outline',
				centerIconClass: 'icon-interactive-stroke',
			},
			isFixed: '',
			isSortable: false,
			isVisible: true,
		},
		{
			id: 'feef7234-3dfd-4c15-af94-93798f1b9f3c',
			name: '',
			maxWidth: 80,
			cellComponent: CellComponents.Button,
			componentArgs: {
				style: 'plain',
				iconOnlyButton: true,
				fn: args.deleteYard,
				centerIconHref: '/icons/Delete-Outline.svg#delete-outline',
				centerIconClass: 'icon-interactive-stroke',
			},
			isFixed: '',
			isSortable: false,
			isVisible: true,
		},
	];
}
