import { DateTime } from 'luxon';
import {
	AggregateAllocatedForecastedHedgedAndCappedVolumeDTO,
	AggregateCattleLotDTO,
	AggregateCattleLotFeedRationUsagePerMonthDTO,
	AggregateCurrentAllocationPositionDTO,
	AggregateLedgerEntryDTO,
	BusinessEntityRole,
	CattleLot,
	CurrentAllocationPosition,
	EntityAllocatedExposureRatio,
	FeedIngredientConsumedAndPurchasedVolume,
	Future,
	InsuranceEndorsementAllocationRatio,
	Product,
	TypeOfInstrument,
	TypeOfOption,
} from 'vault-client/types/graphql-types';
import { PnlMonthArgs } from '../../../controllers/businesses/business/cattle/cattle-dashboard';
import { DisplayUnits } from 'vault-client/types/cattle/display-units';
import { CattlePnlMonth } from 'vault-client/models/cattle-pnl-month';
import { HedgeMonth, ProjectedPnlRow, PercentHedgedTableRow } from 'vault-client/models/cattle/cattle-dashboard';
import { intervalFromDateTime } from 'vault-client/utils/interval-from-date-time';
import { Owner } from 'vault-client';

// Constants for unit conversions
const LBS_PER_TON = 2000;

const populateOtherExpenses = (expenses: AggregateLedgerEntryDTO[], pnlMonthsMap: Map<string, PnlMonthArgs>) => {
	const getPnlMonthArgs = (date: string) => {
		if (pnlMonthsMap.has(date)) {
			return pnlMonthsMap.get(date) as PnlMonthArgs;
		}
		return;
	};
	expenses.forEach((expense: AggregateLedgerEntryDTO) => {
		const month = expense.month;
		const year = expense.year;
		const expenseAmount = expense.sum.calculatedAmount;

		if (!month || !year || expenseAmount == null) return;

		const date = DateTime.fromObject({ month, year }).toISODate();
		const pnlMonth = getPnlMonthArgs(date);
		if (!pnlMonth) return;

		pnlMonth.otherExpenses = pnlMonth.otherExpenses ? pnlMonth.otherExpenses + expenseAmount : expenseAmount;
	});
};

const populateNonFeedRevenue = (revenues: AggregateLedgerEntryDTO[], pnlMonthsMap: Map<string, PnlMonthArgs>) => {
	const getPnlMonthArgs = (date: string) => {
		if (pnlMonthsMap.has(date)) {
			return pnlMonthsMap.get(date) as PnlMonthArgs;
		}
		return;
	};

	revenues.forEach((revenue: AggregateLedgerEntryDTO) => {
		const month = revenue.month;
		const year = revenue.year;
		const revenueAmount = revenue.sum.calculatedAmount;

		if (!month || !year || revenueAmount == null) return;

		const date = DateTime.fromObject({ month, year }).toISODate();
		const pnlMonth = getPnlMonthArgs(date);
		if (!pnlMonth) return;

		pnlMonth.otherRevenue = pnlMonth.otherRevenue ? pnlMonth.otherRevenue + revenueAmount : revenueAmount;
	});
};

const populateInsurance = (insurance: InsuranceEndorsementAllocationRatio[], pnlMonthsMap: Map<string, PnlMonthArgs>) => {
	const getPnlMonthArgs = (date: string) => {
		if (pnlMonthsMap.has(date)) {
			return pnlMonthsMap.get(date) as PnlMonthArgs;
		}
		return;
	};

	insurance.forEach((endorsement) => {
		const effectiveHedgeDate = endorsement.effectiveHedgeDate;
		const pnl = endorsement.RatioAdjustedInsuranceEndorsement.pnl;

		if (!effectiveHedgeDate || pnl == null) return;

		const pnlMonth = getPnlMonthArgs(effectiveHedgeDate);
		if (!pnlMonth) return;
		pnlMonth.insurance = pnlMonth.insurance ? pnlMonth.insurance + pnl : pnl;
	});
};

const populateFeedExpenses = (feedExpenses: AggregateCattleLotFeedRationUsagePerMonthDTO[], pnlMonthsMap: Map<string, PnlMonthArgs>) => {
	const getPnlMonthArgs = (date: string) => {
		if (pnlMonthsMap.has(date)) {
			return pnlMonthsMap.get(date) as PnlMonthArgs;
		}
		return;
	};

	feedExpenses.forEach((feedUsage: AggregateCattleLotFeedRationUsagePerMonthDTO) => {
		const startOfMonth = feedUsage.CattleLot?.activeEndMonthStartDate;
		const totalExpense = feedUsage.sum.totalExpenseInUsd ? -feedUsage.sum.totalExpenseInUsd : 0;

		if (!startOfMonth || totalExpense == null) return;

		const pnlMonth = getPnlMonthArgs(startOfMonth);
		if (!pnlMonth) return;

		pnlMonth.feedExpenses = pnlMonth.feedExpenses ? pnlMonth.feedExpenses + totalExpense : totalExpense;
	});
};

const populateTotalWeight = (cattleLots: CattleLot[], pnlMonthsMap: Map<string, PnlMonthArgs>) => {
	const getPnlMonthArgs = (date: string) => {
		if (pnlMonthsMap.has(date)) {
			return pnlMonthsMap.get(date) as PnlMonthArgs;
		}
		return;
	};

	cattleLots.forEach((cattleLot: CattleLot) => {
		const endMonth = DateTime.fromISO(cattleLot.activeEndDate).startOf('month').toISODate();
		if (!endMonth) return;

		const pnlMonth = getPnlMonthArgs(endMonth);
		if (!pnlMonth) return;

		const lotTotalWeight = (cattleLot.targetWeightInLb ?? 0) * (cattleLot.numberOfCattle ?? 0);
		pnlMonth.totalWeight = pnlMonth.totalWeight ? pnlMonth.totalWeight + lotTotalWeight : lotTotalWeight;
	});
};

const populateNumOfCattle = (cattleLots: CattleLot[], pnlMonthsMap: Map<string, PnlMonthArgs>) => {
	const getPnlMonthArgs = (date: string) => {
		if (pnlMonthsMap.has(date)) {
			return pnlMonthsMap.get(date) as PnlMonthArgs;
		}
		return;
	};

	cattleLots.forEach((cattleLot: CattleLot) => {
		const endMonth = DateTime.fromISO(cattleLot.activeEndDate).startOf('month').toISODate();

		if (!endMonth) return;

		const pnlMonth = getPnlMonthArgs(endMonth);
		if (!pnlMonth) return;

		pnlMonth.numCattle = pnlMonth.numCattle ? pnlMonth.numCattle + (cattleLot.numberOfCattle ?? 0) : cattleLot.numberOfCattle ?? 0;
	});
};

const populateCattleSalesRevenue = (cattleLots: AggregateCattleLotDTO[], pnlMonthsMap: Map<string, PnlMonthArgs>) => {
	const getPnlMonthArgs = (date: string) => {
		if (pnlMonthsMap.has(date)) {
			return pnlMonthsMap.get(date) as PnlMonthArgs;
		}
		return;
	};

	cattleLots.forEach((cattleLot: AggregateCattleLotDTO) => {
		const startDate = cattleLot.activeEndMonthStartDate;
		if (!startDate) return;

		const date = DateTime.fromISO(startDate).startOf('month').toISODate();

		const pnlMonth = getPnlMonthArgs(date);
		if (!pnlMonth) return;

		pnlMonth.cattleSalesRevenue = pnlMonth.cattleSalesRevenue
			? pnlMonth.cattleSalesRevenue + (cattleLot.sum.forecastedSalesRevenueInUsd ?? 0)
			: cattleLot.sum.forecastedSalesRevenueInUsd ?? 0;
	});
};

const populateLotExpenses = (cattleLots: AggregateCattleLotDTO[], pnlMonthsMap: Map<string, PnlMonthArgs>) => {
	const getPnlMonthArgs = (date: string) => {
		if (pnlMonthsMap.has(date)) {
			return pnlMonthsMap.get(date) as PnlMonthArgs;
		}
		return;
	};

	cattleLots.forEach((cattleLot: AggregateCattleLotDTO) => {
		const startDate = cattleLot.activeEndMonthStartDate;
		if (!startDate) return;

		const date = DateTime.fromISO(startDate).startOf('month').toISODate();

		const pnlMonth = getPnlMonthArgs(date);
		if (!pnlMonth) return;

		pnlMonth.lotExpenses = pnlMonth.lotExpenses
			? pnlMonth.lotExpenses + (cattleLot.sum.cattleLotTotalExpensesInUsd ?? 0)
			: cattleLot.sum.cattleLotTotalExpensesInUsd ?? 0;
		pnlMonth.lotExpenses = pnlMonth.lotExpenses
			? pnlMonth.lotExpenses + -(cattleLot.sum.purchasePriceInUsd ?? 0)
			: -(cattleLot.sum.purchasePriceInUsd ?? 0);
	});
};

const populateCmeCattleAndFeed = (cmeCattle: AggregateCurrentAllocationPositionDTO[], pnlMonthsMap: Map<string, PnlMonthArgs>) => {
	const getPnlMonthArgs = (date: string) => {
		if (pnlMonthsMap.has(date)) {
			return pnlMonthsMap.get(date) as PnlMonthArgs;
		}
		return;
	};

	cmeCattle.forEach((currentPosition: AggregateCurrentAllocationPositionDTO) => {
		const date = currentPosition.effectiveHedgeDate;
		const quantity = currentPosition.sum.contractQuantity;
		const instrumentType = currentPosition.instrumentType;
		const optionType = currentPosition.optionType;
		const productSlug = currentPosition?.Product?.slug ?? '';
		if (!date) return;

		const startOfMonth = DateTime.fromISO(date).startOf('month').toISODate();
		const pnlMonth = getPnlMonthArgs(startOfMonth);
		if (!pnlMonth) return;

		const grossPnl = currentPosition.sum.grossPnl ?? 0;

		if (['livestock-feeder-cattle', 'livestock-live-cattle'].includes(productSlug)) {
			if (
				// Short futures and Short Puts are cattle hedges, others are revenue
				(quantity != null && instrumentType === TypeOfInstrument.Future && quantity < 0) ||
				(quantity != null && instrumentType === TypeOfInstrument.Option && optionType === TypeOfOption.Put && quantity < 0)
			) {
				pnlMonth.cmeCattleHedged = pnlMonth.cmeCattleHedged ? pnlMonth.cmeCattleHedged + grossPnl : grossPnl;
			} else {
				pnlMonth.cmeCattleRevenue = pnlMonth.cmeCattleRevenue ? pnlMonth.cmeCattleRevenue + grossPnl : grossPnl;
			}
		} else {
			// Feed Related
			if (
				// long futures and long calls are feed hedges, others are revenue
				(quantity != null && instrumentType === TypeOfInstrument.Future && quantity > 0) ||
				(quantity != null && instrumentType === TypeOfInstrument.Option && optionType === TypeOfOption.Call && quantity > 0)
			) {
				pnlMonth.cmeFeedHedged = pnlMonth.cmeFeedHedged ? pnlMonth.cmeFeedHedged + grossPnl : grossPnl;
			} else {
				pnlMonth.cmeFeedRevenue = pnlMonth.cmeFeedRevenue ? pnlMonth.cmeFeedRevenue + grossPnl : grossPnl;
			}
		}
	});
};

function dashboardMarketPriceGroups(products: Product[]) {
	const orderedFutures: Future[] = [];

	const liveCattleFutures: Future[] =
		products.find((product: Product) => product.slug === 'livestock-live-cattle')?.CurrentFutures?.slice(0, 3) ?? [];

	const cornFutures: Future[] = products.find((product: Product) => product.slug === 'grain-corn')?.CurrentFutures?.slice(0, 3) ?? [];

	const feederCattleFutures: Future[] =
		products.find((product: Product) => product.slug === 'livestock-feeder-cattle')?.CurrentFutures?.slice(0, 3) ?? [];

	orderedFutures.push(...liveCattleFutures, ...cornFutures, ...feederCattleFutures);

	return [
		{
			name: 'Futures',
			prices: orderedFutures,
		},
	];
}

function projectedPnlRawData(months: CattlePnlMonth[], selectedDisplayUnit: DisplayUnits) {
	const data: { 'Net P/L': (number | null)[]; Expenses: (number | null)[]; Revenues: (number | null)[]; 'Hedge P/L': (number | null)[] } = {
		'Net P/L': [],
		Revenues: [],
		Expenses: [],
		'Hedge P/L': [],
	};

	months.forEach((month: CattlePnlMonth) => {
		if (selectedDisplayUnit === DisplayUnits.Total) {
			data['Net P/L'].push(month.netPnl ?? 0);
			data.Revenues.push(month.totalRevenue ?? 0);
			data.Expenses.push(month.totalExpenses ?? 0);
			data['Hedge P/L'].push(month.totalHedged ?? 0);
		} else if (selectedDisplayUnit === DisplayUnits.PerHead) {
			data['Net P/L'].push(month.netPnlPerHead ?? 0);
			data.Revenues.push(month.totalRevenuePerHead ?? 0);
			data.Expenses.push(month.totalExpensesPerHead ?? 0);
			data['Hedge P/L'].push(month.totalHedgedPerHead ?? 0);
		} else if (selectedDisplayUnit === DisplayUnits.PerCWT) {
			data['Net P/L'].push(month.netPnlCwt ?? 0);
			data.Revenues.push(month.totalRevenueCwt ?? 0);
			data.Expenses.push(month.totalExpensesCwt ?? 0);
			data['Hedge P/L'].push(month.totalHedgedCwt ?? 0);
		}
	});

	return data;
}

type PercentHedgedData = Record<string, HedgeMonth[]>;

interface PercentHedgedChartRow {
	month: DateTime;
	'Futures Hedged': number;
	'Calls Hedged': number;
	Purchased: number;
	'Insurance (LRP)': number;
	'Insurance (LGM)': number;
	'Puts Hedged': number;
}

function percentHedgedChartData(
	exposureRatios: EntityAllocatedExposureRatio[],
	slug: string,
	percentHedgedData: PercentHedgedData,
	aggregateCattleLots?: AggregateCattleLotDTO[] | null,
	businessRole?: BusinessEntityRole | string,
) {
	const startDate = DateTime.local().startOf('month');
	const endDate = startDate.plus({ months: 12 });

	const rows: PercentHedgedChartRow[] = [];

	exposureRatios
		.filter((ratio: EntityAllocatedExposureRatio) => {
			const monthDate = DateTime.fromISO(ratio.date).startOf('month');
			return ratio.Product.slug === slug && monthDate >= startDate && monthDate <= endDate;
		})
		.forEach((ratio) => {
			const hedgeMonth = percentHedgedData[slug].find((data: HedgeMonth) => data.date === ratio.date);

			// Update animalsNeeded for livestock-feeder-cattle
			if (hedgeMonth && slug === 'livestock-feeder-cattle' && aggregateCattleLots) {
				const feederCattleToUse =
					aggregateCattleLots.find((lot) => {
						const lotDate = DateTime.fromISO(lot.activeEndMonthStartDate ?? '').startOf('month');
						return lotDate.toISODate() === ratio.date;
					})?.sum.numberOfCattle ?? 0;

				hedgeMonth.setValuesFromObject({
					animalsNeeded: feederCattleToUse,
					businessRole: businessRole,
				});
			}
			rows.push({
				month: DateTime.fromISO(ratio.date),
				'Futures Hedged': hedgeMonth?.percentFuturesHedged ?? 0,
				'Calls Hedged': hedgeMonth?.percentCallsHedged ?? 0,
				Purchased: hedgeMonth?.percentPurchased ?? 0,
				'Insurance (LRP)': ratio.percentLrpVolumeHedged ?? 0,
				'Insurance (LGM)': ratio.percentLgmVolumeHedged ?? 0,
				'Puts Hedged': hedgeMonth?.percentPutsHedged ?? 0,
			});
		});

	return rows;
}

function findNearestFuture(productSlug: string, month: string, inputs: Future[]) {
	const futures = inputs.filter((future) => future.Product.slug === productSlug) ?? [];
	return futures?.find((future) => future.displayExpiresAt >= month) ?? null;
}

function getPercentHedgedData(
	owner: Owner,
	transactions: CurrentAllocationPosition[],
	futures: Future[],
	volumes: AggregateAllocatedForecastedHedgedAndCappedVolumeDTO[],
	entityAllocatedExposureRatios: EntityAllocatedExposureRatio[],
	feedIngredientConsumedAndPurchasedVolumes?: FeedIngredientConsumedAndPurchasedVolume[] | null,
	businessRole?: BusinessEntityRole,
) {
	const startDate = DateTime.local().startOf('month');
	const endDate = startDate.plus({ months: 12 }); // 13 months total (current month + 12)

	const hedgeMonths = intervalFromDateTime(startDate, endDate, { months: 1 }).reduce<Record<string, Record<string, HedgeMonth>>>(
		(acc, month) => {
			const date = month.startOf('month').toISODate();
			if (!date) return acc;

			// Initialize each product's object if it doesn't exist
			if (!acc['grain-corn']) acc['grain-corn'] = {};
			if (!acc['livestock-live-cattle']) acc['livestock-live-cattle'] = {};
			if (!acc['livestock-feeder-cattle']) acc['livestock-feeder-cattle'] = {};

			const nearestCornFuture = findNearestFuture('grain-corn', date, futures);
			const cornBarChartSymbol = nearestCornFuture?.barchartSymbol ?? '';
			if (!cornBarChartSymbol) {
				console.warn(`Barchart symbol not found for corn on ${date}`);
			}
			const cornStandardLotSpecs = nearestCornFuture?.Product?.StandardProductLotSpecification ?? null;
			const cornSymbolGroup = nearestCornFuture?.SymbolGroup ?? null;

			acc['grain-corn'][date] = new HedgeMonth(owner, date, 'grain-corn', cornStandardLotSpecs, cornSymbolGroup, cornBarChartSymbol, {
				businessRole,
			});

			const nearestLiveCattleFuture = findNearestFuture('livestock-live-cattle', date, futures);
			const liveCattleChartSymbol = nearestLiveCattleFuture?.barchartSymbol ?? '';
			if (!liveCattleChartSymbol) {
				console.warn(`Barchart symbol not found for live cattle on ${date}`);
			}
			const liveCattleStandardLotSpecs = nearestLiveCattleFuture?.Product?.StandardProductLotSpecification ?? null;
			const liveCattleSymbolGroup = nearestLiveCattleFuture?.SymbolGroup ?? null;

			acc['livestock-live-cattle'][date] = new HedgeMonth(
				owner,
				date,
				'livestock-live-cattle',
				liveCattleStandardLotSpecs,
				liveCattleSymbolGroup,
				liveCattleChartSymbol,
				{
					businessRole,
				},
			);

			const nearestFeederCattleFuture = findNearestFuture('livestock-feeder-cattle', date, futures);
			const feederCattleChartSymbol = nearestFeederCattleFuture?.barchartSymbol ?? '';
			if (!feederCattleChartSymbol) {
				console.warn(`Barchart symbol not found for feeder cattle on ${date}`);
			}
			const feederCattleStandardLotSpecs = nearestFeederCattleFuture?.Product?.StandardProductLotSpecification ?? null;
			const feederCattleSymbolGroup = nearestLiveCattleFuture?.SymbolGroup ?? null;

			acc['livestock-feeder-cattle'][date] = new HedgeMonth(
				owner,
				date,
				'livestock-feeder-cattle',
				feederCattleStandardLotSpecs,
				feederCattleSymbolGroup,
				feederCattleChartSymbol,
				{
					businessRole,
				},
			);

			return acc;
		},
		{ 'grain-corn': {}, 'livestock-live-cattle': {}, 'livestock-feeder-cattle': {} },
	);

	// Set production values from EntityAllocatedExposureRatio
	entityAllocatedExposureRatios.forEach((ratio) => {
		const slug = ratio.Product?.slug;
		const month = ratio.date;

		if (!slug || !month || !hedgeMonths[slug]?.[month]) return;

		const hedgeMonth = hedgeMonths[slug][month];
		hedgeMonth.setValuesFromObject({
			productionLbs: ratio.netProductionExposure ?? 0,
		});
	});

	volumes.forEach((hedge) => {
		const slug = hedge.Product?.slug;
		const month = hedge.date;

		if (!slug) {
			throw new Error(`Slug not found for hedge ${hedge}`);
		}

		if (!month) {
			throw new Error(`date not found for hedge ${hedge}`);
		}

		const monthDate = DateTime.fromISO(month).startOf('month');
		if (monthDate < startDate || monthDate > endDate) {
			return;
		}

		const hedgeMonth = hedgeMonths[slug]?.[month];
		if (!hedgeMonth) {
			return;
		}

		const dataKey = getHedgedVolumeDataKey(slug, businessRole as BusinessEntityRole);
		if (!dataKey) return;

		const hedgedVolume = hedge.sum[dataKey] ?? 0;

		if (hedge.instrumentType === TypeOfInstrument.Future || hedge.instrumentType === TypeOfInstrument.Swap) {
			hedgeMonth.futuresHedgedInUnits += hedgedVolume;
		} else if (hedge.optionType === TypeOfOption.Call) {
			hedgeMonth.callsHedgedInUnits += hedgedVolume;
		} else if (hedge.optionType === TypeOfOption.Put) {
			hedgeMonth.putsHedgedInUnits += hedgedVolume;
		}
	});

	transactions.forEach((transaction) => {
		const slug = transaction.Product?.slug;
		const month = transaction.effectiveHedgeDate;

		if (!slug) {
			throw new Error(`Slug not found for transaction ${transaction}`);
		}

		if (!month) {
			throw new Error(`month not found for transaction ${transaction}`);
		}

		const monthDate = DateTime.fromISO(month).startOf('month');
		if (monthDate < startDate || monthDate > endDate) {
			return;
		}

		const hedgeMonth = hedgeMonths[slug]?.[month];
		if (!hedgeMonth) {
			return;
		}

		hedgeMonth.transactions.push(transaction);
	});

	// Process feed ingredient volumes
	feedIngredientConsumedAndPurchasedVolumes?.forEach((volume) => {
		const slug = volume.FeedIngredient.FeedCategory?.HedgeProduct?.slug;
		if (slug !== 'grain-corn') return;
		const month = volume.monthStartDate;

		if (!hedgeMonths[slug]?.[month]) return;

		const hedgeMonth = hedgeMonths[slug][month];
		hedgeMonth.setValuesFromObject({
			usageDMITons: (hedgeMonth.usageDMITons ?? 0) + (volume.forecastedConsumptionInTons ?? 0),
			purchasedDMITons: (hedgeMonth.purchasedDMITons ?? 0) + (volume.purchasedInTons ?? 0),
		});
	});

	// Filter the values to only include months within our window
	const filterMonths = (hedgeMonth: HedgeMonth) => {
		const monthDate = DateTime.fromISO(hedgeMonth.date);
		return monthDate >= startDate && monthDate <= endDate;
	};

	return {
		'grain-corn': Object.values(hedgeMonths['grain-corn']).filter(filterMonths),
		'livestock-live-cattle': Object.values(hedgeMonths['livestock-live-cattle']).filter(filterMonths),
		'livestock-feeder-cattle': Object.values(hedgeMonths['livestock-feeder-cattle']).filter(filterMonths),
	};
}

function buildPercentHedgedRows(
	slug: string,
	entityAllocatedExposureRatio: EntityAllocatedExposureRatio[],
	percentHedgedData: PercentHedgedData,
	feedIngredientConsumedAndPurchasedVolumes: FeedIngredientConsumedAndPurchasedVolume[] | null,
	aggregateCattleLots: AggregateCattleLotDTO[] | null,
	businessRole: string,
) {
	const startDate = DateTime.local().startOf('month');
	const endDate = startDate.plus({ months: 12 });
	const allocatedExposureRatios = entityAllocatedExposureRatio.filter((entityAllocatedExposureRatio: EntityAllocatedExposureRatio) => {
		const monthDate = DateTime.fromISO(entityAllocatedExposureRatio.date).startOf('month');
		return entityAllocatedExposureRatio.Product.slug === slug && monthDate >= startDate && monthDate <= endDate;
	});

	const rows: PercentHedgedTableRow[] = [];

	allocatedExposureRatios?.forEach((allocatedExposureRatio: EntityAllocatedExposureRatio) => {
		const hedgeMonth = percentHedgedData[slug].find((data: HedgeMonth) => data.date === allocatedExposureRatio.date);
		const feedIngredientConsumedAndPurchasedVolume = feedIngredientConsumedAndPurchasedVolumes?.filter(
			(volume) => volume.monthStartDate === allocatedExposureRatio.date,
		);
		// Used for live cattle producers that view feeder cattle as an expense
		const feederCattleToUse =
			aggregateCattleLots?.find((lot) => {
				const lotDate = DateTime.fromISO(lot.activeEndMonthStartDate ?? '').startOf('month');
				return lotDate.toISODate() === allocatedExposureRatio.date;
			})?.sum.numberOfCattle ?? 0;

		// Update the HedgeMonth instance with the animalsNeeded value for livestock-feeder-cattle
		if (hedgeMonth) {
			hedgeMonth.setValuesFromObject({
				animalsNeeded: feederCattleToUse,
				businessRole: businessRole,
			});
		}

		// Use HedgeMonth's conversion method for all unit conversions
		const futuresHedgedInTons = hedgeMonth?.convertPriceUnitsToTons(hedgeMonth?.futuresHedgedInUnits ?? 0) ?? 0;
		const putsHedgedInTons = hedgeMonth?.convertPriceUnitsToTons(hedgeMonth?.putsHedgedInUnits ?? 0) ?? 0;
		const callsHedgedInTons = hedgeMonth?.convertPriceUnitsToTons(hedgeMonth?.callsHedgedInUnits ?? 0) ?? 0;
		const physicalPurchasesInTons = feedIngredientConsumedAndPurchasedVolume
			? feedIngredientConsumedAndPurchasedVolume.reduce((acc, curr) => acc + (curr.purchasedInTons ?? 0), 0)
			: 0;

		rows.push({
			month: DateTime.fromISO(allocatedExposureRatio.date),
			production: allocatedExposureRatio.netProductionExposure ?? 0,
			usage: hedgeMonth?.usageDMITons ?? 0,
			animalsNeeded: feederCattleToUse,
			callsHedged: hedgeMonth?.percentCallsHedged ?? 0,
			callsHedgedLbs: callsHedgedInTons * LBS_PER_TON,
			callsHedgedTons: callsHedgedInTons,
			physicalPurchases: hedgeMonth?.percentPurchased ?? 0,
			physicalPurchasesLbs: physicalPurchasesInTons * LBS_PER_TON,
			physicalPurchasesTons: physicalPurchasesInTons,
			futuresHedged: hedgeMonth?.percentFuturesHedged ?? 0,
			futuresHedgedLbs: futuresHedgedInTons * LBS_PER_TON,
			futuresHedgedTons: futuresHedgedInTons,
			putsHedged: hedgeMonth?.percentPutsHedged ?? 0,
			putsHedgedLbs: putsHedgedInTons * LBS_PER_TON,
			lrpInsurance: allocatedExposureRatio.percentLrpVolumeHedged ?? 0,
			lrpInsuranceLbs: allocatedExposureRatio.lrpVolumeHedged ?? 0,
			lgmInsurance: allocatedExposureRatio.percentLgmVolumeHedged ?? 0,
			lgmInsuranceLbs: allocatedExposureRatio.lgmVolumeHedged ?? 0,
		});
	});

	return rows;
}

function buildProjectedPnlRows(pnlMonths: CattlePnlMonth[], selectedDisplayUnit: DisplayUnits) {
	return pnlMonths.map((month) => {
		const data: ProjectedPnlRow = {
			date: month.date,
			cattleSalesRevenue: null,
			otherRevenue: null,
			insurance: null,
			feedExpenses: null,
			otherExpenses: null,
			netPnl: null,
			lotExpenses: null,
			numCattle: null,
			totalWeight: null,
			cmeCattleRevenue: null,
			cmeFeedRevenue: null,
		};

		if (selectedDisplayUnit === DisplayUnits.Total) {
			data.cattleSalesRevenue = month.cattleSalesRevenue;
			data.otherRevenue = month.otherRevenue;
			data.insurance = month.insurance;
			data.feedExpenses = month.feedExpenses;
			data.otherExpenses = month.otherExpenses;
			data.lotExpenses = month.lotExpenses;
			data.netPnl = month.netPnl;
			data.cmeCattleRevenue = month.cmeCattleAmt;
			data.cmeFeedRevenue = month.cmeFeedAmt;
		} else if (selectedDisplayUnit === DisplayUnits.PerHead) {
			data.cattleSalesRevenue = month.cattleSalesPerHead;
			data.otherRevenue = month.otherRevenuePerHead;
			data.insurance = month.insurancePnlPerHead;
			data.feedExpenses = month.feedExpensesPerHead;
			data.otherExpenses = month.otherExpensesPerHead;
			data.lotExpenses = month.lotExpensesPerHead;
			data.netPnl = month.netPnlPerHead;
			data.cmeCattleRevenue = month.cmeCattlePerHead;
			data.cmeFeedRevenue = month.cmeFeedPerHead;
		} else if (selectedDisplayUnit === DisplayUnits.PerCWT) {
			data.cattleSalesRevenue = month.cattleSalesCwt;
			data.otherRevenue = month.otherRevenueCwt;
			data.insurance = month.insurancePnlCwt;
			data.feedExpenses = month.feedExpensesCwt;
			data.otherExpenses = month.otherExpensesCwt;
			data.lotExpenses = month.lotExpensesCwt;
			data.netPnl = month.netPnlCwt;
			data.cmeCattleRevenue = month.cmeCattleCwt;
			data.cmeFeedRevenue = month.cmeFeedCwt;
		}
		return data;
	});
}

function getProjectedPnlFooterRow(projectedPnlRows: ProjectedPnlRow[], selectedDisplayUnit: DisplayUnits): [Omit<ProjectedPnlRow, 'date'>] {
	const totals: Omit<ProjectedPnlRow, 'date'> = {
		cattleSalesRevenue: null,
		otherRevenue: null,
		insurance: null,
		feedExpenses: null,
		otherExpenses: null,
		netPnl: null,
		lotExpenses: null,
		numCattle: null,
		totalWeight: null,
		cmeCattleRevenue: null,
		cmeFeedRevenue: null,
	};

	const validEntries: Record<keyof Omit<ProjectedPnlRow, 'date'>, number> = {
		cattleSalesRevenue: 0,
		otherRevenue: 0,
		insurance: 0,
		feedExpenses: 0,
		netPnl: 0,
		otherExpenses: 0,
		lotExpenses: 0,
		numCattle: 0,
		totalWeight: 0,
		cmeCattleRevenue: 0,
		cmeFeedRevenue: 0,
	};

	const addToTotal = (key: keyof Omit<ProjectedPnlRow, 'date'>, value: number | null) => {
		if (value == null) return;

		if (selectedDisplayUnit !== DisplayUnits.Total) {
			validEntries[key] += 1;
		}
		totals[key] = (totals[key] ?? 0) + value;
	};

	projectedPnlRows.forEach((row: ProjectedPnlRow) => {
		addToTotal('cattleSalesRevenue', row.cattleSalesRevenue);
		addToTotal('otherRevenue', row.otherRevenue);
		addToTotal('insurance', row.insurance);
		addToTotal('lotExpenses', row.lotExpenses);
		addToTotal('feedExpenses', row.feedExpenses);
		addToTotal('otherExpenses', row.otherExpenses);
		addToTotal('netPnl', row.netPnl);
		addToTotal('cmeCattleRevenue', row.cmeCattleRevenue);
		addToTotal('cmeFeedRevenue', row.cmeFeedRevenue);
	});

	if (selectedDisplayUnit !== DisplayUnits.Total) {
		Object.keys(totals).forEach((key: keyof Omit<ProjectedPnlRow, 'date'>) => {
			const total = totals[key];
			if (total != null) {
				totals[key] = total / validEntries[key];
			}
		});
	}

	return [totals];
}

function getHedgedVolumeDataKey(slug: string, role: BusinessEntityRole): 'naturallyLongHedged' | 'naturallyShortHedged' | null {
	if (slug === 'grain-corn' || slug === 'grain-soybean-meal' || slug === 'grain-soybeans') {
		return 'naturallyShortHedged';
	}

	if (role === BusinessEntityRole.LiveCattleProducer) {
		if (slug === 'livestock-live-cattle') {
			return 'naturallyLongHedged';
		} else if (slug === 'livestock-feeder-cattle') return 'naturallyShortHedged';
	}

	if (role === BusinessEntityRole.FeederCattleProducer && slug === 'livestock-feeder-cattle') {
		return 'naturallyLongHedged';
	}

	return null;
}

export {
	populateCattleSalesRevenue,
	populateCmeCattleAndFeed,
	populateNonFeedRevenue,
	populateInsurance,
	populateLotExpenses,
	populateFeedExpenses,
	populateOtherExpenses,
	populateTotalWeight,
	projectedPnlRawData,
	dashboardMarketPriceGroups,
	percentHedgedChartData,
	getPercentHedgedData,
	findNearestFuture,
	buildPercentHedgedRows,
	buildProjectedPnlRows,
	getProjectedPnlFooterRow,
	populateNumOfCattle,
};
