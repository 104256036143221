import type { ProjectedNetPnl } from '../dashboard';

import { type CropGroup } from '../crop';
import { type ProjectedRevenueByCropGroup } from './get-projected-revenue-by-crop-group';
import { type ProjectedExpensesByCropGroup } from './get-projected-expenses-by-crop-group';

type ProjectedNetPnlByCropGroup = Partial<Record<CropGroup, ProjectedNetPnl>>;

/**
 * Calculates projected net P&L values from projected revenue and expenses, organized by crop group
 *
 * @param projectedRevenueByCropGroup - Record of crop groups with their projected revenue values
 * @param projectedExpensesByCropGroup - Record of crop groups with their projected expenses values
 * @returns Record of crop groups with their projected net P&L values
 */
function getProjectedNetPnlByCropGroup({
	projectedRevenueByCropGroup,
	projectedExpensesByCropGroup,
}: {
	projectedRevenueByCropGroup: ProjectedRevenueByCropGroup;
	projectedExpensesByCropGroup: ProjectedExpensesByCropGroup;
}): ProjectedNetPnlByCropGroup {
	// Get all unique crop groups from both revenue and expenses
	const allCropGroups = new Set([
		...Object.keys(projectedRevenueByCropGroup),
		...Object.keys(projectedExpensesByCropGroup),
	]) as Set<CropGroup>;

	// Create the result object
	return Array.from(allCropGroups).reduce((result, cropGroup) => {
		// Get revenue and expenses for this crop group, defaulting to empty objects
		const revenue = projectedRevenueByCropGroup[cropGroup] || {};
		const expenses = projectedExpensesByCropGroup[cropGroup] || {};

		// Extract values with proper defaults
		const totalRevenue = revenue.totalRevenue || 0;
		const totalExpenses = expenses.totalExpenses || 0;
		const netPnl = totalRevenue - totalExpenses;

		// Add to result without creating a new object each time
		result[cropGroup] = {
			totalRevenue,
			totalExpenses,
			netPnl,
		};

		return result;
	}, {} as ProjectedNetPnlByCropGroup);
}

export { getProjectedNetPnlByCropGroup };
