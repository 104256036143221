import { MutationOptions, useMutation } from 'glimmer-apollo';
import { UPDATE_CATTLE_FEED_RATION_STAGE } from 'vault-client/graphql/mutations/cattle/cattle-feed-ration-stage';
import type { Mutation, Mutation_updateCattleFeedRationStageArgs } from 'vault-client/types/graphql-types';

const RELATED_FIELD_NAMES = [
	'CattleFeedRationStages',
	'CattleFeedRationStage',
	'CattleFeedRationStageCount',
	'CattleFeedRationStageIngredient',
	'CattleFeedRationStageIngredientCount',
	'CattleFeedRationStageIngredients',
	'CattleFeedRations',
	'CattleFeedRation',
	'CattleFeedRationCount',
	'AggregateCattleLotFeedRationUsagePerMonths',
] as const;

const defaultOptions: MutationOptions<Mutation['updateCattleFeedRationStage'], Mutation_updateCattleFeedRationStageArgs> = {
	update: (cache) => {
		RELATED_FIELD_NAMES.forEach((fieldName) => {
			cache.evict({ fieldName });
		});
		cache.gc();
	},
};

export function useUpdateCattleFeedRationStageMutation(
	context: object,
	options?: MutationOptions<Mutation['updateCattleFeedRationStage'], Mutation_updateCattleFeedRationStageArgs>,
) {
	return useMutation<Mutation['updateCattleFeedRationStage'], Mutation_updateCattleFeedRationStageArgs>(context, () => [
		UPDATE_CATTLE_FEED_RATION_STAGE,
		{
			...defaultOptions,
			...options,
		},
	]);
}
