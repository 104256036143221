import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type CattleMockService from 'vault-client/services/cattle-mock';
import { useQuery } from 'glimmer-apollo';
import { GET_CATTLE_DASHBOARD } from '../../../../graphql/queries/businesses/business/cattle/cattle-dashboard';
import { DateTime } from 'luxon';
import { tracked } from '@glimmer/tracking';
import {
	CattleLotFeedRationUsagePerMonthFilterDTO,
	CattleLotFilterDTO,
	CurrentAllocationPositionAggregateDTO,
	CurrentAllocationPositionFilterDTO,
	CurrentAllocationPositionGroupByDTO,
	Query,
	TypeOfInstrument,
	TypeOfOption,
} from 'vault-client/types/graphql-types';

export type GetCattleDashboardQueryArgs = {
	productSlugs: string[];
	currentPositionsWhere: CurrentAllocationPositionFilterDTO;
	allocationPositionsGroupBy?: CurrentAllocationPositionGroupByDTO;
	allocationPositionsCalc?: CurrentAllocationPositionAggregateDTO;
	feedRationUsageWhere?: CattleLotFeedRationUsagePerMonthFilterDTO;
	cattleLotsWhere?: CattleLotFilterDTO;
	startDate?: string;
	endDate?: string;
	customerId?: string;
};

export type GetCattleDashboardQuery = {
	AggregateCurrentAllocationPositions: Query['AggregateCurrentAllocationPositions'];
	Products: Query['Products'];
	AggregateCattleLotFeedRationUsagePerMonths: Query['AggregateCattleLotFeedRationUsagePerMonths'];
	AggregateCattleLots: Query['AggregateCattleLots'];
	AggregateCattleLotsForFeederHedged: Query['AggregateCattleLots'];
	AllocatedLrpInsuranceEndorsements: Query['InsuranceEndorsementAllocationRatios'];
	AllocatedLgmInsuranceEndorsements: Query['InsuranceEndorsementAllocationRatios'];
	AggregateExpenseLedgerEntries: Query['AggregateLedgerEntries'];
	AggregateRevenueLedgerEntries: Query['AggregateLedgerEntries'];
	CattleLots: Query['CattleLots'];
	EntityAllocatedExposureRatios: Query['EntityAllocatedExposureRatios'];
	AggregateAllocatedForecastedHedgedAndCappedVolumes: Query['AggregateAllocatedForecastedHedgedAndCappedVolumes'];
	CurrentAllocationPositions: Query['CurrentAllocationPositions'];
	Futures: Query['Futures'];
	FeedIngredientConsumedAndPurchasedVolumes: Query['FeedIngredientConsumedAndPurchasedVolumes'];
	Customer: Query['Customer'];
};

export default class BusinessesBusinessCattleCattleDashboardRoute extends Route {
	@service declare cattleMock: CattleMockService;

	@tracked variables: GetCattleDashboardQueryArgs = {
		productSlugs: [],
		currentPositionsWhere: {
			businessId: {
				equals: '',
			},
		},
		feedRationUsageWhere: {
			businessId: {
				equals: '',
			},
		},
	};
	async model() {
		const { business_id: businessId } = this.paramsFor('businesses.business') as { business_id: string };
		const startDate = DateTime.now().startOf('year').toISODate();
		const endDate = DateTime.now().startOf('year').plus({ months: 13 }).endOf('month').toISODate();

		const getCattleDashboard = useQuery<GetCattleDashboardQuery, GetCattleDashboardQueryArgs>(this, () => [
			GET_CATTLE_DASHBOARD,
			{
				variables: this.variables,
				fetchPolicy: 'no-cache',
			},
		]);

		this.variables = {
			allocationPositionsCalc: {
				sum: {
					grossPnl: true,
					unitQuantity: true,
				},
			},
			allocationPositionsGroupBy: {
				Product: {
					slug: true,
				},
			},
			productSlugs: ['livestock-live-cattle', 'grain-corn', 'grain-soybeans', 'grain-soybean-meal', 'livestock-feeder-cattle'],
			startDate,
			endDate,
			customerId: businessId,
			currentPositionsWhere: this.generateAggregateCurrentAllocationPositionsWhere(businessId, startDate, endDate),
			cattleLotsWhere: {
				businessId: {
					equals: businessId,
				},
			},
			feedRationUsageWhere: {
				businessId: {
					equals: businessId,
				},
			},
		};

		await getCattleDashboard.promise;

		return {
			lastUpdatedAt: DateTime.now().toISO(),
			getCattleDashboard,
			scopeId: businessId,
		};
	}

	generateAggregateCurrentAllocationPositionsWhere(
		businessId: string,
		startDate: string,
		endDate: string,
	): CurrentAllocationPositionFilterDTO {
		return {
			businessId: {
				equals: businessId,
			},
			Product: { slug: { in: ['livestock-live-cattle', 'grain-corn', 'grain-soybeans', 'grain-soybean-meal', 'livestock-feeder-cattle'] } },
			effectiveHedgeDate: { gte: startDate, lte: endDate },
			OR: [
				{ instrumentType: { equals: TypeOfInstrument.Future }, contractQuantity: { lt: 0 } },
				{ instrumentType: { equals: TypeOfInstrument.Option }, optionType: { equals: TypeOfOption.Put }, contractQuantity: { gt: 0 } },
			],
		};
	}
}
