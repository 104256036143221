/* eslint-disable ember/no-controller-access-in-routes */
import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import RouterService from '@ember/routing/router-service';
import AuthService from 'vault-client/services/auth';
import ErrorHandler from 'vault-client/services/error-handler';
// Needed allow typescript to find @cached imports
import 'ember-cached-decorator-polyfill';
import Transition from '@ember/routing/transition';
import PermissionsService from 'vault-client/services/permissions';
import MarkerWidgetService from 'vault-client/services/marker-widget';
import AccessibilityService from 'vault-client/services/accessibility';
import { analyticsPageView, setAnalyticsDistinctId } from 'vault-client/utils/analytics-tracking';

export default class ApplicationRoute extends Route {
	@service declare router: RouterService;
	@service declare auth: AuthService;
	@service declare permissions: PermissionsService;
	@service declare markerWidget: MarkerWidgetService;
	@service declare accessibility: AccessibilityService;
	@service errorHandler!: ErrorHandler;

	disableLoggingForRoute = ['callback'];

	queryParams = {
		organizationId: {
			refreshModel: true,
		},
		globalCustomerId: {
			refreshModel: true,
		},
		locationId: {
			refreshModel: true,
		},
	};

	constructor(...args: any) {
		super(...args);

		this.router.on('routeDidChange', () => {
			const routeName = this.router.currentRouteName || 'unknown';
			if (this.disableLoggingForRoute.includes(routeName)) return;

			setTimeout(() => {
				const paramArray = [];
				const params = this.router.currentRoute?.params;

				// use route params as extra detail to the route name
				for (const prop in params) {
					paramArray.push(` ${prop}: ${params[prop]}`);
				}

				analyticsPageView(routeName, paramArray, this.router.currentRoute);
			}, 3000);
		});
	}

	async beforeModel(transition: Transition<unknown>) {
		await this.auth.createAuthClient();

		try {
			await this.markerWidget.registerWidget();
		} catch (error) {
			console.error('Unable to register marker widget', error);
		}
		this.accessibility.updateBodyClass();

		// Do not require authentication for callback route (Can cause infinite loop)
		if (transition.to?.name !== 'callback') {
			await this.auth.requireAuthentication();
		}
	}

	@action
	error(error: Error): void {
		this.errorHandler.handleError(error);
	}

	@action
	async loading(transition: any) {
		const controller = this.controllerFor('application');
		if (this.auth.isAuthenticated && this.auth?.user) {
			setAnalyticsDistinctId(this.auth.user);
		}
		controller.set('isLoading' as keyof Controller, true);
		transition.promise.finally(function () {
			controller.set('isLoading' as keyof Controller, false);
		});
	}
}
