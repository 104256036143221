import { CustomTooltipOptions } from 'vault-client/utils/chart-utils';
import { ChartData, ChartDataset, ChartOptions } from 'chart.js';
import { getCustomTooltip } from 'vault-client/utils/chart-utils';
import { projectedPnlRawData } from './dashboard-utils';
import { DateTime } from 'luxon';
import { getThirteenMonthsFromStartDate } from 'vault-client/utils/month-utils';
import { CattlePnlMonth } from 'vault-client/models/cattle-pnl-month';
import { DisplayUnits } from 'vault-client/types/cattle/display-units';


function projectedPnlChartData(months: CattlePnlMonth[], selectedDisplayUnit: DisplayUnits): ChartData<'line'> {
	const labels = getThirteenMonthsFromStartDate(DateTime.local().startOf('month'));

	const datasets: ChartDataset<'line', (number | null)[]>[] = [
		{
			label: 'Net P/L',
			data: projectedPnlRawData(months, selectedDisplayUnit)['Net P/L'],
			borderColor: '#0A558C',
			backgroundColor: '#0A558C',
			pointRadius: 0,
			tension: 0,
		},
		{
			label: 'Hedge P/L',
			data: projectedPnlRawData(months, selectedDisplayUnit)['Hedge P/L'],
			borderColor: '#F76808',
			backgroundColor: '#F76808',
			pointRadius: 0,
			tension: 0,
		},
		{
			label: 'Revenues',
			data: projectedPnlRawData(months, selectedDisplayUnit).Revenues,
			borderColor: '#2F9E44',
			backgroundColor: '#2F9E44',
			pointRadius: 0,
			tension: 0,
		},
		{
			label: 'Expenses',
			data: projectedPnlRawData(months, selectedDisplayUnit).Expenses,
			borderColor: '#E6B800',
			backgroundColor: '#E6B800',
			pointRadius: 0,
			tension: 0,
		},
	];

	return {
		labels,
		datasets,
	};
}

function projectedPnlChartOptions(): ChartOptions<'line'> {
	const tooltipOptions: CustomTooltipOptions = {
		titleFormatter: (val: string) => val,
		valueFormatter: (val: number) => {
			return new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD',
				minimumFractionDigits: 0,
				maximumFractionDigits: 0,
				notation: 'compact',
			}).format(val);
		},
	};

	return {
		responsive: true,
		maintainAspectRatio: false,
		interaction: {
			mode: 'index',
			intersect: false,
		},
		scales: {
			x: {
				grid: {
					display: false,
				},
			},
			y: {
				grid: {
					color: '#E5E7EB',
				},
				ticks: {
					callback: (value: number) => {
						return new Intl.NumberFormat('en-US', {
							style: 'currency',
							currency: 'USD',
							notation: 'compact',
						}).format(value);
					},
				},
			},
		},
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				enabled: false,
				external: getCustomTooltip(tooltipOptions),
			},
		},
	};
}

export {
	projectedPnlChartOptions,
	projectedPnlChartData,
}

