import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='mt-3 ml-1' id={{this.legendId}}></div>\n<div id='{{@id}}-container' class='relative h-80' ...attributes>\n\t<BarChart @id={{@id}} @options={{this.chartOptions}} @data={{@data}} @chartData={{this.chartData}} @plugins={{this.plugins}} />\n</div>", {"contents":"<div class='mt-3 ml-1' id={{this.legendId}}></div>\n<div id='{{@id}}-container' class='relative h-80' ...attributes>\n\t<BarChart @id={{@id}} @options={{this.chartOptions}} @data={{@data}} @chartData={{this.chartData}} @plugins={{this.plugins}} />\n</div>","moduleName":"vault-client/components/harvest-pnl-bar-chart/revenues.hbs","parseOptions":{"srcName":"vault-client/components/harvest-pnl-bar-chart/revenues.hbs"}});
import { guidFor } from '@ember/object/internals';
import { getCustomLegend, type CustomTooltipOptions } from 'vault-client/utils/chart-utils';
import Component from '@glimmer/component';
import type { ChartData, ChartDataset, ChartOptions, TooltipItem } from 'chart.js';
import { mapToDefaultChartColors } from 'vault-client/utils/chart';
import type { CropGroup } from 'vault-client/utils/grain/crop';
import {
	projectedRevenueKeyToLabel,
	type ProjectedRevenue,
	type ProjectedRevenueKey,
	type ProjectedRevenueLabel,
} from 'vault-client/utils/grain/dashboard';
import type { GenerateChartOptions, GenerateTooltipOptions } from '../harvest-pnl-bar-chart';

type ProjectedRevenueChartKey = 'Business' | CropGroup;
export type ProjectedRevenueChartRawData = Partial<Record<ProjectedRevenueChartKey, ProjectedRevenue>>;

export interface HarvestPnlBarChartRevenuesSignature {
	Args: {
		id: string;
		data: ProjectedRevenueChartRawData;
		generateTooltipOptions: GenerateTooltipOptions;
		generateChartOptions: GenerateChartOptions;
	};
	// Any blocks yielded by the component
	Blocks: {
		default: [];
	};
	// The element to which `...attributes` is applied in the component template
	Element: HTMLDivElement;
}

export default class HarvestPnlBarChartRevenuesComponent extends Component<HarvestPnlBarChartRevenuesSignature> {
	guid = guidFor(this);

	// Defines the desired datasets to display in the chart and their order
	revenueDatasetKeys: ProjectedRevenueKey[] = [
		'physicalCropSales',
		'brokeragePnl',
		'unsoldMarkToMarket',
		'additionalRevenue',
		'businessRevenue',
	];

	// Defines the colors for each dataset
	get colorMap() {
		return mapToDefaultChartColors(this.revenueDatasetKeys);
	}

	// Defines which rows will be shown in the tooltip for each revenue group
	businessRevenueTooltipLabels: Set<ProjectedRevenueLabel> = new Set(['Business Revenue']);
	nonCommodityCropsRevenueTooltipLabels: Set<ProjectedRevenueLabel> = new Set([
		'Physical Crop Sales',
		'Unsold - M2M',
		'Additional Revenue',
	]);
	commodityCropsRevenueTooltipLabels: Set<ProjectedRevenueLabel> = new Set([
		'Physical Crop Sales',
		'Brokerage P/L',
		'Unsold - M2M',
		'Additional Revenue',
	]);

	get legendId() {
		return `${this.args.id}-legend-${this.guid}`;
	}

	get labels() {
		return Object.keys(this.args.data);
	}

	get values() {
		return Object.values(this.args.data);
	}

	get chartData(): ChartData<'bar'> {
		const { revenueDatasetKeys, colorMap, labels, values } = this;

		const datasets: ChartDataset<'bar'>[] = revenueDatasetKeys.map((key) => {
			const data = values.map((value) => value[key] ?? 0);

			return {
				label: projectedRevenueKeyToLabel[key],
				data,
				backgroundColor: colorMap[key],
			};
		});

		return {
			labels,
			datasets,
		};
	}

	get plugins() {
		const { id } = this.args;
		const { legendId } = this;
		return [
			{
				afterUpdate: getCustomLegend(id, legendId),
			},
		];
	}

	get tooltipOptions(): CustomTooltipOptions {
		return this.args.generateTooltipOptions({ dataPointFilter: this.dataPointFilter });
	}

	get chartOptions(): ChartOptions<'bar'> {
		return this.args.generateChartOptions({
			tooltipOptions: this.tooltipOptions,
		});
	}

	// Filter function to determine which data points to show in tooltips
	dataPointFilter = (tooltipItem: TooltipItem<'bar'>) => {
		const label = tooltipItem.label as ProjectedRevenueChartKey;
		const datasetLabel = tooltipItem.dataset.label as ProjectedRevenueLabel;

		if (label === 'Business') {
			return this.businessRevenueTooltipLabels.has(datasetLabel);
		} else if (label === 'Non-Commodity Crops') {
			return this.nonCommodityCropsRevenueTooltipLabels.has(datasetLabel);
		} else {
			return this.commodityCropsRevenueTooltipLabels.has(datasetLabel);
		}
	};
}
