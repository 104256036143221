import CropDetailController from 'vault-client/controllers/crops/detail';

export default class BusinessesBusinessCropDetail extends CropDetailController {
	cropsDashboardRoutePath: string = 'businesses.business.crops-dashboard';
	targetsRoutePath: string = 'businesses.business.crop-targets';
	cropContractsRoutePath = 'businesses.business.crop-contracts';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/crop-detail': BusinessesBusinessCropDetail;
	}
}
