import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { gql, useMutation, QueryResource } from 'glimmer-apollo';
import { inject as service } from '@ember/service';
import { GrainTargetOrder, GrainTargetOrderUpdateDTO, GrainOrderNoteCreateDTO } from 'vault-client/types/graphql-types';

const REQUEST_CANCEL_TARGET_MUTATION = gql`
	mutation RequestGrainTargetOrderCancellation($id: String!) {
		requestGrainTargetOrderCancellation(id: $id) {
			id
		}
	}
`;

const INSERT_TARGET_NOTES_MUTATION = gql`
	mutation CreateGrainOrderNote($data: GrainOrderNoteCreateDTO!) {
		createGrainOrderNote(data: $data) {
			id
		}
	}
`;

interface RequestCancelTargetButtonArgs {
	target: GrainTargetOrder;
	model: QueryResource<unknown, Record<string, unknown>>;
}

interface RequestCancelTargetVars {
	id: string;
}

interface CreateNoteMutationArgs {
	data: GrainOrderNoteCreateDTO;
}

export default class RequestCancelTargetButton extends Component<RequestCancelTargetButtonArgs> {
	@service permissions: any;
	@tracked showModal: boolean = false;
	@tracked note: string = '';
	@tracked errorMessage: string | null = null;

	cancelTargetMutation = useMutation<GrainTargetOrderUpdateDTO, RequestCancelTargetVars>(this, () => [
		REQUEST_CANCEL_TARGET_MUTATION,
		{
			onComplete: () => {
				this.errorMessage = null;
			},
			onError: (error): void => {
				this.errorMessage = 'There was an error. Request to cancel target not sent.';
				console.error('Error while attempting to request cancel target.', error.message);
			},
			update: (cache) => {
				cache.evict({ fieldName: 'GrainTargetOrder' });
				cache.evict({ fieldName: 'GrainTargetOrders' });
				cache.evict({ fieldName: 'GrainCropPlan' });
				cache.evict({ fieldName: 'GrainCropPlans' });
				cache.evict({ fieldName: 'GrainFeedPlan' });
				cache.evict({ fieldName: 'GrainFeedPlans' });
				cache.gc();
			},
		},
	]);

	insertTargetNotesMutation = useMutation<GrainOrderNoteCreateDTO, CreateNoteMutationArgs>(this, () => [
		INSERT_TARGET_NOTES_MUTATION,
		{
			onComplete: () => {
				this.errorMessage = null;
			},
			onError: (error): void => {
				this.errorMessage = 'There was an error. Unable to create target notes.';
				console.error('Error while attempting to insert target notes on request cancel button.', error.message);
			},
			update: (cache) => {
				cache.evict({ fieldName: 'GrainOrderNotes' });
				cache.gc();
			},
		},
	]);

	@action
	openModal() {
		this.showModal = true;
	}

	@action
	closeModal() {
		this.showModal = false;
		this.note = '';
	}

	@action
	async submit() {
		if (this.args.target) {
			const noteVariables: CreateNoteMutationArgs = {
				data: {
					orderId: this.args.target.id,
					note: this.note,
					writtenBy: this.permissions.userName,
				},
			};

			const targetVariables: RequestCancelTargetVars = {
				id: this.args.target.id,
			};

			const _updateTargetMutation = this.cancelTargetMutation.mutate(targetVariables);
			const _insertTargetNotesMutation = this.note ? this.insertTargetNotesMutation.mutate(noteVariables) : null;

			await _updateTargetMutation;
			await _insertTargetNotesMutation;

			this.closeModal();
		} else {
			throw new Error('No target available to request to cancel target');
		}
	}
}
