import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import BusinessesBusinessRoute from 'vault-client/routes/businesses/business';
import { ModelFrom } from 'vault-client/utils/type-utils';
import { Query, Query_FieldsArgs, FieldSortByDTO } from 'vault-client/types/graphql-types';
import { gql, useQuery } from 'glimmer-apollo';
import { SortObj } from 'vault-client/types/vault-table';
export const GET_FIELDS = gql`
	query Fields(
		$orderBy: FieldSortByDTO
		$where: FieldFilterDTO
		$offset: Float
		$limit: Float
		$ledgerWhere: FieldLedgerEntryPerHarvestYearFilterDTO
		$startDate: String
		$endDate: String
	) {
		Fields(orderBy: $orderBy, where: $where, offset: $offset, limit: $limit) {
			id
			name
			acres
			CropHarvests(where: { harvestYearStartDate: { gte: $startDate, lte: $endDate } }) {
				yieldPerAcre
				totalHarvestYield
				Crop {
					name
					price
					pricingMethodology
				}
				PhysicalCropTransactions(
					where: { OR: [{ deliveryStartDate: { gte: $startDate, lte: $endDate }, deliveryEndDate: { gte: $startDate, lte: $endDate } }] }
				) {
					flatPrice
					futuresPrice
					basisPrice
					bushels
					pricingType
				}
			}
			FieldLedgerEntriesPerHarvestYear(where: $ledgerWhere) {
				cropFieldLedgerType
				perAcreValueInUsd
				flatValueInUsd
			}
		}
		FieldCount(where: $where) {
			count
		}
	}
`;

export type FieldsQuery = {
	Fields: Query['Fields'];
	FieldCount: Query['FieldCount'];
};

interface QueryParams {
	name: string;
	acres: number;
	sorts: SortObj[];
	page: number;
	size: number;
	startDate: string;
	endDate: string;
}
export default class BusinessesBusinessFieldsIndexRoute extends Route {
	@tracked variables = {};

	queryParams = {
		name: { refreshModel: true },
		acres: { refreshModel: true },
		sorts: { refreshModel: true },
		page: { refreshModel: true },
		size: { refreshModel: true },
		startDate: { refreshModel: true },
		endDate: { refreshModel: true },
	};

	clientOnlySortValuePaths = ['crop'];
	isApiSupportedSort = ({ valuePath }: SortObj) => !this.clientOnlySortValuePaths.includes(valuePath);

	generateOrderBy(sorts: SortObj[]): FieldSortByDTO {
		const orderBy: {
			[key: string]: any;
		} = {};

		sorts.filter(this.isApiSupportedSort).forEach((sort: any) => {
			const value = sort.isAscending ? 'Asc' : 'Desc';
			orderBy[sort.valuePath] = value;
		});

		return orderBy;
	}

	getFields = useQuery<FieldsQuery, Query_FieldsArgs>(this, () => [GET_FIELDS, { variables: this.variables }]);
	async model(params: QueryParams) {
		const { businessId } = this.modelFor('businesses.business') as ModelFrom<BusinessesBusinessRoute>;
		this.variables = {
			where: { businessId: { equals: businessId } },
			orderBy: this.generateOrderBy(params.sorts),
			offset: params.page * params.size,
			limit: params.size,
			ledgerWhere: { harvestYearStartDate: { gte: params.startDate, lte: params.endDate } },
			startDate: params.startDate,
			endDate: params.endDate,
		};
		await this.getFields.promise;
		return { businessId: businessId, fields: this.getFields, variables: this.variables };
	}
}
