import { gql } from 'glimmer-apollo';
import { Query, Query_CattleLotsArgs } from 'vault-client/types/graphql-types';

const GET_CATTLE_LOTS_QUERY = gql`
	query CattleLots(
		$scopeId: String!
		$where: CattleLotFilterDTO
		$rationUsageWhere: CattleLotFeedRationUsagePerMonthFilterDTO
		$offset: Float
		$limit: Float
		$sortBy: [CattleLotSortByDTO!]
	) {
		CattleLots(scopeId: $scopeId, where: $where, offset: $offset, limit: $limit, sortBy: $sortBy) {
			id
			name
			description
			activeStartDate
			activeEndDate
			numberOfCattle
			numberOfCattleToSell
			startWeightInLb
			targetWeightInLb
			totalEndingWeightInLb
			averageDailyWeightGainInLb
			mortalityRate
			purchasePriceInUsd
			purchasePricePerHeadInUsd
			isActive
			cattleLotExpensesType
			cattleLotInputExpensesInUsd
			cattleLotTotalExpensesInUsd
			numberOfDaysActive
			forecastedSalesRevenueInUsd
			forecastedSellPricePerHeadInUsd
			forecastedSellPricePerLbInUsd
			CattleLotFeedRationUsagesPerMonth(where: $rationUsageWhere) {
				id
				monthStartDate
				totalExpenseInUsd
			}
			CattleYard {
				id
				name
			}
			CattleFeedRation {
				id
				name
			}
			Business {
				id
				name
			}
		}
		CattleLotCount(scopeId: $scopeId, where: $where) {
			count
		}
	}
`;

const GET_CATTLE_LOTS_SEARCH = gql`
	query CattleLotsSearch($scopeId: String!, $where: CattleLotFilterDTO) {
		CattleLots(scopeId: $scopeId, where: $where) {
			id
			name
			CattleYard {
				id
				name
			}
			CattleFeedRation {
				id
				name
			}
		}
	}
`;

const GET_CATTLE_LOTS_COUNT_BY_BUSINESS = gql`
	query CattleLotsCountByBusiness($scopeId: String!) {
		CattleLotCount(scopeId: $scopeId) {
			count
		}
	}
`;

type LotsOverviewQuery = {
	CattleLots: Pick<
		Query['CattleLots'][number],
		| 'id'
		| 'name'
		| 'description'
		| 'activeStartDate'
		| 'activeEndDate'
		| 'numberOfCattle'
		| 'numberOfCattleToSell'
		| 'startWeightInLb'
		| 'targetWeightInLb'
		| 'averageDailyWeightGainInLb'
		| 'mortalityRate'
		| 'purchasePricePerHeadInUsd'
		| 'isActive'
		| 'cattleLotExpensesType'
		| 'cattleLotInputExpensesInUsd'
		| 'cattleLotTotalExpensesInUsd'
		| 'numberOfDaysActive'
		| 'forecastedSalesRevenueInUsd'
		| 'CattleLotFeedRationUsagesPerMonth'
		| 'CattleYard'
		| 'CattleFeedRation'
		| 'Business'
	>[];
	CattleLotCount: Pick<Query['CattleLotCount'], 'count'>;
};

type LotsSearchQuery = {
	CattleLots: Pick<Query['CattleLots'][number], 'id' | 'name' | 'CattleYard' | 'CattleFeedRation'>[];
};

type LotsOverviewQueryVariables = Query_CattleLotsArgs & {
	rationUsageWhere?: {
		monthStartDate?: {
			gte?: string;
			lte?: string;
		};
	};
};

type LotsSearchQueryVariables = {
	scopeId: string;
	where?: Query_CattleLotsArgs['where'];
};

export {
	GET_CATTLE_LOTS_QUERY,
	GET_CATTLE_LOTS_SEARCH,
	GET_CATTLE_LOTS_COUNT_BY_BUSINESS,
	type LotsOverviewQuery,
	type LotsOverviewQueryVariables,
	type LotsSearchQuery,
	type LotsSearchQueryVariables,
};
