import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { SortObj } from 'vault-client/types/vault-table';
import { hasError as validationStateHasError } from 'vault-client/utils/forms/validation-state/has-error';
import { ModelFrom } from 'vault-client/utils/type-utils';
import BusinessesBusinessCattleYardsRoute from 'vault-client/routes/businesses/business/cattle/yards';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import { CattleYardsYardsTableRow } from 'vault-client/components/cattle/yards/yards-table';
import { service } from '@ember/service';
import FormStateService from 'vault-client/services/form-state';
import { getYardsTableColumns } from 'vault-client/utils/components/cattle/yards/yards-table';

type SidePanelState = 'CREATE_YARD' | 'EDIT_YARD' | null;

export default class BusinessesBusinessCattleYardsController extends Controller {
	yardsTableRoute = 'businesses.business.cattle.yards-table';
	yardsTableId = 'yards-table';
	createYardFormId = 'create-cattle-yard-form';
	editYardFormId = 'edit-cattle-yard-form';
	yardsTableColumnControlTriggerId = 'cattle-yards-table-column-control-trigger';
	queryParams = ['page', 'size', 'sorts'];
	declare model: ModelFrom<BusinessesBusinessCattleYardsRoute>;

	@tracked page = 0;
	@tracked size = 100;
	@tracked sorts: SortObj[] = [];
	@tracked yardToEdit: CattleYardsYardsTableRow | null = null;
	@tracked yardToDelete: CattleYardsYardsTableRow | null = null;
	@tracked sidePanelState: SidePanelState = null;

	@service('form-state') declare formStateService: FormStateService;

	get businessId() {
		return this.model.businessId;
	}

	get yards() {
		return this.model.yardsOverview.data?.CattleYards ?? [];
	}

	get totalYardsCount() {
		return this.model.yardsOverview.data?.CattleYardCount?.count ?? 0;
	}

	get isSidePanelOpen() {
		return this.sidePanelState !== null;
	}

	get formId() {
		switch (this.sidePanelState) {
			case 'CREATE_YARD':
				return this.createYardFormId;
			case 'EDIT_YARD':
				return this.editYardFormId;
			default:
				return null;
		}
	}

	get formState() {
		if (!this.formId) return undefined;
		return this.formStateService.getState(this.formId);
	}

	get submitButtonText(): string {
		switch (this.sidePanelState) {
			case 'CREATE_YARD':
				return this.formState?.submissionState?.isPending ? 'Creating...' : 'Create Yard';
			case 'EDIT_YARD':
				return this.formState?.submissionState?.isPending ? 'Saving...' : 'Save Changes';
			default:
				return 'Submit';
		}
	}

	get panelTitle() {
		switch (this.sidePanelState) {
			case 'CREATE_YARD':
				return 'Create Yard';
			case 'EDIT_YARD':
				return 'Edit Yard';
			default:
				return '';
		}
	}

	get isSubmitDisabled(): boolean {
		// Disable submit by default until we have a validation state
		if (!this.formState?.validationState) return true;
		return validationStateHasError(this.formState.validationState) || !!this.formState.submissionState?.isPending;
	}

	get yardsTableColumns() {
		return getYardsTableColumns({
			editYard: this.editYard,
			deleteYard: this.deleteYard,
		});
	}

	@action
	submitForm() {
		this.formState?.submit?.();
	}

	@action
	setSorts(sorts: SortObj[]) {
		this.sorts = sorts;
	}

	@action
	setTablePageState(newPageVal?: number) {
		this.page = newPageVal ?? 0;
		resetVaultTableScroll(this.yardsTableId);
	}

	@action
	closeSidePanel() {
		this.setSidePanelState({ state: null });
	}

	@action
	createYard() {
		this.setSidePanelState({ state: 'CREATE_YARD' });
	}

	@action
	editYard(yard: CattleYardsYardsTableRow) {
		this.setSidePanelState({ state: 'EDIT_YARD', yardToEdit: yard });
	}

	@action
	deleteYard(yard: CattleYardsYardsTableRow) {
		this.yardToDelete = yard;
	}

	@action
	closeDeleteYardModal() {
		this.yardToDelete = null;
	}

	@action
	setSidePanelState(nextState: { state: 'CREATE_YARD' } | { state: 'EDIT_YARD'; yardToEdit: CattleYardsYardsTableRow } | { state: null }) {
		if (nextState.state === 'EDIT_YARD') {
			this.yardToEdit = nextState.yardToEdit;
		} else {
			this.yardToEdit = null;
		}
		this.sidePanelState = nextState.state;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'businesses/business/cattle/yards': BusinessesBusinessCattleYardsController;
	}
}
