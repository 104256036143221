import type { ErrorRecord, FormKey, FormValidateCallback } from 'ember-headless-form';
import { assert } from '@ember/debug';
import { getDotPath, ObjectEntries, ObjectSchema, safeParseAsync } from 'valibot';

export default function validateChangeset<DATA extends ObjectEntries, TSchema extends ObjectSchema<DATA, undefined>>(
	schema: TSchema,
): FormValidateCallback<Partial<DATA>> {
	return async (formData) => {
		const result = await safeParseAsync(schema, formData, { abortEarly: false });

		if (result.success) return undefined;

		const { issues } = result;
		const errorRecord: ErrorRecord<DATA> = {};

		for (const issue of issues) {
			const { type, input, message } = issue;
			// ember-headless-form does not support nested properties, so this should always return a valid key
			const key = getDotPath(issue) as FormKey<DATA>;
			assert(
				'Received undefined path for valibot validation error. If you see this, please report it as a bug to ember-headless-form!',
				key !== undefined,
			);

			if (!errorRecord[key]) {
				errorRecord[key] = [];
			}

			const errors = errorRecord[key];
			assert('Expected errorRecord to have array', errors); // TS does not understand errors cannot be undefined at this point

			errors.push({
				type: type ?? 'unknown',
				value: input as DATA[FormKey<DATA>],
				message,
			});
		}

		return errorRecord;
	};
}
