import { startOfWeek, parseISO, addWeeks, subWeeks, formatISO, startOfMonth } from 'date-fns';

function getExpectedMarketingDate(dob: string, averageFinishAgeInWeeks: number): Date {
	const dobDate = parseISO(dob);

	const expectedMarketingDate = addWeeks(getStartOfWeek(dobDate), averageFinishAgeInWeeks);
	return expectedMarketingDate;
}

function getExpectedMarketingStartOfMonth(dob: string, averageFinishAgeInWeeks: number): string {
	const expectedMarketingDate = getExpectedMarketingDate(dob, averageFinishAgeInWeeks);
	const startOfExpectedMarketingMonth = startOfMonth(expectedMarketingDate);
	return toISOFormat(startOfExpectedMarketingMonth);
}

/**
 * Returns the DOB start and end dates for the given marketing start and end dates
 * @param marketingStartDate - The start date of the marketing range
 * @param marketingEndDate - The end date of the marketing range
 * @param averageFinishAgeInWeeks - The average finish age in weeks
 * @returns The start and end dates of the DOB range
 */
function getDobDateRange(
	marketingStartDate: string,
	marketingEndDate: string,
	averageFinishAgeInWeeks: number,
): { startDate: string; endDate: string } {
	const startDob = subWeeks(getStartOfWeek(parseISO(marketingStartDate)), averageFinishAgeInWeeks);
	const endDob = subWeeks(getStartOfWeek(parseISO(marketingEndDate)), averageFinishAgeInWeeks);

	return {
		startDate: toISOFormat(startDob),
		endDate: toISOFormat(endDob),
	};
}

function getStartOfWeek(date: Date): Date {
	return startOfWeek(date, { weekStartsOn: 0 });
}

function toISOFormat(date: Date, representation: 'date' | 'complete' | 'time' = 'date'): string {
	return formatISO(date, { representation });
}

export { getExpectedMarketingDate, getExpectedMarketingStartOfMonth, getDobDateRange };
