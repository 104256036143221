/* eslint-disable no-unused-vars */
export enum CellComponents {
	String = 'String',
	EmptyCell = 'EmptyCell',
	Paragraph = 'Paragraph',
	IntlDateTimeFormat = 'IntlDateTimeFormat',
	IntlNumberFormat = 'IntlNumberFormat',
	MonthFormat = 'MonthFormat',
	ColorizedPercent = 'ColorizedPercent',
	Currency = 'Currency',
	Check = 'Check',
	SelectCheckBox = 'SelectCheckBox',
	Details = 'Details',
	Button = 'Button',
	UpdateHedgeStrategyItemsButton = 'UpdateHedgeStrategyItemsButton',
	StringWithButtons = 'StringWithButtons',
	Input = 'Input',
	InputWithButton = 'InputWithButton',
	HistoricalPriceRangeFormat = 'HistoricalPriceRangeFormat',
	MarketPriceFormat = 'MarketPriceFormat',
	SessionChangeFormat = 'SessionChangeFormat',
	PriceFormat = 'PriceFormat',
	QuarterFormat = 'QuarterFormat',
	FormatArrayOfStrings = 'FormatArrayOfStrings',
	MarketVolume = 'MarketVolume',
	MarketOpenInterest = 'MarketOpenInterest',
	MarketDataProperty = 'MarketDataProperty',
	Tag = 'Tag',
	WeekFormat = 'WeekFormat',
	CustomFormat = 'CustomFormat',
	NumericFormat = 'NumericFormat',
	InputOneWay = 'InputOneWay',
	AddButton = 'AddButton',
	CustomClass = 'CustomClass',
	DropDown = 'DropDown',
	CalculationCell = 'CalculationCell',
	CalculationCellInputs = 'CalculationCellInputs',
}

export type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

export type TableColumn = {
	id: string;
	name: string;
	secondaryDisplayName?: string;
	valuePath?: string;
	width?: number;
	minWidth?: number;
	maxWidth?: number;
	cellComponent: CellComponents | ((row: unknown) => CellComponents);
	footerIsString?: boolean;
	isSortable?: boolean;
	isFixed: string;
	isVisible: boolean;
	dark?: boolean;
	light?: boolean;
	isResizable?: boolean;
	isReorderable?: boolean;
	textAlign?: string;
	headerTextAlign?: string;
	componentArgs?: any;
	subcolumns?: TableColumn[];
	headerTooltip?: string;
	headerLinkRoute?: string;
	headerLinkModel?: string;
	headerLinkModels?: (string | number)[];
	headerLinkQuery?: Record<string, string>;
	linkRoute?: string;
	linkRoutePath?: string;
	linkModelPath?: string;
	linkModelPaths?: string[];
	linkQuery?: LinkQuery;
	isTotaled?: boolean;
	csvMaxFractionDigits?: number;
};

export enum LinkQueryValueKey {
	RowKey = 'rowKey',
	StaticValue = 'staticValue',
}

export type LinkQuery = {
	[key: string]: {
		[LinkQueryValueKey.RowKey]?: any;
		[LinkQueryValueKey.StaticValue]?: any;
	} | null;
} | null;

export type ColumnListMap = Map<string, TableColumn[]>;
