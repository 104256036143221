import { service } from '@ember/service';
import { cached, tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { CreateEditFeedIngredientData } from 'vault-client/components/create-edit-feed-ingredient-form';
import Controller from '@ember/controller';
import { ModelFrom } from 'vault-client/utils/type-utils';
import BusinessesBusinessFeedOverviewRoute from 'vault-client/routes/businesses/business/feed-overview';
import { task } from 'ember-concurrency';
import {
	clearCreateEditFeedIngredientData,
	createFeedIngredient,
	CURRENT_FEED_YEAR,
	FEED_YEAR_OPTIONS,
	getIngredientTableId,
	parseCreateEditFeedIngredientData,
	parseHedgeProductSlugFromFeedIngredient,
} from 'vault-client/utils/feed-utils';
import { TrackedObject } from 'tracked-built-ins';
import { getInvalidElements, isFormValid } from 'vault-client/utils/form-validation';
import { DateTime } from 'luxon';
import { UiDateFilterOption } from 'vault-client/components/vault/ui-date-filter';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import { v5 as uuid } from 'uuid';
import {
	CurrentAllocationPosition,
	FeedIngredient,
	TypeOfInstrument,
	TypeOfOption,
	Option,
	Swaption,
	ProductLotSpecification,
	InstrumentSymbolGroup,
	Mutation,
	Maybe,
	BusinessEntityRole,
	FeedIngredientCreateDTO,
} from 'vault-client/types/graphql-types';
import MarketDataService from 'vault-client/services/market-data';
import { convertTonsToPricingUnit, getFeedIngredientMarketPrice } from 'vault-client/utils/feed-utils';
import { intervalFromDateTime } from 'vault-client/utils/interval-from-date-time';
import { getOwner, setOwner } from '@ember/application';
import checkStorageAvailable from 'vault-client/utils/check-storage-available';
import RouterService from '@ember/routing/router-service';

interface RowData {
	month: string;
	usageDMITons: number | null;
	purchasedDMITons: number | null;
	percentPurchased: number | null;
	percentNotPurchased: number | null;
	avgPricePurchased: number | null;
	avgPriceNotPurchased: number | null;
	notPurchasedDMITons: number | null;
	totalCost: number | null;
}

interface HedgedMonthValues {
	usageDMITons?: number;
	purchasedDMITons?: number;
	futuresHedgedInUnits?: number;
	callsHedgedInUnits?: number;
	CurrentAllocationPositions?: CurrentAllocationPosition[];
	transactions?: CurrentAllocationPosition[];
	volumePurchasedWithFlatPriceInTons?: number;
	volumePurchasedWithFuturesPriceOnlyInTons?: number;
	volumePurchasedWithBasisPriceOnlyInTons?: number;
	volumePurchasedWithoutPriceInTons?: number;
}

export class HedgeMonth {
	LBS_PER_TON = 2000;
	tonsPerPriceUnit = 1;
	slug: string;
	date: string;
	symbolGroup: InstrumentSymbolGroup | null;
	standardProductLotSpecification: ProductLotSpecification | null;
	futureBarchartSymbol: string;
	transactions: CurrentAllocationPosition[] = [];

	// These values are in tons
	@tracked purchasedDMITons = 0;
	@tracked usageDMITons = 0;
	@tracked flatContractTons = 0;
	@tracked htaContractTons = 0;
	@tracked basisContractTons = 0;

	// These values are in price units
	@tracked futuresHedgedInUnits = 0;
	@tracked callsHedgedInUnits = 0;

	// All values are in units
	// Bushels for Corn, Tons for Soybean Meal

	@service declare marketData: MarketDataService;

	constructor(
		owner: any,
		date: string,
		slug: string,
		standardProductLotSpecification: ProductLotSpecification | null,
		symbolGroup: InstrumentSymbolGroup | null,
		futureBarchartSymbol: string,
		values?: HedgedMonthValues,
	) {
		setOwner(this, owner);
		this.date = date;
		this.slug = slug;
		this.standardProductLotSpecification = standardProductLotSpecification;
		this.symbolGroup = symbolGroup;
		this.futureBarchartSymbol = futureBarchartSymbol;

		if (values) {
			this.setValuesFromObject(values);
		}
	}

	get displayFactor() {
		return this.symbolGroup?.displayFactor ?? 1;
	}

	get fractionDigits() {
		return this.symbolGroup?.fractionDigits ?? 0;
	}

	get monthStartDate() {
		return DateTime.fromISO(this.date).startOf('month');
	}

	get monthEndDate() {
		return DateTime.fromISO(this.date).endOf('month');
	}

	get lotSize() {
		return this.standardProductLotSpecification?.lotSize ?? 1;
	}

	get pointValue() {
		return this.standardProductLotSpecification?.pointValue ?? 1;
	}

	get contractsHedged() {
		return (this.futuresHedgedInUnits + this.callsHedgedInUnits) / this.lotSize;
	}

	// Values in tons
	get usage() {
		return this.usageDMITons;
	}

	get purchased() {
		return this.purchasedDMITons;
	}

	get futuresHedgedInTons() {
		return this.convertPriceUnitsToTons(this.futuresHedgedInUnits);
	}

	get callsHedgedInTons() {
		return this.convertPriceUnitsToTons(this.callsHedgedInUnits);
	}

	// Prices
	get marketPrice() {
		return this.marketData.getLatestPrice(this.futureBarchartSymbol);
	}

	get hedgePrice() {
		if (!this.marketPrice) return null;

		// All users are being treated as naturally short feed for now
		// Therefore, we must subtract the hedge PL from the market price
		return this.marketPrice - this.hedgePlPerContract * (this.percentHedged ?? 1);
	}

	get fiftyTwoWeekHigh() {
		return this.marketData.getMarketDatum(this.futureBarchartSymbol)?.fiftyTwoWkHigh;
	}

	get fiftyTwoWeekLow() {
		return this.marketData.getMarketDatum(this.futureBarchartSymbol)?.fiftyTwoWkLow;
	}

	// Percentages
	get percentHedged() {
		return this.usage ? (this.futuresHedgedInTons + this.callsHedgedInTons) / this.usage : 0;
	}

	get percentPurchased() {
		return this.usage ? this.purchased / this.usage : 0;
	}

	get percentFlatContracts() {
		return this.usage ? this.flatContractTons / this.usage : 0;
	}

	get percentHTAContracts() {
		return this.usage ? this.htaContractTons / this.usage : 0;
	}

	get percentBasisContracts() {
		return this.usage ? this.basisContractTons / this.usage : 0;
	}

	get percentFuturesHedged() {
		return this.usage ? this.futuresHedgedInTons / this.usage : 0;
	}

	get percentCallsHedged() {
		return this.usage ? this.callsHedgedInTons / this.usage : 0;
	}

	// Hedge PL
	get hedgePlPerContract() {
		const hedgePl = this.hedgePl;
		const contractsHedged = this.contractsHedged;

		if (!hedgePl || !contractsHedged) return 0;

		return hedgePl / this.pointValue / this.contractsHedged;
	}

	get hedgePl() {
		const marketPrice = this.marketPrice;
		if (!marketPrice) return 0;

		return this.calculateHedgePls(this.transactions, marketPrice);
	}

	convertPriceUnitsToTons(units: number) {
		if (this.slug === 'grain-corn') {
			return (units * 56) / this.LBS_PER_TON; // 56 lbs per bushel
		} else {
			return units; // Soybean meal is priced per short ton
		}
	}

	setValuesFromObject(values: HedgedMonthValues) {
		this.purchasedDMITons = values.purchasedDMITons ?? 0;
		this.usageDMITons = values.usageDMITons ?? 0;
		this.futuresHedgedInUnits = values.futuresHedgedInUnits ?? 0;
		this.callsHedgedInUnits = values.callsHedgedInUnits ?? 0;
		this.transactions = values.CurrentAllocationPositions ?? [];
		this.flatContractTons = values.volumePurchasedWithFlatPriceInTons ?? 0;
		this.htaContractTons = values.volumePurchasedWithFuturesPriceOnlyInTons ?? 0;
		this.basisContractTons = values.volumePurchasedWithBasisPriceOnlyInTons ?? 0;
	}

	calculateHedgePls(transactions: CurrentAllocationPosition[], price: number) {
		return (
			transactions.reduce((total, transaction) => {
				let plPerPoint = 0;
				let premium = 0;
				const pointValue = transaction.Product.StandardProductLotSpecification.pointValue || 1;
				if (transaction.Instrument.type === TypeOfInstrument.Option || transaction.Instrument.type === TypeOfInstrument.Swaption) {
					const option = transaction.Instrument as Option | Swaption;
					premium = transaction.contractQuantity * transaction.lifetimeWeightedAveragePrice * pointValue;

					if (option.optionType === TypeOfOption.Call) {
						plPerPoint = transaction.contractQuantity * Math.max(0, price - option.strike);
					} else {
						plPerPoint = transaction.contractQuantity * Math.max(0, option.strike - price);
					}
				}

				if (transaction.Instrument.type === TypeOfInstrument.Future || transaction.Instrument.type === TypeOfInstrument.Swap) {
					plPerPoint = transaction.contractQuantity * (price - transaction.lifetimeWeightedAveragePrice);
				}

				return plPerPoint * pointValue - premium + total;
			}, 0) || 0
		);
	}

	get flatContractPercentage() {
		return this.usageDMITons ? (this.flatContractTons / this.usageDMITons) * 100 : 0;
	}

	get htaContractPercentage() {
		return this.usageDMITons ? (this.htaContractTons / this.usageDMITons) * 100 : 0;
	}

	get basisContractPercentage() {
		return this.usageDMITons ? (this.basisContractTons / this.usageDMITons) * 100 : 0;
	}

	generatePercentHedgedColumns() {
		return [
			{
				name: 'Flat Contracts',
				value: this.flatContractPercentage,
				color: 'var(--brand-interactive-blue-70)',
			},
			{
				name: 'HTA Contracts',
				value: this.htaContractPercentage,
				color: 'var(--brand-lemon-50)',
			},
			{
				name: 'Basis Contracts',
				value: this.basisContractPercentage,
				color: 'var(--brand-lime-50)',
			},
			{
				name: 'Futures',
				value: this.percentFuturesHedged,
				color: 'var(--brand-interactive-blue-70)',
			},
			{
				name: 'Calls',
				value: this.percentCallsHedged,
				color: 'var(--brand-orange-40)',
			},
		];
	}
}

export default class FeedOverviewController extends Controller {
	declare model: ModelFrom<BusinessesBusinessFeedOverviewRoute>;

	queryParams = ['startDate', 'endDate'];
	uuidNamespace = '3de788db-4a95-4523-ba2b-c437b471ea9e';

	@tracked isSidePanelOpen = false;
	@tracked createFeedIngredientFormData: CreateEditFeedIngredientData = new TrackedObject({
		error: '',
		dmiPercentage: '100',
		feedCategory: null,
		ingredientName: '',
		feedPriceType: 'Spot Price',
		flatPricePerTon: '',
		cmeBasisUsd: '',
		cmeBasisPercentage: '',
	}) as CreateEditFeedIngredientData;

	@tracked startDate: string = CURRENT_FEED_YEAR.startDate;
	@tracked endDate: string = CURRENT_FEED_YEAR.endDate;
	@tracked showSoybeanMealTable = false;
	@tracked showCornTable = false;

	@service declare marketData: MarketDataService;
	@service declare router: RouterService;
	timePeriodOptions: UiDateFilterOption[] = FEED_YEAR_OPTIONS.NUMBER;

	registeredFutures: string[] = [];
	get customer() {
		return this.model.getCustomer.data?.Customer;
	}

	get customerRole():
		| BusinessEntityRole.DairyProducer
		| BusinessEntityRole.GrainProducer
		| BusinessEntityRole.HogProducer
		| BusinessEntityRole.LiveCattleProducer
		| BusinessEntityRole.FeederCattleProducer
		| 'Unsupported Role' {
		for (const role of this.customer?.businessRoles ?? []) {
			if (
				role === BusinessEntityRole.DairyProducer ||
				role === BusinessEntityRole.HogProducer ||
				role === BusinessEntityRole.GrainProducer ||
				role === BusinessEntityRole.LiveCattleProducer ||
				role === BusinessEntityRole.FeederCattleProducer
			) {
				return role;
			}
		}
		return 'Unsupported Role';
	}

	get showSoybeanMealPercentHedged() {
		switch (this.customerRole) {
			case BusinessEntityRole.LiveCattleProducer:
			case BusinessEntityRole.FeederCattleProducer:
				return false;
			default:
				return true;
		}
	}

	get ingredientDetailRoute() {
		return 'businesses.business.feed-ingredient';
	}

	get componentId(): string {
		return guidFor(this);
	}

	get formId(): string {
		return `${this.componentId}-create-feed-ingredient-form`;
	}

	get canWriteOperations() {
		return !!this.customer?.CurrentUserPermissions.canWriteOperations && !this.isFoundationsClient;
	}

	get isFoundationsClient() {
		return this.customer?.isVgs;
	}

	get currentTimePeriodOption(): UiDateFilterOption {
		return {
			startDate: this.startDate,
			endDate: this.endDate,
		};
	}

	get showEmptyState(): boolean {
		return this.feedIngredients.length === 0;
	}

	get isSubmitting(): boolean {
		return this.submitCreateFeedIngredientForm.isRunning;
	}

	get disableSubmitButton(): boolean {
		return this.isSubmitting;
	}

	get feedIngredients(): (FeedIngredient & { tableId: string })[] {
		const ingredientsMap =
			this.model.getFeedOverview.data?.FeedIngredientConsumedAndPurchasedVolumes.reduce<
				Record<string, FeedIngredient & { tableId: string }>
			>((acc, volumes) => {
				const tableId = getIngredientTableId(volumes.FeedIngredient);

				if (!acc[tableId]) {
					acc[tableId] = {
						...volumes.FeedIngredient,
						tableId,
					};
				}
				return acc;
			}, {}) ?? [];

		return Object.values(ingredientsMap).sortBy('name');
	}

	@action
	toggleCornTable() {
		this.showCornTable = !this.showCornTable;

		if (checkStorageAvailable('localStorage')) {
			window.localStorage.setItem(`feed-overview-hedgedCornChart.${this.model.scopeId}`, this.showCornTable.toString());
		}
	}

	@action
	toggleSoybeanMealTable() {
		this.showSoybeanMealTable = !this.showSoybeanMealTable;

		if (checkStorageAvailable('localStorage')) {
			window.localStorage.setItem(`feed-overview-hedgedSoybeanMealChart.${this.model.scopeId}`, this.showSoybeanMealTable.toString());
		}
	}

	submitCreateFeedIngredientForm = task({ drop: true }, async () => {
		if (!isFormValid(document)) {
			getInvalidElements(document);
			return;
		}

		let validatedData;
		try {
			validatedData = parseCreateEditFeedIngredientData(this.createFeedIngredientFormData);
			const createFeedIngredientData: FeedIngredientCreateDTO = {
				businessId: this.model.scopeId,
				name: validatedData.name,
				cmePercentageBasis: validatedData.cmePercentageBasis,
				cmeUsdBasis: validatedData.cmeUsdBasis,
				dryMatterPercent: validatedData.dryMatterPercent,
				feedCategoryId: validatedData.feedCategoryId,
				flatPricePerTon: validatedData.flatPricePerTon,
			};

			const response = await (createFeedIngredient(this, { data: createFeedIngredientData }, this.customerRole) as Promise<
				Maybe<{
					createFeedIngredient: Mutation['createFeedIngredient'];
				}>
			>);

			const name = validatedData.name;
			const dmiPercentage = validatedData.dryMatterPercent;
			const feedCategoryId = validatedData.feedCategoryId;

			const createdIngredient = response?.createFeedIngredient?.FeedIngredients.find((ingredient) => {
				return ingredient.name === name && ingredient.dryMatterPercent === dmiPercentage && ingredient.FeedCategory?.id === feedCategoryId;
			});

			if (createdIngredient) {
				this.router.transitionTo(this.ingredientDetailRoute, this.model.scopeId, createdIngredient.feedIngredientId);
			}
		} catch (e) {
			set(this.createFeedIngredientFormData, 'error', e.message);
			return;
		}

		this.closeSidePanel();
	});

	@action
	openSidePanel() {
		this.isSidePanelOpen = true;
	}

	@action
	closeSidePanel() {
		this.isSidePanelOpen = false;
		clearCreateEditFeedIngredientData(this.createFeedIngredientFormData);
	}

	@action
	toggleSidePanel() {
		if (this.isSidePanelOpen) {
			this.closeSidePanel();
		} else {
			this.openSidePanel();
		}
	}

	@action
	updateCreateFeedIngredientFormData(
		key: keyof CreateEditFeedIngredientData,
		value: CreateEditFeedIngredientData[keyof CreateEditFeedIngredientData],
	) {
		if (
			typeof value === 'string' &&
			(key === 'cmeBasisPercentage' ||
				key === 'cmeBasisUsd' ||
				key === 'flatPricePerTon' ||
				key === 'dmiPercentage' ||
				key === 'error' ||
				key === 'ingredientName')
		) {
			set(this.createFeedIngredientFormData, key, value);
			return;
		}

		if (key === 'feedPriceType' && (value === 'Spot Price' || value === 'CME Basis (USD)' || value === 'CME Basis (%)')) {
			set(this.createFeedIngredientFormData, key, value);
			return;
		}

		if (key === 'feedCategory' && (value == null || typeof value === 'object')) {
			set(this.createFeedIngredientFormData, key, value);
			return;
		}

		throw new Error(`Invalid key/value pair: ${key}/${value}`);
	}

	@action
	setTimePeriod(option: UiDateFilterOption) {
		this.startDate = option.startDate;
		this.endDate = option.endDate;
	}

	get tableColumns(): Record<string, TableColumn[]> {
		return this.feedIngredients.reduce<Record<string, TableColumn[]>>((acc, feedIngredient) => {
			const id = feedIngredient.tableId;
			const isCornProduct = parseHedgeProductSlugFromFeedIngredient(feedIngredient) === 'grain-corn';

			const columns: TableColumn[] = [
				{
					id: uuid(`${id}-name`, this.uuidNamespace),
					name: 'Month',
					cellComponent: CellComponents.MonthFormat,
					valuePath: 'month',
					isVisible: true,
					isFixed: '',
					textAlign: 'left',
					isSortable: false,
					width: 100,
				},
				{
					id: uuid(`${id}-usage`, this.uuidNamespace),
					name: isCornProduct ? 'Usage DMI Tons *' : 'Usage DMI Tons',
					cellComponent: CellComponents.IntlNumberFormat,
					valuePath: 'usageDMITons',
					componentArgs: {
						minimumFractionDigits: 0,
						maximumFractionDigits: 2,
					},
					headerTooltip: isCornProduct ? 'Bushels Converted to per Ton Values' : undefined,
					width: 150,
					isSortable: false,
					isVisible: true,
					isFixed: '',
					textAlign: 'right',
				},
				{
					id: uuid(`${id}-Purchased`, this.uuidNamespace),
					name: 'Purchased',
					cellComponent: CellComponents.String,
					isVisible: true,
					isSortable: false,
					isFixed: '',
					textAlign: 'center',
					subcolumns: [
						{
							id: uuid(`${id}-Purchased-Tons`, this.uuidNamespace),
							name: 'Tons',
							valuePath: 'purchasedDMITons',
							cellComponent: CellComponents.IntlNumberFormat,
							componentArgs: {
								minimumFractionDigits: 0,
								maximumFractionDigits: 2,
							},
							width: 100,
							isSortable: false,
							isVisible: true,
							isFixed: '',
							textAlign: 'right',
						},
						{
							id: uuid(`${id}-Purchased-Percent`, this.uuidNamespace),
							name: '%',
							valuePath: 'percentPurchased',
							cellComponent: CellComponents.IntlNumberFormat,
							componentArgs: {
								style: 'percent',
								minimumFractionDigits: 0,
								maximumFractionDigits: 2,
							},
							width: 100,
							isSortable: false,
							isVisible: true,
							isFixed: '',
							textAlign: 'right',
						},
						{
							id: uuid(`${id}-Purchased-price`, this.uuidNamespace),
							name: 'Avg Price',
							valuePath: 'avgPricePurchased',
							cellComponent: CellComponents.IntlNumberFormat,
							width: 150,
							componentArgs: {
								style: 'currency',
								currency: 'USD',
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							},
							isSortable: false,
							isVisible: true,
							isFixed: '',
							textAlign: 'right',
						},
					],
				},
				{
					id: uuid(`${id}-Not-Purchased`, this.uuidNamespace),
					name: 'Not Purchased',
					cellComponent: CellComponents.String,
					isVisible: true,
					isSortable: false,
					isFixed: '',
					textAlign: 'center',
					subcolumns: [
						{
							id: uuid(`${id}-Not-Purchased-Tons`, this.uuidNamespace),
							name: 'Tons',
							valuePath: 'notPurchasedDMITons',
							cellComponent: CellComponents.IntlNumberFormat,
							componentArgs: {
								minimumFractionDigits: 0,
								maximumFractionDigits: 2,
							},
							width: 100,
							isSortable: false,
							isVisible: true,
							isFixed: '',
							textAlign: 'right',
						},
						{
							id: uuid(`${id}-Not-Purchased-Percent`, this.uuidNamespace),
							name: '%',
							valuePath: 'percentNotPurchased',
							cellComponent: CellComponents.IntlNumberFormat,
							componentArgs: {
								style: 'percent',
								minimumFractionDigits: 0,
								maximumFractionDigits: 2,
							},
							width: 100,
							isSortable: false,
							isVisible: true,
							isFixed: '',
							textAlign: 'right',
						},
						{
							id: uuid(`${id}-Not-Purchased-price`, this.uuidNamespace),
							name: 'Market Price',
							valuePath: 'avgPriceNotPurchased',
							cellComponent: CellComponents.IntlNumberFormat,
							componentArgs: {
								style: 'currency',
								currency: 'USD',
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							},
							width: 180,
							isSortable: false,
							isVisible: true,
							isFixed: '',
							textAlign: 'right',
						},
					],
				},
				{
					id: uuid(`${id}-Total-Cost`, this.uuidNamespace),
					name: 'Total',
					valuePath: 'totalCost',
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					},
					width: 100,
					isSortable: false,
					isVisible: true,
					isFixed: '',
					textAlign: 'right',
				},
			];

			acc[id] = columns;

			return acc;
		}, {});
	}

	get soybeanMealPercentHedgedColumns() {
		return this.generatePercentHedgedColumns('soybean-meal', 'Soybean Meal');
	}

	get cornPercentHedgedColumns() {
		return this.generatePercentHedgedColumns('corn', 'Corn');
	}

	get selectedMonths() {
		return intervalFromDateTime(DateTime.fromISO(this.startDate), DateTime.fromISO(this.endDate), { months: 1 }).map((month) =>
			month.startOf('month').toISODate(),
		);
	}

	@cached
	get rowData() {
		const selectedMonths = this.selectedMonths;

		const data =
			this.model.getFeedOverview.data?.FeedIngredientConsumedAndPurchasedVolumes.reduce<Record<string, RowData[]>>((acc, volumes) => {
				const id = getIngredientTableId(volumes.FeedIngredient);

				const month = volumes.monthStartDate;
				const marketPrice = this.getMarketPrice(volumes.FeedIngredient, month);
				const usageDMITons = volumes.forecastedConsumptionInTons ?? 0;
				const purchasedDMITons = volumes.purchasedInTons ?? 0;
				const percentPurchased = usageDMITons ? purchasedDMITons / usageDMITons : null;
				const avgPricePurchased = purchasedDMITons ? (volumes.totalPurchasedCostInUsd ?? 0) / purchasedDMITons : null;
				const notPurchasedDMITons = usageDMITons > purchasedDMITons ? usageDMITons - purchasedDMITons : 0;
				const percentNotPurchased = usageDMITons ? notPurchasedDMITons / usageDMITons : null;
				const avgPriceNotPurchased = marketPrice;
				const notPurchasedInPricingUnits = convertTonsToPricingUnit(
					volumes.FeedIngredient,
					notPurchasedDMITons,
					parseHedgeProductSlugFromFeedIngredient(volumes.FeedIngredient) ?? null,
				);
				const totalCost = (volumes.totalPurchasedCostInUsd ?? 0) + notPurchasedInPricingUnits * (marketPrice ?? 0);

				const dataRow = {
					month,
					usageDMITons,
					purchasedDMITons,
					percentPurchased,
					avgPricePurchased,
					notPurchasedDMITons,
					percentNotPurchased,
					avgPriceNotPurchased,
					totalCost,
				};

				if (!acc[id]) {
					acc[id] = [dataRow];
				} else {
					acc[id].push(dataRow);
				}

				return acc;
			}, {}) ?? {};

		// Fill Empty Months and Sort Rows By Month
		Object.keys(data).forEach((ingredientTableId) => {
			const feedIngredient = this.feedIngredients.find((ingredient) => ingredient.tableId === ingredientTableId) ?? null;
			const existingMonths = data[ingredientTableId].map((row) => row.month);
			const missingMonths = selectedMonths.filter((month) => !existingMonths.includes(month));
			const missingRows = missingMonths.map<RowData>((month) => ({
				month,
				usageDMITons: 0,
				purchasedDMITons: 0,
				percentPurchased: null,
				avgPricePurchased: null,
				notPurchasedDMITons: 0,
				percentNotPurchased: null,
				avgPriceNotPurchased: feedIngredient ? this.getMarketPrice(feedIngredient, month) : null,
				totalCost: 0,
			}));

			data[ingredientTableId] = [...data[ingredientTableId], ...missingRows].sortBy('month');
		});

		return data;
	}

	get footerRowsByIngredientId(): Record<string, [Partial<RowData>]> {
		const rowData = this.rowData;
		const totalRows: Record<string, [Partial<RowData>]> = {};

		Object.entries(rowData).forEach(([ingredientId, rows]) => {
			totalRows[ingredientId] = [
				rows.reduce<Partial<RowData>>(
					(totalRow, currentRow) => {
						if (currentRow.usageDMITons != null) totalRow.usageDMITons = (totalRow.usageDMITons ?? 0) + currentRow.usageDMITons;
						if (currentRow.purchasedDMITons != null)
							totalRow.purchasedDMITons = (totalRow.purchasedDMITons ?? 0) + currentRow.purchasedDMITons;
						if (currentRow.notPurchasedDMITons != null)
							totalRow.notPurchasedDMITons = (totalRow.notPurchasedDMITons ?? 0) + currentRow.notPurchasedDMITons;
						if (currentRow.totalCost != null) totalRow.totalCost = (totalRow.totalCost ?? 0) + currentRow.totalCost;

						return totalRow;
					},
					{
						usageDMITons: null,
						purchasedDMITons: null,
						notPurchasedDMITons: null,
						totalCost: null,
					},
				),
			];
		});

		return totalRows;
	}

	get percentHedgedData() {
		const hedgeMonths = intervalFromDateTime(DateTime.fromISO(this.startDate), DateTime.fromISO(this.endDate), { months: 1 }).reduce<
			Record<string, Record<string, HedgeMonth>>
		>(
			(acc, month) => {
				const date = month.startOf('month').toISODate();

				const nearestCornFuture = this.findNearestFuture('grain-corn', date);
				const nearestSoybeanMealFuture = this.findNearestFuture('grain-soybean-meal', date);

				const cornBarChartSymbol = nearestCornFuture?.barchartSymbol ?? '';
				const soybeanMealBarChartSymbol = nearestSoybeanMealFuture?.barchartSymbol ?? '';

				if (!cornBarChartSymbol || !soybeanMealBarChartSymbol) {
					console.warn(`Barchart symbol not found for corn or soybean meal on ${date}`);
				}

				const cornStandardLotSpecs = nearestCornFuture?.Product?.StandardProductLotSpecification ?? null;
				const soybeanMealStandardLotSpecs = nearestSoybeanMealFuture?.Product?.StandardProductLotSpecification ?? null;
				const cornSymbolGroup = nearestCornFuture?.SymbolGroup ?? null;
				const soybeanMealSymbolGroup = nearestSoybeanMealFuture?.SymbolGroup ?? null;

				acc['grain-corn'][date] = new HedgeMonth(
					getOwner(this),
					date,
					'grain-corn',
					cornStandardLotSpecs,
					cornSymbolGroup,
					cornBarChartSymbol,
				);

				acc['grain-soybean-meal'][date] = new HedgeMonth(
					getOwner(this),
					date,
					'grain-soybean-meal',
					soybeanMealStandardLotSpecs,
					soybeanMealSymbolGroup,
					soybeanMealBarChartSymbol,
				);

				return acc;
			},
			{ 'grain-corn': {}, 'grain-soybean-meal': {} },
		);

		const hedgeValues = new Map<
			HedgeMonth,
			{
				futuresHedgedInUnits: number;
				callsHedgedInUnits: number;
				usageDMITons: number;
				purchasedDMITons: number;
				transactions: CurrentAllocationPosition[];
				volumePurchasedWithFlatPriceInTons?: number;
				volumePurchasedWithFuturesPriceOnlyInTons?: number;
				volumePurchasedWithBasisPriceOnlyInTons?: number;
				volumePurchasedWithoutPriceInTons?: number;
			}
		>(
			Object.values(hedgeMonths)
				.flatMap(Object.values)
				.map((hedgeMonth) => [
					hedgeMonth,
					{
						futuresHedgedInUnits: 0,
						callsHedgedInUnits: 0,
						usageDMITons: 0,
						purchasedDMITons: 0,
						transactions: [],
					},
				]),
		);

		this.model.getFeedOverview.data?.AggregateAllocatedForecastedHedgedAndCappedVolumes.forEach((hedge) => {
			const slug = hedge.Product?.slug;
			const month = hedge.date;

			if (!slug || !month || !hedgeMonths[slug]?.[month]) return;

			const hedgeMonth = hedgeMonths[slug][month];
			const values = hedgeValues.get(hedgeMonth);
			if (!values) return;

			if (hedge.instrumentType === TypeOfInstrument.Future) {
				values.futuresHedgedInUnits += hedge.sum.naturallyShortHedged ?? 0;
			} else if (hedge.instrumentType === TypeOfInstrument.Option && hedge.optionType === TypeOfOption.Call) {
				values.callsHedgedInUnits += hedge.sum.naturallyShortHedged ?? 0;
			}
		});

		this.transactions.forEach((transaction) => {
			const slug = transaction.Product?.slug;
			const month = transaction.effectiveHedgeDate;

			if (!slug || !month || !hedgeMonths[slug]?.[month]) return;

			const hedgeMonth = hedgeMonths[slug][month];
			const values = hedgeValues.get(hedgeMonth);
			if (!values) return;

			values.transactions.push(transaction);
		});

		this.model.getFeedOverview.data?.FeedIngredientConsumedAndPurchasedVolumes.forEach((volume) => {
			const slug = volume.FeedIngredient.FeedCategory?.HedgeProduct?.slug;
			if (slug !== 'grain-corn' && slug !== 'grain-soybean-meal') return;
			const month = volume.monthStartDate;

			if (!hedgeMonths[slug]?.[month]) return;

			const hedgeMonth = hedgeMonths[slug][month];
			const values = hedgeValues.get(hedgeMonth);
			if (!values) return;

			values.usageDMITons += volume.forecastedConsumptionInTons ?? 0;
			values.purchasedDMITons += volume.purchasedInTons ?? 0;

			values.volumePurchasedWithFlatPriceInTons =
				(values.volumePurchasedWithFlatPriceInTons ?? 0) + (volume.volumePurchasedWithFlatPriceInTons ?? 0);
			values.volumePurchasedWithFuturesPriceOnlyInTons =
				(values.volumePurchasedWithFuturesPriceOnlyInTons ?? 0) + (volume.volumePurchasedWithFuturesPriceOnlyInTons ?? 0);
			values.volumePurchasedWithBasisPriceOnlyInTons =
				(values.volumePurchasedWithBasisPriceOnlyInTons ?? 0) + (volume.volumePurchasedWithBasisPriceOnlyInTons ?? 0);
			values.volumePurchasedWithoutPriceInTons =
				(values.volumePurchasedWithoutPriceInTons ?? 0) + (volume.volumePurchasedWithoutPriceInTons ?? 0);
		});

		// Now set all values at once for each HedgeMonth
		hedgeValues.forEach((values, hedgeMonth) => {
			hedgeMonth.setValuesFromObject({
				futuresHedgedInUnits: values.futuresHedgedInUnits,
				callsHedgedInUnits: values.callsHedgedInUnits,
				usageDMITons: values.usageDMITons,
				purchasedDMITons: values.purchasedDMITons,
				CurrentAllocationPositions: values.transactions,
				volumePurchasedWithFlatPriceInTons: values.volumePurchasedWithFlatPriceInTons,
				volumePurchasedWithFuturesPriceOnlyInTons: values.volumePurchasedWithFuturesPriceOnlyInTons,
				volumePurchasedWithBasisPriceOnlyInTons: values.volumePurchasedWithBasisPriceOnlyInTons,
				volumePurchasedWithoutPriceInTons: values.volumePurchasedWithoutPriceInTons,
			});
		});

		return {
			'grain-soybean-meal': Object.values(hedgeMonths['grain-soybean-meal']),
			'grain-corn': Object.values(hedgeMonths['grain-corn']),
		};
	}

	get transactions() {
		// Only include long futures and calls
		// If we need to support naturally short hedges, we will need to update this
		return this.model.getFeedOverview?.data?.CurrentAllocationPositions.filter((transaction) => transaction.contractQuantity > 0) ?? [];
	}

	getMarketPrice(feedIngredient: FeedIngredient, month: string) {
		const feedCategory = feedIngredient.FeedCategory;
		const hedgeProductSlug = feedCategory?.HedgeProduct?.slug;
		const nearestFuture = this.findNearestFuture(hedgeProductSlug ?? '', month);

		const displayFactor = nearestFuture?.SymbolGroup?.displayFactor ?? 1;
		const barchartSymbol = nearestFuture?.barchartSymbol;
		let marketPrice: number | null = null;

		if (barchartSymbol) {
			marketPrice = this.marketData.getLatestPrice(barchartSymbol);
		}

		return getFeedIngredientMarketPrice(feedIngredient, marketPrice, displayFactor, month);
	}

	@action
	formatLastUpdatedAt(date: string | null) {
		if (!date) return null;

		return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_FULL);
	}

	@action
	determineLastUpdatedAt(feedIngredient: FeedIngredient) {
		const isBasisIngredient = feedIngredient.cmePercentageBasis ?? feedIngredient.cmeUsdBasis;

		if (isBasisIngredient) {
			return this.marketData.lastPolledAt?.toISO() ?? null;
		}

		const mostRecentDairyFeedUsageUpdate = feedIngredient.FeedIngredientUsages?.[0];
		const mostRecentSwineFeedUsageUpdate = feedIngredient.LivestockGroupFeedUsages?.[0];
		const mostRecentPhysicalFeedTransaction = feedIngredient.PhysicalFeedTransactions?.[0];
		const ingredientUpdatedAt = feedIngredient.updatedAt;

		// Merge most recent updates and sort from oldest to newest
		const allRecentUsageUpdates = [
			mostRecentDairyFeedUsageUpdate?.updatedAt,
			mostRecentSwineFeedUsageUpdate?.updatedAt,
			mostRecentPhysicalFeedTransaction?.updatedAt,
			ingredientUpdatedAt,
		]
			.filter((date): date is string => date)
			.sort();

		// Return last element (most recent)
		return allRecentUsageUpdates[allRecentUsageUpdates.length - 1];
	}

	generatePercentHedgedColumns(idSuffix: string, product: 'Corn' | 'Soybean Meal'): TableColumn[] {
		return [
			{
				id: uuid(`percent-hedged-month-${idSuffix}`, this.uuidNamespace),
				name: 'Month',
				valuePath: 'date',
				cellComponent: CellComponents.MonthFormat,
				isFixed: '',
				isVisible: true,
				isSortable: false,
				textAlign: 'left',
			},
			{
				id: uuid(`percent-hedged-usage${idSuffix}`, this.uuidNamespace),
				name: 'Usage DMI Tons',
				width: 130,
				valuePath: 'usage',
				headerTooltip: product === 'Corn' ? 'Bushels Converted to per Ton Values' : undefined,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				},
				isFixed: '',
				isVisible: true,
				isSortable: false,
				textAlign: 'left',
			},
			{
				id: uuid(`percent-hedged-futures-hedged-${idSuffix}`, this.uuidNamespace),
				name: 'Futures Hedged',
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				isSortable: false,
				textAlign: 'center',
				subcolumns: [
					{
						id: uuid(`percent-hedged-futures-hedged-percentage-${idSuffix}`, this.uuidNamespace),
						name: '%',
						valuePath: 'percentFuturesHedged',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
							minimumFractionDigits: 0,
							maximumFractionDigits: 2,
						},
						isFixed: '',
						isVisible: true,
						isSortable: false,
						textAlign: 'right',
					},
					{
						id: uuid(`percent-hedged-futures-hedged-tons-${idSuffix}`, this.uuidNamespace),
						name: 'Tons',
						valuePath: 'futuresHedgedInTons',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						},
						isFixed: '',
						isVisible: true,
						isSortable: false,
						textAlign: 'right',
					},
				],
			},
			{
				id: uuid(`percent-hedged-calls-hedged-${idSuffix}`, this.uuidNamespace),
				name: 'Calls Hedged',
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				isSortable: false,
				textAlign: 'center',
				subcolumns: [
					{
						id: uuid(`percent-hedged-calls-hedged-percentage-${idSuffix}`, this.uuidNamespace),
						name: '%',
						valuePath: 'percentCallsHedged',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
							minimumFractionDigits: 0,
							maximumFractionDigits: 2,
						},
						isFixed: '',
						isVisible: true,
						isSortable: false,
						textAlign: 'right',
					},
					{
						id: uuid(`percent-hedged-calls-hedged-tons-${idSuffix}`, this.uuidNamespace),
						name: 'Tons',
						valuePath: 'callsHedgedInTons',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						},
						isFixed: '',
						isVisible: true,
						isSortable: false,
						textAlign: 'right',
					},
				],
			},
			{
				id: uuid(`percent-hedged-physical-purchases-${idSuffix}`, this.uuidNamespace),
				name: 'Flat Contracts',
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				isSortable: false,
				textAlign: 'center',
				subcolumns: [
					{
						id: uuid(`percent-hedged-flat-contracts-percentage-${idSuffix}`, this.uuidNamespace),
						name: '%',
						valuePath: 'percentFlatContracts',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
							minimumFractionDigits: 0,
							maximumFractionDigits: 2,
						},
						isFixed: '',
						isVisible: true,
						isSortable: false,
						textAlign: 'right',
					},
					{
						id: uuid(`percent-hedged-flat-contracts-tons-${idSuffix}`, this.uuidNamespace),
						name: 'Tons',
						valuePath: 'flatContractTons',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						},
						isFixed: '',
						isVisible: true,
						isSortable: false,
						textAlign: 'right',
					},
				],
			},
			{
				id: uuid(`percent-hedged-hta-contracts-${idSuffix}`, this.uuidNamespace),
				name: 'HTA Contracts',
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				isSortable: false,
				textAlign: 'center',
				subcolumns: [
					{
						id: uuid(`percent-hedged-hta-contracts-percentage-${idSuffix}`, this.uuidNamespace),
						name: '%',
						valuePath: 'percentHTAContracts',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
							minimumFractionDigits: 0,
							maximumFractionDigits: 2,
						},
						isFixed: '',
						isVisible: true,
						isSortable: false,
						textAlign: 'right',
					},
					{
						id: uuid(`percent-hedged-hta-contracts-tons-${idSuffix}`, this.uuidNamespace),
						name: 'Tons',
						valuePath: 'htaContractTons',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						},
						isFixed: '',
						isVisible: true,
						isSortable: false,
						textAlign: 'right',
					},
				],
			},
			{
				id: uuid(`percent-hedged-basis-contracts-${idSuffix}`, this.uuidNamespace),
				name: 'Basis Contracts',
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				isSortable: false,
				textAlign: 'center',
				subcolumns: [
					{
						id: uuid(`percent-hedged-basis-contracts-percentage-${idSuffix}`, this.uuidNamespace),
						name: '%',
						valuePath: 'percentBasisContracts',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
							minimumFractionDigits: 0,
							maximumFractionDigits: 2,
						},
						isFixed: '',
						isVisible: true,
						isSortable: false,
						textAlign: 'right',
					},
					{
						id: uuid(`percent-hedged-basis-contracts-tons-${idSuffix}`, this.uuidNamespace),
						name: 'Tons',
						valuePath: 'basisContractTons',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						},
						isFixed: '',
						isVisible: true,
						isSortable: false,
						textAlign: 'right',
					},
				],
			},
		];
	}

	findNearestFuture(productSlug: string, month: string) {
		// Futures are sorted from API, allowing a >= check for finding the nearest future
		// If the date is too far in the future, return null
		const futures = this.model.getFeedOverview.data?.Futures.filter((future) => future.Product.slug === productSlug) ?? [];
		return futures?.find((future) => future.displayExpiresAt >= month) ?? null;
	}
}
