import { cropGroups, getSlugs } from '../crop';
import { groupByCropGroup, type CropGroup } from '../crop';
import type { Crop } from 'vault-client/types/graphql-types';
import { type CropGroupProjectedRevenue } from '../dashboard';
import type { ProductSlug } from 'vault-client/types/vault-client';

type ProjectedRevenueByCropGroup = Partial<Record<CropGroup, CropGroupProjectedRevenue>>;

type getProjectedRevenueByCropGroupHelpers = {
	getBrokeragePnl: (slugs: ProductSlug[]) => number;
	getPhysicalCropSales: (crop: Crop) => number;
	getUnsoldMarkToMarket: (crop: Crop) => number;
	getAdditionalRevenue: (crop: Crop) => number;
};

/**
 * Calculates projected revenue values for an array of crops, organized by crop group
 *
 * @param crops - Array of Crops
 * @param helpers - Helpers for calculating projected revenue values
 * @returns Record of crop groups with their projected revenue values
 */
function getProjectedRevenueByCropGroup(crops: Crop[], helpers: getProjectedRevenueByCropGroupHelpers): ProjectedRevenueByCropGroup {
	const { getBrokeragePnl, getPhysicalCropSales, getUnsoldMarkToMarket, getAdditionalRevenue } = helpers;
	const cropsByCropGroup = groupByCropGroup(crops);
	// Only include crop groups that have crops
	const cropGroupsWithCrops = cropGroups.filter((cropGroup) => cropsByCropGroup[cropGroup].length > 0);

	// Create the result object using reduce for immutability
	return cropGroupsWithCrops.reduce((result, cropGroup) => {
		// Get relevant product slugs for this crop group
		const slugs = getSlugs(cropGroup);

		// Brokerage Pnl is per cropGroup, not per crop
		const brokeragePnl = getBrokeragePnl(slugs) || 0;

		// Get crops for this group or empty array if none
		const cropsInGroup = cropsByCropGroup[cropGroup] || [];

		// Calculate revenue components for all crops in this group
		const revenueComponents = cropsInGroup.reduce(
			(components, crop) => {
				try {
					// Calculate individual revenue components
					const physicalCropSales = getPhysicalCropSales(crop) || 0;
					const unsoldMarkToMarket = getUnsoldMarkToMarket(crop) || 0;
					const additionalRevenue = getAdditionalRevenue(crop) || 0;

					// Return new components object with updated values
					return {
						physicalCropSales: components.physicalCropSales + physicalCropSales,
						unsoldMarkToMarket: components.unsoldMarkToMarket + unsoldMarkToMarket,
						additionalRevenue: components.additionalRevenue + additionalRevenue,
					};
				} catch (error) {
					console.error(`Error calculating revenue for crop ${crop.id}:`, error);
					// Return unchanged components on error
					return components;
				}
			},
			{ physicalCropSales: 0, unsoldMarkToMarket: 0, additionalRevenue: 0 },
		);

		// Calculate total revenue
		const totalRevenue =
			brokeragePnl + revenueComponents.physicalCropSales + revenueComponents.unsoldMarkToMarket + revenueComponents.additionalRevenue;

		// Create the complete revenue object for this crop group
		const cropGroupRevenue: CropGroupProjectedRevenue = {
			brokeragePnl,
			physicalCropSales: revenueComponents.physicalCropSales,
			unsoldMarkToMarket: revenueComponents.unsoldMarkToMarket,
			additionalRevenue: revenueComponents.additionalRevenue,
			totalRevenue,
		};

		// Return new result object with this crop group added
		return {
			...result,
			[cropGroup]: cropGroupRevenue,
		};
	}, {} as ProjectedRevenueByCropGroup);
}

export { getProjectedRevenueByCropGroup, type ProjectedRevenueByCropGroup };
