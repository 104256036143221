import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { TableColumn } from 'vault-client/types/vault-table';
import { DateTime } from 'luxon';
import { DisplayUnits } from 'vault-client/types/cattle/display-units';
import { buildProjectedPnlRows, getProjectedPnlFooterRow } from 'vault-client/utils/cattle/dashboard/dashboard-utils';
import { generatePnlMonths } from 'vault-client/utils/cattle/projected-pl/projected-pl-utils';
import { getCattleDashboardPnlTableColumns } from 'vault-client/utils/components/cattle/dashboard/dashboard-table';
import { ProjectedPnlRow } from 'vault-client/models/cattle/cattle-dashboard';
import { ModelFrom } from 'vault-client/utils/type-utils';
import BusinessesBusinessCattleCattleProjectedPlRoute from 'vault-client/routes/businesses/business/cattle/cattle-projected-pl';
import { DATE_RANGE_OPTIONS, DateRangeOption } from 'vault-client/utils/cattle/date-filter-constants';
import { GetCattleProjectedPlData } from 'vault-client/types/cattle/cattle-projected-pl';

/**
 * Controller for managing cattle projected P&L view
 * Handles date ranges, display units, and data presentation
 */
export default class BusinessesBusinessCattleCattleProjectedPlController extends Controller {
	declare model: ModelFrom<BusinessesBusinessCattleCattleProjectedPlRoute>;

	queryParams = ['startDate', 'endDate'];

	@tracked selectedDisplayUnit = DisplayUnits.Total;
	@tracked startDate = DateTime.local().startOf('month').toISODate();
	@tracked endDate = DateTime.now().plus({ months: 24 }).endOf('month').toISODate();

	productionMonthRangeOptions: readonly DateRangeOption[] = DATE_RANGE_OPTIONS;

	get uiSwitcherOptions() {
		return [DisplayUnits.Total, DisplayUnits.PerHead, DisplayUnits.PerCWT];
	}

	get lastUpdatedAtString() {
		try {
			const lastUpdatedAt = DateTime.fromISO(this.model.lastUpdatedAt);
			return `Last updated: ${lastUpdatedAt.toLocaleString(DateTime.DATE_SHORT)} at ${lastUpdatedAt.toLocaleString(DateTime.TIME_SIMPLE)}`;
		} catch (error) {
			return 'Last updated: Unknown';
		}
	}

	get downloadedFileName() {
		return 'cattle_projected_pl.csv';
	}

	get projectedPnlColumns(): TableColumn[] {
		return getCattleDashboardPnlTableColumns(this.selectedDisplayUnit);
	}

	/**
	 * Processes and returns P&L rows with the selected display unit
	 */
	get projectedPnlRows(): ProjectedPnlRow[] {
		const data = this.model.getCattleProjectedPl.data as GetCattleProjectedPlData;
		const pnlMonths = generatePnlMonths(data, this.startDate, this.endDate);
		return buildProjectedPnlRows(pnlMonths, this.selectedDisplayUnit);
	}

	get projectedPnlFooterRow(): [Omit<ProjectedPnlRow, 'date'>] {
		return getProjectedPnlFooterRow(this.projectedPnlRows, this.selectedDisplayUnit);
	}

	/**
	 * Returns formatted date range object for use in queries and UI
	 */
	get dateRange(): { startDate: string; endDate: string } {
		return {
			startDate: this.startDate,
			endDate: this.endDate,
		};
	}

	@action
	handleDisplayUnitChange(unit: DisplayUnits) {
		this.selectedDisplayUnit = unit;
	}

	@action
	setDateRange(value: { startDate: string; endDate: string }) {
		if (DateTime.fromISO(value.endDate) < DateTime.fromISO(value.startDate)) {
			throw new Error('End date must be after start date');
		}
		this.startDate = value.startDate;
		this.endDate = value.endDate;
	}
}
