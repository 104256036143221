import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @ration}}\n\t<Vault::UiModal @onClose={{@closeModalFn}} as |modal|>\n\t\t<modal.header>\n\t\t\t<h3 class=\"text-lg font-medium text-brand-gray-90\">Remove Ration</h3>\n\t\t</modal.header>\n\n\t\t<modal.body>\n\t\t\t<p class=\"text-sm text-brand-gray-70\">\n\t\t\t\tAre you sure you want to remove the ration \"{{@ration.name}}\"? This action cannot be undone.\n\t\t\t</p>\n\t\t</modal.body>\n\n\t\t<modal.footer>\n\t\t\t<div class=\"flex justify-end space-x-3\">\n\t\t\t\t<Vault::UiButton @style=\"secondary\" {{on \"click\" @closeModalFn}}>Cancel</Vault::UiButton>\n\t\t\t\t<Vault::UiButton @style=\"danger\" {{on \"click\" this.removeRation}}>Remove</Vault::UiButton>\n\t\t\t</div>\n\t\t</modal.footer>\n\t</Vault::UiModal>\n{{/if}} ", {"contents":"{{#if @ration}}\n\t<Vault::UiModal @onClose={{@closeModalFn}} as |modal|>\n\t\t<modal.header>\n\t\t\t<h3 class=\"text-lg font-medium text-brand-gray-90\">Remove Ration</h3>\n\t\t</modal.header>\n\n\t\t<modal.body>\n\t\t\t<p class=\"text-sm text-brand-gray-70\">\n\t\t\t\tAre you sure you want to remove the ration \"{{@ration.name}}\"? This action cannot be undone.\n\t\t\t</p>\n\t\t</modal.body>\n\n\t\t<modal.footer>\n\t\t\t<div class=\"flex justify-end space-x-3\">\n\t\t\t\t<Vault::UiButton @style=\"secondary\" {{on \"click\" @closeModalFn}}>Cancel</Vault::UiButton>\n\t\t\t\t<Vault::UiButton @style=\"danger\" {{on \"click\" this.removeRation}}>Remove</Vault::UiButton>\n\t\t\t</div>\n\t\t</modal.footer>\n\t</Vault::UiModal>\n{{/if}} ","moduleName":"vault-client/components/remove-ration-modal.hbs","parseOptions":{"srcName":"vault-client/components/remove-ration-modal.hbs"}});
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import type CattleMockService from 'vault-client/services/cattle-mock';
import type { Ration } from 'vault-client/services/cattle-mock';

interface RemoveRationModalArgs {
	ration: Ration | null;
	closeModalFn: () => void;
	showError: () => void;
}

export default class RemoveRationModal extends Component<RemoveRationModalArgs> {
	@service declare cattleMock: CattleMockService;

	@action
	removeRation() {
		try {
			if (this.args.ration) {
				this.cattleMock.deleteRation(this.args.ration.id);
				this.args.closeModalFn();
			}
		} catch (error) {
			this.args.showError();
		}
	}
}
