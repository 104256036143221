import Route from '@ember/routing/route';
import dashboardQuery from 'vault-client/graphql/queries/businesses/business/dashboard.graphql';
import insuranceAllocationQuery from 'vault-client/graphql/queries/businesses/business/insurance-allocation-ratios.graphql';
import { queryManager } from 'ember-apollo-client';
import { inject as service } from '@ember/service';

import { DateTime } from 'luxon';
import {
	DerivedDrpEndorsementFilterDTO,
	ForecastedMilkUtilizationFilterDTO,
	TypeOfLedgerCategory,
	FeedIngredientForecastedUsageFilterDTO,
	BusinessEntityRole,
	CustomerEntity,
	Query,
	EntityAllocatedExposureRatioFilterDTO,
	CurrentAllocationPositionFilterDTO,
	AllocatedForecastedHedgedAndCappedVolumeFilterDTO,
	TypeOfAllocatedHedge,
	InsuranceEndorsementAllocationRatio,
} from 'vault-client/types/graphql-types';
import RouterService from '@ember/routing/router-service';
import { ModelFrom } from 'vault-client/utils/type-utils';
import BusinessesBusinessRoute from '../business';
import posthog from 'posthog-js';

export type GetDairyDashboardQuery = {
	Customer: CustomerEntity;
	ClassiiiExposure: Query['EntityAllocatedExposureRatios'];
	ClassivExposure: Query['EntityAllocatedExposureRatios'];
	CheeseExposure: Query['EntityAllocatedExposureRatios'];
	ButterExposure: Query['EntityAllocatedExposureRatios'];
	NonfatExposure: Query['EntityAllocatedExposureRatios'];
	DryWheyExposure: Query['EntityAllocatedExposureRatios'];
	AggregateDerivedDrpEndorsements: Query['AggregateDerivedDrpEndorsements'];
	ForecastedMilkProductionByMonths: Query['ForecastedMilkProductionByMonths'];
	AggregateCurrentAllocationPositions: Query['AggregateCurrentAllocationPositions'];
	AggregateFeedPositions: Query['AggregateCurrentAllocationPositions'];
	AggregateDairyPositions: Query['AggregateCurrentAllocationPositions'];
	ClassIIIFutures: Query['Futures'];
	ClassIVFutures: Query['Futures'];
	ButterFutures: Query['Futures'];
	NonfatDryMilkFutures: Query['Futures'];
	CheeseFutures: Query['Futures'];
	DryWheyFutures: Query['Futures'];
	CornFutures: Query['Futures'];
	SoybeanMealFutures: Query['Futures'];
	AggregateExpenseLedgerEntries: Query['AggregateLedgerEntries'];
	MostCurrentFutures: Query['Products'];
	AggregateRevenueLedgerEntries: Query['AggregateLedgerEntries'];
	AggregateLedgerForecastedEntries: Query['AggregateLedgerForecastedEntries'];
	AggregateDairyBrokerageHedgedValues: Query['AggregateAllocatedForecastedHedgedAndCappedVolumes'];
	AggregateLedgerEntries: Query['AggregateLedgerEntries'];
	InsuranceEndorsementAllocationRatios: Query['InsuranceEndorsementAllocationRatios'];
	FeedIngredientConsumedAndPurchasedVolumes: Query['FeedIngredientConsumedAndPurchasedVolumes'];
};

export type DashboardModel = GetDairyDashboardQuery & { lastUpdatedAt: string };

export type GetInsuranceEndorsementAllocationRatiosQuery = {
	InsuranceEndorsementAllocationRatios: InsuranceEndorsementAllocationRatio[];
};

export type GetInsuranceEndorsementAllocationRatiosQueryArgs = {
	customerId: string;
	startDate: string;
	endDate: string;
};

export type GetDairyDashboardQueryArgs = {
	customerId: string;
	startDate: string;
	endDate: string;
	endorsementWhere?: DerivedDrpEndorsementFilterDTO;
	classiiiExposureWhere?: EntityAllocatedExposureRatioFilterDTO;
	classivExposureWhere?: EntityAllocatedExposureRatioFilterDTO;
	cheeseExposureWhere?: EntityAllocatedExposureRatioFilterDTO;
	butterExposureWhere?: EntityAllocatedExposureRatioFilterDTO;
	nonfatExposureWhere?: EntityAllocatedExposureRatioFilterDTO;
	dryWheyExposureWhere?: EntityAllocatedExposureRatioFilterDTO;
	positionWhere?: CurrentAllocationPositionFilterDTO;
	dairyPositionWhere?: CurrentAllocationPositionFilterDTO;
	feedPositionWhere?: CurrentAllocationPositionFilterDTO;
	milkUtilizationsWhere?: ForecastedMilkUtilizationFilterDTO;
	aggregateDairyBrokerageHedgedValuesWhere?: AllocatedForecastedHedgedAndCappedVolumeFilterDTO;
	aggregateFeedIngredientUsageWhere?: FeedIngredientForecastedUsageFilterDTO;
	typeOfLedgerCategory: TypeOfLedgerCategory;
	futuresStartDate?: string;
	futuresEndDate?: string;
	scopeId?: string;
};

export default class BusinessesBusinessDashboardRoute extends Route {
	@service declare router: RouterService;

	@queryManager apollo: any;

	queryParams = {
		startDate: { refreshModel: true },
		endDate: { refreshModel: true },
	};

	async beforeModel() {
		const businessRoles =
			(this.modelFor('businesses.business') as ModelFrom<BusinessesBusinessRoute> | undefined)?.getCustomer.data?.Customer?.businessRoles ??
			[];
		const hasHogProducerBusinessRole = businessRoles.includes(BusinessEntityRole.HogProducer);
		const hasGrainProducerBusinessRole = businessRoles.includes(BusinessEntityRole.GrainProducer);
		const hasFeederCattleProducerBusinessRole = businessRoles.includes(BusinessEntityRole.FeederCattleProducer);
		const hasLiveCattleProducerBusinessRole = businessRoles.includes(BusinessEntityRole.LiveCattleProducer);

		if (hasHogProducerBusinessRole) {
			this.router.transitionTo('businesses.business.pig-dashboard');
		} else if (hasFeederCattleProducerBusinessRole || hasLiveCattleProducerBusinessRole) {
			this.router.transitionTo('businesses.business.cattle.cattle-dashboard');
		} else if (hasGrainProducerBusinessRole) {
			posthog.isFeatureEnabled('Grain_Feature') ? this.router.transitionTo('businesses.business.crops-dashboard') : '';
		}
	}

	async model(params: { startDate: string; endDate: string }): Promise<DashboardModel | undefined> {
		const startDate = params.startDate;
		const endDate = params.endDate;
		const futuresStartDate = DateTime.fromISO(startDate).minus({ month: 1 }).toISODate(); // Include advanced futures for milk price calculations
		const businessParams = this.paramsFor('businesses.business') as { business_id: string };
		const customerId = businessParams.business_id;

		const endorsementWhere: DerivedDrpEndorsementFilterDTO = {
			quarterEndDate: {
				gte: startDate,
				lte: endDate,
			},
		};

		const classiiiExposureWhere: EntityAllocatedExposureRatioFilterDTO = {
			startDate: startDate,
			endDate: endDate,
			entityId: { equals: customerId },
			Product: {
				slug: { equals: 'us-dairy-class-iii' },
			},
		};

		const classivExposureWhere: EntityAllocatedExposureRatioFilterDTO = {
			startDate: startDate,
			endDate: endDate,
			entityId: { equals: customerId },
			Product: {
				slug: { equals: 'us-dairy-class-iv' },
			},
		};

		const cheeseExposureWhere: EntityAllocatedExposureRatioFilterDTO = {
			startDate: startDate,
			endDate: endDate,
			entityId: { equals: customerId },
			Product: {
				slug: { equals: 'us-dairy-cheese' },
			},
		};

		const butterExposureWhere: EntityAllocatedExposureRatioFilterDTO = {
			startDate: startDate,
			endDate: endDate,
			entityId: { equals: customerId },
			Product: {
				slug: { equals: 'us-dairy-butter' },
			},
		};

		const dryWheyExposureWhere: EntityAllocatedExposureRatioFilterDTO = {
			startDate: startDate,
			endDate: endDate,
			entityId: { equals: customerId },
			Product: {
				slug: { equals: 'us-dairy-dry-whey' },
			},
		};

		const nonfatExposureWhere: EntityAllocatedExposureRatioFilterDTO = {
			startDate: startDate,
			endDate: endDate,
			entityId: { equals: customerId },
			Product: {
				slug: { equals: 'us-dairy-nonfat-milk' },
			},
		};

		const positionWhere: CurrentAllocationPositionFilterDTO = {
			effectiveHedgeDate: {
				gte: startDate,
				lte: endDate,
			},
		};

		const dairyPositionWhere: CurrentAllocationPositionFilterDTO = Object.assign({}, positionWhere);
		dairyPositionWhere.Product = {
			slug: {
				in: ['us-dairy-nonfat-milk', 'us-dairy-dry-whey', 'us-dairy-butter', 'us-dairy-cheese', 'us-dairy-class-iii', 'us-dairy-class-iv'],
			},
		};

		const feedPositionWhere: CurrentAllocationPositionFilterDTO = Object.assign({}, positionWhere);
		feedPositionWhere.Product = {
			slug: {
				in: ['grain-corn', 'grain-soybean-meal'],
			},
		};

		const aggregateDairyBrokerageHedgedValuesWhere: AllocatedForecastedHedgedAndCappedVolumeFilterDTO = {
			entityId: {
				equals: customerId,
			},
			hedgeType: {
				equals: TypeOfAllocatedHedge.Brokerage,
			},
			Product: {
				slug: {
					in: ['us-dairy-class-iii', 'us-dairy-class-iv'],
				},
			},
			date: {
				gte: startDate,
				lte: endDate,
			},
		};

		const dashboardVariables: GetDairyDashboardQueryArgs = {
			startDate: startDate,
			endDate: endDate,
			customerId,
			endorsementWhere,
			classiiiExposureWhere,
			classivExposureWhere,
			cheeseExposureWhere,
			butterExposureWhere,
			dryWheyExposureWhere,
			nonfatExposureWhere,
			positionWhere,
			feedPositionWhere,
			dairyPositionWhere,
			futuresStartDate: futuresStartDate,
			futuresEndDate: endDate,
			typeOfLedgerCategory: TypeOfLedgerCategory.MilkCheck,
			aggregateDairyBrokerageHedgedValuesWhere,
			scopeId: customerId,
		};

		const allocationRatiosVariables = {
			startDate,
			endDate,
			scopeId: customerId,
		};

		const fetchData = async (): Promise<DashboardModel | undefined> => {
			try {
				const [dashboardRes, insuranceAllocationRes] = await Promise.all([
					this.apollo.watchQuery({
						query: dashboardQuery,
						variables: dashboardVariables,
					}),
					this.apollo.watchQuery({
						query: insuranceAllocationQuery,
						variables: allocationRatiosVariables,
						fetchPolicy: 'no-cache',
					}),
				]);

				const combinedRes = {
					...dashboardRes,
					InsuranceEndorsementAllocationRatios: insuranceAllocationRes.InsuranceEndorsementAllocationRatios,
				};

				if (combinedRes.Customer && combinedRes.Customer.businessRoles.includes('DairyProducer')) {
					return { ...(combinedRes as GetDairyDashboardQuery), lastUpdatedAt: DateTime.now().toISO() };
				} else {
					this.router.transitionTo('businesses.business.business-settings', customerId);
					return undefined;
				}
			} catch (error) {
				console.error('Error fetching data:', error);
				throw error;
			}
		};

		return fetchData();
	}
}
