import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { TrackedObject } from 'tracked-built-ins';
import { ModelFrom } from 'vault-client/utils/type-utils';
import BusinessesBusinessFieldRoute from 'vault-client/routes/businesses/business/field';
import { task } from 'ember-concurrency';
import { getInvalidElements, isFormValid } from 'vault-client/utils/form-validation';
import {
	updateField,
	parseFieldData,
	UpdateFieldData,
	setManyFieldLedgerEntriesPerHarvestYear,
	deleteManyFieldLedgerEntriesPerHarvestYear,
} from 'vault-client/utils/grain-utils';
import { CellComponents } from 'vault-client/types/vault-table';
import { guidFor } from '@ember/object/internals';
import {
	TypeOfCropFieldLedger,
	FieldLedgerEntryPerHarvestYear,
	CropFieldLedgerCategory,
	Mutation_setManyFieldLedgerEntriesPerHarvestYearArgs,
	FieldLedgerEntryPerHarvestYearCreateDTO,
	Mutation_deleteManyFieldLedgerEntriesPerHarvestYearArgs,
} from 'vault-client/types/graphql-types';
import { UiDateFilterOption } from 'vault-client/components/vault/ui-date-filter';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { DateTime } from 'luxon';
import { debounce } from '@ember/runloop';
interface CalculatedValues {
	staticItems?: StaticItem[] | undefined;
	fieldRevExpNote?: string;
	perAcreValueInUsd?: number;

	total: number;
	inputs?: { id: string; name: string; value: number }[];
}

interface ChildEntry {
	id: string;
	entryId?: string;
	calculatedValues: CalculatedValues;
	fieldRevExpNote?: string;
	fieldRevExpCategoryId?: string;
	fieldRevExpCategory?: string;
	perAcreValueInUsd?: number;
	isEditable?: boolean;
	children?: ChildEntry[] | undefined;
	displayRow?: boolean;
	categoryType: TypeOfCropFieldLedger;
	name?: string;
}

interface LedgerType {
	fieldRevExpCategory: string;
	calculatedValues: CalculatedValues;
	children: ChildEntry[] | undefined;
	perAcreValueInUsd: number;
	isEditable?: boolean;
}

interface LedgerTableRow {
	id: string;
	fieldRevExpCategory: string;
	calculatedValues: CalculatedValues;
	children?: ChildEntry[] | undefined;
	displayRow?: boolean;
	categoryType: TypeOfCropFieldLedger;
	fieldRevExpNote?: string;
	perAcreValueInUsd?: number;
	isEditable?: boolean;
	doNotDisplayButton?: boolean;
}

interface StaticItem {
	name: string;
	value: number;
}

export interface NewCropFieldLedgerCategory {
	createCropFieldLedgerCategory?: {
		id: string;
		name: string;
	};

	id?: string;
	type?: TypeOfCropFieldLedger;
	name?: string;
}

export default class BusinessesBusinessFieldController extends Controller {
	declare model: ModelFrom<BusinessesBusinessFieldRoute>;
	@service declare router: RouterService;

	@tracked isSidePanelOpen = false;
	@tracked _field: { id: string; name: string; acres: number } | null = null;
	@tracked updateFieldFormData: UpdateFieldData = new TrackedObject({
		name: this.field?.name || '',
		acres: this.field?.acres || '',
		error: '',
	}) as UpdateFieldData;
	@tracked showAddCategoryModal = false;
	@tracked showRevenueEditRow = false;
	@tracked showExpenseEditRow = false;
	@tracked showEditableTable = false;
	@tracked revenueCategory: CropFieldLedgerCategory | null = null;
	@tracked expenseCategory: CropFieldLedgerCategory | null = null;
	@tracked editableRows: LedgerTableRow[] = [];
	@tracked categoryTypeToDisplay: TypeOfCropFieldLedger | null = null;
	@tracked fieldToRemove: { id: string; name: string; acres: number } | null = null;
	@tracked newCategoryRow: LedgerTableRow | null = null;

	@tracked startDate: string = DateTime.now().startOf('year').toISODate();
	@tracked endDate: string = DateTime.now().endOf('year').toISODate();

	entryIdsToDelete: (string | undefined)[] = [];

	queryParams = ['startDate', 'endDate'];

	isRevenueCategory = (category: CropFieldLedgerCategory) => category.type === TypeOfCropFieldLedger.Revenue;
	isExpenseCategory = (category: CropFieldLedgerCategory) => category.type === TypeOfCropFieldLedger.Expense;
	isUnusedCategory = (category: CropFieldLedgerCategory) =>
		this.editableRows.every((row) => (row.children ?? []).every((child) => child.fieldRevExpCategoryId !== category.id));

	harvestYearOptions = [
		{
			displayName: 'Harvest Year '.concat(DateTime.now().startOf('year').minus({ year: 2 }).get('year').toString()),
			startDate: DateTime.now().startOf('year').minus({ year: 2 }).toISODate(),
			endDate: DateTime.now().endOf('year').minus({ year: 2 }).toISODate(),
		},
		{
			displayName: 'Harvest Year '.concat(DateTime.now().startOf('year').minus({ year: 1 }).get('year').toString()),
			startDate: DateTime.now().startOf('year').minus({ year: 1 }).toISODate(),
			endDate: DateTime.now().endOf('year').minus({ year: 1 }).toISODate(),
		},
		{
			displayName: 'Current Harvest Year',
			startDate: DateTime.now().startOf('year').toISODate(),
			endDate: DateTime.now().endOf('year').toISODate(),
		},
		{
			displayName: 'Harvest Year '.concat(DateTime.now().startOf('year').plus({ year: 1 }).get('year').toString()),
			startDate: DateTime.now().startOf('year').plus({ year: 1 }).toISODate(),
			endDate: DateTime.now().endOf('year').plus({ year: 1 }).toISODate(),
		},
		{
			displayName: 'Harvest Year '.concat(DateTime.now().startOf('year').plus({ year: 2 }).get('year').toString()),
			startDate: DateTime.now().startOf('year').plus({ year: 2 }).toISODate(),
			endDate: DateTime.now().endOf('year').plus({ year: 2 }).toISODate(),
		},
		{
			displayName: 'Harvest Year '.concat(DateTime.now().startOf('year').plus({ year: 3 }).get('year').toString()),
			startDate: DateTime.now().startOf('year').plus({ year: 3 }).toISODate(),
			endDate: DateTime.now().endOf('year').plus({ year: 3 }).toISODate(),
		},
	];

	// eslint-disable-next-line ember/classic-decorator-hooks
	init() {
		super.init();
		this.initializeRows();
	}

	get displayedRows() {
		if (!this.editableRows) return;
		const rowsClone = structuredClone(this.editableRows);
		return rowsClone.filter((row: LedgerTableRow) => row.displayRow === true);
	}

	get currentTimePeriodOption(): UiDateFilterOption {
		return {
			startDate: this.startDate,
			endDate: this.endDate,
		};
	}

	submitUpdateFieldForm = task({ drop: true }, async () => {
		if (!isFormValid(document)) {
			getInvalidElements(document);
			return;
		}
		let validatedData;
		try {
			validatedData = parseFieldData(this.updateFieldFormData);
		} catch (error) {
			set(this.updateFieldFormData, 'error', error.message);
			return;
		}
		if (!this.field?.id) {
			set(this.updateFieldFormData, 'error', 'Missing field id');
			return;
		}
		await updateField(this, { data: validatedData, id: this.field.id });
		this.resetState();
	});

	get ledgerCategories() {
		// Include categories that are used by existing FieldLedgerEntriesPerHarvestYear, even if they are not returned by CropFieldLedgerCategories.
		// Filter using set to ensure no duplicates
		const set = new Set();
		return [
			...(this.model.ledgerCategories?.data?.CropFieldLedgerCategories ?? []),
			...(this.model.field.data?.Field.FieldLedgerEntriesPerHarvestYear.map((entry) => entry.CropFieldLedgerCategory) ?? []),
		].filter((category) => !set.has(category.id) && set.add(category.id));
	}

	get fieldLevelLedgerColumns() {
		const baseColumns = [
			{
				id: 'ae563372-f5e9-4196-bbb0-c35ea380a748',
				name: 'Category',
				valuePath: 'fieldRevExpCategory',
				cellComponent: CellComponents.String,
				minWidth: 250,
				textAlign: 'left',
				isFixed: '',
				isVisible: true,
				isSortable: false,
			},
			{
				id: '1d8ada77-76f4-426e-9d3c-24a4b7703019',
				names: [
					{ text: 'Flat', special: false },
					{ text: 'Per Acre', special: true, type: 'plus' },
					{ text: 'Total', special: false },
				],
				valuePath: 'calculatedValues',
				cellComponent: CellComponents.CalculationCell,
				componentArgs: {
					cellWidth: 120,
					gridColumns: 3,
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					transform: this.reverseExpenseValueSign,
					emptyValue: 0,
				},
				minWidth: 500,
				textAlign: 'right',
				isFixed: '',
				isVisible: true,
				isSortable: false,
			},
			{
				id: '2f1fc222-8cf3-48da-8416-1723e4cbfa9c',
				name: 'Notes',
				valuePath: 'fieldRevExpNote',
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isFixed: '',
				isVisible: true,
				isSortable: false,
			},
		];
		return baseColumns;
	}

	get editableColumns() {
		const baseColumns = [
			{
				id: '27d3c571-b9d9-4e0e-87ef-576231ce59ca',
				name: 'Category',
				valuePath: 'fieldRevExpCategory',
				cellComponent: CellComponents.DropDown,
				componentArgs: {
					dropDownOptions: {
						placeholder: 'Select Category',
						revenueOptions: this.ledgerCategories
							?.filter(this.isRevenueCategory)
							.map((category) => ({ ...category, disabled: !this.isUnusedCategory(category) })),
						expenseOptions: this.ledgerCategories
							?.filter(this.isExpenseCategory)
							.map((category) => ({ ...category, disabled: !this.isUnusedCategory(category) })),
					},
					buttonText: 'Add Category',
					openModal: this.openDropDownModal,
					selectedValue: this.setSelectedCategory,
				},
				minWidth: 300,
				textAlign: 'left',
				isFixed: '',
				isVisible: true,
				isSortable: false,
			},
			{
				id: '75c255de-1771-456f-a1e0-3d6182b2f83f',
				names: [
					{ text: 'Flat', special: false },
					{ text: 'Per Acre', special: true, type: 'plus' },
					{ text: 'Total', special: false },
				],
				valuePath: 'calculatedValues',
				cellComponent: CellComponents.CalculationCellInputs,
				componentArgs: {
					cellWidth: 120,
					gridColumns: 3,
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					transform: this.reverseExpenseValueSign,
					emptyValue: 0,
					handleInputChange: this.debouncedHandleInputChange,
				},
				minWidth: 500,
				textAlign: 'right',
				isFixed: '',
				isVisible: true,
				isSortable: false,
			},
			{
				id: '2f1fc222-8cf3-48da-8416-1723e4cbfa9c',
				name: 'Notes',
				valuePath: 'fieldRevExpNote',
				cellComponent: CellComponents.Input,
				componentArgs: {
					type: 'string',
					onInput: (entry: ChildEntry, e: Event) => {
						const currentEntry = this.editableRows.flatMap((row) => row.children ?? []).findBy('id', entry.id);
						const newDescription = ((e.target as HTMLInputElement)?.value ?? '') as string;
						if (currentEntry) {
							set(currentEntry, 'fieldRevExpNote', newDescription);
						}
					},
				},
				textAlign: 'left',
				isFixed: '',
				isVisible: true,
				isSortable: false,
			},
			{
				id: '5ed7eb40-257f-435e-a7bc-66ad8e55d02a',
				name: '',
				minWidth: 80,
				maxWidth: 80,
				width: 80,
				cellComponent: CellComponents.Button,
				componentArgs: {
					style: 'plain',
					iconOnlyButton: true,
					fn: this.removeLedgerEntry,
					centerIconHref: '/icons/Delete-Outline.svg#delete-outline',
					centerIconClass: 'icon-interactive-stroke',
				},
				isSortable: false,
				isVisible: true,
			},
		];
		return baseColumns;
	}

	@action
	initializeRows() {
		if (
			!this.model ||
			!this.model.field ||
			!this.model.field.data ||
			!this.model.field.data.Field ||
			(!this.model.field.data?.Field.FieldLedgerEntriesPerHarvestYear && !this.showRevenueEditRow && !this.showExpenseEditRow)
		) {
			this.editableRows = this.editableRows.length === 0 ? this.editableRows : [];
			return;
		}

		const LedgerEntries = this.model.field.data.Field.FieldLedgerEntriesPerHarvestYear;
		const revenueLedgers = LedgerEntries
			? LedgerEntries?.filter((entry) => entry.cropFieldLedgerType === TypeOfCropFieldLedger.Revenue)
			: null;

		const expenseLedgers = LedgerEntries
			? LedgerEntries?.filter((entry) => entry.cropFieldLedgerType === TypeOfCropFieldLedger.Expense)
			: null;

		if (this.showRevenueEditRow) {
			let revenueRows = [];
			if (revenueLedgers) {
				const totalFlatRevenue = revenueLedgers.reduce((sum, entry) => sum + entry.flatValueInUsd, 0);
				const totalPerAcreRevenue = revenueLedgers.reduce((sum, entry) => sum + entry.perAcreValueInUsd, 0);
				const totalCategoryRevenue = totalFlatRevenue + totalPerAcreRevenue * this.getFieldAcres;

				revenueRows = [
					{
						id: guidFor(`field-revenue-row`),
						fieldRevExpCategory: 'Revenues',
						isEditable: false,
						doNotDisplayButton: true,
						categoryType: TypeOfCropFieldLedger.Revenue,
						displayRow: this.showRevenueEditRow,
						fieldRevExpNote: '',
						calculatedValues: {
							inputs: [
								{ id: guidFor('flat-revenue'), name: 'Flat', value: totalFlatRevenue },
								{ id: guidFor('per-acre-revenue'), name: 'Per Acre', value: totalPerAcreRevenue },
							],
							total: totalCategoryRevenue,
						},
						children: revenueLedgers?.map((entry) => ({
							id: guidFor(`revenue-${entry.id}`),
							entryId: entry.id,
							categoryType: TypeOfCropFieldLedger.Revenue,
							showDropDown: false,
							fieldRevExpCategoryId: entry.CropFieldLedgerCategory.id,
							fieldRevExpCategory: entry.CropFieldLedgerCategory.name,
							isEditable: true,
							fieldRevExpNote: entry.description ?? '',
							calculatedValues: {
								inputs: [
									{
										id: guidFor(`flat-${entry.id}`),
										name: 'Flat',
										value: entry.flatValueInUsd,
									},
									{
										id: guidFor(`per-acre-${entry.id}`),
										name: 'Per Acre',
										value: entry.perAcreValueInUsd,
									},
								],
								total: this.calculateTotal([entry.flatValueInUsd, entry.perAcreValueInUsd]),
							},
						})),
					},
				];
			} else {
				revenueRows = [
					{
						id: guidFor(`field-revenue-row`),
						fieldRevExpCategory: 'New Revenue',
						doNotDisplayButton: true,
						isEditable: false,
						categoryType: TypeOfCropFieldLedger.Revenue,
						displayRow: this.showRevenueEditRow,
						fieldRevExpNote: '',
						calculatedValues: {
							inputs: [
								{ id: guidFor('flat-revenue'), name: 'Flat', value: 0 },
								{ id: guidFor('per-acre-revenue'), name: 'Per Acre', value: 0 },
							],
							total: 0,
						},
					},
				];
			}
			this.editableRows = [...this.editableRows.filter((row) => row.categoryType !== TypeOfCropFieldLedger.Revenue), ...revenueRows];
		}

		if (this.showExpenseEditRow) {
			let expenseRows = [];
			if (expenseLedgers) {
				const totalFlatExpense = expenseLedgers.reduce((sum, entry) => sum + entry.flatValueInUsd, 0);
				const totalPerAcreExpense = expenseLedgers.reduce((sum, entry) => sum + entry.perAcreValueInUsd, 0);
				const totalCategoryExpense = totalFlatExpense + totalPerAcreExpense * this.getFieldAcres;

				expenseRows = [
					{
						id: guidFor(`field-expense-row`),
						fieldRevExpCategory: 'Expenses',
						doNotDisplayButton: true,
						isEditable: false,
						categoryType: TypeOfCropFieldLedger.Expense,
						displayRow: this.showExpenseEditRow,
						fieldRevExpNote: '',
						calculatedValues: {
							inputs: [
								{ id: guidFor('flat-expense'), name: 'Flat', value: totalFlatExpense },
								{ id: guidFor('per-acre-expense'), name: 'Per Acre', value: totalPerAcreExpense },
							],
							total: totalCategoryExpense,
						},
						children: expenseLedgers?.map((entry) => ({
							id: guidFor(`expense-${entry.id}`),
							entryId: entry.id,
							categoryType: TypeOfCropFieldLedger.Expense,
							showDropDown: false,
							fieldRevExpCategoryId: entry.CropFieldLedgerCategory.id,
							fieldRevExpCategory: entry.CropFieldLedgerCategory.name,
							isEditable: true,
							fieldRevExpNote: entry.description ?? '',
							calculatedValues: {
								inputs: [
									{
										id: guidFor(`flat-${entry.id}`),
										name: 'Flat',
										value: entry.flatValueInUsd,
									},
									{
										id: guidFor(`per-acre-${entry.id}`),
										name: 'Per Acre',
										value: entry.perAcreValueInUsd,
									},
								],
								total: this.calculateTotal([entry.flatValueInUsd, entry.perAcreValueInUsd]),
							},
						})),
					},
				];
			} else {
				expenseRows = [
					{
						id: guidFor(`field-expense-row`),
						fieldRevExpCategory: 'Expenses',
						doNotDisplayButton: true,
						isEditable: false,
						categoryType: TypeOfCropFieldLedger.Expense,
						displayRow: this.showExpenseEditRow,
						fieldRevExpNote: '',
						calculatedValues: {
							inputs: [
								{ id: guidFor('flat-expense'), name: 'Flat', value: 0 },
								{ id: guidFor('per-acre-expense'), name: 'Per Acre', value: 0 },
							],
							total: 0,
						},
					},
				];
			}
			this.editableRows = [...this.editableRows.filter((row) => row.categoryType !== TypeOfCropFieldLedger.Expense), ...expenseRows];
		}
	}

	@action
	updateInputValue(rowId: string, inputId: string, newValue: number) {
		const updatedRows = JSON.parse(JSON.stringify(this.editableRows)); // Deep copy
		const revenueChildren = updatedRows.filter((row: LedgerTableRow) => row.categoryType === TypeOfCropFieldLedger.Revenue)[0]?.children;
		const expenseChildren = updatedRows.filter((row: LedgerTableRow) => row.categoryType === TypeOfCropFieldLedger.Expense)[0]?.children;

		let revenueFlatTotal = 0;
		let revenuePerAcreTotal = 0;
		let expenseFlatTotal = 0;
		let expensePerAcreTotal = 0;
		if (revenueChildren) {
			revenueChildren.forEach((child: LedgerTableRow) => {
				const flatItem = child.calculatedValues.inputs?.find((input) => input.name === 'Flat');
				flatItem ? (revenueFlatTotal += flatItem.value) : 0;

				const perAcreItem = child.calculatedValues.inputs?.find((input) => input.name === 'Per Acre');
				perAcreItem ? (revenuePerAcreTotal += perAcreItem.value) : 0;
			});
			const revenueTotalsTotal = revenueFlatTotal + revenuePerAcreTotal * this.getFieldAcres;

			updatedRows.forEach((row: LedgerTableRow) => {
				if (row.categoryType === TypeOfCropFieldLedger.Revenue) {
					row.calculatedValues.inputs = [
						{ id: guidFor('flat-revenue'), name: 'Flat', value: revenueFlatTotal },
						{ id: guidFor('per-acre-revenue'), name: 'Per Acre', value: revenuePerAcreTotal },
					];
					row.calculatedValues.total = revenueTotalsTotal;
				}
			});
		}

		if (expenseChildren) {
			expenseChildren.forEach((child: LedgerTableRow) => {
				const flatItem = child.calculatedValues.inputs?.find((input) => input.name === 'Flat');
				flatItem ? (expenseFlatTotal += flatItem.value) : 0;

				const perAcreItem = child.calculatedValues.inputs?.find((input) => input.name === 'Per Acre');
				perAcreItem ? (expensePerAcreTotal += perAcreItem.value) : 0;
			});
			const expenseTotalsTotal = expenseFlatTotal + expensePerAcreTotal * this.getFieldAcres;

			updatedRows.forEach((row: LedgerTableRow) => {
				if (row.categoryType === TypeOfCropFieldLedger.Expense) {
					row.calculatedValues.inputs = [
						{ id: guidFor('flat-expense'), name: 'Flat', value: expenseFlatTotal },
						{ id: guidFor('per-acre-expense'), name: 'Per Acre', value: expensePerAcreTotal },
					];
					row.calculatedValues.total = expenseTotalsTotal;
				}
			});
		}

		// Helper function to update row values
		const updateRow = (row: LedgerTableRow) => {
			const input = row.calculatedValues?.inputs?.find((input) => input.id === inputId);

			if (input && row.calculatedValues.inputs) {
				input.value = newValue;
				// Recalculate total
				row.calculatedValues.total = this.calculateTotal(
					row.calculatedValues.inputs.map((input) => {
						return +input.value;
					}),
				);
				return true;
			}
			return false;
		};

		// Find and update the row or nested child row
		const updateRowOrChild = (rows: LedgerTableRow[]) => {
			let parentRowUpdated = false;
			// const isExpense = rows.some((row) => row.categoryType === TypeOfCropFieldLedger.Expense);

			// Helper function to update child rows
			const updateChildRows: any = (children: LedgerTableRow[], parentCategoryType: TypeOfCropFieldLedger) => {
				return children.some((child) => {
					if (child.id === rowId) {
						child.categoryType = parentCategoryType;
						updateRow(child);
						return true;
					}
					if (child.children) {
						return updateChildRows(child.children, parentCategoryType);
					}
					return false;
				});
			};

			for (const row of rows) {
				if (row.id === rowId) {
					updateRow(row);
					parentRowUpdated = true;
					break;
				}
				if (row.children) {
					const childFound = updateChildRows(row.children, row.categoryType);
					if (childFound) {
						parentRowUpdated = true;
						break;
					}
				}
			}

			return parentRowUpdated;
		};

		updateRowOrChild(updatedRows);

		// Recalculate parent row values after updating children
		updatedRows.forEach((row: LedgerTableRow) => {
			if (row.categoryType === TypeOfCropFieldLedger.Revenue) {
				let flatTotal = 0;
				let perAcreTotal = 0;
				if (row.children) {
					row.children.forEach((child) => {
						const flatItem = child.calculatedValues.inputs?.find((input) => input.name === 'Flat');
						if (flatItem) flatTotal += flatItem.value;

						const perAcreItem = child.calculatedValues.inputs?.find((input) => input.name === 'Per Acre');
						if (perAcreItem) perAcreTotal += perAcreItem.value;
					});
				}
				row.calculatedValues.inputs = [
					{ id: guidFor('flat-expense'), name: 'Flat', value: flatTotal },
					{ id: guidFor('per-acre-expense'), name: 'Per Acre', value: perAcreTotal },
				];
				row.calculatedValues.total = flatTotal + perAcreTotal * this.getFieldAcres;
			}
			if (row.categoryType === TypeOfCropFieldLedger.Expense) {
				let flatTotal = 0;
				let perAcreTotal = 0;
				if (row.children) {
					row.children.forEach((child) => {
						const flatItem = child.calculatedValues.inputs?.find((input) => input.name === 'Flat');
						if (flatItem) flatTotal += flatItem.value;

						const perAcreItem = child.calculatedValues.inputs?.find((input) => input.name === 'Per Acre');
						if (perAcreItem) perAcreTotal += perAcreItem.value;
					});
				}
				row.calculatedValues.inputs = [
					{ id: guidFor('flat-expense'), name: 'Flat', value: flatTotal },
					{ id: guidFor('per-acre-expense'), name: 'Per Acre', value: perAcreTotal },
				];
				row.calculatedValues.total = flatTotal + perAcreTotal * this.getFieldAcres;
			}
		});

		this.editableRows = updatedRows;
	}

	get fieldLevelLedgerRows() {
		const fieldLedgerEntries = this.model.field?.data?.Field?.FieldLedgerEntriesPerHarvestYear;
		return fieldLedgerEntries?.length ? this.itemsFn(fieldLedgerEntries) : [];
	}

	get field() {
		return this.model.field.data?.Field || this._field;
	}

	get isSubmitting(): boolean {
		return this.submitUpdateFieldForm.isRunning;
	}

	get disableSubmitButton(): boolean {
		return this.isSubmitting;
	}

	get getFieldAcres() {
		return this.field?.acres || 0;
	}

	get defaultTableNetPlRow() {
		return [this.calculateNetPl(this.fieldLevelLedgerRows, 'staticItems')];
	}

	get editableTableNetPlRow() {
		const editableRows = JSON.parse(JSON.stringify(this.editableRows));
		return [this.calculateNetPl(editableRows, 'inputs')];
	}

	@action
	openSidePanel() {
		this.resetFieldFormData();
		this.isSidePanelOpen = true;
	}

	@action
	closeSidePanel() {
		this.resetFieldFormData();
		this.isSidePanelOpen = false;
	}

	@action
	resetState() {
		this.closeSidePanel();
		this.revenueCategory = null;
		this.expenseCategory = null;
		this.showRevenueEditRow = false;
		this.showExpenseEditRow = false;
		this.showEditableTable = false;
		this.editableRows = [];
	}

	@action
	updateUpdateFieldFormData(key: keyof UpdateFieldData, value: UpdateFieldData[keyof UpdateFieldData]) {
		set(this.updateFieldFormData, key, value);
		return;
	}
	@action
	resetFieldFormData() {
		this.updateUpdateFieldFormData('name', this.field?.name || '');
		this.updateUpdateFieldFormData('acres', (typeof this.field?.acres == 'number' ? this.field?.acres.toString() : null) || '0');
		this.updateUpdateFieldFormData('error', '');
	}

	@action
	openDropDownModal(category: TypeOfCropFieldLedger, row: LedgerTableRow) {
		this.showAddCategoryModal = true;
		this.categoryTypeToDisplay = category;
		this.newCategoryRow = row;
	}

	@action
	closeAddCategoryModal() {
		this.showAddCategoryModal = false;
	}

	@action
	addRevenueButton() {
		this.showRevenueEditRow = true;
		this.showEditableTable = true;
		this.initializeRows();
		this.addRevenueRow();
	}

	@action
	addExpenseButton() {
		this.showExpenseEditRow = true;
		this.showEditableTable = true;
		this.initializeRows();
		this.addExpenseRow();
	}

	@action
	cancelEditableTable() {
		this.entryIdsToDelete = [];
		this.showRevenueEditRow = false;
		this.showEditableTable = false;
		this.showExpenseEditRow = false;
		this.editableRows = [];
		this.initializeRows();
	}

	@action
	openEditableTable() {
		this.entryIdsToDelete = [];
		this.showEditableTable = true;
		this.showRevenueEditRow = true;
		this.showExpenseEditRow = true;
		this.initializeRows();
	}

	@action
	itemsFn(fieldLedgerEntries: FieldLedgerEntryPerHarvestYear[]) {
		if (!fieldLedgerEntries) return [];

		const revenueObj: LedgerType = {
			fieldRevExpCategory: 'Revenues',
			calculatedValues: { staticItems: [], total: 0 },
			children: [],
			perAcreValueInUsd: 0,
		};

		const expenseObj: LedgerType = {
			fieldRevExpCategory: 'Expenses',
			calculatedValues: { staticItems: [], total: 0 },
			children: [],
			perAcreValueInUsd: 0,
		};

		const returnedArray: LedgerType[] = [];

		fieldLedgerEntries.forEach((entry) => {
			const ledgerType = entry.cropFieldLedgerType === TypeOfCropFieldLedger.Revenue ? revenueObj : expenseObj;
			const child: ChildEntry = {
				id: entry.id,
				categoryType: entry.cropFieldLedgerType,
				calculatedValues: {
					staticItems: [
						{
							name: 'Flat',
							value: ledgerType === expenseObj ? entry.flatValueInUsd : entry.flatValueInUsd,
						},
						{
							name: 'Per Acre',
							value: ledgerType === expenseObj ? entry.perAcreValueInUsd : entry.perAcreValueInUsd,
						},
					],
					total:
						ledgerType === expenseObj
							? entry.flatValueInUsd + entry.perAcreValueInUsd * this.getFieldAcres
							: entry.flatValueInUsd + entry.perAcreValueInUsd * this.getFieldAcres,
				},
				fieldRevExpNote: entry.description ?? '',
				perAcreValueInUsd: (revenueObj.perAcreValueInUsd += entry.perAcreValueInUsd),
				fieldRevExpCategory: entry.CropFieldLedgerCategory.name,
			};

			ledgerType === expenseObj
				? (ledgerType.calculatedValues.total += entry.flatValueInUsd + entry.perAcreValueInUsd * this.getFieldAcres)
				: (ledgerType.calculatedValues.total += entry.flatValueInUsd + entry.perAcreValueInUsd * this.getFieldAcres);
			if (ledgerType.children) {
				ledgerType.children.push(child);

				const flatTotalValue = ledgerType.children.reduce((sum: number, child: ChildEntry) => {
					if (child.calculatedValues?.staticItems) {
						const flatItem = child.calculatedValues.staticItems.find((item) => item.name === 'Flat');
						return sum + (flatItem?.value ?? 0);
					}
					return sum;
				}, 0);

				const perAcreTotalValue = ledgerType.children.reduce((sum: number, child: ChildEntry) => {
					if (child.calculatedValues?.staticItems) {
						const perAcreItem = child.calculatedValues.staticItems.find((item) => item.name === 'Per Acre');
						return sum + (perAcreItem?.value ?? 0);
					}
					return sum;
				}, 0);
				ledgerType.calculatedValues.staticItems = [
					{ name: 'Flat', value: flatTotalValue },
					{ name: 'Per Acre', value: perAcreTotalValue },
				];
			}
		});

		if (revenueObj.children) returnedArray.push(revenueObj);
		if (expenseObj.children) returnedArray.push(expenseObj);

		return returnedArray;
	}

	@action
	calculateTotal(inputValues: number[]) {
		// for ledgers the total is calculated as the sum of the flat values plus the sum of the per acre values times the field acres
		return inputValues[0] + inputValues[1] * this.getFieldAcres;
	}

	@action
	setSelectedCategory(rowId: string, category: NewCropFieldLedgerCategory) {
		if (category.type === TypeOfCropFieldLedger.Revenue) {
			this.set('revenueCategory', category);
		}
		if (category.type === TypeOfCropFieldLedger.Expense) {
			this.set('expenseCategory', category);
		}

		const editableRows = JSON.parse(JSON.stringify(this.editableRows));

		const parentRow = editableRows.find((row: LedgerTableRow) => {
			return row.children && row.children.some((child: LedgerTableRow) => child.id === rowId);
		});

		let childToUpdate;
		if (parentRow && parentRow.children) {
			childToUpdate = parentRow.children.find((child: LedgerTableRow) => child.id === rowId);
		}

		if (childToUpdate) {
			childToUpdate.fieldRevExpCategory = category.name;
			childToUpdate.fieldRevExpCategoryId = category.id;
			this.editableRows = editableRows;
		} else {
			throw new Error('Could not update Category. Child row not found');
		}
	}

	get isEditableTableValid() {
		const allEntriesHaveCategory = this.editableRows.every((row) =>
			row.children?.every(
				(entry) =>
					entry.fieldRevExpCategory?.length && entry.fieldRevExpCategory !== 'Revenues' && entry.fieldRevExpCategory !== 'New Expense',
			),
		);
		return allEntriesHaveCategory;
	}

	saveEditableTable = task({ drop: true }, async () => {
		const fieldId = this.field?.id;

		if (!fieldId) {
			console.error('Field Id not found');
			return;
		}

		const entryWasUpdated = (entry: ChildEntry) => {
			const originalEntry = this.model.field.data?.Field.FieldLedgerEntriesPerHarvestYear.find(
				(originalEntry) => originalEntry.id === entry.entryId,
			);
			const flatValueInUsd = entry.calculatedValues?.inputs?.find((item) => item.name === 'Flat')?.value;
			const perAcreValueInUsd = entry.calculatedValues?.inputs?.find((item) => item.name === 'Per Acre')?.value;
			return (
				entry.fieldRevExpNote !== (originalEntry?.description ?? '') ||
				flatValueInUsd !== originalEntry?.flatValueInUsd ||
				perAcreValueInUsd !== originalEntry?.perAcreValueInUsd
			);
		};

		const entriesToSet: ChildEntry[] = this.editableRows
			.flatMap((row) => row.children)
			.filter((entry) => !!entry)
			.filter(entryWasUpdated);

		const setManyMutationArgs: Mutation_setManyFieldLedgerEntriesPerHarvestYearArgs = {
			data: entriesToSet
				.map((entry) => {
					if (!entry.fieldRevExpCategoryId) {
						console.error(`Category not found for entry: ${entry}`);
						return;
					}

					const flatValueInUsd = entry.calculatedValues?.inputs?.find((item) => item.name === 'Flat')?.value;
					const perAcreValueInUsd = entry.calculatedValues?.inputs?.find((item) => item.name === 'Per Acre')?.value;

					if (flatValueInUsd === undefined || perAcreValueInUsd === undefined) {
						console.error(`Flat or Per Acre not found: ${{ flatValueInUsd, perAcreValueInUsd }}`);
						return;
					}

					return {
						cropFieldLedgerCategoryId: entry.fieldRevExpCategoryId,
						description: entry.fieldRevExpNote ?? undefined,
						fieldId,
						harvestYearStartDate: DateTime.fromISO(this.startDate).startOf('year').toISODate(),
						flatValueInUsd,
						perAcreValueInUsd,
					} as FieldLedgerEntryPerHarvestYearCreateDTO;
				})
				.filter((args): args is FieldLedgerEntryPerHarvestYearCreateDTO => !!args),
		};

		const deleteManyMutationArgs: Mutation_deleteManyFieldLedgerEntriesPerHarvestYearArgs = {
			ids: this.entryIdsToDelete.filter((maybeId): maybeId is string => !!maybeId),
		};

		await Promise.all([
			undefined,
			setManyMutationArgs.data.length ? setManyFieldLedgerEntriesPerHarvestYear(this, setManyMutationArgs) : undefined,
			deleteManyMutationArgs.ids.length ? deleteManyFieldLedgerEntriesPerHarvestYear(this, deleteManyMutationArgs) : undefined,
		]);

		this.showEditableTable = false;
		this.showRevenueEditRow = false;
		this.showExpenseEditRow = false;
	});

	@action
	handleInputChange(rowId: string, inputId: string, event: Event) {
		const target = event.target as HTMLInputElement;
		const value = +target.value || 0;
		this.updateInputValue(rowId, inputId, value);
	}

	@action
	addRevenueRow() {
		this.showRevenueEditRow = true;
		const editableRows = JSON.parse(JSON.stringify(this.editableRows));
		const row = {
			id: guidFor(`field-revenue-row-${Date.now()}`),
			categoryType: TypeOfCropFieldLedger.Revenue,
			showDropDown: true,
			fieldRevExpCategory: undefined,
			isEditable: true,
			fieldRevExpNote: '',
			calculatedValues: {
				inputs: [
					{
						id: guidFor(`flat-revenue-input-${Date.now()}`),
						name: 'Flat',
						value: 0,
					},
					{
						id: guidFor(`per-acre-revenue-input-${Date.now()}`),
						name: 'Per Acre',
						value: 0,
					},
				],
				total: 0,
			},
		};

		let parentRowFound = false;

		// Iterate over existing editableRows to find the appropriate parent row
		editableRows.forEach((parentRow: LedgerTableRow) => {
			if (parentRow.categoryType === TypeOfCropFieldLedger.Revenue) {
				if (parentRow.children) {
					parentRow.children.push(row);
				} else {
					parentRow.children = [row];
				}
				parentRowFound = true;
			}
		});

		// If no parent row found, create a new parent row
		if (!parentRowFound) {
			editableRows.push({
				id: guidFor(`field-revenue-parent-row-${Date.now()}`),
				categoryType: TypeOfCropFieldLedger.Revenue,
				showDropDown: false,
				fieldRevExpCategory: 'Revenues',
				doNotDisplayButton: true,
				isEditable: false,
				displayRow: true,
				fieldRevExpNote: '',
				calculatedValues: {
					inputs: [
						{
							id: guidFor(`flat-revenue-parent-input-${Date.now()}`),
							name: 'Flat',
							value: 0,
						},
						{
							id: guidFor(`per-acre-revenue-parent-input-${Date.now()}`),
							name: 'Per Acre',
							value: 0,
						},
					],
					total: 0,
				},
				children: [row],
			});
		}
		this.editableRows = JSON.parse(JSON.stringify(editableRows));
	}

	@action
	addExpenseRow() {
		this.showExpenseEditRow = true;
		const editableRows = JSON.parse(JSON.stringify(this.editableRows));

		const row = {
			id: guidFor(`field-expense-row-${Date.now()}`),
			categoryType: TypeOfCropFieldLedger.Expense,
			showDropDown: true,
			fieldRevExpCategory: undefined,
			isEditable: true,
			fieldRevExpNote: '',
			calculatedValues: {
				inputs: [
					{
						id: guidFor(`flat-revenue-input-${Date.now()}`),
						name: 'Flat',
						value: 0,
					},
					{
						id: guidFor(`per-acre-revenue-input-${Date.now()}`),
						name: 'Per Acre',
						value: 0,
					},
				],
				total: 0,
			},
		};

		let parentRowFound = false;

		// Iterate over existing editableRows to find the appropriate parent row
		editableRows.forEach((parentRow: LedgerTableRow) => {
			if (parentRow.categoryType === TypeOfCropFieldLedger.Expense) {
				if (parentRow.children) {
					parentRow.children.push(row);
				} else {
					parentRow.children = [row];
				}
				parentRowFound = true;
			}
		});

		// If no parent row found, create a new parent row
		if (!parentRowFound) {
			editableRows.push({
				id: guidFor(`field-expense-parent-row-${Date.now()}`),
				categoryType: TypeOfCropFieldLedger.Expense,
				showDropDown: false,
				fieldRevExpCategory: 'New Expense',
				doNotDisplayButton: true,
				isEditable: false,
				displayRow: true,
				fieldRevExpNote: '',
				calculatedValues: {
					inputs: [
						{
							id: guidFor(`flat-expense-parent-input-${Date.now()}`),
							name: 'Flat',
							value: 0,
						},
						{
							id: guidFor(`per-acre-expense-parent-input-${Date.now()}`),
							name: 'Per Acre',
							value: 0,
						},
					],
					total: 0,
				},
				children: [row],
			});
		}

		this.editableRows = editableRows;
	}

	@action
	setTimePeriod(option: UiDateFilterOption) {
		this.startDate = option.startDate;
		this.endDate = option.endDate;
	}

	@action
	removeLedgerEntry(row: ChildEntry) {
		const entryIdDeleted: string | undefined = row.entryId;
		// const entryIdToRemoveFromRow: string | undefined = row.id;
		const editableRows = structuredClone(this.editableRows);

		// Filter out the row with the matching entryIdToRemoveFromRow
		const filteredRows = editableRows.map((row: LedgerTableRow) => {
			return {
				...row,
				children: row.children?.filter((child: ChildEntry) => child.entryId !== entryIdDeleted),
			};
		});

		if (entryIdDeleted) {
			this.entryIdsToDelete.push(entryIdDeleted);
		} else {
			console.warn(`Entry Id not found for deleted item`);
		}

		this.editableRows = filteredRows;
		this.updateInputValue('', '', 0);
	}

	@action
	afterDelete() {
		this.router.transitionTo('businesses.business.fields');
	}

	@action
	closeConfirmation() {
		this.fieldToRemove = null;
	}

	@action
	deleteField() {
		this.fieldToRemove = this.field;
	}

	calculateNetPl(rows: any[], key: 'staticItems' | 'inputs') {
		let revenueFlatValue = 0;
		let expenseFlatValue = 0;
		let revenuePerAcreValue = 0;
		let expensePerAcreValue = 0;

		rows.forEach((row) => {
			if (row.fieldRevExpCategory === 'Revenues') {
				if (row.calculatedValues[key]) {
					const flatItem = row.calculatedValues[key].find((item: { name: string }) => item.name === 'Flat');

					if (flatItem) {
						revenueFlatValue = flatItem.value;
					}
					const perAcreItem = row.calculatedValues[key].find((item: { name: string }) => item.name === 'Per Acre');
					if (perAcreItem) {
						revenuePerAcreValue = perAcreItem.value;
					}
				}
			} else if (row.fieldRevExpCategory === 'Expenses') {
				if (row.calculatedValues[key]) {
					const flatItem = row.calculatedValues[key].find((item: { name: string }) => item.name === 'Flat');
					if (flatItem) {
						expenseFlatValue = flatItem.value;
					}
					const perAcreItem = row.calculatedValues[key].find((item: { name: string }) => item.name === 'Per Acre');
					if (perAcreItem) {
						expensePerAcreValue = perAcreItem.value;
					}
				}
			}
		});

		const netFlatValue = revenueFlatValue - expenseFlatValue;
		const netPerAcreValue = revenuePerAcreValue - expensePerAcreValue;

		return {
			fieldRevExpCategory: 'Net P/L',
			calculatedValues: {
				[key]: [
					{ name: 'Flat', value: netFlatValue },
					{ name: 'Per Acre', value: netPerAcreValue },
				],
				total: netFlatValue + netPerAcreValue * this.getFieldAcres,
			},
		};
	}
	// This is needed to be debounced because the input field otherwise loses focus after the input value is updated
	@action
	debouncedHandleInputChange(rowId: string, inputId: string, event: InputEvent) {
		const debounceTime = event.data === '.' ? 1200 : 200;
		const timeoutTime = event.data === '.' ? 1210 : 210;

		debounce(this, this.handleInputChange, rowId, inputId, event, debounceTime);

		setTimeout(() => {
			const inputElement = document.getElementById(inputId);
			if (inputElement) {
				inputElement.focus();
			}
		}, timeoutTime);
	}

	@action
	getNewCategory(category: NewCropFieldLedgerCategory, row: string) {
		const categoryToAdd = category?.createCropFieldLedgerCategory;
		if (categoryToAdd) {
			this.setSelectedCategory(row, categoryToAdd);
		}
	}

	reverseExpenseValueSign(value: number | null | undefined, row: LedgerTableRow) {
		if (row.fieldRevExpCategory === 'Expenses' || row.categoryType === TypeOfCropFieldLedger.Expense) {
			// Truthy check used so that we do not transform `0`. Doing so would cause `-0`, throwing off formatting e.g. `($0.00)`
			return value ? value * -1 : value;
		} else {
			return value;
		}
	}
}
