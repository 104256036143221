import LgmInsuranceMonths from 'vault-client/controllers/lgm-insurance-months';
import { BusinessEntityRole } from 'vault-client/types/graphql-types';

export default class BusinessesBusinessLivestockFeedController extends LgmInsuranceMonths {
	insuranceRoutePath = 'businesses.business.insurance';
	get swineMode() {
		const roles = this.model.getCustomer.data?.Customer?.businessRoles ?? [];
		return roles.includes(BusinessEntityRole.HogProducer);
	}
}
