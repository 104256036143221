import type { AggregateLedgerForecastedEntryDTO } from 'vault-client/types/graphql-types';
import type { BusinessProjectedRevenue } from '../dashboard';

/**
 * Get the projected revenue for a business. Caller must ensure that only revenue ledger entries are passed in.
 * @param aggregateRevenueLedgerEntries - The aggregate ledger entries for the business.
 * @returns The projected revenue for the business
 */
function getBusinessProjectedRevenue(aggregateRevenueLedgerEntries: AggregateLedgerForecastedEntryDTO[]): BusinessProjectedRevenue {
	const businessRevenue = aggregateRevenueLedgerEntries.reduce((acc, entry) => {
		return acc + (entry.sum?.amount ?? 0);
	}, 0);

	return {
		businessRevenue,
		totalRevenue: businessRevenue,
	};
}

export { getBusinessProjectedRevenue };
