import Controller from '@ember/controller';
import { CellComponents } from 'vault-client/types/vault-table';

export default class BusinessesBusinessCropsController extends Controller {
	cropPlansRoute = 'businesses.business.crop-plans';
	cropTargetsRoute = 'businesses.business.crop-targets';
	cropFillsRoute = 'businesses.business.crop-fills';
	fieldsRoute = 'businesses.business.fields';
	contractsRoute = 'businesses.business.crop-contracts';

	get columns() {
		const baseColumns = [
			{
				id: 'fdc5ebe0-1ce5-4a14-a04e-6a24de019f9e',
				name: 'Name',
				valuePath: 'name',
				minWidth: 225,
				textAlign: 'left',
				headerTextAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				linkRoutePath: 'linkRoute',
				linkModelPath: 'id',
			},
		];

		return baseColumns;
	}

	get rows() {
		return [
			{
				name: 'Crop Targets',
				linkRoute: this.cropTargetsRoute,
			},
			{
				name: 'Crop Contracts',
				linkRoute: this.contractsRoute,
			},
			{
				name: 'Fields',
				linkRoute: this.fieldsRoute,
			},
		];
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/crops': BusinessesBusinessCropsController;
	}
}
