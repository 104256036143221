import { QueryOptions, useQuery } from 'glimmer-apollo';
import {
	YardsOverviewQuery,
	YardsOverviewQueryVariables,
	YARDS_OVERVIEW_QUERY,
} from 'vault-client/graphql/queries/businesses/business/cattle/yards';

export function useYardsOverviewQuery(context: object, options: QueryOptions<YardsOverviewQuery, YardsOverviewQueryVariables>) {
	return useQuery<YardsOverviewQuery, YardsOverviewQueryVariables>(context, () => [YARDS_OVERVIEW_QUERY, options]);
}
