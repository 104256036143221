import { gql } from 'glimmer-apollo';

export const UPDATE_RATION = gql`
	mutation UpdateRation($id: String!, $data: CattleFeedRationUpdateDTO!) {
		updateCattleFeedRation(id: $id, data: $data) {
			id
			name
			description
		}
	}
`;

export const CREATE_RATION = gql`
	mutation CreateRation($data: CattleFeedRationCreateDTO!) {
		createCattleFeedRation(data: $data) {
			id
			name
			description
		}
	}
`;

export const DELETE_RATION = gql`
	mutation DeleteRation($id: String!) {
		deleteCattleFeedRation(id: $id) {
			id
		}
	}
`;
