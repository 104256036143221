import { gql } from 'glimmer-apollo';
import type {
	CropFilterDTO,
	CropHarvestedAndSoldVolumeFilterDTO,
	CropHarvestYearFilterDTO,
	CropTransactionFilterDTO,
	LedgerForecastedEntryAggregateDTO,
	LedgerForecastedEntryFilterDTO,
	LedgerForecastedEntryGroupByDTO,
	Query,
} from 'vault-client/types/graphql-types';

const GET_HARVEST_PNL_COMPONENTS = gql`
	query GetFieldHarvestPnlComponents(
		$scopeId: String!
		$cropsWhere: CropFilterDTO!
		$harvestYear: Float!
		$cropHarvestedAndSoldVolumesWhere: CropHarvestedAndSoldVolumeFilterDTO!
		$cropHarvestYearsWhere: CropHarvestYearFilterDTO!
		$cropTransactionsWhere: CropTransactionFilterDTO!
		$aggregateBusinessRevenueLedgerEntriesWhere: LedgerForecastedEntryFilterDTO!
		$aggregateBusinessExpenseLedgerEntriesWhere: LedgerForecastedEntryFilterDTO!
		$aggregateBusinessLedgerEntriesCalc: LedgerForecastedEntryAggregateDTO!
		$aggregateBusinessLedgerEntriesGroupBy: LedgerForecastedEntryGroupByDTO!
	) {
		Crops(where: $cropsWhere) {
			id
			name
			price
			pricingMethodology
			Category {
				id
				HedgeProduct {
					id
					slug
					MostCurrentFuture {
						id
						barchartSymbol
						displayExpiresAt
						SymbolGroup {
							id
							displayFactor
							fractionDigits
						}
					}
					StandardProductLotSpecification {
						id
						pointValue
						lotSize
					}
				}
			}
			CropHarvestYears(where: $cropHarvestYearsWhere) {
				id
				cropId
				harvestYear
				effectiveHedgingStartDate
				effectiveHedgingEndDate
				ContractMonthInstrument {
					id
					displayExpiresAt
					barchartSymbol
					SymbolGroup {
						id
						displayFactor
						fractionDigits
					}
				}
			}
			RevenuesForHarvestYear(harvestYear: $harvestYear) {
				totalUsdFromCropFlatValues
				totalUsdFromCropPerAcreValues
				totalUsdFromFieldFlatValues
				totalUsdFromFieldPerAcreValues
			}
			ExpensesForHarvestYear(harvestYear: $harvestYear) {
				totalUsdFromCropFlatValues
				totalUsdFromCropPerAcreValues
				totalUsdFromFieldFlatValues
				totalUsdFromFieldPerAcreValues
			}
		}
		CropHarvestedAndSoldVolumes(scopeId: $scopeId, where: $cropHarvestedAndSoldVolumesWhere) {
			id
			cropId
			forecastedProductionInBu
			volumeSoldInBu
		}
		CropTransactions(scopeId: $scopeId, where: $cropTransactionsWhere) {
			id
			cropId
			bushels
			pricingType
			flatPrice
			futuresPrice
			basisPrice
		}
		AggregateBusinessLedgerEntriesRevenue: AggregateLedgerForecastedEntries(
			scopeId: $scopeId
			calc: $aggregateBusinessLedgerEntriesCalc
			where: $aggregateBusinessRevenueLedgerEntriesWhere
			groupBy: $aggregateBusinessLedgerEntriesGroupBy
		) {
			sum {
				amount
			}
		}
		AggregateBusinessLedgerEntriesExpenses: AggregateLedgerForecastedEntries(
			scopeId: $scopeId
			calc: $aggregateBusinessLedgerEntriesCalc
			where: $aggregateBusinessExpenseLedgerEntriesWhere
			groupBy: $aggregateBusinessLedgerEntriesGroupBy
		) {
			sum {
				amount
			}
		}
	}
`;

type GetHarvestPnlComponentsVariables = {
	scopeId: string;
	cropsWhere: CropFilterDTO;
	harvestYear: number;
	cropHarvestedAndSoldVolumesWhere: CropHarvestedAndSoldVolumeFilterDTO;
	cropHarvestYearsWhere: CropHarvestYearFilterDTO;
	cropTransactionsWhere: CropTransactionFilterDTO;
	aggregateBusinessRevenueLedgerEntriesWhere: LedgerForecastedEntryFilterDTO;
	aggregateBusinessExpenseLedgerEntriesWhere: LedgerForecastedEntryFilterDTO;
	aggregateBusinessLedgerEntriesCalc: LedgerForecastedEntryAggregateDTO;
	aggregateBusinessLedgerEntriesGroupBy: LedgerForecastedEntryGroupByDTO;
};
type GetHarvestPnlComponentsQuery = {
	Crops: Query['Crops'];
	CropHarvestedAndSoldVolumes: Query['CropHarvestedAndSoldVolumes'];
	CropTransactions: Query['CropTransactions'];
	AggregateBusinessLedgerEntriesRevenue: Query['AggregateLedgerForecastedEntries'];
	AggregateBusinessLedgerEntriesExpenses: Query['AggregateLedgerForecastedEntries'];
};

export { GET_HARVEST_PNL_COMPONENTS, type GetHarvestPnlComponentsQuery, type GetHarvestPnlComponentsVariables };
