import { useMutation, type MutationOptions } from 'glimmer-apollo';
import { CREATE_CATTLE_FEED_RATION_STAGE } from 'vault-client/graphql/mutations/cattle/cattle-feed-ration-stage';
import type { Mutation, Mutation_createCattleFeedRationStageArgs } from 'vault-client/types/graphql-types';

const RELATED_FIELD_NAMES = [
	'CattleFeedRationStages',
	'CattleFeedRationStage',
	'CattleFeedRationStageCount',
	'CattleFeedRationStageIngredient',
	'CattleFeedRationStageIngredientCount',
	'CattleFeedRationStageIngredients',
	'CattleFeedRations',
	'CattleFeedRation',
	'CattleFeedRationCount',
	'AggregateCattleLotFeedRationUsagePerMonths',
] as const;

const defaultOptions: MutationOptions<Mutation['createCattleFeedRationStage'], Mutation_createCattleFeedRationStageArgs> = {
	update: (cache) => {
		RELATED_FIELD_NAMES.forEach((fieldName) => {
			cache.evict({ fieldName });
		});
		cache.gc();
	},
};

export function useCreateCattleFeedRationStageMutation(
	context: object,
	options?: MutationOptions<Mutation['createCattleFeedRationStage'], Mutation_createCattleFeedRationStageArgs>,
) {
	return useMutation<Mutation['createCattleFeedRationStage'], Mutation_createCattleFeedRationStageArgs>(context, () => [
		CREATE_CATTLE_FEED_RATION_STAGE,
		{
			...defaultOptions,
			...options,
		},
	]);
}
