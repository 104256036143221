import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { queryManager } from 'ember-apollo-client';
import { inject as service } from '@ember/service';
import searchQuery from 'vault-client/graphql/queries/search.graphql';
import agentFuzzyQuery from 'vault-client/graphql/queries/agent-fuzzy-match.graphql';
import stateFuzzyQuery from 'vault-client/graphql/queries/state-fuzzy-match.graphql';
import { DateTime } from 'luxon';
import getFilterDisplayProperty from '../../utils/get-filter-display-property';
import query from 'vault-client/graphql/queries/lrp-insurance-endorsements/index.graphql';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import { CellComponents, SortObj, TableColumn } from 'vault-client/types/vault-table';
import { LrpInsuranceEndorsementFilterDTO, LrpInsuranceEndorsementSortByDTO, State } from 'vault-client/types/graphql-types';
import LRPInsuranceEndorsementsIndexRoute from 'vault-client/routes/lrp-insurance-endorsements';
import { isEmpty } from '@ember/utils';
import ApplicationScope from 'vault-client/services/application-scope';
import { ModelFrom } from 'vault-client/utils/type-utils';
import OrganizationLrpInsuranceEndorsementsRoute from 'vault-client/routes/organizations/organization/lrp-insurance-endorsements';
import BusinessLrpInsuranceEndorsementsRoute from 'vault-client/routes/businesses/business/lrp-insurance-endorsements';

const queryParams = [
	'agent',
	'aipId',
	'customerId',
	'salesEffectiveStartDate',
	'salesEffectiveEndDate',
	'coverageEndStartDate',
	'coverageEndEndDate',
	'stateId',
	'page',
	'sorts',
	'size',
];

interface DateFilterQueryParamPrefixes {
	salesEffective: string | null;
	coverageEnd: string | null;
}

type DateFilterQueryParamPrefix = keyof DateFilterQueryParamPrefixes;

interface SearchFilterIdentifiers {
	agent: string | null;
	aipId: string | null;
	customerId: string | null;
	stateId: string | null;
}

type SearchFilterIdentifier = keyof SearchFilterIdentifiers;

function isSearchFilterIdentifier(key: unknown): key is SearchFilterIdentifier {
	const searchFilterIdentifiers = ['agent', 'aipId', 'customerId', 'stateId'];
	if (typeof key === 'string' && searchFilterIdentifiers.includes(key)) {
		return true;
	}
	return false;
}

interface searchResult {
	id?: string;
	name?: string;
	type: string;
}

interface Agent {
	agentFirstName: string;
	agentLastName: string;
}
export default class LRPInsuranceEndorsementsIndexController extends Controller {
	@queryManager apollo: any;
	@service declare applicationScope: ApplicationScope;

	declare model: ModelFrom<OrganizationLrpInsuranceEndorsementsRoute> | ModelFrom<BusinessLrpInsuranceEndorsementsRoute>;
	lrpInsuranceEndorsementRoute: string = '';
	insuranceRoutePath: string = '';
	insurancePolicyRoutePath: string = '';
	searchPlaceholder = 'Filter by AIP, agent, state...';
	searchPrompt = 'Type a search term to filter endorsements by AIP, agent name, or state.';

	queryParams = queryParams;

	salesEffectiveDateRangeOptions = [
		{
			displayName: 'All Dates',
			startDate: '1900-01-01',
			endDate: '2999-12-31',
		},
		{
			displayName: 'Previous 7 Days',
			startDate: DateTime.local().minus({ days: 7 }).toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Previous 30 Days',
			startDate: DateTime.local().minus({ days: 30 }).toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Previous 90 Days',
			startDate: DateTime.local().minus({ days: 90 }).toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Previous 12 Months',
			startDate: DateTime.local().minus({ months: 12 }).startOf('month').toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Previous 24 Months',
			startDate: DateTime.local().minus({ months: 24 }).startOf('month').toISODate(),
			endDate: DateTime.local().toISODate(),
		},
	];

	coverageEndDateRangeOptions = [
		{
			displayName: 'All Dates',
			startDate: '1900-01-01',
			endDate: '2999-12-31',
		},
		{
			displayName: 'Previous 12 Months',
			startDate: DateTime.local().minus({ months: 12 }).startOf('month').toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Next 7 Days',
			startDate: DateTime.local().toISODate(),
			endDate: DateTime.local().plus({ days: 7 }).toISODate(),
		},
		{
			displayName: 'Next 30 Days',
			startDate: DateTime.local().toISODate(),
			endDate: DateTime.local().plus({ days: 30 }).toISODate(),
		},
		{
			displayName: 'Next 90 Days',
			startDate: DateTime.local().toISODate(),
			endDate: DateTime.local().plus({ days: 90 }).toISODate(),
		},
		{
			displayName: 'Next 12 Months',
			startDate: DateTime.local().toISODate(),
			endDate: DateTime.local().endOf('month').plus({ months: 12 }).toISODate(),
		},
	];

	@tracked agent: string | null = null;
	@tracked aipId: string | null = null;
	@tracked salesEffectiveStartDate: string | null = '1900-01-01';
	@tracked salesEffectiveEndDate: string | null = '2999-12-31';
	@tracked coverageEndStartDate: string | null = DateTime.local().toISODate();
	@tracked coverageEndEndDate: string | null = DateTime.local().endOf('month').plus({ months: 12 }).toISODate();
	@tracked stateId: string | null = null;
	@tracked customerId: string | null = null;
	@tracked currentScope = '';
	@tracked page = 0;
	@tracked sorts = [{ valuePath: 'salesEffectiveDate', isAscending: false }];
	@tracked size = 100;
	@tracked columns: TableColumn[] = [
		{
			id: '62058f9b-930c-481a-b308-c762945cc7f4',
			name: 'Producer',
			valuePath: 'InsurancePolicy.producerName',
			width: 225,
			minWidth: 225, // need minWidth === width re bug on resizing first fixed column
			textAlign: 'left',
			isSortable: false,
			cellComponent: CellComponents.String,
			isVisible: true,
			isFixed: '',
		},
		{
			id: '6aa04fe9-fa12-4df8-b3de-c80fcbfc518e',
			name: 'Sales Date',
			valuePath: 'salesEffectiveDate',
			width: 135,
			cellComponent: CellComponents.IntlDateTimeFormat,
			componentArgs: { month: 'numeric', day: 'numeric', year: 'numeric' },
			textAlign: 'left',
			isSortable: true,
			isFixed: '',
			linkRoute: this.lrpInsuranceEndorsementRoute,
			linkModelPath: 'id',
			isVisible: true,
		},
		{
			id: 'e28aaccf-ed23-484f-bba4-385b6719dc8c',
			name: 'Coverage End Date',
			valuePath: 'coverageEndDate',
			width: 175,
			cellComponent: CellComponents.IntlDateTimeFormat,
			componentArgs: { month: 'numeric', day: 'numeric', year: 'numeric' },
			textAlign: 'left',
			isSortable: true,
			isFixed: '',
			isVisible: true,
		},
		{
			id: 'a66d832a-87a5-4c72-ae48-b81da4f4c2e7',
			name: 'Price',
			valuePath: 'coveragePrice',
			width: 110,
			cellComponent: CellComponents.IntlNumberFormat,
			componentArgs: {
				style: 'currency',
				currency: 'USD',
				currencySign: 'accounting',
			},
			textAlign: 'right',
			isSortable: true,
			isFixed: '',
			isVisible: true,
		},
		{
			id: 'f00bafe1-e5ac-4d2e-9c89-8f0f94cbc94b',
			name: 'State',
			valuePath: 'State.abbreviation',
			width: 90,
			cellComponent: CellComponents.String,
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: 'c56a5379-1c18-4dde-b630-f526a481a4c0',
			name: 'State (FIPS)',
			valuePath: 'State.rmaStateCode',
			width: 120,
			cellComponent: CellComponents.String,
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: false,
		},
		{
			id: '36b58605-61a9-4fd1-822c-5255d4c860cc',
			name: 'Commodity',
			valuePath: 'RmaCommodity.commodityName',
			width: 130,
			cellComponent: CellComponents.String,
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: 'f039c0d6-dc77-4f3f-b7bf-889a6efc3c0d',
			name: 'Type',
			valuePath: 'RmaType.typeName',
			width: 120,
			cellComponent: CellComponents.String,
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '145a42a2-e803-4641-aed7-1c13afed7350',
			name: 'Head Count',
			valuePath: 'headCount',
			width: 135,
			cellComponent: CellComponents.IntlNumberFormat,
			textAlign: 'right',
			isSortable: true,
			isFixed: '',
			isVisible: true,
		},
		{
			id: 'f0291851-f7ef-4498-8a26-fe8aa3325658',
			name: 'Target Weight (CWT)',
			valuePath: 'targetWeightCwt',
			width: 185,
			cellComponent: CellComponents.IntlNumberFormat,
			textAlign: 'right',
			isSortable: true,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '6484447b-4ef3-4b52-9238-2beb9b8cf0ae',
			name: 'Insured Value',
			valuePath: 'insuredValue',
			width: 150,
			cellComponent: CellComponents.IntlNumberFormat,
			componentArgs: {
				style: 'currency',
				currency: 'USD',
				currencySign: 'accounting',
			},
			textAlign: 'right',
			isSortable: true,
			isFixed: '',
			isVisible: true,
			isTotaled: true,
		},
		{
			id: '40cfbb0d-dddb-4204-8e3a-50033c356502',
			name: 'Length (weeks)',
			valuePath: 'lengthInWeeks',
			width: 150,
			cellComponent: CellComponents.IntlNumberFormat,
			textAlign: 'right',
			isSortable: true,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '23e420dd-fd24-4625-b13d-b8f6498b361f',
			name: 'Subsidy',
			valuePath: 'subsidyAmount',
			width: 120,
			cellComponent: CellComponents.IntlNumberFormat,
			componentArgs: {
				style: 'currency',
				currency: 'USD',
				currencySign: 'accounting',
			},
			textAlign: 'right',
			isSortable: true,
			isFixed: '',
			isVisible: true,
			isTotaled: true,
		},
		{
			id: '6119e942-02cf-41d3-8ec1-d384cb15dc19',
			name: 'Total Premium',
			valuePath: 'totalPremiumAmount',
			width: 150,
			cellComponent: CellComponents.IntlNumberFormat,
			componentArgs: {
				style: 'currency',
				currency: 'USD',
				currencySign: 'accounting',
			},
			textAlign: 'right',
			isSortable: true,
			isFixed: '',
			isVisible: true,
			isTotaled: true,
		},
		{
			id: 'd98470da-d323-4769-a7b6-3705ed5f830d',
			name: 'Producer Premium',
			valuePath: 'producerPremiumAmount',
			width: 150,
			cellComponent: CellComponents.IntlNumberFormat,
			componentArgs: {
				style: 'currency',
				currency: 'USD',
				currencySign: 'accounting',
				minimumFractionDigits: 0,
				maximumFractionDigits: 0,
			},
			textAlign: 'right',
			isSortable: true,
			isFixed: '',
			isVisible: true,
		},
		{
			id: 'e96d1d7c-a722-441c-a886-01742f6ea429',
			name: 'Policy',
			valuePath: 'InsurancePolicy.policyNumber',
			width: 150,
			textAlign: 'left',
			isSortable: false,
			cellComponent: CellComponents.String,
			isFixed: '',
			linkRoute: this.insurancePolicyRoutePath,
			linkModelPath: 'insurancePolicyId',
			isVisible: false,
		},
		{
			id: 'f75f8717-81d0-4047-a73b-203ad87d0383',
			name: 'Agency',
			valuePath: 'InsurancePolicy.agencyName',
			width: 200,
			textAlign: 'right',
			isSortable: false,
			cellComponent: CellComponents.String,
			isFixed: '',
			isVisible: false,
		},
		{
			id: '5ci07025-ae5c-480a-8621-e30856295d76',
			name: 'AIP',
			valuePath: 'InsurancePolicy.AIP.name',
			width: 120,
			textAlign: '',
			isSortable: false,
			cellComponent: CellComponents.String,
			isFixed: '',
			isVisible: false,
		},
		{
			id: '4cf789b9-5384-4b6c-aa84-8f4c719e801d',
			name: 'Agent',
			valuePath: 'InsurancePolicy.agentFullName',
			width: 170,
			textAlign: 'right',
			isSortable: false,
			cellComponent: CellComponents.String,
			isFixed: '',
			isVisible: false,
		},
		{
			id: '642ffc0b-b67d-437e-9ca5-9590c899c96d',
			name: 'Indemnity',
			valuePath: 'indemnity',
			width: 150,
			cellComponent: CellComponents.IntlNumberFormat,
			componentArgs: {
				style: 'currency',
				currency: 'USD',
				currencySign: 'accounting',
				minimumFractionDigits: 0,
				maximumFractionDigits: 0,
			},
			textAlign: 'right',
			isSortable: true,
			isFixed: '',
			isVisible: true,
			isTotaled: true,
		},
		{
			id: 'b199cec5-29c6-4082-b590-47026de47481',
			name: 'Net P/L',
			valuePath: 'pnl',
			width: 150,
			cellComponent: CellComponents.IntlNumberFormat,
			componentArgs: {
				style: 'currency',
				currency: 'USD',
				currencySign: 'accounting',
				minimumFractionDigits: 0,
				maximumFractionDigits: 0,
			},
			textAlign: 'right',
			isSortable: true,
			isFixed: '',
			isVisible: true,
			isTotaled: true,
		},
	];

	get endorsementData() {
		return this.model.data?.LrpInsuranceEndorsements;
	}

	get totalNumEndorsements() {
		return this.model.data?.LrpInsuranceEndorsementCount.count;
	}

	get searchFilterQueryParams() {
		const obj: {
			[param: string]: { [filterProperty: string]: string };
		} = {};
		const searchQueryParams: SearchFilterIdentifier[] = ['agent', 'aipId', 'customerId', 'stateId'];

		searchQueryParams.forEach((param) => {
			// if applicable setting filter for customerId for customer scope
			const value = param === 'customerId' && this.applicationScope.globalCustomerId ? this.applicationScope.globalCustomerId : this[param];

			if (value) {
				obj[param] = {
					filterRule: 'equals',
					filterValue: value,
				};

				// set filterComponent property to specify component for custom display extended from search-filter
				const filterDisplayObj = getFilterDisplayProperty(param);
				if (filterDisplayObj && filterDisplayObj.customComponent) {
					obj[param].filterComponent = filterDisplayObj.customComponent;
				}
				// set filterLabel property to specify custom label for filter - default would be filterIdentifier (matches queryParam)
				if (filterDisplayObj && filterDisplayObj.label) {
					obj[param].filterLabel = filterDisplayObj.label;
				}
			}
		});

		return obj;
	}

	get scopedSearchFilterQueryParams() {
		if (this.applicationScope.globalCustomerId) {
			const { customerId, ...rest } = this.searchFilterQueryParams;
			return rest;
		} else {
			return this.searchFilterQueryParams;
		}
	}

	get salesEffectiveDateQueryParam() {
		return {
			startDate: this.salesEffectiveStartDate,
			endDate: this.salesEffectiveEndDate,
		};
	}

	get coverageEndDateQueryParam() {
		return {
			startDate: this.coverageEndStartDate,
			endDate: this.coverageEndEndDate,
		};
	}

	get columnTotals() {
		const aggregateLrpEndorsements = this.model.data?.AggregateLrpInsuranceEndorsements;
		if (isEmpty(aggregateLrpEndorsements)) {
			return [];
		}
		const [aggregateEndorsements] = aggregateLrpEndorsements ?? [];
		return [aggregateEndorsements.sum];
	}

	get query() {
		return query;
	}

	get formattedQueryParams(): { orderBy: LrpInsuranceEndorsementSortByDTO; where: LrpInsuranceEndorsementFilterDTO } {
		const route = new LRPInsuranceEndorsementsIndexRoute();

		return {
			orderBy: route.generateOrderBy(this.sorts),
			where: route.generateWhere(
				this.applicationScope.locationId,
				this.applicationScope.globalCustomerId,
				this.applicationScope.organizationId,
				this.aipId,
				this.agent,
				this.stateId,
				this.salesEffectiveStartDate,
				this.salesEffectiveEndDate,
				this.coverageEndStartDate,
				this.coverageEndEndDate,
			),
		};
	}

	@action
	setSorts(sorts: SortObj[]) {
		this.sorts = sorts;
	}

	@action
	async fetchSearchResults(searchText: string) {
		let searchApiResults = [];
		const agentResults: searchResult[] = [];
		const stateResults: searchResult[] = [];

		// get fuzzy match agents
		const agentVariables = {
			where: {
				OR: [
					{ agentFirstName: { contains: searchText } },
					{ agentLastName: { contains: searchText } },
					{ agentFullName: { contains: searchText } },
				],
			},
		};
		const agentsArr = (
			(
				await this.apollo.watchQuery({
					query: agentFuzzyQuery,
					variables: agentVariables,
				})
			).InsurancePolicies as Agent[]
		).map((agent) => `${agent.agentFirstName} ${agent.agentLastName}`);

		const agentSet = new Set(agentsArr);

		for (const agent of agentSet) {
			const obj = {
				type: 'Agent',
				name: agent,
			};

			agentResults.push(obj);
		}

		// get fuzzy match state
		const stateVariables = {
			where: {
				OR: [{ abbreviation: { contains: searchText } }, { name: { contains: searchText } }, { id: { contains: searchText } }],
			},
		};

		const states = (
			await this.apollo.watchQuery({
				query: stateFuzzyQuery,
				variables: stateVariables,
			})
		).States as State[];

		states.forEach((state) => {
			const obj = {
				type: 'State',
				id: state.id,
				name: state.name,
			};
			stateResults.push(obj);
		});

		// get search API matches
		const variables = {
			query: searchText,
			typesToInclude: ['Aip'],
		};

		searchApiResults = (await this.apollo.watchQuery({ query: searchQuery, variables })).Search;

		// return combined set
		return [...agentResults, ...searchApiResults, ...stateResults];
	}

	@action
	structureSearchResults(searchResults: searchResult[]) {
		const map = new Map();

		searchResults.forEach((item) => {
			if (map.has(item.type)) {
				map.get(item.type).push({ id: item.id, name: item.name, type: item.type });
			} else {
				map.set(item.type, [{ id: item.id, name: item.name, type: item.type }]);
			}
		});

		return map;
	}

	@action
	setSearchFilterQueryParam(searchResult: searchResult) {
		const mappedSearchFilter = this.mapSearchResult(searchResult);
		if (mappedSearchFilter) {
			this[mappedSearchFilter.filterIdentifier] = mappedSearchFilter.filterValue;
		}
		this.setTablePageState();
	}

	mapSearchResult(searchResult: searchResult): { filterIdentifier: SearchFilterIdentifier; filterValue: string } | null {
		let filterIdentifier: SearchFilterIdentifier;

		switch (searchResult.type) {
			case 'Agent':
				filterIdentifier = 'agent';
				break;
			case 'Aip':
				filterIdentifier = 'aipId';
				break;
			case 'Customer':
				filterIdentifier = 'customerId';
				break;
			case 'State':
				filterIdentifier = 'stateId';
				break;
			default:
				return null;
		}

		if (searchResult.id || searchResult.name) {
			return {
				filterIdentifier,
				filterValue: (searchResult.id || searchResult.name) as string,
			};
		}

		return null;
	}

	@action
	clearSearchFilterQueryParam(filterIdentifier: string) {
		if (isSearchFilterIdentifier(filterIdentifier)) {
			this[filterIdentifier] = null;
			this.setTablePageState();
		}
	}

	@action
	setDateFilterQueryParam(queryParam: DateFilterQueryParamPrefix, value: { startDate: string; endDate: string }) {
		this[`${queryParam}StartDate`] = value.startDate;
		this[`${queryParam}EndDate`] = value.endDate;
		this.setTablePageState();
	}

	@action
	setTablePageState(newPageVal?: number) {
		this.page = newPageVal ?? 0;
		resetVaultTableScroll('lrp-endorsement-table');
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'lrp-insurance-endorsements/index': LRPInsuranceEndorsementsIndexController;
	}
}
