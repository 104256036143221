import { gql } from 'glimmer-apollo';
import type { FeedIngredient, Query_FeedIngredientsArgs } from 'vault-client/types/graphql-types';

export const GET_FEED_INGREDIENTS = gql`
	query FeedIngredients(
		$distinctOn: FeedIngredientDistinctOnDTO
		$limit: Float
		$offset: Float
		$orderBy: FeedIngredientSortByDTO
		$pagination: PaginationInput
		$scopeId: String
		$sortBy: [FeedIngredientSortByDTO!]
		$where: FeedIngredientFilterDTO
	) {
		FeedIngredients(
			distinctOn: $distinctOn
			limit: $limit
			offset: $offset
			orderBy: $orderBy
			pagination: $pagination
			scopeId: $scopeId
			sortBy: $sortBy
			where: $where
		) {
			id
			name
		}
	}
`;

export type FeedIngredientsQueryVariables = Query_FeedIngredientsArgs;

export type FeedIngredientsQuery = {
	FeedIngredients: Array<Pick<FeedIngredient, 'id' | 'name'>>;
};
