import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class SuccessHandler extends Service {
	@tracked showSuccessToast = false;
	@tracked message: string = '';
	timeoutId: ReturnType<typeof setTimeout> | null = null;

	constructor(properties?: object) {
		super(properties);
		this.showSuccessToast = false;
		this.message = '';
	}

	@action
	showSuccess(message: string) {
		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
			this.timeoutId = null;
		}

		// Force a new render cycle by creating new references
		this.message = message;
		this.showSuccessToast = true;

		this.timeoutId = setTimeout(() => {
			this.toggleSuccess();
		}, 8000);
	}

	@action
	toggleSuccess() {
		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
			this.timeoutId = null;
		}

		// Force a new render cycle by creating new references
		this.showSuccessToast = !this.showSuccessToast;
		if (!this.showSuccessToast) {
			this.message = '';
		}
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
	interface Registry {
		'success-handler': SuccessHandler;
	}
}
