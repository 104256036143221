import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='h-96 relative'>\n\t<Chart\n\t\t@type='bubble'\n\t\t@id={{@chartId}}\n\t\t@legendId={{@legendId}}\n\t\t@data={{@data}}\n\t\t@chartData={{this.chartData}}\n\t\t@options={{this.chartOptions}}\n\t\t@months={{@months}}\n\t\t@updateChart={{this.updateChart}}\n\t/>\n</div>", {"contents":"<div class='h-96 relative'>\n\t<Chart\n\t\t@type='bubble'\n\t\t@id={{@chartId}}\n\t\t@legendId={{@legendId}}\n\t\t@data={{@data}}\n\t\t@chartData={{this.chartData}}\n\t\t@options={{this.chartOptions}}\n\t\t@months={{@months}}\n\t\t@updateChart={{this.updateChart}}\n\t/>\n</div>","moduleName":"vault-client/components/contract-analysis-chart.hbs","parseOptions":{"srcName":"vault-client/components/contract-analysis-chart.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { type Chart, ChartData, ChartOptions, LegendItem, LegendOptions } from 'chart.js';
import { multilineTooltip, smartUpdateChartData } from 'vault-client/utils/chart-utils';
import getCSSVariable from 'vault-client/utils/get-css-variable';

interface ContractAnalysisChartDataItem {
	label: string;
	data: any[];
	borderColor: string;
	backgroundColor: string;
	tooltipData: {};
	title: string[];
}

interface ContractAnalysisChartArgs {
	data: ContractAnalysisChartDataItem[];
	legendId: string;
	chartId: string;
	months: string[];
	minTradingRange: number;
	maxTradingRange: number;
}

const COLOR_MAP: Record<string, string> = {
	Flat: getCSSVariable('--brand-interactive-blue-70'),
	HTA: getCSSVariable('--brand-teal-60'),
	Basis: getCSSVariable('--brand-lime-50'),
	'Trading Range': getCSSVariable('--brand-interactive-blue-20'),
};

export default class ContractAnalysisChartComponent extends Component<ContractAnalysisChartArgs> {
	get isTradingRangeValid() {
		const { minTradingRange, maxTradingRange } = this.args;
		return minTradingRange !== 0 || maxTradingRange !== 0;
	}

	get chartData(): ChartData {
		const { isTradingRangeValid } = this;

		const datasets = this.args.data.map((item) => {
			const stringLabel = item.label.split(' $')[0];
			return {
				label: item.label,
				data: item.data,
				backgroundColor: COLOR_MAP[stringLabel ? stringLabel : item.label],
				borderColor: getCSSVariable('--brand-black'),
				hidden: stringLabel === 'Trading Range' ? !isTradingRangeValid : false,
			};
		});

		return {
			labels: this.args.months,
			datasets,
		};
	}

	get chartOptions() {
		const { minTradingRange = 0, maxTradingRange = 0 } = this.args;
		const { onLegendClick, isTradingRangeValid } = this;

		return {
			responsive: true,
			maintainAspectRatio: false,
			animation: {
				duration: 500,
			},
			datasets: {
				bar: {
					borderRadius: 2,
					categoryPercentage: 0.8,
				},
			},
			scales: {
				y: {
					type: 'linear',
					grace: '20%',
					ticks: {
						autoSkip: false,
						callback: (value: number) => {
							return new Intl.NumberFormat(undefined, {
								style: 'currency',
								currency: 'USD',
							}).format(value);
						},
					},
				},
				x: {
					type: 'time',
					time: {
						unit: 'month',
					},
				},
			},
			hover: {
				mode: 'nearest',
			},
			plugins: {
				legend: {
					onClick: onLegendClick,
				},
				tooltip: {
					mode: 'nearest',
					external: multilineTooltip,
					enabled: false,
					displayColors: false,
					position: 'nearest',
					xAlign: 'center',
					yAlign: 'top',
				},
				annotation: {
					annotations: {
						box: {
							drawTime: 'beforeDraw',
							display: isTradingRangeValid,
							type: 'box',
							yMin: minTradingRange,
							yMax: maxTradingRange,
							backgroundColor: getCSSVariable('--brand-interactive-blue-20'),
						},
					},
				},
			},
		} satisfies ChartOptions<'line' | 'bubble'>;
	}

	@action
	updateChart(chart: Chart) {
		const { chartData } = this;
		smartUpdateChartData(chart, chartData);
	}

	onLegendClick: LegendOptions<'line' | 'bubble'>['onClick'] = (_e, legendItem, legend) => {
		const { chart } = legend;
		const { datasetIndex } = legendItem;
		const { isTradingRangeValid, isTradingRangeLegendItem } = this;

		if (datasetIndex == undefined) return;

		const isDatasetVisible = chart.isDatasetVisible(datasetIndex);

		if (isTradingRangeLegendItem(legendItem)) {
			const shouldDisplayTradingRange = !isDatasetVisible && isTradingRangeValid;

			(chart.options as typeof this.chartOptions).plugins.annotation.annotations.box.display = shouldDisplayTradingRange;
			chart.setDatasetVisibility(datasetIndex, shouldDisplayTradingRange);
		} else {
			chart.setDatasetVisibility(datasetIndex, !isDatasetVisible);
		}

		chart.update();
	};

	isTradingRangeLegendItem = (legendItem: LegendItem) => {
		return legendItem.text.toLowerCase().includes('trading range');
	};
}
