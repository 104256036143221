import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='h-full' ...attributes>\n\t{{#if (eq 'Revenues' @chartType)}}\n\t\t<HarvestPnlBarChart::Revenues\n\t\t\t@id={{@revenuesChartId}}\n\t\t\t@data={{@projectedRevenueChartRawData}}\n\t\t\t@generateTooltipOptions={{this.generateTooltipOptions}}\n\t\t\t@generateChartOptions={{this.generateChartOptions}}\n\t\t/>\n\t{{else if (eq 'Expenses' @chartType)}}\n\t\t<HarvestPnlBarChart::Expenses\n\t\t\t@id={{@expensesChartId}}\n\t\t\t@data={{@projectedExpensesChartRawData}}\n\t\t\t@generateTooltipOptions={{this.generateTooltipOptions}}\n\t\t\t@generateChartOptions={{this.generateChartOptions}}\n\t\t/>\n\t{{else if (eq 'Net P/L' @chartType)}}\n\t\t<HarvestPnlBarChart::NetPnl\n\t\t\t@id={{@pnlChartId}}\n\t\t\t@data={{@projectedPnlChartRawData}}\n\t\t\t@generateTooltipOptions={{this.generateTooltipOptions}}\n\t\t\t@generateChartOptions={{this.generateChartOptions}}\n\t\t/>\n\t{{/if}}\n</div>", {"contents":"<div class='h-full' ...attributes>\n\t{{#if (eq 'Revenues' @chartType)}}\n\t\t<HarvestPnlBarChart::Revenues\n\t\t\t@id={{@revenuesChartId}}\n\t\t\t@data={{@projectedRevenueChartRawData}}\n\t\t\t@generateTooltipOptions={{this.generateTooltipOptions}}\n\t\t\t@generateChartOptions={{this.generateChartOptions}}\n\t\t/>\n\t{{else if (eq 'Expenses' @chartType)}}\n\t\t<HarvestPnlBarChart::Expenses\n\t\t\t@id={{@expensesChartId}}\n\t\t\t@data={{@projectedExpensesChartRawData}}\n\t\t\t@generateTooltipOptions={{this.generateTooltipOptions}}\n\t\t\t@generateChartOptions={{this.generateChartOptions}}\n\t\t/>\n\t{{else if (eq 'Net P/L' @chartType)}}\n\t\t<HarvestPnlBarChart::NetPnl\n\t\t\t@id={{@pnlChartId}}\n\t\t\t@data={{@projectedPnlChartRawData}}\n\t\t\t@generateTooltipOptions={{this.generateTooltipOptions}}\n\t\t\t@generateChartOptions={{this.generateChartOptions}}\n\t\t/>\n\t{{/if}}\n</div>","moduleName":"vault-client/components/harvest-pnl-bar-chart.hbs","parseOptions":{"srcName":"vault-client/components/harvest-pnl-bar-chart.hbs"}});
import Component from '@glimmer/component';
import { ChartOptions, type TooltipItem } from 'chart.js';
import { getCustomTooltip, type CustomTooltipOptions } from 'vault-client/utils/chart-utils';

import getCSSVariable from 'vault-client/utils/get-css-variable';
import type { ProjectedExpensesChartRawData } from './harvest-pnl-bar-chart/expenses';
import type { ProjectedNetPnlChartRawData } from './harvest-pnl-bar-chart/net-pnl';
import type { ProjectedRevenueChartRawData } from './harvest-pnl-bar-chart/revenues';
import { showLabelsForTallBars } from 'vault-client/utils/chart/bar-chart';
export const chartTypes = ['Revenues', 'Expenses', 'Net P/L'] as const;
type ChartType = (typeof chartTypes)[number];

type GenerateChartOptions = HarvestPnlBarChart['generateChartOptions'];
type GenerateTooltipOptions = HarvestPnlBarChart['generateTooltipOptions'];

export type { GenerateChartOptions, GenerateTooltipOptions, ChartType };

interface HarvestPnlBarChartArgs {
	chartType: ChartType;
	expensesChartId: string;
	revenuesChartId: string;
	pnlChartId: string;
	projectedRevenueChartRawData: ProjectedRevenueChartRawData;
	projectedExpensesChartRawData: ProjectedExpensesChartRawData;
	projectedPnlChartRawData: ProjectedNetPnlChartRawData;
}

export default class HarvestPnlBarChart extends Component<HarvestPnlBarChartArgs> {
	yScaleTextColor = getCSSVariable('--brand-gray-60');

	generateTooltipOptions = (
		{
			dataPointFilter,
		}: {
			dataPointFilter?: (tooltipItem: TooltipItem<'bar'>) => boolean;
		} = { dataPointFilter: undefined },
	): CustomTooltipOptions => {
		return {
			valueFormatter: (val: number) => {
				return new Intl.NumberFormat('en-US', {
					style: 'currency',
					currency: 'USD',
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				}).format(val);
			},
			dataPointFilter,
		};
	};

	generateChartOptions = ({ tooltipOptions }: { tooltipOptions: CustomTooltipOptions }): ChartOptions<'bar' | 'line'> => {
		const { yScaleTextColor } = this;

		return {
			maintainAspectRatio: false,
			responsive: true,
			animation: false,
			interaction: {
				intersect: false,
				mode: 'index',
			},
			layout: {
				padding: {
					top: 12,
					left: 12,
					right: 12,
				},
			},
			datasets: {
				bar: {
					borderRadius: 2,
					categoryPercentage: 0.8,
				},
			},
			scales: {
				y: {
					grid: {
						display: true,
					},
					stacked: true,
					ticks: {
						autoSkip: true,
						font: {
							size: 12,
						},
						callback: (val: string | number) => {
							if (typeof val === 'string') {
								return val;
							} else {
								return new Intl.NumberFormat('en-US', {
									style: 'currency',
									currency: 'USD',
									notation: 'compact',
									compactDisplay: 'short',
								}).format(val);
							}
						},
						color: yScaleTextColor,
					},
				},
				x: {
					stacked: true,
					grid: {
						display: false,
					},
				},
			},
			plugins: {
				legend: {
					display: false,
				},
				tooltip: {
					enabled: false,
					external: getCustomTooltip(tooltipOptions),
				},
				datalabels: {
					color: 'white',
					display: showLabelsForTallBars(),
					formatter: function (value: number) {
						return new Intl.NumberFormat('en-US', {
							style: 'currency',
							currency: 'USD',
							notation: 'compact',
							compactDisplay: 'short',
						}).format(value);
					},
				},
			},
		};
	};
}
