import { QueryOptions, useQuery } from 'glimmer-apollo';
import { GET_CATTLE_FEED_RATIONS } from 'vault-client/graphql/queries/businesses/business/cattle/rations';
import type { Query_CattleFeedRationsArgs } from 'vault-client/types/graphql-types';

interface CattleFeedRationsData {
	CattleFeedRations: Array<{
		id: string;
		name: string;
		description: string;
		businessId: string;
		createdAt: string;
		updatedAt: string;
	}>;
}

export function useRationsQuery(context: object, options: QueryOptions<CattleFeedRationsData, Query_CattleFeedRationsArgs>) {
	return useQuery<CattleFeedRationsData, Query_CattleFeedRationsArgs>(context, () => [GET_CATTLE_FEED_RATIONS, options]);
}
