import { QueryOptions, useQuery } from 'glimmer-apollo';
import {
	GET_CATTLE_FEED_RATION,
	type CattleFeedRationQuery,
	type CattleFeedRationQueryVariables,
} from 'vault-client/graphql/queries/businesses/business/cattle/ration-detail';

export function useRationDetailQuery(context: object, options: QueryOptions<CattleFeedRationQuery, CattleFeedRationQueryVariables>) {
	return useQuery<CattleFeedRationQuery, CattleFeedRationQueryVariables>(context, () => [GET_CATTLE_FEED_RATION, options]);
}
