import { isEmpty } from '@ember/utils';

/**
 * Converts an empty argument to undefined.
 * isEmpty is used to check if the argument is empty
 *
 * @param value - The value to convert.
 * @returns The value or undefined if it is empty.
 */
export function emptyToUndefined<TArg>(value: TArg): TArg | undefined {
	return isEmpty(value) ? undefined : value;
}
