import Route from '@ember/routing/route';
import Transition from '@ember/routing/transition';
import BusinessesBusinessCattleLotsController from 'vault-client/controllers/businesses/business/cattle/lots';
import { GET_CATTLE_LOTS_QUERY } from 'vault-client/graphql/queries/businesses/business/cattle/lots';
import { Query_CattleLotsArgs } from 'vault-client/types/graphql-types';
import { SortObj } from 'vault-client/types/vault-table';
import { useLotsOverviewQuery, useLotsOverviewCountByBusiness } from 'vault-client/utils/cattle/lots/lots-overview';
import { generateSortBy } from 'vault-client/utils/general';

type Params = {
	page: number;
	size: number;
	sorts: SortObj[];
	cattleYard: string | null;
	name: string | null;
	cattleFeedRation: string | null;
	activeStartDateStart: string | null;
	activeStartDateEnd: string | null;
	activeEndDateStart: string | null;
	activeEndDateEnd: string | null;
};

export default class BusinessesBusinessCattleLotOverviewRoute extends Route {
	queryParams = {
		page: {
			refreshModel: true,
		},
		size: {
			refreshModel: true,
		},
		sorts: {
			refreshModel: true,
		},
		cattleYard: {
			refreshModel: true,
		},
		name: {
			refreshModel: true,
		},
		cattleFeedRation: {
			refreshModel: true,
		},
		activeStartDateStart: {
			refreshModel: true,
		},
		activeStartDateEnd: {
			refreshModel: true,
		},
		activeEndDateStart: {
			refreshModel: true,
		},
		activeEndDateEnd: {
			refreshModel: true,
		},
	};

	async model(params: Params) {
		const { business_id: businessId } = this.paramsFor('businesses.business') as { business_id: string };
		const variables: Query_CattleLotsArgs = {
			scopeId: businessId,
			offset: params.page * params.size,
			limit: params.size,
			sortBy: generateSortBy(params.sorts),
			where: {
				...(params.name && {
					name: { equals: params.name },
				}),
				...(params.cattleYard && {
					CattleYard: {
						name: { equals: params.cattleYard },
					},
				}),
				...(params.cattleFeedRation && {
					CattleFeedRation: {
						name: { equals: params.cattleFeedRation },
					},
				}),
				...((params.activeStartDateStart || params.activeStartDateEnd) && {
					activeStartDate: {
						...(params.activeStartDateStart && { gte: params.activeStartDateStart }),
						...(params.activeStartDateEnd && { lte: params.activeStartDateEnd }),
					},
				}),
				...((params.activeEndDateStart || params.activeEndDateEnd) && {
					activeEndDate: {
						...(params.activeEndDateStart && { gte: params.activeEndDateStart }),
						...(params.activeEndDateEnd && { lte: params.activeEndDateEnd }),
					},
				}),
			},
		};

		const lotsCountByBusinessVars = { scopeId: businessId };

		const lotsOverview = useLotsOverviewQuery(this, {
			variables: {
				...variables,
				rationUsageWhere: {
					monthStartDate: {
						gte: params.activeStartDateStart || undefined,
						lte: params.activeEndDateEnd || undefined,
					},
				},
			},
		});

		const lotsCountByBusiness = useLotsOverviewCountByBusiness(this, {
			variables: lotsCountByBusinessVars,
		});

		await Promise.all([lotsOverview.promise, lotsCountByBusiness.promise]);

		return {
			lotsOverview,
			lotsCountByBusiness,
			businessId: businessId,
			variables,
			query: GET_CATTLE_LOTS_QUERY,
		};
	}

	resetController(controller: BusinessesBusinessCattleLotsController, isExiting: boolean, transition: Transition) {
		super.resetController(controller, isExiting, transition);
		if (isExiting) {
			controller.setSidePanelState({ state: null });
		}
	}
}
