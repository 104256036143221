import Route from '@ember/routing/route';
import Transition from '@ember/routing/transition';
import { RouteQueryParam } from '@ember/routing/types';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { DateTime } from 'luxon';
import FeedIngredientDetailController from 'vault-client/controllers/feed/ingredients/detail';
import BusinessesBusinessFeedIngredientDetailRoute from 'vault-client/routes/businesses/business/feed-ingredient';
import MarketDataService from 'vault-client/services/market-data';
import { FeedIngredientFilterDTO, FutureFilterDTO, Query } from 'vault-client/types/graphql-types';
import { FeedIngredientBase } from 'vault-client/types/vault-client';
import { getCurrentUnitOfMeasure, getLocallyStoredFeedIngredientValue } from 'vault-client/utils/feed-utils';
import { ModelFrom } from 'vault-client/utils/type-utils';

export type FeedIngredientQueryArgs = {
	id: string;
	feedIngredientsWhere?: FeedIngredientFilterDTO;
};

export type GetFeedIngredientQuery = {
	__typename?: 'Query';
	FeedIngredient: Query['FeedIngredient'];
	FeedIngredients: Query['FeedIngredients'];
};

export type GetFeedUsageQueryArgs = {
	scopeId: string;
	feedIngredientId: string;
	feedCategoryId: string;
	startDate: string;
	endDate: string;
	futuresWhere?: FutureFilterDTO;
};

export type GetFeedUsageQuery = {
	__typename?: 'Query';
	FeedIngredientConsumedAndPurchasedVolumes: Query['FeedIngredientConsumedAndPurchasedVolumes'];
	SwineLivestockGroupFeedUsages: Query['SwineLivestockGroupFeedUsages'];
	FeedIngredientUsages: Query['FeedIngredientUsages'];
	Futures: Query['Futures'];
	FeedTransactions: Query['FeedTransactions'];
	SwineLivestockGroups: Query['SwineLivestockGroups'];
	GrainTargetOrders: Query['GrainTargetOrders'];
};

export default class FeedIngredientDetailRoute extends Route {
	@service declare marketData: MarketDataService;

	@tracked feedIngredientVariables: FeedIngredientQueryArgs = { id: '' };
	@tracked feedUsageVariables: GetFeedUsageQueryArgs = {
		scopeId: '',
		feedIngredientId: '',
		feedCategoryId: '',
		startDate: '',
		endDate: '',
	};

	templateName = 'feed/ingredients/detail';

	queryParams: { [key: string]: RouteQueryParam } = {
		startDate: { refreshModel: true },
		endDate: { refreshModel: true },
	};

	generateFeedIngredientsWhere(businessId: string | null) {
		const where: FeedIngredientFilterDTO = {};

		if (businessId) {
			where.customerId = { equals: businessId };
		}

		return where;
	}

	generateFuturesWhere(ingredientBase: FeedIngredientBase, startDate: string, endDate: string) {
		const where: FutureFilterDTO = {};
		// Ensure we always get the next available future, regardless of selected time frame
		const adjustedStartDate = DateTime.fromISO(startDate).minus({ quarters: 1 }).startOf('quarter').toISODate();
		const adjustedEndDate = DateTime.fromISO(endDate).plus({ quarters: 2 }).endOf('quarter').toISODate();
		where.displayExpiresAt = { gte: adjustedStartDate, lte: adjustedEndDate };
		where.isStandardContractSize = { equals: true };
		if (ingredientBase === FeedIngredientBase.Corn) {
			where.Product = { slug: { equals: 'grain-corn' } };
		} else if (ingredientBase === FeedIngredientBase.SoybeanMeal) {
			where.Product = { slug: { equals: 'grain-soybean-meal' } };
		} else {
			where.Product = { slug: { equals: null } };
		}

		return where;
	}

	setupController(
		controller: FeedIngredientDetailController,
		model: ModelFrom<BusinessesBusinessFeedIngredientDetailRoute>,
		transition: Transition<unknown>,
	): void {
		super.setupController(controller, model, transition);

		// Retrieve unit of measure from local storage, or set to default
		const hedgeProductSlug = model.getFeedIngredient.data?.FeedIngredient?.FeedCategory?.HedgeProduct?.slug ?? null;
		const customerId = model.getCustomer.data?.Customer?.id ?? null;
		const ingredientId = model.getFeedIngredient.data?.FeedIngredient?.id ?? null;

		const locallyStoredUOM = getCurrentUnitOfMeasure(ingredientId, hedgeProductSlug, customerId);
		controller.currentUnitOfMeasure.value = locallyStoredUOM;

		const locallyStoredShowPercentPurchasedByMonthTable = getLocallyStoredFeedIngredientValue<boolean>(
			'showPercentPurchasedByMonthTable',
			ingredientId,
			customerId,
		);

		// Use default value of false if the ingredient has not had showPercentPurchasedByMonthTable set locally
		controller.set('showPercentPurchasedByMonthTable', locallyStoredShowPercentPurchasedByMonthTable ?? false);
	}

	resetController(controller: FeedIngredientDetailController) {
		// Reset side panel state
		controller.resetSidePanelState();
		controller.currentUnitOfMeasure.reset();

		// Unregister futures when ingredient changes or route is exited
		Object.keys(controller.registeredFutures).forEach((barchartSymbol) => {
			if (this.marketData.getMarketDatum(barchartSymbol)) {
				this.marketData.unregister(barchartSymbol);
			}
		});

		controller.registeredFutures = {};
	}
}
