import { useQuery, type QueryOptions } from 'glimmer-apollo';
import {
	GET_FEED_INGREDIENTS,
	type FeedIngredientsQuery,
	type FeedIngredientsQueryVariables,
} from 'vault-client/graphql/queries/feed/ingredients';

export function useGetFeedIngredientsQuery(context: object, options: QueryOptions<FeedIngredientsQuery, FeedIngredientsQueryVariables>) {
	return useQuery<FeedIngredientsQuery, FeedIngredientsQueryVariables>(context, () => [GET_FEED_INGREDIENTS, options]);
}
