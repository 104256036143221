import Controller from '@ember/controller';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { DateTime } from 'luxon';
import { tracked } from 'tracked-built-ins';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import { ModelFrom } from 'vault-client/utils/type-utils';
import { getCustomTooltip, CustomTooltipOptions, getCustomLegend } from 'vault-client/utils/chart-utils';
import ENV from 'vault-client/config/environment';
import { Future, TypeOfInstrument, TypeOfLivestockPopulationChangeReason, TypeOfOption } from 'vault-client/types/graphql-types';
import BusinessesBusinessPigDashboardRoute from 'vault-client/routes/businesses/business/pig-dashboard';
import { ChartOptions, ChartData, ChartDataset, Chart } from 'chart.js';
import { roundTo } from 'vault-client/utils/round-to';
import { getExpectedMarketingStartOfMonth } from 'vault-client/utils/swine/date';

enum DisplayUnits {
	Total = 'Total',
	PerHead = 'Per Head',
	PerCWT = 'Per CWT',
}

export interface ProjectedPnlRow {
	date: string;
	swineSalesRevenue: null | number;
	cmeSwinePnl: null | number;
	otherRevenue: null | number;
	insurancePnl: null | number;
	feedExpenses: null | number;
	cmeFeedPnl: null | number;
	otherExpenses: null | number;
	netPnl: null | number;
}

export interface PnlMonthArgs {
	date: string;
	numPigs: number | null;
	avgLbsPerHead: number | null;
	swineSales: number | null;
	swinePurchases: number | null;
	cmeSwineRevenue: number | null;
	cmeSwineHedged: number | null;
	nonFeedRevenue: number | null;
	insurancePnl: number | null;
	feedExpenses: number | null;
	cmeFeedRevenue: number | null;
	cmeFeedHedged: number | null;
	nonFeedExpenses: number | null;
}

export class PnlMonth {
	id: string;
	date: string;
	swineSales: number | null = null;
	swinePurchases: number | null = null;
	cmeSwineRevenue: number | null = null;
	cmeSwineHedged: number | null = null;
	nonFeedRevenue: number | null = null;
	insurancePnl: number | null = null;
	feedExpenses: number | null = null;
	cmeFeedRevenue: number | null = null;
	cmeFeedHedged: number | null = null;
	nonFeedExpenses: number | null = null;
	numPigs: number | null = null;
	avgLbsPerHead: number | null = null;

	constructor(
		date: string,
		avgLbsPerHead: number,
		numPigs: number | null,
		swineSales: number | null,
		swinePurchases: number | null,
		cmeSwineRevenue: number | null,
		cmeSwineHedged: number | null,
		nonFeedRevenue: number | null,
		insurancePnl: number | null,
		feedExpenses: number | null,
		cmeFeedRevenue: number | null,
		cmeFeedHedged: number | null,
		nonFeedExpenses: number | null,
	) {
		this.id = date;
		this.date = date;
		this.numPigs = numPigs;
		this.avgLbsPerHead = avgLbsPerHead ?? 0;
		this.swineSales = swineSales;
		this.swinePurchases = swinePurchases;
		this.cmeSwineRevenue = cmeSwineRevenue;
		this.cmeSwineHedged = cmeSwineHedged;
		this.nonFeedRevenue = nonFeedRevenue;
		this.insurancePnl = insurancePnl;
		this.feedExpenses = feedExpenses;
		this.cmeFeedRevenue = cmeFeedRevenue;
		this.cmeFeedHedged = cmeFeedHedged;
		this.nonFeedExpenses = nonFeedExpenses;
	}

	get netPnl() {
		if (this.totalRevenue == null && this.totalHedged == null && this.totalExpenses == null) return null;
		return (this.totalRevenue ?? 0) + (this.totalHedged ?? 0) + (this.totalExpenses ?? 0);
	}

	get netPnlPerHead() {
		return this.getValuePerHead(this.netPnl);
	}

	get netPnlCwt() {
		return this.getValuePerCwt(this.netPnl);
	}

	get otherRevenue() {
		if (this.nonFeedRevenue == null) return null;
		return this.nonFeedRevenue ?? 0;
	}

	get otherRevenuePerHead() {
		return this.getValuePerHead(this.otherRevenue);
	}

	get otherRevenueCwt() {
		return this.getValuePerCwt(this.otherRevenue);
	}

	get totalRevenue() {
		if (this.swineSales == null && this.otherRevenue == null) return null;

		return (this.swineSales ?? 0) + (this.otherRevenue ?? 0);
	}

	get totalRevenuePerHead() {
		return this.getValuePerHead(this.totalRevenue);
	}

	get totalRevenueCwt() {
		return this.getValuePerCwt(this.totalRevenue);
	}

	get totalHedged() {
		if (this.cmeSwine == null && this.cmeFeed == null && this.insurancePnl == null) return null;
		return (this.cmeSwine ?? 0) + (this.cmeFeed ?? 0) + (this.insurancePnl ?? 0);
	}

	get totalHedgedPerHead() {
		return this.getValuePerHead(this.totalHedged);
	}

	get totalHedgedCwt() {
		return this.getValuePerCwt(this.totalHedged);
	}

	get otherExpenses() {
		if (this.nonFeedExpenses == null && this.swinePurchases == null) return null;
		return (this.nonFeedExpenses ?? 0) + (this.swinePurchases ?? 0);
	}

	get otherExpensesPerHead() {
		return this.getValuePerHead(this.otherExpenses);
	}

	get otherExpensesCwt() {
		return this.getValuePerCwt(this.otherExpenses);
	}

	get totalExpenses() {
		if (this.feedExpenses == null && this.otherExpenses === null) return null;
		return (this.feedExpenses ?? 0) + (this.otherExpenses ?? 0);
	}

	get totalExpensesPerHead() {
		return this.getValuePerHead(this.totalExpenses);
	}

	get totalExpensesCwt() {
		return this.getValuePerCwt(this.totalExpenses);
	}

	get cmeSwine() {
		if (this.cmeSwineHedged == null && this.cmeSwineRevenue == null) return null;
		return (this.cmeSwineHedged ?? 0) + (this.cmeSwineRevenue ?? 0);
	}

	get cmeSwinePerHead() {
		return this.getValuePerHead(this.cmeSwine);
	}

	get cmeSwineCwt() {
		return this.getValuePerCwt(this.cmeSwine);
	}

	get cmeFeed() {
		if (this.cmeFeedHedged == null && this.cmeFeedRevenue == null) return null;
		return (this.cmeFeedHedged ?? 0) + (this.cmeFeedRevenue ?? 0);
	}

	get cmeFeedPerHead() {
		return this.getValuePerHead(this.cmeFeed);
	}

	get cmeFeedCwt() {
		return this.getValuePerCwt(this.cmeFeed);
	}

	get totalLbs() {
		if (this.numPigs == null || this.avgLbsPerHead == null) return null;
		return this.numPigs * this.avgLbsPerHead;
	}

	get swineSalesPerHead() {
		return this.getValuePerHead(this.swineSales);
	}

	get swineSalesCwt() {
		return this.getValuePerCwt(this.swineSales);
	}

	get swinePurchasesPerHead() {
		return this.getValuePerHead(this.swinePurchases);
	}

	get swinePurchasesCwt() {
		return this.getValuePerCwt(this.swinePurchases);
	}

	get nonFeedRevenuePerHead() {
		return this.getValuePerHead(this.nonFeedRevenue);
	}

	get nonFeedRevenueCwt() {
		return this.getValuePerCwt(this.nonFeedRevenue);
	}

	get nonFeedExpensesPerHead() {
		return this.getValuePerHead(this.nonFeedExpenses);
	}

	get nonFeedExpensesCwt() {
		return this.getValuePerCwt(this.nonFeedExpenses);
	}

	get insurancePnlPerHead() {
		return this.getValuePerHead(this.insurancePnl);
	}

	get insurancePnlCwt() {
		return this.getValuePerCwt(this.insurancePnl);
	}

	get feedExpensesPerHead() {
		return this.getValuePerHead(this.feedExpenses);
	}

	get feedExpensesCwt() {
		return this.getValuePerCwt(this.feedExpenses);
	}

	getValuePerHead(value: number | null) {
		if (value == null || this.numPigs == null) return null;

		if (this.numPigs === 0) return 0;

		return value / this.numPigs;
	}

	getValuePerCwt(value: number | null) {
		if (value == null || this.totalLbs == null) return null;
		if (this.totalLbs === 0) return 0;
		return value / (this.totalLbs / 100);
	}
}

export default class BusinessesBusinessPigsDashboardController extends Controller {
	@tracked selectedDisplayUnit = DisplayUnits.Total;
	@tracked startDate = DateTime.local().startOf('month').toISODate();
	@tracked endDate = DateTime.local().plus({ months: 12 }).endOf('month').toISODate();
	declare model: ModelFrom<BusinessesBusinessPigDashboardRoute>;
	id = guidFor(this);

	get lastUpdatedAtString() {
		const lastUpdatedAt = DateTime.fromISO(this.model.lastUpdatedAt);

		return `Last updated: ${lastUpdatedAt.toLocaleString(DateTime.DATE_SHORT)} at ${lastUpdatedAt.toLocaleString(DateTime.TIME_SIMPLE)}`;
	}

	get projectedPnlChartId() {
		return `projected-Pnl-chart-${this.id}`;
	}

	get projectedPnlLegendId() {
		return `projected-Pnl-chart-legend-${this.id}`;
	}

	get percentHedgedLegendId() {
		return `percent-hedged-chart-legend-${this.id}`;
	}

	get averageFinishWeight() {
		return (
			this.model.getPigsDashboard.data?.Customer.averageFinishWeightInLbs ?? (ENV.APP.DEFAULT_AVG_SWINE_FINISHING_WEIGHT_IN_LBS as number)
		);
	}

	get averageFinishAgeInWeeks() {
		return this.model.getPigsDashboard.data?.Customer.averageFinishAgeInWeeks ?? (ENV.APP.DEFAULT_AVG_SWINE_FINISH_AGE_IN_WEEKS as number);
	}

	get marketPriceGroups() {
		const orderedFutures: Future[] = [];

		const leanHogFutures: Future[] =
			this.model.getPigsDashboard.data?.Products.find((product) => product.slug === 'livestock-lean-hogs')?.CurrentFutures.slice(0, 3) ??
			[];
		const cornFutures: Future[] =
			this.model.getPigsDashboard.data?.Products.find((product) => product.slug === 'grain-corn')?.CurrentFutures.slice(0, 3) ?? [];
		const soybeanMealFutures: Future[] =
			this.model.getPigsDashboard.data?.Products.find((product) => product.slug === 'grain-soybean-meal')?.CurrentFutures.slice(0, 3) ?? [];
		orderedFutures.push(...leanHogFutures, ...cornFutures, ...soybeanMealFutures);

		return [
			{
				name: 'Futures',
				prices: orderedFutures,
			},
		];
	}

	get projectedPnlChartOptions(): ChartOptions<'line'> {
		const tooltipOptions: CustomTooltipOptions = {
			titleFormatter: (val: string) => {
				const date = DateTime.fromISO(val);
				return `${date.monthShort} ${date.year}`;
			},
			valueFormatter: (val: number) => {
				const decimalPlaces = this.selectedDisplayUnit === DisplayUnits.PerHead || this.selectedDisplayUnit === DisplayUnits.PerCWT ? 2 : 0;

				return new Intl.NumberFormat('en-US', {
					style: 'currency',
					currency: 'USD',
					minimumFractionDigits: decimalPlaces,
					maximumFractionDigits: decimalPlaces,
				}).format(val);
			},
		};
		return {
			spanGaps: true,
			maintainAspectRatio: false,
			responsive: true,
			interaction: {
				intersect: false,
				mode: 'index',
			},
			layout: {
				padding: {
					top: 12,
					left: 12,
					right: 12,
				},
			},
			scales: {
				y: {
					grid: {
						display: true,
					},
					ticks: {
						autoSkip: true,
						font: {
							size: 12,
						},
						callback: (val) => {
							if (typeof val === 'string') {
								return val;
							} else {
								return new Intl.NumberFormat('en-US', {
									style: 'currency',
									currency: 'USD',
									notation: 'compact',
								}).format(val);
							}
						},
						color: getComputedStyle(document.documentElement).getPropertyValue('brand-gray-60'),
					},
				},
				x: {
					stacked: true,
					ticks: {
						callback: function (_tickValue, index) {
							const luxonDate = DateTime.fromISO(this.getLabelForValue(index));
							return `${luxonDate.monthShort} ${luxonDate.year}`;
						},
					},
					grid: {
						display: false,
					},
				},
			},
			plugins: {
				legend: {
					display: false,
				},
				tooltip: {
					enabled: false,
					external: getCustomTooltip(tooltipOptions),
				},
			},
		};
	}

	get formattedChartLabels() {
		return this.chartLabels.map((date) => DateTime.fromISO(date).toFormat('MMM y'));
	}

	get chartLabels() {
		const labels: string[] = [];

		let currentDate = DateTime.fromISO(this.startDate);
		const endDate = DateTime.fromISO(this.endDate);

		while (currentDate <= endDate) {
			labels.push(currentDate.toISODate());
			currentDate = currentDate.plus({ month: 1 }).startOf('month');
		}

		return labels;
	}

	get pnlMonths() {
		const pnlMonthsMap = new Map<string, PnlMonthArgs>();

		const getPnlMonthArgs = (date: string) => {
			if (pnlMonthsMap.has(date)) {
				return pnlMonthsMap.get(date) as PnlMonthArgs;
			}

			return;
		};

		let currDateTime = DateTime.fromISO(this.startDate).startOf('month');
		const endDateTime = DateTime.fromISO(this.endDate).startOf('month');

		while (currDateTime <= endDateTime) {
			const date = currDateTime.toISODate();
			const pnlMonthArgs: PnlMonthArgs = {
				date,
				avgLbsPerHead: this.model.getPigsDashboard.data?.Customer.averageFinishWeightInLbs ?? 0,
				numPigs: null,
				swineSales: null,
				swinePurchases: null,
				cmeSwineRevenue: null,
				cmeSwineHedged: null,
				nonFeedRevenue: null,
				insurancePnl: null,
				feedExpenses: null,
				cmeFeedRevenue: null,
				cmeFeedHedged: null,
				nonFeedExpenses: null,
			};

			pnlMonthsMap.set(date, pnlMonthArgs);
			currDateTime = currDateTime.plus({ month: 1 }).startOf('month');
		}

		this.model.getPigsDashboard.data?.AggregateCurrentAllocationPositions.forEach((currentPosition) => {
			const date = currentPosition.effectiveHedgeDate;
			const quantity = currentPosition.sum.contractQuantity;
			const instrumentType = currentPosition.instrumentType;
			const optionType = currentPosition.optionType;
			const productSlug = currentPosition?.Product?.slug;
			if (!date) return;

			const startOfMonth = DateTime.fromISO(date).startOf('month').toISODate();
			const pnlMonth = getPnlMonthArgs(startOfMonth);
			if (!pnlMonth) return;

			const grossPnl = currentPosition.sum.grossPnl;
			if (grossPnl == null) return;

			if (productSlug === 'livestock-lean-hogs') {
				if (
					// Short futures and Short Puts are swine hedges, others are revenue
					(quantity != null && instrumentType === TypeOfInstrument.Future && quantity < 0) ||
					(quantity != null && instrumentType === TypeOfInstrument.Option && optionType === TypeOfOption.Put && quantity < 0)
				) {
					pnlMonth.cmeSwineHedged = pnlMonth.cmeSwineHedged ? pnlMonth.cmeSwineHedged + grossPnl : grossPnl;
				} else {
					pnlMonth.cmeSwineRevenue = pnlMonth.cmeSwineRevenue ? pnlMonth.cmeSwineRevenue + grossPnl : grossPnl;
				}
			} else {
				// Feed Related
				if (
					// long futures and long calls are feed hedges, others are revenue
					(quantity != null && instrumentType === TypeOfInstrument.Future && quantity > 0) ||
					(quantity != null && instrumentType === TypeOfInstrument.Option && optionType === TypeOfOption.Call && quantity > 0)
				) {
					pnlMonth.cmeFeedHedged = pnlMonth.cmeFeedHedged ? pnlMonth.cmeFeedHedged + grossPnl : grossPnl;
				} else {
					pnlMonth.cmeFeedRevenue = pnlMonth.cmeFeedRevenue ? pnlMonth.cmeFeedRevenue + grossPnl : grossPnl;
				}
			}
		});

		this.model.getPigsDashboard.data?.AggregateForecastedSwineLivestockFeedUsages.forEach((aggregateFeedUsage) => {
			const dob = aggregateFeedUsage.dob;
			if (!dob) return;

			const expectedMarketingStartOfMonth = getExpectedMarketingStartOfMonth(dob, this.averageFinishAgeInWeeks);
			const totalExpense = aggregateFeedUsage.sum.salesAdjustedTotalExpenseInUsd
				? -aggregateFeedUsage.sum.salesAdjustedTotalExpenseInUsd
				: null;

			if (!expectedMarketingStartOfMonth || totalExpense == null) return;

			const pnlMonth = getPnlMonthArgs(expectedMarketingStartOfMonth);
			if (!pnlMonth) return;

			pnlMonth.feedExpenses = pnlMonth.feedExpenses ? pnlMonth.feedExpenses + totalExpense : totalExpense;
		});

		this.model.getPigsDashboard.data?.AllocatedLrpInsuranceEndorsements.forEach((lrpEndorsement) => {
			const effectiveHedgeDate = lrpEndorsement.effectiveHedgeDate;
			const pnl = lrpEndorsement.RatioAdjustedInsuranceEndorsement.pnl;

			if (!effectiveHedgeDate || pnl == null) return;

			const pnlMonth = getPnlMonthArgs(effectiveHedgeDate);
			if (!pnlMonth) return;
			pnlMonth.insurancePnl = pnlMonth.insurancePnl ? pnlMonth.insurancePnl + pnl : pnl;
		});

		this.model.getPigsDashboard.data?.AllocatedLgmInsuranceEndorsements.forEach((lgmEndorsement) => {
			const effectiveHedgeDate = lgmEndorsement.effectiveHedgeDate;
			const pnl = lgmEndorsement.RatioAdjustedInsuranceEndorsement.pnl;

			if (!effectiveHedgeDate || pnl == null) return;

			const pnlMonth = getPnlMonthArgs(effectiveHedgeDate);
			if (!pnlMonth) return;
			pnlMonth.insurancePnl = pnlMonth.insurancePnl ? pnlMonth.insurancePnl + pnl : pnl;
		});

		this.model.getPigsDashboard.data?.SwineSalesPurchasesAndProduced.forEach((swinePopulationChange) => {
			if (swinePopulationChange.reasonType === TypeOfLivestockPopulationChangeReason.Sale) {
				const saleDate = swinePopulationChange.date;
				const totalValue = swinePopulationChange.sum.totalValue != null ? Math.abs(swinePopulationChange.sum.totalValue) : null;

				if (!saleDate || totalValue == null) return;

				const date = DateTime.fromISO(saleDate).startOf('month').toISODate();
				const pnlMonth = getPnlMonthArgs(date);
				if (!pnlMonth) return;

				const numSold = swinePopulationChange.sum.quantity != null ? Math.abs(swinePopulationChange.sum.quantity) : 0;

				pnlMonth.numPigs = pnlMonth.numPigs ? pnlMonth.numPigs + numSold : numSold;
				pnlMonth.swineSales = pnlMonth.swineSales ? pnlMonth.swineSales + totalValue : totalValue;
			} else if (
				swinePopulationChange.reasonType === TypeOfLivestockPopulationChangeReason.Purchase ||
				swinePopulationChange.reasonType === TypeOfLivestockPopulationChangeReason.Birth
			) {
				const dob = swinePopulationChange.dob;
				if (!dob) return;

				const expectedMarketingDate = DateTime.fromISO(dob).plus({
					weeks: this.averageFinishAgeInWeeks,
				});

				const totalValue = swinePopulationChange.sum.totalValue;

				if (!expectedMarketingDate || totalValue == null) return;

				const date = expectedMarketingDate.startOf('month').toISODate();
				const pnlMonth = getPnlMonthArgs(date);
				if (!pnlMonth) return;

				// Purchases return positive value. Flip sign to treat them as expenses
				pnlMonth.swinePurchases = pnlMonth.swinePurchases ? pnlMonth.swinePurchases - totalValue : -totalValue;
			}
		});

		this.model.getPigsDashboard.data?.AggregateExpenseLedgerEntries.forEach((expense) => {
			const month = expense.month;
			const year = expense.year;
			const expenseAmount = expense.sum.calculatedAmount;

			if (!month || !year || expenseAmount == null) return;

			const date = DateTime.fromObject({ month, year }).toISODate();
			const pnlMonth = getPnlMonthArgs(date);
			if (!pnlMonth) return;

			pnlMonth.nonFeedExpenses = pnlMonth.nonFeedExpenses ? pnlMonth.nonFeedExpenses + expenseAmount : expenseAmount;
		});

		this.model.getPigsDashboard.data?.AggregateRevenueLedgerEntries.forEach((revenue) => {
			const month = revenue.month;
			const year = revenue.year;
			const revenueAmount = revenue.sum.calculatedAmount;

			if (!month || !year || revenueAmount == null) return;

			const date = DateTime.fromObject({ month, year }).toISODate();
			const pnlMonth = getPnlMonthArgs(date);
			if (!pnlMonth) return;

			pnlMonth.nonFeedRevenue = pnlMonth.nonFeedRevenue ? pnlMonth.nonFeedRevenue + revenueAmount : revenueAmount;
		});

		return Array.from(pnlMonthsMap.values())
			.sortBy('date')
			.map((args) => {
				return new PnlMonth(
					args.date,
					args.avgLbsPerHead ?? 0,
					args.numPigs,
					args.swineSales,
					args.swinePurchases,
					args.cmeSwineRevenue,
					args.cmeSwineHedged,
					args.nonFeedRevenue,
					args.insurancePnl,
					args.feedExpenses,
					args.cmeFeedRevenue,
					args.cmeFeedHedged,
					args.nonFeedExpenses,
				);
			});
	}

	get projectedPnlRawData() {
		const data: { 'Net P/L': (number | null)[]; Revenue: (number | null)[]; 'Hedge P/L': (number | null)[]; Costs: (number | null)[] } = {
			'Net P/L': [],
			Revenue: [],
			'Hedge P/L': [],
			Costs: [],
		};

		// Flip costs to display as positive in this chart (requested by Josh Sonnabend)
		this.pnlMonths.forEach((month) => {
			if (this.selectedDisplayUnit === DisplayUnits.Total) {
				data['Net P/L'].push(month.netPnl);
				data.Revenue.push(month.totalRevenue);
				data['Hedge P/L'].push(month.totalHedged);
				data.Costs.push(month.totalExpenses ? -month.totalExpenses : null);
			} else if (this.selectedDisplayUnit === DisplayUnits.PerHead) {
				data['Net P/L'].push(month.netPnlPerHead);
				data.Revenue.push(month.totalRevenuePerHead);
				data['Hedge P/L'].push(month.totalHedgedPerHead);
				data.Costs.push(month.totalExpensesPerHead ? -month.totalExpensesPerHead : null);
			} else if (this.selectedDisplayUnit === DisplayUnits.PerCWT) {
				data['Net P/L'].push(month.netPnlCwt);
				data.Revenue.push(month.totalRevenueCwt);
				data['Hedge P/L'].push(month.totalHedgedCwt);
				data.Costs.push(month.totalExpensesCwt ? -month.totalExpensesCwt : null);
			}
		});

		return data;
	}

	get projectedPnlChartData(): ChartData<'line'> {
		const datasets: ChartDataset<'line', (number | null)[]>[] = [
			{
				label: 'Net P/L',
				data: this.projectedPnlRawData['Net P/L'],
				borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-interactive-blue-70'),
				backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-interactive-blue-70'),
				pointRadius: 0,
				tension: 0.1,
			},
			{
				label: 'Revenue',
				data: this.projectedPnlRawData.Revenue,
				borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-orange-40'),
				backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-orange-40'),
				pointRadius: 0,
				tension: 0.1,
			},
			{
				label: 'Hedge P/L',
				data: this.projectedPnlRawData['Hedge P/L'],
				borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-lime-40'),
				backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-lime-40'),
				pointRadius: 0,
				tension: 0.1,
			},
			{
				label: 'Costs',
				data: this.projectedPnlRawData.Costs,
				borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-lemon-40'),
				backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-lemon-40'),
				pointRadius: 0,
				tension: 0.1,
			},
		];

		return {
			labels: this.chartLabels,
			datasets,
		};
	}

	get projectedPnlChartPlugins() {
		return [
			{
				afterUpdate: getCustomLegend(this.projectedPnlChartId, this.projectedPnlLegendId),
			},
		];
	}

	get percentHedgedChartPlugins() {
		return [
			{
				afterUpdate: getCustomLegend(this.percentHedgedChartId, this.percentHedgedLegendId),
			},
		];
	}

	get percentHedgedColumns(): TableColumn[] {
		const columns = [
			{
				id: '89b7c45f-adc2-4b74-91e0-8192bd0edde7',
				name: 'Date',
				valuePath: 'date',
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '00508b92-2eb1-4a7a-b76e-9dfa80bf2d51',
				name: 'Swine',
				valuePath: 'swinePercentHedged',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'percent',
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				},
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '760d6a3b-09be-4645-87fa-194422109855',
				name: 'Corn',
				valuePath: 'cornPercentHedged',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'percent',
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				},
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'a6ef88bd-4763-4114-bc73-cb9131004e9d',
				name: 'Soybean Meal',
				valuePath: 'soybeanMealPercentHedged',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'percent',
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				},
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
		];

		return columns;
	}

	get projectedPnlRows() {
		return this.pnlMonths.map((month) => {
			const data: ProjectedPnlRow = {
				date: month.date,
				swineSalesRevenue: null,
				cmeSwinePnl: null,
				otherRevenue: null,
				insurancePnl: null,
				feedExpenses: null,
				cmeFeedPnl: null,
				otherExpenses: null,
				netPnl: null,
			};

			if (this.selectedDisplayUnit === DisplayUnits.Total) {
				data.swineSalesRevenue = month.swineSales;
				data.cmeSwinePnl = month.cmeSwine;
				data.otherRevenue = month.otherRevenue;
				data.insurancePnl = month.insurancePnl;
				data.feedExpenses = month.feedExpenses;
				data.cmeFeedPnl = month.cmeFeed;
				data.otherExpenses = month.otherExpenses;
				data.netPnl = month.netPnl;
			} else if (this.selectedDisplayUnit === DisplayUnits.PerHead) {
				data.swineSalesRevenue = month.swineSalesPerHead;
				data.cmeSwinePnl = month.cmeSwinePerHead;
				data.otherRevenue = month.otherRevenuePerHead;
				data.insurancePnl = month.insurancePnlPerHead;
				data.feedExpenses = month.feedExpensesPerHead;
				data.cmeFeedPnl = month.cmeFeedPerHead;
				data.otherExpenses = month.otherExpensesPerHead;
				data.netPnl = month.netPnlPerHead;
			} else if (this.selectedDisplayUnit === DisplayUnits.PerCWT) {
				data.swineSalesRevenue = month.swineSalesCwt;
				data.cmeSwinePnl = month.cmeSwineCwt;
				data.otherRevenue = month.otherRevenueCwt;
				data.insurancePnl = month.insurancePnlCwt;
				data.feedExpenses = month.feedExpensesCwt;
				data.cmeFeedPnl = month.cmeFeedCwt;
				data.otherExpenses = month.otherExpensesCwt;
				data.netPnl = month.netPnlCwt;
			}
			return data;
		});
	}

	get projectedPnlFooterRow(): [Omit<ProjectedPnlRow, 'date'>] {
		const totals: Omit<ProjectedPnlRow, 'date'> = {
			swineSalesRevenue: null,
			cmeSwinePnl: null,
			otherRevenue: null,
			insurancePnl: null,
			feedExpenses: null,
			cmeFeedPnl: null,
			otherExpenses: null,
			netPnl: null,
		};

		const validEntries: Record<keyof Omit<ProjectedPnlRow, 'date'>, number> = {
			swineSalesRevenue: 0,
			cmeSwinePnl: 0,
			otherRevenue: 0,
			insurancePnl: 0,
			feedExpenses: 0,
			cmeFeedPnl: 0,
			netPnl: 0,
			otherExpenses: 0,
		};

		const addToTotal = (key: keyof Omit<ProjectedPnlRow, 'date'>, value: number | null) => {
			if (value == null) return;

			if (this.selectedDisplayUnit !== DisplayUnits.Total) {
				validEntries[key] += 1;
			}
			totals[key] = (totals[key] ?? 0) + value;
		};

		this.projectedPnlRows.forEach((row) => {
			addToTotal('swineSalesRevenue', row.swineSalesRevenue);
			addToTotal('cmeSwinePnl', row.cmeSwinePnl);
			addToTotal('otherRevenue', row.otherRevenue);
			addToTotal('insurancePnl', row.insurancePnl);
			addToTotal('feedExpenses', row.feedExpenses);
			addToTotal('cmeFeedPnl', row.cmeFeedPnl);
			addToTotal('netPnl', row.netPnl);
			addToTotal('otherExpenses', row.otherExpenses);
		});

		if (this.selectedDisplayUnit !== DisplayUnits.Total) {
			Object.keys(totals).forEach((key: keyof Omit<ProjectedPnlRow, 'date'>) => {
				const total = totals[key];
				if (total != null) {
					totals[key] = total / validEntries[key];
				}
			});
		}

		return [totals];
	}

	get projectedPnlColumns(): TableColumn[] {
		return [
			{
				id: '8109668f-e206-48f7-b1c5-00ab74b1aee4',
				name: 'Date',
				valuePath: 'date',
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				componentArgs: {
					footerContent: this.selectedDisplayUnit === DisplayUnits.Total ? 'Total' : 'Average',
				},
			},
			{
				id: '2792e94a-bc5f-482e-a196-c123fe960f52',
				name: 'Swine Sales',
				valuePath: 'swineSalesRevenue',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: this.selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
					maximumFractionDigits: this.selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '94ad1ca6-cf0b-4ca3-bbc0-1ea3d8f25ba5',
				name: 'CME Swine',
				valuePath: 'cmeSwinePnl',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: this.selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
					maximumFractionDigits: this.selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'b75f45b1-6c5a-4485-a863-a00c0272f105',
				name: 'Other Revenue',
				valuePath: 'otherRevenue',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: this.selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
					maximumFractionDigits: this.selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '22f7e690-b1ca-441d-a8c4-faaef1ec9eaa',
				name: 'Insurance',
				valuePath: 'insurancePnl',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: this.selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
					maximumFractionDigits: this.selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '0c8cdfac-e9c9-44cb-90eb-e278a78f4111',
				name: 'Feed Expenses',
				valuePath: 'feedExpenses',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: this.selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
					maximumFractionDigits: this.selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '13a95e7d-a0a8-4c79-b9fc-f1ead22c7427',
				name: 'CME Feed',
				valuePath: 'cmeFeedPnl',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: this.selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
					maximumFractionDigits: this.selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'c9964c50-7844-46aa-9048-b1e0cda098ea',
				name: 'Other Expenses',
				valuePath: 'otherExpenses',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: this.selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
					maximumFractionDigits: this.selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '71f731bc-14cc-4dfb-9ddc-75cc50796a61',
				name: 'Net P/L',
				valuePath: 'netPnl',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: this.selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
					maximumFractionDigits: this.selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: 'right',
				isVisible: true,
			},
		];
	}

	get percentHedgedRows() {
		const map: {
			[date: string]: {
				date: string;
				soybeanMealPercentHedged?: number;
				swinePercentHedged?: number;
				cornPercentHedged?: number;
			};
		} = {};

		this.model.getPigsDashboard.data?.EntityAllocatedExposureRatios.forEach((exposure) => {
			const date = exposure.date;

			if (!map[date]) {
				map[date] = {
					date,
				};
			}

			const obj = map[date];

			const percentageHedged = exposure.totalPercentVolumeHedged != null ? roundTo(exposure.totalPercentVolumeHedged, 2) : 0;

			const productSlug = exposure?.Product?.slug;

			switch (productSlug) {
				case 'livestock-lean-hogs':
					obj.swinePercentHedged = percentageHedged;
					break;
				case 'grain-corn':
					obj.cornPercentHedged = percentageHedged;
					break;
				case 'grain-soybean-meal':
					obj.soybeanMealPercentHedged = percentageHedged;
					break;
			}
		});

		return Object.values(map).sortBy('date');
	}

	get percentHedgedAverages() {
		const totals = this.percentHedgedRows.reduce(
			(totals, row) => {
				totals.swinePercentHedged += row.swinePercentHedged ?? 0;
				totals.cornPercentHedged += row.cornPercentHedged ?? 0;
				totals.soybeanMealPercentHedged += row.soybeanMealPercentHedged ?? 0;
				return totals;
			},
			{
				soybeanMealPercentHedged: 0,
				swinePercentHedged: 0,
				cornPercentHedged: 0,
			},
		);

		const numRows = this.percentHedgedRows.length;

		return [
			{
				soybeanMealPercentHedged: roundTo(totals.soybeanMealPercentHedged / numRows, 2),
				swinePercentHedged: roundTo(totals.swinePercentHedged / numRows, 2),
				cornPercentHedged: roundTo(totals.cornPercentHedged / numRows, 2),
			},
		];
	}

	get percentHedgedData() {
		const data: {
			Swine: (number | null)[];
			Corn: (number | null)[];
			'Soybean Meal': (number | null)[];
		} = {
			Swine: [],
			Corn: [],
			'Soybean Meal': [],
		};

		this.percentHedgedRows.forEach((row) => {
			data.Swine.push(row.swinePercentHedged ?? 0);
			data.Corn.push(row.cornPercentHedged ?? 0);
			data['Soybean Meal'].push(row.soybeanMealPercentHedged ?? 0);
		});

		return data;
	}

	get percentHedgedChartId() {
		return `pig-dashboard-hedged-chart-${this.id}`;
	}

	get percentHedgedChartData(): ChartData<'line'> {
		const datasets: ChartDataset<'line', (number | null)[]>[] = [
			{
				label: 'Swine',
				data: this.percentHedgedData['Swine'],
				borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-teal-60'),
				backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-teal-60'),
				pointRadius: 0,
				tension: 0.1,
			},
			{
				label: 'Corn',
				data: this.percentHedgedData['Corn'],
				borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-purple-50'),
				backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-purple-50'),
				pointRadius: 0,
				tension: 0.1,
			},
			{
				label: 'Soybean Meal',
				data: this.percentHedgedData['Soybean Meal'],
				borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-interactive-blue-50'),
				backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-interactive-blue-50'),
				pointRadius: 0,
				tension: 0.1,
			},
		];

		return {
			labels: this.chartLabels,
			datasets,
		};
	}

	get percentHedgedChartOptions(): ChartOptions<'line'> {
		const tooltipOptions: CustomTooltipOptions = {
			titleFormatter: (val: string) => {
				const dateTime = DateTime.fromISO(val);
				return `${dateTime.monthShort} ${dateTime.year}`;
			},
			valueFormatter: (val: number) => {
				return new Intl.NumberFormat('en-US', {
					style: 'percent',
					maximumFractionDigits: 0,
					minimumFractionDigits: 0,
				}).format(val);
			},
		};

		return {
			maintainAspectRatio: false,
			spanGaps: true,
			responsive: true,
			interaction: {
				intersect: false,
				mode: 'index',
			},
			layout: {
				padding: {
					left: 16,
					right: 16,
				},
			},
			scales: {
				y: {
					min: 0,
					ticks: {
						autoSkip: false,
						font: {
							size: 12,
						},
						callback: (tickValue) => {
							if (typeof tickValue === 'string') {
								return tickValue;
							} else {
								return new Intl.NumberFormat('en-us', { style: 'percent', maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(
									tickValue,
								);
							}
						},
						color: getComputedStyle(document.documentElement).getPropertyValue('--brand-gray-80'),
					},
				},
				x: {
					ticks: {
						autoSkip: true,
						color: getComputedStyle(document.documentElement).getPropertyValue('--brand-gray-80'),
						font: {
							size: 12,
						},
						callback: function (_tickValue, index) {
							const luxonDate = DateTime.fromISO(this.getLabelForValue(index));
							return [luxonDate.monthShort, luxonDate.year.toString()];
						},
					},
					grid: {
						display: false,
					},
				},
			},
			plugins: {
				legend: {
					display: false,
				},
				tooltip: {
					enabled: false,
					external: getCustomTooltip(tooltipOptions),
				},
			},
		};
	}

	@action
	updateProjectedPnlChart(chart: Chart<'line'>, _data: unknown) {
		chart.data = this.projectedPnlChartData;
		chart.update('none');
	}

	@action
	updatePercentHedgedChart(chart: Chart<'line'>, _data: unknown) {
		chart.data = this.percentHedgedChartData;
		chart.update('none');
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/pigs-dashboard': BusinessesBusinessPigsDashboardController;
	}
}
