import type { Context } from 'chartjs-plugin-datalabels';

function showLabelsForTallBars(thresholdPx: number = 16) {
	return (context: Context) => {
		const { datasetIndex, dataIndex, chart, dataset } = context;

		// Don't show data labels for lines
		if (dataset.type === 'line') return false;

		// TODO: Chartjs types are not cooperating. This should be allowed
		// - Preston
		const datasetMeta = chart.getSortedVisibleDatasetMetas().find(({ index }) => index === datasetIndex) as unknown as {
			index: number;
			data: { height?: number }[];
		};

		const barSectionHeight = datasetMeta?.data[dataIndex].height ?? 0;

		return barSectionHeight > thresholdPx;
	};
}

export { showLabelsForTallBars };
