import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<UiValidatedForm\n\t@id={{@id}}\n\t@onSubmit={{perform this.onSubmit}}\n\t@validate={{validate-valibot this.schema}}\n\t@ignoreNativeValidation={{true}}\n\t@data={{this.initialValues}}\n\tclass='flex flex-col gap-4'\n\tdata-test-edit-cattle-yard-form\n\t...attributes\n\tas |form|\n>\n\t<form.Group @name='yardName' as |group|>\n\t\t<group.Label>Yard Name<sup>&#42;</sup></group.Label>\n\t\t<group.Input placeholder='Enter yard name' aria-required='true' />\n\t\t<group.Errors data-test-yard-name-errors />\n\t</form.Group>\n\n\t<form.Group @name='yardDetails' as |group|>\n\t\t<group.Label>Yard Details</group.Label>\n\t\t<group.Textarea placeholder='Enter yard details' />\n\t\t<group.Errors data-test-yard-details-errors />\n\t</form.Group>\n</UiValidatedForm>", {"contents":"<UiValidatedForm\n\t@id={{@id}}\n\t@onSubmit={{perform this.onSubmit}}\n\t@validate={{validate-valibot this.schema}}\n\t@ignoreNativeValidation={{true}}\n\t@data={{this.initialValues}}\n\tclass='flex flex-col gap-4'\n\tdata-test-edit-cattle-yard-form\n\t...attributes\n\tas |form|\n>\n\t<form.Group @name='yardName' as |group|>\n\t\t<group.Label>Yard Name<sup>&#42;</sup></group.Label>\n\t\t<group.Input placeholder='Enter yard name' aria-required='true' />\n\t\t<group.Errors data-test-yard-name-errors />\n\t</form.Group>\n\n\t<form.Group @name='yardDetails' as |group|>\n\t\t<group.Label>Yard Details</group.Label>\n\t\t<group.Textarea placeholder='Enter yard details' />\n\t\t<group.Errors data-test-yard-details-errors />\n\t</form.Group>\n</UiValidatedForm>","moduleName":"vault-client/components/cattle/yards/edit-cattle-yard-form.hbs","parseOptions":{"srcName":"vault-client/components/cattle/yards/edit-cattle-yard-form.hbs"}});
import Component from '@glimmer/component';
import { CattleYard, Maybe, Mutation_updateCattleYardArgs } from 'vault-client/types/graphql-types';
import { task } from 'ember-concurrency';
import { FormError } from 'vault-client/utils/forms/form-error';
import { ERRORS } from 'vault-client/utils/cattle/errors';
import { type InferOutput, minLength, object, optional, pipe, string } from 'valibot';
import { useUpdateCattleYardMutation } from 'vault-client/utils/cattle/yards/update-cattle-yard';
import { emptyToNull } from 'vault-client/utils/transforms/empty-to-null';

export type EditCattleYardFormData = InferOutput<typeof EditCattleYardFormSchema>;
type EditCattleYardFormSchema = typeof EditCattleYardFormSchema;

const EditCattleYardFormSchema = object({
	yardName: pipe(string(), minLength(1, 'Yard Name is required')),
	yardDetails: optional(string()),
});

const Serializer = {
	serialize(yard: Pick<CattleYard, 'name' | 'description'>): EditCattleYardFormData {
		return {
			yardName: yard.name,
			yardDetails: yard.description ?? undefined,
		};
	},

	deserialize({ yardName, yardDetails }: EditCattleYardFormData, yardId: string): Mutation_updateCattleYardArgs {
		return {
			id: yardId,
			data: {
				name: yardName,
				description: emptyToNull(yardDetails),
			},
		};
	},
};

interface EditCattleYardFormArgs {
	id: string;
	yard: Pick<CattleYard, 'id' | 'name' | 'description'>;
	onSuccess?: (result: Maybe<CattleYard> | undefined) => void;
}

export default class EditCattleYardForm extends Component<EditCattleYardFormArgs> {
	schema = EditCattleYardFormSchema;
	serializer = Serializer;

	updateCattleYardMutation = useUpdateCattleYardMutation(this, {
		onError: function () {
			throw new FormError([ERRORS.UPDATE_CATTLE_YARD.DEFAULT]);
		},
	});

	get initialValues(): EditCattleYardFormData {
		return this.serializer.serialize(this.args.yard);
	}

	onSubmit = task({ drop: true }, async (formData: EditCattleYardFormData) => {
		const mutationArgs = this.serializer.deserialize(formData, this.args.yard.id);
		const result = await this.updateCattleYardMutation.mutate(mutationArgs);
		if (this.args.onSuccess) {
			this.args.onSuccess(result);
		}
	});
}
