import { MutationOptions, useMutation, QueryOptions, useQuery } from 'glimmer-apollo';
import {
	CREATE_CATTLE_LOT,
	type CreateCattleLotMutation,
	type CreateCattleLotMutationVariables,
} from 'vault-client/graphql/mutations/cattle/lots';
import {
	CATTLE_YARD_OPTION_LIST_QUERY,
	CATTLE_RATION_OPTION_LIST_QUERY,
} from 'vault-client/graphql/queries/option-dropdown-lists/cattle-lists';
import { CattleYardDistinctOnDTO } from 'vault-client/types/graphql-types';

const RELATED_FIELD_NAMES = ['CattleLots', 'CattleLot', 'CattleLotCount', 'AggregateCattleLots'] as const;

export interface CattleYardsOptionListData {
	CattleYards: Array<{
		id: string;
		name: string;
	}>;
}

export interface CattleRationsOptionListData {
	CattleFeedRations: Array<{
		id: string;
		name: string;
	}>;
}

interface OptionListQueryVariables {
	scopeId?: string;
	distinctOn?: CattleYardDistinctOnDTO;
}

const defaultOptions: MutationOptions<CreateCattleLotMutation, CreateCattleLotMutationVariables> = {
	update: (cache) => {
		RELATED_FIELD_NAMES.forEach((fieldName) => {
			cache.evict({ fieldName });
		});
		cache.gc();
	},
};

export function useCreateCattleLotMutation(
	context: object,
	options?: MutationOptions<CreateCattleLotMutation, CreateCattleLotMutationVariables>,
) {
	return useMutation<CreateCattleLotMutation, CreateCattleLotMutationVariables>(context, () => [
		CREATE_CATTLE_LOT,
		{
			...defaultOptions,
			...options,
		},
	]);
}

export function useCattleYardsOptionListQuery(context: object, options: QueryOptions<CattleYardsOptionListData, OptionListQueryVariables>) {
	return useQuery<CattleYardsOptionListData, OptionListQueryVariables>(context, () => [CATTLE_YARD_OPTION_LIST_QUERY, options]);
}

export function useCattleRationsOptionListQuery(
	context: object,
	options: QueryOptions<CattleRationsOptionListData, OptionListQueryVariables>,
) {
	return useQuery<CattleRationsOptionListData, OptionListQueryVariables>(context, () => [CATTLE_RATION_OPTION_LIST_QUERY, options]);
}
