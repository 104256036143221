import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { gql, useMutation } from 'glimmer-apollo';
import {
	GrainFillOrder,
	GrainFillOrderCreateDTO,
	GrainLocation,
	Buyer,
	Seller,
	TypeOfGrainPlan,
	TypeOfGrainOrderSales,
	GrainOrderDeliveryTerms,
} from 'vault-client/types/graphql-types';
import { inject as service } from '@ember/service';
import { isFormValid, getInvalidElements } from '../utils/form-validation';
import { guidFor } from '@ember/object/internals';
import Big from 'big.js';
import Target from 'vault-client/models/vgs/target';
import { task } from 'ember-concurrency';

const FILL_TARGET_MUTATION = gql`
	mutation CreateGrainFillOrder($data: GrainFillOrderCreateDTO!) {
		createGrainFillOrder(data: $data) {
			id
		}
	}
`;

interface FillTargetButtonArgs {
	target: Target;
}

interface FillVgsTargetVars {
	data?: GrainFillOrderCreateDTO;
}

export default class FillTargetButton extends Component<FillTargetButtonArgs> {
	@service permissions: any;

	componentId = guidFor(this);

	@tracked showModal = false;
	@tracked showConfirmationModal = false;
	@tracked errorMessage: string | null = null;
	@tracked deliveryTerms: GrainOrderDeliveryTerms | undefined | null = this.args.target.deliveryTerms;

	fillTargetMutation = useMutation<GrainFillOrder, FillVgsTargetVars>(this, () => [
		FILL_TARGET_MUTATION,
		{
			onComplete: () => {
				this.errorMessage = null;
				this.closeModal();
			},
			onError: (error): void => {
				this.errorMessage = 'There was an error. Target not filled.';
				console.error('Error while attempting to fill target.', error.message);
			},
			update: (cache) => {
				cache.evict({ fieldName: 'GrainTargetOrder' });
				cache.evict({ fieldName: 'GrainTargetOrders' });
				cache.evict({ fieldName: 'GrainCropPlan' });
				cache.evict({ fieldName: 'GrainCropPlans' });
				cache.evict({ fieldName: 'GrainFeedPlan' });
				cache.evict({ fieldName: 'GrainFeedPlans' });
				cache.gc();
			},
		},
	]);

	@tracked bushels: string = this.args.target.bushels?.toString() || '';

	get maxBushels() {
		return this.args.target?.bushels;
	}

	@tracked _futuresMonth: string | undefined | null = null;
	get futuresMonth() {
		if (this._futuresMonth) {
			return this._futuresMonth;
		} else {
			return this.args.target.futuresMonth ? this.args.target.futuresMonth.slice(0, -3) : this.args.target.futuresMonth;
		}
	}
	set futuresMonth(futuresMonth) {
		this._futuresMonth = futuresMonth;
	}

	@tracked _salesType: TypeOfGrainOrderSales | null | undefined = null;
	get salesType() {
		return this._salesType ? this._salesType : this.args.target.salesType;
	}
	set salesType(salesType) {
		this._salesType = salesType;
	}

	@tracked _futurePrice: string | number | null | undefined;
	get futurePrice() {
		return this._futurePrice != undefined ? this._futurePrice : this.args.target.futurePrice;
	}
	set futurePrice(futurePrice) {
		this._futurePrice = futurePrice;
	}

	@tracked _spread: number | null | undefined = null;
	get spread() {
		return this._spread ?? this.args.target.spread;
	}
	set spread(spread) {
		this._spread = spread;
	}

	// htaFee, rollFee, and flexFee are non-nullable. If not set the default is 0

	@tracked htaFee: string = this.args.target.htaFee?.toString() || '0';

	@tracked rollFee: string = this.args.target.rollFee?.toString() || '0';

	@tracked flexFee: string = this.args.target.flexFee?.toString() || '0';

	@tracked _basis: string = this.args.target.basis?.toString() || '';

	get basis() {
		return this._basis.toString();
	}

	set basis(basis) {
		this._basis = basis;
	}

	get isFlatPriceDisabled() {
		return this.futurePrice && this.basis;
	}

	@tracked _flatPrice: string | number | null | undefined = null;
	get flatPrice() {
		if (this.futurePrice && this.basis) return Big(this.futurePrice.toString()).add(this.basis.toString()).toNumber();
		else if (this._flatPrice) return this._flatPrice;
		else {
			return this.args.target.flatPrice;
		}
	}
	set flatPrice(flatPrice) {
		this._flatPrice = flatPrice;
	}

	get showPricesError() {
		//only allow the following combinations: futures price only, basis only, flat price only, or futures pice and basis together
		if (this.futurePrice && this.basis) {
			return false;
		} else if (this.futurePrice || this.basis) {
			return this.flatPrice ? true : false;
		}
		return false;
	}

	get disableConfirmAndSubmit(): boolean {
		return this.errorMessage != null || this.isSubmitting || this.showPricesError;
	}

	@tracked _buyer: Buyer | null | undefined = null;
	get buyer() {
		return this._buyer ? this._buyer : this.args.target.Buyer ? this.args.target.Buyer : null;
	}
	set buyer(buyer) {
		this._buyer = buyer;
	}

	@tracked _seller: Seller | null | undefined = null;
	get seller() {
		return this._seller ? this._seller : this.args.target.Seller ? this.args.target.Seller : null;
	}
	set seller(seller) {
		this._seller = seller;
	}

	@tracked _location: GrainLocation | null = null;
	get location() {
		return this._location ? this._location : this.args.target.Location ? this.args.target.Location : null;
	}
	set location(location) {
		this._location = location;
	}

	@tracked _deliveryStartDate: string | null | undefined = null;
	get deliveryStartDate() {
		return this._deliveryStartDate ? this._deliveryStartDate : this.args.target.deliveryStartDate;
	}
	set deliveryStartDate(deliveryStartDate) {
		this._deliveryStartDate = deliveryStartDate;
	}

	@tracked _deliveryEndDate: string | null | undefined = null;
	get deliveryEndDate() {
		return this._deliveryEndDate ? this._deliveryEndDate : this.args.target.deliveryEndDate;
	}
	set deliveryEndDate(deliveryEndDate) {
		this._deliveryEndDate = deliveryEndDate;
	}

	@tracked _contractNumber: string | null | undefined = null;
	get contractNumber() {
		return this._contractNumber ? this._contractNumber : this.args.target.contractNumber;
	}
	set contractNumber(contractNumber) {
		this._contractNumber = contractNumber;
	}

	@tracked _targetNote: string | null | undefined = null;
	get targetNote() {
		return this._targetNote ? this._targetNote : this.args.target.targetNote;
	}
	set targetNote(note) {
		this._targetNote = note;
	}

	get isSubmitting() {
		return this.submitFillTarget.isRunning;
	}

	resetFields() {
		this.basis = this.args.target.basis?.toString() || '';
		this.bushels = this.args.target.bushels?.toString() || '';
		this.rollFee = this.args.target.rollFee?.toString() || '0';
		this.flexFee = this.args.target.flexFee?.toString() || '0';
		this.htaFee = this.args.target.flexFee?.toString() || '0';
		this._buyer = null;
		this._spread = null;
		this._deliveryStartDate = null;
		this._deliveryEndDate = null;
		this._futuresMonth = null;
		this._location = null;
		this._salesType = null;
		this._contractNumber = null;
		this.targetNote = null;
	}

	@action
	openModal() {
		this.showModal = true;
	}

	@action
	closeModal() {
		this.showModal = false;
		this.errorMessage = null;
		this.showConfirmationModal = false;
		this.resetFields();
	}

	@action
	openConfirmationModal() {
		if (isFormValid(document)) {
			this.showConfirmationModal = true;
			this.showModal = false;
		} else {
			getInvalidElements(document);
		}
	}

	@action
	closeConfirmationModal() {
		this.showConfirmationModal = false;
		this.showModal = false;
		this.errorMessage = null;
	}

	@action
	confirm() {
		this.submitFillTarget.perform();
	}

	submitFillTarget = task({ drop: true }, async () => {
		// Changes made from this mutation will not appear on the target. This button creates a new fill it does not actually change the target. However, it will update the TargetStatus to 'Filled'. If the user wishes to make changes to the target itself they will need to use the Edit Target button.
		if (this.args.target) {
			if (this.args.target.Plan?.type === TypeOfGrainPlan.Crop) {
				const variables = {
					data: {
						basis: this.basis ? parseFloat(this.basis.toString()) : null,
						bushels: this.bushels ? parseFloat(this.bushels.toString()) : null,
						buyerId: this.buyer ? this.buyer.id : undefined,
						flexFee: this.flexFee ? parseFloat(this.flexFee.toString()) : 0,
						futurePrice: this.futurePrice ? parseFloat(this.futurePrice.toString()) : null,
						deliveryStartDate: this.deliveryStartDate ? this.deliveryStartDate : undefined,
						deliveryEndDate: this.deliveryEndDate ? this.deliveryEndDate : undefined,
						futuresMonth: this.futuresMonth ? this.futuresMonth + '-01' : undefined,
						htaFee: this.htaFee ? parseFloat(this.htaFee.toString()) : 0,
						locationId: this.location ? this.location.id : undefined,
						rollFee: this.rollFee ? parseFloat(this.rollFee.toString()) : 0,
						salesType: this.salesType,
						spread: this.spread ? parseFloat(this.spread.toString()) : null,
						targetId: this.args.target.id,
						planId: this.args.target?.Plan?.id,
						contractNumber: this.contractNumber,
						shortNote: this.targetNote,
					},
				};
				const _fillTargetMutation = this.fillTargetMutation.mutate(variables);
				await _fillTargetMutation;
			}
			if (this.args.target.Plan?.type === TypeOfGrainPlan.Feed) {
				const variables = {
					data: {
						basis: this.basis ? parseFloat(this.basis.toString()) : null,
						bushels: this.bushels ? parseFloat(this.bushels.toString()) : null,
						sellerId: this.seller ? this.seller.id : undefined,
						futurePrice: this.futurePrice ? parseFloat(this.futurePrice.toString()) : null,
						deliveryStartDate: this.deliveryStartDate ? this.deliveryStartDate : undefined,
						deliveryEndDate: this.deliveryEndDate ? this.deliveryEndDate : undefined,
						futuresMonth: this.futuresMonth ? this.futuresMonth + '-01' : undefined,
						locationId: this.location ? this.location.id : undefined,
						salesType: this.salesType,
						targetId: this.args.target.id,
						planId: this.args.target.Plan.id,
						contractNumber: this.contractNumber,
						deliveryTerms: this.deliveryTerms,
						feedFlatPrice:
							!this.futurePrice && !this.basis == null && this.flatPrice != null ? Big(this.flatPrice.toString()).toNumber() ?? null : null,
						shortNote: this.targetNote,
					},
				};

				const _fillTargetMutation = this.fillTargetMutation.mutate(variables);
				await _fillTargetMutation;
			}
		} else {
			throw new Error('No Target available to fill the Target');
		}
	});
}
