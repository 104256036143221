import { DateTime } from 'luxon';
import Future from './future';
import Instrument from './instrument';
import { Swaption as SwaptionInterface, TypeOfOption, Future as FutureInterface } from 'vault-client/types/graphql-types';
import { tracked } from '@glimmer/tracking';
import Option from './option';

export default class Swaption extends Instrument {
	@tracked expiresAt: string;
	@tracked displayExpiresAt: string;
	// Underlying future.
	@tracked priceInstrument: Future | null = null;
	// Lookalike option.
	@tracked settlementInstrument: Option | null = null;
	@tracked optionType: TypeOfOption | undefined;
	@tracked strike: number;

	constructor(instrument: SwaptionInterface) {
		super(instrument);

		this.expiresAt = instrument.expiresAt;
		this.displayExpiresAt = instrument.displayExpiresAt;

		if (instrument.SettlementInstrument) {
			this.settlementInstrument = new Option(instrument.SettlementInstrument);
		}

		if (instrument.PriceInstrument) {
			this.priceInstrument = new Future(instrument.PriceInstrument as FutureInterface);
		}

		this.optionType = instrument.optionType;
		this.strike = instrument.strike;
	}

	get instrumentType() {
		return `${this.optionType} Swaption`;
	}

	get barchartSymbol() {
		return this.settlementInstrument?.barchartSymbol;
	}

	get isCall() {
		return this.optionType == TypeOfOption.Call;
	}

	get isPut() {
		return this.optionType == TypeOfOption.Put;
	}

	get hasExpired() {
		// This should be > 0, but we don't have exact times for expirations.
		return DateTime.fromISO(this.expiresAt).diffNow().valueOf() < -86400000;
	}
}
