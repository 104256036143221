import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { useQuery } from 'glimmer-apollo';
import { DateTime } from 'luxon';
import {
	GET_AGGREGATE_CURRENT_ALLOCATION_POSITIONS,
	type GetAggregateCurrentAllocationPositionsQuery,
	type GetAggregateCurrentAllocationPositionsVariables,
} from 'vault-client/graphql/queries/grain/aggregate-current-allocation-positions';
import type { GetHarvestPnlComponentsQuery } from 'vault-client/graphql/queries/grain/harvest-pnl-components';
import {
	GET_HARVEST_PNL_COMPONENTS,
	type GetHarvestPnlComponentsVariables,
} from 'vault-client/graphql/queries/grain/harvest-pnl-components';
import {
	type Crop,
	type CropFilterDTO,
	type CropHarvestedAndSoldVolumeFilterDTO,
	type CropHarvestYearFilterDTO,
	type PhysicalCropTransactionFilterDTO,
	type CropTransactionFilterDTO,
	type LedgerForecastedEntryFilterDTO,
	TypeOfLedgerCategory,
	type CurrentAllocationPositionFilterDTO,
} from 'vault-client/types/graphql-types';

interface Params {
	harvestYear: number;
}

export default class BusinessesBusinessHarvestPnlRoute extends Route {
	queryParams = {
		harvestYear: {
			refreshModel: true,
		},
	};

	@tracked businessId: string = '';
	@tracked harvestYear: number = 0;

	getHarvestPnlComponents = useQuery<GetHarvestPnlComponentsQuery, GetHarvestPnlComponentsVariables>(this, () => [
		GET_HARVEST_PNL_COMPONENTS,
		{
			variables: this.harvestPnlComponentsVariables,
		},
	]);

	getAggregateCurrentAllocationPositions = useQuery<
		GetAggregateCurrentAllocationPositionsQuery,
		GetAggregateCurrentAllocationPositionsVariables
	>(this, () => [
		GET_AGGREGATE_CURRENT_ALLOCATION_POSITIONS,
		{
			variables: this.aggregateCurrentAllocationPositionsVariables,
			skip: !this.hasHedgedHarvestYears,
		},
	]);

	async model(params: Params) {
		const { business_id: businessId } = this.paramsFor('businesses.business') as { business_id: string };
		const { harvestYear } = params;

		this.businessId = businessId;
		this.harvestYear = harvestYear;

		await this.getHarvestPnlComponents.promise;
		await this.getAggregateCurrentAllocationPositions.promise;

		return {
			harvestYear,
			getHarvestPnlComponents: this.getHarvestPnlComponents,
			getAggregateCurrentAllocationPositions: this.getAggregateCurrentAllocationPositions,
		};
	}

	get harvestPnlComponentsVariables(): GetHarvestPnlComponentsVariables {
		return this.generateHarvestPnlComponentsVariables({
			businessId: this.businessId,
			harvestYear: this.harvestYear,
		});
	}

	get aggregateCurrentAllocationPositionsVariables() {
		return this.generateAggregateCurrentAllocationPositionsVariables({
			crops: this.getHarvestPnlComponents.data?.Crops ?? [],
			businessId: this.businessId,
		});
	}

	get hasHedgedHarvestYears() {
		return !!this.aggregateCurrentAllocationPositionsVariables.allocationPositionsWhere.OR?.length;
	}

	generateHarvestPnlComponentsVariables({
		businessId,
		harvestYear,
	}: {
		businessId: string;
		harvestYear: number;
	}): GetHarvestPnlComponentsVariables {
		const harvestYearDateTime = DateTime.fromObject({ year: harvestYear });
		const harvestYearStartDate = harvestYearDateTime.startOf('year').toISODate();
		const harvestYearEndDate = harvestYearDateTime.endOf('year').toISODate();

		return {
			scopeId: businessId,
			cropsWhere: this.generateCropsWhere(businessId, harvestYear),
			harvestYear,
			cropHarvestedAndSoldVolumesWhere: this.generateCropHarvestedAndSoldVolumesWhere(harvestYearDateTime),
			cropHarvestYearsWhere: this.generateCropHarvestYearsWhere(harvestYear),
			cropTransactionsWhere: this.generateCropTransactionsWhere(harvestYear),
			aggregateBusinessRevenueLedgerEntriesWhere: this.generateAggregateBusinessLedgerEntriesWhere({
				ledgerCategory: TypeOfLedgerCategory.Revenue,
				harvestYearStartDate,
				harvestYearEndDate,
			}),
			aggregateBusinessExpenseLedgerEntriesWhere: this.generateAggregateBusinessLedgerEntriesWhere({
				ledgerCategory: TypeOfLedgerCategory.Expense,
				harvestYearStartDate,
				harvestYearEndDate,
			}),
			aggregateBusinessLedgerEntriesCalc: { sum: { amount: true } },
			aggregateBusinessLedgerEntriesGroupBy: {},
		};
	}

	generateAggregateCurrentAllocationPositionsVariables({
		crops,
		businessId,
	}: {
		crops: Crop[];
		businessId: string;
	}): GetAggregateCurrentAllocationPositionsVariables {
		return {
			allocationPositionsCalc: { sum: { grossPnl: true, unitQuantity: true } },
			allocationPositionsGroupBy: {
				Product: {
					id: true,
					slug: true,
				},
			},
			allocationPositionsWhere: this.generateAllocatedPositionsFilterForHarvestYears(crops, businessId),
		};
	}

	generateAllocatedPositionsFilterForHarvestYears(crops: Crop[], businessId: string): CurrentAllocationPositionFilterDTO {
		const hedgedHarvestYearFilters = crops
			.filter((crop) => !!crop.Category.HedgeProduct?.id)
			.flatMap((crop) =>
				crop.CropHarvestYears.map((harvestYear) => ({
					effectiveHedgeDate: {
						gte: harvestYear.effectiveHedgingStartDate!,
						lte: harvestYear.effectiveHedgingEndDate ?? undefined,
					},
					productId: {
						equals: crop.Category.HedgeProduct!.id,
					},
				})),
			);

		return {
			OR: [...hedgedHarvestYearFilters],
			businessId: {
				equals: businessId,
			},
		};
	}

	generateAggregateBusinessLedgerEntriesWhere({
		ledgerCategory,
		harvestYearStartDate,
		harvestYearEndDate,
	}: {
		ledgerCategory: TypeOfLedgerCategory;
		harvestYearStartDate: string;
		harvestYearEndDate: string;
	}): LedgerForecastedEntryFilterDTO {
		return {
			LedgerCategory: {
				type: {
					equals: ledgerCategory,
				},
			},
			date: {
				gte: harvestYearStartDate,
				lte: harvestYearEndDate,
			},
		};
	}

	generateCropTransactionsWhere(harvestYear: number): CropTransactionFilterDTO {
		return {
			harvestYear: {
				equals: harvestYear,
			},
		};
	}

	generatePhysicalCropTransactionsWhere(harvestYear: number): PhysicalCropTransactionFilterDTO {
		return {
			harvestYear: {
				equals: harvestYear,
			},
		};
	}

	generateCropHarvestYearsWhere(harvestYear: number): CropHarvestYearFilterDTO {
		return {
			harvestYear: {
				equals: harvestYear,
			},
		};
	}

	generateCropHarvestedAndSoldVolumesWhere(harvestYearDateTime: DateTime): CropHarvestedAndSoldVolumeFilterDTO {
		const startDate = harvestYearDateTime.startOf('year').toISODate();
		const endDate = harvestYearDateTime.endOf('year').toISODate();
		return {
			startDate,
			endDate,
		};
	}

	generateCropsWhere(businessId: string, harvestYear: number): CropFilterDTO {
		return {
			businessId: {
				equals: businessId,
			},
			// Only include crops that have a harvest in the given harvest year
			CropHarvestYears: {
				harvestYear: {
					equals: harvestYear,
				},
			},
		};
	}
}
