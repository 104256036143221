import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<UiValidatedForm\n\t@id={{@id}}\n\t@onSubmit={{perform this.onSubmit}}\n\t@validate={{validate-valibot this.schema}}\n\t@ignoreNativeValidation={{true}}\n\t@data={{this.initialValues}}\n\tclass='flex flex-col gap-4'\n\tdata-test-edit-ration-form\n\t...attributes\n\tas |form|\n>\n\t<form.Group @name='rationName' as |group|>\n\t\t<group.Label>Ration Name<sup>&#42;</sup></group.Label>\n\t\t<group.Input placeholder='Enter ration name' aria-required='true' />\n\t\t<group.Errors data-test-ration-name-errors />\n\t</form.Group>\n\n\t<form.Group @name='rationDetails' as |group|>\n\t\t<group.Label>Notes</group.Label>\n\t\t<group.Textarea />\n\t\t<group.Errors data-test-ration-details-errors />\n\t</form.Group>\n</UiValidatedForm>", {"contents":"<UiValidatedForm\n\t@id={{@id}}\n\t@onSubmit={{perform this.onSubmit}}\n\t@validate={{validate-valibot this.schema}}\n\t@ignoreNativeValidation={{true}}\n\t@data={{this.initialValues}}\n\tclass='flex flex-col gap-4'\n\tdata-test-edit-ration-form\n\t...attributes\n\tas |form|\n>\n\t<form.Group @name='rationName' as |group|>\n\t\t<group.Label>Ration Name<sup>&#42;</sup></group.Label>\n\t\t<group.Input placeholder='Enter ration name' aria-required='true' />\n\t\t<group.Errors data-test-ration-name-errors />\n\t</form.Group>\n\n\t<form.Group @name='rationDetails' as |group|>\n\t\t<group.Label>Notes</group.Label>\n\t\t<group.Textarea />\n\t\t<group.Errors data-test-ration-details-errors />\n\t</form.Group>\n</UiValidatedForm>","moduleName":"vault-client/components/cattle/rations/edit-ration-form.hbs","parseOptions":{"srcName":"vault-client/components/cattle/rations/edit-ration-form.hbs"}});
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { FormError } from 'vault-client/utils/forms/form-error';
import { ERRORS } from 'vault-client/utils/cattle/errors';
import { type InferOutput, minLength, object, optional, pipe, string } from 'valibot';
import { useUpdateRationMutation } from 'vault-client/utils/cattle/rations/use-update-ration-mutation';
import { CattleFeedRation, Maybe } from 'vault-client/types/graphql-types';
import { emptyToNull } from 'vault-client/utils/transforms/empty-to-null';
import type { UpdateRationData } from 'vault-client/types/cattle/ration';

export type EditRationFormData = InferOutput<typeof EditRationFormSchema>;
type EditRationFormSchema = typeof EditRationFormSchema;

const EditRationFormSchema = object({
	rationName: pipe(string(), minLength(1, 'Ration Name is required')),
	rationDetails: optional(string()),
});

const Serializer = {
	serialize(ration: Pick<CattleFeedRation, 'name' | 'description'>): EditRationFormData {
		return {
			rationName: ration.name,
			rationDetails: ration.description ?? undefined,
		};
	},

	deserialize({ rationName, rationDetails }: EditRationFormData, rationId: string): { id: string; data: UpdateRationData } {
		return {
			id: rationId,
			data: {
				name: rationName,
				description: emptyToNull(rationDetails) ?? '',
			},
		};
	},
};

interface EditRationFormArgs {
	id: string;
	ration: Pick<CattleFeedRation, 'id' | 'name' | 'description'>;
	onSuccess?: (result: Maybe<CattleFeedRation> | undefined) => void;
}

export default class EditRationForm extends Component<EditRationFormArgs> {
	schema = EditRationFormSchema;
	serializer = Serializer;

	updateRationMutation = useUpdateRationMutation(this, {
		onError: function () {
			throw new FormError([ERRORS.UPDATE_CATTLE_RATION.DEFAULT]);
		},
	});

	get initialValues(): EditRationFormData {
		return this.serializer.serialize(this.args.ration);
	}

	onSubmit = task({ drop: true }, async (formData: EditRationFormData) => {
		const mutationArgs = this.serializer.deserialize(formData, this.args.ration.id);
		const result = await this.updateRationMutation.mutate(mutationArgs);
		if (this.args.onSuccess) {
			this.args.onSuccess(result);
		}
	});
}
