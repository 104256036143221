import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<UiValidatedForm\n\t@id={{@id}}\n\t@onSubmit={{perform this.onSubmit}}\n\t@validate={{validate-valibot this.schema}}\n\t@ignoreNativeValidation={{true}}\n\tclass='flex flex-col gap-4'\n\t...attributes\n\tas |form|\n>\n\t<form.Group @name='yardName' as |group|>\n\t\t<group.Label>Yard Name<sup>&#42;</sup></group.Label>\n\t\t<group.Input placeholder='Enter yard name' aria-required='true' />\n\t\t<group.Errors />\n\t</form.Group>\n\n\t<form.Group @name='yardDetails' as |group|>\n\t\t<group.Label>Yard Details</group.Label>\n\t\t<group.Textarea placeholder='Enter yard details' />\n\t\t<group.Errors />\n\t</form.Group>\n</UiValidatedForm>", {"contents":"<UiValidatedForm\n\t@id={{@id}}\n\t@onSubmit={{perform this.onSubmit}}\n\t@validate={{validate-valibot this.schema}}\n\t@ignoreNativeValidation={{true}}\n\tclass='flex flex-col gap-4'\n\t...attributes\n\tas |form|\n>\n\t<form.Group @name='yardName' as |group|>\n\t\t<group.Label>Yard Name<sup>&#42;</sup></group.Label>\n\t\t<group.Input placeholder='Enter yard name' aria-required='true' />\n\t\t<group.Errors />\n\t</form.Group>\n\n\t<form.Group @name='yardDetails' as |group|>\n\t\t<group.Label>Yard Details</group.Label>\n\t\t<group.Textarea placeholder='Enter yard details' />\n\t\t<group.Errors />\n\t</form.Group>\n</UiValidatedForm>","moduleName":"vault-client/components/cattle/yards/create-cattle-yard-form.hbs","parseOptions":{"srcName":"vault-client/components/cattle/yards/create-cattle-yard-form.hbs"}});
import Component from '@glimmer/component';
import { CattleYard, Maybe, Mutation_createCattleYardArgs } from 'vault-client/types/graphql-types';
import { task } from 'ember-concurrency';
import { FormError } from 'vault-client/utils/forms/form-error';
import { ERRORS } from 'vault-client/utils/cattle/errors';
import { emptyToUndefined } from 'vault-client/utils/transforms/empty-to-undefined';
import { type InferOutput, minLength, object, optional, pipe, string } from 'valibot';
import { useCreateCattleYardMutation } from 'vault-client/utils/cattle/yards/create-cattle-yard';

export type CreateCattleYardFormData = InferOutput<typeof CreateCattleYardFormSchema>;
type CreateCattleYardFormSchema = typeof CreateCattleYardFormSchema;

const CreateCattleYardFormSchema = object({
	yardName: pipe(string(), minLength(1, 'Yard Name is required')),
	yardDetails: optional(string()),
});

const Serializer = {
	deserialize({ yardName, yardDetails }: CreateCattleYardFormData, businessId: string): Mutation_createCattleYardArgs {
		return {
			data: {
				businessId: businessId,
				name: yardName,
				description: emptyToUndefined(yardDetails),
			},
		};
	},
};

interface CreateCattleYardFormArgs {
	id: string;
	businessId: string;
	onSuccess?: (result: Maybe<CattleYard> | undefined) => void;
}

export default class CreateCattleYardForm extends Component<CreateCattleYardFormArgs> {
	schema = CreateCattleYardFormSchema;
	serializer = Serializer;
	createCattleYardMutation = useCreateCattleYardMutation(this, {
		onError: function () {
			throw new FormError([ERRORS.CREATE_CATTLE_YARD.DEFAULT]);
		},
	});

	onSubmit = task({ drop: true }, async (formData: CreateCattleYardFormData) => {
		const mutationArgs = this.serializer.deserialize(formData, this.args.businessId);
		const result = await this.createCattleYardMutation.mutate(mutationArgs);
		if (this.args.onSuccess) {
			this.args.onSuccess(result);
		}
	});
}
