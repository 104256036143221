import * as v from 'valibot';
import { type Mutation_createCattleLotArgs } from 'vault-client/types/graphql-types';
import {
	calculatePurchasePricePerHeadInUsd,
	convertCattleLotInputExpensesInputToOutput,
	convertMortalityRateToPercentage,
	TypeOfCattleLotExpenseOptions,
	TypeOfCattleLotPurchasePriceOptions,
} from 'vault-client/utils/cattle/lots/lots-utils';
import { convertToNegative } from 'vault-client/utils/logic-utils';
import { emptyToUndefined } from 'vault-client/utils/transforms/empty-to-undefined';

const CreateCattleLotFormSchema = v.pipe(
	v.object({
		lotName: v.pipe(v.string(), v.minLength(1, 'Lot Name is required')),
		feedYardId: v.optional(v.string()),
		feedRationId: v.optional(v.string()),
		totalNumberOfCattle: v.pipe(
			v.string(),
			v.transform((value) => Number(value)),
			v.minValue(1, 'Number of animals must be at least 1'),
		),
		activeStartDate: v.pipe(v.string(), v.minLength(1, 'Start date is required')),
		startWeightInLb: v.pipe(
			v.string(),
			v.transform((value) => Number(value)),
			v.minValue(1, 'Start weight must be greater than 0'),
			v.maxValue(10000, 'Start weight must be less than 10,000'),
		),
		purchasePrice: v.pipe(
			v.string(),
			v.nonEmpty('Purchase price is required'),
			v.transform((value) => Number(value)),
			v.minValue(0, 'Purchase price must be greater than 0'),
		),
		purchasePriceType: v.picklist(TypeOfCattleLotPurchasePriceOptions),
		mortalityRate: v.pipe(
			v.string(),
			v.minLength(1, 'Mortality rate is required'),
			v.transform((value) => Number(value)),
			v.minValue(0, 'Mortality rate must be between 0 and 100'),
			v.maxValue(100, 'Mortality rate must be between 0 and 100'),
			v.transform((value) => convertMortalityRateToPercentage(value)),
		),
		targetWeightInLb: v.pipe(
			v.string(),
			v.minLength(1, 'Target weight is required'),
			v.transform((value) => Number(value)),
			v.minValue(0, 'Target weight must be greater than 0'),
		),
		averageDailyWeightGainInLb: v.pipe(
			v.string(),
			v.minLength(1, 'Average daily gain is required'),
			v.transform((value) => Number(value)),
			v.minValue(0, 'Average daily gain must be greater than 0'),
		),
		cattleLotInputExpensesInUsd: v.pipe(
			v.string(),
			v.transform((value) => Number(value)),
			v.minValue(0, 'Entered expenses must be greater than or equal to 0'),
			v.transform((value) => convertToNegative(value)),
		),
		description: v.pipe(
			v.optional(v.string()),
			v.transform((value) => emptyToUndefined(value)),
		),
		cattleLotExpensesType: v.picklist(TypeOfCattleLotExpenseOptions),
	}),
	v.forward(
		v.partialCheck(
			[['startWeightInLb'], ['targetWeightInLb']],
			(input) => {
				const startWeight = Number(input.startWeightInLb);
				const targetWeight = Number(input.targetWeightInLb);
				return !startWeight || !targetWeight || startWeight < targetWeight;
			},
			'Start weight must be less than target weight',
		),
		['targetWeightInLb'],
	),
);

const Serializer = {
	deserialize(formData: CreateCattleLotFormDataOutput, businessId: string): Mutation_createCattleLotArgs {
		const purchasePricePerHeadInUsd = calculatePurchasePricePerHeadInUsd({
			purchasePrice: formData.purchasePrice,
			purchasePriceType: formData.purchasePriceType,
			numberOfCattle: formData.totalNumberOfCattle,
			startWeightInLb: formData.startWeightInLb,
		});

		const { cattleLotInputExpensesInUsd, cattleLotExpensesType } = convertCattleLotInputExpensesInputToOutput({
			cattleLotInputExpensesInUsd: formData.cattleLotInputExpensesInUsd,
			cattleLotExpensesType: formData.cattleLotExpensesType,
		});

		return {
			data: {
				businessId,
				name: formData.lotName,
				description: formData.description,
				activeStartDate: formData.activeStartDate,
				startWeightInLb: formData.startWeightInLb,
				averageDailyWeightGainInLb: formData.averageDailyWeightGainInLb,
				cattleLotInputExpensesInUsd,
				mortalityRate: formData.mortalityRate,
				numberOfCattle: formData.totalNumberOfCattle,
				purchasePricePerHeadInUsd,
				targetWeightInLb: formData.targetWeightInLb,
				cattleLotExpensesType,
				cattleYardId: formData.feedYardId,
				cattleFeedRationId: formData.feedRationId,
			},
		};
	},
};

export type CreateCattleLotFormDataInput = v.InferInput<typeof CreateCattleLotFormSchema>;
export type CreateCattleLotFormDataOutput = v.InferOutput<typeof CreateCattleLotFormSchema>;
export type PartialCreateCattleLotFormDataInput = Partial<CreateCattleLotFormDataInput>;

export { Serializer, CreateCattleLotFormSchema, TypeOfCattleLotPurchasePriceOptions, TypeOfCattleLotExpenseOptions };
