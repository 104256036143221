import { BusinessEntityRole } from 'vault-client/types/graphql-types';

export const supportedBusinessRoles = [
	BusinessEntityRole.DairyProducer,
	BusinessEntityRole.HogProducer,
	BusinessEntityRole.LiveCattleProducer,
	BusinessEntityRole.FeederCattleProducer,
] as const;
export type SupportedBusinessRole = (typeof supportedBusinessRoles)[number];

export const dynamicUnits = ['Per Head', 'Per CWT'] as const;
export type DynamicUnit = (typeof dynamicUnits)[number];

export const staticUnits = ['Static'] as const;
export type StaticUnit = (typeof staticUnits)[number];

export const selectableUnits = [...dynamicUnits, ...staticUnits] as const;
export type SelectableUnit = (typeof selectableUnits)[number];
