import { setClient } from 'glimmer-apollo';
import { ApolloClient, InMemoryCache, NextLink, createHttpLink, Operation } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import config from '../config/environment';
import { setContext } from '@apollo/client/link/context';
import ApplicationInstance from '@ember/application/instance';
import AuthService from 'vault-client/services/auth';
import ErrorHandler from 'vault-client/services/error-handler';
import { NetworkError } from '@apollo/client/errors';
import { promiseToObservable } from 'vault-client/utils/apollo-utils';
import { PaginationDirection, PaginationInput } from 'vault-client/types/graphql-types';
import { GraphQLFormattedError } from 'graphql';

interface Headers {
	Authorization?: string;
}

export default function setupApolloClient(context: ApplicationInstance): void {
	const auth = context.lookup('service:auth') as AuthService;
	const errorHandler = context.lookup('service:error-handler') as ErrorHandler;

	const getVaultHeaders = (): Headers => {
		const headers: Headers = {};

		if (!auth.accessToken) {
			return headers;
		}

		headers.Authorization = `Bearer ${auth.accessToken}`;

		return headers;
	};

	const httpLink = createHttpLink({
		uri: config.apollo.apiURL as string,
	});

	const authLink = setContext(async (): Promise<object> => {
		let headers = getVaultHeaders();

		if (!headers.Authorization) {
			await auth.refreshTokens();
			headers = getVaultHeaders();
		}

		if (!headers.Authorization) {
			// This should have been prevented above
			throw new Error('Authorization token could not be fetched for Vault Glimmer Apollo client');
		}

		return { headers };
	});

	const handleErrors = (
		graphQLErrors: readonly GraphQLFormattedError[] | undefined,
		networkError: NetworkError | undefined,
		operation: Operation,
		forward: NextLink,
		getHeaders: () => Headers,
	) => {
		if (graphQLErrors) {
			for (const err of graphQLErrors) {
				const { message, locations, path, extensions } = err;

				if (extensions?.code === 'UNAUTHENTICATED' || extensions?.code === 'invalid-jwt' || message.includes('Access denied')) {
					return promiseToObservable(auth.refreshTokens().then(() => operation.setContext({ headers: getHeaders() }))).flatMap(() =>
						forward(operation),
					);
				} else {
					errorHandler.handleError(
						Error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Code: ${extensions?.code}`),
						false,
					);
				}
			}
		}

		if (networkError) {
			const statusCode = (networkError as any).statusCode as number | undefined;
			console.error(`[Network error]: ${statusCode} ${networkError}`);
			if (statusCode && statusCode === 401) {
				return promiseToObservable(auth.refreshTokens().then(() => operation.setContext({ headers: getHeaders() }))).flatMap(() =>
					forward(operation),
				);
			} else {
				errorHandler.handleError(networkError, false);
			}
		}

		return;
	};

	// Afterware
	const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
		return handleErrors(graphQLErrors, networkError, operation, forward, getVaultHeaders);
	});

	const cache = new InMemoryCache({
		typePolicies: {
			Query: {
				fields: {
					FeedIngredientConsumedAndPurchasedVolumes: {
						keyArgs: ['distinctOn', 'scopeId', 'where', 'orderBy'],
						merge(existing = [], incoming, { args }) {
							const pagination = args?.pagination as PaginationInput | undefined;

							// Return early if we are not paginating, or we are trying to fetch the first page
							if (!pagination || !pagination.previousRowId) return incoming;

							if (pagination.direction === PaginationDirection.NextPageFromKeyset) {
								return [...existing, ...incoming];
							} else if (pagination.direction === PaginationDirection.NextPageIncludingKeyset) {
								return [...existing, ...incoming.slice(1)];
							} else if (pagination.direction === PaginationDirection.PreviousPageFromKeyset) {
								return [...incoming, ...existing];
							} else {
								return [...incoming.slice(0, -1), ...existing];
							}
						},
					},
				},
			},
			CropRevenueForHarvestYear: {
				merge(_, incoming) {
					return incoming;
				},
			},
			CropExpenseForHarvestYear: {
				merge(_, incoming) {
					return incoming;
				},
			},
		},
	});

	const apolloClient = new ApolloClient({
		link: errorLink.concat(authLink.concat(httpLink)),
		cache,
		defaultOptions: {
			watchQuery: {
				fetchPolicy: 'cache-and-network',
			},
		},
	});

	// Set default apollo client for Glimmer Apollo
	setClient(context, apolloClient);
}
