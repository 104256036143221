export const FIELD_NAMES = [
	'AggregateCattleLotFeedRationUsagePerMonths',
	'AggregateCattleLots',
	'CattleFeedRation',
	'CattleFeedRationCount',
	'CattleFeedRations',
	'CattleFeedRationStage',
	'CattleFeedRationStageCount',
	'CattleFeedRationStageIngredient',
	'CattleFeedRationStageIngredientCount',
	'CattleFeedRationStageIngredients',
	'CattleFeedRationStages',
	'CattleLot',
	'CattleLotCount',
	'CattleLotFeedRationUsagePerMonth',
	'CattleLotFeedRationUsagePerMonthCount',
	'CattleLotFeedRationUsagePerMonths',
	'CattleLots',
	'CattleYard',
	'CattleYardCount',
	'CattleYards',
] as const;
