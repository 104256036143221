import { MutationOptions, useMutation } from 'glimmer-apollo';
import type { Mutation } from 'vault-client/types/graphql-types';
import { DELETE_RATION } from 'vault-client/graphql/mutations/cattle/rations';

const defaultOptions: MutationOptions<Mutation['deleteCattleFeedRation'], { id: string }> = {
	onError: (error) => {
		console.error('Failed to delete ration:', error);
	},
	update: (cache) => {
		cache.evict({ fieldName: 'CattleFeedRations' });
		cache.gc();
	},
};

export function useDeleteRationMutation(context: object, options?: MutationOptions<Mutation['deleteCattleFeedRation'], { id: string }>) {
	return useMutation<Mutation['deleteCattleFeedRation'], { id: string }>(context, () => [
		DELETE_RATION,
		{
			...defaultOptions,
			...options,
		},
	]);
}
