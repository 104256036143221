import * as v from 'valibot';
import type { CattleFeedRationStage, Mutation_updateCattleFeedRationStageArgs } from 'vault-client/types/graphql-types';
import { ERRORS } from 'vault-client/utils/cattle/errors';
import {
	MAX_END_WEIGHT_IN_LB,
	MAX_START_WEIGHT_IN_LB,
	MIN_END_WEIGHT_IN_LB,
	MIN_START_WEIGHT_IN_LB,
} from 'vault-client/utils/cattle/rations/stages';
import { gt } from 'vault-client/utils/precision-math';
import { float } from 'vault-client/utils/validations/actions/float';

const {
	UPDATE_CATTLE_FEED_RATION_STAGE: {
		MISSING_STAGE_NAME,
		INVALID_START_WEIGHT,
		MISSING_START_WEIGHT,
		INVALID_END_WEIGHT,
		MISSING_END_WEIGHT,
		START_WEIGHT_TOO_SMALL,
		START_WEIGHT_TOO_LARGE,
		END_WEIGHT_TOO_SMALL,
		END_WEIGHT_TOO_LARGE,
		END_WEIGHT_NOT_GT_START_WEIGHT,
	},
} = ERRORS;

const stageNameSchema = v.pipe(v.string(), v.trim(), v.nonEmpty(MISSING_STAGE_NAME));

const startWeightSchema = v.pipe(
	v.string(),
	v.trim(),
	v.nonEmpty(MISSING_START_WEIGHT),
	float(INVALID_START_WEIGHT),
	v.transform(parseFloat),
	v.minValue(MIN_START_WEIGHT_IN_LB, START_WEIGHT_TOO_SMALL),
	v.maxValue(MAX_START_WEIGHT_IN_LB, START_WEIGHT_TOO_LARGE),
);

const endWeightSchema = v.pipe(
	v.string(),
	v.trim(),
	v.nonEmpty(MISSING_END_WEIGHT),
	float(INVALID_END_WEIGHT),
	v.transform(parseFloat),
	v.minValue(MIN_END_WEIGHT_IN_LB, END_WEIGHT_TOO_SMALL),
	v.maxValue(MAX_END_WEIGHT_IN_LB, END_WEIGHT_TOO_LARGE),
);

export const EditCattleFeedRationStageFormSchema = v.pipe(
	v.object({
		stageName: stageNameSchema,
		startWeight: startWeightSchema,
		endWeight: endWeightSchema,
	}),
	v.forward(
		v.partialCheck(
			[['startWeight'], ['endWeight']],
			({ startWeight, endWeight }) => gt(endWeight, startWeight),
			END_WEIGHT_NOT_GT_START_WEIGHT,
		),
		['endWeight'],
	),
);

export type EditCattleFeedRationStageFormData = v.InferInput<typeof EditCattleFeedRationStageFormSchema>;
export type EditCattleFeedRationStageFormSchemaOutput = v.InferOutput<typeof EditCattleFeedRationStageFormSchema>;

export const EditCattleFeedRationStageFormSerializer = {
	serialize(stage: Pick<CattleFeedRationStage, 'name' | 'startWeightInLb' | 'endWeightInLb'>): EditCattleFeedRationStageFormData {
		return {
			stageName: stage.name,
			startWeight: stage.startWeightInLb?.toString() ?? '',
			endWeight: stage.endWeightInLb?.toString() ?? '',
		};
	},

	deserialize(
		{ stageName, startWeight, endWeight }: EditCattleFeedRationStageFormSchemaOutput,
		stageId: string,
	): Mutation_updateCattleFeedRationStageArgs {
		return {
			id: stageId,
			data: {
				name: stageName,
				startWeightInLb: startWeight,
				endWeightInLb: endWeight,
			},
		};
	},
};
