import { action } from '@ember/object';
import Component from '@glimmer/component';
import { TableColumn } from 'vault-client/types/vault-table';
import { getByCompositeKey } from 'vault-client/utils/general';
import { Select } from 'ember-power-select/components/power-select';

interface VaultTableCellArgs {
	cell: string | number | null;
	row: any;
	column: TableColumn;
	footerRow: boolean;
	rowIndex: number;
	buttonText: string;
	openModal: () => void;
	closeDropdown: () => void;
}

export default class VaultTableCell extends Component<VaultTableCellArgs> {
	get linkChildColumn() {
		const newColumn = { ...this.args.column };
		delete newColumn.linkRoute;
		delete newColumn.linkRoutePath;
		delete newColumn.linkModelPath;
		delete newColumn.linkQuery;
		return newColumn;
	}

	get linkQuery() {
		if (!this.args.column.linkQuery) {
			return {};
		}

		const queryObject = { ...this.args.column.linkQuery };

		Object.keys(queryObject).forEach((key) => {
			const value = queryObject[key];
			if (value?.rowKey) {
				queryObject[key] = value.rowKey
					.split('.')
					.reduce((prev: { [key: string]: any } | null, item: string) => (prev ? prev[item] : null), this.args.row);
			} else if (value?.staticValue) {
				queryObject[key] = value.staticValue;
			} else {
				queryObject[key] = null;
			}
		});

		return queryObject;
	}

	@action
	setSelectedDropDownOption(rowId: string, selectedItem: string) {
		this.args.column.componentArgs.selectedValue(rowId, selectedItem);
	}
	/**
	 * Handles the button click event. Triggers the `openModal` function if available
	 * and closes the dropdown menu (assuming we want to close the dropdown when the button is click. For opening a modal we do. May need to augment for future additional 'power-select/after-options' buttons).
	 *
	 * @action
	 * @param {Select} select - The select dropdown instance.
	 * @returns {void}
	 */
	@action
	handleButtonClick(row: unknown, select: Select) {
		const { openModal } = this.args.column.componentArgs;
		const type = this.args.row.categoryType ? this.args.row.categoryType : this.args.row.cropFieldLedgerType;
		if (typeof openModal === 'function') {
			openModal(type, row);
		}

		this.closeDropdown(select);
	}

	@action
	closeDropdown(select: Select) {
		select.actions.close();
	}

	@action
	getValuesByPaths(paths: string[], object: Record<PropertyKey, unknown>) {
		return paths.map((path) => getByCompositeKey(object, path));
	}
}
