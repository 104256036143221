import { CattleFeedRation } from 'vault-client/types/graphql-types';
import { TableColumn, CellComponents } from 'vault-client/types/vault-table';

export type RationsTableRow = Pick<CattleFeedRation, 'id' | 'name' | 'description'>;

export function getRationsTableColumns(args: {
	editRation: (ration: RationsTableRow) => void;
	deleteRation: (ration: RationsTableRow) => void;
}): TableColumn[] {
	return [
		{
			id: 'name',
			name: 'Ration Name',
			valuePath: 'name',
			cellComponent: CellComponents.String,
			width: 200,
			textAlign: 'left',
			isSortable: true,
			isFixed: '',
			isVisible: true,
			linkRoute: 'businesses.business.cattle.rations.show',
			linkModelPath: 'id',
		},
		{
			id: 'activeLots',
			name: 'Active Lots',
			valuePath: 'activeLots',
			cellComponent: CellComponents.String,
			headerTooltip: 'Lots actively feeding this Ration',
			width: 130,
			textAlign: 'right',
			headerTextAlign: 'right',
			isSortable: true,
			isFixed: '',
			isVisible: true,
		},
		{
			id: 'activeAnimals',
			name: '# of Animals',
			valuePath: 'numberOfCattle',
			cellComponent: CellComponents.String,
			headerTooltip: 'Animals actively feeding this Ration',
			width: 130,
			textAlign: 'right',
			headerTextAlign: 'right',
			isSortable: true,
			isFixed: '',
			isVisible: true,
		},
		{
			id: 'notes',
			name: 'Notes',
			valuePath: 'description',
			cellComponent: CellComponents.String,
			width: 200,
			textAlign: 'left',
			isSortable: true,
			isFixed: '',
			isVisible: true,
		},
		{
			id: 'edit-action',
			name: '',
			width: 80,
			minWidth: 80,
			maxWidth: 80,
			cellComponent: CellComponents.Button,
			componentArgs: {
				style: 'plain',
				iconOnlyButton: true,
				fn: args.editRation,
				centerIconHref: '/icons/Edit-Outline.svg#edit-outline',
				centerIconClass: 'icon-interactive-stroke',
			},
			isFixed: 'right',
			isSortable: false,
			isVisible: true,
		},
		{
			id: 'delete',
			name: '',
			width: 80,
			minWidth: 80,
			maxWidth: 80,
			textAlign: 'center',
			cellComponent: CellComponents.Button,
			componentArgs: {
				style: 'plain',
				iconOnlyButton: true,
				fn: args.deleteRation,
				centerIconHref: '/icons/Delete-Outline.svg#delete-outline',
				centerIconClass: 'icon-interactive-stroke',
			},
			isFixed: 'right',
			isSortable: false,
			isVisible: true,
		},
	];
}
