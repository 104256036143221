import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='mt-3 ml-1' id={{this.legendId}}></div>\n<div id='{{@id}}-container' class='relative h-80' ...attributes>\n\t<BarChart @id={{@id}} @options={{this.chartOptions}} @data={{@data}} @chartData={{this.chartData}} @plugins={{this.plugins}} />\n</div>", {"contents":"<div class='mt-3 ml-1' id={{this.legendId}}></div>\n<div id='{{@id}}-container' class='relative h-80' ...attributes>\n\t<BarChart @id={{@id}} @options={{this.chartOptions}} @data={{@data}} @chartData={{this.chartData}} @plugins={{this.plugins}} />\n</div>","moduleName":"vault-client/components/harvest-pnl-bar-chart/expenses.hbs","parseOptions":{"srcName":"vault-client/components/harvest-pnl-bar-chart/expenses.hbs"}});
import { guidFor } from '@ember/object/internals';
import { getCustomLegend, type CustomTooltipOptions } from 'vault-client/utils/chart-utils';
import Component from '@glimmer/component';
import type { ChartData, ChartDataset, ChartOptions, TooltipItem } from 'chart.js';
import { mapToDefaultChartColors } from 'vault-client/utils/chart';
import type { CropGroup } from 'vault-client/utils/grain/crop';
import type { ProjectedExpenses, ProjectedExpensesDefinedKey, ProjectedExpensesKey } from 'vault-client/utils/grain/dashboard';
import type { GenerateChartOptions, GenerateTooltipOptions } from '../harvest-pnl-bar-chart';

type ProjectedExpensesChartKey = 'Business' | CropGroup;
export type ProjectedExpensesChartRawData = Partial<Record<ProjectedExpensesChartKey, ProjectedExpenses>>;

export interface HarvestPnlBarChartExpensesSignature {
	// The arguments accepted by the component
	Args: {
		id: string;
		data: ProjectedExpensesChartRawData;
		generateTooltipOptions: GenerateTooltipOptions;
		generateChartOptions: GenerateChartOptions;
	};
	// Any blocks yielded by the component
	Blocks: {
		default: [];
	};
	// The element to which `...attributes` is applied in the component template
	Element: HTMLDivElement;
}

export default class HarvestPnlBarChartExpensesComponent extends Component<HarvestPnlBarChartExpensesSignature> {
	guid = guidFor(this);

	// Expense dataset keys that are excluded from the chart
	// Keys are non-deterministic due to using category names, so we need a blacklist
	excludedDataKeys: Set<ProjectedExpensesDefinedKey> = new Set(['totalExpenses']);

	// Defines which rows will be shown in business tooltip
	businessTooltipLabels: Set<ProjectedExpensesDefinedKey> = new Set(['Business Expenses']);

	// Defines which rows will be excluded from the crop tooltip
	excludedCropTooltipLabels: Set<ProjectedExpensesDefinedKey> = new Set(['Business Expenses']);

	get legendId() {
		return `${this.args.id}-legend-${this.guid}`;
	}

	get labels() {
		return Object.keys(this.args.data);
	}

	get values() {
		return Object.values(this.args.data);
	}

	get colorMap() {
		return mapToDefaultChartColors(this.dataKeys);
	}

	// Returns the keys for the datasets that are not excluded
	get dataKeys() {
		const { values, excludedDataKeys } = this;
		return Array.from(new Set([...values.flatMap((value) => Object.keys(value))])).filter(
			(key) => !excludedDataKeys.has(key as ProjectedExpensesDefinedKey),
		);
	}

	get chartData(): ChartData<'bar'> {
		const { dataKeys, colorMap, labels } = this;

		const datasets: ChartDataset<'bar'>[] = dataKeys.map((key) => {
			const data = this.values.map((value) => value[key] ?? 0);

			return {
				label: key,
				data,
				backgroundColor: colorMap[key],
			};
		});

		return {
			labels,
			datasets,
		};
	}

	get plugins() {
		const { id } = this.args;
		const { legendId } = this;
		return [
			{
				afterUpdate: getCustomLegend(id, legendId),
			},
		];
	}

	get tooltipOptions(): CustomTooltipOptions {
		return this.args.generateTooltipOptions({ dataPointFilter: this.dataPointFilter });
	}

	get chartOptions(): ChartOptions<'bar'> {
		return this.args.generateChartOptions({
			tooltipOptions: this.tooltipOptions,
		});
	}

	// Determine is a given data point should be included in the tooltip for a bar
	dataPointFilter = (tooltipItem: TooltipItem<'bar'>) => {
		const label = tooltipItem.label as ProjectedExpensesChartKey;
		const datasetLabel = tooltipItem.dataset.label;

		// Skip if the dataset label is not defined
		if (!datasetLabel) return false;

		if (label === 'Business') {
			return this.includeInBusinessTooltip(datasetLabel);
		} else {
			return this.includeInCropTooltip(datasetLabel);
		}
	};

	includeInBusinessTooltip = (key: ProjectedExpensesKey) => {
		return this.businessTooltipLabels.has(key as ProjectedExpensesDefinedKey);
	};

	includeInCropTooltip = (key: ProjectedExpensesKey) => {
		return !this.excludedCropTooltipLabels.has(key as ProjectedExpensesDefinedKey);
	};
}
