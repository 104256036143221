import { gql } from 'glimmer-apollo';
import type { CattleFeedRation, CattleFeedRationStageFilterDTO, CattleFeedRationStageSortByDTO } from 'vault-client/types/graphql-types';

export const GET_CATTLE_FEED_RATION = gql`
	query CattleFeedRation(
		$id: String!
		$scopeId: String
		$stagesOrderBy: CattleFeedRationStageSortByDTO
		$stagesWhere: CattleFeedRationStageFilterDTO
	) {
		CattleFeedRation(id: $id, scopeId: $scopeId) {
			id
			name
			description
			CattleFeedRationStages(orderBy: $stagesOrderBy, where: $stagesWhere) {
				id
				name
				startWeightInLb
				endWeightInLb
				CattleFeedRationStageIngredients {
					id
					volumeInWetLbPerHeadPerDay
					FeedIngredient {
						id
						name
					}
				}
			}
		}
	}
`;

export type CattleFeedRationQuery = {
	CattleFeedRation: {
		id: CattleFeedRation['id'];
		name: CattleFeedRation['name'];
		description: CattleFeedRation['description'];
		CattleFeedRationStages: Array<{
			id: CattleFeedRation['CattleFeedRationStages'][number]['id'];
			name: CattleFeedRation['CattleFeedRationStages'][number]['name'];
			startWeightInLb: CattleFeedRation['CattleFeedRationStages'][number]['startWeightInLb'];
			endWeightInLb: CattleFeedRation['CattleFeedRationStages'][number]['endWeightInLb'];
			CattleFeedRationStageIngredients: Array<{
				id: CattleFeedRation['CattleFeedRationStages'][number]['CattleFeedRationStageIngredients'][number]['id'];
				volumeInWetLbPerHeadPerDay: CattleFeedRation['CattleFeedRationStages'][number]['CattleFeedRationStageIngredients'][number]['volumeInWetLbPerHeadPerDay'];
				FeedIngredient: {
					id: CattleFeedRation['CattleFeedRationStages'][number]['CattleFeedRationStageIngredients'][number]['FeedIngredient']['id'];
					name: CattleFeedRation['CattleFeedRationStages'][number]['CattleFeedRationStageIngredients'][number]['FeedIngredient']['name'];
				};
			}>;
		}>;
	};
};

export type CattleFeedRationQueryVariables = {
	id: string;
	scopeId?: string | null;
	stagesOrderBy?: CattleFeedRationStageSortByDTO | null;
	stagesWhere?: CattleFeedRationStageFilterDTO | null;
};
