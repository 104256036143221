import type { Crop, CropLedgerEntryPerHarvestYear } from 'vault-client/types/graphql-types';
import { cropGroups, groupByCropGroup, type CropGroup } from '../crop';
import { getAdditionalExpensesByLedgerCategory, type CropGroupProjectedExpenses } from '../dashboard';

export type ProjectedExpensesByCropGroup = Partial<Record<CropGroup, CropGroupProjectedExpenses>>;

type getProjectedExpensesByCropGroupHelpers = {
	getExpenseLedgerEntries: (crop: Crop) => CropLedgerEntryPerHarvestYear[];
};

/**
 * Gets projected expenses organized by crop group
 *
 * @param crops - Array of crops
 * @param helpers - Helpers for getting expense ledger entries
 * @returns Projected expenses organized by crop group. The top 4 expenses are returned for each crop group. The rest are grouped into "Other".
 */
function getProjectedExpensesByCropGroup(crops: Crop[], helpers: getProjectedExpensesByCropGroupHelpers): ProjectedExpensesByCropGroup {
	const numExpensesToKeep = 4;
	const { getExpenseLedgerEntries } = helpers;
	const result: ProjectedExpensesByCropGroup = {};
	const cropsByCropGroup = groupByCropGroup(crops);

	// Process only crop groups with crops
	for (const cropGroup of cropGroups) {
		const cropsInGroup = cropsByCropGroup[cropGroup];
		if (!cropsInGroup.length) continue;

		// Get combined expenses for this crop group
		const expensesArray = getCombinedExpensesForCropGroup(cropsInGroup, (crop) =>
			getAdditionalExpensesByLedgerCategory(crop, getExpenseLedgerEntries(crop)),
		);

		// Format expenses for display
		result[cropGroup] = formatTopExpensesWithOther(expensesArray, numExpensesToKeep);
	}
	return result;
}

/**
 * Combines expenses from all crops in a group, using a reusable Map for performance
 */
function getCombinedExpensesForCropGroup(
	cropsInGroup: Crop[],
	getExpenses: (crop: Crop) => [{ id: string; name: string }, number][],
): Array<{ category: { id: string; name: string }; amount: number }> {
	// Use a Map for O(1) lookups by category ID
	const expenseCategoryMap = new Map<string, { category: { id: string; name: string }; amount: number }>();

	// Collect and combine expenses in a single pass
	for (const crop of cropsInGroup) {
		const expenses = getExpenses(crop);

		for (const [category, amount] of expenses) {
			const categoryId = category.id;
			const existing = expenseCategoryMap.get(categoryId);

			if (existing) {
				existing.amount += amount;
			} else {
				expenseCategoryMap.set(categoryId, { category, amount });
			}
		}
	}

	// Convert map to array and sort by amount (descending)
	const expensesArray = Array.from(expenseCategoryMap.values());
	expensesArray.sort((a, b) => b.amount - a.amount);

	return expensesArray;
}

/**
 * Formats expenses for display, keeping top N expenses and grouping the rest as "Other"
 */
function formatTopExpensesWithOther(
	sortedExpenses: Array<{ category: { id: string; name: string }; amount: number }>,
	numToKeep: number,
): Record<string, number> {
	const result: Record<string, number> = {};
	let otherExpenseAmount = 0;
	let totalExpenses = 0;

	// Process expenses in a single pass
	for (let i = 0; i < sortedExpenses.length; i++) {
		const { category, amount } = sortedExpenses[i];

		totalExpenses += amount;

		if (i < numToKeep) {
			result[category.name] = amount;
		} else {
			otherExpenseAmount += amount;
		}
	}

	// Add "Other" category if needed
	if (otherExpenseAmount > 0) {
		result['Other'] = otherExpenseAmount;
	}

	// Only add "totalExpenses" if there are expenses
	if (totalExpenses) {
		result['totalExpenses'] = totalExpenses;
	}

	return result;
}

export { getProjectedExpensesByCropGroup };
