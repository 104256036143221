import type { Crop } from 'vault-client/types/graphql-types';
import { isProductSlug } from '../type-utils';
import { cornCategorySlugs, soyBeanCategorySlugs, wheatCategorySlugs } from '../grain-utils';
import type { ProductSlug } from 'vault-client/types/vault-client';

const cropGroups = ['Corn', 'Soybeans', 'Wheat', 'Non-Commodity Crops'] as const;
type CropGroup = (typeof cropGroups)[number];

function getCropGroup(crop: Crop): CropGroup {
	const slug = crop?.Category?.HedgeProduct?.slug ?? '';
	if (!isProductSlug(slug)) return 'Non-Commodity Crops';

	if (cornCategorySlugs.includes(slug)) return 'Corn';
	if (soyBeanCategorySlugs.includes(slug)) return 'Soybeans';
	if (wheatCategorySlugs.includes(slug)) return 'Wheat';

	return 'Non-Commodity Crops';
}

function getSlugs(cropGroup: CropGroup): ProductSlug[] {
	if (cropGroup === 'Corn') return cornCategorySlugs;
	if (cropGroup === 'Soybeans') return soyBeanCategorySlugs;
	if (cropGroup === 'Wheat') return wheatCategorySlugs;
	return [];
}

/**
 * Groups crops by crop group. Will return an object with all crop groups as keys, even if they have no crops.
 * @param crops - Array of crops
 * @returns Object with crop groups as keys and arrays of crops as values
 */
function groupByCropGroup(crops: Crop[]): Record<CropGroup, Crop[]> {
	const initialGroups = cropGroups.reduce(
		(acc, group) => {
			acc[group] = [];
			return acc;
		},
		{} as Record<CropGroup, Crop[]>,
	);

	return crops.reduce((acc, crop) => {
		const group = getCropGroup(crop);
		acc[group].push(crop);
		return acc;
	}, initialGroups);
}

export { cropGroups, groupByCropGroup, getCropGroup, getSlugs, type CropGroup };
