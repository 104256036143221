import { assert } from '@ember/debug';
import { task } from 'ember-concurrency';
import BaseDeleteModalComponent from 'vault-client/components/base-delete-modal';
import { CattleFeedRation } from 'vault-client/types/graphql-types';
import { ERRORS } from 'vault-client/utils/cattle/errors';
import { useDeleteRationMutation } from 'vault-client/utils/cattle/rations/use-delete-ration-mutation';

export interface CattleRationsDeleteRationModalSignature {
	// The arguments accepted by the component
	Args: {
		itemToDelete: Pick<CattleFeedRation, 'id' | 'name'>;
	};
	// Any blocks yielded by the component
	Blocks: {
		default: [];
	};
	// The element to which `...attributes` is applied in the component template
	Element: null;
}

const RELATED_FIELD_NAMES = [
	'CattleFeedRations',
	'CattleFeedRationCount',
	'CattleFeedRationStageIngredients',
	'CattleFeedRationStages',
	'CattleFeedRation',
] as const;

export default class CattleRationsDeleteRationModalComponent extends BaseDeleteModalComponent<
	CattleRationsDeleteRationModalSignature['Args']
> {
	deleteItem = task({ drop: true }, async () => {
		const id = this.id;

		assert('No id provided to Delete Ration Component', id);

		await useDeleteRationMutation(this, {
			update: (cache) => {
				RELATED_FIELD_NAMES.forEach((fieldName) => {
					cache.evict({ fieldName });
				});
				cache.gc();
			},
			onError: (error) => {
				console.error('error', error.message);
				this.errorMessage = ERRORS.DELETE_CATTLE_RATION.DEFAULT;
			},
			onComplete: () => {
				if (this.args.redirectRoutePath) {
					this.router.transitionTo(this.args.redirectRoutePath);
				}
				this.args.closeConfirmation();
			},
		}).mutate({ id });
	});

	get ration() {
		return this.args.itemToDelete as Pick<CattleFeedRation, 'id' | 'name'>;
	}

	get titleText() {
		return 'Delete Ration';
	}

	get bodyText() {
		return 'Are you sure you want to delete this ration?';
	}

	get cancelButtonText() {
		return 'Cancel';
	}

	get deleteButtonText() {
		return 'Delete';
	}

	get label(): string {
		return this.ration.name;
	}
}
