import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<VaultTable\n\t@id={{@id}}\n\t@tableRoute={{@tableRoute}}\n\t@columns={{this.columns}}\n\t@rows={{@rows}}\n\t@sorts={{@sorts}}\n\t@setSorts={{@setSorts}}\n\t@setTablePageState={{@setTablePageState}}\n\t@columnControlTrigger={{@columnControlTrigger}}\n\t...attributes\n\tas |vt|\n>\n\t<vt.emptyStateWell class='mt-4 mb-4'>\n\t\t<:headerMessage as |default|>{{default}}</:headerMessage>\n\t</vt.emptyStateWell>\n</VaultTable>", {"contents":"<VaultTable\n\t@id={{@id}}\n\t@tableRoute={{@tableRoute}}\n\t@columns={{this.columns}}\n\t@rows={{@rows}}\n\t@sorts={{@sorts}}\n\t@setSorts={{@setSorts}}\n\t@setTablePageState={{@setTablePageState}}\n\t@columnControlTrigger={{@columnControlTrigger}}\n\t...attributes\n\tas |vt|\n>\n\t<vt.emptyStateWell class='mt-4 mb-4'>\n\t\t<:headerMessage as |default|>{{default}}</:headerMessage>\n\t</vt.emptyStateWell>\n</VaultTable>","moduleName":"vault-client/components/cattle/yards/yards-table.hbs","parseOptions":{"srcName":"vault-client/components/cattle/yards/yards-table.hbs"}});
import Component from '@glimmer/component';
import { CattleYard } from 'vault-client/types/graphql-types';
import { SortObj } from 'vault-client/types/vault-table';
import { getYardsTableColumns } from 'vault-client/utils/components/cattle/yards/yards-table';

export type CattleYardsYardsTableRow = Pick<CattleYard, 'id' | 'name' | 'description'>;

export interface CattleYardsYardsTableSignature {
	// The arguments accepted by the component
	Args: {
		id: string;
		downloadTableInCsvButtonId?: string;
		editYard: (yard: CattleYardsYardsTableRow) => void;
		deleteYard: (yard: CattleYardsYardsTableRow) => void;
		sorts: SortObj[];
		setSorts: (sorts: SortObj[]) => void;
		setTablePageState: (page: number) => void;
		rows: CattleYardsYardsTableRow[];
	};
	// Any blocks yielded by the component
	Blocks: {
		default: [];
	};
	// The element to which `...attributes` is applied in the component template
	Element: null;
}

export default class CattleYardsYardsTableComponent extends Component<CattleYardsYardsTableSignature> {
	get columns() {
		return getYardsTableColumns({
			editYard: this.args.editYard,
			deleteYard: this.args.deleteYard,
		});
	}
}
