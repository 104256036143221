import type { ProjectedNetPnl } from '../dashboard';
import type { BusinessProjectedRevenue } from '../dashboard';
import type { BusinessProjectedExpenses } from '../dashboard';

/**
 * Calculates the business-level projected net P&L from business projected revenue and expenses
 *
 * @param businessProjectedRevenue - The projected revenue for the business
 * @param businessProjectedExpenses - The projected expenses for the business
 * @returns Business-level projected net P&L values
 */
function getBusinessProjectedNetPnl({
	businessProjectedRevenue,
	businessProjectedExpenses,
}: {
	businessProjectedRevenue: BusinessProjectedRevenue;
	businessProjectedExpenses: BusinessProjectedExpenses;
}): ProjectedNetPnl {
	// Extract values with proper defaults
	const totalRevenue = businessProjectedRevenue.totalRevenue || 0;
	const totalExpenses = businessProjectedExpenses.totalExpenses || 0;
	const netPnl = totalRevenue - totalExpenses;

	return {
		totalRevenue,
		totalExpenses,
		netPnl,
	};
}

export { getBusinessProjectedNetPnl };
