export class CattlePnlMonth {
	id: string;
	date: string;
	cattleSalesRevenue: number | null;
	cmeCattleRevenue: number | null;
	otherRevenue: number | null;
	insurance: number | null;
	feedExpenses: number | null;
	lotExpenses: number | null;
	otherExpenses: number | null;
	numCattle: number | null;
	totalWeight: number | null;
	cmeFeedRevenue: number | null;
	cmeCattleHedged: number | null;
	cmeFeedHedged: number | null;
	constructor(
		date: string,
		cattleSalesRevenue: number | null,
		otherRevenue: number | null,
		insurance: number | null,
		feedExpenses: number | null,
		lotExpenses: number | null,
		otherExpenses: number | null,
		numCattle: number | null,
		totalWeight: number | null,
		cmeCattleRevenue: number | null,
		cmeFeedRevenue: number | null,
		cmeCattleHedged: number | null,
		cmeFeedHedged: number | null,
	) {
		this.id = date;
		this.date = date;
		this.cattleSalesRevenue = cattleSalesRevenue;
		this.cmeCattleRevenue = cmeCattleRevenue;
		this.otherRevenue = otherRevenue;
		this.insurance = insurance;
		this.feedExpenses = feedExpenses;
		this.lotExpenses = lotExpenses;
		this.otherExpenses = otherExpenses;
		this.numCattle = numCattle;
		this.totalWeight = totalWeight;
		this.cmeFeedRevenue = cmeFeedRevenue;
		this.cmeCattleHedged = cmeCattleHedged;
		this.cmeFeedHedged = cmeFeedHedged;
	}

	get netPnl() {
		return (
			(this.cattleSalesRevenue ?? 0) +
			(this.cmeCattleAmt ?? 0) +
			(this.insurance ?? 0) +
			(this.feedExpenses ?? 0) +
			(this.lotExpenses ?? 0) +
			(this.otherExpenses ?? 0) +
			(this.otherRevenue ?? 0) +
			(this.cmeFeedRevenue ?? 0)
		);
	}

	get netPnlPerHead() {
		return this.getValuePerHead(this.netPnl);
	}

	get netPnlCwt() {
		return this.getValuePerCwt(this.netPnl);
	}

	get otherRev() {
		if (this.otherRevenue == null) return null;
		return this.otherRevenue ?? 0;
	}

	get otherRevenuePerHead() {
		return this.getValuePerHead(this.otherRevenue);
	}

	get otherRevenueCwt() {
		return this.getValuePerCwt(this.otherRevenue);
	}

	get totalRevenue() {
		if (this.cattleSalesRevenue == null && this.otherRevenue == null) return null;

		return (this.cattleSalesRevenue ?? 0) + (this.otherRevenue ?? 0);
	}

	get totalRevenuePerHead() {
		return this.getValuePerHead(this.totalRevenue);
	}

	get totalRevenueCwt() {
		return this.getValuePerCwt(this.totalRevenue);
	}

	get totalHedged() {
		if (this.cmeCattleRevenue == null && this.insurance == null) return null;
		return (this.cmeCattleRevenue ?? 0) + (this.insurance ?? 0);
	}

	get totalHedgedPerHead() {
		return this.getValuePerHead(this.totalHedged);
	}

	get totalHedgedCwt() {
		return this.getValuePerCwt(this.totalHedged);
	}

	get otherExp() {
		return (this.otherExpenses ?? 0) + (this.otherRevenue ?? 0);
	}

	get otherExpensesPerHead() {
		return this.getValuePerHead(this.otherExpenses);
	}

	get otherExpensesCwt() {
		return this.getValuePerCwt(this.otherExpenses);
	}

	get totalExpenses() {
		return (this.feedExpenses ?? 0) + (this.otherExpenses ?? 0) + (this.lotExpenses ?? 0);
	}

	get totalExpensesPerHead() {
		return this.getValuePerHead(this.totalExpenses);
	}

	get totalExpensesCwt() {
		return this.getValuePerCwt(this.totalExpenses);
	}

	get cmeCattleAmt() {
		if (this.cmeCattleRevenue == null && this.cmeCattleHedged == null) return null;
		return (this.cmeCattleRevenue ?? 0) + (this.cmeCattleHedged ?? 0);
	}

	get cmeCattlePerHead() {
		return this.getValuePerHead(this.cmeCattleAmt);
	}

	get cmeCattleCwt() {
		return this.getValuePerCwt(this.cmeCattleAmt);
	}

	get cattleSalesPerHead() {
		return this.getValuePerHead(this.cattleSalesRevenue);
	}

	get cattleSalesCwt() {
		return this.getValuePerCwt(this.cattleSalesRevenue);
	}

	get insurancePnlPerHead() {
		return this.getValuePerHead(this.insurance);
	}

	get insurancePnlCwt() {
		return this.getValuePerCwt(this.insurance);
	}

	get feedExpensesPerHead() {
		return this.getValuePerHead(this.feedExpenses);
	}

	get feedExpensesCwt() {
		return this.getValuePerCwt(this.feedExpenses);
	}

	get lotExpensesPerHead() {
		return this.getValuePerHead(this.lotExpenses);
	}

	get lotExpensesCwt() {
		return this.getValuePerCwt(this.lotExpenses);
	}

	get cmeFeedAmt() {
		if (this.cmeFeedRevenue == null && this.cmeFeedHedged == null) return null;
		return (this.cmeFeedRevenue ?? 0) + (this.cmeFeedHedged ?? 0);
	}

	get cmeFeedPerHead() {
		return this.getValuePerHead(this.cmeFeedAmt);
	}

	get cmeFeedCwt() {
		return this.getValuePerCwt(this.cmeFeedAmt);
	}

	getValuePerHead(value: number | null) {
		if (value == null || this.numCattle == null) return null;
		if (this.numCattle === 0) return 0;
		return value / this.numCattle;
	}

	getValuePerCwt(value: number | null) {
		if (value == null || this.totalWeight == null) return null;
		if (this.totalWeight === 0) return 0;
		return value / (this.totalWeight / 100);
	}
}
