import { assert } from '@ember/debug';
import { task } from 'ember-concurrency';
import BaseDeleteModalComponent from 'vault-client/components/base-delete-modal';
import type { CattleFeedRationStageIngredient, FeedIngredient } from 'vault-client/types/graphql-types';
import { ERRORS } from 'vault-client/utils/cattle/errors';
import { useDeleteCattleFeedRationStageIngredientMutation } from 'vault-client/utils/cattle/rations/stages/ingredients/delete-cattle-feed-ration-stage-ingredient';

export interface DeleteCattleFeedRationStageIngredientModalArgs {
	Args: {
		ingredient:
			| (Pick<CattleFeedRationStageIngredient, 'id'> & {
					FeedIngredient: Pick<FeedIngredient, 'name'>;
			  })
			| null;
		onSuccess: () => void;
		closeConfirmation: () => void;
	};
	Blocks: {
		default: [];
	};
	Element: null;
}

export default class DeleteCattleFeedRationStageIngredientModal extends BaseDeleteModalComponent<
	DeleteCattleFeedRationStageIngredientModalArgs['Args']
> {
	deleteItem = task({ drop: true }, async () => {
		const id = this.id;

		assert('No id provided to Delete Cattle Feed Ration Stage Ingredient Component', id);

		await useDeleteCattleFeedRationStageIngredientMutation(this, {
			onError: () => {
				this.errorMessage = ERRORS.DELETE_CATTLE_FEED_RATION_STAGE_INGREDIENT.DEFAULT;
			},
			onComplete: () => {
				this.closeConfirmation();

				if (this.args.onSuccess) {
					this.args.onSuccess();
				}

				if (this.args.redirectRoutePath) {
					this.redirect();
				}
			},
		}).mutate({ id });
	});

	get id() {
		return this.args.ingredient?.id;
	}

	get titleText() {
		return 'Delete Ingredient';
	}

	get bodyText() {
		return 'Are you sure you want to delete this ingredient?';
	}

	get cancelButtonText() {
		return 'Cancel';
	}

	get deleteButtonText() {
		return 'Delete';
	}

	get label(): string {
		return this.args.ingredient?.FeedIngredient.name ?? '';
	}
}
