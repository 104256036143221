import * as v from 'valibot';
import { float } from 'vault-client/utils/validations/actions/float';
import { ERRORS } from 'vault-client/utils/cattle/errors';
import { FormError } from 'vault-client/utils/forms/form-error';
import { SortByDirection, type Mutation_createCattleFeedRationStageIngredientArgs } from 'vault-client/types/graphql-types';
import type { FeedIngredientsQueryVariables } from 'vault-client/graphql/queries/feed/ingredients';

const {
	CREATE_CATTLE_FEED_RATION_STAGE_INGREDIENT: { INVALID_USAGE, MISSING_INGREDIENT_ID },
} = ERRORS;
const MIN_USAGE = 0;

export const CreateCattleFeedRationStageIngredientFormSchema = v.object({
	feedIngredientId: v.pipe(v.string(MISSING_INGREDIENT_ID), v.nonEmpty(MISSING_INGREDIENT_ID)),
	usage: v.pipe(
		v.string(INVALID_USAGE),
		float(INVALID_USAGE),
		v.transform((value) => +value),
		v.minValue(MIN_USAGE, INVALID_USAGE),
	),
});

export type CreateCattleFeedRationStageIngredientFormData = v.InferInput<typeof CreateCattleFeedRationStageIngredientFormSchema>;
export type CreateCattleFeedRationStageIngredientFormSchemaOutput = v.InferOutput<typeof CreateCattleFeedRationStageIngredientFormSchema>;

export const CreateCattleFeedRationStageIngredientFormSerializer = {
	deserialize: ({
		schema,
		data,
		businessId,
		stageId,
	}: {
		schema: typeof CreateCattleFeedRationStageIngredientFormSchema;
		data: CreateCattleFeedRationStageIngredientFormData;
		businessId: string;
		stageId: string;
	}): Mutation_createCattleFeedRationStageIngredientArgs => {
		const { output: parsedData, success } = v.safeParse(schema, data);
		if (!success) {
			// This should never happen, the form will always be valid before deserialize is called
			throw new FormError([ERRORS.CREATE_CATTLE_FEED_RATION_STAGE_INGREDIENT.DEFAULT]);
		}

		return {
			data: {
				businessId,
				cattleFeedRationStageId: stageId,
				feedIngredientId: parsedData.feedIngredientId,
				volumeInWetLbPerHeadPerDay: parsedData.usage,
			},
		};
	},
};

export function getFeedIngredientQueryVariables({
	businessId,
	stageId,
}: {
	businessId: string;
	stageId: string;
}): FeedIngredientsQueryVariables {
	return {
		scopeId: businessId,
		sortBy: [
			{
				name: SortByDirection.Asc,
			},
		],
		where: {
			NOT: {
				CattleFeedRationStageIngredients: {
					cattleFeedRationStageId: {
						equals: stageId,
					},
				},
			},
		},
	};
}
