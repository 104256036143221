import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<VaultTable\n\t@id={{@id}}\n\t@tableRoute={{@tableRoute}}\n\t@columns={{this.columns}}\n\t@rows={{@rows}}\n\t@widthConstraint='eq-container'\n\t@resizeMode='fluid'\n\t@columnControlTrigger='cattle-rations-table-column-control-trigger'\n\tas |vt|\n>\n\t<vt.emptyStateWell class='mt-4 mb-4'>\n\t\t<:headerMessage as |default|>{{default}}</:headerMessage>\n\t</vt.emptyStateWell>\n</VaultTable>", {"contents":"<VaultTable\n\t@id={{@id}}\n\t@tableRoute={{@tableRoute}}\n\t@columns={{this.columns}}\n\t@rows={{@rows}}\n\t@widthConstraint='eq-container'\n\t@resizeMode='fluid'\n\t@columnControlTrigger='cattle-rations-table-column-control-trigger'\n\tas |vt|\n>\n\t<vt.emptyStateWell class='mt-4 mb-4'>\n\t\t<:headerMessage as |default|>{{default}}</:headerMessage>\n\t</vt.emptyStateWell>\n</VaultTable>","moduleName":"vault-client/components/cattle/rations/rations-table.hbs","parseOptions":{"srcName":"vault-client/components/cattle/rations/rations-table.hbs"}});
import Component from '@glimmer/component';
import { getRationsTableColumns, type RationsTableRow } from 'vault-client/utils/components/cattle/rations/rations-table';

export interface RationsTableSignature {
	Args: {
		id: string;
		tableRoute?: string;
		downloadTableInCsvButtonId?: string;
		editRation: (ration: RationsTableRow) => void;
		deleteRation: (ration: RationsTableRow) => void;
		rows: RationsTableRow[];
	};
	Blocks: {
		default: [];
	};
	Element: null;
}

export default class RationsTable extends Component<RationsTableSignature> {
	get columns() {
		return getRationsTableColumns({
			editRation: this.args.editRation,
			deleteRation: this.args.deleteRation,
		});
	}
}
