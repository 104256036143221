import Controller from '@ember/controller';
import { DateTime, Interval } from 'luxon';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import { TableColumn, CellComponents } from 'vault-client/types/vault-table';
import { v5 as uuid } from 'uuid';
import { camelize, classify } from '@ember/string';
import {
	ILedgerCategory,
	LedgerRevenueCategory,
	LedgerRevenueCategoryCreateDTO,
	Mutation_createLedgerRevenueCategoryArgs,
	TypeOfLedgerEntry,
	TypeOfLedgerCalculation,
	BusinessEntityRole,
	Maybe,
} from 'vault-client/types/graphql-types';
import { gql, useMutation } from 'glimmer-apollo';
import { TYPE_OF_CALCULATIONS_LABELS } from './business-settings';
import { ModelFrom } from 'vault-client/utils/type-utils';
import BusinessesBusinessRevenuesRoute from 'vault-client/routes/businesses/business/revenues';
import {
	getLedgerCalculationType,
	getSelectableUnits,
	getSupportedBusinessRole,
	type DynamicUnit,
	type SupportedBusinessRole,
} from 'vault-client/utils/operations';

interface AggregateRevenueRow {
	id: string;
	month: string;
	forecastedTotal?: number;
	// Procedurally generated keys based on ledger category name
	[key: string]: Maybe<number> | string | undefined;
}
interface AggregateLedgerEntryTotal {
	sum: {
		amount: number;
		calculatedAmount: number | null;
	};
	avg: {
		amount: number;
	};
	categoryId: string;
	type: TypeOfLedgerEntry;
	LedgerCategory: ILedgerCategory;
}

interface DateFilterOption {
	displayName: string;
	startDate: string | null;
	endDate: string | null;
}

function* months(interval: Interval) {
	let cursor = interval.start.startOf('month');
	while (cursor < interval.end) {
		yield cursor;
		cursor = cursor.plus({ month: 1 });
	}
}

const CREATE_LEDGER_REVENUE_CATEGORY = gql`
	mutation createLedgerRevenueCategory($data: LedgerRevenueCategoryCreateDTO!) {
		createLedgerRevenueCategory(data: $data) {
			id
			name
		}
	}
`;

type CreateLedgerRevenueCategoryMutation = {
	__typename?: 'Mutation';

	deleteForecastedMilkUtilization?: {
		data: LedgerRevenueCategoryCreateDTO;
	} | null;
};

export default class BusinessesBusinessRevenues extends Controller {
	declare model: ModelFrom<BusinessesBusinessRevenuesRoute>;
	@tracked startDate: string | null = DateTime.local().startOf('year').toISODate();
	@tracked endDate: string | null = DateTime.local().endOf('year').toISODate();
	@tracked selectedRows: any[] = [];
	@tracked categoryName: string = '';
	@tracked dynamicUnit: Maybe<DynamicUnit> = null;

	operationsRoutePath = 'businesses.business.operations';

	uuidNamespace = '281db24c-63f2-4e6f-8359-d086825ea09a';

	get showUpdateValuesButton() {
		return this.selectedRows.length > 0;
	}

	get hasNonDairyProducerBusinessRole() {
		const businessRoles = this.model.getRevenues.data?.Customer?.businessRoles ?? [];
		return businessRoles.filter((role: string) => !role.includes(BusinessEntityRole.DairyProducer));
	}

	get columns(): TableColumn[] {
		const revenueEntries: TableColumn[] = (this.model.getRevenues.data?.LedgerRevenueCategories as LedgerRevenueCategory[])
			.map((LedgerRevenueCategory) => {
				return {
					id: uuid(LedgerRevenueCategory.id, this.uuidNamespace),
					name: LedgerRevenueCategory.name,
					valuePath: LedgerRevenueCategory.isStatic ? camelize(LedgerRevenueCategory.id + ' Forecasted') : undefined,
					minWidth: 220,
					textAlign: 'right',
					isSortable: true,
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						currencySign: 'accounting',
					},
					isFixed: '',
					isVisible: true,
					isTotaled: true,
					subcolumns: this.getSubcolumns(LedgerRevenueCategory),
				};
			})
			.sortBy('name');

		return [
			{
				id: 'f0bbbe46-6233-4159-b13d-7a1f7635a0a9',
				name: 'Month',
				valuePath: 'month',
				minWidth: 150,
				textAlign: 'left',
				isSortable: true,
				isReorderable: false,
				cellComponent: CellComponents.MonthFormat,
				isFixed: '',
				isVisible: true,
			},
			...revenueEntries,
			{
				id: '476d5d0b-e771-4b01-a630-e461d9a8f4d4',
				name: 'Total',
				valuePath: 'forecastedTotal',
				minWidth: 150,
				textAlign: 'right',
				isSortable: false,
				isReorderable: false,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				isFixed: 'right',
				isVisible: true,
				isTotaled: true,
			},
		];
	}

	queryParams = ['startDate', 'endDate'];

	get data(): AggregateRevenueRow[] {
		const map = new Map<string, AggregateRevenueRow>();

		this.model.getRevenues.data?.RevenueEntries?.forEach((aggregateLedgerEntry) => {
			const month = aggregateLedgerEntry.month;
			const year = aggregateLedgerEntry.year;
			const categoryId = aggregateLedgerEntry.categoryId;

			if (!month || !year || !categoryId) {
				console.warn(`Month, Year, or Category Name not found for revenue entry`);
				return;
			}

			const date = DateTime.local(year, month).toISODate();

			if (!map.get(date)) {
				map.set(date, { month: date, id: date });
			}

			if (aggregateLedgerEntry.type === TypeOfLedgerEntry.Actual) {
				const actualValuePath = `${camelize(categoryId + ' Actual')}`;
				const actualTotalValuePath = 'actualTotal';
				const row = map.get(date);

				if (!row) return;

				const amount = aggregateLedgerEntry.sum.amount;

				if (typeof amount !== 'number') return;

				row[actualValuePath] = amount;

				if (row[actualTotalValuePath] && typeof row[actualTotalValuePath] === 'number') {
					row[actualTotalValuePath] = (row[actualTotalValuePath] ?? 0) + amount;
				} else {
					row[actualTotalValuePath] = amount;
				}
			} else {
				const forecastedAggregateEntryTotalValuePath = 'forecastedTotal';
				const dynamicCategoryAggregateEntryTotalValuePath = categoryId ? `${camelize(categoryId + 'AggregateEntryTotal')}` : null;
				const forecastedValuePath = `${camelize(categoryId + ' Forecasted')}`;

				const row = map.get(date);
				if (!row) return;

				if (aggregateLedgerEntry.LedgerCategory?.isStatic) {
					const amount = aggregateLedgerEntry.sum.amount;
					row[forecastedValuePath] = aggregateLedgerEntry.sum.amount;
					if (typeof amount !== 'number') return;
					row[forecastedAggregateEntryTotalValuePath]
						? (row[forecastedAggregateEntryTotalValuePath] += amount)
						: (row[forecastedAggregateEntryTotalValuePath] = amount);
				} else {
					row[forecastedValuePath] =
						aggregateLedgerEntry.LedgerCategory?.calculationType === TypeOfLedgerCalculation.DairyCwt ||
						aggregateLedgerEntry.LedgerCategory?.calculationType === TypeOfLedgerCalculation.CattleCwt
							? aggregateLedgerEntry.avg.amount
							: aggregateLedgerEntry.sum.amount;
					const totalAmount = aggregateLedgerEntry.sum.calculatedAmount;
					if (typeof totalAmount !== 'number' || dynamicCategoryAggregateEntryTotalValuePath === null) return;

					row[dynamicCategoryAggregateEntryTotalValuePath] = totalAmount;

					row[forecastedAggregateEntryTotalValuePath]
						? (row[forecastedAggregateEntryTotalValuePath] += totalAmount)
						: (row[forecastedAggregateEntryTotalValuePath] = totalAmount);
				}
			}
		});

		const interval = Interval.fromISO(`${this.startDate}/${this.endDate}`);
		const currentMonths: { [key: string]: number | undefined } = {};

		// Make dict of months that are already present
		for (const currentMonth of map.keys()) {
			const date = DateTime.fromISO(currentMonth).startOf('month').toISODate();
			currentMonths[date] = 1;
		}

		// Fill in month gaps if necessary
		for (const date of months(interval)) {
			if (!currentMonths[date.toISODate()]) {
				map.set(date.toISODate(), { month: date.toISODate(), id: date.toISODate() });
			}
		}
		return Array.from(map.values()).sortBy('month');
	}

	get columnTotals() {
		const totals: { [key: string]: number } = {};

		this.model.getRevenues.data?.RevenueEntryTotals.forEach((ledgerEntryTotal: AggregateLedgerEntryTotal) => {
			const categoryId = ledgerEntryTotal.categoryId;
			if (!categoryId) {
				console.warn('Category Name not found for revenue entry total');
				return;
			}
			const staticCategoryTotalValuePath = `${camelize(categoryId + ' ' + ledgerEntryTotal.type)}`;
			const dynamicCategoryTotalValuePath = `${camelize(categoryId + 'AggregateEntryTotal')}`;
			const forecastedAllCategoriesTotalValuePath = `${camelize(ledgerEntryTotal.type + 'Total')}`;
			if (ledgerEntryTotal.LedgerCategory.isStatic) {
				totals[staticCategoryTotalValuePath] = ledgerEntryTotal.sum.calculatedAmount ?? 0;
			} else {
				totals[dynamicCategoryTotalValuePath] = ledgerEntryTotal.sum.calculatedAmount ?? 0;
			}

			totals[forecastedAllCategoriesTotalValuePath]
				? (totals[forecastedAllCategoriesTotalValuePath] += ledgerEntryTotal.sum.calculatedAmount ?? 0)
				: (totals[forecastedAllCategoriesTotalValuePath] = ledgerEntryTotal.sum.calculatedAmount ?? 0);
		});

		return [totals];
	}

	get csvFileName() {
		return this.model.getRevenues.data?.Customer ? `revenue-${classify(this.model.getRevenues.data.Customer.name)}.csv` : null;
	}

	get dateRangeOptions(): DateFilterOption[] {
		return [
			{
				displayName: 'Previous Year',
				startDate: DateTime.local().minus({ year: 1 }).startOf('year').toISODate(),
				endDate: DateTime.local().minus({ year: 1 }).endOf('year').toISODate(),
			},
			{
				displayName: `Current Year`,
				startDate: DateTime.local().startOf('year').toISODate(),
				endDate: DateTime.local().endOf('year').toISODate(),
			},
			{
				displayName: `Calendar Year (${DateTime.local().plus({ year: 1 }).year.toString()})`,
				startDate: DateTime.local().plus({ year: 1 }).startOf('year').toISODate(),
				endDate: DateTime.local().plus({ year: 1 }).endOf('year').toISODate(),
			},
			{
				displayName: `Calendar Year (${DateTime.local().plus({ year: 2 }).year.toString()})`,
				startDate: DateTime.local().plus({ year: 2 }).startOf('year').toISODate(),
				endDate: DateTime.local().plus({ year: 2 }).endOf('year').toISODate(),
			},
		];
	}

	get dateRangeQueryParam() {
		return {
			startDate: this.startDate,
			endDate: this.endDate,
		};
	}

	get selectableUnits() {
		return getSelectableUnits(this.businessRole);
	}

	get businessRole(): Maybe<SupportedBusinessRole> {
		return getSupportedBusinessRole(this.model.getRevenues.data?.Customer?.businessRoles ?? []);
	}

	@action
	getSubcolumns(LedgerRevenueCategory: LedgerRevenueCategory) {
		const unitCalculationType = this.model.getRevenues.data?.LedgerRevenueCategories.find(
			(category) => category.id == LedgerRevenueCategory.id,
		)?.calculationType;

		if (unitCalculationType != null)
			return [
				{
					id: uuid(LedgerRevenueCategory.id + 'Dynamic', this.uuidNamespace),
					name: `${TYPE_OF_CALCULATIONS_LABELS[unitCalculationType as keyof typeof TYPE_OF_CALCULATIONS_LABELS]}`,
					valuePath: camelize(LedgerRevenueCategory.id + 'Forecasted'),
					minWidth: 150,
					textAlign: 'left',
					isSortable: true,
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						currencySign: 'accounting',
					},
					isFixed: '',
					isVisible: true,
					isTotaled: false,
				},
				{
					id: uuid(LedgerRevenueCategory.id + 'Total', this.uuidNamespace),
					name: 'Total',
					valuePath: camelize(LedgerRevenueCategory.id + 'AggregateEntryTotal'),
					minWidth: 150,
					textAlign: 'right',
					isSortable: false,
					isReorderable: false,
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						currencySign: 'accounting',
					},
					isFixed: 'right',
					isVisible: true,
					isTotaled: true,
				},
			];
		return [];
	}

	@action
	setDateRangeQueryParam(value: { startDate?: string; endDate?: string }) {
		this.startDate = value.startDate || null;
		this.endDate = value.endDate || null;
	}

	@action
	setTablePageState() {
		resetVaultTableScroll('locations-production-table');
	}

	@action
	clear() {
		this.categoryName = '';
	}

	@action
	async submit() {
		const categoryNameTrimmed = this.categoryName.trim();

		if (!categoryNameTrimmed) {
			console.warn('Category name must be supplied');
			return;
		}

		const createLedgerRevenueCategory = useMutation<CreateLedgerRevenueCategoryMutation, Mutation_createLedgerRevenueCategoryArgs>(
			this,
			() => [CREATE_LEDGER_REVENUE_CATEGORY],
		);

		await createLedgerRevenueCategory.mutate({
			data: {
				name: categoryNameTrimmed,
				customerId: this.model.businessId,
				calculationType: getLedgerCalculationType(this.businessRole, this.dynamicUnit),
			},
		});

		this.clear();

		await this.model.getRevenues.refetch();
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/revenues': BusinessesBusinessRevenues;
	}
}
