import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import { CropTransactionFilterDTO, CropTransactionSortByDTO, Query } from 'vault-client/types/graphql-types';
import { SortObj } from 'vault-client/types/vault-table';
import { generateOrderBy } from 'vault-client/utils/general';

interface QueryParams {
	page: number;
	size: number;
	sorts: SortObj[];
	deliveryStartDateStart: string | null;
	deliveryStartDateEnd: string | null;
	deliveryEndDateStart: string | null;
	deliveryEndDateEnd: string | null;
	cropIds: string[] | null;
	customerId: string | null;
	location: string | null;
	buyer: string | null;
}

interface GetCropTransactionsArgs {
	scopeId: string;
	where?: CropTransactionFilterDTO;
	offset?: number;
	limit?: number;
	orderBy?: CropTransactionSortByDTO;
}

interface GetCropTransactionsQuery {
	Entity: Query['Entity'];
	FeedIngredients: Query['FeedIngredients'];
	CropTransactions: Query['CropTransactions'];
	CropTransactionsCount: Query['CropTransactionCount'];
	Crops: Query['Crops'];
}

const GET_CROP_CONTRACTS = gql`
	query GetCropContracts(
		$where: CropTransactionFilterDTO
		$scopeId: String!
		$offset: Float
		$limit: Float
		$orderBy: CropTransactionSortByDTO
	) {
		Entity(id: $scopeId) {
			id
			isVgs
			CurrentUserPermissions {
				canWriteOperations
			}
		}
		CropTransactions(scopeId: $scopeId, where: $where, offset: $offset, limit: $limit, orderBy: $orderBy) {
			id
			pricingType
			buyer
			location
			flatPrice
			deliveryStartDate
			deliveryEndDate
			contractIdentifier
			futuresPrice
			basisPrice
			salesDate
			harvestYear
			isManuallyAdded
			bushels
			deliveryTerms
			futuresMonthStartDate
			Crop {
				id
				name
				hedgeProductId
			}
			Business {
				id
				name
			}
		}
		CropTransactionsCount: CropTransactionCount(scopeId: $scopeId, where: $where) {
			count
		}
		Crops(where: { businessId: { equals: $scopeId } }, orderBy: { name: Asc }) {
			id
			name
			hedgeProductId
		}
	}
`;

export default class CropContractsRoute extends Route {
	templateName = 'crop-contracts/index';

	queryParams = {
		page: { refreshModel: true },
		sorts: { refreshModel: true },
		size: { refreshModel: true },
		deliveryStartDateStart: { refreshModel: true },
		deliveryStartDateEnd: { refreshModel: true },
		deliveryEndDateStart: { refreshModel: true },
		deliveryEndDateEnd: { refreshModel: true },
		cropIds: { refreshModel: true },
		customerId: { refreshModel: true },
		location: { refreshModel: true },
		buyer: { refreshModel: true },
	};

	@tracked variables: GetCropTransactionsArgs = {
		scopeId: '',
	};

	scopeId: string | null = null;

	getCropContracts = useQuery<GetCropTransactionsQuery, GetCropTransactionsArgs>(this, () => [
		GET_CROP_CONTRACTS,
		{ variables: this.variables },
	]);

	@service declare router: RouterService;

	async model(params: QueryParams) {
		if (!this.scopeId) {
			throw new Error('scopeId not found in Feed Contracts model');
		}

		const where = this.generateWhere(
			params.deliveryStartDateStart,
			params.deliveryStartDateEnd,
			params.deliveryEndDateStart,
			params.deliveryEndDateEnd,
			params.cropIds,
			params.customerId,
			params.location,
			params.buyer,
		);

		const orderBy = generateOrderBy<CropTransactionSortByDTO>(params.sorts);

		this.variables = { scopeId: this.scopeId, where, offset: params.page * params.size, limit: params.size, orderBy };

		await this.getCropContracts.promise;

		return {
			where,
			scopeId: this.scopeId,
			variables: this.variables,
			getCropContracts: this.getCropContracts,
			query: GET_CROP_CONTRACTS,
		};
	}

	generateWhere(
		deliveryStartDateStart: string | null,
		deliveryStartDateEnd: string | null,
		deliveryEndDateStart: string | null,
		deliveryEndDateEnd: string | null,
		cropIds: string[] | null,
		customerId: string | null,
		location: string | null,
		buyer: string | null,
	): CropTransactionFilterDTO {
		const where: CropTransactionFilterDTO = {};

		this.setDateFilter(where, 'deliveryStartDate', deliveryStartDateStart, deliveryStartDateEnd);

		this.setDateFilter(where, 'deliveryEndDate', deliveryEndDateStart, deliveryEndDateEnd);

		if (cropIds?.length) {
			where.cropId = { in: cropIds };
		}

		if (customerId) {
			where.businessId = { equals: customerId };
		}

		if (location) {
			where.location = { equals: location };
		}

		if (buyer) {
			where.buyer = { equals: buyer };
		}

		return where;
	}

	setDateFilter(
		where: CropTransactionFilterDTO,
		key: 'deliveryStartDate' | 'deliveryEndDate',
		startDate: string | null,
		endDate: string | null,
		defaultStart = '1900-01-01',
		defaultEnd = '2999-12-31',
	) {
		if (startDate && startDate !== defaultStart && endDate && endDate !== defaultEnd) {
			where[key] = { gte: startDate, lte: endDate };
		} else if (startDate && startDate !== defaultStart) {
			where[key] = { gte: startDate };
		} else if (endDate && endDate !== defaultEnd) {
			where[key] = { lte: endDate };
		}
	}
}
