import Route from '@ember/routing/route';
import { DateTime } from 'luxon';
import { queryProjectedPl } from 'vault-client/utils/cattle/projected-pl/projected-pl-utils';
import { RouteQueryParam } from '@ember/routing/types';

interface ModelParams {
	startDate: string;
	endDate: string;
}

export default class BusinessesBusinessCattleCattleProjectedPlRoute extends Route {
	queryParams: { [key: string]: RouteQueryParam } = {
		startDate: {
			refreshModel: true,
		},
		endDate: {
			refreshModel: true,
		},
	};

	async model(params: ModelParams) {
		const { business_id: businessId } = this.paramsFor('businesses.business') as { business_id: string };

		const startDate = params.startDate ?? DateTime.now().startOf('month').toISODate();
		const endDate = params.endDate ?? DateTime.now().plus({ months: 24 }).endOf('month').toISODate();
		const getCattleProjectedPl = queryProjectedPl(this, businessId, startDate, endDate);
		await getCattleProjectedPl.promise;

		return {
			lastUpdatedAt: DateTime.now().toISO(),
			getCattleProjectedPl,
			businessId,
			startDate,
			endDate,
		};
	}
}
