import { assert } from '@ember/debug';
import { task } from 'ember-concurrency';
import BaseDeleteModalComponent from 'vault-client/components/base-delete-modal';
import { CattleYard } from 'vault-client/types/graphql-types';
import { ERRORS } from 'vault-client/utils/cattle/errors';
import { useDeleteCattleYardMutation } from 'vault-client/utils/cattle/yards/delete-cattle-yard';

export interface CattleYardsDeleteCattleYardModalSignature {
	// The arguments accepted by the component
	Args: {
		itemToDelete: Pick<CattleYard, 'id' | 'name'>;
	};
	// Any blocks yielded by the component
	Blocks: {
		default: [];
	};
	// The element to which `...attributes` is applied in the component template
	Element: null;
}

export default class CattleYardsDeleteCattleYardModalComponent extends BaseDeleteModalComponent {
	deleteItem = task({ drop: true }, async () => {
		const id = this.id;

		assert('No id provided to  Delete Cattle Yard Component', id);

		await useDeleteCattleYardMutation(this, {
			onError: () => {
				this.errorMessage = ERRORS.DELETE_CATTLE_YARD.DEFAULT;
			},
			onComplete: () => {
				this.closeConfirmation();
				if (this.args.redirectRoutePath) {
					this.redirect();
				}
			},
		}).mutate({ id });
	});

	get yard() {
		return this.args.itemToDelete as Pick<CattleYard, 'id' | 'name'>;
	}

	get titleText() {
		return 'Delete Yard';
	}

	get bodyText() {
		return 'Are you sure you want to delete this yard?';
	}

	get cancelButtonText() {
		return 'Cancel';
	}

	get deleteButtonText() {
		return 'Delete';
	}

	get label(): string {
		return this.yard.name;
	}
}
