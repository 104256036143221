import { getOwner } from '@ember/application';
import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import { cached, tracked } from '@glimmer/tracking';
import Big from 'big.js';
import { gql, useMutation } from 'glimmer-apollo';
import { DateTime } from 'luxon';
import FutureTransaction from 'vault-client/models/future-transaction';
import OptionTransaction from 'vault-client/models/option-transaction';
import SwapTransaction from 'vault-client/models/swap-transaction';
import SwaptionTransaction from 'vault-client/models/swaption-transaction';
import PositionsShowRoute from 'vault-client/routes/positions/show';
import {
	Future,
	Mutation_setPositionComponentAllocationsArgs,
	Option,
	Swap,
	Swaption,
	TypeOfInstrument,
	TypeOfPositionComponentAllocationQuantity,
} from 'vault-client/types/graphql-types';
import { CellComponents, SortObj, TableColumn } from 'vault-client/types/vault-table';
import { waitForUserButtonClickChoice } from 'vault-client/utils/await-user-button-click';
import { intervalFromDateTime } from 'vault-client/utils/interval-from-date-time';
import { enDash } from 'vault-client/utils/string-utils';
import { ModelFrom } from 'vault-client/utils/type-utils';

export type MonthAllocations = {
	tableRowOrFooterValue: number | string | null;
	longContractQuantity?: number | null;
	shortContractQuantity?: number | null;
};

export type AllocationTotals = {
	month: string;
	longTotal: number;
	longAllocated: number;
	shortTotal: number;
	shortAllocated: number;
};

export type AllocationData = {
	price: number;
	displayFactor: number;
	fractionDigits: number;
	quantity: number;
	shortTotal: number;
	longTotal: number;
	allocationMonthsMap: Record<string, MonthAllocations>;
};

export type EditableAllocationsMonth = {
	id: string;
	month: string;
	longAllocated: number;
	shortAllocated: number;
};

const UPDATE_POSITION_COMPONENT_ALLOCATIONS_MUTATION = gql`
	mutation SetPositionComponentAllocations($data: PositionComponentAllocationsSetInput!) {
		setPositionComponentAllocations(data: $data) {
			id
		}
	}
`;

type UpdatePositionComponentAllocationsMutation = {
	__typename?: 'Mutation';
};

export default class PositionsShowController extends Controller {
	positionsRoutePath: string = '';
	transactionShowRoute: string = '';
	accountShowRoute: string = '';
	// If null, the links within the headers will be disabled
	exposureHedgeMonthDetailRoute: string | null = null;

	@service router: RouterService | undefined;
	@tracked allocationsStartMonth: string = DateTime.now().startOf('month').toISODate();
	@tracked allocationsEndMonth: string = DateTime.now().plus({ months: 11 }).endOf('month').toISODate();
	@tracked allocationSorts: SortObj[] = [{ valuePath: 'price', isAscending: true }];
	@tracked transactionSorts = [{ valuePath: 'tradeDate', isAscending: false }];
	@tracked allAllocationsDataMap: Map<number, AllocationData> = new Map();
	@tracked allocationRowToEdit: EditableAllocationsMonth[] = [];
	@tracked editableAllocationsTotals: AllocationTotals = {
		month: 'Total',
		longTotal: 0,
		longAllocated: 0,
		shortTotal: 0,
		shortAllocated: 0,
	};
	@tracked selectedTradePrice: string | null = null;
	@tracked unformattedSelectedTradePrice: string | null = null;
	@tracked isSidePanelOpen = false;
	@tracked userAppliedChanges = false;
	@tracked showConfirmation = false;
	@tracked transition: Transition<unknown> | null = null;
	@tracked errorMessage: string | null = null;

	updatePositionComponentAllocationsMutation = useMutation<
		UpdatePositionComponentAllocationsMutation,
		Mutation_setPositionComponentAllocationsArgs
	>(this, () => [
		UPDATE_POSITION_COMPONENT_ALLOCATIONS_MUTATION,
		{
			onComplete: async () => {
				await this.model.getCurrentPosition.refetch();
				this.resetDataFn(null);
				this.isSidePanelOpen = false;
			},
			onError: (error) => {
				this.errorMessage = error.message;
			},
		},
	]);

	allocationsDateRangeOptions = [
		{
			displayName: 'Next 3 Months',
			startDate: DateTime.now().startOf('month').toISODate(),
			endDate: DateTime.now().plus({ months: 2 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Previous 3 Months',
			startDate: DateTime.now().minus({ months: 3 }).startOf('month').toISODate(),
			endDate: DateTime.now().minus({ month: 1 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 12 Months',
			startDate: DateTime.now().startOf('month').toISODate(),
			endDate: DateTime.now().plus({ months: 11 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Previous 12 Months',
			startDate: DateTime.now().minus({ months: 12 }).startOf('month').toISODate(),
			endDate: DateTime.now().minus({ month: 1 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 24 Months',
			startDate: DateTime.now().startOf('month').toISODate(),
			endDate: DateTime.now().plus({ months: 23 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Previous 24 Months',
			startDate: DateTime.now().minus({ months: 24 }).startOf('month').toISODate(),
			endDate: DateTime.now().minus({ month: 1 }).endOf('month').toISODate(),
		},
	];

	queryParams = ['allocationsStartMonth', 'allocationsEndMonth'];

	declare model: ModelFrom<PositionsShowRoute>;

	get isSubmitDisabled() {
		return !this.userAppliedChanges;
	}

	get currentAllocationsDateRange() {
		return {
			startDate: this.allocationsStartMonth,
			endDate: this.allocationsEndMonth,
		};
	}

	get instrumentTypeLabel() {
		return this.model?.getCurrentPosition.data?.CurrentPosition?.Instrument?.type ?? '';
	}

	get pricingInstrument() {
		const instrument = this.model?.getCurrentPosition.data?.CurrentPosition?.Instrument;
		if (!instrument) return null;

		switch (instrument.type) {
			case TypeOfInstrument.Swap:
				return (instrument as Swap).PriceInstrument;
			case TypeOfInstrument.Swaption:
				return (instrument as Swaption).SettlementInstrument;
			default:
				return instrument;
		}
	}

	get instrument() {
		return this.model?.getCurrentPosition.data?.CurrentPosition?.Instrument as Future | Option | Swap | Swaption;
	}

	get isInstrumentSwapOrSwaption() {
		return this.instrument.type === TypeOfInstrument.Swap || this.instrument.type === TypeOfInstrument.Swaption;
	}

	get displayFactor() {
		return this.instrument.SymbolGroup.displayFactor ?? 1;
	}

	get fractionDigits() {
		return this.instrument.SymbolGroup.fractionDigits ?? 0;
	}
	get formattedDisplayExpiresAt() {
		const displayExpirationMonth = this.model.getCurrentPosition.data?.CurrentPosition?.displayExpiresAt;
		if (!displayExpirationMonth) return null;
		return DateTime.fromISO(displayExpirationMonth).toFormat('LLL-yyyy');
	}

	get modelUpdatedAt() {
		return this.model.updatedAt ? DateTime.fromISO(this.model.updatedAt).toLocaleString(DateTime.DATETIME_FULL) : '';
	}

	get transactionColumns(): TableColumn[] {
		return [
			{
				id: 'c1efec0d-493f-4165-aa81-3479061de8f3',
				name: 'Account Name',
				valuePath: 'Account.name',
				minWidth: 200,
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: 'c71b9eb2-60bf-48cc-85df-90b66c650fc67',
				name: 'Sales Code',
				valuePath: 'Account.salesCode',
				minWidth: 125,
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '376d573b-cc7c-4750-8633-5fc1433aaa05',
				name: 'Trade Day',
				valuePath: 'tradeDate',
				width: 110,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				linkRoute: this.transactionShowRoute,
				linkModelPath: 'id',
			},
			{
				id: '3fc0cce5-7bd3-4f3c-b40e-73e4d73b2694',
				name: 'Account',
				valuePath: 'Account.accountNumber',
				width: 100,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				linkRoute: this.accountShowRoute,
				linkModelPath: 'Account.id',
			},
			{
				id: '01e64d22-0fa6-4e4d-93d8-078019da59d1',
				name: 'Commodity',
				valuePath: 'Instrument.Product.name',
				width: 170,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'b8b959bd-f85d-442b-8d16-888e0b374308',
				name: 'Exp Month',
				valuePath: 'Instrument.displayExpiresAt',
				width: 110,
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '6325e488-fbfd-4e80-b1fd-fe165cb7b84e',
				name: 'B / S',
				valuePath: 'side',
				width: 70,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '4a5a06d9-f2c5-428e-ab6d-353c8a3f2911',
				name: 'Contracts',
				valuePath: 'quantityInContracts',
				width: 100,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'fe068ec4-6131-4b85-a005-f84adf0ea43a',
				name: 'ABS Quantity',
				valuePath: 'absQuantityInContracts',
				width: 100,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: false,
			},
			{
				id: 'df370bdb-c199-4f71-bb5c-9154c93bec6e',
				name: 'Type',
				valuePath: 'Instrument.instrumentType',
				width: 75,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'ceaed76e-f2fc-4725-a8b2-bc5df4b0eb11',
				name: 'Symbol',
				valuePath: 'Instrument.exchangeSymbol',
				width: 100,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'f327f161-70dc-4c8d-b994-ba4841b79d95',
				name: 'Strike',
				valuePath: 'Instrument.strike',
				width: 80,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'Instrument.symbolGroup.fractionDigits',
					displayFactorPath: 'Instrument.symbolGroup.displayFactor',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '0ca3e013-fed8-430f-9cb4-aa388ad2c4bf',
				name: 'Trade Price',
				valuePath: 'price',
				width: 120,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'Instrument.symbolGroup.fractionDigits',
					displayFactorPath: 'Instrument.symbolGroup.displayFactor',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'd60c2cf6-3a4e-4171-acdb-7f8f92e9b840',
				name: 'Commission',
				valuePath: 'commission',
				width: 120,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '512555e8-7a53-478d-9374-8b3c81cf1530',
				name: 'Fees',
				valuePath: 'nonCommissionFees',
				width: 90,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'f0e1014b-9bfc-4d5a-a08f-4a8406e907ae',
				name: 'Gross P/L (EOD)',
				valuePath: 'grossPl',
				width: 130,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '8e3f73cb-31a3-4a3d-95b6-6de4045f8067',
				name: 'Net P/L (EOD)',
				valuePath: 'netPl',
				width: 120,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
		];
	}

	get allocationMonths() {
		return intervalFromDateTime(DateTime.fromISO(this.allocationsStartMonth), DateTime.fromISO(this.allocationsEndMonth), {
			month: 1,
		}).map((month) => month.startOf('month').toISODate());
	}

	get transactions() {
		const owner = getOwner(this);
		const useEODPrices = true;
		return this.model.getCurrentPosition.data?.CurrentPosition?.Transactions.map((transaction) => {
			if (transaction.Instrument.type === TypeOfInstrument.Future) {
				return new FutureTransaction(owner, transaction, null, null, useEODPrices);
			}

			if (transaction.Instrument.type === TypeOfInstrument.Option) {
				return new OptionTransaction(owner, transaction, null, null, useEODPrices);
			}

			if (transaction.Instrument.type === TypeOfInstrument.Swap) {
				return new SwapTransaction(owner, transaction, null, null, useEODPrices);
			}

			if (transaction.Instrument.type === TypeOfInstrument.Swaption) {
				return new SwaptionTransaction(owner, transaction, null, null, useEODPrices);
			}
			return;
		});
	}

	@cached
	get allocations(): AllocationData[] | undefined {
		if (!this.model.getCurrentPosition.data?.PositionComponentAllocations?.length) return [];
		this.initializeEditableAllocationsDataMap();
		this.model.getCurrentPosition.data?.PositionComponentAllocations?.forEach((component) => {
			const month = DateTime.fromISO(component.effectiveHedgeDate).startOf('month').toISODate();
			const componentMapObj = this.allAllocationsDataMap.get(component.price);
			if (!componentMapObj) return null;
			componentMapObj.price = component.price;
			componentMapObj.displayFactor = this.displayFactor;
			componentMapObj.fractionDigits = this.fractionDigits;
			componentMapObj.shortTotal += component.shortContractQuantity;
			componentMapObj.longTotal += component.longContractQuantity;
			componentMapObj.quantity += component.netContractQuantity;

			if (!componentMapObj.allocationMonthsMap[month]) return null;
			componentMapObj.allocationMonthsMap[month].tableRowOrFooterValue = component.netContractQuantity;
			componentMapObj.allocationMonthsMap[month].longContractQuantity = component.longContractQuantity;
			componentMapObj.allocationMonthsMap[month].shortContractQuantity = component.shortContractQuantity;
			return;
		});
		return Array.from(this.allAllocationsDataMap.values());
	}

	get allocationColumns(): TableColumn[] {
		return [
			{
				id: '498d688b-b080-498e-8a7c-cc1bf35aec29',
				name: 'Trade Price',
				valuePath: 'price',
				cellComponent: CellComponents.PriceFormat,
				width: 120,
				componentArgs: {
					displayFactorPath: 'displayFactor',
					fractionDigitsPath: 'fractionDigits',
				},
				footerIsString: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '123fe0e4-9dba-47a3-b52b-1fc501ab4e13',
				name: 'Contracts',
				valuePath: 'quantity',
				width: 110,
				cellComponent: CellComponents.StringWithButtons,
				componentArgs: {
					rightButtonSize: 'icon-small',
					rightButtonStyle: 'plain',
					rightButtonFn: this.isInstrumentSwapOrSwaption ? undefined : this.openSidePanelFn,
					rightButtonRightIconHref: '/icons/Edit-allocation.svg#edit-allocation',
					rightButtonRightIconClass: 'icon-interactive-stroke',
					rightButtonRightIconViewBox: '-4 -4 24 24',
				},
				footerIsString: true,
				isFixed: '',
				isVisible: true,
			},
			...this.allocationMonths.map((month): TableColumn => {
				const exposureMonthStartDate = DateTime.fromISO(month).startOf('month').toISODate();
				const exposureMonthEndDate = DateTime.fromISO(month).endOf('month').toISODate();

				return {
					id: month,
					name: DateTime.fromISO(month).toFormat('LLL-yy'),
					valuePath: `allocationMonthsMap.${month}.tableRowOrFooterValue`,
					cellComponent: CellComponents.String,
					...(this.exposureHedgeMonthDetailRoute && {
						headerLinkRoute: this.exposureHedgeMonthDetailRoute,
						headerLinkModels: [this.instrument.Product.slug],
						headerLinkQuery: { exposureMonthStartDate, exposureMonthEndDate },
					}),
					footerIsString: true,
					textAlign: 'right',
					isFixed: '',
					isVisible: true,
				};
			}),
		];
	}

	get allocationFooterRows() {
		if (!this.allocations?.length) return [];

		let totalNumberOfContracts: null | number = null;
		let totalPositionValues: null | number = null;
		let totalProfitLosses: null | number = null;

		const totalAllocationMonthsMap: Record<string, MonthAllocations> = this.allocationMonths.reduce((acc, month) => {
			acc[month] = {
				tableRowOrFooterValue: null,
			};
			return acc;
		}, {} as Record<string, MonthAllocations>);

		const pnlAllocationMonthsMap: Record<string, MonthAllocations> = this.allocationMonths.reduce((acc, month) => {
			acc[month] = {
				tableRowOrFooterValue: null,
			};
			return acc;
		}, {} as Record<string, MonthAllocations>);

		const averagePriceAllocationMonthsMap: Record<string, MonthAllocations> = this.allocationMonths.reduce((acc, month) => {
			acc[month] = {
				tableRowOrFooterValue: null,
			};
			return acc;
		}, {} as Record<string, MonthAllocations>);

		// Set the monthly/total number of contracts and profit/losses using AggregateCurrentAllocationPositions
		this.model.getCurrentPosition.data?.AggregateCurrentAllocationPositions?.forEach((allocationPosition) => {
			const effectiveHedgeDate = allocationPosition.effectiveHedgeDate;
			if (!effectiveHedgeDate) return;
			if (!pnlAllocationMonthsMap[effectiveHedgeDate]) return;
			const pnl = allocationPosition.sum.grossPnl ?? 0;
			totalProfitLosses = totalProfitLosses ? totalProfitLosses + pnl : pnl;
			pnlAllocationMonthsMap[effectiveHedgeDate].tableRowOrFooterValue = pnl;
		});

		// Set purchases prices for allocations by month and contract quanities regular using PositionComponentAllocations (allocations)
		this.allocations.forEach((allocation) => {
			const price = allocation.price;
			const quantity = allocation.quantity;

			const totalAllocationValue = price * quantity;
			totalPositionValues = totalPositionValues ? totalPositionValues + totalAllocationValue : totalAllocationValue;

			totalNumberOfContracts = totalNumberOfContracts ? totalNumberOfContracts + quantity : quantity;

			this.allocationMonths.forEach((month) => {
				const monthQuantity = allocation.allocationMonthsMap[month].tableRowOrFooterValue;
				const existingContractsInMonth = totalAllocationMonthsMap[month].tableRowOrFooterValue;
				const existingPositionValue = averagePriceAllocationMonthsMap[month].tableRowOrFooterValue;

				if (!monthQuantity) return;
				if (typeof monthQuantity !== 'number') return;

				if (existingContractsInMonth != null && typeof existingContractsInMonth !== 'number') return;
				if (existingPositionValue != null && typeof existingPositionValue !== 'number') return;

				totalAllocationMonthsMap[month].tableRowOrFooterValue = existingContractsInMonth
					? existingContractsInMonth + monthQuantity
					: monthQuantity;

				const positionValue = allocation.price * monthQuantity;
				averagePriceAllocationMonthsMap[month].tableRowOrFooterValue = existingPositionValue
					? existingPositionValue + positionValue
					: positionValue;
			});
		});

		// Calculate derived values
		Object.keys(averagePriceAllocationMonthsMap).map((key) => {
			const quantity = totalAllocationMonthsMap[key].tableRowOrFooterValue;
			averagePriceAllocationMonthsMap[key].tableRowOrFooterValue =
				quantity == null
					? null
					: quantity == 0
					? enDash
					: Big(averagePriceAllocationMonthsMap[key].tableRowOrFooterValue ?? 0)
							.div(quantity)
							.times(this.displayFactor)
							.toFixed(this.fractionDigits);
		});

		const averagePriceFooterRow = {
			price: 'Avg Price',
			quantity: totalNumberOfContracts
				? Big(totalPositionValues ?? 0)
						.div(totalNumberOfContracts)
						.times(this.displayFactor)
						.toFixed(this.fractionDigits)
				: enDash,
			allocationMonthsMap: averagePriceAllocationMonthsMap,
		};

		// Format numbers for display (as string)
		Object.keys(pnlAllocationMonthsMap).map((key) => {
			const currValue = pnlAllocationMonthsMap[key].tableRowOrFooterValue;
			pnlAllocationMonthsMap[key].tableRowOrFooterValue = currValue
				? new Intl.NumberFormat(undefined, { style: 'currency', currency: 'USD' }).format(Number(currValue))
				: enDash;
		});

		const profitLossFooterRow = {
			price: 'Gross P/L (EOD)',
			quantity: totalProfitLosses
				? new Intl.NumberFormat(undefined, { style: 'currency', currency: 'USD' }).format(totalProfitLosses)
				: enDash,
			allocationMonthsMap: pnlAllocationMonthsMap,
		};

		Object.keys(totalAllocationMonthsMap).map((key) => {
			const currValue = Number(totalAllocationMonthsMap[key].tableRowOrFooterValue);
			totalAllocationMonthsMap[key].tableRowOrFooterValue =
				currValue != null ? new Intl.NumberFormat(undefined, { maximumFractionDigits: 10 }).format(currValue) : enDash;
		});

		const totalFooterRow = { price: 'Total', quantity: totalNumberOfContracts, allocationMonthsMap: totalAllocationMonthsMap };

		if (this.instrument.type === TypeOfInstrument.Swaption) {
			return [totalFooterRow, averagePriceFooterRow];
		} else {
			return [totalFooterRow, averagePriceFooterRow, profitLossFooterRow];
		}
	}

	initializeEditableAllocationsDataMap() {
		this.model.getCurrentPosition.data?.PositionComponentAllocations?.map((component) => {
			const emptyMonthsAllocationRecords = this.allocationMonths.reduce((acc, month) => {
				acc[month] = {
					tableRowOrFooterValue: null,
					longContractQuantity: null,
					shortContractQuantity: null,
				};
				return acc;
			}, {} as Record<string, MonthAllocations>);

			this.allAllocationsDataMap.set(component.price, {
				price: 0,
				quantity: 0,
				longTotal: 0,
				shortTotal: 0,
				allocationMonthsMap: emptyMonthsAllocationRecords,
			} as AllocationData);
		});
	}

	openSidePanelFn = async (row: any) => {
		const changeSidePanelContent = await this.ShowDirtyFormConfirmationIfNeededFn();
		if (changeSidePanelContent) {
			this.resetDataFn(row);
			if (this.isSidePanelOpen) {
				return;
			}
			if (!this.isSidePanelOpen) this.isSidePanelOpen = true;
		}
	};

	closeSidePanelFn = async () => {
		const closeSidePanel = await this.ShowDirtyFormConfirmationIfNeededFn();
		if (closeSidePanel) {
			this.resetDataFn(null);
			this.isSidePanelOpen = false;
		}
	};

	ShowDirtyFormConfirmationIfNeededFn = async () => {
		// Return true if the form is not dirty or if the user clicks confirm on the confirmation modal
		if (!this.userAppliedChanges) {
			return true;
		} else {
			this.showConfirmation = true;
			//Adds a 200ms delay to allow the rendering of the confirmation modal
			await new Promise((resolve) => setTimeout(resolve, 200));
			const buttonClickResult = await waitForUserButtonClickChoice('confirmation-cancel', 'confirmation-submit');
			this.showConfirmation = false;
			if (buttonClickResult == 'cancel') {
				return false;
			} else if (buttonClickResult == 'confirm') {
				return true;
			} else {
				console.log('Error: ' + buttonClickResult);
				console.log('An error occured with the confirmation modal, please try again');
				return false;
			}
		}
	};

	showConfirmationIfNeededAndTransitionFn = async () => {
		await this.closeSidePanelFn();
		if (!this.isSidePanelOpen && this.transition) {
			this.transition.retry();
		}

		this.transition = null;
	};

	resetDataFn = (row: AllocationData | null) => {
		const monthsAndAllocationsArray = this.allocationMonths.map(
			(month: string) =>
				({
					id: month,
					month: month,
					longAllocated: row?.allocationMonthsMap[month]?.longContractQuantity ?? 0,
					shortAllocated: row?.allocationMonthsMap[month]?.shortContractQuantity ?? 0,
				} as EditableAllocationsMonth)
		);
		this.errorMessage = null;
		this.userAppliedChanges = false;
		this.allocationRowToEdit = monthsAndAllocationsArray;
		const resetTotals = {
			month: 'Total',
			longTotal: row?.longTotal ?? 0,
			shortTotal: row?.shortTotal ?? 0,
			longAllocated: row?.longTotal ?? 0,
			shortAllocated: row?.shortTotal ?? 0,
		} as AllocationTotals;
		this.editableAllocationsTotals = resetTotals;
		this.selectedTradePrice = row?.price
			? new Intl.NumberFormat(undefined, {
					minimumFractionDigits: this.instrument.SymbolGroup.fractionDigits,
					maximumFractionDigits: this.instrument.SymbolGroup.fractionDigits,
			  }).format(row.price * this.displayFactor)
			: null;
		this.unformattedSelectedTradePrice = row?.price.toString() ?? null;
	};

	sidePanelSubmitFn = async () => {
		const longAllocatedMatchesTotal = this.editableAllocationsTotals.longAllocated == this.editableAllocationsTotals.longTotal;
		const shortAllocatedMatchesTotal = this.editableAllocationsTotals.shortAllocated == this.editableAllocationsTotals.shortTotal;
		if (longAllocatedMatchesTotal && shortAllocatedMatchesTotal) {
			this.errorMessage = null;
			if (this.unformattedSelectedTradePrice == null) return;
			const filteredAllocationRowToEdit = this.allocationRowToEdit.filter((month) => month.longAllocated > 0 || month.shortAllocated > 0);
			const data = {
				accountId: this.model.getCurrentPosition.data?.CurrentPosition?.Transactions[0].Account.id ?? '',
				instrumentId: this.model.getCurrentPosition.data?.CurrentPosition?.Transactions[0].Instrument.id ?? '',
				price: Number(this.unformattedSelectedTradePrice),
				componentAllocations: filteredAllocationRowToEdit.map((month) => {
					return {
						effectiveHedgeDate: month.month,
						longQuantity: month.longAllocated,
						shortQuantity: month.shortAllocated,
						quantityType: TypeOfPositionComponentAllocationQuantity.Contract,
					};
				}),
			};
			await this.updatePositionComponentAllocationsMutation.mutate({
				data: data,
			});
		} else {
			this.errorMessage = 'Allocated contracts must match total # available';
		}
	};

	decrementLongValueFn = (editableRow: EditableAllocationsMonth) => {
		this.mutateComponentAllocations(editableRow.id, 'decrement', 'longAllocated');
	};

	incrementLongValueFn = (editableRow: EditableAllocationsMonth) => {
		this.mutateComponentAllocations(editableRow.id, 'increment', 'longAllocated');
	};

	decrementShortValueFn = (editableRow: EditableAllocationsMonth) => {
		this.mutateComponentAllocations(editableRow.id, 'decrement', 'shortAllocated');
	};

	incrementShortValueFn = (editableRow: EditableAllocationsMonth) => {
		this.mutateComponentAllocations(editableRow.id, 'increment', 'shortAllocated');
	};

	@action
	mutateComponentAllocations(rowId: string, operation: 'increment' | 'decrement', varToBeMutated: 'longAllocated' | 'shortAllocated') {
		const index = this.allocationRowToEdit.findIndex((month) => month.id == rowId);
		if (index == -1) return;

		// The spreading operation below is needed to trigger row level change detection in EmberTable
		const rowToMutate = { ...this.allocationRowToEdit[index] } as EditableAllocationsMonth;
		const rowValueToMutate = rowToMutate[varToBeMutated];
		const rowMutatedValue = operation == 'increment' ? rowValueToMutate + 1 : rowValueToMutate - 1;
		set(rowToMutate, varToBeMutated, rowMutatedValue);
		// The spreading operation below is needed to trigger the tracking for allocationRowToEdit being passed into the table
		this.allocationRowToEdit = [...this.allocationRowToEdit.slice(0, index), rowToMutate, ...this.allocationRowToEdit.slice(index + 1)];

		// Update totals
		const totalValueToMutate = this.editableAllocationsTotals[varToBeMutated];
		const totalMutatedValue = operation == 'increment' ? totalValueToMutate + 1 : totalValueToMutate - 1;
		set(this.editableAllocationsTotals, varToBeMutated, totalMutatedValue);
		this.userAppliedChanges = true;
	}

	@action
	setCurrentAllocationsDateRange(value: { startDate: string; endDate: string }) {
		this.allocationsStartMonth = value.startDate;
		this.allocationsEndMonth = value.endDate;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'positions-show-controller': PositionsShowController;
	}
}
