import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { ChartOptions, ChartData, Chart } from 'chart.js';
import { getCustomLegend } from 'vault-client/utils/chart-utils';
import { TableColumn } from 'vault-client/types/vault-table';
import { DateTime } from 'luxon';
import BusinessesBusinessCattleCattleDashboardRoute from 'vault-client/routes/businesses/business/cattle/cattle-dashboard';
import { ModelFrom } from 'vault-client/utils/type-utils';
import { CattlePnlMonth as PnlMonth } from 'vault-client/models/cattle-pnl-month';
import {
	populateCattleSalesRevenue,
	populateFeedExpenses,
	populateInsurance,
	populateLotExpenses,
	populateOtherExpenses,
	populateNonFeedRevenue,
	populateCmeCattleAndFeed,
	populateTotalWeight,
	projectedPnlRawData,
	dashboardMarketPriceGroups,
	percentHedgedChartData,
	getPercentHedgedData,
	buildPercentHedgedRows,
	buildProjectedPnlRows,
	getProjectedPnlFooterRow,
	populateNumOfCattle,
} from 'vault-client/utils/cattle/dashboard/dashboard-utils';
import { getOwner } from '@ember/application';
import {
	cornPercentHedgedColumns,
	feederForLiveCattlePercentHedgedColumns,
	getCattleDashboardPnlTableColumns,
	getCattleAsRevenuePercentHedgedTableColumns,
} from 'vault-client/utils/components/cattle/dashboard/dashboard-table';
import { projectedPnlChartData, projectedPnlChartOptions } from 'vault-client/utils/cattle/dashboard/dashboard-chart-utils';
import { ProjectedPnlRow } from 'vault-client/models/cattle/cattle-dashboard';
import { BusinessEntityRole } from 'vault-client/types/graphql-types';

enum DisplayUnits {
	Total = 'Total',
	PerHead = 'Per Head',
	PerCWT = 'Per CWT',
}

export interface PnlMonthArgs {
	date: string;
	cattleSalesRevenue: number | null;
	cattlePurchases: number | null;
	otherRevenue: number | null;
	insurance: number | null;
	feedExpenses: number | null;
	lotExpenses: number | null;
	otherExpenses: number | null;
	numCattle: number | null;
	totalWeight: number | null;
	cmeFeedRevenue: number | null;
	cmeFeedHedged: number | null;
	cmeCattleRevenue: number | null;
	cmeCattleHedged: number | null;
	netPnl: number | null;
}

export default class BusinessesBusinessCattleDashboardController extends Controller {
	declare model: ModelFrom<BusinessesBusinessCattleCattleDashboardRoute>;

	@tracked selectedDisplayUnit = DisplayUnits.Total;
	@tracked projectedPnlChartId = 'cattle-projected-pnl-chart';
	@tracked projectedPnlLegendId = 'cattle-projected-pnl-legend';

	// % Hedged chart IDs and toggle states
	@tracked liveCattlePercentHedgedChartId = 'live-cattle-percent-hedged-chart';
	@tracked cornPercentHedgedChartId = 'corn-percent-hedged-chart';
	@tracked feederForLiveCattlePercentHedgedChartId = 'feeder-cattle-percent-hedged-chart';

	@tracked showLiveCattlePercentHedgedTable = false;
	@tracked showCornPercentHedgedTable = false;
	@tracked showFeederForLiveCattlePercentHedgedTable = false;
	@tracked showFeederCattlePercentHedgedTable = false;

	get lastUpdatedAtString() {
		const lastUpdatedAt = DateTime.fromISO(this.model.lastUpdatedAt);
		return `Last updated: ${lastUpdatedAt.toLocaleString(DateTime.DATE_SHORT)} at ${lastUpdatedAt.toLocaleString(DateTime.TIME_SIMPLE)}`;
	}

	get transactions() {
		return this.model.getCattleDashboard?.data?.CurrentAllocationPositions.filter((transaction) => transaction.contractQuantity > 0) ?? [];
	}

	get percentHedgedData() {
		const volumes = this.model.getCattleDashboard.data?.AggregateAllocatedForecastedHedgedAndCappedVolumes ?? [];
		return getPercentHedgedData(
			getOwner(this),
			this.transactions,
			this.model.getCattleDashboard.data?.Futures ?? [],
			volumes,
			this.model.getCattleDashboard.data?.EntityAllocatedExposureRatios ?? [],
			this.model.getCattleDashboard.data?.FeedIngredientConsumedAndPurchasedVolumes ?? null,
			this.businessRole as BusinessEntityRole,
		);
	}

	get lastUpdatedAt() {
		return `${DateTime.now().toLocaleString(DateTime.DATE_SHORT)} at ${DateTime.now().toLocaleString(DateTime.TIME_SIMPLE)}`;
	}

	get cornPercentHedgedChartData() {
		const percentHedgedData = this.percentHedgedData;
		return percentHedgedChartData(
			this.model.getCattleDashboard.data?.EntityAllocatedExposureRatios ?? [],
			'grain-corn',
			percentHedgedData,
			null,
			this.businessRole,
		);
	}

	get feederForLiveCattlePercentHedgedChartData() {
		const percentHedgedData = this.percentHedgedData;
		return percentHedgedChartData(
			this.model.getCattleDashboard.data?.EntityAllocatedExposureRatios ?? [],
			'livestock-feeder-cattle',
			percentHedgedData,
			this.model.getCattleDashboard.data?.AggregateCattleLotsForFeederHedged ?? [],
			this.businessRole,
		);
	}

	get liveCattlePercentHedgedChartData() {
		const percentHedgedData = this.percentHedgedData;
		return percentHedgedChartData(
			this.model.getCattleDashboard.data?.EntityAllocatedExposureRatios ?? [],
			'livestock-live-cattle',
			percentHedgedData,
			null,
			this.businessRole,
		);
	}

	get feederCattlePercentHedgedChartData() {
		const percentHedgedData = this.percentHedgedData;
		return percentHedgedChartData(
			this.model.getCattleDashboard.data?.EntityAllocatedExposureRatios ?? [],
			'livestock-feeder-cattle',
			percentHedgedData,
			null,
			this.businessRole,
		);
	}

	get liveCattlePercentHedgedColumns(): TableColumn[] {
		return getCattleAsRevenuePercentHedgedTableColumns('live-cattle');
	}

	get cornPercentHedgedColumns(): TableColumn[] {
		return cornPercentHedgedColumns();
	}

	get feederForLiveCattlePercentHedgedColumns(): TableColumn[] {
		return feederForLiveCattlePercentHedgedColumns();
	}

	get feederCattlePercentHedgedColumns(): TableColumn[] {
		return getCattleAsRevenuePercentHedgedTableColumns('feeder-cattle');
	}

	get liveCattlePercentHedgedRows() {
		const percentHedgedData = this.percentHedgedData;
		return buildPercentHedgedRows(
			'livestock-live-cattle',
			this.model.getCattleDashboard.data?.EntityAllocatedExposureRatios ?? [],
			percentHedgedData,
			null,
			null,
			this.businessRole,
		);
	}

	get cornPercentHedgedRows() {
		const percentHedgedData = this.percentHedgedData;
		return buildPercentHedgedRows(
			'grain-corn',
			this.model.getCattleDashboard.data?.EntityAllocatedExposureRatios ?? [],
			percentHedgedData,
			this.model.getCattleDashboard.data?.FeedIngredientConsumedAndPurchasedVolumes ?? [],
			null,
			this.businessRole,
		);
	}

	get feederForLiveCattlePercentHedgedRows() {
		const percentHedgedData = this.percentHedgedData;
		return buildPercentHedgedRows(
			'livestock-feeder-cattle',
			this.model.getCattleDashboard.data?.EntityAllocatedExposureRatios ?? [],
			percentHedgedData,
			null,
			this.model.getCattleDashboard.data?.AggregateCattleLotsForFeederHedged ?? [],
			this.businessRole,
		);
	}

	get feederCattlePercentHedgedRows() {
		const percentHedgedData = this.percentHedgedData;
		return buildPercentHedgedRows(
			'livestock-feeder-cattle',
			this.model.getCattleDashboard.data?.EntityAllocatedExposureRatios ?? [],
			percentHedgedData,
			null,
			null,
			this.businessRole,
		);
	}

	get projectedPnlColumns(): TableColumn[] {
		return getCattleDashboardPnlTableColumns(this.selectedDisplayUnit);
	}

	get pnlMonths(): PnlMonth[] {
		const pnlMonthsMap = new Map<string, PnlMonthArgs>();

		let startDateTime = DateTime.now().startOf('month');
		const endDateTime = DateTime.now().endOf('month').plus({ month: 12 });

		while (startDateTime <= endDateTime) {
			const date = startDateTime.toISODate();
			const pnlMonthArgs: PnlMonthArgs = {
				date,
				cattleSalesRevenue: 0,
				otherRevenue: 0,
				insurance: 0,
				feedExpenses: 0,
				lotExpenses: 0,
				otherExpenses: 0,
				numCattle: 0,
				totalWeight: 0,
				cattlePurchases: 0,
				cmeFeedRevenue: 0,
				cmeCattleRevenue: 0,
				netPnl: 0,
				cmeFeedHedged: 0,
				cmeCattleHedged: 0,
			};

			pnlMonthsMap.set(date, pnlMonthArgs);
			startDateTime = startDateTime.plus({ month: 1 }).startOf('month');
		}

		populateCattleSalesRevenue(this.model.getCattleDashboard.data?.AggregateCattleLots ?? [], pnlMonthsMap);
		populateOtherExpenses(this.model.getCattleDashboard.data?.AggregateExpenseLedgerEntries ?? [], pnlMonthsMap);
		populateNonFeedRevenue(this.model.getCattleDashboard.data?.AggregateRevenueLedgerEntries ?? [], pnlMonthsMap);
		populateInsurance(this.model.getCattleDashboard.data?.AllocatedLrpInsuranceEndorsements ?? [], pnlMonthsMap);
		populateInsurance(this.model.getCattleDashboard.data?.AllocatedLgmInsuranceEndorsements ?? [], pnlMonthsMap);
		populateFeedExpenses(this.model.getCattleDashboard.data?.AggregateCattleLotFeedRationUsagePerMonths ?? [], pnlMonthsMap);
		populateLotExpenses(this.model.getCattleDashboard.data?.AggregateCattleLots ?? [], pnlMonthsMap);
		populateTotalWeight(this.model.getCattleDashboard.data?.CattleLots ?? [], pnlMonthsMap);
		populateCmeCattleAndFeed(this.model.getCattleDashboard.data?.AggregateCurrentAllocationPositions ?? [], pnlMonthsMap);
		populateNumOfCattle(this.model.getCattleDashboard.data?.CattleLots ?? [], pnlMonthsMap);

		return Array.from(pnlMonthsMap.values())
			.sortBy('date')
			.map((args) => {
				return new PnlMonth(
					args.date,
					args.cattleSalesRevenue,
					args.otherRevenue,
					args.insurance,
					args.feedExpenses,
					args.lotExpenses,
					args.otherExpenses,
					args.numCattle,
					args.totalWeight,
					args.cmeCattleRevenue,
					args.cmeFeedRevenue,
					args.cmeCattleHedged,
					args.cmeFeedHedged,
				);
			});
	}

	get projectedPnlRows(): ProjectedPnlRow[] {
		const pnlMonths = this.pnlMonths;
		return buildProjectedPnlRows(pnlMonths, this.selectedDisplayUnit);
	}

	get projectedPnlRawData() {
		return projectedPnlRawData(this.pnlMonths, this.selectedDisplayUnit);
	}

	get projectedPnlChartData(): ChartData<'line'> {
		return projectedPnlChartData(this.pnlMonths, this.selectedDisplayUnit);
	}

	get projectedPnlChartOptions(): ChartOptions<'line'> {
		return projectedPnlChartOptions();
	}

	get projectedPnlChartPlugins() {
		return [
			{
				afterUpdate: getCustomLegend(this.projectedPnlChartId, this.projectedPnlLegendId),
			},
		];
	}

	get marketPriceGroups() {
		return dashboardMarketPriceGroups(this.model.getCattleDashboard.data?.Products ?? []);
	}

	get projectedPnlFooterRow(): [Omit<ProjectedPnlRow, 'date'>] {
		return getProjectedPnlFooterRow(this.projectedPnlRows, this.selectedDisplayUnit);
	}
	get businessRole() {
		return this.model.getCattleDashboard.data?.Customer?.businessRoles?.[0] ?? '';
	}

	@action
	setShowLiveCattlePercentHedgedTable(show: boolean): void {
		this.showLiveCattlePercentHedgedTable = show;
	}

	@action
	setShowCornPercentHedgedTable(show: boolean) {
		this.showCornPercentHedgedTable = show;
	}

	@action
	setShowFeederForLiveCattlePercentHedgedTable(show: boolean) {
		this.showFeederForLiveCattlePercentHedgedTable = show;
	}

	@action
	setShowFeederCattlePercentHedgedTable(show: boolean) {
		this.showFeederCattlePercentHedgedTable = show;
	}

	@action
	updateProjectedPnlChart(chart: Chart<'line'>, _data: unknown) {
		chart.data = this.projectedPnlChartData;
		chart.update('none');
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'businesses/business/cattle-dashboard': BusinessesBusinessCattleDashboardController;
	}
}
