import Route from '@ember/routing/route';
import Transition from '@ember/routing/transition';
import BusinessesBusinessCattleYardsController from 'vault-client/controllers/businesses/business/cattle/yards';
import { YARDS_OVERVIEW_QUERY } from 'vault-client/graphql/queries/businesses/business/cattle/yards';
import { Query_CattleYardsArgs } from 'vault-client/types/graphql-types';
import { SortObj } from 'vault-client/types/vault-table';
import { useYardsOverviewQuery } from 'vault-client/utils/cattle/yards/yards-overview';
import { generateSortBy } from 'vault-client/utils/general';

type Params = {
	page: number;
	size: number;
	sorts: SortObj[];
};

export default class BusinessesBusinessCattleYardsRoute extends Route {
	queryParams = {
		page: {
			refreshModel: true,
		},
		size: {
			refreshModel: true,
		},
		sorts: {
			refreshModel: true,
		},
	};

	async model(params: Params) {
		const { business_id: businessId } = this.paramsFor('businesses.business') as { business_id: string };
		const variables: Query_CattleYardsArgs = {
			scopeId: businessId,
			offset: params.page * params.size,
			limit: params.size,
			sortBy: generateSortBy(params.sorts),
		};

		const yardsOverview = useYardsOverviewQuery(this, {
			variables,
		});

		await yardsOverview.promise;

		return {
			yardsOverview,
			businessId,
			variables,
			query: YARDS_OVERVIEW_QUERY,
		};
	}

	resetController(controller: BusinessesBusinessCattleYardsController, isExiting: boolean, transition: Transition) {
		super.resetController(controller, isExiting, transition);
		if (isExiting) {
			controller.setSidePanelState({ state: null });
		}
	}
}
