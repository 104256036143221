import { gql } from 'glimmer-apollo';
import { SortByDirection } from 'vault-client/types/graphql-types';
const SORT_BY_DIRECTION = SortByDirection.Asc;
export const GET_CATTLE_FEED_RATIONS = gql`
	query GetCattleFeedRations($distinctOn: CattleFeedRationDistinctOnDTO, $scopeId: String, $where: CattleFeedRationFilterDTO) {
		CattleFeedRations(distinctOn: $distinctOn, scopeId: $scopeId, where: $where, sortBy: { name: ${SORT_BY_DIRECTION} }) {
			id
			name
			description
			businessId
			CattleLots(where: { isActive: { equals: true } }) {
				id
				numberOfCattle
			}
		}
	}
`;
