import Controller from '@ember/controller';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { DateTime } from 'luxon';
import { tracked } from 'tracked-built-ins';
import { CellComponents, TableColumn, SortObj } from 'vault-client/types/vault-table';
import { ModelFrom } from 'vault-client/utils/type-utils';
import { getOwner } from '@ember/application';
import ENV from 'vault-client/config/environment';
import {
	AggregateAllocatedForecastedHedgedAndCappedVolumeDTO,
	AggregateCurrentAllocationPositionDTO,
	AggregateForecastedMilkProductionByMonthDTO,
	AggregateLedgerEntryDTO,
	ForecastedMilkUtilization,
	Future,
	TypeOfInstrument,
	TypeOfLedgerCategory,
	TypeOfLivestockPopulationChangeReason,
	TypeOfOption,
} from 'vault-client/types/graphql-types';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import { PnlMonth, PnlMonthArgs, ProjectedPnlRow } from './pig-dashboard';
import { IQuarterObjects, Quarter, getOrCreateMonth, getOrCreateQuarter } from './dashboard';
import BusinessesBusinessProjectedPLRoute from 'vault-client/routes/businesses/business/projected-pl';
import { cmeFutureMonthToRelevantMonths } from 'vault-client/utils/cme-future-month-to-relevant-months';
import {
	calculateMonthlyWeightedPricesAndBasisValues,
	calculateUtilizationValuesFromForecastedProduction,
} from 'vault-client/utils/milk-check-utils';
import { getExpectedMarketingStartOfMonth } from 'vault-client/utils/swine/date';

enum DisplayUnits {
	Total = 'Total',
	CWT = 'CWT',
	PerHead = 'Per Head',
	PerCWT = 'Per CWT',
}
export default class BusinessesBusinessProjectedPLController extends Controller {
	declare model: ModelFrom<BusinessesBusinessProjectedPLRoute>;
	@tracked selectedDisplayUnit = DisplayUnits.Total;
	@tracked projectedRevenueSorts: SortObj[] = [{ valuePath: 'date', isAscending: true }];
	@tracked startDate = DateTime.local().startOf('month').toISODate();
	@tracked type: string = '';
	@tracked endDate =
		this.type === 'pig-dashboard'
			? DateTime.local().plus({ months: 12 }).endOf('month').toISODate()
			: DateTime.now().plus({ years: 2 }).endOf('month').toISODate();
	@tracked showCwt: boolean = false;
	queryParams = ['type'];
	id = guidFor(this);

	productionMonthRangeOptions = [
		{
			displayName: 'Next 24 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 24 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Previous 24 Months',
			startDate: DateTime.local().minus({ months: 24 }).startOf('month').toISODate(),
			endDate: DateTime.local().endOf('month').toISODate(),
		},
		{
			displayName: 'Next 12 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 12 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Previous 12 Months',
			startDate: DateTime.local().minus({ months: 12 }).startOf('month').toISODate(),
			endDate: DateTime.local().endOf('month').toISODate(),
		},
		{
			displayName: `Calendar Year (${DateTime.local().year}) `,
			startDate: DateTime.local().startOf('year').toISODate(),
			endDate: DateTime.local().endOf('year').toISODate(),
		},
	];

	get uiSwitcherOptions() {
		return this.type == 'pig-dashboard' ? ['Total', 'Per Head', 'Per CWT'] : ['Total', 'CWT'];
	}

	get lastUpdatedAtString() {
		const lastUpdatedAt = DateTime.fromISO(this.model.lastUpdatedAt ?? DateTime.now().toISO());
		return `Last updated: ${lastUpdatedAt.toLocaleString(DateTime.DATE_SHORT)} at ${lastUpdatedAt.toLocaleString(DateTime.TIME_SIMPLE)}`;
	}

	get downloadedFileName() {
		return this.type == 'pig-dashboard' ? 'swine_marketing_projected-pnl.csv' : 'dairy_marketing_projected-pnl.csv';
	}

	get dynamicTableRoute() {
		return this.type == 'pig-dashboard' ? 'swine-marketing-projected-revenue' : 'dairy-marketing-projected-revenue';
	}

	get dairyProjectedRevenueColumns(): TableColumn[] {
		return [
			{
				id: 'cebc8b62-084f-46fc-8247-4294bb09a1ba',
				name: 'Date',
				valuePath: 'date',
				width: 90,
				cellComponent: CellComponents.QuarterFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '01fab77a-20ec-4549-b1f9-1e33bfc88d4f',
				name: 'Milk Check',
				valuePath: this.selectedDisplayUnit === 'CWT' ? 'milkCheckRevenueCwt' : 'milkCheckRevenue',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'e12e7dee-6db8-4bfa-8ed0-987818b648be',
				name: 'DRP',

				valuePath: this.selectedDisplayUnit === 'CWT' ? 'insuranceRevenueCwt' : 'insuranceRevenue',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '467f0487-09bc-40fd-896f-aea5d82d0f2c',
				name: 'CME (Dairy)',
				valuePath: this.selectedDisplayUnit === 'CWT' ? 'dairyBrokerageRevenueCwt' : 'dairyBrokerageRevenue',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '20a02df8-1a4c-440a-9d77-5933d43c331e',
				name: 'Non-Milk Revenues',
				valuePath: this.selectedDisplayUnit === 'CWT' ? 'revenuesTotalCwt' : 'revenuesTotal',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				width: 120,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'df34a119-1522-46e7-a0a4-26380c58eab1',
				name: 'CME (Feed)',
				valuePath: this.selectedDisplayUnit === 'CWT' ? 'feedBrokerageRevenueCwt' : 'feedBrokerageRevenue',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'f78c576d-d19b-4744-98e4-06e156e33d31',
				name: 'Feed Expenses',
				valuePath: this.selectedDisplayUnit === 'CWT' ? 'feedExpensesCwt' : 'feedExpenses',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '8106632d-32f7-47ab-b470-aab72c28ecd9',
				name: 'Non-Feed Expenses',
				valuePath: this.selectedDisplayUnit === 'CWT' ? 'expensesTotalCwt' : 'expensesTotal',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				width: 120,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'd79f2008-2a36-43de-bd72-836b4d9e065f',
				name: 'Net P/L',
				valuePath: this.selectedDisplayUnit === 'CWT' ? 'netRevenueCwt' : 'netRevenue',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
		];
	}

	get dairyQuarters() {
		const quarterMap: IQuarterObjects = {};
		const data = this.model.getDairyDashboard?.data;

		this.model.getInsuranceEndorsementAllocationRatios?.data?.InsuranceEndorsementAllocationRatios?.forEach((allocationRatio) => {
			const month = allocationRatio.effectiveHedgeDate;
			if (!month) return;

			const quarterStartDate = DateTime.fromISO(month).startOf('quarter').toISODate();
			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);

			const monthObject = getOrCreateMonth(month, quarter, getOwner(this));

			monthObject.allocatedDrpInsuranceEndorsementRatios.push(allocationRatio);
			quarter.allocatedDrpInsuranceEndorsementRatios.push(allocationRatio);
		});

		data?.ForecastedMilkProductionByMonths.forEach((production) => {
			if (production.date == undefined) return;

			const monthDate = DateTime.fromISO(production.date);

			const quarterStartDate = monthDate.startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(monthDate.toISODate(), quarter, getOwner(this));

			if (month.forecastedMilkProduction?.sum) {
				month.forecastedMilkProduction.sum.grossProduction =
					(month.forecastedMilkProduction.sum.grossProduction ?? 0) + production.grossProduction;
				month.forecastedMilkProduction.sum.grossButterfatProduction =
					(month.forecastedMilkProduction.sum.grossButterfatProduction ?? 0) + production.grossButterfatProduction;
				month.forecastedMilkProduction.sum.grossProteinProduction =
					(month.forecastedMilkProduction.sum.grossProteinProduction ?? 0) + production.grossProteinProduction;
				month.forecastedMilkProduction.sum.grossOtherSolidsProduction =
					(month.forecastedMilkProduction.sum.grossOtherSolidsProduction ?? 0) + production.grossOtherSolidsProduction;
			} else {
				month.forecastedMilkProduction = {
					sum: {
						grossProduction: production.grossProduction,
						grossButterfatProduction: production.grossButterfatProduction,
						grossProteinProduction: production.grossProteinProduction,
						grossOtherSolidsProduction: production.grossOtherSolidsProduction,
					},
				} as AggregateForecastedMilkProductionByMonthDTO;
			}
		});

		calculateUtilizationValuesFromForecastedProduction(data?.ForecastedMilkProductionByMonths ?? []).forEach((v) => {
			if (v.date == undefined) return;
			const monthDate = DateTime.fromISO(v.date);

			const quarterStartDate = monthDate.startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(monthDate.toISODate(), quarter, getOwner(this));

			month.milkCheckUtilizations = {
				classiUtilization: v.classIUtilization,
				classiiUtilization: v.classIIUtilization,
				classiiiUtilization: v.classIIIUtilization,
				classivUtilization: v.classIVUtilization,
				grossClassiPounds: v.grossClassIProduction,
				grossClassiiPounds: v.grossClassIIProduction,
				grossClassiiiPounds: v.grossClassIIIProduction,
				grossClassivPounds: v.grossClassIVProduction,
			} as ForecastedMilkUtilization;
			month.classiDifferential = v.classIDifferential;
		});

		data?.AggregateCurrentAllocationPositions.forEach((aggregate: AggregateCurrentAllocationPositionDTO) => {
			if (!aggregate.effectiveHedgeDate) return;

			const monthDate = DateTime.fromISO(aggregate.effectiveHedgeDate);

			const quarterStartDate = monthDate.startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(monthDate.toISODate(), quarter, getOwner(this));
			month.brokeragePosition = aggregate.sum;
		});

		data?.AggregateDairyBrokerageHedgedValues.forEach(
			(forecastedHedgedAndCappedVolume: AggregateAllocatedForecastedHedgedAndCappedVolumeDTO) => {
				if (forecastedHedgedAndCappedVolume.date == undefined) return;

				const monthDate = DateTime.fromISO(forecastedHedgedAndCappedVolume.date).startOf('month');

				const quarterStartDate = monthDate.startOf('quarter').toISODate();

				const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
				const month = getOrCreateMonth(monthDate.toISODate(), quarter, getOwner(this));

				if (forecastedHedgedAndCappedVolume?.Product && forecastedHedgedAndCappedVolume.Product.slug === 'us-dairy-class-iii') {
					month.classiiiBrokerageHedgedVolume = forecastedHedgedAndCappedVolume?.sum.naturallyLongHedged ?? 0;
				} else if (forecastedHedgedAndCappedVolume?.Product && forecastedHedgedAndCappedVolume.Product.slug === 'us-dairy-class-iv') {
					month.classivBrokerageHedgedVolume = forecastedHedgedAndCappedVolume?.sum.naturallyLongHedged ?? 0;
				}
			},
		);

		data?.AggregateDairyPositions.forEach((aggregate: AggregateCurrentAllocationPositionDTO) => {
			if (!aggregate.effectiveHedgeDate) return;

			const monthDate = DateTime.fromISO(aggregate.effectiveHedgeDate);

			const quarterStartDate = monthDate.startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(monthDate.toISODate(), quarter, getOwner(this));
			month.dairyBrokeragePosition = aggregate.sum;
		});

		data?.AggregateFeedPositions.forEach((aggregate: AggregateCurrentAllocationPositionDTO) => {
			if (!aggregate.effectiveHedgeDate) return;

			const monthDate = DateTime.fromISO(aggregate.effectiveHedgeDate);

			const quarterStartDate = monthDate.startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(monthDate.toISODate(), quarter, getOwner(this));
			month.feedBrokeragePosition = aggregate.sum;
		});

		data?.ClassiiiExposure?.forEach((aggregate) => {
			if (aggregate.date == undefined) return;

			const quarterStartDate = DateTime.fromISO(aggregate.date).startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(aggregate.date, quarter, getOwner(this));
			month.classiiiExposure = aggregate;
		});

		data?.ClassivExposure.forEach((aggregate) => {
			if (aggregate.date == undefined) return;

			const quarterStartDate = DateTime.fromISO(aggregate.date).startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(aggregate.date, quarter, getOwner(this));
			month.classivExposure = aggregate;
		});

		data?.CheeseExposure.forEach((aggregate) => {
			if (aggregate.date == undefined) return;

			const quarterStartDate = DateTime.fromISO(aggregate.date).startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(aggregate.date, quarter, getOwner(this));
			month.cheeseExposure = aggregate;
		});

		data?.ButterExposure.forEach((aggregate) => {
			if (aggregate.date == undefined) return;

			const quarterStartDate = DateTime.fromISO(aggregate.date).startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(aggregate.date, quarter, getOwner(this));
			month.butterExposure = aggregate;
		});

		data?.DryWheyExposure.forEach((aggregate) => {
			if (aggregate.date == undefined) return;

			const quarterStartDate = DateTime.fromISO(aggregate.date).startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(aggregate.date, quarter, getOwner(this));
			month.dryWheyExposure = aggregate;
		});

		data?.NonfatExposure.forEach((aggregate) => {
			if (aggregate.date == undefined) return;

			const quarterStartDate = DateTime.fromISO(aggregate.date).startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(aggregate.date, quarter, getOwner(this));
			month.nonfatExposure = aggregate;
		});

		data?.ClassIIIFutures?.forEach((future: Future) => {
			const quarterStartDate = DateTime.fromISO(future.displayExpiresAt).startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(future.displayExpiresAt, quarter, getOwner(this));
			month.classiiiFuture = future;
		});

		data?.ClassIVFutures?.forEach((future: Future) => {
			const quarterStartDate = DateTime.fromISO(future.displayExpiresAt).startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(future.displayExpiresAt, quarter, getOwner(this));
			month.classivFuture = future;
		});

		data?.ButterFutures?.forEach((future: Future) => {
			const quarterStartDate = DateTime.fromISO(future.displayExpiresAt).startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(future.displayExpiresAt, quarter, getOwner(this));
			month.butterFuture = future;

			const advancedDate = DateTime.fromISO(future.displayExpiresAt).plus({ month: 1 }).toISODate();

			const advancedQuarterStartDate = DateTime.fromISO(advancedDate).startOf('quarter').toISODate();

			const advancedQuarter = getOrCreateQuarter(advancedQuarterStartDate, quarterMap);
			const advancedMonth = getOrCreateMonth(advancedDate, advancedQuarter, getOwner(this));
			advancedMonth.advancedButterFuture = future;
		});

		data?.NonfatDryMilkFutures?.forEach((future: Future) => {
			const quarterStartDate = DateTime.fromISO(future.displayExpiresAt).startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(future.displayExpiresAt, quarter, getOwner(this));
			month.nonfatDryMilkFuture = future;

			const advancedDate = DateTime.fromISO(future.displayExpiresAt).plus({ month: 1 }).toISODate();
			const advancedQuarterStartDate = DateTime.fromISO(advancedDate).startOf('quarter').toISODate();

			const advancedQuarter = getOrCreateQuarter(advancedQuarterStartDate, quarterMap);
			const advancedMonth = getOrCreateMonth(advancedDate, advancedQuarter, getOwner(this));
			advancedMonth.advancedNonfatDryMilkFuture = future;
		});

		data?.CheeseFutures?.forEach((future: Future) => {
			const quarterStartDate = DateTime.fromISO(future.displayExpiresAt).startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(future.displayExpiresAt, quarter, getOwner(this));
			month.cheeseFuture = future;

			const advancedDate = DateTime.fromISO(future.displayExpiresAt).plus({ month: 1 }).toISODate();

			const advancedQuarterStartDate = DateTime.fromISO(advancedDate).startOf('quarter').toISODate();

			const advancedQuarter = getOrCreateQuarter(advancedQuarterStartDate, quarterMap);
			const advancedMonth = getOrCreateMonth(advancedDate, advancedQuarter, getOwner(this));
			advancedMonth.advancedCheeseFuture = future;
		});

		data?.DryWheyFutures?.forEach((future: Future) => {
			const advancedDate = DateTime.fromISO(future.displayExpiresAt).plus({ month: 1 }).toISODate();

			const advancedQuarterStartDate = DateTime.fromISO(advancedDate).startOf('quarter').toISODate();

			const advancedQuarter = getOrCreateQuarter(advancedQuarterStartDate, quarterMap);
			const advancedMonth = getOrCreateMonth(advancedDate, advancedQuarter, getOwner(this));
			advancedMonth.advancedDryWheyFuture = future;
		});

		data?.SoybeanMealFutures?.forEach((future: Future) => {
			const relevantDates = cmeFutureMonthToRelevantMonths(future.displayExpiresAt, 'grain-soybean-meal');

			relevantDates.forEach((date) => {
				const quarterStartDate = DateTime.fromISO(date).startOf('quarter').toISODate();

				const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
				const month = getOrCreateMonth(date, quarter, getOwner(this));
				month.soybeanMealFuture = future;
			});
		});

		data?.CornFutures?.forEach((future: Future) => {
			const relevantDates = cmeFutureMonthToRelevantMonths(future.displayExpiresAt, 'grain-corn');

			relevantDates.forEach((date) => {
				const quarterStartDate = DateTime.fromISO(date).startOf('quarter').toISODate();
				const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
				const month = getOrCreateMonth(date, quarter, getOwner(this));
				month.cornFuture = future;
			});
		});

		data?.AggregateLedgerEntries?.forEach((aggregateLedgerEntry: AggregateLedgerEntryDTO) => {
			if (aggregateLedgerEntry.month == undefined || aggregateLedgerEntry.year == undefined) return;

			const monthDate = DateTime.local(aggregateLedgerEntry.year, aggregateLedgerEntry.month).startOf('month');
			const quarterStartDate = monthDate.startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(monthDate.toISODate(), quarter, getOwner(this));
			month.operation = aggregateLedgerEntry.sum.calculatedAmount;
		});

		data?.AggregateRevenueLedgerEntries?.forEach((aggregateLedgerEntry: AggregateLedgerEntryDTO) => {
			if (aggregateLedgerEntry.month == undefined || aggregateLedgerEntry.year == undefined) return;

			const monthDate = DateTime.local(aggregateLedgerEntry.year, aggregateLedgerEntry.month).startOf('month');
			const quarterStartDate = monthDate.startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(monthDate.toISODate(), quarter, getOwner(this));
			month.revenues = aggregateLedgerEntry.sum.calculatedAmount;
		});

		data?.AggregateExpenseLedgerEntries?.forEach((aggregateLedgerEntry: AggregateLedgerEntryDTO) => {
			if (aggregateLedgerEntry.month == undefined || aggregateLedgerEntry.year == undefined) return;

			const monthDate = DateTime.local(aggregateLedgerEntry.year, aggregateLedgerEntry.month).startOf('month');
			const quarterStartDate = monthDate.startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(monthDate.toISODate(), quarter, getOwner(this));
			if (aggregateLedgerEntry.LedgerCategory?.type == TypeOfLedgerCategory.Expense) {
				month.expenses = aggregateLedgerEntry.sum.calculatedAmount;
			}
			if (aggregateLedgerEntry.LedgerCategory?.type == TypeOfLedgerCategory.Feed) {
				month.fixedFeedExpenses = aggregateLedgerEntry.sum.calculatedAmount;
			}
		});

		data?.AggregateLedgerForecastedEntries?.forEach((aggregateLedgerForecastedEntry) => {
			if (aggregateLedgerForecastedEntry.month == undefined || aggregateLedgerForecastedEntry.year == undefined) return;

			const monthDate = DateTime.local(aggregateLedgerForecastedEntry.year, aggregateLedgerForecastedEntry.month).startOf('month');
			const quarterStartDate = monthDate.startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(monthDate.toISODate(), quarter, getOwner(this));
			month.monthlyCalculatedAmount = aggregateLedgerForecastedEntry.sum.calculatedAmount;
		});

		data?.FeedIngredientConsumedAndPurchasedVolumes?.forEach((volume) => {
			if (volume.monthStartDate == undefined) return;

			const monthDate = DateTime.fromISO(volume.monthStartDate).startOf('month');
			const quarterStartDate = monthDate.startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(monthDate.toISODate(), quarter, getOwner(this));
			month.feedIngredientConsumedAndPurchasedVolumes.push(volume);
		});

		calculateMonthlyWeightedPricesAndBasisValues(this.startDate, this.endDate, data?.ForecastedMilkProductionByMonths ?? []).forEach(
			(v) => {
				const monthDate = DateTime.fromISO(v.date).startOf('month');
				const quarterStartDate = monthDate.startOf('quarter').toISODate();

				const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
				const month = getOrCreateMonth(monthDate.toISODate(), quarter, getOwner(this));

				month.everAgBasis = v.locationCurrentBasis;
			},
		);

		this.filterQuarterMapByDate(quarterMap, this.startDate, this.endDate);

		const results = Object.entries(quarterMap).map(([date, quarterObject]) => {
			return new Quarter(date, quarterObject.children);
		});

		return results.sort((a, b) => (a.date < b.date ? -1 : 1)).sortBy('date');
	}

	get averageFinishAgeInWeeks() {
		return (
			this.model.getPigsDashboard?.data?.Customer?.averageFinishAgeInWeeks ?? (ENV.APP.DEFAULT_AVG_SWINE_FINISH_AGE_IN_WEEKS as number)
		);
	}

	get pnlMonths() {
		const pnlMonthsMap = new Map<string, PnlMonthArgs>();

		const getPnlMonthArgs = (date: string) => {
			if (pnlMonthsMap.has(date)) {
				return pnlMonthsMap.get(date) as PnlMonthArgs;
			}

			return;
		};

		let currDateTime = DateTime.fromISO(this.startDate).startOf('month');
		const endDateTime = DateTime.fromISO(this.endDate).startOf('month');

		while (currDateTime <= endDateTime) {
			const date = currDateTime.toISODate();
			const pnlMonthArgs: PnlMonthArgs = {
				date,
				avgLbsPerHead: this.model.getPigsDashboard?.data?.Customer?.averageFinishWeightInLbs ?? 0,
				numPigs: null,
				swineSales: null,
				swinePurchases: null,
				cmeSwineRevenue: null,
				cmeSwineHedged: null,
				nonFeedRevenue: null,
				insurancePnl: null,
				feedExpenses: null,
				cmeFeedRevenue: null,
				cmeFeedHedged: null,
				nonFeedExpenses: null,
			};

			pnlMonthsMap.set(date, pnlMonthArgs);
			currDateTime = currDateTime.plus({ month: 1 }).startOf('month');
		}

		this.model.getPigsDashboard?.data?.AggregateCurrentAllocationPositions.forEach((currentPosition) => {
			const date = currentPosition.effectiveHedgeDate;
			const quantity = currentPosition.sum.contractQuantity;
			const instrumentType = currentPosition.instrumentType;
			const optionType = currentPosition.optionType;
			const productSlug = currentPosition?.Product?.slug;
			if (!date) return;

			const startOfMonth = DateTime.fromISO(date).startOf('month').toISODate();
			const pnlMonth = getPnlMonthArgs(startOfMonth);
			if (!pnlMonth) return;

			const grossPnl = currentPosition.sum.grossPnl;
			if (grossPnl == null) return;

			if (productSlug === 'livestock-lean-hogs') {
				if (
					// Short futures and Short Puts are swine hedges, others are revenue
					(quantity != null && instrumentType === TypeOfInstrument.Future && quantity < 0) ||
					(quantity != null && instrumentType === TypeOfInstrument.Option && optionType === TypeOfOption.Put && quantity < 0)
				) {
					pnlMonth.cmeSwineHedged = pnlMonth.cmeSwineHedged ? pnlMonth.cmeSwineHedged + grossPnl : grossPnl;
				} else {
					pnlMonth.cmeSwineRevenue = pnlMonth.cmeSwineRevenue ? pnlMonth.cmeSwineRevenue + grossPnl : grossPnl;
				}
			} else {
				// Feed Related
				if (
					// long futures and long calls are feed hedges, others are revenue
					(quantity != null && instrumentType === TypeOfInstrument.Future && quantity > 0) ||
					(quantity != null && instrumentType === TypeOfInstrument.Option && optionType === TypeOfOption.Call && quantity > 0)
				) {
					pnlMonth.cmeFeedHedged = pnlMonth.cmeFeedHedged ? pnlMonth.cmeFeedHedged + grossPnl : grossPnl;
				} else {
					pnlMonth.cmeFeedRevenue = pnlMonth.cmeFeedRevenue ? pnlMonth.cmeFeedRevenue + grossPnl : grossPnl;
				}
			}
		});

		this.model.getPigsDashboard?.data?.AggregateForecastedSwineLivestockFeedUsages.forEach((aggregateFeedUsage) => {
			const dob = aggregateFeedUsage.dob;
			if (!dob) return;

			const expectedMarketingStartOfMonth = getExpectedMarketingStartOfMonth(dob, this.averageFinishAgeInWeeks);

			const totalExpense = aggregateFeedUsage.sum.salesAdjustedTotalExpenseInUsd
				? -aggregateFeedUsage.sum.salesAdjustedTotalExpenseInUsd
				: null;

			if (!expectedMarketingStartOfMonth || totalExpense == null) return;

			const pnlMonth = getPnlMonthArgs(expectedMarketingStartOfMonth);
			if (!pnlMonth) return;

			pnlMonth.feedExpenses = pnlMonth.feedExpenses ? pnlMonth.feedExpenses + totalExpense : totalExpense;
		});

		this.model.getPigsDashboard?.data?.AllocatedLrpInsuranceEndorsements.forEach((lrpEndorsement) => {
			const effectiveHedgeDate = lrpEndorsement.effectiveHedgeDate;
			const pnl = lrpEndorsement.RatioAdjustedInsuranceEndorsement.pnl;

			if (!effectiveHedgeDate || pnl == null) return;

			const pnlMonth = getPnlMonthArgs(effectiveHedgeDate);
			if (!pnlMonth) return;
			pnlMonth.insurancePnl = pnlMonth.insurancePnl ? pnlMonth.insurancePnl + pnl : pnl;
		});

		this.model.getPigsDashboard?.data?.AllocatedLgmInsuranceEndorsements.forEach((lgmEndorsement) => {
			const effectiveHedgeDate = lgmEndorsement.effectiveHedgeDate;
			const pnl = lgmEndorsement.RatioAdjustedInsuranceEndorsement.pnl;

			if (!effectiveHedgeDate || pnl == null) return;

			const pnlMonth = getPnlMonthArgs(effectiveHedgeDate);
			if (!pnlMonth) return;
			pnlMonth.insurancePnl = pnlMonth.insurancePnl ? pnlMonth.insurancePnl + pnl : pnl;
		});

		this.model.getPigsDashboard?.data?.SwineSalesPurchasesAndProduced.forEach((swinePopulationChange) => {
			if (swinePopulationChange.reasonType === TypeOfLivestockPopulationChangeReason.Sale) {
				const saleDate = swinePopulationChange.date;
				const totalValue = swinePopulationChange.sum.totalValue != null ? Math.abs(swinePopulationChange.sum.totalValue) : null;

				if (!saleDate || totalValue == null) return;

				const date = DateTime.fromISO(saleDate).startOf('month').toISODate();
				const pnlMonth = getPnlMonthArgs(date);
				if (!pnlMonth) return;

				const numSold = swinePopulationChange.sum.quantity != null ? Math.abs(swinePopulationChange.sum.quantity) : 0;

				pnlMonth.numPigs = pnlMonth.numPigs ? pnlMonth.numPigs + numSold : numSold;
				pnlMonth.swineSales = pnlMonth.swineSales ? pnlMonth.swineSales + totalValue : totalValue;
			} else if (
				swinePopulationChange.reasonType === TypeOfLivestockPopulationChangeReason.Purchase ||
				swinePopulationChange.reasonType === TypeOfLivestockPopulationChangeReason.Birth
			) {
				const dob = swinePopulationChange.dob;
				if (!dob) return;

				const expectedMarketingDate = DateTime.fromISO(dob).plus({
					weeks: this.averageFinishAgeInWeeks,
				});

				const totalValue = swinePopulationChange.sum.totalValue;

				if (!expectedMarketingDate || totalValue == null) return;

				const date = expectedMarketingDate.startOf('month').toISODate();
				const pnlMonth = getPnlMonthArgs(date);
				if (!pnlMonth) return;

				// Purchases return positive value. Flip sign to treat them as expenses
				pnlMonth.swinePurchases = pnlMonth.swinePurchases ? pnlMonth.swinePurchases - totalValue : -totalValue;
			}
		});

		this.model.getPigsDashboard?.data?.AggregateExpenseLedgerEntries.forEach((expense) => {
			const month = expense.month;
			const year = expense.year;
			const expenseAmount = expense.sum.calculatedAmount;

			if (!month || !year || expenseAmount == null) return;

			const date = DateTime.fromObject({ month, year }).toISODate();
			const pnlMonth = getPnlMonthArgs(date);
			if (!pnlMonth) return;

			pnlMonth.nonFeedExpenses = pnlMonth.nonFeedExpenses ? pnlMonth.nonFeedExpenses + expenseAmount : expenseAmount;
		});

		this.model.getPigsDashboard?.data?.AggregateRevenueLedgerEntries.forEach((revenue) => {
			const month = revenue.month;
			const year = revenue.year;
			const revenueAmount = revenue.sum.calculatedAmount;

			if (!month || !year || revenueAmount == null) return;

			const date = DateTime.fromObject({ month, year }).toISODate();
			const pnlMonth = getPnlMonthArgs(date);
			if (!pnlMonth) return;

			pnlMonth.nonFeedRevenue = pnlMonth.nonFeedRevenue ? pnlMonth.nonFeedRevenue + revenueAmount : revenueAmount;
		});

		return Array.from(pnlMonthsMap.values())
			.sortBy('date')
			.map((args) => {
				return new PnlMonth(
					args.date,
					args.avgLbsPerHead ?? 0,
					args.numPigs,
					args.swineSales,
					args.swinePurchases,
					args.cmeSwineRevenue,
					args.cmeSwineHedged,
					args.nonFeedRevenue,
					args.insurancePnl,
					args.feedExpenses,
					args.cmeFeedRevenue,
					args.cmeFeedHedged,
					args.nonFeedExpenses,
				);
			});
	}

	get projectedPnlRows() {
		if (this.type == 'pig-dashboard') {
			return this.pnlMonths.map((month) => {
				const data: ProjectedPnlRow = {
					date: month.date,
					swineSalesRevenue: null,
					cmeSwinePnl: null,
					otherRevenue: null,
					insurancePnl: null,
					feedExpenses: null,
					cmeFeedPnl: null,
					otherExpenses: null,
					netPnl: null,
				};

				if (this.selectedDisplayUnit === DisplayUnits.Total) {
					data.swineSalesRevenue = month.swineSales;
					data.cmeSwinePnl = month.cmeSwine;
					data.otherRevenue = month.otherRevenue;
					data.insurancePnl = month.insurancePnl;
					data.feedExpenses = month.feedExpenses;
					data.cmeFeedPnl = month.cmeFeed;
					data.otherExpenses = month.otherExpenses;
					data.netPnl = month.netPnl;
				} else if (this.selectedDisplayUnit === DisplayUnits.PerHead) {
					data.swineSalesRevenue = month.swineSalesPerHead;
					data.cmeSwinePnl = month.cmeSwinePerHead;
					data.otherRevenue = month.otherRevenuePerHead;
					data.insurancePnl = month.insurancePnlPerHead;
					data.feedExpenses = month.feedExpensesPerHead;
					data.cmeFeedPnl = month.cmeFeedPerHead;
					data.otherExpenses = month.otherExpensesPerHead;
					data.netPnl = month.netPnlPerHead;
				} else if (this.selectedDisplayUnit === DisplayUnits.PerCWT) {
					data.swineSalesRevenue = month.swineSalesCwt;
					data.cmeSwinePnl = month.cmeSwineCwt;
					data.otherRevenue = month.otherRevenueCwt;
					data.insurancePnl = month.insurancePnlCwt;
					data.feedExpenses = month.feedExpensesCwt;
					data.cmeFeedPnl = month.cmeFeedCwt;
					data.otherExpenses = month.otherExpensesCwt;
					data.netPnl = month.netPnlCwt;
				}
				return data;
			});
		} else if (this.type == 'dairy-dashboard') {
			return this.dairyQuarters;
		}
		return null;
	}

	get projectedPnlTotalRow(): [Omit<ProjectedPnlRow, 'date'>] | null {
		if (this.type == 'pig-dashboard') {
			const totals: Omit<ProjectedPnlRow, 'date'> = {
				swineSalesRevenue: null,
				cmeSwinePnl: null,
				otherRevenue: null,
				insurancePnl: null,
				feedExpenses: null,
				cmeFeedPnl: null,
				otherExpenses: null,
				netPnl: null,
			};

			const addToTotal = (key: keyof Omit<ProjectedPnlRow, 'date'>, value: number | null) => {
				if (value == null) return;

				totals[key] = (totals[key] ?? 0) + value;
			};

			const pigProjectedPnlRows: ProjectedPnlRow[] | null = Array.isArray(this.projectedPnlRows)
				? (this.projectedPnlRows as ProjectedPnlRow[])
				: null;

			pigProjectedPnlRows?.forEach((row: ProjectedPnlRow) => {
				addToTotal('swineSalesRevenue', row.swineSalesRevenue);
				addToTotal('cmeSwinePnl', row.cmeSwinePnl);
				addToTotal('otherRevenue', row.otherRevenue);
				addToTotal('insurancePnl', row.insurancePnl);
				addToTotal('feedExpenses', row.feedExpenses);
				addToTotal('cmeFeedPnl', row.cmeFeedPnl);
				addToTotal('netPnl', row.netPnl);
				addToTotal('otherExpenses', row.otherExpenses);
			});
			return [totals];
		}
		return null;
	}

	get projectedPnlColumns(): TableColumn[] {
		if (this.type == 'pig-dashboard') {
			return [
				{
					id: '8109668f-e206-48f7-b1c5-00ab74b1aee4',
					name: 'Date',
					valuePath: 'date',
					cellComponent: CellComponents.MonthFormat,
					textAlign: 'left',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: '2792e94a-bc5f-482e-a196-c123fe960f52',
					name: 'Swine Sales',
					valuePath: 'swineSalesRevenue',
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						currencySign: 'accounting',
						minimumFractionDigits: this.selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
						maximumFractionDigits: this.selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: '94ad1ca6-cf0b-4ca3-bbc0-1ea3d8f25ba5',
					name: 'CME Swine',
					valuePath: 'cmeSwinePnl',
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						currencySign: 'accounting',
						minimumFractionDigits: this.selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
						maximumFractionDigits: this.selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: 'b75f45b1-6c5a-4485-a863-a00c0272f105',
					name: 'Other Revenue',
					valuePath: 'otherRevenue',
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						currencySign: 'accounting',
						minimumFractionDigits: this.selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
						maximumFractionDigits: this.selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: '22f7e690-b1ca-441d-a8c4-faaef1ec9eaa',
					name: 'Insurance',
					valuePath: 'insurancePnl',
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						currencySign: 'accounting',
						minimumFractionDigits: this.selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
						maximumFractionDigits: this.selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: '0c8cdfac-e9c9-44cb-90eb-e278a78f4111',
					name: 'Feed Expenses',
					valuePath: 'feedExpenses',
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						currencySign: 'accounting',
						minimumFractionDigits: this.selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
						maximumFractionDigits: this.selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: '690c39c6-2d0f-4cc8-a781-5471a4a6d8dc',
					name: 'CME Feed',
					valuePath: 'cmeFeedPnl',
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						currencySign: 'accounting',
						minimumFractionDigits: this.selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
						maximumFractionDigits: this.selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: '9e3942e9-a164-41b8-b4a5-01b806facb26',
					name: 'Other Expenses',
					valuePath: 'otherExpenses',
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						currencySign: 'accounting',
						minimumFractionDigits: this.selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
						maximumFractionDigits: this.selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: '0a526007-7fff-4e64-a069-b80ac6c857af',
					name: 'Net P/L',
					valuePath: 'netPnl',
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						currencySign: 'accounting',
						minimumFractionDigits: this.selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
						maximumFractionDigits: this.selectedDisplayUnit === DisplayUnits.Total ? 0 : 2,
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: 'right',
					isVisible: true,
				},
			];
		} else if (this.type == 'dairy-dashboard') {
			return this.dairyProjectedRevenueColumns;
		}
		return [];
	}

	get dateRange() {
		return {
			startDate: this.startDate,
			endDate: this.endDate,
		};
	}

	@action
	setDateRange(value: { startDate: string; endDate: string }) {
		this.startDate = value.startDate ? DateTime.fromISO(value.startDate).startOf('month').toISODate() : '';
		this.endDate = value.endDate ? DateTime.fromISO(value.endDate).endOf('month').toISODate() : '';
		this.setTablePageState();
	}

	@action
	setTablePageState() {
		resetVaultTableScroll('projected-pnl-table');
	}

	filterQuarterMapByDate(quarterMap: IQuarterObjects, startDate: string, endDate: string) {
		// Remove quarters and months that do not fall within the specified date range.
		// This mutates the original object.

		Object.keys(quarterMap).forEach((quarter) => {
			Object.keys(quarterMap[quarter].children ?? {}).forEach((month) => {
				// Outside of range
				if (month < startDate || month > endDate) {
					delete quarterMap[quarter].children[month];
				}
			});

			// Delete empty quarters
			if (Object.keys(quarterMap[quarter].children).length === 0) {
				delete quarterMap[quarter];
			}
		});
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/projected-pl': BusinessesBusinessProjectedPLController;
	}
}
