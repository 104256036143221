import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { queryManager } from 'ember-apollo-client';
import { inject as service } from '@ember/service';
import searchQuery from 'vault-client/graphql/queries/search.graphql';
import agentFuzzyQuery from 'vault-client/graphql/queries/agent-fuzzy-match.graphql';
import stateFuzzyQuery from 'vault-client/graphql/queries/state-fuzzy-match.graphql';
import { DateTime } from 'luxon';
import getFilterDisplayProperty from 'vault-client/utils/get-filter-display-property';
import query from 'vault-client/graphql/queries/lrp-insurance-endorsements/index.graphql';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import { CellComponents, SortObj, TableColumn } from 'vault-client/types/vault-table';
import {
	LrpInsuranceEndorsement,
	LrpInsuranceEndorsementFilterDTO,
	LrpInsuranceEndorsementSortByDTO,
	State,
} from 'vault-client/types/graphql-types';
import LRPInsuranceEndorsementsIndexRoute from 'vault-client/routes/lrp-insurance-endorsements';
import ApplicationScope from 'vault-client/services/application-scope';
import { ModelFrom } from 'vault-client/utils/type-utils';
import BusinessesBusinessHedgeStrategiesEditLrpEndorsementsIndexRoute from 'vault-client/routes/businesses/business/hedge-strategies/edit-lrp-endorsements/index';
import LrpInsuranceEndorsementModel from 'vault-client/models/lrp-endorsement';
import { getOwner } from '@ember/application';
import { getStableId } from 'vault-client/utils/get-stable-id';

const queryParams = [
	'agent',
	'aipId',
	'customerId',
	'salesEffectiveStartDate',
	'salesEffectiveEndDate',
	'coverageEndStartDate',
	'coverageEndEndDate',
	'stateId',
	'page',
	'sorts',
	'size',
];

interface DateFilterQueryParamPrefixes {
	salesEffective: string | null;
	coverageEnd: string | null;
}

type DateFilterQueryParamPrefix = keyof DateFilterQueryParamPrefixes;

interface SearchFilterIdentifiers {
	agent: string | null;
	aipId: string | null;
	customerId: string | null;
	stateId: string | null;
}

type SearchFilterIdentifier = keyof SearchFilterIdentifiers;

function isSearchFilterIdentifier(key: unknown): key is SearchFilterIdentifier {
	const searchFilterIdentifiers = ['agent', 'aipId', 'customerId', 'stateId'];
	if (typeof key === 'string' && searchFilterIdentifiers.includes(key)) {
		return true;
	}
	return false;
}

interface searchResult {
	id?: string;
	name?: string;
	type: string;
}

interface Agent {
	agentFirstName: string;
	agentLastName: string;
}

export default class BusinessesBusinessHedgeStrategiesEditLrpEndorsementsIndexController extends Controller {
	@queryManager apollo: any;
	@service declare applicationScope: ApplicationScope;

	declare model: ModelFrom<BusinessesBusinessHedgeStrategiesEditLrpEndorsementsIndexRoute>;
	lrpInsuranceEndorsementRoute: string = '';
	insuranceRoutePath: string = '';
	insurancePolicyRoutePath: string = '';
	searchPlaceholder = 'Filter by AIP, agent, state...';
	searchPrompt = 'Type a search term to filter endorsements by AIP, agent name, or state.';

	queryParams = queryParams;

	salesEffectiveDateRangeOptions = [
		{
			displayName: 'All Dates',
			startDate: '1900-01-01',
			endDate: '2999-12-31',
		},
		{
			displayName: 'Previous 7 Days',
			startDate: DateTime.local().minus({ days: 7 }).toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Previous 30 Days',
			startDate: DateTime.local().minus({ days: 30 }).toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Previous 90 Days',
			startDate: DateTime.local().minus({ days: 90 }).toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Previous 12 Months',
			startDate: DateTime.local().minus({ months: 12 }).startOf('month').toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Previous 24 Months',
			startDate: DateTime.local().minus({ months: 24 }).startOf('month').toISODate(),
			endDate: DateTime.local().toISODate(),
		},
	];

	coverageEndDateRangeOptions = [
		{
			displayName: 'All Dates',
			startDate: '1900-01-01',
			endDate: '2999-12-31',
		},
		{
			displayName: 'Previous 12 Months',
			startDate: DateTime.local().minus({ months: 12 }).startOf('month').toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Next 7 Days',
			startDate: DateTime.local().toISODate(),
			endDate: DateTime.local().plus({ days: 7 }).toISODate(),
		},
		{
			displayName: 'Next 30 Days',
			startDate: DateTime.local().toISODate(),
			endDate: DateTime.local().plus({ days: 30 }).toISODate(),
		},
		{
			displayName: 'Next 90 Days',
			startDate: DateTime.local().toISODate(),
			endDate: DateTime.local().plus({ days: 90 }).toISODate(),
		},
		{
			displayName: 'Next 12 Months',
			startDate: DateTime.local().toISODate(),
			endDate: DateTime.local().endOf('month').plus({ months: 12 }).toISODate(),
		},
	];

	@tracked agent: string | null = null;
	@tracked aipId: string | null = null;
	@tracked salesEffectiveStartDate: string | null = '1900-01-01';
	@tracked salesEffectiveEndDate: string | null = '2999-12-31';
	@tracked coverageEndStartDate: string | null = DateTime.local().toISODate();
	@tracked coverageEndEndDate: string | null = DateTime.local().endOf('month').plus({ months: 12 }).toISODate();
	@tracked stateId: string | null = null;
	@tracked customerId: string | null = null;
	@tracked currentScope = '';
	@tracked page = 0;
	@tracked sorts = [{ valuePath: 'coverageEndDate', isAscending: true }];
	@tracked size = 100;

	getLrpColumns(tableType: 'selected' | 'available'): TableColumn[] {
		const baseColumns = [
			{
				id: getStableId(`${tableType}-salesEffectiveDate`),
				name: 'Sales Date',
				valuePath: 'salesEffectiveDate',
				width: 135,
				cellComponent: CellComponents.IntlDateTimeFormat,
				componentArgs: { month: 'numeric', day: 'numeric', year: 'numeric' },
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				linkRoute: this.lrpInsuranceEndorsementRoute,
				linkModelPath: 'id',
				isVisible: true,
			},
			{
				id: getStableId(`${tableType}-coverageEndDate`),
				name: 'Coverage End Date',
				valuePath: 'coverageEndDate',
				width: 175,
				cellComponent: CellComponents.IntlDateTimeFormat,
				componentArgs: { month: 'numeric', day: 'numeric', year: 'numeric' },
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: getStableId(`${tableType}-coveragePrice`),
				name: 'Price',
				valuePath: 'coveragePrice',
				width: 110,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: getStableId(`${tableType}-headCount`),
				name: 'Head Count',
				valuePath: 'headCount',
				width: 135,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '19422c96-fb0a-4ab1-b034-cf1ac9eaff85',
				name: 'Delta Quantity',
				valuePath: 'lrpModelData.delta',
				width: 120,
				cellComponent: CellComponents.NumericFormat,
				componentArgs: {
					maximumFractionDigits: '1',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: getStableId(`${tableType}-targetWeightCwt`),
				name: 'Target Weight (CWT)',
				valuePath: 'targetWeightCwt',
				width: 185,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: getStableId(`${tableType}-producerPremiumAmount`),
				name: 'Producer Premium',
				valuePath: 'producerPremiumAmount',
				width: 150,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: getStableId(`${tableType}-indemnity`),
				name: 'Est. Indemnity',
				valuePath: 'indemnity',
				width: 150,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
			},
			{
				id: getStableId(`${tableType}-pnl`),
				name: 'P/L',
				valuePath: 'pnl',
				width: 150,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
			},
			{
				id: getStableId(`${tableType}-producerName`),
				name: 'Producer',
				valuePath: 'InsurancePolicy.producerName',
				width: 225,
				minWidth: 225, // need minWidth === width re bug on resizing first fixed column
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isVisible: false,
				isFixed: '',
			},
			{
				id: getStableId(`${tableType}-stateAbbreviation`),
				name: 'State',
				valuePath: 'State.abbreviation',
				width: 90,
				cellComponent: CellComponents.String,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: getStableId(`${tableType}-stateFips`),
				name: 'State (FIPS)',
				valuePath: 'State.rmaStateCode',
				width: 120,
				cellComponent: CellComponents.String,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: getStableId(`${tableType}-totalPremiumAmount`),
				name: 'Total Premium',
				valuePath: 'totalPremiumAmount',
				width: 150,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: false,
				isTotaled: true,
			},
			{
				id: getStableId(`${tableType}-commodityName`),
				name: 'Commodity',
				valuePath: 'commodityName',
				width: 130,
				cellComponent: CellComponents.String,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: getStableId(`${tableType}-rmaTypeName`),
				name: 'Type',
				valuePath: 'RmaType.typeName',
				width: 120,
				cellComponent: CellComponents.String,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: getStableId(`${tableType}-insuredValue`),
				name: 'Insured Value',
				valuePath: 'insuredValue',
				width: 150,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: false,
				isTotaled: true,
			},
			{
				id: getStableId(`${tableType}-lengthInWeeks`),
				name: 'Length (weeks)',
				valuePath: 'lengthInWeeks',
				width: 150,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: false,
			},
			{
				id: getStableId(`${tableType}-subsidyAmount`),
				name: 'Subsidy',
				valuePath: 'subsidyAmount',
				width: 120,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: false,
				isTotaled: true,
			},
			{
				id: getStableId(`${tableType}-policyNumber`),
				name: 'Policy',
				valuePath: 'InsurancePolicy.policyNumber',
				width: 150,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				linkRoute: this.insurancePolicyRoutePath,
				linkModelPath: 'insurancePolicyId',
				isVisible: false,
			},
			{
				id: getStableId(`${tableType}-agencyName`),
				name: 'Agency',
				valuePath: 'InsurancePolicy.agencyName',
				width: 200,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: false,
			},
			{
				id: getStableId(`${tableType}-aipName`),
				name: 'AIP',
				valuePath: 'InsurancePolicy.AIP.name',
				width: 120,
				textAlign: '',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: false,
			},
			{
				id: getStableId(`${tableType}-agentFullName`),
				name: 'Agent',
				valuePath: 'InsurancePolicy.agentFullName',
				width: 170,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '2b333511-4ad8-491d-85ea-e45bf136fa51',
				name: '',
				valuePath: 'isAdded',
				minWidth: 125,
				maxWidth: 125,
				width: 125,
				cellComponent: CellComponents.UpdateHedgeStrategyItemsButton,
				componentArgs: {
					hedgeStrategyId: this.hedgeStrategyId,
					updateLrpEndorsements: this.updateLrpEndorsements,
					tableType,
				},
				textAlign: 'center',
				isSortable: false,
				isFixed: 'right',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get selectedLrpColumns(): TableColumn[] {
		return this.getLrpColumns('selected');
	}

	get availableLrpColumns(): TableColumn[] {
		return this.getLrpColumns('available');
	}

	get hedgeStrategy() {
		return this.model.HedgeStrategy.data?.HedgeStrategy;
	}

	get hedgeStrategyId() {
		return this.model.HedgeStrategy.data?.HedgeStrategy?.id;
	}

	get selectedLrpRows() {
		const data = this.model.HedgeStrategy.data?.HedgeStrategy?.InsuranceEndorsements as LrpInsuranceEndorsement[];
		if (!data) return null;
		const dataObj = data.map((endorsement) => {
			let lrpModelData: LrpInsuranceEndorsement | LrpInsuranceEndorsementModel | null = null;

			if (endorsement.revenueHedgeProductId) {
				lrpModelData = new LrpInsuranceEndorsementModel(getOwner(this), endorsement, undefined);
			}
			const { ...endorsementObj } = endorsement;
			return {
				...endorsementObj,
				lrpModelData: lrpModelData,
			};
		});
		return this.checkIfAddedToStrategy(dataObj);
	}

	get availableLrpRows() {
		const data = this.model.LrpInsuranceEndorsements.data?.LrpInsuranceEndorsements;
		if (!data) return null;
		const dataObj = data.map((endorsement) => {
			let lrpModelData: LrpInsuranceEndorsement | LrpInsuranceEndorsementModel | null = null;

			if (endorsement.revenueHedgeProductId) {
				lrpModelData = new LrpInsuranceEndorsementModel(getOwner(this), endorsement, undefined);
			}
			const { ...endorsementObj } = endorsement;
			return {
				...endorsementObj,
				lrpModelData: lrpModelData,
			};
		});
		return this.checkIfAddedToStrategy(dataObj);
	}

	checkIfAddedToStrategy(data: LrpInsuranceEndorsement[]) {
		const endorsements: LrpInsuranceEndorsement[] = [];

		data?.map((endorsement) => {
			const { ...endorsementObj } = endorsement as LrpInsuranceEndorsement;
			const isAdded = endorsementObj?.hedgeStrategyId === this.hedgeStrategyId;
			const newEndorsementObj = {
				...endorsementObj,
				commodityName: endorsement.RmaCommodity ? endorsement.RmaCommodity.commodityName : endorsement.RmaType?.RmaCommodity.commodityName,
				isAdded,
			};
			endorsements.push(newEndorsementObj);
		});

		return endorsements;
	}

	get totalNumEndorsements() {
		return this.model.LrpInsuranceEndorsements.data?.LrpInsuranceEndorsementCount.count;
	}

	get searchFilterQueryParams() {
		const obj: {
			[param: string]: { [filterProperty: string]: string };
		} = {};
		const searchQueryParams: SearchFilterIdentifier[] = ['agent', 'aipId', 'customerId', 'stateId'];

		searchQueryParams.forEach((param) => {
			// if applicable setting filter for customerId for customer scope
			const value = param === 'customerId' && this.applicationScope.globalCustomerId ? this.applicationScope.globalCustomerId : this[param];

			if (value) {
				obj[param] = {
					filterRule: 'equals',
					filterValue: value,
				};

				// set filterComponent property to specify component for custom display extended from search-filter
				const filterDisplayObj = getFilterDisplayProperty(param);
				if (filterDisplayObj && filterDisplayObj.customComponent) {
					obj[param].filterComponent = filterDisplayObj.customComponent;
				}
				// set filterLabel property to specify custom label for filter - default would be filterIdentifier (matches queryParam)
				if (filterDisplayObj && filterDisplayObj.label) {
					obj[param].filterLabel = filterDisplayObj.label;
				}
			}
		});

		return obj;
	}

	get scopedSearchFilterQueryParams() {
		if (this.applicationScope.globalCustomerId) {
			const { customerId, ...rest } = this.searchFilterQueryParams;
			return rest;
		} else {
			return this.searchFilterQueryParams;
		}
	}

	get salesEffectiveDateQueryParam() {
		return {
			startDate: this.salesEffectiveStartDate,
			endDate: this.salesEffectiveEndDate,
		};
	}

	get coverageEndDateQueryParam() {
		return {
			startDate: this.coverageEndStartDate,
			endDate: this.coverageEndEndDate,
		};
	}

	get query() {
		return query;
	}

	get modelLastUpdatedAt() {
		try {
			return DateTime.fromISO(this.model.lastUpdatedAt).toLocaleString(DateTime.DATETIME_FULL);
		} catch {
			return '';
		}
	}

	get formattedQueryParams(): { orderBy: LrpInsuranceEndorsementSortByDTO; where: LrpInsuranceEndorsementFilterDTO } {
		const route = new LRPInsuranceEndorsementsIndexRoute();

		return {
			orderBy: route.generateOrderBy(this.sorts),
			where: route.generateWhere(
				this.applicationScope.locationId,
				this.applicationScope.globalCustomerId,
				this.applicationScope.organizationId,
				this.aipId,
				this.agent,
				this.stateId,
				this.salesEffectiveStartDate,
				this.salesEffectiveEndDate,
				this.coverageEndStartDate,
				this.coverageEndEndDate,
			),
		};
	}

	@action
	setSorts(sorts: SortObj[]) {
		this.sorts = sorts;
	}

	@action
	async fetchSearchResults(searchText: string) {
		let searchApiResults = [];
		const agentResults: searchResult[] = [];
		const stateResults: searchResult[] = [];

		// get fuzzy match agents
		const agentVariables = {
			where: {
				OR: [
					{ agentFirstName: { contains: searchText } },
					{ agentLastName: { contains: searchText } },
					{ agentFullName: { contains: searchText } },
				],
			},
		};
		const agentsArr = (
			(
				await this.apollo.watchQuery({
					query: agentFuzzyQuery,
					variables: agentVariables,
				})
			).InsurancePolicies as Agent[]
		).map((agent) => `${agent.agentFirstName} ${agent.agentLastName}`);

		const agentSet = new Set(agentsArr);

		for (const agent of agentSet) {
			const obj = {
				type: 'Agent',
				name: agent,
			};

			agentResults.push(obj);
		}

		// get fuzzy match state
		const stateVariables = {
			where: {
				OR: [{ abbreviation: { contains: searchText } }, { name: { contains: searchText } }, { id: { contains: searchText } }],
			},
		};

		const states = (
			await this.apollo.watchQuery({
				query: stateFuzzyQuery,
				variables: stateVariables,
			})
		).States as State[];

		states.forEach((state) => {
			const obj = {
				type: 'State',
				id: state.id,
				name: state.name,
			};
			stateResults.push(obj);
		});

		// get search API matches
		const variables = {
			query: searchText,
			typesToInclude: ['Aip'],
		};

		searchApiResults = (await this.apollo.watchQuery({ query: searchQuery, variables })).Search;

		// return combined set
		return [...agentResults, ...searchApiResults, ...stateResults];
	}

	@action
	structureSearchResults(searchResults: searchResult[]) {
		const map = new Map();

		searchResults.forEach((item) => {
			if (map.has(item.type)) {
				map.get(item.type).push({ id: item.id, name: item.name, type: item.type });
			} else {
				map.set(item.type, [{ id: item.id, name: item.name, type: item.type }]);
			}
		});

		return map;
	}

	@action
	setSearchFilterQueryParam(searchResult: searchResult) {
		const mappedSearchFilter = this.mapSearchResult(searchResult);
		if (mappedSearchFilter) {
			this[mappedSearchFilter.filterIdentifier] = mappedSearchFilter.filterValue;
		}
		this.setTablePageState();
	}

	mapSearchResult(searchResult: searchResult): { filterIdentifier: SearchFilterIdentifier; filterValue: string } | null {
		let filterIdentifier: SearchFilterIdentifier;

		switch (searchResult.type) {
			case 'Agent':
				filterIdentifier = 'agent';
				break;
			case 'Aip':
				filterIdentifier = 'aipId';
				break;
			case 'Customer':
				filterIdentifier = 'customerId';
				break;
			case 'State':
				filterIdentifier = 'stateId';
				break;
			default:
				return null;
		}

		if (searchResult.id || searchResult.name) {
			return {
				filterIdentifier,
				filterValue: (searchResult.id || searchResult.name) as string,
			};
		}

		return null;
	}

	@action
	clearSearchFilterQueryParam(filterIdentifier: string) {
		if (isSearchFilterIdentifier(filterIdentifier)) {
			this[filterIdentifier] = null;
			this.setTablePageState();
		}
	}

	@action
	setDateFilterQueryParam(queryParam: DateFilterQueryParamPrefix, value: { startDate: string; endDate: string }) {
		this[`${queryParam}StartDate`] = value.startDate;
		this[`${queryParam}EndDate`] = value.endDate;
		this.setTablePageState();
	}

	@action
	setTablePageState(newPageVal?: number) {
		this.page = newPageVal ?? 0;
		resetVaultTableScroll('lrp-endorsement-table');
	}

	@action
	async updateLrpEndorsements() {
		this.model.LrpInsuranceEndorsements.refetch();
	}
}
