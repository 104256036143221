import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! template-lint-disable no-inline-styles }}\n<div ...attributes>\n\t<div class='mb-4 px-6' id={{this.legendId}}></div>\n\t<div class='relative {{@containerClasses}}' id='{{this.chartId}}-container'>\n\t\t<BarChart\n\t\t\t@chartData={{this.chartData}}\n\t\t\t@options={{this.chartOptions}}\n\t\t\t@data={{@data}}\n\t\t\t@id={{this.chartId}}\n\t\t\t@plugins={{this.plugins}}\n\t\t\t@updateChart={{this.updateChart}}\n\t\t/>\n\t</div>\n</div>", {"contents":"{{! template-lint-disable no-inline-styles }}\n<div ...attributes>\n\t<div class='mb-4 px-6' id={{this.legendId}}></div>\n\t<div class='relative {{@containerClasses}}' id='{{this.chartId}}-container'>\n\t\t<BarChart\n\t\t\t@chartData={{this.chartData}}\n\t\t\t@options={{this.chartOptions}}\n\t\t\t@data={{@data}}\n\t\t\t@id={{this.chartId}}\n\t\t\t@plugins={{this.plugins}}\n\t\t\t@updateChart={{this.updateChart}}\n\t\t/>\n\t</div>\n</div>","moduleName":"vault-client/components/percent-hedged-chart.hbs","parseOptions":{"srcName":"vault-client/components/percent-hedged-chart.hbs"}});
import Component from '@glimmer/component';
import { guidFor } from '@ember/object/internals';
import { Chart, ChartData, ChartDataset, ChartOptions } from 'chart.js';
import { action } from '@ember/object';
import { DateTime } from 'luxon';
import getCSSVariable from 'vault-client/utils/get-css-variable';
import { CustomTooltipOptions, getCustomLegend, getCustomTooltip } from 'vault-client/utils/chart-utils';

type LiveCattleHedgeType = 'Futures Hedged' | 'Puts Hedged' | 'Insurance (LRP)' | 'Insurance (LGM)';
type CornHedgeType = 'Futures Hedged' | 'Calls Hedged' | 'Purchased';
type FeederForLiveCattleHedgeType = 'Futures Hedged' | 'Calls Hedged';
type FeederCattleHedgeType = 'Futures Hedged' | 'Puts Hedged' | 'Insurance (LRP)' | 'Insurance (LGM)';

type ChartType = 'live-cattle' | 'corn' | 'feeder-cattle' | 'feeder-for-live-cattle';

interface PercentHedgedChartSignature {
	Args: {
		data: {
			month: string;
			'Futures Hedged'?: number;
			'Puts Hedged'?: number;
			'Calls Hedged'?: number;
			'Insurance (LRP)'?: number;
			'Insurance (LGM)'?: number;
			Purchased?: number;
		}[];
		chartId?: string;
		legendId?: string;
		containerClasses?: string;
		type: ChartType;
	};
}

const LIVE_CATTLE_COLOR_MAP: Record<LiveCattleHedgeType, string> = {
	'Futures Hedged': getCSSVariable('--brand-interactive-blue-70'),
	'Puts Hedged': getCSSVariable('--brand-orange-40'),
	'Insurance (LRP)': getCSSVariable('--brand-lime-50'),
	'Insurance (LGM)': getCSSVariable('--brand-lemon-50'),
};

const CORN_COLOR_MAP: Record<CornHedgeType, string> = {
	'Futures Hedged': getCSSVariable('--brand-interactive-blue-70'),
	'Calls Hedged': getCSSVariable('--brand-orange-40'),
	Purchased: getCSSVariable('--brand-lime-50'),
};

const FEEDER__FOR_LIVE_CATTLE_COLOR_MAP: Record<FeederForLiveCattleHedgeType, string> = {
	'Futures Hedged': getCSSVariable('--brand-interactive-blue-70'),
	'Calls Hedged': getCSSVariable('--brand-orange-40'),
};

const FEEDER_CATTLE_COLOR_MAP: Record<FeederCattleHedgeType, string> = {
	'Futures Hedged': getCSSVariable('--brand-interactive-blue-70'),
	'Puts Hedged': getCSSVariable('--brand-orange-40'),
	'Insurance (LRP)': getCSSVariable('--brand-lime-50'),
	'Insurance (LGM)': getCSSVariable('--brand-lemon-50'),
};

export default class PercentHedgedChart extends Component<PercentHedgedChartSignature> {
	guid = guidFor(this);

	get chartId(): string {
		return this.args.chartId || `percent-hedged-chart-${this.guid}`;
	}

	get legendId(): string {
		return this.args.legendId || `percent-hedged-legend-${this.guid}`;
	}

	get colorMap(): Record<string, string> {
		switch (this.args.type) {
			case 'live-cattle':
				return LIVE_CATTLE_COLOR_MAP;
			case 'corn':
				return CORN_COLOR_MAP;
			case 'feeder-cattle':
				return FEEDER_CATTLE_COLOR_MAP;
			case 'feeder-for-live-cattle':
				return FEEDER__FOR_LIVE_CATTLE_COLOR_MAP;
			default:
				return {};
		}
	}

	get chartData(): ChartData<'bar'> {
		const datasets: ChartDataset<'bar'>[] = Object.keys(this.colorMap).map((type) => ({
			type: 'bar',
			label: type,
			data: this.args.data?.map((item) => (item[type as keyof typeof item] as number) ?? 0),
			backgroundColor: this.colorMap[type],
			borderColor: this.colorMap[type],
			yAxisID: 'y',
		}));

		return {
			labels: this.args.data.map((item) => DateTime.fromISO(item.month).toFormat('LLL yyyy')),
			datasets,
		};
	}

	get chartOptions(): ChartOptions<'bar'> {
		const tooltipOptions: CustomTooltipOptions = {
			valueFormatter: (val: unknown) => {
				if (typeof val !== 'number') return '';
				return new Intl.NumberFormat('en-US', {
					style: 'percent',
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				}).format(val);
			},
			titleFormatter: (title: unknown) => {
				if (typeof title !== 'string') return '';
				return title;
			},
		};

		return {
			responsive: true,
			maintainAspectRatio: false,
			interaction: {
				mode: 'index',
				intersect: false,
			},
			scales: {
				x: {
					stacked: true,
					grid: {
						display: false,
					},
				},
				y: {
					stacked: true,
					beginAtZero: true,
					ticks: {
						callback: (value) => {
							if (typeof value !== 'number') return '';
							return new Intl.NumberFormat('en-US', {
								style: 'percent',
								minimumFractionDigits: 0,
								maximumFractionDigits: 0,
							}).format(value);
						},
					},
					grid: {
						color: '#E5E7EB',
					},
				},
			},
			plugins: {
				legend: {
					display: false,
				},
				tooltip: {
					enabled: false,
					external: getCustomTooltip(tooltipOptions),
				},
			},
		};
	}

	get plugins() {
		return [
			{
				id: 'custom-legend',
				afterUpdate: getCustomLegend(this.chartId, this.legendId),
			},
		];
	}

	@action
	updateChart(chart: Chart) {
		chart.data = this.chartData;
		chart.update('none');
	}
}
