import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div ...attributes>\n\t<div>\n\t\t<span class='text-2xl font-sans-semibold'>\n\t\t\t<IntlNumberFormat @style='percent' @value={{this.totalPercentHedged}} />\n\t\t\tHedged\n\t\t</span>\n\t</div>\n\t<div class='relative shadow border rounded-md h-16 {{@containerClasses}}' id='{{this.chartId}}-container'>\n\t\t<BarChart\n\t\t\t@chartData={{this.chartData}}\n\t\t\t@options={{this.chartOptions}}\n\t\t\t@data={{@data}}\n\t\t\t@id={{this.chartId}}\n\t\t\t@plugins={{this.plugins}}\n\t\t\t@updateChart={{this.updateChart}}\n\t\t/>\n\t</div>\n\t<div class='mt-10' id={{this.legendId}}></div>\n</div>", {"contents":"<div ...attributes>\n\t<div>\n\t\t<span class='text-2xl font-sans-semibold'>\n\t\t\t<IntlNumberFormat @style='percent' @value={{this.totalPercentHedged}} />\n\t\t\tHedged\n\t\t</span>\n\t</div>\n\t<div class='relative shadow border rounded-md h-16 {{@containerClasses}}' id='{{this.chartId}}-container'>\n\t\t<BarChart\n\t\t\t@chartData={{this.chartData}}\n\t\t\t@options={{this.chartOptions}}\n\t\t\t@data={{@data}}\n\t\t\t@id={{this.chartId}}\n\t\t\t@plugins={{this.plugins}}\n\t\t\t@updateChart={{this.updateChart}}\n\t\t/>\n\t</div>\n\t<div class='mt-10' id={{this.legendId}}></div>\n</div>","moduleName":"vault-client/components/percent-hedged-bar-chart.hbs","parseOptions":{"srcName":"vault-client/components/percent-hedged-bar-chart.hbs"}});
import Component from '@glimmer/component';
import { guidFor } from '@ember/object/internals';
import { assert } from '@ember/debug';
import Big from 'big.js';
import type { Chart, ChartOptions, ChartData } from 'chart.js';
import {
	CustomInnerHTMLFunction,
	CustomTooltipOptions,
	getCustomLegend,
	getCustomTooltip,
	smartUpdateChartData,
} from 'vault-client/utils/chart-utils';
import getCSSVariable from 'vault-client/utils/get-css-variable';
import { action } from '@ember/object';

type CropPercentHedgedChartDataType = 'Flat' | 'HTA' | 'Basis' | 'Futures' | 'Options' | 'Unhedged';

export type CropPercentHedgedChartDataItem = {
	type: CropPercentHedgedChartDataType;
	amount: number;
	avgPrice?: number;
};

export interface CropPercentHedgedChartSignature {
	// The arguments accepted by the component
	Args: {
		chartId?: string;
		data: CropPercentHedgedChartDataItem[];
		totalUsage: number;
	};
	// Any blocks yielded by the component
	Blocks: {
		default: [];
	};
	// The element to which `...attributes` is applied in the component template
	Element: null;
}

const COLOR_MAP: Record<CropPercentHedgedChartDataType, string> = {
	'Flat': getCSSVariable('--brand-interactive-blue-70'),
	'HTA': getCSSVariable('--brand-orange-40'),
	'Basis': getCSSVariable('--brand-lemon-40'),
	'Futures': getCSSVariable('--brand-lime-40'),
	'Options': getCSSVariable('--brand-purple-50'),
	'Unhedged': getCSSVariable('--brand-white'),
};

type CropPercentHedgedDataValue = { percentage: number; amount?: number; avgPrice?: number };
type CropPercentHedgedChartData = ChartData<'bar', CropPercentHedgedDataValue[]>;
type CropPercentHedgedChartDataset = CropPercentHedgedChartData['datasets'][0];

// It is the responsibility of the invoker to ensure that the supplied data adds up to 1
// This component does not perform any rounding or validation of the data
export default class CropPercentHedgedChartComponent extends Component<CropPercentHedgedChartSignature> {
	constructor(owner: unknown, args: CropPercentHedgedChartSignature['Args']) {
		super(owner, args);

		assert('CropPercentHedgedChartComponent requires data', args.data);
	}

	guid = guidFor(this);

	get legendId(): string {
		return `crop-percent-hedged-legend-${this.guid}`;
	}

	get chartId(): string {
		return this.args.chartId || `crop-percent-hedged-chart-${this.guid}`;
	}

	get totalHedged(): number {
		return this.args.data.reduce((acc, item) => (item.type !== 'Unhedged' ? acc + item.amount : acc), 0);
	}

	get totalPercentHedged(): number {
		return this.args.totalUsage ? Big(this.totalHedged).div(this.args.totalUsage).toNumber() : 0;
	}

	get xMax() {
		return Math.max(1, this.totalPercentHedged);
	}

	get chartData(): ChartData<'bar', { percentage: number; amount?: number; avgPrice?: number }[]> {
		return {
			datasets: this.args.data.map((item) => {
				return {
					label: item.type,
					data: [
						{
							y: 'bar-1',
							percentage: this.args.totalUsage ? Big(item.amount).div(this.args.totalUsage).toNumber() : 0,
							avgPrice: item.avgPrice,
							amount: item.amount,
						},
					],
					backgroundColor: COLOR_MAP[item.type],
					borderColor: COLOR_MAP[item.type] === getCSSVariable('--brand-white') ? getCSSVariable('--brand-black') : COLOR_MAP[item.type],
				};
			}),
		};
	}

	get chartOptions(): ChartOptions<'bar'> {
		const customTooltipOptions: CustomTooltipOptions = {
			valueFormatter: (val) => {
				const typedValue = val as unknown as CropPercentHedgedDataValue;

				return Intl.NumberFormat('en-US', {
					style: 'percent',
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				}).format(typedValue.percentage ?? 0);
			},
			hideTitles: true,
			useBorderColorForBorder: true,
		};

		return {
			indexAxis: 'y',
			responsive: true,
			maintainAspectRatio: false,
			interaction: {
				intersect: false,
				mode: 'index',
			},
			parsing: {
				xAxisKey: 'percentage',
			},
			datasets: {
				bar: {
					borderSkipped: 'middle',
					borderRadius: 6,
					categoryPercentage: 1,
					barPercentage: 1,
				},
			},
			scales: {
				x: {
					display: false,
					stacked: true,
				},
				y: {
					display: false,
					stacked: true,
				},
			},
			plugins: {
				tooltip: {
					mode: 'index',
					external: getCustomTooltip(customTooltipOptions),
					enabled: false,
					displayColors: false,
				},
				legend: {
					display: false,
				},
			},
		};
	}

	get plugins() {
		const chartId = this.chartId;
		const legendId = this.legendId;
		const valueFormatter = (val: unknown) => {
			// This cast is not ideal, but proper generics got a bit complex
			const typedValue = val as unknown as CropPercentHedgedDataValue;
			const percentage = typedValue.percentage ?? 0;
			return Intl.NumberFormat('en-US', {
				style: 'percent',
				minimumFractionDigits: 0,
				maximumFractionDigits: 0,
			}).format(percentage ?? 0);
		};

		const legendCustomInnerHtmlFunction = this.legendCustomInnerHtmlFunction;

		return [
			{
				afterUpdate: getCustomLegend(chartId, legendId, valueFormatter, undefined, legendCustomInnerHtmlFunction),
			},
		];
	}

	@action
	updateChart(chart: Chart) {
		smartUpdateChartData(chart, this.chartData);

		const currentXMax = chart.options.scales!.x?.max;
		const newXMax = this.xMax;

		if (currentXMax !== newXMax) {
			chart.options.scales!.x!.max = newXMax;
		}

		chart.update('none');
	}

	legendCustomInnerHtmlFunction: CustomInnerHTMLFunction<CropPercentHedgedChartDataset> = (dataset, value, valueFormatter) => {
		const data = dataset.data[0];

		const amount = data?.amount;
		const avgPrice = data?.avgPrice;
		const formattedValue = valueFormatter ? valueFormatter(value) : value;

		let textDesc = 'Avg Price';
		if (dataset?.label === 'Unhedged') {
			textDesc = 'Mark to Market';
		} else if (dataset?.label === 'Options') {
			textDesc = 'Avg Strike Price';
		}

		const amountRow =
			amount != null
				? `<span class="ml-4 whitespace-nowrap text-brand-gray-90 text-xs">Bu: ${new Intl.NumberFormat(undefined, {
						minimumFractionDigits: 0,
						maximumFractionDigits: 0,
					}).format(amount)}</span>`
				: undefined;

		const avgPriceRow =
			avgPrice != null
				? `<span class="ml-4 whitespace-nowrap text-brand-gray-90 text-xs">${textDesc}: ${new Intl.NumberFormat(undefined, {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
						style: 'currency',
						currency: 'USD',
					}).format(avgPrice)}</span>`
				: undefined;

		return `
				<div class="flex flex-col font-sans" style="grid-template-columns: auto 1fr;">
					<span>
						<span
							style="background-color: ${dataset?.backgroundColor};
							border: ${dataset?.borderColor ?? dataset?.backgroundColor} solid 1px"
							class=" mr-0.5 my-auto w-2 h-2 rounded-full inline-block">
						</span>
						<span class="text-brand-gray-70 font-sans-semibold">${formattedValue}</span>
					</span>
					<span class="ml-4 whitespace-nowrap text-brand-gray-60">${dataset.label}</span>
					${amountRow ? amountRow : ''}
					${avgPriceRow ? avgPriceRow : ''}
				</div>
			`;
	};
}
