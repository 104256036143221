import Big from 'big.js';
import getCSSVariable from 'vault-client/utils/get-css-variable';
import { gt } from 'vault-client/utils/precision-math';
import { type Chart, type Plugin } from 'chart.js';
import { getCustomLegend, getCustomTooltip, type CustomTooltipOptions } from 'vault-client/utils/chart-utils';

// Expected to be a decimal between 0 and 1 or null
export type InputDatum = number;
export type Label = 'Brokerage' | 'DRP' | 'LGM' | 'LRP' | 'Physical';
export type InputData = Partial<Record<Label, InputDatum>>;

export type FixedLabel = 'Unhedged';
export type FixedDatum = number;
export type FixedData = Record<FixedLabel, FixedDatum>;
export type PercentHedgedData = InputData & FixedData;
export type PercentHedgedDataLabel = Label | FixedLabel;

export type PercentHedgedChart = Chart<'bar', number[], ''>;
export type PercentHedgedChartData = PercentHedgedChart['data'];
export type PercentHedgedChartDataset = PercentHedgedChart['data']['datasets'][number];
export type PercentHedgedChartOptions = PercentHedgedChart['options'];
export type PercentHedgedChartPlugin = Plugin<'bar'>;
export type PercentHedgedChartPlugins = PercentHedgedChartPlugin[];

const datasetOrder: PercentHedgedDataLabel[] = ['Brokerage', 'LRP', 'LGM', 'DRP', 'Physical', 'Unhedged'] as const;
const datasetOrderComparator = (a: PercentHedgedChartDataset, b: PercentHedgedChartDataset) =>
	datasetOrder.indexOf(a.label as PercentHedgedDataLabel) - datasetOrder.indexOf(b.label as PercentHedgedDataLabel);

const labelBackgroundColorMap: Record<PercentHedgedDataLabel, string> = {
	Brokerage: getCSSVariable('--brand-lime-40'),
	Unhedged: getCSSVariable('--brand-white'),
	DRP: getCSSVariable('--brand-teal-60'),
	LGM: getCSSVariable('--brand-purple-50'),
	LRP: getCSSVariable('--brand-teal-60'),
	Physical: getCSSVariable('--brand-teal-60'),
};

export function getTotalPercentHedgedFontColorClass(totalPercentHedged: number): string {
	if (gt(totalPercentHedged, 1.1)) {
		return 'text-brand-error-50';
	}

	return 'text-brand-black';
}

export function getLabelColors(label: PercentHedgedDataLabel): { backgroundColor: string; borderColor: string } {
	const backgroundColor = getLabelBackgroundColor(label);
	const borderColor = getBackgroundColorBorderColor(backgroundColor);

	return {
		backgroundColor,
		borderColor,
	};
}

export function getPercentHedgedData({
	data,
	hasExposure,
	totalPercentHedged,
}: {
	data: InputData;
	hasExposure: boolean;
	totalPercentHedged: number;
}): PercentHedgedData {
	return {
		...data,
		Unhedged: getUnhedged({ totalPercentHedged, hasExposure }),
	};
}

export function getChartData(data: PercentHedgedData): PercentHedgedChartData {
	return {
		labels: [''],
		datasets: Object.entries(data)
			.map(([label, value]: [PercentHedgedDataLabel, number]) => {
				const backgroundColor = getLabelBackgroundColor(label);
				const borderColor = getBackgroundColorBorderColor(backgroundColor);
				return {
					label,
					data: [value ?? 0],
					backgroundColor,
					borderColor,
				};
			})
			.sort(datasetOrderComparator),
	};
}

export function getChartOptions(totalPercentHedged: number): PercentHedgedChartOptions {
	return {
		indexAxis: 'y',
		responsive: true,
		maintainAspectRatio: false,
		interaction: {
			intersect: false,
			mode: 'index',
		},
		datasets: {
			bar: {
				borderSkipped: 'middle',
				borderRadius: 6,
				categoryPercentage: 1,
				barPercentage: 1,
			},
		},
		scales: {
			x: {
				display: false,
				stacked: true,
				max: getXScaleMax(totalPercentHedged),
			},
			y: {
				display: false,
				stacked: true,
			},
		},
		plugins: {
			tooltip: {
				mode: 'index',
				external: getCustomTooltip(getCustomTooltipOptions()),
				enabled: false,
				displayColors: false,
			},
			legend: {
				display: false,
			},
		},
	};
}

export function getChartPlugins({ chartId, legendId }: { chartId: string; legendId: string }): PercentHedgedChartPlugins {
	return [getLegendPlugin({ chartId, legendId })];
}

function getLabelBackgroundColor(label: PercentHedgedDataLabel): string {
	return labelBackgroundColorMap[label];
}

function getBackgroundColorBorderColor(backgroundColor: string): string {
	if (backgroundColor === getCSSVariable('--brand-white')) {
		return getCSSVariable('--brand-black');
	}

	return backgroundColor;
}

function getUnhedged({ totalPercentHedged, hasExposure }: { totalPercentHedged: number; hasExposure: boolean }): number {
	const rawUnhedged = Big(1).minus(totalPercentHedged);
	// If there is no exposure or the unhedged amount is negative, set the unhedged amount to 0
	const unhedged = hasExposure && rawUnhedged.gt(0) ? rawUnhedged : Big(0);
	return unhedged.toNumber();
}

function getCustomTooltipOptions(): CustomTooltipOptions {
	return {
		valueFormatter: (val: number) => {
			if (typeof val === 'string') return val;
			return Intl.NumberFormat('en-US', {
				style: 'percent',
				minimumFractionDigits: 0,
				maximumFractionDigits: 0,
			}).format(val || 0);
		},
		useBorderColorForBorder: true,
	};
}

function getXScaleMax(totalPercentHedged: number): number {
	return totalPercentHedged > 1 ? totalPercentHedged : 1;
}

function getLegendPlugin({ chartId, legendId }: { chartId: string; legendId: string }): PercentHedgedChartPlugin {
	const valueFormatter = (val: number | null) => {
		if (val === null) return '';
		return Intl.NumberFormat('en-US', {
			style: 'percent',
			minimumFractionDigits: 0,
			maximumFractionDigits: 0,
		}).format(val);
	};
	return {
		id: 'custom-legend',
		afterUpdate: getCustomLegend(chartId, legendId, valueFormatter),
	};
}
