import { gql } from 'glimmer-apollo';

export const GET_CATTLE_PROJECTED_PL = gql`
	query GetCattleProjectedPl(
		$productSlugs: [String!]!
		$customerId: String!
		$startDate: String!
		$endDate: String!
		$cattleLotsWhere: CattleLotFilterDTO!
		$feedRationUsageWhere: CattleLotFeedRationUsagePerMonthFilterDTO
		$insuranceEndorsementWhere: InsuranceEndorsementAllocationRatioFilterDTO
	) {
		AggregateCattleLots(
			calc: { sum: { forecastedSalesRevenueInUsd: true, cattleLotTotalExpensesInUsd: true, purchasePriceInUsd: true } }
			groupBy: { activeEndMonthStartDate: true }
			where: $cattleLotsWhere
			orderBy: { activeEndMonthStartDate: Asc }
		) {
			sum {
				forecastedSalesRevenueInUsd
				cattleLotTotalExpensesInUsd
				purchasePriceInUsd
			}
			activeEndMonthStartDate
		}

		AggregateExpenseLedgerEntries: AggregateLedgerEntries(
			calc: { sum: { calculatedAmount: true } }
			groupBy: { month: true, year: true }
			where: {
				entityId: { equals: $customerId }
				LedgerCategory: { type: { equals: Expense } }
				AND: [{ date: { gte: $startDate } }, { date: { lte: $endDate } }]
			}
		) {
			sum {
				calculatedAmount
			}
			month
			year
		}

		AggregateRevenueLedgerEntries: AggregateLedgerEntries(
			calc: { sum: { calculatedAmount: true } }
			groupBy: { month: true, year: true }
			where: {
				entityId: { equals: $customerId }
				LedgerCategory: { type: { equals: Revenue } }
				AND: [{ date: { gte: $startDate } }, { date: { lte: $endDate } }]
			}
		) {
			sum {
				calculatedAmount
			}
			month
			year
		}

		AllocatedLrpInsuranceEndorsements: InsuranceEndorsementAllocationRatios(
			scopeId: $customerId
			orderBy: { effectiveHedgeDate: Asc }
			where: {
				effectiveHedgeDate: { gte: $startDate, lte: $endDate }
				InsuranceEndorsement: { AsLrpInsuranceEndorsement: { commodityCode: { in: ["0801", "0802"] } } }
			}
			limit: 2000
		) {
			id
			effectiveHedgeDate
			RatioAdjustedInsuranceEndorsement {
				id
				... on LrpInsuranceEndorsement {
					pnl
				}
			}
		}

		AllocatedLgmInsuranceEndorsements: InsuranceEndorsementAllocationRatios(
			scopeId: $customerId
			orderBy: { effectiveHedgeDate: Asc }
			where: {
				effectiveHedgeDate: { gte: $startDate, lte: $endDate }
				InsuranceEndorsement: {
					AsLgmInsuranceEndorsement: { Product: { slug: { in: ["livestock-live-cattle", "livestock-feeder-cattle"] } } }
				}
			}
			limit: 2000
		) {
			id
			effectiveHedgeDate
			RatioAdjustedInsuranceEndorsement {
				id
				... on LgmInsuranceEndorsement {
					pnl
				}
			}
		}

		AggregateCattleLotFeedRationUsagePerMonths(
			calc: { sum: { totalExpenseInUsd: true } }
			groupBy: { CattleLot: { activeEndMonthStartDate: true } }
			where: $feedRationUsageWhere
			orderBy: { CattleLot: { activeEndMonthStartDate: Asc } }
		) {
			sum {
				totalExpenseInUsd
			}
			CattleLot {
				activeEndMonthStartDate
			}
		}

		CattleLots(
			scopeId: $customerId
			where: { businessId: { equals: $customerId }, activeEndDate: { gte: $startDate, lte: $endDate } }
			orderBy: { activeEndDate: Asc }
		) {
			id
			activeEndDate
			numberOfCattle
			targetWeightInLb
		}

		AggregateCurrentAllocationPositions(
			scopeId: $customerId
			calc: { sum: { grossPnl: true, contractQuantity: true } }
			groupBy: { effectiveHedgeDate: true, instrumentId: true, instrumentType: true, optionType: true, Product: { slug: true } }
			orderBy: { effectiveHedgeDate: Asc }
			where: { Product: { slug: { in: $productSlugs } } }
		) {
			effectiveHedgeDate
			instrumentType
			optionType
			contractQuantity
			Product {
				slug
			}
			sum {
				grossPnl
				contractQuantity
			}
		}

		Customer(id: $customerId) {
			id
			businessRoles
		}

		InsuranceEndorsementAllocationRatios(where: $insuranceEndorsementWhere) {
			id
			insuranceEndorsementId
		}
	}
`;
