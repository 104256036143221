import { SortObj } from 'vault-client/types/vault-table';

function getByCompositeKey(obj: Record<PropertyKey, unknown>, key: string) {
	if (!key) {
		return null;
	}

	return key.split('.').reduce((prev, itm) => (prev ? prev[itm] : null), obj) || null;
}

function setObjProp(object: Record<string | number | symbol, unknown>, path: string, value: unknown) {
	const [prop, ...rest] = path.split('.');

	if (!rest.length) {
		object[prop] = value;
		return;
	}

	if (!object[prop]) object[prop] = {};

	if (typeof object[prop] !== 'object' || Array.isArray(object[prop])) {
		throw new Error(`Path ${prop} is not an object`);
	}

	setObjProp(object[prop] as Record<string | number | symbol, unknown>, rest.join('.'), value);
}

function getFocusableElementsInContainer(container: HTMLElement | null) {
	return container?.querySelectorAll<HTMLElement>('a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])');
}

function objectCompareStringify(a: Record<string, unknown>, b: Record<string, unknown>) {
	// This is not a great way to compare objects, but it works in controlled instances
	// Limitations are:
	// 1. It doesn't work with nested objects
	// 2. It doesn't work with arrays
	// 3. It doesn't work with functions
	return JSON.stringify(Object.entries(a).sort()) === JSON.stringify(Object.entries(b).sort());
}

function generateFullName(firstName: string | null | undefined, lastName: string | null | undefined) {
	if (firstName && lastName) {
		return `${firstName} ${lastName}`;
	} else if (firstName) {
		return firstName;
	} else if (lastName) {
		return lastName;
	}
	return null;
}

// Replace underscores and dashes with spaces
function dedasherize(string: string | null | undefined) {
	const regex = /_+|-+/g;
	const replacement = ' ';
	if (string === undefined || string === null) {
		return '';
	}

	const result = string.toLowerCase().replace(regex, replacement);
	return result;
}

// Capitalize first letter of each word
function titleize(string: string | null | undefined) {
	if (string === undefined || string === null) {
		return '';
	}

	const result = string
		.split(' ')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');

	return result;
}

function camelCaseToTitleCase(string: string) {
	const result = string
		.replace(/([A-Z])/g, ' $1')
		.replace(/^./, (char) => char.toUpperCase())
		.trim();

	return result;
}

/**
 * @description Formats a number to an en-US string with commas
 * @param value number
 * @returns string
 */
function formatNumber(value: number, options?: Intl.NumberFormatOptions) {
	return new Intl.NumberFormat('en-US', options).format(value);
}

// Used to format non-table currency values such as in widgets to include parentheses for negative values ex. -34.45 -> ($34.45)
function currencyFormatter(value: number) {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
		currencyDisplay: 'symbol',
	});

	let formattedCurrency = formatter.format(value);

	if (value < 0) {
		formattedCurrency = `(${formattedCurrency.substring(1)})`;
	}

	return formattedCurrency;
}

function generateOrderBy<T extends Record<string, any>>(sorts: SortObj[]): T {
	const orderBy: Record<string, any> = {};

	sorts.forEach((sort) => {
		let context = orderBy;
		const paths = sort.valuePath.split('.');
		paths.forEach((path, i) => {
			if (i === paths.length - 1) {
				context[path] = sort.isAscending ? 'Asc' : 'Desc';
			} else {
				context[path] = { ...(context[path] ?? {}) };
				context = context[path];
			}
		});
	});

	return orderBy as T;
}

function generateSortBy<T extends Record<string, any>>(sorts: SortObj[]): T[] {
	return sorts.map((sort) => {
		const orderBy: Record<string, any> = {};
		let context = orderBy;
		const paths = sort.valuePath.split('.');

		paths.forEach((path, i) => {
			if (i === paths.length - 1) {
				context[path] = sort.isAscending ? 'Asc' : 'Desc';
			} else {
				context[path] = { ...(context[path] ?? {}) };
				context = context[path];
			}
		});

		return orderBy as T;
	});
}

/**
 * @description Parses a formatted number string and returned a number. This does not handle locale specific formatting, only commas and periods.
 *
 * @param value string
 * @returns number
 */
function parseFormattedNumber(formattedNumber: string) {
	return parseFloat(formattedNumber.replace(/,/g, ''));
}

/**
 * @description Returns an array with only unique values. Relies on native Set, so object contents are not compared
 *
 */
function uniqueArray<T>(arr: T[]): T[] {
	return Array.from(new Set(arr));
}

export {
	getByCompositeKey,
	getFocusableElementsInContainer,
	objectCompareStringify,
	generateFullName,
	dedasherize,
	titleize,
	camelCaseToTitleCase,
	currencyFormatter,
	setObjProp,
	generateOrderBy,
	parseFormattedNumber,
	uniqueArray,
	generateSortBy,
	formatNumber,
};
