import { getOwner } from '@ember/application';
import { action } from '@ember/object';
import { debounce } from '@ember/runloop';
import Service from '@ember/service';
import markerSDK, { MarkerSdk } from '@marker.io/browser';
import { MediaService } from 'vault-client/types/vault-client';

export default class MarkerWidgetService extends Service {
	widget: MarkerSdk | null = null;
	handler: ResizeObserver | null = null;

	get media() {
		return getOwner(this).lookup('service:media') as MediaService;
	}

	async registerWidget() {
		try {
			this.widget = await markerSDK.loadWidget({
				project: '642b072841b9c072435b0110',
			});

			this.widget.on('feedbackdiscarded', () => {
				this.determineVisibility();
			});
			this.widget.on('feedbacksent', () => {
				this.determineVisibility();
			});
			this.widget.on('feedbackerror', () => {
				this.determineVisibility();
			});

			this.determineVisibility();
			this.attachResizeObserver();
		} catch (error) {
			console.error('Error registering marker widget', error);
		}
	}

	willDestroy(): void {
		this.detachResizeObserver();
		this.unloadWidget();
	}

	show() {
		// If the widget is not visible, show it
		if (this.widget && !this.widget?.isVisible()) {
			this.widget?.show();
		}
	}

	hide() {
		// If the widget is visible, hide it
		if (this.widget?.isVisible()) {
			this.widget?.hide();
		}
	}

	capture() {
		this.widget?.capture('fullscreen');
	}

	isVisible() {
		return this.widget?.isVisible() ?? false;
	}

	unloadWidget() {
		this.widget?.unload();
		this.widget = null;
	}

	attachResizeObserver() {
		if (this.handler) {
			return;
		}

		this.handler = new ResizeObserver(() => debounce(this.determineVisibility, 200));
		this.handler.observe(document.body);
	}

	detachResizeObserver() {
		this.handler?.disconnect();
		this.handler = null;
	}

	@action
	determineVisibility() {
		if (this.media.isMobile) {
			this.hide();
		} else {
			this.show();
		}
	}
}

// Don't remove this declaration: this is what enables TypeScript to resolve
// this service using `Owner.lookup('service:marker-widget')`, as well
// as to check when you pass the service name as an argument to the decorator,
// like `@service('marker-widget') declare altName: MarkerWidgetService;`.
declare module '@ember/service' {
	interface Registry {
		'marker-widget': MarkerWidgetService;
	}
}
