import Route from '@ember/routing/route';
import { RouteQueryParam } from '@ember/routing/types';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import { DateTime } from 'luxon';
import {
	CurrentAllocationPositionAggregateDTO,
	CurrentAllocationPositionFilterDTO,
	CurrentAllocationPositionGroupByDTO,
	CustomerEntity,
	Query,
	SwineLivestockPopulationChangeFilterDTO,
	TypeOfLivestockPopulationChangeReason,
	type ForecastedSwineLivestockFeedUsageFilterDTO,
} from 'vault-client/types/graphql-types';
import type { ModelFrom } from 'vault-client/utils/type-utils';
import type BusinessesBusinessRoute from '../business';
import ENV from 'vault-client/config/environment';
import { getDobDateRange } from 'vault-client/utils/swine/date';

interface ModelParams {
	startDate: string;
	endDate: string;
}

export const GET_PIGS_DASHBOARD = gql`
	query PigsDashboard(
		$customerId: String!
		$startDate: String!
		$endDate: String!
		$aggregatePositionsCalc: CurrentAllocationPositionAggregateDTO!
		$aggregatePositionsGroupBy: CurrentAllocationPositionGroupByDTO!
		$aggregatePositionsWhere: CurrentAllocationPositionFilterDTO!
		$swineSalesPurchasesAndProducedWhere: SwineLivestockPopulationChangeFilterDTO!
		$forecastedSwineLivestockFeedUsageWhere: ForecastedSwineLivestockFeedUsageFilterDTO
	) {
		Customer(id: $customerId) {
			id
			averageFinishAgeInWeeks
			averageFinishWeightInLbs
			SwineLivestockPopulationForecastsByWeek(where: { date: { gte: $startDate, lte: $endDate } }) {
				id
				date
				endQuantity
			}
		}
		EntityAllocatedExposureRatios(
			where: {
				startDate: $startDate
				endDate: $endDate
				Product: { slug: { in: ["grain-corn", "grain-soybean-meal", "livestock-lean-hogs"] } }
				entityId: { equals: $customerId }
			}
			orderBy: { date: Asc }
		) {
			Product {
				id
				slug
			}
			date
			totalPercentVolumeHedged
		}
		AggregateCurrentAllocationPositions(
			calc: $aggregatePositionsCalc
			groupBy: $aggregatePositionsGroupBy
			where: $aggregatePositionsWhere
			orderBy: { effectiveHedgeDate: Asc }
		) {
			effectiveHedgeDate
			instrumentType
			optionType
			Product {
				slug
			}
			sum {
				grossPnl
				contractQuantity
			}
		}
		Products(where: { slug: { in: ["livestock-lean-hogs", "grain-corn", "grain-soybean-meal"] } }) {
			id
			slug
			CurrentFutures {
				id
				displayExpiresAt
				barchartSymbol
				productId
				name
				SymbolGroup {
					id
					displayFactor
					fractionDigits
				}
			}
		}
		AllocatedLrpInsuranceEndorsements: InsuranceEndorsementAllocationRatios(
			scopeId: $customerId
			orderBy: { effectiveHedgeDate: Asc }
			where: {
				effectiveHedgeDate: { gte: $startDate, lte: $endDate }
				InsuranceEndorsement: { AsLrpInsuranceEndorsement: { commodityCode: { equals: "0815" } } }
			} # Swine Code
			limit: 2000
		) {
			id
			effectiveHedgeDate
			RatioAdjustedInsuranceEndorsement {
				id
				... on LrpInsuranceEndorsement {
					pnl
				}
			}
		}
		AllocatedLgmInsuranceEndorsements: InsuranceEndorsementAllocationRatios(
			scopeId: $customerId
			orderBy: { effectiveHedgeDate: Asc }
			where: {
				effectiveHedgeDate: { gte: $startDate, lte: $endDate }
				InsuranceEndorsement: { AsLgmInsuranceEndorsement: { Product: { slug: { equals: "livestock-lean-hogs" } } } }
			}
			limit: 2000
		) {
			id
			effectiveHedgeDate
			RatioAdjustedInsuranceEndorsement {
				id
				... on LgmInsuranceEndorsement {
					pnl
				}
			}
		}
		SwineSalesPurchasesAndProduced: AggregateSwineLivestockPopulationChanges(
			calc: { sum: { quantity: true, totalValue: true } }
			groupBy: { date: true, reasonType: true, valueType: true, dob: true }
			where: $swineSalesPurchasesAndProducedWhere
		) {
			date
			dob
			reasonType
			valueType
			sum {
				quantity
				totalValue
			}
		}
		AggregateExpenseLedgerEntries: AggregateLedgerEntries(
			calc: { sum: { calculatedAmount: true } }
			groupBy: { month: true, year: true }
			where: {
				entityId: { equals: $customerId }
				LedgerCategory: { type: { equals: Expense } }
				AND: [{ date: { gte: $startDate } }, { date: { lte: $endDate } }]
			}
		) {
			sum {
				calculatedAmount
			}
			month
			year
		}
		AggregateRevenueLedgerEntries: AggregateLedgerEntries(
			calc: { sum: { calculatedAmount: true } }
			groupBy: { month: true, year: true }
			where: {
				entityId: { equals: $customerId }
				LedgerCategory: { type: { equals: Revenue } }
				AND: [{ date: { gte: $startDate } }, { date: { lte: $endDate } }]
			}
		) {
			sum {
				calculatedAmount
			}
			month
			year
		}
		AggregateForecastedSwineLivestockFeedUsages(
			scopeId: $customerId
			calc: { sum: { salesAdjustedTotalExpenseInUsd: true } }
			groupBy: { dob: true }
			where: $forecastedSwineLivestockFeedUsageWhere
		) {
			dob
			sum {
				salesAdjustedTotalExpenseInUsd
			}
		}
	}
`;

export type GetPigsDashboardQuery = {
	Customer: CustomerEntity;
	EntityAllocatedExposureRatios: Query['EntityAllocatedExposureRatios'];
	AggregateCurrentAllocationPositions: Query['AggregateCurrentAllocationPositions'];
	Products: Query['Products'];
	AllocatedLrpInsuranceEndorsements: Query['InsuranceEndorsementAllocationRatios'];
	AllocatedLgmInsuranceEndorsements: Query['InsuranceEndorsementAllocationRatios'];
	SwineSalesPurchasesAndProduced: Query['AggregateSwineLivestockPopulationChanges'];
	AggregateExpenseLedgerEntries: Query['AggregateLedgerEntries'];
	AggregateRevenueLedgerEntries: Query['AggregateLedgerEntries'];
	AggregateForecastedSwineLivestockFeedUsages: Query['AggregateForecastedSwineLivestockFeedUsages'];
};

export type GetPigsDashboardQueryArgs = {
	customerId?: string;
	aggregatePositionsCalc?: CurrentAllocationPositionAggregateDTO;
	aggregatePositionsGroupBy?: CurrentAllocationPositionGroupByDTO;
	aggregatePositionsWhere?: CurrentAllocationPositionFilterDTO;
	swineSalesPurchasesAndProducedWhere?: SwineLivestockPopulationChangeFilterDTO;
	forecastedSwineLivestockFeedUsageWhere?: ForecastedSwineLivestockFeedUsageFilterDTO;
	startDate?: string;
	endDate?: string;
};

export default class BusinessesBusinessPigDashboardRoute extends Route {
	@tracked variables: GetPigsDashboardQueryArgs = {};

	queryParams: { [key: string]: RouteQueryParam } = {
		startDate: {
			refreshModel: true,
		},
		endDate: {
			refreshModel: true,
		},
	};

	async model(params: ModelParams) {
		const { getCustomer, businessId } = this.modelFor('businesses.business') as ModelFrom<BusinessesBusinessRoute>;
		const averageFinishAgeInWeeks =
			getCustomer.data?.Customer?.averageFinishAgeInWeeks ?? (ENV.APP.DEFAULT_AVG_SWINE_FINISH_AGE_IN_WEEKS as number);
		const startDate = params.startDate || DateTime.now().startOf('month').toISODate();
		const endDate = params.endDate || DateTime.local().plus({ months: 12 }).endOf('month').toISODate();
		const { startDate: dobStartDate, endDate: dobEndDate } = getDobDateRange(startDate, endDate, averageFinishAgeInWeeks);

		const getPigsDashboard = useQuery<GetPigsDashboardQuery, GetPigsDashboardQueryArgs>(this, () => [
			GET_PIGS_DASHBOARD,
			{
				variables: this.variables,
				fetchPolicy: 'no-cache', // Rely on network for dashboard view as there are many mutations that will affect this data (weaned pigs, contracts, feed usage, etc)  and allocated insurance uses duplicated ids
			},
		]);

		this.variables = {
			customerId: businessId,
			aggregatePositionsCalc: {
				sum: {
					grossPnl: true,
					contractQuantity: true,
				},
			},
			aggregatePositionsGroupBy: {
				effectiveHedgeDate: true,
				instrumentId: true,
				instrumentType: true,
				optionType: true,
				Product: {
					slug: true,
				},
			},
			aggregatePositionsWhere: {
				entityId: {
					equals: businessId,
				},
				Product: {
					slug: {
						in: ['livestock-lean-hogs', 'grain-corn', 'grain-soybean-meal'],
					},
				},
				effectiveHedgeDate: {
					gte: startDate,
					lte: endDate,
				},
			},
			swineSalesPurchasesAndProducedWhere: generateSwineSalesPurchasesAndProducedWhere({
				customerId: businessId,
				startDate,
				endDate,
				dobStartDate,
				dobEndDate,
			}),
			forecastedSwineLivestockFeedUsageWhere: generateForecastedSwineLivestockFeedUsageWhere(dobStartDate, dobEndDate),
			startDate: startDate,
			endDate: endDate,
		};

		await getPigsDashboard.promise;

		return {
			businessId: businessId,
			lastUpdatedAt: DateTime.now().toISO(),
			getPigsDashboard: getPigsDashboard,
		};
	}
}

export function generateSwineSalesPurchasesAndProducedWhere({
	customerId,
	startDate,
	endDate,
	dobStartDate,
	dobEndDate,
}: {
	customerId: string;
	startDate: string;
	endDate: string;
	dobStartDate: string;
	dobEndDate: string;
}): SwineLivestockPopulationChangeFilterDTO {
	return {
		businessId: { equals: customerId },
		OR: [
			{
				reasonType: {
					equals: TypeOfLivestockPopulationChangeReason.Sale,
				},
				date: {
					gte: startDate,
					lte: endDate,
				},
			},
			{
				reasonType: {
					in: [TypeOfLivestockPopulationChangeReason.Purchase, TypeOfLivestockPopulationChangeReason.Birth],
				},
				dob: {
					gte: dobStartDate,
					lte: dobEndDate,
				},
			},
		],
	};
}

export function generateForecastedSwineLivestockFeedUsageWhere(
	dobStartDate: string,
	dobEndDate: string,
): ForecastedSwineLivestockFeedUsageFilterDTO {
	return {
		dob: { gte: dobStartDate, lte: dobEndDate },
	};
}
