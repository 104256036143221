import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span {{did-insert this.fetchStates}}>\n\t<PowerSelect\n\t\t@selected={{@selected}}\n\t\t@options={{this.states}}\n\t\t@searchEnabled={{true}}\n\t\t@allowClear={{true}}\n\t\t@searchField='name'\n\t\t@placeholder={{this.placeholderText}}\n\t\t@onChange={{@onChange}}\n\t\t@renderInPlace={{@renderInPlace}}\n\t\tas |state|\n\t>\n\t\t{{state.name}}\n\t</PowerSelect>\n</span>", {"contents":"<span {{did-insert this.fetchStates}}>\n\t<PowerSelect\n\t\t@selected={{@selected}}\n\t\t@options={{this.states}}\n\t\t@searchEnabled={{true}}\n\t\t@allowClear={{true}}\n\t\t@searchField='name'\n\t\t@placeholder={{this.placeholderText}}\n\t\t@onChange={{@onChange}}\n\t\t@renderInPlace={{@renderInPlace}}\n\t\tas |state|\n\t>\n\t\t{{state.name}}\n\t</PowerSelect>\n</span>","moduleName":"vault-client/components/state-select.hbs","parseOptions":{"srcName":"vault-client/components/state-select.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { queryManager } from 'ember-apollo-client';
import query from 'vault-client/graphql/queries/state-select.graphql';
interface StateSelectArgs {
	placeholderText?: string;
}

export default class StateSelect extends Component<StateSelectArgs> {
	@queryManager apollo: any;

	@tracked states = [];
	@tracked placeholderText = this.args.placeholderText || 'All States';

	@action
	async fetchStates() {
		const states = (await this.apollo.watchQuery({ query })).States;
		this.states = states.sortBy('name');
	}
}
