import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { SortObj } from 'vault-client/types/vault-table';
import { hasError as validationStateHasError } from 'vault-client/utils/forms/validation-state/has-error';
import { ModelFrom } from 'vault-client/utils/type-utils';
import BusinessesBusinessCattleLotsIndexRoute from 'vault-client/routes/businesses/business/cattle/lots/index';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import { service } from '@ember/service';
import FormStateService from 'vault-client/services/form-state';
import { getLotsTableColumns, LotsTableRow } from 'vault-client/utils/components/cattle/lots/lots-table';
import { SearchResult } from 'vault-client/types/vault-client';
import getFilterDisplayProperty from 'vault-client/utils/get-filter-display-property';
import { useLotsSearchQuery } from 'vault-client/utils/cattle/lots/lots-overview';
import { CattleLot as ICattleLot } from 'vault-client/types/graphql-types';
import CattleLot from 'vault-client/models/cattle/cattle-lot';
import { DisplayUnits } from 'vault-client/types/cattle/display-units';

type SidePanelState = 'CREATE_LOT' | 'EDIT_LOT' | null;
type SearchQueryParam =
	| 'cattleYard'
	| 'name'
	| 'cattleFeedRation'
	| 'activeStartDateStart'
	| 'activeStartDateEnd'
	| 'activeEndDateStart'
	| 'activeEndDateEnd';

export default class BusinessesBusinessCattleLotsController extends Controller {
	lotsTableRoute = 'businesses.business.cattle.lots.lots-table';
	lotsTableId = 'lots-table';
	createLotFormId = 'create-cattle-lot-form';
	editLotFormId = 'edit-cattle-lot-form';
	queryParams = [
		'page',
		'size',
		'sorts',
		'cattleYard',
		'name',
		'cattleFeedRation',
		'activeStartDateStart',
		'activeStartDateEnd',
		'activeEndDateStart',
		'activeEndDateEnd',
	];
	declare model: ModelFrom<BusinessesBusinessCattleLotsIndexRoute>;

	@tracked page = 0;
	@tracked size = 100;
	@tracked sorts: SortObj[] = [];
	@tracked lotToEdit: LotsTableRow | null = null;
	@tracked lotToDelete: LotsTableRow | null = null;
	@tracked sidePanelState: SidePanelState = null;
	@tracked cattleYard: string | null = null;
	@tracked name: string | null = null;
	@tracked cattleFeedRation: string | null = null;
	@tracked activeStartDateStart: string | null = '1900-01-01';
	@tracked activeStartDateEnd: string | null = '2999-12-31';
	@tracked activeEndDateStart: string | null = '1900-01-01';
	@tracked activeEndDateEnd: string | null = '2999-12-31';
	@tracked selectedDisplayUnit = DisplayUnits.Total;

	@service('form-state') declare formStateService: FormStateService;

	get businessId() {
		return this.model.businessId;
	}

	get lots() {
		const lots = this.model.lotsOverview.data?.CattleLots ?? [];
		const displayUnit = this.selectedDisplayUnit;
		return this.itemsFn(lots as ICattleLot[], displayUnit);
	}

	get footerRows() {
		const lots = this.model.lotsOverview.data?.CattleLots ?? [];
		const displayUnit = this.selectedDisplayUnit;
		return [this.footerItemsFn(lots as ICattleLot[], displayUnit)];
	}

	get totalLotsCount() {
		return this.model.lotsOverview.data?.CattleLotCount?.count ?? 0;
	}

	get isSidePanelOpen() {
		return this.sidePanelState !== null;
	}

	get formId() {
		switch (this.sidePanelState) {
			case 'CREATE_LOT':
				return this.createLotFormId;
			case 'EDIT_LOT':
				return this.editLotFormId;
			default:
				return null;
		}
	}

	get formState() {
		if (!this.formId) return undefined;
		return this.formStateService.getState(this.formId);
	}

	get submitButtonText(): string {
		switch (this.sidePanelState) {
			case 'CREATE_LOT':
				return this.formState?.submissionState?.isPending ? 'Creating...' : 'Create Lot';
			case 'EDIT_LOT':
				return this.formState?.submissionState?.isPending ? 'Saving...' : 'Save Changes';
			default:
				return 'Submit';
		}
	}

	get panelTitle() {
		switch (this.sidePanelState) {
			case 'CREATE_LOT':
				return 'Create Lot';
			case 'EDIT_LOT':
				return 'Edit Lot';
			default:
				return '';
		}
	}

	get isSubmitDisabled(): boolean {
		// Disable submit by default until we have a validation state
		if (!this.formState?.validationState) return true;
		return validationStateHasError(this.formState.validationState) || !!this.formState.submissionState?.isPending;
	}

	get lotsTableColumns() {
		return getLotsTableColumns({
			editLot: this.editLot,
			deleteLot: this.deleteLot,
		});
	}

	get searchFilterQueryParams() {
		const obj: {
			[key: string]: any;
		} = {};
		const searchQueryParams = ['cattleYard', 'name', 'cattleFeedRation'] as const;
		const labels = {
			cattleYard: 'Yard',
			name: 'Lot',
			cattleFeedRation: 'Ration',
		};

		searchQueryParams.forEach((param) => {
			const value = this[param];

			if (value) {
				obj[param] = {
					filterRule: 'equals',
					filterValue: value,
					filterLabel: labels[param],
				};

				const filterDisplayObj = getFilterDisplayProperty(param);
				if (filterDisplayObj && filterDisplayObj.customComponent) {
					obj[param].filterComponent = filterDisplayObj.customComponent;
				}
			}
		});

		return obj;
	}

	get activeStartDateQueryParam() {
		return {
			startDate: this.activeStartDateStart ?? '1900-01-01',
			endDate: this.activeStartDateEnd ?? '2999-12-31',
		};
	}

	get activeEndDateQueryParam() {
		return {
			startDate: this.activeEndDateStart ?? '1900-01-01',
			endDate: this.activeEndDateEnd ?? '2999-12-31',
		};
	}

	@action
	submitForm() {
		this.formState?.submit?.();
	}

	@action
	setSorts(sorts: SortObj[]) {
		this.sorts = sorts;
	}

	@action
	setTablePageState(newPageVal?: number) {
		this.page = newPageVal ?? 0;
		resetVaultTableScroll(this.lotsTableId);
	}

	@action
	closeSidePanel() {
		this.setSidePanelState({ state: null });
	}

	@action
	createLot() {
		this.setSidePanelState({ state: 'CREATE_LOT' });
	}

	@action
	editLot(lot: LotsTableRow) {
		this.setSidePanelState({ state: 'EDIT_LOT', lotToEdit: lot });
	}

	@action
	deleteLot(lot: LotsTableRow) {
		this.lotToDelete = lot;
	}

	@action
	closeDeleteLotModal() {
		this.lotToDelete = null;
	}

	@action
	setSidePanelState(nextState: { state: 'CREATE_LOT' } | { state: 'EDIT_LOT'; lotToEdit: LotsTableRow } | { state: null }) {
		if (nextState.state === 'EDIT_LOT') {
			this.lotToEdit = nextState.lotToEdit;
		} else {
			this.lotToEdit = null;
		}
		this.sidePanelState = nextState.state;
	}

	@action
	clearSearchFilterQueryParam(filterIdentifier: SearchQueryParam) {
		this[filterIdentifier] = null;
		this.setTablePageState(0);
	}

	@action
	structureSearchResults(searchResults: SearchResult[]) {
		const map = new Map();

		searchResults.forEach((item) => {
			if (map.has(item.type)) {
				map.get(item.type).push({ id: item.id, name: item.name, type: item.type });
			} else {
				map.set(item.type, [{ id: item.id, name: item.name, type: item.type }]);
			}
		});

		return map;
	}

	@action
	async fetchSearchResults(searchText: string): Promise<SearchResult[]> {
		const searchResults: SearchResult[] = [];
		const nameResults: SearchResult[] = [];
		const cattleYardResults: SearchResult[] = [];
		const cattleFeedRationResults: SearchResult[] = [];

		const promises: Promise<void>[] = [];

		const nameQuery = useLotsSearchQuery(this, {
			variables: {
				where: {
					name: { contains: searchText },
				},
				scopeId: this.businessId,
			},
			onComplete: (data): void => {
				nameResults.push(
					...(data?.CattleLots.map((lot) => ({
						type: 'Lot',
						name: lot?.name ?? '',
						id: lot?.id ?? '',
					})) ?? []),
				);
			},
		});

		promises.push(nameQuery.promise);

		const cattleYardQuery = useLotsSearchQuery(this, {
			variables: {
				where: {
					CattleYard: {
						name: { contains: searchText },
					},
				},
				scopeId: this.businessId,
			},
			onComplete: (data): void => {
				cattleYardResults.push(
					...(data?.CattleLots.map((lot) => ({
						type: 'Yard',
						name: lot?.CattleYard?.name ?? '',
						id: lot?.CattleYard?.id ?? '',
						detail: lot?.name ?? '',
					})) ?? []),
				);
			},
		});

		promises.push(cattleYardQuery.promise);

		const cattleFeedRationQuery = useLotsSearchQuery(this, {
			variables: {
				where: {
					CattleFeedRation: {
						name: { contains: searchText },
					},
				},
				scopeId: this.businessId,
			},
			onComplete: (data): void => {
				cattleFeedRationResults.push(
					...(data?.CattleLots.map((lot) => ({
						type: 'Ration',
						name: lot?.CattleFeedRation?.name ?? '',
						id: lot?.CattleFeedRation?.id ?? '',
						detail: lot?.name ?? '',
					})) ?? []),
				);
			},
		});

		promises.push(cattleFeedRationQuery.promise);

		await Promise.all(promises);

		const uniqueNameResults = [...new Map(nameResults.map((item) => [item.name, item])).values()];
		const uniqueCattleYardResults = [...new Map(cattleYardResults.map((item) => [item.name, item])).values()];
		const uniqueCattleFeedRationResults = [...new Map(cattleFeedRationResults.map((item) => [item.name, item])).values()];

		return [...searchResults, ...uniqueNameResults, ...uniqueCattleYardResults, ...uniqueCattleFeedRationResults];
	}

	@action
	setSearchFilterQueryParam(searchResult: SearchResult) {
		const mappedSearchFilter = this.mapSearchResult(searchResult);
		if (!mappedSearchFilter.filterIdentifier) return;

		this[mappedSearchFilter.filterIdentifier] = mappedSearchFilter.filterValue;
	}

	mapSearchResult(searchResult: SearchResult) {
		let filterIdentifier: SearchQueryParam | null = null;

		switch (searchResult.type) {
			case 'Yard':
				filterIdentifier = 'cattleYard';
				break;
			case 'Lot':
				filterIdentifier = 'name';
				break;
			case 'Ration':
				filterIdentifier = 'cattleFeedRation';
				break;
		}

		return {
			filterIdentifier,
			filterValue: searchResult.name ?? '',
		};
	}

	@action
	setActiveStartDate(dateObj: { startDate: string; endDate: string }) {
		const { startDate, endDate } = dateObj;
		this.activeStartDateStart = startDate;
		this.activeStartDateEnd = endDate;
		this.setTablePageState(0);
	}

	@action
	setActiveEndDate(dateObj: { startDate: string; endDate: string }) {
		const { startDate, endDate } = dateObj;
		this.activeEndDateStart = startDate;
		this.activeEndDateEnd = endDate;
		this.setTablePageState(0);
	}

	footerItemsFn = (rows: ICattleLot[], displayUnit: DisplayUnits) => {
		let totalRevenue = 0;
		let totalExpense = 0;

		rows.forEach((row: ICattleLot) => {
			const item = new CattleLot(row, displayUnit);
			totalRevenue += item.revenue ?? 0;
			totalExpense += item.expenses ?? 0;
		});

		return {
			revenue: totalRevenue,
			expenses: totalExpense,
			netPnl: totalExpense + totalRevenue,
		}
	};

	itemsFn = (rows: ICattleLot[], displayUnit: DisplayUnits) => {
		return rows.map((row) => {
			return new CattleLot(row, displayUnit);
		});
	};
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'businesses/business/cattle/lots': BusinessesBusinessCattleLotsController;
	}
}
