import { DateTime } from 'luxon';

/** Returns true if the two arrays contain the same elements, in the same order. Returns false otherwise */
export default function arraysEqual<K, T extends Array<K>>(a: T, b: T) {
	if (a === b) return true;
	if (a == null || b == null) return false;
	if (a.length !== b.length) return false;

	for (let i = 0; i < a.length; ++i) {
		if (!_equals(a[i], b[i])) return false;
	}
	return true;
}

function _equals<T>(x: T, y: T) {
	if (x instanceof DateTime && y instanceof DateTime) {
		return x.equals(y);
	} else {
		return x === y;
	}
}
