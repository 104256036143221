function isFiniteNumber(value: any): boolean {
	return typeof value === 'number' && isFinite(value);
}

function isStringExcludeEmpty(value: any): boolean {
	return typeof value === 'string' && value.trim() !== '';
}

function convertToNegative(value: number): number {
	return value * -1;
}

function convertToPositive(value: number): number {
	return Math.abs(value);
}

export { isFiniteNumber, isStringExcludeEmpty, convertToNegative, convertToPositive };
