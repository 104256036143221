import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<PowerSelect\n\t@selectedItemComponent={{component 'add-edit-crop-harvest-form/month-select/selected-item'}}\n\t@disabled={{@disabled}}\n\t@selected={{@month}}\n\t@extra={{hash formatter=this.formatMonth}}\n\t@options={{@months}}\n\t@renderInPlace={{false}}\n\t@onChange={{@onChange}}\n\t@ariaLabel='Harvest Month'\n\t@dropdownClass='harvest-month-select-dropdown'\n\t@placeholder='Select a Harvest Month'\n\t@labelText='Harvest Month'\n\t@labelClass='font-sans-semibold'\n\t...attributes\n\tas |month|\n>\n\t{{this.formatMonth month}}\n</PowerSelect>", {"contents":"<PowerSelect\n\t@selectedItemComponent={{component 'add-edit-crop-harvest-form/month-select/selected-item'}}\n\t@disabled={{@disabled}}\n\t@selected={{@month}}\n\t@extra={{hash formatter=this.formatMonth}}\n\t@options={{@months}}\n\t@renderInPlace={{false}}\n\t@onChange={{@onChange}}\n\t@ariaLabel='Harvest Month'\n\t@dropdownClass='harvest-month-select-dropdown'\n\t@placeholder='Select a Harvest Month'\n\t@labelText='Harvest Month'\n\t@labelClass='font-sans-semibold'\n\t...attributes\n\tas |month|\n>\n\t{{this.formatMonth month}}\n</PowerSelect>","moduleName":"vault-client/components/add-edit-crop-harvest-form/month-select.hbs","parseOptions":{"srcName":"vault-client/components/add-edit-crop-harvest-form/month-select.hbs"}});
import Component from '@glimmer/component';
import { DateTime } from 'luxon';

export interface AddEditCropHarvestFormMonthSelectSignature {
	// The arguments accepted by the component
	Args: {
		disabled?: boolean;
		month: string;
		months: string[];
		onChange: (month: string) => void;
	};
	// Any blocks yielded by the component
	Blocks: {
		default: [];
	};
	// The element to which `...attributes` is applied in the component template
	Element: null;
}

export default class AddEditCropHarvestFormMonthSelectComponent extends Component<AddEditCropHarvestFormMonthSelectSignature> {
	formatMonth = (monthISOFormat: string) => DateTime.fromISO(monthISOFormat).toFormat('LLL yyyy');
}
