import type { CattleFeedRationStage } from 'vault-client/types/graphql-types';
import { WARNINGS } from 'vault-client/utils/cattle/warnings';
import { DEFAULT_END_WEIGHT_IN_LB } from '../stages';
import { DEFAULT_START_WEIGHT_IN_LB } from '../stages';

const {
	STAGES: { OVERLAPPING_WEIGHT_RANGES, GAPS_BETWEEN_WEIGHT_RANGES },
} = WARNINGS;

type StagesWeightRangeWarning = typeof OVERLAPPING_WEIGHT_RANGES | typeof GAPS_BETWEEN_WEIGHT_RANGES;

export function getStagesWeightRangeWarnings(
	stages: Pick<CattleFeedRationStage, 'id' | 'name' | 'startWeightInLb' | 'endWeightInLb'>[],
): StagesWeightRangeWarning[] {
	const warnings: StagesWeightRangeWarning[] = [];
	const sortedStages = [...stages]
		.map((stage) => ({
			...stage,
			startWeightInLb: stage.startWeightInLb ?? DEFAULT_START_WEIGHT_IN_LB,
			endWeightInLb: stage.endWeightInLb ?? DEFAULT_END_WEIGHT_IN_LB,
		}))
		.sort((a, b) => a.startWeightInLb - b.startWeightInLb);

	for (let i = 0; i < sortedStages.length - 1; i++) {
		const currentStage = sortedStages[i];
		const nextStage = sortedStages[i + 1];

		if (currentStage.endWeightInLb > nextStage.startWeightInLb) {
			warnings.push(OVERLAPPING_WEIGHT_RANGES);
		} else if (currentStage.endWeightInLb < nextStage.startWeightInLb) {
			warnings.push(GAPS_BETWEEN_WEIGHT_RANGES);
		}
	}

	return [...new Set(warnings)];
}
