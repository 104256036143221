import { MutationOptions, useMutation } from 'glimmer-apollo';
import { UPDATE_CATTLE_LOT } from 'vault-client/graphql/mutations/cattle/lots';
import { Mutation, Mutation_updateCattleLotArgs } from 'vault-client/types/graphql-types';

const RELATED_FIELD_NAMES = [
	'CattleLots',
	'CattleLot',
	'CattleLotCount',
	'AggregateCattleLots',
] as const;

const defaultOptions: MutationOptions<Mutation['updateCattleLot'], Mutation_updateCattleLotArgs> = {
	update: (cache) => {
		RELATED_FIELD_NAMES.forEach((fieldName) => {
			cache.evict({ fieldName });
		});
		cache.gc();
	},
};

export function useUpdateCattleLotMutation(
	context: object,
	options?: MutationOptions<Mutation['updateCattleLot'], Mutation_updateCattleLotArgs>,
) {
	return useMutation<Mutation['updateCattleLot'], Mutation_updateCattleLotArgs>(context, () => [
		UPDATE_CATTLE_LOT,
		{
			...defaultOptions,
			...options,
		},
	]);
}
