import { DateTime } from 'luxon';
import { TypeOfCattleLotExpense } from 'vault-client/types/graphql-types';
import { divide } from 'vault-client/utils/precision-math';

const TypeOfCattleLotPurchasePriceOptions = ['per Head', 'per CWT', 'Total $'] as const;
type TypeOfCattleLotPurchasePriceOption = (typeof TypeOfCattleLotPurchasePriceOptions)[number];

const TypeOfCattleLotExpenseOptions = ['per Head', 'per CWT', 'Total $'] as const;
type TypeOfCattleLotExpenseOption = (typeof TypeOfCattleLotExpenseOptions)[number];

type CattleLotInputExpensesInput = {
	cattleLotInputExpensesInUsd: number;
	cattleLotExpensesType: TypeOfCattleLotExpenseOption;
};

type CattleLotInputExpensesOutput = {
	cattleLotInputExpensesInUsd: number;
	cattleLotExpensesType: TypeOfCattleLotExpense;
};

const calculateTargetDate = (
	startDate: string | undefined,
	startWeight: number | undefined,
	targetWeight: number | undefined,
	avgDailyGain: number | undefined,
): string | undefined => {
	if (!startDate || !startWeight || !targetWeight || !avgDailyGain) {
		return undefined;
	}

	const weightToGain = targetWeight - startWeight;
	const daysNeeded = Math.ceil(weightToGain / avgDailyGain);

	return DateTime.fromISO(startDate).plus({ days: daysNeeded }).toFormat('MM/dd/yyyy');
};

function convertMortalityRateToPercentage(value: number): number {
	if (value < 0) {
		return 0;
	}
	return value / 100;
}

function convertPercentageToMortalityRate(value: number): number {
	if (value < 0) {
		return 0;
	}
	return value * 100;
}

const calculatePurchasePricePerHeadInUsd = ({
	purchasePrice,
	purchasePriceType,
	numberOfCattle,
	startWeightInLb,
}: {
	purchasePrice: number;
	numberOfCattle: number;
	purchasePriceType: TypeOfCattleLotPurchasePriceOption;
	startWeightInLb: number;
}) => {
	switch (purchasePriceType) {
		case 'per Head':
			return purchasePrice;
		case 'per CWT':
			return convertPerCwtToPerHead({ purchasePrice, startWeightInLb });
		case 'Total $':
			return convertTotalUsdToPerHead({ purchasePrice, numberOfCattle });
	}
};

const convertPerCwtToPerHead = ({ purchasePrice, startWeightInLb }: { purchasePrice: number; startWeightInLb: number }) => {
	const cwtPerHead = divide(startWeightInLb, 100);
	return purchasePrice * cwtPerHead;
};

const convertTotalUsdToPerHead = ({ purchasePrice, numberOfCattle }: { purchasePrice: number; numberOfCattle: number }) => {
	return divide(purchasePrice, numberOfCattle);
};

const convertPerCwtToPerLb = (value: number) => {
	return divide(value, 100);
};

const convertPerLbToPerCwt = (value: number) => {
	return value * 100;
};

const convertCattleLotInputExpensesInputToOutput = ({
	cattleLotInputExpensesInUsd,
	cattleLotExpensesType,
}: CattleLotInputExpensesInput): CattleLotInputExpensesOutput => {
	switch (cattleLotExpensesType) {
		case 'per Head':
			return { cattleLotInputExpensesInUsd, cattleLotExpensesType: TypeOfCattleLotExpense.PerHead };
		case 'per CWT':
			return {
				cattleLotInputExpensesInUsd: convertPerCwtToPerLb(cattleLotInputExpensesInUsd),
				cattleLotExpensesType: TypeOfCattleLotExpense.PerLb,
			};
		case 'Total $':
			return { cattleLotInputExpensesInUsd, cattleLotExpensesType: TypeOfCattleLotExpense.Flat };
	}
};

const convertCattleLotInputExpensesOutputToInput = ({
	cattleLotInputExpensesInUsd,
	cattleLotExpensesType,
}: CattleLotInputExpensesOutput): CattleLotInputExpensesInput => {
	switch (cattleLotExpensesType) {
		case TypeOfCattleLotExpense.PerHead:
			return { cattleLotInputExpensesInUsd, cattleLotExpensesType: 'per Head' };
		case TypeOfCattleLotExpense.PerLb:
			return { cattleLotInputExpensesInUsd: convertPerLbToPerCwt(cattleLotInputExpensesInUsd), cattleLotExpensesType: 'per CWT' };
		case TypeOfCattleLotExpense.Flat:
			return { cattleLotInputExpensesInUsd, cattleLotExpensesType: 'Total $' };
	}
};

export {
	type TypeOfCattleLotPurchasePriceOption,
	type TypeOfCattleLotExpenseOption,
	TypeOfCattleLotPurchasePriceOptions,
	TypeOfCattleLotExpenseOptions,
	calculateTargetDate,
	convertMortalityRateToPercentage,
	convertPercentageToMortalityRate,
	convertCattleLotInputExpensesInputToOutput,
	convertCattleLotInputExpensesOutputToInput,
	calculatePurchasePricePerHeadInUsd,
};
