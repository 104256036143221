import { set } from '@ember/object';
import { TableColumn } from 'vault-client/types/vault-table';

export type Row = {
	[key: string | number | symbol]: unknown;
	id?: string;
	children?: Row[];
};

// Return available columns, ordered by saved settings.
function getCurrentColumns(savedColumns: TableColumn[], availableColumns: TableColumn[]): TableColumn[] {
	let remainingAvailableColumns = [...availableColumns];
	const currentColumns: TableColumn[] = [];

	savedColumns.forEach((savedColumn: TableColumn) => {
		// Check to see if previously saved column is still available.
		const availableColumn = remainingAvailableColumns.findBy('id', savedColumn.id);

		// If the column is still available
		if (availableColumn) {
			// Process the sub columns of the saved column.
			if (availableColumn.subcolumns && savedColumn.subcolumns) {
				// Required to use set here, for Ember tracking.
				set(availableColumn, 'subcolumns', getCurrentColumns(savedColumn.subcolumns, availableColumn.subcolumns));
			}
			// if available on saved column, use saved column isFixed and isVisible property values
			if ('isFixed' in savedColumn) {
				set(availableColumn, 'isFixed', savedColumn.isFixed);
			}
			if ('isVisible' in savedColumn) {
				set(availableColumn, 'isVisible', savedColumn.isVisible);
			}

			// Add the column to the columns to show.
			currentColumns.push(availableColumn);
			// Remove the column from the list of available columns.

			remainingAvailableColumns = remainingAvailableColumns.filter((column) => column.id != availableColumn.id);
		}
	});

	// if there are saved columns in currentColumns,
	// then set isVisible to false on any new column definitions to hide and not include in user customized columns
	if (currentColumns.length > 0) {
		remainingAvailableColumns.forEach((col) => {
			set(col, 'isVisible', false);
		});
	}

	// Add available columns, that weren't in the savedColumn array
	// This makes sure new columns become available.
	currentColumns.pushObjects(remainingAvailableColumns);

	return currentColumns;
}

const hasId = (row: Row): row is Required<Pick<Row, 'id'>> & Row => !!row.id;
const isParentRow = (row: Row): row is Required<Pick<Row, 'children'>> & Row => !!row.children;

export { getCurrentColumns, hasId, isParentRow };
