import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { TrackedObject } from 'tracked-built-ins';
import { CreateFieldData, createField, parseFieldData } from 'vault-client/utils/grain-utils';
import { getInvalidElements, isFormValid } from 'vault-client/utils/form-validation';
import { task } from 'ember-concurrency';
import { ModelFrom } from 'vault-client/utils/type-utils';
import { CellComponents, SortObj } from 'vault-client/types/vault-table';
import { Field } from 'vault-client/types/graphql-types';
import BusinessesBusinessFieldsIndexRoute, { FieldsQuery } from 'vault-client/routes/businesses/business/fields';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import { DateTime } from 'luxon';
import { UiDateFilterOption } from 'vault-client/components/vault/ui-date-filter';

export default class BusinessesBusinessFieldsIndexController extends Controller {
	declare model: ModelFrom<BusinessesBusinessFieldsIndexRoute>;
	@tracked startDate: string = DateTime.now().startOf('year').toISODate();
	@tracked endDate: string = DateTime.now().endOf('year').toISODate();

	@tracked isSidePanelOpen = false;
	@tracked createFieldFormData: CreateFieldData = new TrackedObject({
		name: '',
		acres: '',
		businessId: '',
		error: '',
	}) as CreateFieldData;
	@tracked sorts = [{ valuePath: 'name', isAscending: true }];
	@tracked fieldToRemove: FieldsQuery | null = null;
	@tracked page = 0;
	@tracked size = 100;

	queryParams = ['name', 'acres', 'sorts', 'page', 'size'];

	get isSubmitting(): boolean {
		return this.submitCreateFieldForm.isRunning;
	}

	get disableSubmitButton(): boolean {
		return this.isSubmitting;
	}

	get fields() {
		return this.model.fields.data?.Fields;
	}

	get currentTimePeriodOption(): UiDateFilterOption {
		return {
			startDate: this.startDate,
			endDate: this.endDate,
		};
	}

	harvestYearOptions = [
		{
			displayName: 'Harvest Year '.concat(DateTime.now().startOf('year').minus({ year: 2 }).get('year').toString()),
			startDate: DateTime.now().startOf('year').minus({ year: 2 }).toISODate(),
			endDate: DateTime.now().endOf('year').minus({ year: 2 }).toISODate(),
		},
		{
			displayName: 'Harvest Year '.concat(DateTime.now().startOf('year').minus({ year: 1 }).get('year').toString()),
			startDate: DateTime.now().startOf('year').minus({ year: 1 }).toISODate(),
			endDate: DateTime.now().endOf('year').minus({ year: 1 }).toISODate(),
		},
		{
			displayName: 'Current Harvest Year',
			startDate: DateTime.now().startOf('year').toISODate(),
			endDate: DateTime.now().endOf('year').toISODate(),
		},
		{
			displayName: 'Harvest Year '.concat(DateTime.now().startOf('year').plus({ year: 1 }).get('year').toString()),
			startDate: DateTime.now().startOf('year').plus({ year: 1 }).toISODate(),
			endDate: DateTime.now().endOf('year').plus({ year: 1 }).toISODate(),
		},
		{
			displayName: 'Harvest Year '.concat(DateTime.now().startOf('year').plus({ year: 2 }).get('year').toString()),
			startDate: DateTime.now().startOf('year').plus({ year: 2 }).toISODate(),
			endDate: DateTime.now().endOf('year').plus({ year: 2 }).toISODate(),
		},
		{
			displayName: 'Harvest Year '.concat(DateTime.now().startOf('year').plus({ year: 3 }).get('year').toString()),
			startDate: DateTime.now().startOf('year').plus({ year: 3 }).toISODate(),
			endDate: DateTime.now().endOf('year').plus({ year: 3 }).toISODate(),
		},
	];

	get fieldsColumns() {
		const baseColumns = [
			{
				id: 'ee2fc067-d483-487a-8a2f-06efb39b68e9',
				name: 'Name',
				valuePath: 'name',
				width: 140,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				linkRoute: 'businesses.business.field',
				linkModelPath: 'id',
			},
			{
				id: '72f00a52-e907-4e9d-97c7-ba277430b7f8',
				name: 'Acres',
				valuePath: 'acres',
				cellComponent: CellComponents.String,
				width: 80,
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
			},
			{
				id: '540d3111-7268-49c5-a33a-c9006d4f27bb',
				name: 'Crop',
				valuePath: 'crop',
				width: 100,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'b8b3b08b-8331-490f-b919-7cc4c2b61069',
				name: 'Production/Ac',
				valuePath: 'productionPerAcre',
				cellComponent: CellComponents.String,
				componentArgs: {
					minimumFractionDigits: '2',
					maximumFractionDigits: '2',
				},
				width: 80,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '0a5a20a1-e71d-40ef-a118-348ba4633758',
				name: 'Total Production',
				valuePath: 'productionTotal',
				cellComponent: CellComponents.String,
				componentArgs: {
					minimumFractionDigits: '2',
					maximumFractionDigits: '2',
				},
				width: 140,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '08c30a47-44af-4e5b-82c9-05f1d5c864b3',
				name: 'Field-Level Revenue',
				valuePath: 'revenueTotal',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: '2',
					maximumFractionDigits: '2',
				},
				width: 140,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'dbfb396e-3338-480f-bebe-1f9f625d3fec',
				name: 'Field-Level Expenses',
				valuePath: 'expensesTotal',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: '2',
					maximumFractionDigits: '2',
				},
				width: 140,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '364a1e22-887a-4424-a248-20d708ffd40b',
				name: 'Field-Level Net P/L',
				valuePath: 'netPL',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: '2',
					maximumFractionDigits: '2',
				},
				width: 140,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'c1d1e6d4-3d9e-4f7e-8e3d-0d5f7b5c4f5e',
				name: '',
				minWidth: 80,
				maxWidth: 80,
				width: 80,
				cellComponent: CellComponents.Button,
				componentArgs: {
					style: 'plain',
					iconOnlyButton: true,
					fn: this.removeField,
					centerIconHref: '/icons/Delete-Outline.svg#delete-outline',
					centerIconClass: 'icon-interactive-stroke',
				},
				isSortable: false,
				isVisible: true,
			},
		];

		return baseColumns;
	}

	get fieldsRows() {
		const data = this.model.fields.data?.Fields;
		if (!data) return [];
		return this.itemFn(data);
	}

	get columnTotals() {
		const data = this.model.fields.data?.Fields;
		if (!data) return [];
		let totalAcres = 0;

		let revenueTotal = 0,
			expensesTotal = 0;
		data.forEach((field) => {
			totalAcres += field.acres;
			field.FieldLedgerEntriesPerHarvestYear.forEach((entry) => {
				if (entry.cropFieldLedgerType === 'Revenue') {
					revenueTotal += entry.perAcreValueInUsd * field.acres;
					revenueTotal += entry.flatValueInUsd;
				} else if (entry.cropFieldLedgerType === 'Expense') {
					expensesTotal += entry.perAcreValueInUsd * field.acres;
					expensesTotal += entry.flatValueInUsd;
				}
			});
		});

		return [
			{
				acres: totalAcres.toFixed(2),
				revenueTotal,
				expensesTotal: -expensesTotal,
				netPL: revenueTotal - expensesTotal,
			},
		];
	}

	get totalNumFields() {
		return this.model.fields.data?.FieldCount?.count ?? 0;
	}

	@action
	openSidePanel() {
		this.isSidePanelOpen = true;
	}

	@action
	closeSidePanel() {
		this.isSidePanelOpen = false;
		this.createFieldFormData = {
			name: '',
			acres: '',
			businessId: '',
			error: '',
		};
	}

	get csvItems() {
		return this.itemFn(this.model.fields.data?.Fields ?? []);
	}

	itemFn = (fields: Field[]) => {
		return fields.map((field) => {
			let revenueTotal = 0,
				expensesTotal = 0;

			const acres = field.acres;
			const cropName: string[] = [];
			field.FieldLedgerEntriesPerHarvestYear.forEach((entry) => {
				if (entry.cropFieldLedgerType === 'Revenue') {
					revenueTotal += entry.perAcreValueInUsd * acres;
					revenueTotal += entry.flatValueInUsd;
				} else if (entry.cropFieldLedgerType === 'Expense') {
					expensesTotal += entry.perAcreValueInUsd * acres;
					expensesTotal += entry.flatValueInUsd;
				}
			});

			//We will need to revisit this and add additional logic once we have Crop Harvests, Contracts, Revenues/Expenses in place
			field.CropHarvests.forEach((harvest) => {
				/* harvest.PhysicalCropTransactions.forEach((transaction) => {
					if(transaction.flatPrice) {
						revenueTotal = transaction.bushels * (transaction.flatPrice ?? 0);
					} else if (transaction.basisPrice) {
						const futuresPrice = transaction.futuresPrice ?? 0;
						const basisPrice = transaction.basisPrice ?? 0;
						revenueTotal = transaction.bushels * (futuresPrice + basisPrice);
					} else if (transaction.futuresPrice) {
						const futuresPrice = transaction.futuresPrice ?? 0;
						const basisPrice = transaction.basisPrice ?? 0;
						revenueTotal = transaction.bushels * (futuresPrice * basisPrice);
					}
				}); */
				cropName.push(harvest.Crop.name);
			});

			const nameSet = new Set(cropName);

			const productionTotal =
				nameSet?.size > 1 ? 'Multiple Crops' : field.CropHarvests.reduce((acc, harvest) => acc + harvest.totalHarvestYield, 0);
			const netPL = revenueTotal - expensesTotal;
			const crop = nameSet?.size > 1 ? 'Multiple Crops' : cropName[0] ?? '';
			const productionPerAcre =
				nameSet?.size > 1
					? 'Multiple Crops'
					: field.CropHarvests.reduce((acc, harvest) => acc + harvest.totalHarvestYield, 0) / field.acres;

			return {
				id: field.id,
				name: field.name,
				acres: field.acres,
				revenueTotal,
				expensesTotal: -expensesTotal,
				netPL,
				productionTotal,
				crop,
				productionPerAcre,
			};
		});
	};

	submitCreateFieldForm = task({ drop: true }, async () => {
		if (!isFormValid(document)) {
			getInvalidElements(document);
			return;
		}
		let validatedData;
		try {
			validatedData = parseFieldData(this.createFieldFormData);
			validatedData.businessId = this.model.businessId;
		} catch (error) {
			set(this.createFieldFormData, 'error', error.message);
			return;
		}
		if (!validatedData.businessId) {
			set(this.createFieldFormData, 'error', 'Missing business id');
			throw new Error('Missing business id');
		} else {
			// Call mutation to create feed ingredient, use 'call' to pass in 'this' context
			await createField(this, { data: validatedData });
			this.closeSidePanel();
		}
	});

	@action
	updateCreateFieldFormData(key: keyof CreateFieldData, value: CreateFieldData[keyof CreateFieldData]) {
		set(this.createFieldFormData, key, value);
		return;
	}

	@action noopFunction() {}

	@action
	removeField(row: any) {
		this.fieldToRemove = row;
	}

	@action
	closeConfirmation() {
		this.fieldToRemove = null;
	}

	@action
	setSorts(sorts: SortObj[]) {
		this.sorts = sorts;
	}

	@action
	setTablePageState(newPageVal = 0) {
		this.page = newPageVal;
		resetVaultTableScroll('fields-table');
	}

	@action
	setTimePeriod(option: UiDateFilterOption) {
		this.startDate = option.startDate;
		this.endDate = option.endDate;
	}
}
