import { setOwner } from '@ember/application';
import type Owner from '@ember/owner';
import { service } from '@ember/service';
import { DateTime } from 'luxon';
import MarketDataService from 'vault-client/services/market-data';
import {
	CurrentAllocationPosition,
	InstrumentSymbolGroup,
	Option,
	ProductLotSpecification,
	Swaption,
	TypeOfInstrument,
	TypeOfOption,
} from 'vault-client/types/graphql-types';

class HedgeMonth {
	LBS_PER_TON = 2000;
	tonsPerPriceUnit = 1;
	slug: string;
	date: string;
	symbolGroup: InstrumentSymbolGroup | null;
	standardProductLotSpecification: ProductLotSpecification | null;
	futureBarchartSymbol: string;
	transactions: CurrentAllocationPosition[] = [];
	businessRole: string = '';

	// These values are in tons/lbs
	purchasedDMITons: number = 0;
	usageDMITons: number = 0;

	// These values are in lbs
	productionLbs: number = 0;

	// These values are in price units
	futuresHedgedInUnits: number = 0;
	callsHedgedInUnits: number = 0;
	putsHedgedInUnits: number = 0;

	// Insurance values
	percentLrpVolumeHedged: number = 0;
	percentLgmVolumeHedged: number = 0;
	animalsNeeded: number = 0;

	@service declare marketData: MarketDataService;

	constructor(
		owner: Owner,
		date: string,
		slug: string,
		standardProductLotSpecification: ProductLotSpecification | null,
		symbolGroup: InstrumentSymbolGroup | null,
		futureBarchartSymbol: string,
		values?: HedgedMonthValues,
	) {
		setOwner(this, owner);
		this.date = date;
		this.slug = slug;
		this.standardProductLotSpecification = standardProductLotSpecification;
		this.symbolGroup = symbolGroup;
		this.futureBarchartSymbol = futureBarchartSymbol;
		if (values) {
			this.setValuesFromObject(values);
		}
	}

	get displayFactor() {
		return this.symbolGroup?.displayFactor ?? 1;
	}

	get fractionDigits() {
		return this.symbolGroup?.fractionDigits ?? 0;
	}

	get monthStartDate() {
		return DateTime.fromISO(this.date).startOf('month');
	}

	get monthEndDate() {
		return DateTime.fromISO(this.date).endOf('month');
	}

	get lotSize() {
		return this.standardProductLotSpecification?.lotSize ?? 1;
	}

	get pointValue() {
		return this.standardProductLotSpecification?.pointValue ?? 1;
	}

	get contractsHedged() {
		return (this.futuresHedgedInUnits + this.callsHedgedInUnits) / this.lotSize;
	}

	// Values in tons
	get usage() {
		return this.usageDMITons;
	}

	get purchased() {
		return this.purchasedDMITons;
	}

	get futuresHedgedInTons() {
		return this.convertPriceUnitsToTons(this.futuresHedgedInUnits);
	}

	get callsHedgedInTons() {
		return this.convertPriceUnitsToTons(this.callsHedgedInUnits);
	}

	get putsHedgedInTons() {
		return this.convertPriceUnitsToTons(this.putsHedgedInUnits);
	}

	get purchasedInTons() {
		return this.convertPriceUnitsToTons(this.purchasedDMITons);
	}

	// Prices
	get marketPrice() {
		return this.marketData.getLatestPrice(this.futureBarchartSymbol);
	}

	get hedgePrice() {
		if (!this.marketPrice) return null;

		return this.marketPrice - this.hedgePlPerContract * (this.percentHedged ?? 1);
	}

	get fiftyTwoWeekHigh() {
		return this.marketData.getMarketDatum(this.futureBarchartSymbol)?.fiftyTwoWkHigh;
	}

	get fiftyTwoWeekLow() {
		return this.marketData.getMarketDatum(this.futureBarchartSymbol)?.fiftyTwoWkLow;
	}

	get percentHedged() {
		if (this.slug.startsWith('livestock-')) {
			return this.productionLbs ? (this.futuresHedgedInUnits + this.callsHedgedInUnits) / this.productionLbs : 0;
		}
		return this.usage ? (this.futuresHedgedInTons + this.callsHedgedInTons) / this.usage : 0;
	}

	get percentPurchased() {
		return this.usage ? this.purchased / this.usage : 0;
	}

	get percentFuturesHedged() {
		if (this.futuresHedgedInUnits === 0 || this.futuresHedgedInUnits === null || this.futuresHedgedInUnits === undefined) {
			return 0;
		}
		if (
			this.slug === 'livestock-live-cattle' ||
			(this.slug === 'livestock-feeder-cattle' && this.businessRole === 'FeederCattleProducer')
		) {
			return this.productionLbs ? this.futuresHedgedInUnits / this.productionLbs : 0;
		}
		if (this.slug === 'livestock-feeder-cattle' && this.businessRole === 'LiveCattleProducer') {
			return this.animalsNeeded ? this.futuresHedgedInUnits / this.animalsNeeded : 0;
		}
		// Corn uses futuresHedgedInTons to calculate the percent hedged
		return this.usage ? this.futuresHedgedInTons / this.usage : 0;
	}

	get percentCallsHedged() {
		if (this.callsHedgedInUnits === 0 || this.callsHedgedInUnits === null || this.callsHedgedInUnits === undefined) {
			return 0;
		}
		// Feeder cattle uses animalsNeeded to calculate the percent hedged when an expense
		if (this.slug === 'livestock-feeder-cattle' && this.businessRole === 'LiveCattleProducer') {
			return this.animalsNeeded ? this.callsHedgedInUnits / this.animalsNeeded : 0;
		}
		return this.usage ? this.callsHedgedInTons / this.usage : 0;
	}

	get percentPutsHedged() {
		if (this.putsHedgedInUnits === 0 || this.putsHedgedInUnits === null || this.putsHedgedInUnits === undefined) {
			return 0;
		}
		if (
			this.slug === 'livestock-live-cattle' ||
			(this.slug === 'livestock-feeder-cattle' && this.businessRole === 'FeederCattleProducer')
		) {
			return this.productionLbs ? this.putsHedgedInUnits / this.productionLbs : 0;
		}
		return this.usage ? this.putsHedgedInTons / this.usage : 0;
	}

	// Hedge PL
	get hedgePlPerContract() {
		const hedgePl = this.hedgePl;
		const contractsHedged = this.contractsHedged; // could be naturallyLongFuturesHedgedInUnits or naturallyShortFuturesHedgedInUnits or sum of both

		if (!hedgePl || !contractsHedged) return 0;

		return hedgePl / this.pointValue / this.contractsHedged;
	}

	get hedgePl() {
		const marketPrice = this.marketPrice;
		if (!marketPrice) return 0;

		return this.calculateHedgePls(this.transactions, marketPrice);
	}

	convertPriceUnitsToTons(units: number) {
		if (this.slug === 'grain-corn') {
			return (units * 56) / this.LBS_PER_TON; // 56 lbs per bushel
		} else {
			return units;
		}
	}

	setValuesFromObject(obj: HedgedMonthValues) {
		if (obj.usageDMITons != undefined) this.usageDMITons = obj.usageDMITons;
		if (obj.purchasedDMITons != undefined) this.purchasedDMITons = obj.purchasedDMITons;
		if (obj.futuresHedgedInUnits != undefined) this.futuresHedgedInUnits = obj.futuresHedgedInUnits;
		if (obj.callsHedgedInUnits != undefined) this.callsHedgedInUnits = obj.callsHedgedInUnits;
		if (obj.putsHedgedInUnits != undefined) this.putsHedgedInUnits = obj.putsHedgedInUnits;
		if (obj.transactions != undefined) this.transactions = obj.transactions;
		if (obj.productionLbs != undefined) this.productionLbs = obj.productionLbs;
		if (obj.percentLrpVolumeHedged != undefined) this.percentLrpVolumeHedged = obj.percentLrpVolumeHedged;
		if (obj.percentLgmVolumeHedged != undefined) this.percentLgmVolumeHedged = obj.percentLgmVolumeHedged;
		if (obj.animalsNeeded != undefined) this.animalsNeeded = obj.animalsNeeded;
		if (obj.businessRole != undefined) this.businessRole = obj.businessRole;
	}

	calculateHedgePls(transactions: CurrentAllocationPosition[], price: number) {
		return (
			transactions.reduce((total, transaction) => {
				let plPerPoint = 0;
				let premium = 0;
				const pointValue = transaction.Product.StandardProductLotSpecification.pointValue || 1;
				if (transaction.Instrument.type === TypeOfInstrument.Option || transaction.Instrument.type === TypeOfInstrument.Swaption) {
					const option = transaction.Instrument as Option | Swaption;
					premium = transaction.contractQuantity * transaction.lifetimeWeightedAveragePrice * pointValue;

					if (option.optionType === TypeOfOption.Call) {
						plPerPoint = transaction.contractQuantity * Math.max(0, price - option.strike);
					} else {
						plPerPoint = transaction.contractQuantity * Math.max(0, option.strike - price);
					}
				}

				if (transaction.Instrument.type === TypeOfInstrument.Future || transaction.Instrument.type === TypeOfInstrument.Swap) {
					plPerPoint = transaction.contractQuantity * (price - transaction.lifetimeWeightedAveragePrice);
				}

				return plPerPoint * pointValue - premium + total;
			}, 0) || 0
		);
	}
}

interface HedgedMonthValues {
	usageDMITons?: number;
	purchasedDMITons?: number;
	futuresHedgedInUnits?: number;
	callsHedgedInUnits?: number;
	putsHedgedInUnits?: number;
	transactions?: CurrentAllocationPosition[];
	productionLbs?: number;
	percentLrpVolumeHedged?: number;
	percentLgmVolumeHedged?: number;
	animalsNeeded?: number;
	businessRole?: string;
}

interface PercentHedgedTableRow {
	month: DateTime;
	production: number;
	usage: number;
	animalsNeeded: number;
	callsHedged: number;
	callsHedgedLbs: number;
	callsHedgedTons: number;
	physicalPurchases: number;
	physicalPurchasesLbs: number;
	physicalPurchasesTons: number;
	futuresHedged: number;
	futuresHedgedLbs: number;
	futuresHedgedTons: number;
	putsHedged: number;
	putsHedgedLbs: number;
	lrpInsurance: number;
	lrpInsuranceLbs: number;
	lgmInsurance: number;
	lgmInsuranceLbs: number;
}

interface ProjectedPnlRow {
	date: string;
	cattleSalesRevenue: number | null;
	otherRevenue: number | null;
	insurance: number | null;
	feedExpenses: number | null;
	lotExpenses: number | null;
	otherExpenses: number | null;
	numCattle: number | null;
	totalWeight: number | null;
	netPnl: null | number;
	cmeCattleRevenue: null | number;
	cmeFeedRevenue: null | number;
}

export { HedgeMonth, PercentHedgedTableRow, ProjectedPnlRow };
