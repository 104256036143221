import { camelCase } from 'string-ts';
import type { CropGroupModel } from 'vault-client/models/grain/crop-group';
import type { BusinessRevenueAndExpensesData } from 'vault-client/utils/grain/business';
import { CellComponents, type TableColumn } from 'vault-client/types/vault-table';
import { cropGroups } from 'vault-client/utils/grain/crop';
import { safeSumNull } from 'vault-client/utils/precision-math';

const cropGroupValuePaths = cropGroups.map((group) => camelCase(group));
const businessValuePaths = ['business'] as const;
const sharedValuePaths = ['label', 'total'] as const;
const valuePaths = [...sharedValuePaths, ...cropGroupValuePaths, ...businessValuePaths] as const;
type ValuePath = (typeof valuePaths)[number];
type DataValuePath = Exclude<ValuePath, 'label'>;

const rowGroupLabels = ['Total Revenue', 'Total Expenses', 'Net P/L'] as const;

function isRowGroupLabel(label: unknown): label is RowGroupLabel {
	return typeof label === 'string' && rowGroupLabels.includes(label as RowGroupLabel);
}

const rowGroupItems = {
	'Total Revenue': ['Crop Sales', 'Brokerage P/L (EOD)', 'Field-Level Revenue', 'Crop-Level Revenue', 'Business Revenue'],
	'Total Expenses': ['Field-Level Expenses', 'Crop-Level Expenses', 'Business Expenses'],
	'Net P/L': [],
} as const satisfies Record<RowGroupLabel, string[]>;

type RowGroupLabel = (typeof rowGroupLabels)[number];
type RowGroupItem = (typeof rowGroupItems)[RowGroupLabel][number];
type RowLabel = RowGroupLabel | RowGroupItem;

const rowLabelToCropGroupModelKey: Partial<Record<RowLabel, keyof CropGroupModel>> = {
	'Crop Sales': 'totalCropSales',
	'Brokerage P/L (EOD)': 'brokeragePnl',
	'Field-Level Revenue': 'totalFieldLevelRevenue',
	'Crop-Level Revenue': 'totalCropLevelRevenue',
	'Field-Level Expenses': 'totalFieldLevelExpenses',
	'Crop-Level Expenses': 'totalCropLevelExpenses',
	'Total Revenue': 'totalRevenue',
	'Total Expenses': 'totalExpenses',
	'Net P/L': 'totalNetPnl',
} as const;

const rowLabelToBusinessDataKey: Partial<Record<RowLabel, keyof BusinessRevenueAndExpensesData>> = {
	'Business Revenue': 'businessRevenue',
	'Business Expenses': 'businessExpenses',
	'Total Revenue': 'totalRevenue',
	'Total Expenses': 'totalExpenses',
	'Net P/L': 'businessNetPnl',
} as const;

type CropsHarvestPnlTableColumn = TableColumn & { valuePath: ValuePath };

export type CropsHarvestPnlTableRow = {
	label: RowLabel;
} & {
	[key in DataValuePath]: number | undefined;
};

type CropsHarvestPnlTableParentRow = CropsHarvestPnlTableRow & {
	children: CropsHarvestPnlTableRow[];
};

class Row implements CropsHarvestPnlTableRow {
	label: RowLabel;
	private businessData: BusinessRevenueAndExpensesData;
	private cornGroup?: CropGroupModel;
	private soybeanGroup?: CropGroupModel;
	private wheatGroup?: CropGroupModel;
	private nonCommodityGroup?: CropGroupModel;

	constructor(label: RowLabel, cropGroups: CropGroupModel[], businessData: BusinessRevenueAndExpensesData) {
		this.label = label;
		this.businessData = businessData;
		this.cornGroup = cropGroups.find((group) => group.cropGroup === 'Corn');
		this.soybeanGroup = cropGroups.find((group) => group.cropGroup === 'Soybeans');
		this.wheatGroup = cropGroups.find((group) => group.cropGroup === 'Wheat');
		this.nonCommodityGroup = cropGroups.find((group) => group.cropGroup === 'Non-Commodity Crops');
	}

	get cropGroupDataKey(): keyof CropGroupModel | undefined {
		return rowLabelToCropGroupModelKey[this.label];
	}

	get businessDataKey(): keyof BusinessRevenueAndExpensesData | undefined {
		return rowLabelToBusinessDataKey[this.label];
	}

	get corn(): number | undefined {
		return this.getCropGroupDataValue(this.cornGroup);
	}

	get soybeans(): number | undefined {
		return this.getCropGroupDataValue(this.soybeanGroup);
	}

	get wheat(): number | undefined {
		return this.getCropGroupDataValue(this.wheatGroup);
	}

	get nonCommodityCrops(): number | undefined {
		return this.getCropGroupDataValue(this.nonCommodityGroup);
	}

	get business(): number | undefined {
		return this.getBusinessDataValue(this.businessData);
	}

	get total(): number | undefined {
		return safeSumNull(this.corn, this.soybeans, this.wheat, this.nonCommodityCrops, this.business) ?? undefined;
	}

	getCropGroupDataValue(cropGroup: CropGroupModel | undefined): number | undefined {
		const dataKey = this.cropGroupDataKey;
		if (!dataKey) return undefined;

		return cropGroup?.[dataKey] as number | undefined;
	}

	getBusinessDataValue(businessData: BusinessRevenueAndExpensesData): number | undefined {
		const businessDataKey = this.businessDataKey;
		if (!businessDataKey) return undefined;

		return businessData[businessDataKey] as number | undefined;
	}
}

class ParentRow extends Row implements CropsHarvestPnlTableParentRow {
	children: CropsHarvestPnlTableRow[];

	constructor(
		label: RowLabel,
		cropGroups: CropGroupModel[],
		businessData: BusinessRevenueAndExpensesData,
		children: CropsHarvestPnlTableRow[] = [],
	) {
		super(label, cropGroups, businessData);
		this.children = children;
	}

	/**
	 * Creates a parent row with calculated children rows
	 */
	static createWithChildren(
		label: RowGroupLabel,
		cropGroups: CropGroupModel[],
		businessData: BusinessRevenueAndExpensesData,
	): CropsHarvestPnlTableParentRow {
		const childRows = rowGroupItems[label].map((childLabel) => new Row(childLabel, cropGroups, businessData));
		return new ParentRow(label, cropGroups, businessData, childRows);
	}
}

const baseCurrencyColumn = {
	textAlign: 'right',
	cellComponent: CellComponents.IntlNumberFormat,
	componentArgs: {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	},
	isFixed: '',
	isVisible: true,
} satisfies Partial<TableColumn>;

function getDefaultColumnWidth(columnName: string): number {
	// These values were determined by trial and error
	const charWidth = 6.25;
	const widthOffset = 75;
	return Math.round(columnName.length * charWidth + widthOffset);
}

export {
	rowGroupLabels,
	cropGroupValuePaths,
	baseCurrencyColumn,
	getDefaultColumnWidth,
	isRowGroupLabel,
	Row,
	ParentRow,
	CropsHarvestPnlTableColumn,
	CropsHarvestPnlTableParentRow,
	type ValuePath,
};
