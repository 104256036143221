import Transition from '@ember/routing/transition';
import FeedOverviewRoute from 'vault-client/routes/feed/overview';
import BusinessesBusinessRoute from 'vault-client/routes/businesses/business';
import { ModelFrom } from 'vault-client/utils/type-utils';

export default class BusinessesBusinessFeedOverviewRoute extends FeedOverviewRoute {
	beforeModel(transition: Transition<unknown>): void | Promise<unknown> {
		super.beforeModel(transition);

		const { businessId } = this.modelFor('businesses.business') as ModelFrom<BusinessesBusinessRoute>;
		if (!businessId) throw new Error(`BusinessId not found`);
		this.scopeId = businessId;
	}
}
