import Route from '@ember/routing/route';
import Transition from '@ember/routing/transition';
import BusinessesBusinessCattleRationsController from 'vault-client/controllers/businesses/business/cattle/rations';
import { GET_CATTLE_FEED_RATIONS } from 'vault-client/graphql/queries/businesses/business/cattle/rations';
import { Query_CattleFeedRationsArgs } from 'vault-client/types/graphql-types';
import { useRationsQuery } from 'vault-client/utils/cattle/rations/use-rations-query';

type Params = {
	ingredientIds?: string[];
};

export default class BusinessesBusinessCattleRationsIndexRoute extends Route {
	queryParams = {
		ingredientIds: {
			refreshModel: true,
		},
	};

	async model(params: Params) {
		const { business_id: businessId } = this.paramsFor('businesses.business') as { business_id: string };
		const variables: Query_CattleFeedRationsArgs = {
			scopeId: businessId,
			where: params.ingredientIds?.length
				? {
						CattleFeedRationStages: {
							CattleFeedRationStageIngredients: {
								feedIngredientId: {
									in: params.ingredientIds,
								},
							},
						},
					}
				: undefined,
		};

		const rationsQuery = useRationsQuery(this, {
			variables,
		});

		await rationsQuery.promise;

		return {
			rationsQuery,
			businessId,
			variables,
			query: GET_CATTLE_FEED_RATIONS,
		};
	}

	resetController(controller: BusinessesBusinessCattleRationsController, isExiting: boolean, transition: Transition) {
		super.resetController(controller, isExiting, transition);
		if (isExiting) {
			controller.setSidePanelState({ state: null });
		}
	}
}
