import { CattleLot } from 'vault-client/types/graphql-types';
import { TableColumn, CellComponents } from 'vault-client/types/vault-table';

export type LotsTableRow = Pick<CattleLot, 'id' | 'name' | 'description'>;

export function getLotsTableColumns(args: { editLot: (lot: LotsTableRow) => void; deleteLot: (lot: LotsTableRow) => void }): TableColumn[] {
	return [
		{
			id: '4f9c51d0-5049-4c4c-9c5d-6739bc5bfc7a',
			name: 'Lot Name',
			valuePath: 'name',
			cellComponent: CellComponents.String,
			width: 120,
			textAlign: 'left',
			isSortable: true,
			isFixed: '',
			isVisible: true,
			isTotaled: false,
		},
		{
			id: 'b3f3b5d9-83e3-4c4b-8c7d-3e3e3e3e3e3e',
			name: 'Yard',
			valuePath: 'CattleYard.name',
			cellComponent: CellComponents.String,
			width: 100,
			textAlign: 'left',
			isSortable: true,
			isFixed: '',
			isVisible: true,
			isTotaled: false,
		},
		{
			id: 'fe596886-ebd7-43a8-a747-15c776ba0150',
			name: '# of Animals',
			valuePath: 'numberOfCattle',
			cellComponent: CellComponents.String,
			width: 130,
			textAlign: 'right',
			isSortable: true,
			isFixed: '',
			isVisible: false,
			isTotaled: false,
		},
		{
			id: 'fde5fa7f-4d53-472e-b474-5442c31dcd63',
			name: 'Purchase Price',
			valuePath: 'purchasePrice',
			cellComponent: CellComponents.IntlNumberFormat,
			componentArgs: {
				style: 'currency',
				currency: 'USD',
				currencySign: 'accounting',
				minimumFractionDigits: '2',
				maximumFractionDigits: '2',
			},
			width: 140,
			textAlign: 'right',
			isSortable: true,
			isFixed: '',
			isVisible: false,
			isTotaled: false,
		},
		{
			id: 'bf71bbc1-2760-460f-baea-e4f17fcf27dc',
			name: 'Mortality Rate',
			valuePath: 'mortalityRate',
			cellComponent: CellComponents.IntlNumberFormat,
			componentArgs: {
				style: 'percent',
				minimumFractionDigits: '0',
				maximumFractionDigits: '2',
			},
			width: 140,
			textAlign: 'right',
			isSortable: true,
			isFixed: '',
			isVisible: false,
			isTotaled: false,
		},
		{
			id: 'c4f4c5d9-93e3-4c4b-8c7d-4e4e4e4e4e4e',
			name: 'Start Weight',
			valuePath: 'startWeightInLb',
			cellComponent: CellComponents.String,
			width: 130,
			textAlign: 'right',
			isSortable: true,
			isFixed: '',
			isVisible: true,
			isTotaled: false,
		},
		{
			id: 'd5f5d5d9-a3e3-4c4b-8c7d-5e5e5e5e5e5e',
			name: 'Start Date',
			valuePath: 'activeStartDate',
			cellComponent: CellComponents.IntlDateTimeFormat,
			width: 120,
			textAlign: 'left',
			isSortable: true,
			isFixed: '',
			isVisible: true,
			isTotaled: false,
		},
		{
			id: 'e6f6e5d9-b3e3-4c4b-8c7d-6e6e6e6e6e6e',
			name: 'Target Weight',
			valuePath: 'targetWeightInLb',
			cellComponent: CellComponents.String,
			width: 140,
			textAlign: 'right',
			isSortable: true,
			isFixed: '',
			isVisible: true,
			isTotaled: false,
		},
		{
			id: 'f7f7f5d9-c3e3-4c4b-8c7d-7e7e7e7e7e7e',
			name: 'Target Date',
			valuePath: 'activeEndDate',
			cellComponent: CellComponents.IntlDateTimeFormat,
			width: 130,
			textAlign: 'left',
			isSortable: true,
			isFixed: '',
			isVisible: true,
			isTotaled: false,
		},
		{
			id: 'g8f8g5d9-d3e3-4c4b-8c7d-8e8e8e8e8e8e',
			name: 'Days on Feed',
			valuePath: 'numberOfDaysActive',
			cellComponent: CellComponents.String,
			width: 130,
			textAlign: 'right',
			isSortable: true,
			isFixed: '',
			isVisible: true,
			isTotaled: false,
		},
		{
			id: 'h9f9h5d9-e3e3-4c4b-8c7d-9e9e9e9e9e9e',
			name: 'Ration',
			valuePath: 'CattleFeedRation.name',
			cellComponent: CellComponents.String,
			width: 110,
			textAlign: 'left',
			isSortable: true,
			isFixed: '',
			isVisible: true,
			isTotaled: false,
		},
		{
			id: 'i0f0i5d9-f3e3-4c4b-8c7d-0e0e0e0e0e0e',
			name: 'Revenue',
			valuePath: 'revenue',
			cellComponent: CellComponents.IntlNumberFormat,
			componentArgs: {
				style: 'currency',
				currency: 'USD',
				currencySign: 'accounting',
				minimumFractionDigits: '2',
				maximumFractionDigits: '2',
			},
			width: 130,
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
			isTotaled: true,
		},
		{
			id: 'j1f1j5d9-g3e3-4c4b-8c7d-1e1e1e1e1e1e',
			name: 'Expenses',
			valuePath: 'expenses',
			cellComponent: CellComponents.IntlNumberFormat,
			componentArgs: {
				style: 'currency',
				currency: 'USD',
				currencySign: 'accounting',
				minimumFractionDigits: '2',
				maximumFractionDigits: '2',
			},
			width: 130,
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
			isTotaled: true,
		},
		{
			id: 'k2f2k5d9-h3e3-4c4b-8c7d-2e2e2e2e2e2e',
			name: 'Net P/L',
			valuePath: 'netPnl',
			cellComponent: CellComponents.IntlNumberFormat,
			componentArgs: {
				style: 'currency',
				currency: 'USD',
				currencySign: 'accounting',
				minimumFractionDigits: '2',
				maximumFractionDigits: '2',
			},
			width: 130,
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
			isTotaled: true,
		},
		{
			id: 'dbf26259-a3de-42a7-b1fb-e5525d9aa0e8',
			name: 'Notes',
			valuePath: 'description',
			cellComponent: CellComponents.String,
			width: 140,
			textAlign: 'left',
			isSortable: true,
			isFixed: '',
			isVisible: false,
			isTotaled: false,
		},
		{
			id: '602f415d-728e-4f35-9dea-22bfcfbc7a92',
			name: '',
			secondaryDisplayName: 'Edit',
			minWidth: 80,
			maxWidth: 80,
			width: 80,
			cellComponent: CellComponents.Button,
			componentArgs: {
				style: 'plain',
				iconOnlyButton: true,
				fn: args.editLot,
				centerIconHref: '/icons/Edit-Outline.svg#edit-outline',
				centerIconClass: 'icon-interactive-stroke',
			},
			isSortable: false,
			isVisible: true,
			isFixed: 'right',
		},
		{
			id: 'ac0d91a8-5925-4bd2-9f89-c78a2f1cf771',
			name: '',
			secondaryDisplayName: 'Remove',
			minWidth: 80,
			maxWidth: 80,
			width: 80,
			cellComponent: CellComponents.Button,
			componentArgs: {
				style: 'plain',
				iconOnlyButton: true,
				fn: args.deleteLot,
				centerIconHref: '/icons/Delete-Outline.svg#delete-outline',
				centerIconClass: 'icon-interactive-stroke',
			},
			isSortable: false,
			isVisible: true,
			isFixed: 'right',
		},
	];
}
