import { setOwner } from '@ember/application';
import Owner from '@ember/owner';
import { service } from '@ember/service';
import type MarketDataService from 'vault-client/services/market-data';
import type {
	Crop as CropType,
	CropHarvestedAndSoldVolume,
	CropTransaction,
	Future,
	Maybe,
	CropPricingMethodology,
} from 'vault-client/types/graphql-types';
import { getCropGroup, type CropGroup } from 'vault-client/utils/grain/crop';
import { safeSubNull, safeSum, safeSumNull } from 'vault-client/utils/precision-math';
import { getCropMarketPricePerUnit, getCropPrice, getTotalPriceForPricedCropTransactions } from 'vault-client/utils/grain-utils';
import { debug } from '@ember/debug';
import { registerDestructor } from '@ember/destroyable';

const cleanup = (crop: CropModel) => {
	crop.unregisterCropPricingFuturePrice();
};

export class CropModel {
	@service declare marketData: MarketDataService;

	id: string;
	crop: CropType;
	cropGroup: CropGroup;
	name: string;
	pricingMethodology: CropPricingMethodology;
	cropPrice: number;
	forecastedProduction: number;
	soldUnits: number;
	unsoldUnits: number;
	cropPricingFuture: Maybe<Future>;
	pointValue: number;
	lotSize: number;
	cropTransactions: CropTransaction[];
	cropLevelRevenue: Maybe<number>;
	cropLevelExpenses: Maybe<number>;
	fieldLevelRevenue: Maybe<number>;
	fieldLevelExpenses: Maybe<number>;
	harvestYear: number;

	constructor(
		owner: Owner,
		{
			crop,
			CropHarvestedAndSoldVolumes,
			CropTransactions = [],
			harvestYear,
		}: {
			crop: CropType;
			CropHarvestedAndSoldVolumes: CropHarvestedAndSoldVolume[];
			CropTransactions?: CropTransaction[];
			harvestYear: number;
		},
	) {
		setOwner(this, owner);
		this.id = crop.id;
		this.crop = crop;
		this.cropGroup = getCropGroup(crop);
		this.name = crop.name;
		this.pricingMethodology = crop.pricingMethodology;
		this.cropPrice = getCropPrice(crop, harvestYear);
		this.cropTransactions = CropTransactions.filter(({ cropId }) => cropId === crop.id) ?? [];
		this.harvestYear = harvestYear;

		// Calculate volumes
		const filteredCropHarvestedAndSoldVolumes = CropHarvestedAndSoldVolumes.filter(({ cropId }) => cropId === crop.id);
		this.forecastedProduction = filteredCropHarvestedAndSoldVolumes.reduce(
			(acc, cropVolume) => safeSum(acc, cropVolume.forecastedProductionInBu),
			0,
		);
		this.soldUnits = filteredCropHarvestedAndSoldVolumes.reduce((acc, cropVolume) => safeSum(acc, cropVolume.volumeSoldInBu), 0);
		this.unsoldUnits = this.forecastedProduction - this.soldUnits;

		// Set pricing future
		this.cropPricingFuture = this.getCropPricingFuture(crop);
		this.pointValue = crop.Category.HedgeProduct?.StandardProductLotSpecification?.pointValue ?? 0;
		this.lotSize = crop.Category.HedgeProduct?.StandardProductLotSpecification?.lotSize ?? 0;

		// Calculate revenues and expenses
		const {
			totalUsdFromCropFlatValues: flatRevenue,
			totalUsdFromCropPerAcreValues: perAcreRevenue,
			totalUsdFromFieldFlatValues: flatFieldRevenue,
			totalUsdFromFieldPerAcreValues: perAcreFieldRevenue,
		} = crop.RevenuesForHarvestYear ?? {};

		const {
			totalUsdFromCropFlatValues: flatExpenses,
			totalUsdFromCropPerAcreValues: perAcreExpenses,
			totalUsdFromFieldFlatValues: flatFieldExpenses,
			totalUsdFromFieldPerAcreValues: perAcreFieldExpenses,
		} = crop.ExpensesForHarvestYear ?? {};

		this.cropLevelRevenue = safeSumNull(flatRevenue, perAcreRevenue);
		this.fieldLevelRevenue = safeSumNull(flatFieldRevenue, perAcreFieldRevenue);

		this.cropLevelExpenses = safeSumNull(flatExpenses, perAcreExpenses);
		this.fieldLevelExpenses = safeSumNull(flatFieldExpenses, perAcreFieldExpenses);

		this.registerCropPricingFuturePrice();

		// Register destructor to unregister from market data service
		// Associate with owner to ensure it is destroyed when the owner is destroyed
		// associateDestroyableChild(destroyableParent, this);
		registerDestructor(this, cleanup);
	}

	/**
	 * Gets the current price of the crop's pricing future
	 */
	get cropPricingFuturePrice(): Maybe<number> {
		const symbol = this.cropPricingFuture?.barchartSymbol;
		if (!symbol) return null;

		return this.marketData.getLatestPrice(symbol);
	}

	/**
	 * Calculates the total price for sold units
	 */
	get soldUnitsTotalPrice(): number {
		return getTotalPriceForPricedCropTransactions(
			this.cropTransactions,
			this.cropPrice,
			this.pricingMethodology,
			this.pointValue,
			this.lotSize,
			this.cropPricingFuturePrice,
		);
	}

	/**
	 * Calculates the mark-to-market value of unsold units
	 */
	get unsoldMarkToMarketValue(): number {
		const pricePerUnit = getCropMarketPricePerUnit(this.crop, this.pointValue, this.lotSize, this.cropPricingFuturePrice, this.harvestYear);
		if (!pricePerUnit) return 0;
		return this.unsoldUnits * pricePerUnit;
	}

	get revenue(): Maybe<number> {
		return safeSumNull(this.cropLevelRevenue, this.fieldLevelRevenue, this.unsoldMarkToMarketValue, this.soldUnitsTotalPrice);
	}

	get expenses(): Maybe<number> {
		return safeSumNull(this.cropLevelExpenses, this.fieldLevelExpenses);
	}

	get netPnl(): Maybe<number> {
		return safeSubNull(this.revenue, this.expenses);
	}

	/**
	 * Gets the appropriate future contract for pricing this crop
	 */
	getCropPricingFuture(crop: CropType): Maybe<Future> {
		// Prefer ContractMonthInstrument, fallback to MostCurrentFuture
		return crop.CropHarvestYears[0]?.ContractMonthInstrument ?? crop.Category.HedgeProduct?.MostCurrentFuture ?? null;
	}

	registerCropPricingFuturePrice() {
		const { barchartSymbol } = this.cropPricingFuture ?? {};
		if (!barchartSymbol) {
			debug('No barchart symbol found for crop pricing future. Could not register with market data service.');
			return;
		}

		this.marketData.register(barchartSymbol);
	}

	unregisterCropPricingFuturePrice() {
		const { barchartSymbol } = this.cropPricingFuture ?? {};
		if (!barchartSymbol) {
			debug('No barchart symbol found for crop pricing future. Could not unregister from market data service.');
			return;
		}

		this.marketData.unregister(barchartSymbol);
	}
}
