import { CattleLot as ICattleLot, TypeOfCattleLotExpense, CattleLotFeedRationUsagePerMonth } from 'vault-client/types/graphql-types';
import { tracked } from '@glimmer/tracking';
import { DisplayUnits } from 'vault-client/types/cattle/display-units';
import { divide, safeSum } from 'vault-client/utils/precision-math';

export default class CattleLot
	implements
		Pick<
			ICattleLot,
			| 'id'
			| 'name'
			| 'description'
			| 'activeStartDate'
			| 'activeEndDate'
			| 'numberOfCattle'
			| 'startWeightInLb'
			| 'targetWeightInLb'
			| 'averageDailyWeightGainInLb'
			| 'mortalityRate'
			| 'purchasePricePerHeadInUsd'
			| 'isActive'
			| 'cattleLotExpensesType'
			| 'cattleLotTotalExpensesInUsd'
			| 'numberOfDaysActive'
			| 'forecastedSalesRevenueInUsd'
			| 'CattleLotFeedRationUsagesPerMonth'
		>
{
	@tracked id: string;
	@tracked name: string;
	@tracked description: string | null | undefined;
	@tracked activeStartDate: string;
	@tracked activeEndDate: string;
	@tracked numberOfCattle: number;
	@tracked numberOfCattleToSell: number;
	@tracked startWeightInLb: number;
	@tracked targetWeightInLb: number;
	@tracked averageDailyWeightGainInLb: number;
	@tracked mortalityRate: number;
	@tracked purchasePricePerHeadInUsd: number;
	@tracked isActive: boolean;
	@tracked cattleLotExpensesType: TypeOfCattleLotExpense;
	@tracked cattleLotTotalExpensesInUsd: number;
	@tracked numberOfDaysActive: number;
	@tracked CattleYard: { id: string; name: string } | null | undefined;
	@tracked CattleFeedRation: { id: string; name: string } | null | undefined;
	@tracked selectedDisplayUnit: DisplayUnits;
	@tracked forecastedSalesRevenueInUsd: number;
	@tracked totalFeedExpenseInUsd: number;
	@tracked purchasePriceInUsd: number;
	@tracked forecastedSellPricePerHeadInUsd: number;
	@tracked forecastedSellPricePerLbInUsd: number;
	@tracked totalEndingWeightInLb: number;
	@tracked cattleLotInputTotalExpensesInUsd: number;
	@tracked cattleLotInputExpensesInUsd: number;
	@tracked CattleLotFeedRationUsagesPerMonth: CattleLotFeedRationUsagePerMonth[];
	constructor(lot: Partial<ICattleLot>, selectedDisplayUnit: DisplayUnits) {
		this.id = lot.id!;
		this.name = lot.name!;
		this.description = lot.description;
		this.activeStartDate = lot.activeStartDate!;
		this.activeEndDate = lot.activeEndDate!;
		this.numberOfCattle = lot.numberOfCattle!;
		this.numberOfCattleToSell = lot.numberOfCattleToSell!;
		this.startWeightInLb = lot.startWeightInLb!;
		this.targetWeightInLb = lot.targetWeightInLb!;
		this.averageDailyWeightGainInLb = lot.averageDailyWeightGainInLb!;
		this.mortalityRate = lot.mortalityRate!;
		this.purchasePricePerHeadInUsd = lot.purchasePricePerHeadInUsd!;
		this.isActive = lot.isActive ?? true;
		this.cattleLotExpensesType = lot.cattleLotExpensesType ?? TypeOfCattleLotExpense.PerHead;
		this.numberOfDaysActive = lot.numberOfDaysActive ?? 0;
		this.CattleYard = lot.CattleYard;
		this.CattleFeedRation = lot.CattleFeedRation;
		this.selectedDisplayUnit = selectedDisplayUnit;
		this.purchasePriceInUsd = lot.purchasePriceInUsd ?? 0;
		this.forecastedSalesRevenueInUsd = lot.forecastedSalesRevenueInUsd ?? 0;
		this.forecastedSellPricePerHeadInUsd = lot.forecastedSellPricePerHeadInUsd ?? 0;
		this.forecastedSellPricePerLbInUsd = lot.forecastedSellPricePerLbInUsd ?? 0;
		this.CattleLotFeedRationUsagesPerMonth = lot.CattleLotFeedRationUsagesPerMonth ?? [];
		this.totalEndingWeightInLb = lot.totalEndingWeightInLb ?? 0;
		this.cattleLotInputTotalExpensesInUsd = lot.cattleLotTotalExpensesInUsd ?? 0;
		this.cattleLotInputExpensesInUsd = lot.cattleLotInputExpensesInUsd ?? 0;
		// Calculate total feed expenses and make it negative since it's an expense
		// This represents the raw total feed cost across all months
		this.totalFeedExpenseInUsd = -this.CattleLotFeedRationUsagesPerMonth.reduce((sum, expense) => sum + expense.totalExpenseInUsd, 0);

		// For total expenses, we add the raw feed expenses to the total
		// This ensures the total always includes all expenses regardless of display unit
		this.cattleLotTotalExpensesInUsd =
			(lot.cattleLotTotalExpensesInUsd ?? 0) + this.totalFeedExpenseInUsd + -(this.purchasePriceInUsd);
	}

	get revenue() {
		switch (this.selectedDisplayUnit) {
			case DisplayUnits.Total:
				return this.forecastedSalesRevenueInUsd;
			case DisplayUnits.PerHead:
				return this.forecastedSellPricePerHeadInUsd;
			case DisplayUnits.PerCWT:
				return this.forecastedSellPricePerLbInUsd * 100;
		}
	}

	get expenses() {
		switch (this.selectedDisplayUnit) {
			case DisplayUnits.Total:
				// Return total expenses which already includes feed expenses
				return this.cattleLotTotalExpensesInUsd;
			case DisplayUnits.PerHead:
				// For non-per-head expense type, simply divide total expenses (which includes feed) by number of cattle
				return divide(this.cattleLotTotalExpensesInUsd, this.numberOfCattle);
			case DisplayUnits.PerCWT:
				return this.totalEstimatedExpensesPerCwt
									- this.purchasePricePerCwt
									- this.feedExpensePerCwt
			default:
				return null;
		}
	}

	get totalEstimatedExpensesPerCwt() {
		return this.cattleLotInputTotalExpensesInUsd / (this.totalEndingWeightInLb / 100);
	}

	get purchasePricePerCwt() {
		return this.purchasePriceInUsd / ((this.numberOfCattle * this.startWeightInLb) / 100);
	}

	get feedExpensePerCwt() {
		return -(this.totalFeedExpenseInUsd) / (this.totalEndingWeightInLb / 100);
	}



	get purchasePrice() {
		switch (this.selectedDisplayUnit) {
			case DisplayUnits.Total:
				return this.purchasePriceInUsd;
			case DisplayUnits.PerHead:
				return this.purchasePricePerHeadInUsd;
			case DisplayUnits.PerCWT:
				if(this.numberOfCattle === 0 || this.purchasePricePerHeadInUsd === 0 || this.totalEstimatedStartingRevenueWeightInCWT === 0) {
					return 0;
				}
				return divide(this.purchasePriceInUsd, this.totalEstimatedStartingRevenueWeightInCWT);
			default:
				return null;
		}
	}

	get totalEstimatedStartingRevenueWeightInCWT() {
		return this.numberOfCattle * divide(this.startWeightInLb, 100);
	}

	// If both null return null, else return sum
	get netPnl() {
		if (this.revenue === null && this.expenses === null) {
			return null;
		}
		// Since expenses (including feed expenses) are stored as negative values,
		// we can simply sum revenue and expenses to get net P&L
		return safeSum(this.revenue, this.expenses);
	}
}
