import Route from '@ember/routing/route';
import { ProductSlug } from 'vault-client/types/vault-client';

/**
  Get the resolved type of an item.
  - If the item is a promise, the result will be the resolved value type
  - If the item is not a promise, the result will just be the type of the item
 */
export type Resolved<P> = P extends Promise<infer T> ? T : P;

/** Get the resolved model value from a route. */
export type ModelFrom<R extends Route> = Resolved<ReturnType<R['model']>>;

export const productSlugs = [
	'bonds-30-day-fed-funds',
	'bonds-us-10-year-treasury-note',
	'bonds-us-2-year-treasury-note',
	'bonds-us-30-year-treasury-bond',
	'bonds-us-5-year-treasury-note',
	'energy-chicago-ethanol',
	'energy-conway-propane-opis-swap',
	'energy-crude-oil-brent',
	'energy-crude-oil-wti',
	'energy-gasoline-rbob',
	'energy-henry-hub-natural-gas-swap',
	'energy-natural-gas',
	'energy-ny-harbor-ulsd',
	'equity-dow-jones-$5-mini',
	'equity-nasdaq-100-index',
	'equity-russell-2000-index',
	'equity-s&p-500-index',
	'equity-s&p-500-vix',
	'equity-s&p-gsci',
	'eu-dairy-butter-european',
	'eu-dairy-liquid-milk-europe',
	'eu-dairy-skimmed-milk-europe',
	'eu-dairy-whey-powder-european',
	'fx-australian-dollar',
	'fx-british-pound',
	'fx-canadian-dollar',
	'fx-euro',
	'fx-japanese-yen',
	'fx-mexican-peso',
	'fx-newzealand-dollar',
	'fx-russian-ruble',
	'fx-swiss-franc',
	'fx-us-dollar-index',
	'grain-canola',
	'grain-chicago-soft-red-winter-wheat',
	'grain-corn',
	'grain-hard-red-spring-wheat',
	'grain-hrwi-hard-red-winter-wheat',
	'grain-kansas-city-hard-red-wheat',
	'grain-oats',
	'grain-rough-rice',
	'grain-soybean-meal',
	'grain-soybean-oil',
	'grain-soybeans',
	'grain-srwi-soft-red-winter-wheat',
	'livestock-feeder-cattle',
	'livestock-lean-hogs',
	'livestock-live-cattle',
	'livestock-pork-cutouts',
	'metals-aluminum',
	'metals-copper',
	'metals-gold',
	'metals-palladium',
	'metals-platinum',
	'metals-silver',
	'nz-dairy-anhydrous-milk-fat',
	'nz-dairy-global-butter',
	'nz-dairy-liquid-milk',
	'nz-dairy-skim-milk-powder',
	'nz-dairy-whole-milk-powder',
	'softs-cocoa',
	'softs-coffee',
	'softs-cotton-2',
	'softs-lumber',
	'softs-orange-juice',
	'softs-sugar-11',
	'softs-sugar-16',
	'us-dairy-butter',
	'us-dairy-butter-grade-aa',
	'us-dairy-cheese',
	'us-dairy-cheese-barrel',
	'us-dairy-cheese-block',
	'us-dairy-cheese-block-spot',
	'us-dairy-class-iii',
	'us-dairy-class-iv',
	'us-dairy-dry-whey',
	'us-dairy-grade-a-non-fat-dry-milk',
	'us-dairy-nonfat-milk',
] as const;

export function isProductSlug(maybeProductSlug: unknown): maybeProductSlug is ProductSlug {
	return typeof maybeProductSlug === 'string' && productSlugs.includes(maybeProductSlug as ProductSlug);
}

export function isDefined<T>(value: T | undefined | null): value is T {
	return value !== undefined && value !== null;
}
