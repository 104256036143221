import { useMutation, type MutationOptions } from 'glimmer-apollo';
import { CREATE_CATTLE_FEED_RATION_STAGE_INGREDIENT } from 'vault-client/graphql/mutations/cattle/cattle-feed-ration-stage-ingredient';
import type { Mutation, Mutation_createCattleFeedRationStageIngredientArgs } from 'vault-client/types/graphql-types';
import { FIELD_NAMES } from 'vault-client/utils/cattle';

const defaultOptions: MutationOptions<
	Mutation['createCattleFeedRationStageIngredient'],
	Mutation_createCattleFeedRationStageIngredientArgs
> = {
	update: (cache) => {
		FIELD_NAMES.forEach((fieldName) => {
			cache.evict({ fieldName });
		});
		cache.gc();
	},
};

export function useCreateCattleFeedRationStageIngredientMutation(
	context: object,
	options?: MutationOptions<Mutation['createCattleFeedRationStageIngredient'], Mutation_createCattleFeedRationStageIngredientArgs>,
) {
	return useMutation<Mutation['createCattleFeedRationStageIngredient'], Mutation_createCattleFeedRationStageIngredientArgs>(context, () => [
		CREATE_CATTLE_FEED_RATION_STAGE_INGREDIENT,
		{
			...defaultOptions,
			...options,
		},
	]);
}
