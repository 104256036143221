import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { gql, useMutation } from 'glimmer-apollo';
import { inject as service } from '@ember/service';
import { isFormValid, getInvalidElements } from '../utils/form-validation';
import {
	Buyer,
	GrainFillOrder,
	GrainFillOrderCreateDTO,
	GrainFillOrderUpdateDTO,
	GrainLocation,
	Mutation,
	Seller,
	TypeOfGrainOrderSales,
	TypeOfGrainPlan,
	GrainOrderDeliveryTerms,
	GrainTargetOrder,
	CloneGrainFillOrderNoteInput,
} from 'vault-client/types/graphql-types';
import { guidFor } from '@ember/object/internals';
import { task } from 'ember-concurrency';

const SPLIT_FILL_MUTATION = gql`
	mutation UpdateGrainFillOrder($existingFillId: String!, $existingFillData: GrainFillOrderUpdateDTO!, $newFill: GrainFillOrderCreateDTO!) {
		updateGrainFillOrder(data: $existingFillData, id: $existingFillId) {
			id
		}
		createGrainFillOrder(data: $newFill) {
			id
		}
	}
`;

const CREATE_CLONE_FILL_NOTES = gql`
	mutation CloneGrainFillOrderNotes($data: CloneGrainFillOrderNoteInput!) {
		cloneGrainFillOrderNotes(data: $data) {
			id
		}
	}
`;

interface Target {
	Target: GrainTargetOrder;
}

interface SplitFillButtonArgs {
	fill: GrainFillOrder & Target;
}

type SplitGrainFillOrderVars = {
	existingFillId: string;
	existingFillData: GrainFillOrderUpdateDTO;
	newFill: GrainFillOrderCreateDTO;
};

type CreateCloneFillNotesVars = {
	data: CloneGrainFillOrderNoteInput;
};

export default class SplitFillButton extends Component<SplitFillButtonArgs> {
	@service permissions: any;
	@tracked showModal = false;
	@tracked showConfirmationModal = false;
	@tracked errorMessage: string | null = null;
	@tracked incorrectSplit: boolean = false;
	@tracked deliveryTerms: GrainOrderDeliveryTerms | null | undefined = this.args.fill.deliveryTerms;
	@tracked newFillId: string | null = null;

	componentId = guidFor(this);

	splitFillMutation = useMutation<
		{
			updateGrainFillOrder: Mutation['updateGrainFillOrder'];
			createGrainFillOrder: Mutation['createGrainFillOrder'];
		},
		SplitGrainFillOrderVars
	>(this, () => [
		SPLIT_FILL_MUTATION,
		{
			onComplete: (data) => {
				this.errorMessage = null;
				this.newFillId = data?.createGrainFillOrder?.id ?? null;
			},
			onError: (error): void => {
				this.errorMessage = 'There was an error. Fill not split.';
				console.error('Error while attempting to split fill.', error.message);
			},
			update: (cache) => {
				cache.evict({ fieldName: 'GrainTargetOrder' });
				cache.evict({ fieldName: 'GrainTargetOrders' });
				cache.evict({ fieldName: 'GrainFillOrder' });
				cache.evict({ fieldName: 'GrainFillOrders' });
				cache.evict({ fieldName: 'AggregateGrainFillOrders' });
				cache.evict({ fieldName: 'GrainCropPlan' });
				cache.evict({ fieldName: 'GrainCropPlans' });
				cache.evict({ fieldName: 'AggregateGrainCropPlans' });
				cache.evict({ fieldName: 'GrainFeedPlans' });
				cache.evict({ fieldName: 'GrainFeedPlan' });
				cache.evict({ fieldName: 'AggregateGrainFeedPlans' });
				cache.gc();
			},
		},
	]);

	createCloneFillNotes = useMutation<{ createGrainOrderNote: Mutation['cloneGrainFillOrderNotes'] }, CreateCloneFillNotesVars>(this, () => [
		CREATE_CLONE_FILL_NOTES,
		{
			onComplete: () => {
				this.closeModal();
			},
			onError: (error): void => {
				this.errorMessage = 'There was an error. Fill Notes not inserted.';
				console.error('Error while attempting to insert existing notes while splitting fill.', error.message);
			},
			update: (cache) => {
				cache.evict({ fieldName: 'GrainFillOrder' });
				cache.evict({ fieldName: 'GrainFillOrders' });
				cache.evict({ fieldName: 'AggregateGrainFillOrders' });
				cache.evict({ fieldName: 'GrainCropPlan' });
				cache.evict({ fieldName: 'GrainCropPlans' });
				cache.evict({ fieldName: 'AggregateGrainCropPlans' });
				cache.evict({ fieldName: 'GrainFeedPlans' });
				cache.evict({ fieldName: 'GrainFeedPlan' });
				cache.evict({ fieldName: 'AggregateGrainFeedPlans' });
			},
		},
	]);

	get planType() {
		return this.args.fill.Plan?.type;
	}

	get isFeedPlan() {
		return this.planType === TypeOfGrainPlan.Feed;
	}

	get isCropPlan() {
		return this.planType === TypeOfGrainPlan.Crop;
	}

	get disableButton() {
		return this.incorrectSplit ? true : false;
	}

	@tracked _bushels: string | null = null;
	get bushels() {
		return this._bushels ?? this.args.fill.bushels?.toString() ?? null;
	}
	set bushels(bushels) {
		this._bushels = bushels;
	}

	@tracked _futureMonth: string | null = null;
	get futureMonth() {
		return this._futureMonth ?? this.args.fill?.futuresMonth?.slice(0, -3) ?? null;
	}
	set futureMonth(futureMonth) {
		this._futureMonth = futureMonth;
	}

	@tracked _deliveryStartDate: string | null = null;
	get deliveryStartDate() {
		return this._deliveryStartDate ?? this.args.fill.deliveryStartDate ?? null;
	}
	set deliveryStartDate(deliveryStartDate) {
		this._deliveryStartDate = deliveryStartDate;
	}

	@tracked _deliveryEndDate: string | null = null;
	get deliveryEndDate() {
		return this._deliveryEndDate ?? this.args.fill.deliveryEndDate ?? null;
	}
	set deliveryEndDate(deliveryEndDate) {
		this._deliveryEndDate = deliveryEndDate;
	}

	@tracked _salesType: TypeOfGrainOrderSales | null = null;
	get salesType() {
		return this._salesType ? this._salesType : this.args.fill.salesType ?? null;
	}
	set salesType(salesType) {
		this._salesType = salesType;
	}

	@tracked _futurePrice: string | null = null;
	get futurePrice() {
		return this._futurePrice ?? this.args.fill.futurePrice?.toString() ?? null;
	}
	set futurePrice(futurePrice) {
		this._futurePrice = futurePrice;
	}

	@tracked _spread: string | null = null;
	get spread() {
		return this._spread ?? this.args.fill.spread?.toString() ?? null;
	}
	set spread(spread) {
		this._spread = spread;
	}

	@tracked htaFee: string = this.args.fill.htaFee?.toString() || '';

	@tracked rollFee: string = this.args.fill.rollFee?.toString() || '';

	@tracked flexFee: string = this.args.fill.flexFee?.toString() || '';

	@tracked _basis: string | null = null;
	get basis() {
		return this._basis ?? this.args.fill.basis?.toString() ?? null;
	}
	set basis(basis) {
		this._basis = basis;
	}

	@tracked _buyer: Buyer | null = null;
	get buyer() {
		return this._buyer ? this._buyer : this.args.fill.Buyer ?? null;
	}
	set buyer(buyer) {
		this._buyer = buyer;
	}

	@tracked _seller: Seller | null = null;
	get seller() {
		return this._seller ? this._seller : this.args.fill.Seller ?? null;
	}
	set seller(seller) {
		this._seller = seller;
	}

	@tracked _location: GrainLocation | null = null;
	get location() {
		return this._location ? this._location : this.args.fill.Location ?? null;
	}
	set location(location) {
		this._location = location;
	}

	@tracked _contractNumber: string | null = null;
	get contractNumber() {
		return this._contractNumber ?? this.args.fill.contractNumber ?? null;
	}
	set contractNumber(contractNumber) {
		this._contractNumber = contractNumber;
	}

	get disableConfirmAndSubmit(): boolean {
		return this.errorMessage != null || this.isSubmitting;
	}

	get isSubmitting() {
		return this.submit.isRunning;
	}

	@action
	openModal() {
		this.showModal = true;
	}

	@action
	closeModal() {
		this.showModal = false;
		this.errorMessage = null;
		this.showConfirmationModal = false;
	}

	@action
	openConfirmationModal() {
		if (isFormValid(document)) {
			if ((this.args.fill.bushels != null || this.args.fill.bushels != undefined) && (this.bushels != null || this.bushels != undefined)) {
				if (this.args.fill.bushels <= parseFloat(this.bushels)) {
					this.incorrectSplit = true;
					this.errorMessage = 'Incorrect volume amount';
				} else {
					this.incorrectSplit = false;
					this.errorMessage = null;
					this.showConfirmationModal = true;
				}
			}
		} else {
			getInvalidElements(document);
		}
	}

	@action
	closeConfirmationModal() {
		this.showConfirmationModal = false;
		this.showModal = false;
		this.errorMessage = null;
	}

	@action
	confirm() {
		this.submit.perform();
	}

	submit = task({ drop: true }, async () => {
		let variables: SplitGrainFillOrderVars;
		const fillId = this.args.fill.id;

		if (this.args.fill.Plan?.type === TypeOfGrainPlan.Crop) {
			variables = {
				existingFillId: fillId,
				existingFillData: {
					bushels:
						(this.args.fill.bushels != null || this.args.fill.bushels != undefined) && (this.bushels != null || this.bushels != undefined)
							? this.args.fill.bushels - parseFloat(this.bushels)
							: null,
				},
				newFill: {
					basis: this.basis ? parseFloat(this.basis) : null,
					bushels: this.bushels ? parseFloat(this.bushels) : null,
					buyerId: this.buyer?.id ?? null,
					flexFee: this.flexFee ? parseFloat(this.flexFee) : 0,
					futurePrice: this.futurePrice ? parseFloat(this.futurePrice) : null,
					deliveryStartDate: this.deliveryStartDate ? this.deliveryStartDate : null,
					deliveryEndDate: this.deliveryEndDate ? this.deliveryEndDate : null,
					futuresMonth: this.futureMonth ? this.futureMonth + '-01' : null,
					htaFee: this.htaFee ? parseFloat(this.htaFee) : 0,
					locationId: this.location ? this.location.id : undefined,
					rollFee: this.rollFee ? parseFloat(this.rollFee) : 0,
					salesType: this.salesType,
					spread: this.spread ? parseFloat(this.spread) : null,
					targetId: this.args.fill.Target?.id ?? null,
					planId: this.args.fill.Plan.id,
					lastUpdatedBy: this.permissions.userName,
					contractNumber: this.contractNumber,
				},
			};
		} else if (this.args.fill.Plan?.type === TypeOfGrainPlan.Feed) {
			variables = {
				existingFillId: fillId,
				existingFillData: {
					bushels:
						(this.args.fill.bushels != null || this.args.fill.bushels != undefined) && (this.bushels != null || this.bushels != undefined)
							? this.args.fill.bushels - parseFloat(this.bushels)
							: null,
				},
				newFill: {
					basis: this.basis ? parseFloat(this.basis) : null,
					bushels: this.bushels ? parseFloat(this.bushels) : null,
					sellerId: this.seller?.id ?? null,
					flexFee: this.flexFee ? parseFloat(this.flexFee) : null,
					futurePrice: this.futurePrice ? parseFloat(this.futurePrice) : null,
					deliveryStartDate: this.deliveryStartDate ? this.deliveryStartDate : null,
					deliveryEndDate: this.deliveryEndDate ? this.deliveryEndDate : null,
					futuresMonth: this.futureMonth ? this.futureMonth + '-01' : null,
					htaFee: this.htaFee ? parseFloat(this.htaFee) : 0,
					locationId: this.location ? this.location.id : undefined,
					rollFee: this.rollFee ? parseFloat(this.rollFee) : 0,
					salesType: this.salesType,
					spread: this.spread ? parseFloat(this.spread) : 0,
					targetId: this.args.fill.Target?.id ?? null,
					planId: this.args.fill.Plan.id,
					lastUpdatedBy: this.permissions.userName,
					contractNumber: this.contractNumber,
					deliveryTerms: this.deliveryTerms,
				},
			};
		} else {
			console.error('Error: Plan type not found.');
			return;
		}

		await this.splitFillMutation.mutate(variables);

		if (this.splitFillMutation.error) {
			return;
		}

		const newFillId = this.newFillId;

		if (!newFillId) {
			console.error('Error: New fill id not found after creation. Notes not inserted.');
			return;
		}

		const cloneNoteVars = {
			data: {
				fromFillId: fillId,
				toFillId: newFillId,
			},
		};

		await this.createCloneFillNotes.mutate(cloneNoteVars);

		this.closeConfirmationModal();
	});
}
