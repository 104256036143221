import { action } from '@ember/object';
import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import FeedContractsController from 'vault-client/controllers/feed-contracts';
import {
	FeedTransactionFilterDTO,
	FeedTransactionSortByDTO,
	PhysicalFeedTransactionFilterDTO,
	Query,
} from 'vault-client/types/graphql-types';
import { SortObj } from 'vault-client/types/vault-table';
import { generateOrderBy } from 'vault-client/utils/general';

interface QueryParams {
	page: number;
	size: number;
	sorts: SortObj[];
	deliveryStartDateStart: string | null;
	deliveryStartDateEnd: string | null;
	deliveryEndDateStart: string | null;
	deliveryEndDateEnd: string | null;
	ingredientIds: string[] | null;
	customerId: string | null;
	location: string | null;
	seller: string | null;
}

interface GetFeedContractsArgs {
	scopeId: string;
	where?: FeedTransactionFilterDTO;
	offset?: number;
	limit?: number;
	orderBy?: FeedTransactionSortByDTO;
}

interface GetFeedContractsQuery {
	Entity: Query['Entity'];
	FeedIngredients: Query['FeedIngredients'];
	FeedTransactions: Query['FeedTransactions'];
	FeedTransactionsCount: Query['FeedTransactionCount'];
}

const GET_FEED_CONTRACTS = gql`
	query GetFeedContracts(
		$where: FeedTransactionFilterDTO
		$scopeId: String!
		$offset: Float
		$limit: Float
		$orderBy: FeedTransactionSortByDTO
	) {
		Entity(id: $scopeId) {
			id
			isVgs
			CurrentUserPermissions {
				canWriteOperations
			}
		}
		FeedTransactions(scopeId: $scopeId, where: $where, offset: $offset, limit: $limit, orderBy: $orderBy) {
			id
			tons
			seller
			location
			flatPrice
			deliveryStartDate
			deliveryEndDate
			contractIdentifier
			futuresPrice
			basisPrice
			deliveryTerms
			FeedIngredient {
				id
				name
				flatPricePerTon
				cmePercentageBasis
				cmeUsdBasis
				FeedCategory {
					id
					defaultCmePercentageBasis
					defaultCmeUsdBasis
					defaultFlatPricePerTon
				}
				HedgeProduct {
					id
					slug
				}
			}
			Business {
				id
				name
			}
		}
		FeedTransactionsCount: FeedTransactionCount(scopeId: $scopeId, where: $where) {
			count
		}
		FeedIngredients(orderBy: { name: Asc }, scopeId: $scopeId) {
			id
			name
		}
	}
`;

export default class FeedContractsRoute extends Route {
	templateName = 'feed-contracts/index';

	queryParams = {
		page: { refreshModel: true },
		sorts: { refreshModel: true },
		size: { refreshModel: true },
		deliveryStartDateStart: { refreshModel: true },
		deliveryStartDateEnd: { refreshModel: true },
		deliveryEndDateStart: { refreshModel: true },
		deliveryEndDateEnd: { refreshModel: true },
		ingredientIds: { refreshModel: true },
		customerId: { refreshModel: true },
		location: { refreshModel: true },
		seller: { refreshModel: true },
	};

	@tracked variables: GetFeedContractsArgs = {
		scopeId: '',
	};

	scopeId: string | null = null;

	getFeedContracts = useQuery<GetFeedContractsQuery, GetFeedContractsArgs>(this, () => [GET_FEED_CONTRACTS, { variables: this.variables }]);
	@service declare router: RouterService;

	activate(): void {
		this.router.on('routeWillChange', this.handleTransition);
	}

	deactivate(): void {
		this.router.off('routeWillChange', this.handleTransition);
	}

	async model(params: QueryParams) {
		if (!this.scopeId) {
			throw new Error('scopeId not found in Feed Contracts model');
		}

		const where = this.generateWhere(
			params.deliveryStartDateStart,
			params.deliveryStartDateEnd,
			params.deliveryEndDateStart,
			params.deliveryEndDateEnd,
			params.ingredientIds,
			params.customerId,
			params.location,
			params.seller,
		);

		const orderBy = generateOrderBy<FeedTransactionSortByDTO>(params.sorts);

		this.variables = { scopeId: this.scopeId, where, offset: params.page * params.size, limit: params.size, orderBy };

		await this.getFeedContracts.promise;

		return {
			where,
			scopeId: this.scopeId,
			variables: this.variables,
			getFeedContracts: this.getFeedContracts,
			query: GET_FEED_CONTRACTS,
		};
	}

	generateWhere(
		deliveryStartDateStart: string | null,
		deliveryStartDateEnd: string | null,
		deliveryEndDateStart: string | null,
		deliveryEndDateEnd: string | null,
		ingredientIds: string[] | null,
		customerId: string | null,
		location: string | null,
		seller: string | null,
	): PhysicalFeedTransactionFilterDTO {
		const where: PhysicalFeedTransactionFilterDTO = {};

		this.setDateFilter(where, 'deliveryStartDate', deliveryStartDateStart, deliveryStartDateEnd);

		this.setDateFilter(where, 'deliveryEndDate', deliveryEndDateStart, deliveryEndDateEnd);

		if (ingredientIds?.length) {
			where.feedIngredientId = { in: ingredientIds };
		}

		if (customerId) {
			where.businessId = { equals: customerId };
		}

		if (location) {
			where.location = { equals: location };
		}

		if (seller) {
			where.seller = { equals: seller };
		}

		return where;
	}

	private setDateFilter(
		where: PhysicalFeedTransactionFilterDTO,
		key: 'deliveryStartDate' | 'deliveryEndDate',
		startDate: string | null,
		endDate: string | null,
		defaultStart = '1900-01-01',
		defaultEnd = '2999-12-31',
	) {
		if (startDate && startDate !== defaultStart && endDate && endDate !== defaultEnd) {
			where[key] = { gte: startDate, lte: endDate };
		} else if (startDate && startDate !== defaultStart) {
			where[key] = { gte: startDate };
		} else if (endDate && endDate !== defaultEnd) {
			where[key] = { lte: endDate };
		}
	}

	resetController(controller: FeedContractsController, isExiting: boolean): void {
		if (isExiting) {
			controller.closeSidePanel();
		}
	}

	@action
	handleTransition(transition: Transition): void {
		if (transition.to?.name !== this.routeName) {
			// eslint-disable-next-line ember/no-controller-access-in-routes
			const controller = this.controllerFor(this.routeName) as FeedContractsController;
			if (!controller.transition) {
				transition.abort();
				controller.transition = transition;
				controller.showConfirmationIfNeededAndTransitionFn();
			}
		}
	}
}
