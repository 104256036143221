import { gql } from 'glimmer-apollo';
import type { Mutation, Mutation_createCattleLotArgs } from 'vault-client/types/graphql-types';

const UPDATE_CATTLE_LOT = gql`
	mutation UpdateCattleLot($id: String!, $data: CattleLotUpdateDTO!) {
		updateCattleLot(id: $id, data: $data) {
			id
			name
			description
			activeStartDate
			activeEndDate
			numberOfCattle
			startWeightInLb
			targetWeightInLb
			averageDailyWeightGainInLb
			mortalityRate
			purchasePricePerHeadInUsd
			isActive
			cattleLotExpensesType
			cattleLotInputExpensesInUsd
			CattleYard {
				id
				name
			}
			CattleFeedRation {
				id
				name
			}
		}
	}
`;

const CREATE_CATTLE_LOT = gql`
	mutation CreateCattleLot($data: CattleLotCreateDTO!) {
		createCattleLot(data: $data) {
			id
			name
			description
			activeStartDate
			activeEndDate
			numberOfCattle
			startWeightInLb
			targetWeightInLb
			averageDailyWeightGainInLb
			mortalityRate
			purchasePricePerHeadInUsd
			isActive
			cattleLotExpensesType
			cattleLotInputExpensesInUsd
		}
	}
`;

export type CreateCattleLotMutation = {
	createCattleLot: Mutation['createCattleLot'];
};

export type CreateCattleLotMutationVariables = Mutation_createCattleLotArgs;

const DELETE_CATTLE_LOT = gql`
	mutation DeleteCattleLot($id: String!) {
		deleteCattleLot(id: $id) {
			id
		}
	}
`;

export { UPDATE_CATTLE_LOT, DELETE_CATTLE_LOT, CREATE_CATTLE_LOT };
