import Route from '@ember/routing/route';
import { gql, useQuery } from 'glimmer-apollo';
import { ProductGroup, Query_ProductGroupsArgs } from 'vault-client/types/graphql-types';

const GET_PRODUCT_GROUPS = gql`
	query ProductGroups {
		ProductGroups(orderBy: { name: Asc }) {
			Products(orderBy: { name: Asc }, where: { Instruments: { type: { equals: Future } } }) {
				slug
				name
			}
			name
		}
	}
`;
export default class ExposureIndexRoute extends Route {
	templateName = 'exposure/index';

	getProductGroups = useQuery<Array<ProductGroup>, Query_ProductGroupsArgs>(this, () => [GET_PRODUCT_GROUPS, {}]);

	async model() {
		await this.getProductGroups.promise;
		return this.getProductGroups;
	}
}
