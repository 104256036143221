import { gql } from 'glimmer-apollo';
import BaseDeleteModalComponent from './base-delete-modal';
import { PhysicalCropTransaction } from 'vault-client/types/graphql-types';
import { DateTime } from 'luxon';

export default class DeletePhysicalCropTransactionComponent extends BaseDeleteModalComponent {
	mutation = gql`
		mutation deletePhysicalCropTransaction($id: String!) {
			deletePhysicalCropTransaction(id: $id) {
				id
			}
		}
	`;

	evictionFields: string[] = [
		'PhysicalCropTransactions',
		'PhysicalCropTransaction',
		'AggregatePhysicalCropTransactions',
		'CropTransaction',
		'CropTransactions',
		'AggregateCropTransactions',
	];

	get titleText(): string {
		return 'Delete Crop Transaction';
	}

	get bodyText() {
		return 'Are you sure you want to delete this Crop Transaction?';
	}

	get deleteButtonText() {
		return 'Delete Crop Transaction';
	}

	get physicalCropTransaction() {
		return this.args.itemToDelete as PhysicalCropTransaction;
	}

	get label() {
		if (!this.physicalCropTransaction) return '';

		const { deliveryStartDate, deliveryEndDate, contractIdentifier } = this.physicalCropTransaction;

		const formattedStartDate = DateTime.fromISO(deliveryStartDate).toLocaleString(DateTime.DATE_MED);
		const formattedEndDate = DateTime.fromISO(deliveryEndDate).toLocaleString(DateTime.DATE_MED);
		return `Contract ${contractIdentifier}: ${formattedStartDate} to ${formattedEndDate}`;
	}
}
