import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export interface Report {
	name: string;
	description: string;
	routePathRoot: string;
	routePath?: string;
	access?: string;
}

export const UnmappedInsurancePoliciesReport: Report = {
	name: 'Unmapped Insurance Policies',
	description: 'A report of all insurance policies that are not currently mapped to a customer.',
	routePathRoot: 'unmapped-insurance-policies',
	access: 'Internal Only',
};

export const UnmappedAccountsReport: Report = {
	name: 'Unmapped Accounts',
	description: 'A report of all accounts that are not currently mapped to an organization.',
	routePathRoot: 'unmapped-accounts',
	access: 'Internal Only',
};

export const CalendarMonthReport: Report = {
	name: 'P/L by Calendar Month',
	description: 'End of month P/L values, combined with fees and commissions occured during the month.',
	routePathRoot: 'pl-by-calendar-month.index',
	access: 'Internal Only',
};

export const DairyMarginReport: Report = {
	name: 'Dairy Margin',
	description: 'A chart and table that shows the calculated margin against the 25th, 50th, 75th, and 90th percentiles of historical margin',
	routePathRoot: 'dairy-margin',
};

export const DairyHistoricalPercentilesReport: Report = {
	name: 'Dairy Historical Percentiles',
	description: 'A report of visual charts displaying the futures curve vs. historical percentiles',
	routePathRoot: 'dairy-historical-percentiles',
};

export const HistoricalPercentilesByProductReport: Report = {
	name: 'Historical Percentiles by Product',
	description:
		'A table that shows current prices for a product and allows the user to enter hypthetical prices to see the historical percentile of those prices.',
	routePathRoot: 'historical-percentiles-by-product',
};

export const ButterInventoryHedgesReport: Report = {
	name: 'Butter Inventory Hedges',
	description: 'Overview of how much current butter inventory is hedged vs open and in what month it is hedged.',
	routePathRoot: 'inventory-hedges-by-product',
};

export const ButterSimReport: Report = {
	name: 'Strategic Inventory Model (SIM) - Butter ',
	description:
		"A report of potential hedging opportunities, based on the current market prices, historical price ranges, and the organization's carrying cost for butter.",
	routePathRoot: 'butter-strategic-inventory-model',
};

export const ButterSbmReport: Report = {
	name: 'Strategic Buying Model (SBM) - Butter',
	description:
		"A report of potential hedging opportunities, based on the current market prices, historical price ranges, and the organization's carrying cost for butter.",
	routePathRoot: 'butter-strategic-buying-model',
};

export const NonfatDryMilkSbmReport: Report = {
	name: 'Strategic Buying Model (SBM) - Nonfat Dry Milk ',
	description:
		"A report of potential hedging opportunities, based on the current market prices, historical price ranges, and the organization's carrying cost for nonfat dry milk.",
	routePathRoot: 'nfdm-strategic-buying-model',
};

export const ClassIiiSbmReport: Report = {
	name: 'Strategic Buying Model (SBM) - Class III ',
	description:
		"A report of potential hedging opportunities, based on the current market prices, historical price ranges, and the organization's carrying cost for class III milk.",
	routePathRoot: 'class-iii-strategic-buying-model',
};

export const ClassIvSbmReport: Report = {
	name: 'Strategic Buying Model (SBM) - Class IV ',
	description:
		"A report of potential hedging opportunities, based on the current market prices, historical price ranges, and the organization's carrying cost for class IV milk.",
	routePathRoot: 'class-iv-strategic-buying-model',
};

export const DryWheySbmReport: Report = {
	name: 'Strategic Buying Model (SBM) - Dry Whey ',
	description:
		"A report of potential hedging opportunities, based on the current market prices, historical price ranges, and the organization's carrying cost for dry whey.",
	routePathRoot: 'dry-whey-strategic-buying-model',
};

export const CattleHistoricalPercentilesReport: Report = {
	name: 'Cattle Historical Percentiles',
	description: 'A report of visual charts displaying the futures curve vs. historical percentiles',
	routePathRoot: 'cattle-historical-percentiles',
	access: 'Internal Only',
};

export const CheeseSbmReport: Report = {
	name: 'Strategic Buying Model (SBM) - Cheese ',
	description:
		"A report of potential hedging opportunities, based on the current market prices, historical price ranges, and the organization's carrying cost for cheese.",
	routePathRoot: 'cheese-strategic-buying-model',
};

export const CheeseBlockSbmReport: Report = {
	name: 'Strategic Buying Model (SBM) - Cheese Block ',
	description:
		"A report of potential hedging opportunities, based on the current market prices, historical price ranges, and the organization's carrying cost for cheese block.",
	routePathRoot: 'cheese-block-strategic-buying-model',
};

export const DryWheySimReport: Report = {
	name: 'Strategic Inventory Model (SIM) - Dry Whey ',
	description:
		"A report of potential hedging opportunities, based on the current market prices, historical price ranges, and the organization's carrying cost for dry whey.",
	routePathRoot: 'dry-whey-strategic-inventory-model',
};

export const NonfatDryMilkSimReport: Report = {
	name: 'Strategic Inventory Model (SIM) - Nonfat Dry Milk ',
	description:
		"A report of potential hedging opportunities, based on the current market prices, historical price ranges, and the organization's carrying cost for nonfat dry milk.",
	routePathRoot: 'nfdm-strategic-inventory-model',
};

export const CheeseBlockSimReport: Report = {
	name: 'Strategic Inventory Model (SIM) - Cheese Block ',
	description:
		"A report of potential hedging opportunities, based on the current market prices, historical price ranges, and the organization's carrying cost for cheese block.",
	routePathRoot: 'cheese-block-strategic-inventory-model',
};

export const LrpPaymentCalculatorReport: Report = {
	name: 'LRP Payment Calculator',
	description: 'A calculator which shows the profitability of LRP Quotes',
	routePathRoot: 'lrp-calculator',
};

export const DrpPerformanceByPlanYearReport: Report = {
	name: 'DRP Performance by Plan Year',
	description: 'The actual or estimated performance of insurance endorsements, grouped by plan year.',
	routePathRoot: 'drp-performance-by-plan-year',
	access: 'Internal Only',
};

export const DrpPerformancebyAipReport: Report = {
	name: 'DRP Performance by AIP',
	description: 'The actual or estimated performance of insurance endorsements, grouped by Approved Insurance Provider (AIP).',
	routePathRoot: 'drp-performance-by-aip',
	access: 'Internal Only',
};

export const DrpPerformanceBySalesEffectiveDateMonthReport: Report = {
	name: 'DRP Performance by Sales Effective Date Month',
	description: 'The actual or estimated performance of insurance endorsements, grouped by sales effective date month.',
	routePathRoot: 'drp-performance-by-sales-effective-date-month',
	access: 'Internal Only',
};

export const PriceScenariosReport: Report = {
	name: 'Dairy Producer Price Scenarios',
	description: 'A report allowing you to run scenarios (stress test) your combined Net P&L',
	routePathRoot: 'price-scenarios',
};

export default class ReportsIndexController extends Controller {
	@service permissions: any;

	generalRows = [HistoricalPercentilesByProductReport];

	get availableRows() {
		const _rows = [...this.generalRows];
		return _rows;
	}

	get columns() {
		const baseColumns: any[] = [
			{
				id: 'e8183614-d4bd-4997-9857-1fdef3097ffc',
				name: 'Name',
				valuePath: 'name',
				width: 300,
				textAlign: 'left',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
				linkRoutePath: 'routePath',
			},
			{
				id: 'd9386e0d-9f49-4d5a-a423-f8a876ce9573',
				name: 'Description',
				valuePath: 'description',
				width: 950,
				textAlign: 'left',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
		];

		const accessColumn = {
			id: 'c1073e20-252b-4ed1-a2f9-9671d6f6c773',
			name: 'Access',
			valuePath: 'access',
			width: 150,
			textAlign: 'left',
			isSortable: false,
			cellComponent: 'String',
			isFixed: '',
			isReorderable: false,
			isVisible: true,
		};

		if (this.permissions.isInternal) {
			baseColumns.push(accessColumn);
		}
		return baseColumns;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'reports-index-controller': ReportsIndexController;
	}
}
