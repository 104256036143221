import { BusinessEntityRole, type Maybe } from 'vault-client/types/graphql-types';
import { TypeOfLedgerCalculation } from 'vault-client/types/graphql-types';
import type { DynamicUnit, SupportedBusinessRole } from './operations';

function getPerHeadCalculationType(role: Maybe<SupportedBusinessRole>): Maybe<TypeOfLedgerCalculation> {
	switch (role) {
		case BusinessEntityRole.HogProducer:
			return TypeOfLedgerCalculation.SwineHead;
		case BusinessEntityRole.DairyProducer:
			return TypeOfLedgerCalculation.DairyHead;
		case BusinessEntityRole.LiveCattleProducer:
			return TypeOfLedgerCalculation.CattleHead;
		case BusinessEntityRole.FeederCattleProducer:
			return TypeOfLedgerCalculation.CattleHead;
		default:
			return null;
	}
}

function getPerCwtCalculationType(role: Maybe<SupportedBusinessRole>): Maybe<TypeOfLedgerCalculation> {
	switch (role) {
		case BusinessEntityRole.DairyProducer:
			return TypeOfLedgerCalculation.DairyCwt;
		case BusinessEntityRole.LiveCattleProducer:
		case BusinessEntityRole.FeederCattleProducer:
			return TypeOfLedgerCalculation.CattleCwt;
		default:
			return null;
	}
}

export function getLedgerCalculationType(
	role: Maybe<SupportedBusinessRole>,
	dynamicUnit: Maybe<DynamicUnit>,
): Maybe<TypeOfLedgerCalculation> {
	switch (dynamicUnit) {
		case 'Per Head':
			return getPerHeadCalculationType(role);
		case 'Per CWT':
			return getPerCwtCalculationType(role);
		default:
			// If the dynamic unit is not set,  calculation type is static
			return null;
	}
}
