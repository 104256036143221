import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<UiValidatedForm\n\t@id={{@id}}\n\t@onSubmit={{perform this.onSubmit}}\n\t@validate={{validate-valibot this.schema}}\n\t@ignoreNativeValidation={{true}}\n\tclass='flex flex-col gap-4'\n\t...attributes\n\tas |form|\n>\n\t<form.Group @name='rationName' as |group|>\n\t\t<group.Label>Ration Name<sup>&#42;</sup></group.Label>\n\t\t<group.Input placeholder='Enter ration name' aria-required='true' />\n\t\t<group.Errors />\n\t</form.Group>\n\n\t<form.Group @name='rationDetails' as |group|>\n\t\t<group.Label>Notes</group.Label>\n\t\t<group.Textarea />\n\t\t<group.Errors />\n\t</form.Group>\n</UiValidatedForm>", {"contents":"<UiValidatedForm\n\t@id={{@id}}\n\t@onSubmit={{perform this.onSubmit}}\n\t@validate={{validate-valibot this.schema}}\n\t@ignoreNativeValidation={{true}}\n\tclass='flex flex-col gap-4'\n\t...attributes\n\tas |form|\n>\n\t<form.Group @name='rationName' as |group|>\n\t\t<group.Label>Ration Name<sup>&#42;</sup></group.Label>\n\t\t<group.Input placeholder='Enter ration name' aria-required='true' />\n\t\t<group.Errors />\n\t</form.Group>\n\n\t<form.Group @name='rationDetails' as |group|>\n\t\t<group.Label>Notes</group.Label>\n\t\t<group.Textarea />\n\t\t<group.Errors />\n\t</form.Group>\n</UiValidatedForm>","moduleName":"vault-client/components/cattle/rations/create-ration-form.hbs","parseOptions":{"srcName":"vault-client/components/cattle/rations/create-ration-form.hbs"}});
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { FormError } from 'vault-client/utils/forms/form-error';
import { ERRORS } from 'vault-client/utils/cattle/errors';
import { type InferOutput, minLength, object, optional, pipe, string } from 'valibot';
import { useCreateRationMutation } from 'vault-client/utils/cattle/rations/use-create-ration-mutation';
import type { CreateRationData, CattleFeedRationCreateResponse } from 'vault-client/types/cattle/ration';

export type CreateRationFormData = InferOutput<typeof CreateRationFormSchema>;
type CreateRationFormSchema = typeof CreateRationFormSchema;

const CreateRationFormSchema = object({
	rationName: pipe(string(), minLength(1, 'Ration Name is required')),
	rationDetails: optional(string()),
});

const Serializer = {
	deserialize({ rationName, rationDetails }: CreateRationFormData, businessId: string): { data: CreateRationData } {
		return {
			data: {
				businessId: businessId,
				name: rationName,
				description: rationDetails || '',
			},
		};
	},
};

const RELATED_FIELD_NAMES = ['CattleFeedRations', 'CattleFeedRationCount', 'CattleFeedRationStageIngredients'] as const;

interface CreateRationFormArgs {
	id: string;
	businessId: string;
	onSuccess?: (result: NonNullable<CattleFeedRationCreateResponse>) => void;
}

export default class CreateRationForm extends Component<CreateRationFormArgs> {
	schema = CreateRationFormSchema;
	serializer = Serializer;
	createRationMutation = useCreateRationMutation(this, {
		update: (cache) => {
			RELATED_FIELD_NAMES.forEach((fieldName) => {
				cache.evict({ fieldName });
			});
			cache.gc();
		},
		onError: function () {
			throw new FormError([ERRORS.CREATE_CATTLE_RATION.DEFAULT]);
		},
	});

	onSubmit = task({ drop: true }, async (formData: CreateRationFormData) => {
		const mutationArgs = this.serializer.deserialize(formData, this.args.businessId);
		const result = await this.createRationMutation.mutate(mutationArgs);
		if (this.args.onSuccess && result) {
			this.args.onSuccess(result);
		}
	});
}
