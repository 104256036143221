import { gql } from '@apollo/client/core';

export const CREATE_CATTLE_FEED_RATION_STAGE_INGREDIENT = gql`
	mutation CreateCattleFeedRationStageIngredient($data: CattleFeedRationStageIngredientCreateDTO!) {
		createCattleFeedRationStageIngredient(data: $data) {
			id
			volumeInWetLbPerHeadPerDay
		}
	}
`;

export const UPDATE_CATTLE_FEED_RATION_STAGE_INGREDIENT = gql`
	mutation UpdateCattleFeedRationStageIngredient($id: String!, $data: CattleFeedRationStageIngredientUpdateDTO!) {
		updateCattleFeedRationStageIngredient(id: $id, data: $data) {
			id
		}
	}
`;

export const DELETE_CATTLE_FEED_RATION_STAGE_INGREDIENT = gql`
	mutation DeleteCattleFeedRationStageIngredient($id: String!) {
		deleteCattleFeedRationStageIngredient(id: $id) {
			id
		}
	}
`;
