import { useMutation, type MutationOptions } from 'glimmer-apollo';
import { DELETE_CATTLE_FEED_RATION_STAGE_INGREDIENT } from 'vault-client/graphql/mutations/cattle/cattle-feed-ration-stage-ingredient';
import type { Mutation, Mutation_deleteCattleFeedRationStageIngredientArgs } from 'vault-client/types/graphql-types';
import { FIELD_NAMES } from 'vault-client/utils/cattle';

const defaultOptions: MutationOptions<
	Mutation['deleteCattleFeedRationStageIngredient'],
	Mutation_deleteCattleFeedRationStageIngredientArgs
> = {
	update: (cache) => {
		FIELD_NAMES.forEach((fieldName) => {
			cache.evict({ fieldName });
		});
		cache.gc();
	},
};

export function useDeleteCattleFeedRationStageIngredientMutation(
	context: object,
	options?: MutationOptions<Mutation['deleteCattleFeedRationStageIngredient'], Mutation_deleteCattleFeedRationStageIngredientArgs>,
) {
	return useMutation<Mutation['deleteCattleFeedRationStageIngredient'], Mutation_deleteCattleFeedRationStageIngredientArgs>(context, () => [
		DELETE_CATTLE_FEED_RATION_STAGE_INGREDIENT,
		{
			...defaultOptions,
			...options,
		},
	]);
}
