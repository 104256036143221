import { gql } from 'glimmer-apollo';

const CATTLE_YARD_OPTION_LIST_QUERY = gql`
	query CattleYardsOptionList($scopeId: String) {
		CattleYards(scopeId: $scopeId) {
			id
			name
		}
	}
`;

const CATTLE_RATION_OPTION_LIST_QUERY = gql`
	query CattleRationsOptionList($scopeId: String) {
		CattleFeedRations(scopeId: $scopeId) {
			id
			name
		}
	}
`;

const CATTLE_RATION_INGREDIENTS_LIST_QUERY = gql`
	query CattleRationIngredientsList($where: CattleFeedRationStageIngredientFilterDTO) {
		CattleFeedRationStageIngredients(where: $where) {
			id
			FeedIngredient {
				id
				name
			}
		}
	}
`;

export { CATTLE_RATION_OPTION_LIST_QUERY, CATTLE_RATION_INGREDIENTS_LIST_QUERY, CATTLE_YARD_OPTION_LIST_QUERY };
