import { gql } from 'glimmer-apollo';

export const CREATE_CATTLE_YARD = gql`
	mutation CreateCattleYard($data: CattleYardCreateDTO!) {
		createCattleYard(data: $data) {
			id
			name
			description
		}
	}
`;

export const UPDATE_CATTLE_YARD = gql`
	mutation updateCattleYard($id: String!, $data: CattleYardUpdateDTO!) {
		updateCattleYard(id: $id, data: $data) {
			id
			name
			description
		}
	}
`;

export const DELETE_CATTLE_YARD = gql`
	mutation deleteCattleYard($id: String!) {
		deleteCattleYard(id: $id) {
			id
		}
	}
`;
