import Route from '@ember/routing/route';
import { DateTime } from 'luxon';
import {
	DerivedDrpEndorsementFilterDTO,
	ForecastedMilkProductionByMonthFilterDTO,
	InsuranceEndorsementAllocationRatioFilterDTO,
	Query,
	SwineLivestockPopulationChangeFilterDTO,
	TypeOfLivestockPopulationChangeReason,
} from 'vault-client/types/graphql-types';
import query from 'vault-client/graphql/queries/businesses/business/insurance-overview.graphql';
import { queryManager } from 'ember-apollo-client';
import { ModelFrom } from 'vault-client/utils/type-utils';
import BusinessesBusinessRoute from 'vault-client/routes/businesses/business';
import { formatISO, subWeeks, startOfWeek, parseISO } from 'date-fns';
import ENV from 'vault-client/config/environment';

type BusinessesBusinessInsuranceOverviewQuery = {
	DerivedDrpEndorsements: Query['DerivedDrpEndorsements'];
	AggregateDerivedDrpEndorsements: Query['AggregateDerivedDrpEndorsements'];
	AggregateForecastedMilkProductionByMonths: Query['AggregateForecastedMilkProductionByMonths'];
	InsuranceEndorsementAllocationRatios: Query['InsuranceEndorsementAllocationRatios'];
	SwinePurchasesAndProduced: Query['AggregateSwineLivestockPopulationChanges'];
};
export default class BusinessesBusinessInsuranceOverviewRoute extends Route {
	@queryManager apollo: any;

	generateProductionWhere(quarterStartDate: string | null, quarterEndDate: string | null): ForecastedMilkProductionByMonthFilterDTO {
		const productionWhere: ForecastedMilkProductionByMonthFilterDTO = {};

		if (quarterStartDate && quarterEndDate) {
			productionWhere.date = {
				gte: DateTime.fromISO(quarterStartDate).startOf('quarter').toISODate(),
				lte: DateTime.fromISO(quarterEndDate).endOf('quarter').toISODate(),
			};
		} else if (quarterStartDate) {
			productionWhere.date = {
				gte: DateTime.fromISO(quarterStartDate).startOf('quarter').toISODate(),
			};
		} else if (quarterEndDate) {
			productionWhere.date = {
				lte: DateTime.fromISO(quarterEndDate).endOf('quarter').toISODate(),
			};
		}

		return productionWhere;
	}

	generateEndorsementWhere(quarterStartDate: string | null, quarterEndDate: string | null): DerivedDrpEndorsementFilterDTO {
		const endorsementWhere: DerivedDrpEndorsementFilterDTO = {};

		if (quarterStartDate && quarterEndDate) {
			endorsementWhere.quarterEndDate = {
				gte: quarterStartDate,
				lte: DateTime.fromISO(quarterEndDate).endOf('quarter').toISODate(),
			};
		} else if (quarterStartDate) {
			endorsementWhere.quarterEndDate = { gte: quarterStartDate };
		} else if (quarterEndDate) {
			endorsementWhere.quarterEndDate = { lte: quarterEndDate };
		}

		return endorsementWhere;
	}

	generateLGMEndorsementWhere(startDate: string | null, endDate: string | null) {
		const where: InsuranceEndorsementAllocationRatioFilterDTO = {
			InsuranceEndorsement: {
				AsLgmInsuranceEndorsement: {
					id: {
						not: null,
					},
					Product: {
						slug: {
							in: ['livestock-lean-hogs', 'us-dairy-class-iii'],
						},
					},
				},
			},
			effectiveHedgeDate: {
				gte: startDate,
				lte: endDate,
			},
		};

		return where;
	}

	generateSwinePurchasesAndProducedWhere(
		customerId: string,
		startDate: string,
		endDate: string,
		averageFinishAgeInWeeks: number,
	): SwineLivestockPopulationChangeFilterDTO {
		return {
			businessId: { equals: customerId },
			reasonType: {
				in: [TypeOfLivestockPopulationChangeReason.Purchase, TypeOfLivestockPopulationChangeReason.Birth],
			},
			dob: {
				gte: formatISO(subWeeks(startOfWeek(parseISO(startDate), { weekStartsOn: 0 }), averageFinishAgeInWeeks), {
					representation: 'date',
				}),
				lte: formatISO(subWeeks(startOfWeek(parseISO(endDate), { weekStartsOn: 0 }), averageFinishAgeInWeeks), {
					representation: 'date',
				}),
			},
		};
	}

	async model() {
		const businessParams = this.paramsFor('businesses.business') as { business_id: string };
		const customer = (this.modelFor('businesses.business') as ModelFrom<BusinessesBusinessRoute>)?.getCustomer.data?.Customer;
		const averageFinishAgeInWeeks = customer?.averageFinishAgeInWeeks ?? (ENV.APP.DEFAULT_AVG_SWINE_FINISH_AGE_IN_WEEKS as number);
		const quarterStartDate: string = DateTime.local().minus({ days: 60 }).startOf('quarter').toISODate();
		const quarterEndDate: string = '2999-12-31';
		const lgmStartDate = DateTime.local().minus({ month: 1 }).startOf('month').toISODate();
		const lgmEndDate = DateTime.local().plus({ month: 11 }).endOf('month').toISODate();

		const variables = {
			productionWhere: this.generateProductionWhere(quarterStartDate, quarterEndDate),
			endorsementWhere: this.generateEndorsementWhere(quarterStartDate, quarterEndDate),
			lgmEndorsementsWhere: this.generateLGMEndorsementWhere(lgmStartDate, lgmEndDate),
			swinePurchasesAndProducedWhere: this.generateSwinePurchasesAndProducedWhere(
				businessParams.business_id,
				lgmStartDate,
				lgmEndDate,
				averageFinishAgeInWeeks,
			),
			customerId: businessParams.business_id,
		};

		const resp = (await this.apollo.watchQuery({ query, variables, fetchPolicy: 'no-cache' })) as BusinessesBusinessInsuranceOverviewQuery;

		return {
			...resp,
			Customer: customer,
			lgmStartDate,
			lgmEndDate,
		};
	}
}
