import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Vault::UiDateFilter\n\t@dateRangeOptions={{this.monthDateRangeOptions}}\n\t@currentValue={{this.currentValue}}\n\t@onChange={{this.setDateQueryParam}}\n\t@ariaLabel={{@ariaLabel}}\n\t@uid={{@uid}}\n\t@label={{@label}}\n/>", {"contents":"<Vault::UiDateFilter\n\t@dateRangeOptions={{this.monthDateRangeOptions}}\n\t@currentValue={{this.currentValue}}\n\t@onChange={{this.setDateQueryParam}}\n\t@ariaLabel={{@ariaLabel}}\n\t@uid={{@uid}}\n\t@label={{@label}}\n/>","moduleName":"vault-client/components/cattle/lots/lots-date-filter.hbs","parseOptions":{"srcName":"vault-client/components/cattle/lots/lots-date-filter.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { DateTime } from 'luxon';

interface LotsDateFilterSignature {
	Args: {
		currentValue: { startDate: string; endDate: string };
		onChange: (value: { startDate: string; endDate: string }) => void;
		label: string;
		uid: string;
		ariaLabel: string;
	};
}

export default class LotsDateFilterComponent extends Component<LotsDateFilterSignature> {
	defaultStartDate = '1900-01-01';
	defaultEndDate = '2999-12-31';

	get currentValue() {
		return {
			startDate: this.args.currentValue?.startDate ?? this.defaultStartDate,
			endDate: this.args.currentValue?.endDate ?? this.defaultEndDate,
		};
	}

	get monthDateRangeOptions() {
		return [
			{
				displayName: 'All Dates',
				startDate: this.defaultStartDate,
				endDate: this.defaultEndDate,
			},
			{
				displayName: 'Current Month',
				startDate: DateTime.local().startOf('month').toISODate(),
				endDate: DateTime.local().endOf('month').toISODate(),
			},
			{
				displayName: 'All Future Dates',
				startDate: DateTime.now().toISODate(),
				endDate: this.defaultEndDate,
			},
			{
				displayName: 'All Past Dates',
				startDate: this.defaultStartDate,
				endDate: DateTime.now().toISODate(),
			},
			{
				displayName: 'Next 3 Months',
				startDate: DateTime.local().startOf('month').toISODate(),
				endDate: DateTime.local().plus({ months: 2 }).endOf('month').toISODate(),
			},
			{
				displayName: 'Next 6 Months',
				startDate: DateTime.local().startOf('month').toISODate(),
				endDate: DateTime.local().plus({ months: 5 }).endOf('month').toISODate(),
			},
			{
				displayName: 'Next 12 Months',
				startDate: DateTime.local().startOf('month').toISODate(),
				endDate: DateTime.local().plus({ months: 11 }).endOf('month').toISODate(),
			},
		];
	}

	@action
	setDateQueryParam(option: { startDate: string; endDate: string } | null) {
		this.args.onChange({
			startDate: option?.startDate ?? this.defaultStartDate,
			endDate: option?.endDate ?? this.defaultEndDate,
		});
	}
}
