import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { gql, QueryResource, useQuery } from 'glimmer-apollo';
import { DateTime } from 'luxon';
import { Query, Query_CropFieldLedgerCategoriesArgs, CropFieldLedgerCategory, Maybe } from 'vault-client/types/graphql-types';
import BusinessesBusinessRoute from '../business';
import { ModelFrom } from 'vault-client/utils/type-utils';
import CropDetailController from 'vault-client/controllers/businesses/business/crop-detail';
import ENV from 'vault-client/config/environment';
import MarketDataService from 'vault-client/services/market-data';
import { service } from '@ember/service';
import { OperationVariables } from '@apollo/client/core/types';

interface QueryParams {
	crop_id: string;
	startDate: string;
	endDate: string;
}

const GET_CROP_DETAIL = gql`
	query CropDetail($scopeId: String!, $cropId: String!, $startDate: String!, $endDate: String!, $harvestYear: Float!) {
		Crop(id: $cropId) {
			id
			name
			pricingMethodology
			price
			hedgeProductId
			CropPrices {
				id
				price
				pricingMethodology
				harvestYear
				harvestYearStartDate
			}
			Category {
				id
				name
				HedgeProduct {
					id
					slug
					MostCurrentFuture {
						id
						displayExpiresAt
						barchartSymbol
						SymbolGroup {
							id
							displayFactor
							fractionDigits
						}
					}
					StandardProductLotSpecification {
						id
						lotSize
						pointValue
					}
				}
			}
			RevenuesForHarvestYear(harvestYear: $harvestYear) {
				harvestYear
				totalUsdFromCropFlatValues
				totalUsdFromCropPerAcreValues
				totalUsdFromFieldFlatValues
				totalUsdFromFieldPerAcreValues
			}
			ExpensesForHarvestYear(harvestYear: $harvestYear) {
				harvestYear
				totalUsdFromCropFlatValues
				totalUsdFromCropPerAcreValues
				totalUsdFromFieldFlatValues
				totalUsdFromFieldPerAcreValues
			}
		}
		Fields(scopeId: $scopeId, sortBy: [{ name: Asc }]) {
			id
			name
			acres
		}
		CropHarvestYears(where: { cropId: { equals: $cropId }, harvestYear: { equals: $harvestYear } }) {
			id
			ContractMonthInstrument {
				id
				displayExpiresAt
				barchartSymbol
				SymbolGroup {
					id
					displayFactor
					fractionDigits
				}
			}
		}
		CropHarvests(
			where: { cropId: { equals: $cropId }, forecastedHarvestDate: { gte: $startDate, lte: $endDate } }
			sortBy: [{ forecastedHarvestDate: Asc }]
		) {
			fieldId
			cropId
			acres
			forecastedHarvestDate
			yieldPerAcre
			Field {
				id
				name
			}
		}
		CropTransactions(scopeId: $scopeId, where: { cropId: { equals: $cropId }, harvestYear: { equals: $harvestYear } }) {
			id
			bushels
			pricingType
			contractIdentifier
			futuresMonthStartDate
			futuresPrice
			basisPrice
			flatPrice
			deliveryStartDate
			deliveryEndDate
			buyer
			location
			salesDate
			isManuallyAdded
			salesDate
			Crop {
				Category {
					HedgeProduct {
						id
						slug
						MostCurrentFuture {
							barchartSymbol
						}
					}
				}
			}
		}
		CropHarvestedAndSoldVolumes(scopeId: $scopeId, where: { cropId: { equals: $cropId }, startDate: $startDate, endDate: $endDate }) {
			id
			harvestYearStartDate
			forecastedProductionInBu
			volumeSoldInBu
			volumeSoldInBu
		}
		GrainTargetOrders(
			scopeId: $scopeId
			where: {
				Plan: { AsGrainCropPlan: { CropCategory: { Crop: { id: { equals: $cropId } } }, CropYear: { year: { equals: $harvestYear } } } }
				status: { notIn: [Cancelled, PendingCancellation, Filled] }
			}
		) {
			id
			salesType
			contractNumber
			quantity
			quantityUnitType
			futurePrice
			basis
			cropFlatPrice
			deliveryStartDate
			deliveryEndDate
			Buyer {
				id
				name
			}
			Location {
				id
				name
			}
		}
		CropLedgerEntriesPerHarvestYear(
			scopeId: $scopeId
			where: { cropId: { equals: $cropId }, harvestYearStartDate: { gte: $startDate, lte: $endDate } }
		) {
			id
			cropFieldLedgerType
			description
			flatValueInUsd
			harvestYear
			harvestYearStartDate
			perAcreValueInUsd
			CropFieldLedgerCategory {
				id
				name
				type
				description
				CropLedgerEntriesPerHarvestYear(where: { cropId: { equals: $cropId } }) {
					id
					harvestYear
					flatValueInUsd
					cropFieldLedgerType
					perAcreValueInUsd
				}
			}
		}
		CropAndFieldLedgerTotalPerHarvestYears(
			scopeId: $scopeId
			where: { harvestYearStartDate: { gte: $startDate, lte: $endDate }, cropId: { equals: $cropId } }
		) {
			id
			fieldTotalInUsd
			CropFieldLedgerCategory {
				id
				name
				type
				CropLedgerEntriesPerHarvestYear(where: { harvestYearStartDate: { gte: $startDate, lte: $endDate }, cropId: { equals: $cropId } }) {
					id
					harvestYear
					cropId
					flatValueInUsd
					perAcreValueInUsd
				}
			}
		}
	}
`;

const GET_FIELD_LEDGER_CATEGORIES = gql`
	query CropFieldLedgerCategories(
		$ledgerCategoryWhere: CropFieldLedgerCategoryFilterDTO
		$ledgerCategoryOrderBy: CropFieldLedgerCategorySortByDTO
	) {
		CropFieldLedgerCategories(where: $ledgerCategoryWhere, orderBy: $ledgerCategoryOrderBy) {
			id
			name
			description
			type
			businessId
		}
	}
`;

const GET_BUSINESSES = gql`
	query Customers {
		Customers(where: { businessRoles: { contains: GrainProducer } }, sortBy: [{ name: Asc }]) {
			id
			name
			hasWriteAccess
			businessRoles
		}
	}
`;

export type GetCropDetailQuery = {
	__typename?: 'Query';
	Fields: Query['Fields'];
	Crop: Query['Crop'];
	CropHarvests: Query['CropHarvests'];
	GrainTargetOrders: Query['GrainTargetOrders'];
	CropTransactions: Query['CropTransactions'];
	CropLedgerEntriesPerHarvestYear: Query['CropLedgerEntriesPerHarvestYear'];
	CropAndFieldLedgerTotalPerHarvestYears: Query['CropAndFieldLedgerTotalPerHarvestYears'];
	CropHarvestedAndSoldVolumes: Query['CropHarvestedAndSoldVolumes'];
	CropHarvestYears: Query['CropHarvestYears'];
	Customers: Query['Customers'];
};

export type GetCropDetailQueryArgs = {
	scopeId: string;
	cropId: string;
	startDate: string;
	endDate: string;
	harvestYear: number | null;
};
export interface LedgerCategoryQuery {
	CropFieldLedgerCategories: CropFieldLedgerCategory[];
}

interface CustomersQuery {
	Customers: Query['Customers'];
}
interface LedgerCategoryArgs {
	ledgerCategoryWhere: Query_CropFieldLedgerCategoriesArgs['where'];
}

export default class BusinessesBusinessCropDetailRoute extends Route {
	queryParams = {
		startDate: { refreshModel: true },
		endDate: { refreshModel: true },
	};

	@tracked _variables: GetCropDetailQueryArgs = {
		cropId: '',
		scopeId: '',
		startDate: '',
		endDate: '',
		harvestYear: null,
	};
	@tracked ledgerCategoryWhere = { businessId: { equals: '' } };
	@tracked ledgerCategoryOrderBy = { name: 'Asc' };

	@service declare marketData: MarketDataService;

	registeredInstruments: string[] = [];

	getLedgerCategories = useQuery<LedgerCategoryQuery, LedgerCategoryArgs>(this, () => [
		GET_FIELD_LEDGER_CATEGORIES,
		{ variables: { ledgerCategoryWhere: this.ledgerCategoryWhere, ledgerCategoryOrderBy: this.ledgerCategoryOrderBy } },
	]);

	async model(params: QueryParams) {
		const { businessId, getCustomer } = this.modelFor('businesses.business') as ModelFrom<BusinessesBusinessRoute>;
		const getCropDetail = useQuery<GetCropDetailQuery, GetCropDetailQueryArgs>(this, () => [
			GET_CROP_DETAIL,
			{ variables: this._variables, fetchPolicy: 'network-only' },
		]);

		const getBusinesses = useQuery<CustomersQuery>(this, () => [GET_BUSINESSES]);

		const startDate = params.startDate ?? DateTime.now().startOf('year').toISODate();
		const endDate = params.endDate ?? DateTime.now().endOf('year').toISODate();
		const harvestYear = DateTime.fromISO(startDate).startOf('year').year;

		this._variables = {
			cropId: params.crop_id,
			scopeId: businessId,
			startDate,
			endDate,
			harvestYear,
		};

		this.ledgerCategoryWhere = {
			businessId: {
				equals: businessId,
			},
		};
		await getCropDetail.promise;
		await getBusinesses.promise;
		await this.getLedgerCategories.promise;

		const responseObj: {
			cropDetail: QueryResource<GetCropDetailQuery, GetCropDetailQueryArgs>;
			businessId: string;
			ledgerCategories: QueryResource<LedgerCategoryQuery, LedgerCategoryArgs>;
			fetchBusinesses: QueryResource<CustomersQuery, OperationVariables>;
			harvestYear: number;
			minTradingValue?: string;
			maxTradingValue?: string;
			getCustomer: ModelFrom<BusinessesBusinessRoute>['getCustomer'];
		} = {
			cropDetail: getCropDetail,
			businessId: businessId,
			getCustomer,
			ledgerCategories: this.getLedgerCategories,
			fetchBusinesses: getBusinesses,
			harvestYear,
		};

		if (getCropDetail.data?.Crop?.Category?.HedgeProduct?.MostCurrentFuture?.barchartSymbol) {
			const instrumentParams = new URLSearchParams({
				apikey: ENV.barchartApiToken,
				symbol: getCropDetail.data?.Crop?.Category?.HedgeProduct?.MostCurrentFuture?.barchartSymbol ?? '',
				startDate: DateTime.fromISO(startDate).minus({ months: 1 }).toISODate(),
				endDate: DateTime.fromISO(endDate).plus({ months: 1 }).toISODate(),
				type: 'daily',
			});

			const instrumentHistoricalDataPromise = fetch('https://ondemand.websol.barchart.com/getHistory.json?' + instrumentParams.toString())
				.then((res) => res.json())
				.then((json) => json.results);

			const [instrumentHistoricalData] = await Promise.all([instrumentHistoricalDataPromise]);

			const maxValue = instrumentHistoricalData.reduce((prev: any, current: any) => {
				return prev.high > current.high ? prev : current;
			});
			const minValue = instrumentHistoricalData.reduce((prev: any, current: any) => {
				return prev.low < current.low ? prev : current;
			});

			if (minValue && maxValue) {
				responseObj['minTradingValue'] = Number(minValue.low).toFixed(2);
				responseObj['maxTradingValue'] = Number(maxValue.low).toFixed(2);
			}
		}
		return responseObj;
	}

	afterModel(resolvedModel: ModelFrom<BusinessesBusinessCropDetailRoute>): Promise<unknown> | void {
		this.unregisterAllInstruments();
		this.registerInstrumentIfSymbolPresent(
			this.getHarvestYearFutureBarchartSymbol(resolvedModel) ?? this.getMostCurrentFutureBarchartSymbol(resolvedModel),
		);
	}

	deactivate(): void {
		this.unregisterAllInstruments();
	}

	resetController(controller: CropDetailController, isExiting: boolean) {
		if (isExiting) {
			controller.closeSidePanel();
		}
	}

	getMostCurrentFutureBarchartSymbol(model: ModelFrom<BusinessesBusinessCropDetailRoute>): Maybe<string> | undefined {
		return model.cropDetail.data?.Crop?.Category.HedgeProduct?.MostCurrentFuture?.barchartSymbol;
	}

	getHarvestYearFutureBarchartSymbol(model: ModelFrom<BusinessesBusinessCropDetailRoute>): Maybe<string> | undefined {
		return model.cropDetail.data?.CropHarvestYears[0]?.ContractMonthInstrument?.barchartSymbol;
	}

	registerInstrumentIfSymbolPresent(barchartSymbol?: Maybe<string>) {
		if (!barchartSymbol || barchartSymbol.length === 0) return;
		this.marketData.register(barchartSymbol);
		this.registeredInstruments.push(barchartSymbol);
	}

	unregisterAllInstruments() {
		this.registeredInstruments.forEach((barchartSymbol) => {
			this.marketData.unregister(barchartSymbol);
		});
		this.registeredInstruments = [];
	}
}
