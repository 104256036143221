import { MutationOptions, useMutation } from 'glimmer-apollo';
import type { CreateRationData, CattleFeedRationCreateResponse } from 'vault-client/types/cattle/ration';
import { CREATE_RATION } from 'vault-client/graphql/mutations/cattle/rations';

export const defaultOptions: MutationOptions<CattleFeedRationCreateResponse, { data: CreateRationData }> = {
	onError: (error) => {
		console.error('Failed to create ration:', error);
	},
};

export function useCreateRationMutation(
	context: object,
	options?: MutationOptions<CattleFeedRationCreateResponse, { data: CreateRationData }>,
) {
	return useMutation<CattleFeedRationCreateResponse, { data: CreateRationData }>(context, () => [
		CREATE_RATION,
		{
			...defaultOptions,
			...options,
		},
	]);
}
