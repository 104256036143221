import { assert } from '@ember/debug';
import { task } from 'ember-concurrency';
import BaseDeleteModalComponent from 'vault-client/components/base-delete-modal';
import { CattleFeedRationStage } from 'vault-client/types/graphql-types';
import { ERRORS } from 'vault-client/utils/cattle/errors';
import { useDeleteCattleFeedRationStageMutation } from 'vault-client/utils/cattle/rations/stages/delete-cattle-feed-ration-stage';

export interface DeleteCattleFeedRationStageModalArgs {
	Args: {
		stage: Pick<CattleFeedRationStage, 'id' | 'name'> | null;
		onSuccess: () => void;
		closeConfirmation: () => void;
	};
	Blocks: {
		default: [];
	};
	Element: null;
}

export default class DeleteCattleFeedRationStageModal extends BaseDeleteModalComponent<DeleteCattleFeedRationStageModalArgs['Args']> {
	deleteItem = task({ drop: true }, async () => {
		const id = this.id;

		assert('No id provided to Delete Cattle Feed Ration Stage Component', id);

		await useDeleteCattleFeedRationStageMutation(this, {
			onError: () => {
				this.errorMessage = ERRORS.DELETE_CATTLE_FEED_RATION_STAGE.DEFAULT;
			},
			onComplete: () => {
				this.closeConfirmation();

				if (this.args.onSuccess) {
					this.args.onSuccess();
				}

				if (this.args.redirectRoutePath) {
					this.redirect();
				}
			},
		}).mutate({ id });
	});

	get id() {
		return this.args.stage?.id;
	}

	get titleText() {
		return 'Delete Stage';
	}

	get bodyText() {
		return 'Are you sure you want to delete this stage?';
	}

	get cancelButtonText() {
		return 'Cancel';
	}

	get deleteButtonText() {
		return 'Delete';
	}

	get label(): string {
		return this.args.stage?.name ?? '';
	}
}
