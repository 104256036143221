import { isEmpty } from '@ember/utils';

/**
 * Converts an empty argument to null.
 * isEmpty is used to check if the argument is an empty
 *
 * @param value - The value to convert.
 * @returns The value or null if it is empty.
 */
export function emptyToNull<TArg>(value: TArg): TArg | null {
	return isEmpty(value) ? null : value;
}
