import Controller from '@ember/controller';
import { TableColumn, CellComponents } from 'vault-client/types/vault-table';

export default class OrganizationsOrganizationMarketingController extends Controller {
	cropPlansRoute = 'organizations.organization.crop-plans';
	cropTargetsRoute = 'organizations.organization.crop-targets';
	cropFillsRoute = 'organizations.organization.crop-fills';

	get columns(): TableColumn[] {
		return [
			{
				id: '8980c5b8-6fc4-4062-9fc1-e58ac351aa71',
				name: 'Marketing Views',
				valuePath: 'name',
				cellComponent: CellComponents.String,
				linkRoutePath: 'route',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				textAlign: 'left',
			},
		];
	}

	get rows() {
		return [
			{
				name: 'Crop Targets',
				route: this.cropTargetsRoute,
			},
			{
				name: 'Crop Contracts',
				route: this.cropFillsRoute,
			},
		];
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/marketing': OrganizationsOrganizationMarketingController;
	}
}
