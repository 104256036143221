import { MutationOptions, useMutation } from 'glimmer-apollo';
import type { Mutation } from 'vault-client/types/graphql-types';
import type { UpdateRationData } from 'vault-client/types/cattle/ration';
import { UPDATE_RATION } from 'vault-client/graphql/mutations/cattle/rations';

// Testing the basic configuration and return value
// Verifying that custom options can be merged with defaults
// Ensuring error handling works correctly
// This same pattern could be reused for testing other similar mutation hooks in the codebase. The key differences would just be:
// The specific mutation types
// The error messages
// 	Any custom options specific to that mutation

export const defaultOptions: MutationOptions<Mutation['updateCattleFeedRation'], { id: string; data: UpdateRationData }> = {
	onError: (error) => {
		console.error('Failed to update ration:', error);
	},
};

export function useUpdateRationMutation(
	context: object,
	options?: MutationOptions<Mutation['updateCattleFeedRation'], { id: string; data: UpdateRationData }>,
) {
	return useMutation<Mutation['updateCattleFeedRation'], { id: string; data: UpdateRationData }>(context, () => [
		UPDATE_RATION,
		{
			...defaultOptions,
			...options,
		},
	]);
}
