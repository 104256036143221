import { DateTime } from 'luxon';
import { MonthSymbol } from 'vault-client/types/graphql-types';

export function monthSymbolToMonthNumber(monthSymbol: MonthSymbol) {
	const map = [
		MonthSymbol.January,
		MonthSymbol.February,
		MonthSymbol.March,
		MonthSymbol.April,
		MonthSymbol.May,
		MonthSymbol.June,
		MonthSymbol.July,
		MonthSymbol.August,
		MonthSymbol.September,
		MonthSymbol.October,
		MonthSymbol.November,
		MonthSymbol.December,
	];

	return map.findIndex((month) => month === monthSymbol);
}

export function getFirstDaysOfMonthsBetween(
  startDate: DateTime,
  endDate: DateTime
): DateTime[] {
  // Ensure we're working with the first day of the start month
  const firstDayOfStartMonth = startDate.startOf('month');

  const firstDaysOfMonths: DateTime[] = [];
  let currentDate = firstDayOfStartMonth;

  // Loop through each month in the interval
  while (currentDate <= endDate) {
    firstDaysOfMonths.push(currentDate);
    // Move to the first day of the next month
    currentDate = currentDate.plus({ months: 1 });
  }

  return firstDaysOfMonths;
}
