import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { UiSecondaryNavItemArgs, UiSecondaryNavItemChild } from 'vault-client/components/ui-secondary-nav-item';
import OrganizationsOrganizationRoute from 'vault-client/routes/organizations/organization';
import PermissionsService from 'vault-client/services/permissions';
import { ModelFrom } from 'vault-client/utils/type-utils';

export default class OrganizationsOrganizationController extends Controller {
	@service declare permissions: PermissionsService;
	@service applicationScope: any;
	@service media: any;
	declare model: ModelFrom<OrganizationsOrganizationRoute>;

	accountRoutePath: string = 'organization.organization.account';
	exposureRoute = 'organizations.organization.exposure';

	get organizationActiveProductLinks() {
		const activeProducts = [...(this.model?.Organization?.ActiveProducts ?? [])];
		const sortedActiveProducts = activeProducts.sort(function (a, b) {
			if (a.name < b.name) {
				return -1;
			}
			if (a.name > b.name) {
				return 1;
			}
			return 0;
		});
		const organizationActiveProductLinks: UiSecondaryNavItemChild[] = sortedActiveProducts.map((product) => {
			return {
				label: product.name,
				link: this.exposureRoute,
				linkModel: product.slug,
			};
		});
		organizationActiveProductLinks.push({
			label: 'View All',
			link: 'organizations.organization.exposures',
		});
		return organizationActiveProductLinks;
	}

	get directlyLinkToViewALLExposure() {
		if (this.organizationActiveProductLinks.length == 1 || this.organizationActiveProductLinks.length > 16) return true;
		else if (this.media.isMobile) return true;
		else return false;
	}

	get secondaryNavItems() {
		const secondaryNavItems: Omit<UiSecondaryNavItemArgs, 'uid'>[] = [
			{
				label: 'Exposure',
				hidden: this.directlyLinkToViewALLExposure ? false : true,
				link: 'organizations.organization.exposures',
			},
			{
				label: 'Exposure',
				megaMenu: true,
				hidden: this.directlyLinkToViewALLExposure ? true : false,
				children: [
					{
						label: 'My Products',
						link: 'organizations.organization.exposures',
						children: this.organizationActiveProductLinks,
					},
				],
			},
			{
				label: 'Hedges',
				megaMenu: true,
				hidden: this.media.isMobile ? true : false,
				children: [
					{
						label: 'Brokerage',
						link: 'organizations.organization.brokerage',
						children: [
							{
								label: 'Accounts',
								link: `organizations.organization.accounts`,
							},
							{
								label: 'Account',
								link: `organizations.organization.account`,
								hidden: true,
							},
							{
								label: 'Positions',
								link: `organizations.organization.positions`,
							},
							{
								label: 'Position',
								link: `organizations.organization.position`,
								hidden: true,
							},
							{
								label: 'Transactions',
								link: `organizations.organization.transactions`,
							},
							{
								label: 'Transaction',
								link: `organizations.organization.transaction`,
								hidden: true,
							},
						],
						hidden: this.media.isMobile ? true : false,
					},
					{
						label: 'Insurance',
						link: 'organizations.organization.insurance',
						children: [
							{
								label: 'Overview',
								link: `organizations.organization.insurance-overview`,
							},
							{
								label: 'Policies',
								link: `organizations.organization.insurance-policies`,
							},
							{
								label: 'Policy',
								link: `organizations.organization.insurance-policy`,
								hidden: true,
							},
							{
								label: 'DRP Endorsements',
								link: `organizations.organization.drp-insurance-endorsements`,
							},
							{
								label: 'DRP Endorsement',
								link: `organizations.organization.drp-insurance-endorsement`,
								hidden: true,
							},
							{
								label: 'LRP Endorsements',
								link: `organizations.organization.lrp-insurance-endorsements`,
							},
							{
								label: 'LRP Endorsement',
								link: `organizations.organization.lrp-insurance-endorsement`,
								hidden: true,
							},
							{
								label: 'LGM Endorsements',
								link: `organizations.organization.lgm-insurance-endorsements`,
							},
							{
								label: 'LGM Endorsement',
								link: `organizations.organization.lgm-insurance-endorsement`,
								hidden: true,
							},
							{
								label: 'Documents',
								link: `organizations.organization.insurance-documents`,
							},
							{
								label: 'Quotes',
								link: 'https://drp-quotes.ever.ag',
								isExternal: true,
							},
							{
								label: 'Insurance Overview By Business',
								link: `organizations.organization.insurance-overview-by-business`,
								hidden: true,
							},
						],
						hidden: this.media.isMobile ? true : false,
					},
				],
			},
			{
				label: 'Feed',
				link: 'organizations.organization.feed-contracts',
				children: [
					{
						label: 'Feed Contracts',
						link: 'organizations.organization.feed-contracts',
					},
				],
			},
			{
				label: 'Brokerage',
				link: 'organizations.organization.brokerage',
				children: [
					{
						label: 'Accounts',
						link: `organizations.organization.accounts`,
					},
					{
						label: 'Account',
						link: `organizations.organization.account`,
						hidden: true,
					},
					{
						label: 'Positions',
						link: `organizations.organization.positions`,
					},
					{
						label: 'Position',
						link: `organizations.organization.position`,
						hidden: true,
					},
					{
						label: 'Transactions',
						link: `organizations.organization.transactions`,
					},
					{
						label: 'Transaction',
						link: `organizations.organization.transaction`,
						hidden: true,
					},
				],
				hidden: this.media.isMobile ? false : true,
			},
			{
				label: 'Insurance',
				link: 'organizations.organization.insurance',
				children: [
					{
						label: 'Overview',
						link: `organizations.organization.insurance-overview`,
					},
					{
						label: 'Policies',
						link: `organizations.organization.insurance-policies`,
					},
					{
						label: 'Policy',
						link: `organizations.organization.insurance-policy`,
						hidden: true,
					},
					{
						label: 'DRP Endorsements',
						link: `organizations.organization.drp-insurance-endorsements`,
					},
					{
						label: 'DRP Endorsement',
						link: `organizations.organization.drp-insurance-endorsement`,
						hidden: true,
					},
					{
						label: 'LRP Endorsements',
						link: `organizations.organization.lrp-insurance-endorsements`,
					},
					{
						label: 'LRP Endorsement',
						link: `organizations.organization.lrp-insurance-endorsement`,
						hidden: true,
					},
					{
						label: 'LGM Endorsements',
						link: `organizations.organization.lgm-insurance-endorsements`,
					},
					{
						label: 'LGM Endorsement',
						link: `organizations.organization.lgm-insurance-endorsement`,
						hidden: true,
					},
					{
						label: 'Documents',
						link: `organizations.organization.insurance-documents`,
					},
					{
						label: 'Quotes',
						link: 'https://drp-quotes.ever.ag',
						isExternal: true,
					},
					{
						label: 'Insurance Overview By Business',
						link: `organizations.organization.insurance-overview-by-business`,
						hidden: true,
					},
				],
				hidden: this.media.isMobile ? false : true,
			},
			{
				label: 'Crops',
				link: 'organizations.organization.marketing',
				children: [
					{
						label: 'Crop Targets',
						link: `organizations.organization.crop-targets`,
					},
					{
						label: 'Crop Contracts',
						link: `organizations.organization.crop-fills`,
					},
				],
			},
			{
				label: 'Businesses',
				link: `organizations.organization.businesses`,
			},
			{
				label: 'Reports',
				link: `organizations.organization.reports`,
				children: [
					{
						label: 'Unmapped Insurance Policies',
						link: 'organizations.organization.unmapped-insurance-policies',
						hidden: true,
					},
					{
						label: 'Unmapped Accounts',
						link: 'organizations.organization.unmapped-accounts',
						hidden: true,
					},
					{
						label: 'Dairy Historical Percentiles',
						link: 'organizations.organization.dairy-historical-percentiles',
						hidden: true,
					},
					{
						label: 'Historical Percentiles by Product',
						link: 'organizations.organization.historical-percentiles-by-product',
						hidden: true,
					},
					{
						label: 'Butter Inventory Hedges',
						link: 'organizations.organization.inventory-hedges-by-product',
						hidden: true,
					},
					{
						label: 'Strategic Inventory Model (SIM) - Butter ',
						link: 'organizations.organization.butter-strategic-inventory-model',
						hidden: true,
					},
					{
						label: 'Strategic Buying Model (SBM) - Butter',
						link: 'organizations.organization.butter-strategic-buying-model',
						hidden: true,
					},
					{
						label: 'Strategic Buying Model (SBM) - Nonfat Dry Milk ',
						link: 'organizations.organization.nfdm-strategic-buying-model',
						hidden: true,
					},
					{
						label: 'Strategic Buying Model (SBM) - Class III ',
						link: 'organizations.organization.class-iii-strategic-buying-model',
						hidden: true,
					},
					{
						label: 'Strategic Buying Model (SBM) - Class IV ',
						link: 'organizations.organization.class-iv-strategic-buying-model',
						hidden: true,
					},
					{
						label: 'Strategic Buying Model (SBM) - Dry Whey ',
						link: 'organizations.organization.dry-whey-strategic-buying-model',
						hidden: true,
					},
					{
						label: 'Cattle Historical Percentiles',
						link: 'organizations.organization.cattle-historical-percentiles',
						hidden: true,
					},
					{
						label: 'Strategic Buying Model (SBM) - Cheese ',
						link: 'organizations.organization.cheese-strategic-buying-model',
						hidden: true,
					},
					{
						label: 'Strategic Inventory Model (SIM) - Cheese ',
						link: 'organizations.organization.cheese-strategic-inventory-model',
						hidden: true,
					},
					{
						label: 'Strategic Buying Model (SBM) - Cheese Block ',
						link: 'organizations.organization.cheese-block-strategic-buying-model',
						hidden: true,
					},
					{
						label: 'Strategic Inventory Model (SIM) - Dry Whey ',
						link: 'organizations.organization.dry-whey-strategic-inventory-model',
						hidden: true,
					},
					{
						label: 'Strategic Inventory Model (SIM) - Nonfat Dry Milk ',
						link: 'organizations.organization.nfdm-strategic-inventory-model',
						hidden: true,
					},
					{
						label: 'Strategic Inventory Model (SIM) - Cheese Block ',
						link: 'organizations.organization.cheese-block-strategic-inventory-model',
						hidden: true,
					},
					{
						label: 'Dairy Margin',
						link: 'organizations.organization.dairy-margin',
						hidden: true,
					},
					{
						label: 'LRP Payment Calculator',
						link: 'organizations.organization.lrp-calculator',
						hidden: true,
					},
					{
						label: 'DRP Performance by Plan Year',
						link: 'organizations.organization.drp-performance-by-plan-year',
						hidden: true,
					},
					{
						label: 'DRP Performance by AIP',
						link: 'organizations.organization.drp-performance-by-aip',
						hidden: true,
					},
					{
						label: 'DRP Performance by Sales Effective Date Month',
						link: 'organizations.organization.drp-performance-by-sales-effective-date-month',
						hidden: true,
					},
					{
						label: 'A report allowing you to run scenarios (stress test) you combined Net P&L',
						link: 'organizations.organization.price-scenarios',
						hidden: true,
					},
				],
			},
			// {
			// 	label: 'Calculators',
			// 	children: [
			// 		{
			// 			label: 'DRP Quotes',
			// 			isExternal: true,
			// 			link: 'https://quotes.ever.ag/drp-quotes?practiceCode=803',
			// 		},
			// 		{
			// 			label: 'LRP Quotes',
			// 			isExternal: true,
			// 			link: 'https://quotes.ever.ag/lrp-quotes',
			// 		},
			// 		{
			// 			label: 'Feed Basis',
			// 			isExternal: true,
			// 			link: 'https://quotes.ever.ag/feed-basis',
			// 		},
			// 		{
			// 			label: 'Cheese Price',
			// 			isExternal: true,
			// 			link: 'https://www.ever.ag/cheese-calculator',
			// 		},
			// 		{
			// 			label: 'Class III/IV Prices',
			// 			isExternal: true,
			// 			link: 'https://www.ever.ag/class-iii-iv-calculator',
			// 		},
			// 		{
			// 			label: `Energy Corrected Milk & Feed Efficiency`,
			// 			isExternal: true,
			// 			link: 'https://www.ever.ag/energy-corrected-milk-and-feed-efficiency-calculator',
			// 		},
			// 	],
			// },
		];
		if (this.model?.Organization?.name) {
			secondaryNavItems.push({
				label: 'Settings',
				link: `organizations.organization.settings`,
				children: [
					{
						label: 'Details & Members',
						link: `organizations.organization.details-and-members`,
					},
					{
						label: 'Permission Groups',
						link: `organizations.organization.permission-groups`,
					},
					{
						label: 'Permission Group',
						link: `organizations.organization.permission-group`,
						hidden: true,
					},
				],
			});
		}
		return secondaryNavItems.map((item) => {
			this.setLinkModel(item);
			if (item.children) {
				item.children.map((child) => {
					this.setLinkModel(child);
					return child;
				});
			}
			return item;
		});
	}

	get current(): { label: string; route: string; model: string } {
		if (this.permissions.showOrganizations || this.permissions.showOrganization) {
			return {
				label: this.model?.Organization?.name,
				route: 'organizations.organization.details-and-members',
				model: this.model?.Organization?.id,
			};
		} else {
			return {
				label: 'All Businesses',
				route: 'organizations.organization.businesses',
				model: this.applicationScope.organizationId,
			};
		}
	}

	setLinkModel(item: UiSecondaryNavItemArgs | UiSecondaryNavItemChild) {
		if (item.linkModels) {
			item.linkModels.unshiftObject(this.applicationScope.organizationId);
		} else if (item.linkModel) {
			item.linkModels = [this.applicationScope.organizationId, item.linkModel];
			item.linkModel = undefined;
		} else {
			item.linkModel = this.applicationScope.organizationId;
		}
		return item;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization': OrganizationsOrganizationController;
	}
}
