import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { queryManager } from 'ember-apollo-client';
import getFilterDisplayProperty from '../../utils/get-filter-display-property';
import { SortObj, CellComponents, TableColumn } from 'vault-client/types/vault-table';
import { inject as service } from '@ember/service';
import searchQuery from 'vault-client/graphql/queries/search.graphql';
import agentFuzzyQuery from 'vault-client/graphql/queries/agent-fuzzy-match.graphql';
import stateFuzzyQuery from 'vault-client/graphql/queries/state-fuzzy-match.graphql';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import LgmInsuranceEndorsementsIndexRoute, { GET_LGM_INSURANCE_ENDORSEMENTS } from 'vault-client/routes/lgm-insurance-endorsements';
import { ModelFrom } from 'vault-client/utils/type-utils';
import OrganizationLgmInsuranceEndorsementsRoute from 'vault-client/routes/organizations/organization/lgm-insurance-endorsements';
import BusinessLgmInsuranceEndorsementsRoute from 'vault-client/routes/businesses/business/lgm-insurance-endorsements';
import {
	LgmInsuranceEndorsement,
	LgmInsuranceEndorsementFilterDTO,
	LgmInsuranceEndorsementSortByDTO,
	State,
} from 'vault-client/types/graphql-types';
import ApplicationScope from 'vault-client/services/application-scope';
import { DateTime } from 'luxon';
import intlDateTimeFormat from 'vault-client/utils/intl-date-time-format';

const queryParams = [
	'agent',
	'aipId',
	'customerId',
	'page',
	'sorts',
	'size',
	'salesEffectiveStartDate',
	'salesEffectiveEndDate',
	'stateId',
];
interface DateFilterQueryParamPrefixes {
	salesEffective: string | null;
}

type DateFilterQueryParamPrefix = keyof DateFilterQueryParamPrefixes;
interface SearchFilterIdentifiers {
	agent: string | null;
	aipId: string | null;
	customerId: string | null;
	stateId: string | null;
}

type SearchFilterIdentifier = keyof SearchFilterIdentifiers;

function isSearchFilterIdentifier(key: unknown): key is SearchFilterIdentifier {
	const searchFilterIdentifiers = ['agent', 'aipId', 'customerId', 'stateId'];
	if (typeof key === 'string' && searchFilterIdentifiers.includes(key)) {
		return true;
	}
	return false;
}

interface searchResult {
	id?: string;
	name?: string;
	type: string;
}

interface Agent {
	agentFirstName: string;
	agentLastName: string;
}

interface PerMonthData {
	coverageMonth?: string | null;
	startDate?: string | null;
	endDate?: string | null;
	monthIndex?: number | null;
	target?: number | null;
	corn?: number | null;
	expectedFeedCost?: number | null;
	expectedGrossMargin?: number | null;
	sbm?: number | null;
}

type LgmInsuranceEndorsementByCoverageMonth = {
	coverageMonth?: string;
	startDate?: string;
	endDate?: string;
	target?: number;
	monthIndex?: number;
	expectedGrossMargin?: number;
	corn?: number;
	sbm?: number;
	expectedFeedCost?: number;
	children?: Array<PerMonthData>;
	coverageMonths?: Array<string>;
	perMonthData?: Array<PerMonthData>;
	forecastedIndemnity: number;
	pnl: number;
};

export default class LgmInsuranceEndorsementsIndex extends Controller {
	declare model: ModelFrom<OrganizationLgmInsuranceEndorsementsRoute> | ModelFrom<BusinessLgmInsuranceEndorsementsRoute>;
	@queryManager apollo: any;

	@service declare applicationScope: ApplicationScope;
	@tracked lgmInsuranceEndorsementRoute: string = '';
	@tracked agent: string | null = null;
	@tracked aipId: string | null = null;
	@tracked stateId: string | null = null;
	@tracked customerId: string | null = null;
	@tracked page = 0;
	@tracked sorts = [{ valuePath: 'salesEffectiveDate', isAscending: false }];
	@tracked size = 100;
	@tracked salesEffectiveEndDate: string | null = '2999-12-31';
	@tracked salesEffectiveStartDate: string | null = '1900-01-01';
	searchPlaceholder = 'Filter by AIP, agent, state...';
	searchPrompt = 'Type a search term to filter endorsements by AIP, agent name, or state.';
	query = GET_LGM_INSURANCE_ENDORSEMENTS;

	queryParams = queryParams;

	salesEffectiveDateRangeOptions = [
		{
			displayName: 'All Dates',
			startDate: '1900-01-01',
			endDate: '2999-12-31',
		},
		{
			displayName: 'Previous 7 Days',
			startDate: DateTime.local().minus({ days: 7 }).toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Previous 30 Days',
			startDate: DateTime.local().minus({ days: 30 }).toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Previous 90 Days',
			startDate: DateTime.local().minus({ days: 90 }).toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Previous 12 Months',
			startDate: DateTime.local().minus({ months: 12 }).startOf('month').toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Previous 24 Months',
			startDate: DateTime.local().minus({ months: 24 }).startOf('month').toISODate(),
			endDate: DateTime.local().toISODate(),
		},
	];

	get columns(): TableColumn[] {
		return [
			{
				id: '5f0d72b2-7603-4676-893f-18bbc0c5fa55',
				name: 'Sales Date',
				valuePath: 'salesEffectiveDate',
				cellComponent: CellComponents.IntlDateTimeFormat,
				componentArgs: { month: 'numeric', day: 'numeric', year: 'numeric' },
				width: 115,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
				linkRoute: this.lgmInsuranceEndorsementRoute,
				linkModelPath: 'id',
			},
			{
				id: 'e3576691-3dae-474a-8411-fbc2a215259g',
				name: 'Coverage Period',
				valuePath: 'coverageMonth',
				cellComponent: CellComponents.String,
				width: 155,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'e3576691-3dae-474a-8411-fbc2a215259f',
				name: 'Producer',
				valuePath: 'InsurancePolicy.producerName',
				cellComponent: CellComponents.String,
				width: 125,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'e9348a64-ec54-4bf5-936b-4f76551fd536',
				name: 'Target',
				valuePath: 'target',
				width: 80,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '9ff9ad0f-f16a-4b79-9147-22cc96acd9cf',
				name: 'Corn',
				valuePath: 'corn',
				width: 100,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '97233c16-5b98-4d62-b941-6fcd2740b2da',
				name: 'SBM',
				valuePath: 'sbm',
				width: 100,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: 'a77cee0e-7aa7-46a5-978c-4752e5a6a15d',
				name: 'Expected Feed Cost',
				valuePath: 'expectedFeedCost',
				width: 180,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '2c73d1e3-d187-4b12-89aa-5cba2cdaaefa',
				name: 'Expected Gross Margin',
				valuePath: 'expectedGrossMargin',
				width: 180,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '71b8b3af-5ad3-4625-9d53-5cc11ff143cf',
				name: 'State',
				valuePath: 'InsurancePolicy.State.abbreviation',
				cellComponent: CellComponents.String,
				width: 70,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '0565b0a1-4f81-47ae-9bd2-7d0a6b89ff85',
				name: 'State (FIPS)',
				valuePath: 'InsurancePolicy.State.rmaStateCode',
				cellComponent: CellComponents.String,
				minWidth: 130,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: 'ca6e4c15-5748-4c88-a96f-978027166857',
				name: 'Commodity',
				valuePath: 'RmaType.RmaCommodity.commodityName',
				width: 110,
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'f480eda4-6f99-4e93-888b-067d222167ff',
				name: 'RMA Type',
				valuePath: 'RmaType.typeName',
				width: 125,
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'f4ca3f3c-df0c-4ae1-a53e-cd9929c4cc1d',
				name: 'Agent',
				valuePath: 'InsurancePolicy.agentFullName',
				width: 120,
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '5ci07025-ae5c-480a-8621-e30856295d86',
				name: 'AIP',
				valuePath: 'InsurancePolicy.AIP.name',
				width: 120,
				textAlign: '',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '59fc26d5-0839-4335-9fa2-16b3e2ae15be',
				name: 'Agency',
				valuePath: 'InsurancePolicy.agencyName',
				width: 200,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '4cbc7b9f-fbbd-49a0-8c76-352fe9745dae',
				name: 'Subsidy',
				valuePath: 'subsidyAmount',
				width: 180,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '8106747e-fd3d-4cb8-a299-dcdd451cb252',
				name: 'Producer Premium',
				valuePath: 'producerPremiumAmount',
				width: 160,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '85de8b2e-1e11-4aeb-a335-bfc3585fcdaf',
				name: 'Total Gross Margin',
				valuePath: 'totalExpectedGrossMargin',
				width: 165,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '1491d585-08af-436d-99e4-70cc7c87618c',
				name: 'Gross Margin Guarantee',
				valuePath: 'grossMarginGuarantee',
				width: 180,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},

			{
				id: '83839def-6f3a-452d-94da-50ccbe516bd3',
				name: 'Deductible',
				valuePath: 'deductibleAmount',
				width: 120,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'cd1e04b2-2553-495c-9ebb-0c2e87ec672b',
				name: 'Est. Indemnity',
				valuePath: 'forecastedIndemnity',
				width: 140,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
			},
			{
				id: 'f7b2ce52-97fa-493b-900d-d1a1b4325c69',
				name: 'Net P/L',
				valuePath: 'pnl',
				width: 110,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
			},
		];
	}

	formatMonthStringFromDate(isoDate: string) {
		const luxonDate = DateTime.fromISO(isoDate);
		return `${luxonDate.monthShort} ${luxonDate.year}`;
	}

	get salesEffectiveDateQueryParam() {
		return {
			startDate: this.salesEffectiveStartDate,
			endDate: this.salesEffectiveEndDate,
		};
	}

	get endorsementData() {
		return this.itemsFn(this.model.getLgmInsuranceEndorsements.data.LgmInsuranceEndorsements ?? []);
	}

	@action
	itemsFn(lgmInsuranceEndorsements: LgmInsuranceEndorsement[]) {
		const endorsements: Array<LgmInsuranceEndorsementByCoverageMonth> = [];

		lgmInsuranceEndorsements.map((endorsement) => {
			const { coverageMonths, perMonthData, ...endorsementObj } = endorsement;
			//breaking endorsement up into separate row items based on coverage month and `perMonthData`. This is because the users expects to see the endorsement broken up by coverage month and there can be multiple months on a single LGM endorsement.

			const coverageMonthCount = endorsement.coverageMonths?.length;

			const firstCoverageMonth = Array.isArray(coverageMonths) && coverageMonths?.length > 0 ? coverageMonths[0] : null;

			const lastCoverageMonth =
				Array.isArray(coverageMonths) && coverageMonths?.length > 1 ? coverageMonths[coverageMonths.length - 1] : null;

			const targetSum = perMonthData?.reduce((acc, { target }) => acc + (target ?? 0), 0);

			const cornSum = perMonthData?.reduce((acc, { corn }) => acc + (corn ?? 0), 0);

			const sbmSum = perMonthData?.reduce((acc, { sbm }) => acc + (sbm ?? 0), 0);

			const expectedFeedCostSum = perMonthData?.reduce((acc, { expectedFeedCost }) => acc + (expectedFeedCost ?? 0), 0);

			const expectedGrossMarginSum = perMonthData?.reduce((acc, { expectedGrossMargin }) => acc + (expectedGrossMargin ?? 0), 0);

			const forecastedIndemnity = endorsement?.forecastedIndemnity;

			const pnl = endorsement?.pnl;

			const newEndorsementObj = {
				...endorsementObj,
				target: targetSum,
				corn: cornSum,
				sbm: sbmSum,
				expectedFeedCost: expectedFeedCostSum,
				expectedGrossMargin: expectedGrossMarginSum,
				forecastedIndemnity,
				pnl,
				coverageMonth: `${
					firstCoverageMonth
						? intlDateTimeFormat({
								value: firstCoverageMonth,
								month: 'short',
								year: 'numeric',
							})
						: '-'
				} thru ${
					lastCoverageMonth
						? intlDateTimeFormat({
								value: lastCoverageMonth,
								month: 'short',
								year: 'numeric',
							})
						: '-'
				}`,
			} as LgmInsuranceEndorsementByCoverageMonth;
			const childrenArray: PerMonthData[] = [];
			if (coverageMonthCount) {
				for (let i = 0; i < coverageMonthCount; i++) {
					const child: PerMonthData | null | undefined = {};
					const { coverageMonths, perMonthData } = endorsement;
					child.coverageMonth = coverageMonths?.length
						? intlDateTimeFormat({
								value: coverageMonths[i],
								month: 'short',
								year: 'numeric',
							})
						: null;
					child.monthIndex = perMonthData?.length ? perMonthData[i].monthIndex : null;
					child.target = perMonthData?.length ? perMonthData[i].target : null;
					child.corn = perMonthData?.length ? perMonthData[i].corn : null;
					child.expectedFeedCost = perMonthData?.length ? perMonthData[i].expectedFeedCost : null;
					child.expectedGrossMargin = perMonthData?.length ? perMonthData[i].expectedGrossMargin : null;
					child.sbm = perMonthData?.length ? perMonthData[i].sbm : null;

					childrenArray.push(child);
				}
			}

			newEndorsementObj.children = childrenArray;
			endorsements.push(newEndorsementObj);
		});

		return endorsements;
	}

	get totalNumEndorsements() {
		return this.model.getLgmInsuranceEndorsements.data?.LgmInsuranceEndorsementCount.count;
	}

	get currentPage() {
		return this.page;
	}

	set currentPage(page) {
		this.page = page;
	}

	get formattedQueryParams(): { orderBy: LgmInsuranceEndorsementSortByDTO; where: LgmInsuranceEndorsementFilterDTO } {
		const route = new LgmInsuranceEndorsementsIndexRoute();

		return {
			orderBy: route.generateOrderBy(this.sorts),
			where: route.generateWhere(
				this.applicationScope.locationId,
				this.applicationScope.globalCustomerId,
				this.applicationScope.organizationId,
				this.aipId,
				this.agent,
				this.stateId,
				this.salesEffectiveStartDate,
				this.salesEffectiveEndDate,
			),
		};
	}

	get searchFilterQueryParams() {
		const obj: {
			[param: string]: { [filterProperty: string]: string };
		} = {};
		const searchQueryParams: SearchFilterIdentifier[] = ['agent', 'aipId', 'customerId', 'stateId'];

		searchQueryParams.forEach((param) => {
			// if applicable setting filter for customerId for customer scope
			const value = param === 'customerId' && this.applicationScope.globalCustomerId ? this.applicationScope.globalCustomerId : this[param];

			if (value) {
				obj[param] = {
					filterRule: 'equals',
					filterValue: value,
				};

				// set filterComponent property to specify component for custom display extended from search-filter
				const filterDisplayObj = getFilterDisplayProperty(param);
				if (filterDisplayObj && filterDisplayObj.customComponent) {
					obj[param].filterComponent = filterDisplayObj.customComponent;
				}
				// set filterLabel property to specify custom label for filter - default would be filterIdentifier (matches queryParam)
				if (filterDisplayObj && filterDisplayObj.label) {
					obj[param].filterLabel = filterDisplayObj.label;
				}
			}
		});

		return obj;
	}

	get scopedSearchFilterQueryParams() {
		if (this.applicationScope.globalCustomerId) {
			const { customerId, ...rest } = this.searchFilterQueryParams;
			return rest;
		} else {
			return this.searchFilterQueryParams;
		}
	}

	@action
	setDateFilterQueryParam(queryParam: DateFilterQueryParamPrefix, value: { startDate: string; endDate: string }) {
		this[`${queryParam}StartDate`] = value.startDate;
		this[`${queryParam}EndDate`] = value.endDate;

		this.setTablePageState();
	}

	@action
	setTablePageState(newPageVal?: number) {
		this.currentPage = newPageVal ?? 0;
		resetVaultTableScroll('lgm-endorsement-table');
	}

	@action
	setSorts(sorts: SortObj[]) {
		this.sorts = sorts;
	}

	@action
	async fetchSearchResults(searchText: string) {
		let searchApiResults = [];
		const agentResults: searchResult[] = [];
		const stateResults: searchResult[] = [];

		// get fuzzy match agents
		const agentVariables = {
			where: {
				OR: [
					{ agentFirstName: { contains: searchText } },
					{ agentLastName: { contains: searchText } },
					{ agentFullName: { contains: searchText } },
				],
			},
		};
		const agentsArr = (
			(
				await this.apollo.watchQuery({
					query: agentFuzzyQuery,
					variables: agentVariables,
				})
			).InsurancePolicies as Agent[]
		).map((agent) => `${agent.agentFirstName} ${agent.agentLastName}`);

		const agentSet = new Set(agentsArr);

		for (const agent of agentSet) {
			const obj = {
				type: 'Agent',
				name: agent,
			};

			agentResults.push(obj);
		}

		// get fuzzy match state
		const stateVariables = {
			where: {
				OR: [{ abbreviation: { contains: searchText } }, { name: { contains: searchText } }, { id: { contains: searchText } }],
			},
		};

		const states = (
			await this.apollo.watchQuery({
				query: stateFuzzyQuery,
				variables: stateVariables,
			})
		).States as State[];

		states.forEach((state) => {
			const obj = {
				type: 'State',
				id: state.id,
				name: state.name,
			};
			stateResults.push(obj);
		});

		// get search API matches
		const variables = {
			query: searchText,
			typesToInclude: ['Aip'],
		};

		searchApiResults = (await this.apollo.watchQuery({ query: searchQuery, variables })).Search;

		// return combined set
		return [...agentResults, ...searchApiResults, ...stateResults];
	}

	@action
	structureSearchResults(searchResults: searchResult[]) {
		const map = new Map();

		searchResults.forEach((item) => {
			if (map.has(item.type)) {
				map.get(item.type).push({ id: item.id, name: item.name, type: item.type });
			} else {
				map.set(item.type, [{ id: item.id, name: item.name, type: item.type }]);
			}
		});

		return map;
	}

	@action
	setSearchFilterQueryParam(searchResult: searchResult) {
		const mappedSearchFilter = this.mapSearchResult(searchResult);
		if (mappedSearchFilter) {
			this[mappedSearchFilter.filterIdentifier] = mappedSearchFilter.filterValue;
		}
		this.setTablePageState();
	}

	mapSearchResult(searchResult: searchResult): { filterIdentifier: SearchFilterIdentifier; filterValue: string } | null {
		let filterIdentifier: SearchFilterIdentifier;

		switch (searchResult.type) {
			case 'Agent':
				filterIdentifier = 'agent';
				break;
			case 'Aip':
				filterIdentifier = 'aipId';
				break;
			case 'Customer':
				filterIdentifier = 'customerId';
				break;
			case 'State':
				filterIdentifier = 'stateId';
				break;
			default:
				return null;
		}

		if (searchResult.id || searchResult.name) {
			return {
				filterIdentifier,
				filterValue: (searchResult.id || searchResult.name) as string,
			};
		}

		return null;
	}

	@action
	clearSearchFilterQueryParam(filterIdentifier: string) {
		if (isSearchFilterIdentifier(filterIdentifier)) {
			this[filterIdentifier] = null;
			this.setTablePageState();
		}
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'lgm-insurance-endorsements/index': LgmInsuranceEndorsementsIndex;
	}
}
