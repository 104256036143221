import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @contract}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.title>Delete Contract?</confirmation.title>\n\t\t<confirmation.body>Are you sure that you want to delete this contract?\n\t\t\t<br />\n\t\t\t<span class='font-sans-semibold'>\n\t\t\t\t{{@contract.name}}\n\t\t\t</span>\n\t\t\t<br />\n\t\t\tYou cannot undo this action\n\t\t\t{{#if this.errorMessage}}\n\t\t\t\t<div class='w-full py-3'>\n\t\t\t\t\t<p class='justify-center text-brand-error-50 w-full'>{{this.errorMessage}}</p>\n\t\t\t\t</div>\n\t\t\t{{/if}}\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action @style='destructive' {{on 'click' this.deleteContract}}>Delete Contract</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}", {"contents":"{{#if @contract}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.title>Delete Contract?</confirmation.title>\n\t\t<confirmation.body>Are you sure that you want to delete this contract?\n\t\t\t<br />\n\t\t\t<span class='font-sans-semibold'>\n\t\t\t\t{{@contract.name}}\n\t\t\t</span>\n\t\t\t<br />\n\t\t\tYou cannot undo this action\n\t\t\t{{#if this.errorMessage}}\n\t\t\t\t<div class='w-full py-3'>\n\t\t\t\t\t<p class='justify-center text-brand-error-50 w-full'>{{this.errorMessage}}</p>\n\t\t\t\t</div>\n\t\t\t{{/if}}\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action @style='destructive' {{on 'click' this.deleteContract}}>Delete Contract</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}","moduleName":"vault-client/components/delete-production-contract-modal.hbs","parseOptions":{"srcName":"vault-client/components/delete-production-contract-modal.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { Mutation_deleteSwineLivestockGroupContractArgs, SwineLivestockGroupContract } from 'vault-client/types/graphql-types';
import { gql, QueryResource, useMutation } from 'glimmer-apollo';
import { tracked } from 'tracked-built-ins';

interface DeleteProductionContractModalArgs {
	contract: SwineLivestockGroupContract;
	model: QueryResource<unknown, Record<string, unknown>>;
	closeModalFn: Function;
}

const DELETE_CONTRACT_MUTATION = gql`
	mutation DeleteSwineLivestockGroupContract($id: String!) {
		deleteSwineLivestockGroupContract(id: $id) {
			id
		}
	}
`;

type DeleteContractMutation = {
	__typename?: 'Mutation';

	id: string | null;
};

export default class DeleteProductionContractModal extends Component<DeleteProductionContractModalArgs> {
	@tracked errorMessage: string | null = null;

	deleteContractMutation = useMutation<DeleteContractMutation, Mutation_deleteSwineLivestockGroupContractArgs>(this, () => [
		DELETE_CONTRACT_MUTATION,
		{
			variables: { id: this.args.contract.id },
			onComplete: (): void => {
				this.args.model.refetch();
				this.closeConfirmation();
			},
			onError: (error): void => {
				this.errorMessage = 'Error deleting contract.';
				console.error('Error while attempting to delete contract.', error.message);
			},
		},
	]);

	@action
	closeConfirmation() {
		this.args.closeModalFn();
	}

	@action
	async deleteContract() {
		await this.deleteContractMutation.mutate();
	}
}
