import * as v from 'valibot';
import type {
	CattleFeedRationStageIngredient,
	FeedIngredient,
	Mutation_updateCattleFeedRationStageIngredientArgs,
} from 'vault-client/types/graphql-types';
import { ERRORS } from 'vault-client/utils/cattle/errors';
import { FormError } from 'vault-client/utils/forms/form-error';
import { float } from 'vault-client/utils/validations/actions/float';

const {
	UPDATE_CATTLE_FEED_RATION_STAGE_INGREDIENT: { INVALID_USAGE },
} = ERRORS;
const MIN_USAGE = 0;

export const EditCattleFeedRationStageIngredientFormSchema = v.object({
	feedIngredientId: v.pipe(v.string(), v.nonEmpty()),
	usage: v.pipe(
		v.string(INVALID_USAGE),
		float(INVALID_USAGE),
		v.transform((value) => +value),
		v.minValue(MIN_USAGE, INVALID_USAGE),
	),
});

export type EditCattleFeedRationStageIngredientFormData = v.InferOutput<typeof EditCattleFeedRationStageIngredientFormSchema>;

export const EditCattleFeedRationStageIngredientFormSerializer = {
	serialize(
		stageIngredient: Pick<CattleFeedRationStageIngredient, 'volumeInWetLbPerHeadPerDay'> & { FeedIngredient: Pick<FeedIngredient, 'id'> },
	): EditCattleFeedRationStageIngredientFormData {
		return {
			feedIngredientId: stageIngredient.FeedIngredient.id,
			usage: stageIngredient.volumeInWetLbPerHeadPerDay,
		};
	},

	deserialize({
		schema,
		data,
		ingredientId,
	}: {
		schema: typeof EditCattleFeedRationStageIngredientFormSchema;
		data: EditCattleFeedRationStageIngredientFormData;
		ingredientId: string;
	}): Mutation_updateCattleFeedRationStageIngredientArgs {
		const { output: parsedData, success } = v.safeParse(schema, data);

		if (!success) {
			throw new FormError([ERRORS.UPDATE_CATTLE_FEED_RATION_STAGE_INGREDIENT.DEFAULT]);
		}

		return {
			id: ingredientId,
			data: {
				volumeInWetLbPerHeadPerDay: parsedData.usage,
			},
		};
	},
};
