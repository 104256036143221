import Controller from '@ember/controller';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import type { CattleFeedRation, CattleFeedRationStage, CattleFeedRationStageIngredient } from 'vault-client/types/graphql-types';
import type FormStateService from 'vault-client/services/form-state';
import type SuccessHandler from 'vault-client/services/success-handler';
import { hasError as validationStateHasError } from 'vault-client/utils/forms/validation-state/has-error';
import type { ModelFrom } from 'vault-client/utils/type-utils';
import type BusinessesBusinessCattleRationsShowRoute from 'vault-client/routes/businesses/business/cattle/rations/show';
import { titleCase } from 'string-ts';
import { getStagesWeightRangeWarnings } from 'vault-client/utils/cattle/rations/stages/get-stages-weight-range-warnings';

type SidePanelState =
	| { type: 'EDIT_RATION'; ration: Pick<CattleFeedRation, 'id' | 'name' | 'description'> }
	| { type: 'ADD_STAGE'; ration: Pick<CattleFeedRation, 'id'> }
	| { type: 'EDIT_STAGE'; stage: CattleFeedRationStage }
	| { type: 'ADD_INGREDIENT'; stage: Pick<CattleFeedRationStage, 'id'> }
	| { type: 'EDIT_INGREDIENT'; ingredient: CattleFeedRationStageIngredient }
	| null;

export default class BusinessesBusinessCattleRationsShowController extends Controller {
	@service declare router: RouterService;
	@service('form-state') declare formStateService: FormStateService;
	@service('success-handler') declare successHandler: SuccessHandler;

	@tracked rationToDelete: Pick<CattleFeedRation, 'id' | 'name'> | undefined | null = null;
	@tracked stageToDelete: CattleFeedRationStage | null = null;
	@tracked ingredientToDelete: CattleFeedRationStageIngredient | null = null;
	@tracked sidePanelState: SidePanelState = null;

	editRationFormId = 'edit-ration-form';

	declare model: ModelFrom<BusinessesBusinessCattleRationsShowRoute>;

	get stages() {
		return this.model.rationDetailQuery.data?.CattleFeedRation.CattleFeedRationStages ?? [];
	}

	get ration() {
		return this.model.rationDetailQuery.data?.CattleFeedRation;
	}

	get stageWeightRangeWarnings() {
		return getStagesWeightRangeWarnings(this.stages);
	}

	@action
	openDeleteRationModal() {
		if (!this.ration) return;
		this.rationToDelete = this.ration;
	}

	@action
	closeDeleteRationModal() {
		this.rationToDelete = null;
	}

	@action
	openSidePanel(sidePanelContent: SidePanelState) {
		this.sidePanelState = sidePanelContent;
	}

	get isSidePanelOpen() {
		return this.sidePanelState !== null;
	}

	get formId() {
		if (!this.sidePanelState) return null;
		const baseId = this.sidePanelState.type.toLowerCase().replace('_', '-');
		return `${baseId}-form`;
	}

	get formState() {
		if (!this.formId) return undefined;
		return this.formStateService.getState(this.formId);
	}

	get isSubmitDisabled(): boolean {
		if (!this.formState?.validationState) return true;
		return validationStateHasError(this.formState.validationState) || !!this.formState.submissionState?.isPending;
	}

	get sidePanelTitle(): string {
		if (!this.sidePanelState) return '';
		return titleCase(this.sidePanelState.type);
	}

	get submitButtonText(): string {
		if (!this.sidePanelState) return 'Submit';
		const isPending = this.formState?.submissionState?.isPending;

		switch (this.sidePanelState.type) {
			case 'EDIT_RATION':
				return isPending ? 'Saving...' : 'Save Changes';
			case 'ADD_STAGE':
				return isPending ? 'Adding...' : 'Apply';
			case 'EDIT_STAGE':
				return isPending ? 'Saving...' : 'Save Changes';
			case 'ADD_INGREDIENT':
				return isPending ? 'Adding...' : 'Apply';
			case 'EDIT_INGREDIENT':
				return isPending ? 'Saving...' : 'Save Changes';
			default:
				return isPending ? 'Submit' : 'Submitting...';
		}
	}

	// Side Panel Actions - Start
	@action
	setSidePanelState(state: SidePanelState) {
		this.sidePanelState = state;
	}

	@action
	closeSidePanel() {
		this.setSidePanelState(null);
	}

	@action
	submitForm() {
		this.formState?.submit?.();
	}
	// Side Panel Actions - End

	// Stage Actions - Start
	@action
	addStage() {
		if (!this.ration) return;
		this.setSidePanelState({ type: 'ADD_STAGE', ration: this.ration });
	}

	@action
	editStage(stage: CattleFeedRationStage) {
		this.setSidePanelState({ type: 'EDIT_STAGE', stage });
	}

	@action
	deleteStage(stage: CattleFeedRationStage) {
		this.stageToDelete = stage;
	}

	@action
	handleAddStageSuccess() {
		this.successHandler.showSuccess('Stage added successfully');
		this.closeSidePanel();
	}

	@action
	handleEditStageSuccess() {
		this.successHandler.showSuccess('Stage updated successfully');
		this.closeSidePanel();
	}

	@action
	handleDeleteStageSuccess() {
		this.successHandler.showSuccess('Stage deleted successfully');
	}

	@action
	closeDeleteStageConfirmation() {
		this.stageToDelete = null;
	}
	// Stage Actions - End

	// Ingredient Actions - Start
	@action
	addIngredient(stage: CattleFeedRationStage) {
		this.setSidePanelState({ type: 'ADD_INGREDIENT', stage });
	}

	@action
	editIngredient(ingredient: CattleFeedRationStageIngredient) {
		this.setSidePanelState({ type: 'EDIT_INGREDIENT', ingredient });
	}

	@action
	deleteIngredient(ingredient: CattleFeedRationStageIngredient) {
		this.ingredientToDelete = ingredient;
	}

	@action
	handleAddIngredientSuccess() {
		this.successHandler.showSuccess('Ingredient added successfully');
		this.closeSidePanel();
	}

	@action
	handleEditIngredientSuccess() {
		this.successHandler.showSuccess('Ingredient updated successfully');
		this.closeSidePanel();
	}

	@action
	handleDeleteIngredientSuccess() {
		this.successHandler.showSuccess('Ingredient deleted successfully');
	}

	@action
	closeDeleteIngredientConfirmation() {
		this.ingredientToDelete = null;
	}

	// Ingredient Actions - End

	// Ration Actions - Start
	@action
	editRation() {
		if (!this.ration) return;
		this.setSidePanelState({ type: 'EDIT_RATION', ration: this.ration });
	}

	@action
	deleteRation() {
		// this.cattleMock.deleteRation(this.model.rationDetailQuery.data?.CattleFeedRation.id);
		this.router.transitionTo('businesses.business.cattle.rations.index');
	}

	@action
	async handleEditSuccess() {
		this.successHandler.showSuccess('Ration updated successfully');
		this.closeSidePanel();
	}

	@action
	navigateToCreateIngredient() {
		this.router.transitionTo('businesses.business.feed-overview');
	}
	// Ration Actions - End
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'businesses/business/cattle/rations/show': BusinessesBusinessCattleRationsShowController;
	}
}
