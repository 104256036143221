import { Scale, type ScriptableTooltipContext, type ChartTypeRegistry } from 'chart.js';
import { CropTransaction, TypeOfPhysicalCropTransactionPricing } from 'vault-client/types/graphql-types';
import getCSSVariable from 'vault-client/utils/get-css-variable';
import { getOrCreateTooltip } from 'vault-client/utils/chart-utils';
import Big from 'big.js';

export type ScaleId = 'y' | 'totalProduction' | 'transaction';

type BorderWidth = number | { top?: number; right?: number; bottom?: number; left?: number };

export interface ColorOptions {
	backgroundColor: Array<string | undefined>;
	borderColor: Array<string | undefined>;
	borderWidth: Array<BorderWidth | undefined>;
}

export interface DatasetOptions extends ColorOptions {
	xAxisID: ScaleId;
	label: string;
	categoryPercentage: number;
	barPercentage: number;
	barThickness: number | 'flex';
}

export const DATA_LABELS = ['Flat', 'HTA', 'Basis', 'Unsold', 'Avg Price', 'Dynamic Breakeven'] as const;

export type DataLabel = (typeof DATA_LABELS)[number];

export const PRICE_TYPE_TO_DATA_LABEL: Record<TypeOfPhysicalCropTransactionPricing, DataLabel> = {
	[TypeOfPhysicalCropTransactionPricing.CalculatedFlat]: 'Flat',
	[TypeOfPhysicalCropTransactionPricing.ExplicitlyFlat]: 'Flat',
	[TypeOfPhysicalCropTransactionPricing.FuturesOnly]: 'HTA',
	[TypeOfPhysicalCropTransactionPricing.BasisOnly]: 'Basis',
	[TypeOfPhysicalCropTransactionPricing.NoPrice]: 'Unsold',
};

const DATA_LABEL_ORDER: Record<DataLabel, number> = {
	Flat: 0,
	HTA: 1,
	Basis: 2,
	Unsold: 3,
	'Avg Price': 4,
	'Dynamic Breakeven': 5,
};

export function compareDatasetLabels(a: DataLabel, b: DataLabel) {
	return DATA_LABEL_ORDER[a] - DATA_LABEL_ORDER[b];
}

export function compareByDatasetLabelAndSalesDate(a: CropTransaction, b: CropTransaction) {
	return (
		compareDatasetLabels(PRICE_TYPE_TO_DATA_LABEL[a.pricingType], PRICE_TYPE_TO_DATA_LABEL[b.pricingType]) ||
		(a.salesDate < b.salesDate ? -1 : 1)
	);
}

const FLAT_COLORS = {
	backgroundColor: getCSSVariable('--brand-interactive-blue-70'),
	borderColor: getCSSVariable('--brand-interactive-blue-90'),
	borderWidth: 2,
};

const UNSOLD_COLORS = {
	backgroundColor: getCSSVariable('--brand-orange-40'),
	borderColor: undefined,
	borderWidth: undefined,
};

type ColorValues = {
	backgroundColor?: string;
	borderColor?: string;
	borderWidth?: number;
};

export const COLOR_MAP = {
	[TypeOfPhysicalCropTransactionPricing.CalculatedFlat]: FLAT_COLORS,
	[TypeOfPhysicalCropTransactionPricing.ExplicitlyFlat]: FLAT_COLORS,
	[TypeOfPhysicalCropTransactionPricing.FuturesOnly]: {
		backgroundColor: getCSSVariable('--brand-teal-60'),
		borderColor: getCSSVariable('--brand-teal-90'),
		borderWidth: 2,
	},
	[TypeOfPhysicalCropTransactionPricing.BasisOnly]: {
		backgroundColor: getCSSVariable('--brand-lime-50'),
		borderColor: getCSSVariable('--brand-lime-90'),
		borderWidth: 2,
	},
	[TypeOfPhysicalCropTransactionPricing.NoPrice]: UNSOLD_COLORS,
	Unsold: UNSOLD_COLORS,
	'Avg Price': {
		backgroundColor: undefined,
		borderColor: getCSSVariable('--brand-lemon-40'),
		borderWidth: 2,
	},
	'Dynamic Breakeven': {
		backgroundColor: undefined,
		borderColor: getCSSVariable('--brand-purple-50'),
		borderWidth: 2,
	},
} satisfies Record<string, ColorValues>;

export const DATA_LABEL_TO_COLOR: Record<DataLabel, ColorValues> = {
	Flat: COLOR_MAP[TypeOfPhysicalCropTransactionPricing.CalculatedFlat],
	HTA: COLOR_MAP[TypeOfPhysicalCropTransactionPricing.FuturesOnly],
	Basis: COLOR_MAP[TypeOfPhysicalCropTransactionPricing.BasisOnly],
	Unsold: COLOR_MAP['Unsold'],
	'Avg Price': COLOR_MAP['Avg Price'],
	'Dynamic Breakeven': COLOR_MAP['Dynamic Breakeven'],
};

export function getBarCoordinates(barWidths: number[]) {
	const labelBreakpoints = barWidths.reduce(
		(breakPoints, _width, i) => [...breakPoints, breakPoints[i] + 2 * barWidths[i + 1]],
		[barWidths[0] / 2, (3 * barWidths[0]) / 2],
	);

	labelBreakpoints.pop();

	const barCenters = barWidths.reduce(
		(barCenters, width, i) => [...barCenters, barCenters[i] + (width + barWidths[i + 1]) / 2],
		[barWidths[0] / 2],
	);

	barCenters.pop();

	const totalWidth = barWidths.reduce((a, b) => a + b, 0);
	return { labelBreakpoints, barCenters, totalWidth };
}

export function getStepPixelWidth(scale: Scale) {
	// Get the number of pixels per scale step
	return (scale.max - scale.min) / scale.width;
}

export function getDatasetColorOptionsWithoutUnsold(cropTransactions: CropTransaction[]): ColorOptions {
	const colors = cropTransactions.reduce(
		({ backgroundColor, borderColor, borderWidth }, { pricingType }, i) => {
			const {
				backgroundColor: transactionBackgroundColor,
				borderColor: transactionBorderColor,
				borderWidth: transactionBorderWidth,
			} = COLOR_MAP[pricingType];

			const isLast = i === cropTransactions.length - 1;

			return {
				backgroundColor: [...backgroundColor, transactionBackgroundColor],
				borderColor: [...borderColor, transactionBorderColor],
				borderWidth: [
					...borderWidth,
					{
						top: transactionBorderWidth,
						right: isLast ? transactionBorderWidth : undefined,
						bottom: transactionBorderWidth,
						left: transactionBorderWidth,
					},
				],
			};
		},
		{
			backgroundColor: [] as (string | undefined)[],
			borderColor: [] as (string | undefined)[],
			borderWidth: [] as (BorderWidth | undefined)[],
		},
	);

	return colors;
}

export function getDatasetColorOptionsWithUnsold(cropTransactions: CropTransaction[]): ColorOptions {
	const colors = getDatasetColorOptionsWithoutUnsold(cropTransactions);
	const { backgroundColor, borderColor, borderWidth } = COLOR_MAP['Unsold'];
	colors.backgroundColor.push(backgroundColor);
	colors.borderColor.push(borderColor);
	colors.borderWidth.push(borderWidth);

	return colors;
}

// Each entry represents one line of information to display
type TooltipContractLine = string;

type TooltipContractData = {
	label: string;
	lines: Array<TooltipContractLine>;
};

export type TooltipOptions = {
	// data is expected to line up directly with chart's data
	data: Array<TooltipContractData>;
};

export function getCustomExternalTooltip(options: TooltipOptions) {
	return (context: ScriptableTooltipContext<keyof ChartTypeRegistry>) => {
		// Tooltip Element
		const { chart, tooltip } = context;
		const tooltipEl = getOrCreateTooltip(chart);
		const tooltipUl = tooltipEl.querySelector('ul')!;

		// Hide if no tooltip
		if (tooltip.opacity === 0) {
			tooltipEl.classList.replace('opacity-1', 'opacity-0');
			return;
		}

		// Set Text
		if (tooltip.body) {
			const dataPoint = tooltip.dataPoints[0];
			const { dataIndex } = dataPoint ?? { dataIndex: 0 };
			const { label, lines: bodyLines } = options.data[dataIndex] ?? { label: '', lines: [] };

			const titleLines = [label] || [];
			const tooltipLi = document.createElement('li');

			tooltipLi.classList.add('whitespace-nowrap');

			// Title Loop
			titleLines.forEach((title: string) => {
				const tooltipSpan = document.createElement('span');
				tooltipSpan.classList.add('whitespace-nowrap', 'text-base', 'font-sans-semibold', 'text-brand-blue-70', 'block');
				const tooltipTitle = document.createTextNode(title);

				tooltipSpan.appendChild(tooltipTitle);
				tooltipLi.appendChild(tooltipSpan);
				tooltipUl.appendChild(tooltipLi);
			});

			// Body lines
			const tooltipBodyP = document.createElement('p');

			bodyLines.forEach((line) => {
				const bodyItem = document.createElement('div');
				bodyItem.classList.add('text-brand-gray-90', 'text-sm');

				const textElement = document.createTextNode(line);
				bodyItem.appendChild(textElement);
				tooltipBodyP.appendChild(bodyItem);
			});

			// Remove old children
			while (tooltipUl.firstChild) {
				tooltipUl.firstChild.remove();
			}

			// Add new children
			tooltipUl.appendChild(tooltipLi);
			tooltipLi.appendChild(tooltipBodyP);
			tooltipEl.classList.replace('opacity-0', 'opacity-1');
		}

		// Position tooltip
		const position = context.chart.canvas.getBoundingClientRect();
		const { width: tooltipElWidth, height: tooltipElHeight } = tooltipEl.getBoundingClientRect();

		let offsetX = 0;

		if (tooltip.caretX < tooltipElWidth / 3) {
			offsetX = tooltip.caretX + tooltipElWidth / 2 + 12;

			tooltipEl.classList.remove('line-chart-tooltip-bottom', 'line-chart-tooltip-right');
			tooltipEl.classList.add('line-chart-tooltip-left');
		} else if (position.width - tooltip.caretX < tooltipElWidth / 3) {
			offsetX = tooltip.caretX - tooltipElWidth / 2 - 12;

			tooltipEl.classList.remove('line-chart-tooltip-bottom', 'line-chart-tooltip-left');
			tooltipEl.classList.add('line-chart-tooltip-right');
		} else {
			offsetX = tooltip.caretX;
			tooltipEl.classList.remove('line-chart-tooltip-right', 'line-chart-tooltip-left');
			tooltipEl.classList.add('line-chart-tooltip-bottom');
		}

		tooltipEl.style.left = offsetX + 'px';
		tooltipEl.style.top = -tooltipElHeight + 'px';
	};
}

export function calculateBreakevenPrice(params: {
	totalProduction: number;
	totalProductionCost: number;
	totalCropTransactionsValue: number;
	totalCropTransactionsUnits: number;
}) {
	// Formula: (Total Cost of Production - Sum of total value of contracts) / (Total Production - sum of total contract units)
	const totalProduction = Big(params.totalProduction);
	const totalProductionCost = Big(params.totalProductionCost);
	const totalValueOfCropTransactions = Big(params.totalCropTransactionsValue);
	const totalCropTransactionUnits = Big(params.totalCropTransactionsUnits);

	const dividend = totalProductionCost.minus(totalValueOfCropTransactions);
	const divisor = totalProduction.minus(totalCropTransactionUnits);

	if (divisor.eq(0)) return 0;

	return dividend.div(divisor).toNumber();
}
