import { setOwner } from '@ember/application';
import Owner from '@ember/owner';
import type { AggregateCurrentAllocationPositionDTO, Maybe } from 'vault-client/types/graphql-types';
import { safeSubNull, safeSum } from 'vault-client/utils/precision-math';
import { getSlugs, type CropGroup } from 'vault-client/utils/grain/crop';
import { CropModel } from './crop';

export class CropGroupModel {
	cropGroup: CropGroup;
	crops: CropModel[];
	brokeragePnl: number;

	constructor(
		owner: Owner,
		{
			cropGroup,
			crops,
			aggregateCurrentAllocationPositions = [],
		}: {
			cropGroup: CropGroup;
			crops: CropModel[];
			aggregateCurrentAllocationPositions?: AggregateCurrentAllocationPositionDTO[];
		},
	) {
		setOwner(this, owner);
		this.cropGroup = cropGroup;
		this.crops = crops;

		// Calculate brokerage P&L
		const productSlugs = getSlugs(cropGroup);
		this.brokeragePnl = productSlugs.reduce((sum, slug) => {
			const position = aggregateCurrentAllocationPositions?.find((position) => position.Product?.slug === slug);
			return sum + (position?.sum.grossPnl ?? 0);
		}, 0);
	}

	// Static revenue components

	get totalCropLevelRevenue(): number {
		return this.crops.reduce((sum, crop) => safeSum(sum, crop.cropLevelRevenue ?? 0), 0);
	}

	get totalCropLevelExpenses(): number {
		return this.crops.reduce((sum, crop) => safeSum(sum, crop.cropLevelExpenses ?? 0), 0);
	}

	get totalFieldLevelRevenue(): number {
		return this.crops.reduce((sum, crop) => safeSum(sum, crop.fieldLevelRevenue ?? 0), 0);
	}

	get totalFieldLevelExpenses(): number {
		return this.crops.reduce((sum, crop) => safeSum(sum, crop.fieldLevelExpenses ?? 0), 0);
	}

	// Dynamic revenue components

	get totalSoldUnitsTotalPrice(): number {
		return this.crops.reduce((sum, crop) => safeSum(sum, crop.soldUnitsTotalPrice), 0);
	}

	get totalUnsoldMarkToMarketValue(): number {
		return this.crops.reduce((sum, crop) => safeSum(sum, crop.unsoldMarkToMarketValue), 0);
	}

	get totalCropSales(): number {
		return safeSum(this.totalSoldUnitsTotalPrice, this.totalUnsoldMarkToMarketValue);
	}

	// Total revenue and P&L

	get totalRevenue(): number {
		return safeSum(
			this.crops.reduce((sum, crop) => safeSum(sum, crop.revenue), 0),
			this.brokeragePnl,
		);
	}

	get totalExpenses(): number {
		return safeSum(this.totalCropLevelExpenses, this.totalFieldLevelExpenses);
	}

	get totalNetPnl(): Maybe<number> {
		return safeSubNull(this.totalRevenue, this.totalExpenses);
	}
}
