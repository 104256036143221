import { CattleFeedRation } from 'vault-client/types/graphql-types';
import { tracked } from '@glimmer/tracking';

export default class CattleRation implements Pick<CattleFeedRation, 'id' | 'name' | 'description' | 'CattleLots'> {
	@tracked id: string;
	@tracked name: string;
	@tracked description: string | null | undefined;
	@tracked numberOfCattle: number;
	@tracked activeLots: number;
	@tracked CattleLots: CattleFeedRation['CattleLots'];
	constructor(ration: Partial<CattleFeedRation>) {
		this.id = ration.id!;
		this.name = ration.name!;
		this.description = ration.description;
		this.CattleLots = ration.CattleLots ?? [];
		this.numberOfCattle = this.CattleLots.reduce((sum, lot) => sum + lot.numberOfCattle, 0);
		this.activeLots = this.CattleLots.length;
	}
}
