import Controller from '@ember/controller';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { DateTime } from 'luxon';
import { UiDateFilterOption } from 'vault-client/components/vault/ui-date-filter';
import { CellComponents, SortObj, TableColumn } from 'vault-client/types/vault-table';
import { ModelFrom } from 'vault-client/utils/type-utils';
import CropContractsRoute from 'vault-client/routes/crop-contracts';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import getFilterDisplayProperty from 'vault-client/utils/get-filter-display-property';
import {
	CropTransaction,
	Mutation_createPhysicalCropTransactionArgs,
	Mutation_updatePhysicalCropTransactionArgs,
	PhysicalCropTransaction,
	Query,
	Query_CropTransactionsArgs,
} from 'vault-client/types/graphql-types';
import { SearchResult } from 'vault-client/types/vault-client';
import { gql, useQuery } from 'glimmer-apollo';
import { guidFor } from '@ember/object/internals';
import { ContractRow, SidePanelData } from '../crops/detail';
import { task } from 'ember-concurrency';
import { addPhysicalCropTransaction, pricingTypeToDisplayString, updatePhysicalCropTransaction } from 'vault-client/utils/grain-utils';

type SearchQueryParam = 'location' | 'buyer';
type SidePanelState = 'AddEditCropContract' | null;

const GET_CROP_CONTRACTS_SEARCH = gql`
	query GetCropContracts($where: CropTransactionFilterDTO, $scopeId: String) {
		CropTransactions(scopeId: $scopeId, where: $where) {
			id
			buyer
			location
		}
	}
`;
export default class CropContractsController extends Controller {
	declare model: ModelFrom<CropContractsRoute>;

	@service declare router: RouterService;

	@tracked page = 0;
	@tracked size = 100;
	@tracked startDate: string = DateTime.now().startOf('year').toISODate();
	@tracked endDate: string = DateTime.now().endOf('year').toISODate();
	@tracked deliveryStartDateStart: string | null = '1900-01-01';
	@tracked deliveryStartDateEnd: string | null = '2999-12-31';
	@tracked deliveryEndDateStart: string | null = '1900-01-01';
	@tracked deliveryEndDateEnd: string | null = '2999-12-31';
	@tracked cropIds: string[] = [];
	@tracked customerId: string | null = null;
	@tracked location: string | null = null;
	@tracked buyer: string | null = null;
	@tracked sorts: SortObj[] = [{ valuePath: 'deliveryStartDate', isAscending: true }];
	@tracked cropContractToEdit?: CropTransaction;
	@tracked isAddEditCropContractFormValid: boolean = false;
	@tracked addEditCropContractSubmitErrors: string[] = [];
	@tracked sidePanelState: SidePanelState = null;
	@tracked isSidePanelOpen = false;

	guid = guidFor(this);
	@tracked contractToDelete: PhysicalCropTransaction | null = null;

	businessCropContractsRoutePath: string | null = null;

	addEditCropContractFormId = `add-edit-contract-${this.guid}`;

	queryParams = [
		'page',
		'sorts',
		'size',
		'deliveryStartDateStart',
		'deliveryStartDateEnd',
		'deliveryEndDateStart',
		'deliveryEndDateEnd',
		'cropIds',
		'customerId',
		'location',
		'buyer',
	];

	get allowMutations() {
		return this.hasWriteAccess && !this.isFoundationsClient;
	}

	get hasWriteAccess() {
		return this.model.getCropContracts.data?.Entity?.CurrentUserPermissions.canWriteOperations ?? false;
	}

	get isFoundationsClient() {
		return this.model.getCropContracts.data?.Entity?.isVgs ?? false;
	}

	get query() {
		return this.model.query;
	}

	get variables() {
		return this.model.variables;
	}

	get dateRangeOptions(): UiDateFilterOption[] {
		return [
			{
				displayName: 'All Dates',
				startDate: '1900-01-01',
				endDate: '2999-12-31',
			},
			{
				displayName: 'Current Month',
				startDate: DateTime.local().startOf('month').toISODate(),
				endDate: DateTime.local().endOf('month').toISODate(),
			},
			{
				displayName: 'Next 3 Months',
				startDate: DateTime.local().startOf('month').toISODate(),
				endDate: DateTime.local().plus({ months: 3 }).endOf('month').toISODate(),
			},
			{
				displayName: 'Next 6 Months',
				startDate: DateTime.local().startOf('month').toISODate(),
				endDate: DateTime.local().plus({ months: 6 }).endOf('month').toISODate(),
			},
			{
				displayName: 'Next 12 Months',
				startDate: DateTime.local().startOf('month').toISODate(),
				endDate: DateTime.local().plus({ months: 12 }).endOf('month').toISODate(),
			},
		];
	}

	get columns(): TableColumn[] {
		return [
			{
				id: 'cfdb8b52-f411-4668-8173-ec69addfc4a7',
				name: 'Business',
				cellComponent: CellComponents.String,
				width: 180,
				...(this.businessCropContractsRoutePath && {
					linkRoute: this.businessCropContractsRoutePath,
					linkModelPath: 'Business.id',
				}),
				valuePath: 'Business.name',
				textAlign: 'left',
				isFixed: '',
				isSortable: false,
				isVisible: false,
			},
			{
				id: '8d57641e-b44c-4160-9c9c-b981ad21e56e',
				name: 'Contract Number',
				cellComponent: CellComponents.String,
				valuePath: 'contractIdentifier',
				width: 160,
				textAlign: 'left',
				isFixed: '',
				isSortable: true,
				isVisible: true,
			},
			{
				id: '7f7dc77a-623c-4a66-b427-16acd2ba482d',
				name: 'Year',
				cellComponent: CellComponents.String,
				valuePath: 'harvestYear',
				width: 100,
				textAlign: 'left',
				isFixed: '',
				isSortable: true,
				isVisible: false,
			},
			{
				id: '5691fe6d-6930-4cce-8b37-1d475244739d',
				name: 'Crop',
				cellComponent: CellComponents.String,
				valuePath: 'Crop.name',
				width: 135,
				textAlign: 'left',
				isFixed: '',
				isSortable: true,
				isVisible: true,
			},
			{
				id: '8b82c6ed-9825-4155-b420-48ea5cd2d4dd',
				name: 'Units',
				cellComponent: CellComponents.IntlNumberFormat,
				valuePath: 'bushels',
				width: 80,
				textAlign: 'right',
				isFixed: '',
				isSortable: true,
				isVisible: true,
			},
			{
				id: 'd1a3abfe-0dda-4fab-9e0e-51f246716372',
				name: 'Futures Month',
				cellComponent: CellComponents.MonthFormat,
				valuePath: 'futuresMonthStartDate',
				width: 140,
				textAlign: 'left',
				isFixed: '',
				isSortable: true,
				isVisible: true,
			},
			{
				id: '4dee42b5-ccfe-461f-be5d-6267e2e24765',
				name: 'Delivery Start Date',
				cellComponent: CellComponents.IntlDateTimeFormat,
				valuePath: 'deliveryStartDate',
				width: 170,
				textAlign: 'left',
				isFixed: '',
				isSortable: true,
				isVisible: true,
			},
			{
				id: '422243db-2576-401d-a344-9aac568e384b',
				name: 'Delivery End Date',
				cellComponent: CellComponents.IntlDateTimeFormat,
				valuePath: 'deliveryEndDate',
				width: 160,
				textAlign: 'left',
				isFixed: '',
				isSortable: true,
				isVisible: true,
			},
			{
				id: '2ecf22b9-328b-4a1e-9681-b344312a4fcc',
				name: 'Sales Type',
				cellComponent: CellComponents.String,
				valuePath: 'pricingType',
				width: 120,
				textAlign: 'left',
				isFixed: '',
				isSortable: true,
				isVisible: true,
			},
			{
				id: '7d1ca1b6-29c5-413f-aad8-0b84731ab7da',
				name: 'Futures Price',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
				},
				valuePath: 'futuresPrice',
				textAlign: 'right',
				width: 130,
				isFixed: '',
				isSortable: true,
				isVisible: true,
			},
			{
				id: '32e81357-d1df-48af-9106-37fc61f80ff6',
				name: 'Flat Price',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
				},
				valuePath: 'flatPrice',
				textAlign: 'right',
				width: 110,
				isFixed: '',
				isSortable: true,
				isVisible: true,
			},
			{
				id: 'c82bc0b1-f567-4660-bb0b-297e18d48805',
				name: 'Basis',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
				},
				valuePath: 'basisPrice',
				textAlign: 'right',
				width: 90,
				isFixed: '',
				isSortable: true,
				isVisible: true,
			},
			{
				id: '74c4a7ba-e12a-43c0-a9cf-20f3460c3820',
				name: 'Buyer',
				cellComponent: CellComponents.String,
				valuePath: 'buyer',
				width: 125,
				textAlign: 'left',
				isFixed: '',
				isSortable: true,
				isVisible: true,
			},
			{
				id: 'f2d06167-e65d-460b-80f3-3603aa3c7e6a',
				name: 'Location',
				cellComponent: CellComponents.String,
				valuePath: 'location',
				width: 140,
				textAlign: 'left',
				isFixed: '',
				isSortable: true,
				isVisible: true,
			},
			{
				id: 'fa510e6a-4caa-4024-9d28-e995e6e74021',
				name: 'Delivery Terms',
				cellComponent: CellComponents.String,
				valuePath: 'deliveryTerms',
				textAlign: 'left',
				width: 150,
				isFixed: '',
				isSortable: true,
				isVisible: true,
			},
			...(this.allowMutations
				? [
						{
							id: 'c21d5146-fc56-4707-a0bc-517ec1427397',
							name: '',
							cellComponent: CellComponents.Button,
							valuePath: '',
							componentArgs: {
								style: 'plain',
								iconOnlyButton: true,
								centerIconHref: '/icons/Edit-Outline.svg#edit-outline',
								centerIconClass: 'icon-interactive-stroke',
								fn: this.onEditContractButtonClick,
								disableFn: (contract: CropTransaction) => {
									return this.isSidePanelOpen || !contract.isManuallyAdded || !this.hasWriteAccess || this.isFoundationsClient;
								},
							},
							width: 80,
							textAlign: 'left',
							isFixed: 'right',
							isSortable: false,
							isVisible: true,
						},
						{
							id: 'a4522b44-a3eb-42a8-8b26-effa9d63f0a3',
							name: '',
							cellComponent: CellComponents.Button,
							valuePath: '',
							componentArgs: {
								style: 'plain',
								iconOnlyButton: true,
								fn: this.setContractToDelete,
								centerIconHref: '/icons/Delete-Outline.svg#delete-outline',
								centerIconClass: 'icon-interactive-stroke',
							},
							width: 80,
							textAlign: 'left',
							isFixed: 'right',
							isSortable: false,
							isVisible: true,
						},
					]
				: []),
		];
	}

	get cropContracts() {
		return this.model.getCropContracts.data?.CropTransactions
			? this.model.getCropContracts.data.CropTransactions.map((cropTransaction) => ({
					...cropTransaction,
					pricingType: pricingTypeToDisplayString[cropTransaction.pricingType],
				}))
			: [];
	}

	get totalNumCropContracts() {
		return this.model.getCropContracts.data?.CropTransactionsCount?.count ?? 0;
	}

	get deliveryStartDate() {
		return {
			startDate: this.deliveryStartDateStart ?? '1900-01-01',
			endDate: this.deliveryStartDateEnd ?? '2999-12-31',
		};
	}

	get deliveryEndDate() {
		return {
			startDate: this.deliveryEndDateStart ?? '1900-01-01',
			endDate: this.deliveryEndDateEnd ?? '2999-12-31',
		};
	}

	get sidePanelData(): SidePanelData {
		return {
			formId: this.addEditCropContractFormId,
			disableSubmitButton: this.onSubmitAddEditCropContractForm.isRunning || !this.isAddEditCropContractFormValid,
			panelSubmitAction: undefined,
			submitButtonText: this.cropContractToEdit ? 'Edit' : 'Create',
			panelTitle: this.cropContractToEdit ? 'Edit Contract' : 'Add Contract',
		};
	}

	@action
	openSidePanel(newSidePanelState: SidePanelState) {
		this.sidePanelState = newSidePanelState;
		this.isSidePanelOpen = true;
	}

	@action
	openAddEditCropContractTray(contract?: CropTransaction) {
		this.addEditCropContractSubmitErrors = [];
		this.isAddEditCropContractFormValid = false;
		this.cropContractToEdit = contract;
		this.openSidePanel('AddEditCropContract');
	}

	@action
	closeSidePanel() {
		this.isSidePanelOpen = false;
		this.sidePanelState = null;
	}

	@action
	closeAddEditContractTray() {
		this.addEditCropContractSubmitErrors = [];
		this.cropContractToEdit = undefined;
		this.isAddEditCropContractFormValid = false;
		this.closeSidePanel();
	}

	onSubmitAddEditCropContractForm = task(
		{ drop: true },
		async (
			createMutationArgs: Mutation_createPhysicalCropTransactionArgs | undefined,
			updateMutationArgs: Mutation_updatePhysicalCropTransactionArgs | undefined,
		) => {
			const errors: string[] = [];
			try {
				if (createMutationArgs) {
					await addPhysicalCropTransaction(this, createMutationArgs);
				} else if (updateMutationArgs) {
					await updatePhysicalCropTransaction(this, updateMutationArgs);
				}
				this.closeAddEditContractTray();
			} catch (e: unknown) {
				if (typeof e === 'string') {
					errors.push(e);
				} else if (e instanceof Object && 'message' in e && typeof e.message === 'string') {
					errors.push(e.message);
				}
				this.addEditCropContractSubmitErrors = errors;
			}
		},
	);

	@action
	onAddEditCropContractFormChange(isValid: boolean) {
		this.isAddEditCropContractFormValid = isValid;
	}

	@action
	onEditContractButtonClick(row: ContractRow) {
		const contractId = row.id;
		const contract = this.cropContracts.find((contract) => contract.id === contractId) as CropTransaction | undefined;
		if (contract) {
			this.cropContractToEdit = contract;
			this.openAddEditCropContractTray(contract);
		}
	}

	get crops() {
		return this.model.getCropContracts.data?.Crops ?? [];
	}

	get selectedCropsString() {
		if (this.cropIds.length === 0) {
			return 'All';
		}

		if (this.cropIds.length === 1) {
			return this.crops.find((crop) => crop.id === this.cropIds[0])?.name ?? '';
		}

		return `${this.cropIds.length} Selected`;
	}

	get searchFilterQueryParams() {
		const obj: {
			[key: string]: any;
		} = {};
		const searchQueryParams = ['location', 'buyer'] as const;
		searchQueryParams.forEach((param) => {
			const value = this[param];

			if (value) {
				obj[param] = {
					filterRule: 'equals',
					filterValue: value,
				};

				// set filterComponent property to specify component for custom display extended from search-filter
				const filterDisplayObj = getFilterDisplayProperty(param);
				if (filterDisplayObj && filterDisplayObj.customComponent) {
					obj[param].filterComponent = filterDisplayObj.customComponent;
				}
				// set filterLabel property to specify custom label for filter - default would be filterIdentifier (matches queryParam)
				if (filterDisplayObj && filterDisplayObj.label) {
					obj[param].filterLabel = filterDisplayObj.label;
				}
			}
		});

		return obj;
	}

	@action
	setTablePageState(newPageVal = 0) {
		this.page = newPageVal;
		resetVaultTableScroll('crop-contracts-index-table');
	}

	@action
	setDeliveryStartDate(dateObj: { endDate: string; startDate: string }) {
		const { startDate, endDate } = dateObj;
		this.deliveryStartDateStart = startDate;
		this.deliveryStartDateEnd = endDate;
	}

	@action
	setDeliveryEndDate(dateObj: { endDate: string; startDate: string }) {
		const { startDate, endDate } = dateObj;
		this.deliveryEndDateStart = startDate;
		this.deliveryEndDateEnd = endDate;
	}

	@action
	setContractToDelete(contract: PhysicalCropTransaction) {
		this.contractToDelete = contract;
	}

	@action
	closeDeleteModal() {
		this.contractToDelete = null;
	}

	@action
	addCropId(cropId: string | null) {
		if (cropId === null) {
			this.cropIds = [];
		} else if (this.cropIds.includes(cropId)) {
			this.cropIds = this.cropIds.filter((v) => v !== cropId);
		} else {
			this.cropIds = [...this.cropIds, cropId];
		}

		if (this.cropIds.length > 1 && this.cropIds.length === this.crops.length) {
			this.cropIds = [];
		}
	}

	@action
	clearSearchFilterQueryParam(filterIdentifier: SearchQueryParam) {
		this[filterIdentifier] = null;
		this.setTablePageState();
	}

	@action
	structureSearchResults(searchResults: SearchResult[]) {
		const map = new Map();

		searchResults.forEach((item) => {
			if (map.has(item.type)) {
				map.get(item.type).push({ id: item.id, name: item.name, type: item.type });
			} else {
				map.set(item.type, [{ id: item.id, name: item.name, type: item.type }]);
			}
		});

		return map;
	}

	@action
	async fetchSearchResults(searchText: string): Promise<SearchResult[]> {
		const searchResults: SearchResult[] = [];
		const buyerResults: SearchResult[] = [];
		const locationResults: SearchResult[] = [];

		const promises: Promise<void>[] = [];

		const buyerQuery = useQuery<
			{
				CropTransactions: Query['CropTransactions'];
			},
			Query_CropTransactionsArgs
		>(this, () => [
			GET_CROP_CONTRACTS_SEARCH,
			{
				variables: {
					where: {
						buyer: { contains: searchText },
					},
					scopeId: this.model.scopeId,
				},
				onComplete: (data): void => {
					buyerResults.push(
						...(data?.CropTransactions.map((CropTransaction) => ({
							type: 'Buyer',
							name: CropTransaction?.buyer ?? '',
						})) ?? []),
					);
				},
			},
		]);

		promises.push(buyerQuery.promise);

		const locationQuery = useQuery<
			{
				CropTransactions: Query['CropTransactions'];
			},
			Query_CropTransactionsArgs
		>(this, () => [
			GET_CROP_CONTRACTS_SEARCH,
			{
				variables: {
					where: {
						location: { contains: searchText },
					},
					scopeId: this.model.scopeId,
				},
				onComplete: (data): void => {
					locationResults.push(
						...(data?.CropTransactions.map((cropTransaction) => ({
							type: 'Location',
							name: cropTransaction?.location ?? '',
						})) ?? []),
					);
				},
			},
		]);

		promises.push(locationQuery.promise);

		await Promise.all(promises);

		// Buyer and Locations can have duplicates, so we need to filter them out
		// Does not preserve order
		const uniqueBuyerResults = [...new Map(buyerResults.map((item) => [item.name, item])).values()];
		const uniqueLocationResults = [...new Map(locationResults.map((item) => [item.name, item])).values()];

		// return combined set
		return [...searchResults, ...uniqueBuyerResults, ...uniqueLocationResults];
	}

	@action
	setSearchFilterQueryParam(searchResult: SearchResult) {
		const mappedSearchFilter = this.mapSearchResult(searchResult);
		if (!mappedSearchFilter.filterIdentifier) return;

		this[mappedSearchFilter.filterIdentifier] = mappedSearchFilter.filterValue;
	}

	mapSearchResult(searchResult: SearchResult) {
		let filterIdentifier: SearchQueryParam | null = null;

		switch (searchResult.type) {
			case 'Location':
				filterIdentifier = 'location';
				break;
			case 'Buyer':
				filterIdentifier = 'buyer';
				break;
		}

		return {
			filterIdentifier,
			filterValue: searchResult.id || searchResult.name || '',
		};
	}
}
