import { DateTime } from 'luxon';
import {
	CattleLotFilterDTO,
	CattleLotFeedRationUsagePerMonthFilterDTO,
	InsuranceEndorsementAllocationRatioFilterDTO,
	AggregateCurrentAllocationPositionDTO,
	AggregateCattleLotDTO,
	AggregateCattleLotFeedRationUsagePerMonthDTO,
	AggregateLedgerEntryDTO,
	CattleLot,
	InsuranceEndorsementAllocationRatio,
	TypeOfInstrument,
	TypeOfOption,
} from 'vault-client/types/graphql-types';
import { GET_CATTLE_PROJECTED_PL } from '../../../graphql/queries/businesses/business/cattle/cattle-projected-pl';
import { useQuery } from 'glimmer-apollo';
import { CattlePnlMonth } from 'vault-client/models/cattle-pnl-month';

export interface GetCattleProjectedPlQueryArgs {
	productSlugs: string[];
	customerId: string;
	startDate: string;
	endDate: string;
	cattleLotsWhere: CattleLotFilterDTO;
	feedRationUsageWhere?: CattleLotFeedRationUsagePerMonthFilterDTO;
	insuranceEndorsementWhere?: InsuranceEndorsementAllocationRatioFilterDTO;
}

/**
 * Query the cattle projected PL data using the GraphQL endpoint
 */
export function queryProjectedPl(context: any, businessId: string, startDate?: string, endDate?: string) {
	const defaultStartDate = DateTime.now().startOf('month').toISODate();
	const defaultEndDate = DateTime.now().plus({ months: 24 }).endOf('month').toISODate();

	const effectiveStartDate = startDate ?? defaultStartDate;
	const effectiveEndDate = endDate ?? defaultEndDate;

	const variables: GetCattleProjectedPlQueryArgs = {
		productSlugs: ['livestock-live-cattle', 'grain-corn', 'grain-soybeans', 'grain-soybean-meal', 'livestock-feeder-cattle'],
		customerId: businessId,
		startDate: effectiveStartDate,
		endDate: effectiveEndDate,
		cattleLotsWhere: {
			businessId: {
				equals: businessId,
			},
			AND: [{ activeEndMonthStartDate: { gte: effectiveStartDate } }, { activeEndMonthStartDate: { lte: effectiveEndDate } }],
		},
		feedRationUsageWhere: {
			businessId: {
				equals: businessId,
			},
			CattleLot: {
				activeEndMonthStartDate: {
					gte: effectiveStartDate,
					lte: effectiveEndDate,
				},
			},
		},
	};

	return useQuery(context, () => [
		GET_CATTLE_PROJECTED_PL,
		{
			variables,
			fetchPolicy: 'no-cache',
		},
	]);
}

interface PnlMonthArgs {
	date: string;
	cattleSalesRevenue: number | null;
	cattlePurchases: number | null;
	otherRevenue: number | null;
	insurance: number | null;
	feedExpenses: number | null;
	lotExpenses: number | null;
	otherExpenses: number | null;
	numCattle: number | null;
	totalWeight: number | null;
	cmeFeedRevenue: number | null;
	cmeFeedHedged: number | null;
	cmeCattleRevenue: number | null;
	cmeCattleHedged: number | null;
	netPnl: number | null;
}

/**
 * Process the GraphQL data and generate PnlMonth objects for the Projected P/L table
 */
export function generatePnlMonths(data: any, startDate?: string, endDate?: string): CattlePnlMonth[] {
	const pnlMonthsMap = new Map<string, PnlMonthArgs>();

	let startDateTime = DateTime.fromISO(startDate ?? DateTime.now().startOf('month').toISODate());
	const endDateTime = DateTime.fromISO(endDate ?? DateTime.now().endOf('month').plus({ month: 12 }).toISODate());

	while (startDateTime <= endDateTime) {
		const date = startDateTime.toISODate();
		const pnlMonthArgs: PnlMonthArgs = {
			date,
			cattleSalesRevenue: 0,
			otherRevenue: 0,
			insurance: 0,
			feedExpenses: 0,
			lotExpenses: 0,
			otherExpenses: 0,
			numCattle: 0,
			totalWeight: 0,
			cattlePurchases: 0,
			cmeFeedRevenue: 0,
			cmeCattleRevenue: 0,
			netPnl: 0,
			cmeFeedHedged: 0,
			cmeCattleHedged: 0,
		};

		pnlMonthsMap.set(date, pnlMonthArgs);
		startDateTime = startDateTime.plus({ month: 1 }).startOf('month');
	}

	// Populate data from GraphQL response
	populateCattleSalesRevenue(data?.AggregateCattleLots ?? [], pnlMonthsMap);
	populateOtherExpenses(data?.AggregateExpenseLedgerEntries ?? [], pnlMonthsMap);
	populateNonFeedRevenue(data?.AggregateRevenueLedgerEntries ?? [], pnlMonthsMap);
	populateInsurance(data?.AllocatedLrpInsuranceEndorsements ?? [], pnlMonthsMap);
	populateInsurance(data?.AllocatedLgmInsuranceEndorsements ?? [], pnlMonthsMap);
	populateFeedExpenses(data?.AggregateCattleLotFeedRationUsagePerMonths ?? [], pnlMonthsMap);
	populateLotExpenses(data?.AggregateCattleLots ?? [], pnlMonthsMap);
	populateTotalWeight(data?.CattleLots ?? [], pnlMonthsMap);
	populateCmeCattleAndFeed(data?.AggregateCurrentAllocationPositions ?? [], pnlMonthsMap);
	populateNumOfCattle(data?.CattleLots ?? [], pnlMonthsMap);

	return Array.from(pnlMonthsMap.values())
		.sortBy('date')
		.map((args) => {
			return new CattlePnlMonth(
				args.date,
				args.cattleSalesRevenue,
				args.otherRevenue,
				args.insurance,
				args.feedExpenses,
				args.lotExpenses,
				args.otherExpenses,
				args.numCattle,
				args.totalWeight,
				args.cmeCattleRevenue,
				args.cmeFeedRevenue,
				args.cmeCattleHedged,
				args.cmeFeedHedged,
			);
		});
}

/**
 * Helper functions to populate PnlMonthArgs with data from the GraphQL response
 */
function getPnlMonthArgs(date: string, pnlMonthsMap: Map<string, PnlMonthArgs>) {
	if (pnlMonthsMap.has(date)) {
		return pnlMonthsMap.get(date) as PnlMonthArgs;
	}
	return undefined;
}

function populateCattleSalesRevenue(cattleLots: AggregateCattleLotDTO[], pnlMonthsMap: Map<string, PnlMonthArgs>) {
	cattleLots.forEach((cattleLot: AggregateCattleLotDTO) => {
		const startDate = cattleLot.activeEndMonthStartDate;
		if (!startDate) return;

		const date = DateTime.fromISO(startDate).startOf('month').toISODate();
		const pnlMonth = getPnlMonthArgs(date, pnlMonthsMap);
		if (!pnlMonth) return;

		pnlMonth.cattleSalesRevenue = pnlMonth.cattleSalesRevenue
			? pnlMonth.cattleSalesRevenue + (cattleLot.sum.forecastedSalesRevenueInUsd ?? 0)
			: cattleLot.sum.forecastedSalesRevenueInUsd ?? 0;
	});
}

function populateOtherExpenses(expenses: AggregateLedgerEntryDTO[], pnlMonthsMap: Map<string, PnlMonthArgs>) {
	expenses.forEach((expense: AggregateLedgerEntryDTO) => {
		const month = expense.month;
		const year = expense.year;
		const expenseAmount = expense.sum.calculatedAmount;

		if (!month || !year || expenseAmount == null) return;

		const date = DateTime.fromObject({ month, year }).toISODate();
		const pnlMonth = getPnlMonthArgs(date, pnlMonthsMap);
		if (!pnlMonth) return;

		pnlMonth.otherExpenses = pnlMonth.otherExpenses ? pnlMonth.otherExpenses + expenseAmount : expenseAmount;
	});
}

function populateNonFeedRevenue(revenues: AggregateLedgerEntryDTO[], pnlMonthsMap: Map<string, PnlMonthArgs>) {
	revenues.forEach((revenue: AggregateLedgerEntryDTO) => {
		const month = revenue.month;
		const year = revenue.year;
		const revenueAmount = revenue.sum.calculatedAmount;

		if (!month || !year || revenueAmount == null) return;

		const date = DateTime.fromObject({ month, year }).toISODate();
		const pnlMonth = getPnlMonthArgs(date, pnlMonthsMap);
		if (!pnlMonth) return;

		pnlMonth.otherRevenue = pnlMonth.otherRevenue ? pnlMonth.otherRevenue + revenueAmount : revenueAmount;
	});
}

function populateInsurance(insurance: InsuranceEndorsementAllocationRatio[], pnlMonthsMap: Map<string, PnlMonthArgs>) {
	insurance.forEach((endorsement) => {
		const effectiveHedgeDate = endorsement.effectiveHedgeDate;
		const pnl = endorsement.RatioAdjustedInsuranceEndorsement.pnl;

		if (!effectiveHedgeDate || pnl == null) return;

		const pnlMonth = getPnlMonthArgs(effectiveHedgeDate, pnlMonthsMap);
		if (!pnlMonth) return;

		pnlMonth.insurance = pnlMonth.insurance ? pnlMonth.insurance + pnl : pnl;
	});
}

function populateFeedExpenses(feedExpenses: AggregateCattleLotFeedRationUsagePerMonthDTO[], pnlMonthsMap: Map<string, PnlMonthArgs>) {
	feedExpenses.forEach((feedUsage: AggregateCattleLotFeedRationUsagePerMonthDTO) => {
		const startOfMonth = feedUsage.CattleLot?.activeEndMonthStartDate;
		const totalExpense = feedUsage.sum.totalExpenseInUsd ? -feedUsage.sum.totalExpenseInUsd : 0;

		if (!startOfMonth || totalExpense == null) return;

		const pnlMonth = getPnlMonthArgs(startOfMonth, pnlMonthsMap);
		if (!pnlMonth) return;

		pnlMonth.feedExpenses = pnlMonth.feedExpenses ? pnlMonth.feedExpenses + totalExpense : totalExpense;
	});
}

function populateLotExpenses(cattleLots: AggregateCattleLotDTO[], pnlMonthsMap: Map<string, PnlMonthArgs>) {
	cattleLots.forEach((cattleLot: AggregateCattleLotDTO) => {
		const startDate = cattleLot.activeEndMonthStartDate;
		if (!startDate) return;

		const date = DateTime.fromISO(startDate).startOf('month').toISODate();
		const pnlMonth = getPnlMonthArgs(date, pnlMonthsMap);
		if (!pnlMonth) return;

		pnlMonth.lotExpenses = pnlMonth.lotExpenses
			? pnlMonth.lotExpenses + (cattleLot.sum.cattleLotTotalExpensesInUsd ?? 0)
			: cattleLot.sum.cattleLotTotalExpensesInUsd ?? 0;

		pnlMonth.lotExpenses = pnlMonth.lotExpenses
			? pnlMonth.lotExpenses + -(cattleLot.sum.purchasePriceInUsd ?? 0)
			: -(cattleLot.sum.purchasePriceInUsd ?? 0);
	});
}

function populateTotalWeight(cattleLots: CattleLot[], pnlMonthsMap: Map<string, PnlMonthArgs>) {
	cattleLots.forEach((cattleLot: CattleLot) => {
		const endMonth = DateTime.fromISO(cattleLot.activeEndDate).startOf('month').toISODate();
		if (!endMonth) return;

		const pnlMonth = getPnlMonthArgs(endMonth, pnlMonthsMap);
		if (!pnlMonth) return;

		const lotTotalWeight = (cattleLot.targetWeightInLb ?? 0) * (cattleLot.numberOfCattle ?? 0);
		pnlMonth.totalWeight = pnlMonth.totalWeight ? pnlMonth.totalWeight + lotTotalWeight : lotTotalWeight;
	});
}

function populateNumOfCattle(cattleLots: CattleLot[], pnlMonthsMap: Map<string, PnlMonthArgs>) {
	cattleLots.forEach((cattleLot: CattleLot) => {
		const endMonth = DateTime.fromISO(cattleLot.activeEndDate).startOf('month').toISODate();
		if (!endMonth) return;

		const pnlMonth = getPnlMonthArgs(endMonth, pnlMonthsMap);
		if (!pnlMonth) return;

		pnlMonth.numCattle = pnlMonth.numCattle ? pnlMonth.numCattle + (cattleLot.numberOfCattle ?? 0) : cattleLot.numberOfCattle ?? 0;
	});
}

function populateCmeCattleAndFeed(cmeCattle: AggregateCurrentAllocationPositionDTO[], pnlMonthsMap: Map<string, PnlMonthArgs>) {
	cmeCattle.forEach((currentPosition: AggregateCurrentAllocationPositionDTO) => {
		const date = currentPosition.effectiveHedgeDate;
		const productSlug = currentPosition?.Product?.slug ?? '';
		const quantity = currentPosition.sum.contractQuantity;
		const instrumentType = currentPosition.instrumentType;
		const optionType = currentPosition.optionType;
		if (!date || quantity === null || quantity === undefined) return;

		const startOfMonth = DateTime.fromISO(date).startOf('month').toISODate();
		const pnlMonth = getPnlMonthArgs(startOfMonth, pnlMonthsMap);
		if (!pnlMonth) return;

		const grossPnl = currentPosition.sum.grossPnl ?? 0;

		if (['livestock-feeder-cattle', 'livestock-live-cattle'].includes(productSlug)) {
			// Determine if this is a hedge or revenue position based on contractQuantity and instrument type
			if (
				// Short futures and Short Puts are cattle hedges, others are revenue
				(quantity != null && instrumentType === TypeOfInstrument.Future && quantity < 0) ||
				(quantity != null && instrumentType === TypeOfInstrument.Option && optionType === TypeOfOption.Put && quantity < 0)
			) {
				pnlMonth.cmeCattleHedged = pnlMonth.cmeCattleHedged ? pnlMonth.cmeCattleHedged + grossPnl : grossPnl;
			} else {
				pnlMonth.cmeCattleRevenue = pnlMonth.cmeCattleRevenue ? pnlMonth.cmeCattleRevenue + grossPnl : grossPnl;
			}
		} else {
			// Feed related positions
			if (
				// long futures and long calls are feed hedges, others are revenue
				(quantity != null && instrumentType === TypeOfInstrument.Future && quantity > 0) ||
				(quantity != null && instrumentType === TypeOfInstrument.Option && optionType === TypeOfOption.Call && quantity > 0)
			) {
				pnlMonth.cmeFeedHedged = pnlMonth.cmeFeedHedged ? pnlMonth.cmeFeedHedged + grossPnl : grossPnl;
			} else {
				pnlMonth.cmeFeedRevenue = pnlMonth.cmeFeedRevenue ? pnlMonth.cmeFeedRevenue + grossPnl : grossPnl;
			}
		}
	});
}
