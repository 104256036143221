import type Service from '@ember/service';
import { productSlugs } from 'vault-client/utils/type-utils';

export enum FeedIngredientBase {
	'Corn' = 'Corn',
	'SoybeanMeal' = 'SoybeanMeal',
	'Other' = 'Other',
}

export interface SearchResult {
	id?: string;
	name?: string;
	type?: string;
}

export enum InternalChartType {
	HistoricalPercentile = 'historical-percentile',
	HistoricalFuturePrices = 'historical-future-prices',
}

export type MediaService = Service & {
	isMobile: boolean;
	isTablet: boolean;
	isLaptop: boolean;
	isDesktop: boolean;
	isJumpbo: boolean;
};

export type ProductSlug = (typeof productSlugs)[number];
