import { QueryOptions, useQuery } from 'glimmer-apollo';
import { CATTLE_RATION_INGREDIENTS_LIST_QUERY } from 'vault-client/graphql/queries/option-dropdown-lists/cattle-lists';
import type { CattleFeedRationStageIngredientFilterDTO } from 'vault-client/types/graphql-types';

interface CattleRationIngredientsListData {
	CattleFeedRationStageIngredients: Array<{
		id: string;
		FeedIngredient: {
			id: string;
			name: string;
		};
	}>;
}

interface QueryVariables {
	where?: CattleFeedRationStageIngredientFilterDTO;
}

export function useRationIngredientsListQuery(context: object, options: QueryOptions<CattleRationIngredientsListData, QueryVariables>) {
	return useQuery<CattleRationIngredientsListData, QueryVariables>(context, () => [CATTLE_RATION_INGREDIENTS_LIST_QUERY, options]);
}
