import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{this.formattedValue}}", {"contents":"{{this.formattedValue}}","moduleName":"vault-client/components/intl-number-format.hbs","parseOptions":{"srcName":"vault-client/components/intl-number-format.hbs"}});
import Component from '@glimmer/component';

function getByCompositeKey(obj: any, key: any) {
	// turn this into utility function?
	if (!key) {
		return null;
	}

	return key.split('.').reduce((prev: any, itm: any) => (prev ? prev[itm] : null), obj) || null;
}

interface Args {
	valuePath: any;
	rowData: any;
	value: any;
	style: any;
	localeMatcher: any;
	currency: any;
	currencyDisplay: any;
	useGrouping: any;
	minimumIntegerDigits: number;
	minimumFractionDigits: number;
	maximumFractionDigits: number;
	minimumSignificantDigits: number;
	maximumSignificantDigits: number;
	currencySign: number;
	notation: number;
	compactDisplay: number;
	locales: string;
	emptyValue: string;
	transform?: (value: unknown, rowData: unknown) => number | null;
}

export default class IntlNumberFormat extends Component<Args> {
	constructor(owner: any, args: Args) {
		super(owner, args);
	}

	get emptyValue() {
		if (this.args.emptyValue !== undefined) return this.args.emptyValue;

		return '-';
	}

	get formattedValue() {
		const { valuePath, rowData, value: rawValue, transform } = this.args;
		const parsedValue = valuePath && rowData ? getByCompositeKey(rowData, valuePath) : rawValue;
		const value = transform ? transform(parsedValue, rowData) : parsedValue;

		if (value !== null && value !== undefined) {
			const options: Intl.NumberFormatOptions = {};

			Object.assign(
				options,
				this.args.style && {
					style: this.args.style,
				},
				this.args.localeMatcher && {
					localeMatcher: this.args.localeMatcher,
				},
				this.args.currency && {
					currency: this.args.currency,
				},
				this.args.currencyDisplay && {
					currencyDisplay: this.args.currencyDisplay,
				},
				this.args.useGrouping && {
					useGrouping: this.args.useGrouping,
				},
				this.args.minimumIntegerDigits != undefined && {
					minimumIntegerDigits: this.args.minimumIntegerDigits,
				},
				this.args.minimumFractionDigits != undefined && {
					minimumFractionDigits: this.args.minimumFractionDigits,
				},
				this.args.maximumFractionDigits != undefined && {
					maximumFractionDigits: this.args.maximumFractionDigits,
				},
				this.args.minimumSignificantDigits != undefined && {
					minimumSignificantDigits: this.args.minimumSignificantDigits,
				},
				this.args.maximumSignificantDigits != undefined && {
					maximumSignificantDigits: this.args.maximumSignificantDigits,
				},
				this.args.currencySign && {
					currencySign: this.args.currencySign,
				},
				this.args.notation && {
					notation: this.args.notation,
				},
				this.args.compactDisplay && {
					compactDisplay: this.args.compactDisplay,
				},
			);

			if (this.args.locales) {
				return new Intl.NumberFormat(this.args.locales, options).format(value);
			} else {
				return new Intl.NumberFormat(undefined, options).format(value);
			}
		} else {
			return this.emptyValue;
		}
	}
}
