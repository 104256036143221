import Controller from '@ember/controller';
import { DateTime } from 'luxon';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import query from 'vault-client/graphql/queries/exposure/show.graphql';
import { queryManager } from 'ember-apollo-client';
import searchQuery from 'vault-client/graphql/queries/search.graphql';
import accountFuzzyQuery from 'vault-client/graphql/queries/account-fuzzy-match.graphql';
import getFilterDisplayProperty from 'vault-client/utils/get-filter-display-property';
import isTouchDevice from 'vault-client/utils/is-touch-device';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import { ModelFrom } from 'vault-client/utils/type-utils';
import tonsPerFuturesContract from 'vault-client/utils/tons-per-futures-contract';
import BusinessesBusinessExposureRoute from 'vault-client/routes/businesses/business/exposure';
import OrganizationsOrganizationExposureRoute from 'vault-client/routes/organizations/organization/exposure';
import {
	InstrumentSymbolGroup,
	InsuranceEndorsementAllocationRatio,
	LgmInsuranceEndorsement,
	TypeOfInsuranceEndorsement,
	LrpInsuranceEndorsement,
	TypeOfInstrumentSymbolGroup,
	Future,
	FeedTransaction,
} from 'vault-client/types/graphql-types';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import { lgmTotalTargetToContractUnit, lgmTotalTargetToContracts } from 'vault-client/utils/insurance-utils';
import Big from 'big.js';

interface ExposureRow {
	futureQuantity: number;
	futureGrossProtection: number;
}

interface FeedTransactionMonthGroup {
	transactions: FeedTransaction[];
	totalTons: number;
}

interface FeedTransactionGroupedByMonth {
	[month: string]: FeedTransactionMonthGroup;
}

class ExposureRow {
	date: string;

	insuredGrossProtection: number | undefined;
	insuredQuantity: number | undefined;
	insuredContracts: number | undefined;
	estimatedIndemnity: number | undefined;

	futureInstrument: Future | undefined;

	longPutQuantity: number | undefined;
	longPutGrossProtection: number | undefined;

	shortPutQuantity: number | undefined;
	shortPutGrossProtection: number | undefined;

	longCallQuantity: number | undefined;
	longCallGrossProtection: number | undefined;

	shortCallQuantity: number | undefined;
	shortCallGrossProtection: number | undefined;

	lrpAvgInsuredPrice: number | null = null;
	lrpInsuredContracts: number = 0;

	lgmContracts: number | undefined;

	totalTons: number | undefined;
	contractEquivalent: number | undefined;

	constructor(date: string) {
		this.date = date;
		this.futureQuantity = 0;
		this.futureGrossProtection = 0;
	}

	get longPutAvgStrike() {
		return this.longPutQuantity
			? Big(this.longPutGrossProtection ?? 0)
					.div(this.longPutQuantity)
					.toNumber()
			: undefined;
	}

	get shortPutAvgStrike() {
		return this.shortPutQuantity
			? Big(this.shortPutGrossProtection ?? 0)
					.div(this.shortPutQuantity)
					.toNumber()
			: undefined;
	}

	get longCallAvgStrike() {
		return this.longCallQuantity
			? Big(this.longCallGrossProtection ?? 0)
					.div(this.longCallQuantity)
					.toNumber()
			: undefined;
	}

	get shortCallAvgStrike() {
		return this.shortCallQuantity
			? Big(this.shortCallGrossProtection ?? 0)
					.div(this.shortCallQuantity)
					.toNumber()
			: undefined;
	}

	get avgInsuredPrice() {
		if (this.insuredGrossProtection && this.insuredQuantity) {
			return this.insuredGrossProtection / this.insuredQuantity;
		} else {
			return null;
		}
	}

	get exposureMonthStartDate() {
		return DateTime.fromISO(this.date).startOf('month').toISODate();
	}

	get exposureMonthEndDate() {
		return DateTime.fromISO(this.date).endOf('month').toISODate();
	}
}

export default class ExposureShowController extends Controller {
	@queryManager apollo: any;
	@service permissions: any;
	@service applicationScope: any;

	declare model: ModelFrom<OrganizationsOrganizationExposureRoute> | ModelFrom<BusinessesBusinessExposureRoute>;

	exposuresRoutePath = '';
	exposureRoutePath = '';
	// If null, the exposure detail link will be disabled
	exposureHedgeMonthDetailRoutePath: string | null = null;

	queryParams = ['accountId', 'exposureMonthStartDate', 'exposureMonthEndDate', 'sorts'];

	//The logic of the next two lines makes sure that if it is the first half of the month, show the current month as part of the next X months.
	startDateForFutureMonths = DateTime.local().day <= 15 ? DateTime.local() : DateTime.local().plus({ months: 1 });
	//and If it is the last half of the month, we want to show the current month as part of the last X months.
	endDateForPreviousMonths = DateTime.local().day <= 15 ? DateTime.local().minus({ months: 1 }) : DateTime.local();

	exposureMonthRangeOptions = [
		{
			displayName: 'Next 24 Months',
			startDate: this.startDateForFutureMonths.startOf('month').toISODate(),
			endDate: this.startDateForFutureMonths.plus({ months: 23 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Previous 24 Months',
			startDate: this.endDateForPreviousMonths.minus({ months: 23 }).startOf('month').toISODate(),
			endDate: this.endDateForPreviousMonths.endOf('month').toISODate(),
		},
		{
			displayName: 'Next 12 Months',
			startDate: this.startDateForFutureMonths.startOf('month').toISODate(),
			endDate: this.startDateForFutureMonths.plus({ months: 11 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Previous 12 Months',
			startDate: this.endDateForPreviousMonths.minus({ months: 11 }).startOf('month').toISODate(),
			endDate: this.endDateForPreviousMonths.endOf('month').toISODate(),
		},
		{
			displayName: 'Next 3 Months',
			startDate: this.startDateForFutureMonths.startOf('month').toISODate(),
			endDate: this.startDateForFutureMonths.plus({ months: 2 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Previous 3 Months',
			startDate: this.endDateForPreviousMonths.minus({ months: 2 }).startOf('month').toISODate(),
			endDate: this.endDateForPreviousMonths.endOf('month').toISODate(),
		},
	];

	@tracked accountId = null;
	@tracked exposureMonthStartDate = this.startDateForFutureMonths.startOf('month').toISODate();
	@tracked exposureMonthEndDate = this.startDateForFutureMonths.plus({ months: 23 }).endOf('month').toISODate();
	@tracked sorts = [];

	get columns() {
		const COLUMN_IDS = {
			DRP_INSURANCE: '0da80bd1-308c-4bc8-a294-e6cb76736bf8',
			LRP_INSURANCE: '9a827749-fa92-4571-95c7-0fc1d1573a5b',
			LGM_INSURANCE: '96d67791-8159-4171-b83d-b1a5c3bf6fda',
			PHYSICAL_FILLS: '47be8d3f-021e-4559-b1bd-2dc975f8debc',
		};

		const isDrpProduct = ['us-dairy-class-iii', 'us-dairy-class-iv'].includes(this.product.slug);
		const isLgmProduct = [
			'livestock-feeder-cattle',
			'livestock-lean-hogs',
			'livestock-live-cattle',
			'livestock-pork-cutouts',
			'us-dairy-class-iii',
		].includes(this.product.slug);
		const isFeedProduct = ['grain-corn', 'grain-soybean-meal'].includes(this.product.slug);
		const isClassIII = this.product.slug === 'us-dairy-class-iii';

		const baseColumns: TableColumn[] = [
			{
				id: 'ed8f7b0c-f9a6-48d3-a170-f70c226779c9',
				name: 'Month',
				valuePath: 'date',
				...(this.exposureHedgeMonthDetailRoutePath && {
					linkRoute: this.exposureHedgeMonthDetailRoutePath,
					linkModelPaths: ['slug'],
					linkQuery: {
						exposureMonthStartDate: {
							rowKey: 'exposureMonthStartDate',
						},
						exposureMonthEndDate: {
							rowKey: 'exposureMonthEndDate',
						},
					},
				}),
				minWidth: 100,
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: !isTouchDevice() ? 'left' : '',
				isVisible: true,
			},
			{
				id: '93708b7a-51de-4fa9-8a6c-797e2ca473cd',
				name: '5 yr %',
				valuePath: 'futureInstrument',
				minWidth: 85,
				cellComponent: CellComponents.HistoricalPriceRangeFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'b13cf9b1-4205-426b-b5bb-e5d43422f343',
				name: 'Last Price',
				valuePath: 'futureInstrument',
				minWidth: 105,
				cellComponent: CellComponents.MarketPriceFormat,
				componentArgs: {
					fractionDigitsPath: 'futureInstrument.SymbolGroup.fractionDigits',
					displayFactorPath: 'futureInstrument.SymbolGroup.displayFactor',
					side: 'last',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'fe717398-b34f-4b9e-9623-cd6dd0201052',
				name: 'Session Change',
				valuePath: 'futureInstrument',
				minWidth: 140,
				cellComponent: CellComponents.SessionChangeFormat,
				componentArgs: {
					fractionDigitsPath: 'futureInstrument.SymbolGroup.fractionDigits',
					displayFactorPath: 'futureInstrument.SymbolGroup.displayFactor',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},

			{
				id: COLUMN_IDS.LGM_INSURANCE,
				name: 'LGM Insurance',
				textAlign: '',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '1a05b8a5-29ce-4fdd-9f69-7b4e665f4c6c',
						name: 'Contracts',
						valuePath: 'lgmContracts',
						width: 130,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							minimumFractionDigits: 0,
							maximumFractionDigits: 3,
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: COLUMN_IDS.DRP_INSURANCE,
				name: isDrpProduct ? 'DRP Insurance' : 'Insurance',
				textAlign: '',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: 'b0d780d7-78ce-467c-b4a5-df7e5304c1f1',
						name: 'Avg. Price',
						valuePath: 'avgInsuredPrice',
						minWidth: 100,
						cellComponent: CellComponents.PriceFormat,
						componentArgs: {
							displayFactorPath: 'futureInstrument.SymbolGroup.displayFactor',
							fractionDigitsPath: 'futureInstrument.SymbolGroup.fractionDigits',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '3ff9f97d-af0b-44f2-b747-fc2dd942f475',
						name: 'Contracts',
						valuePath: 'insuredContracts',
						minWidth: 110,
						cellComponent: CellComponents.IntlNumberFormat,
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: COLUMN_IDS.LRP_INSURANCE,
				name: 'LRP Insurance',
				textAlign: '',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '71048af0-c4df-4397-9310-e415938033e9',
						name: 'Avg. Floor',
						valuePath: 'lrpAvgInsuredPrice',
						minWidth: 100,
						cellComponent: CellComponents.PriceFormat,
						componentArgs: {
							displayFactorPath: 'futureSymbolGroup.displayFactor',
							fractionDigitsPath: 'futureSymbolGroup.fractionDigits',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: 'd3f3bebb-b0d9-4217-aed4-90087b07ffdf',
						name: 'Contracts',
						valuePath: 'lrpInsuredContracts',
						minWidth: 110,
						cellComponent: CellComponents.IntlNumberFormat,
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: 'd03c4864-6163-469f-93f8-f3d02fb23dd3',
				name: 'Futures',
				textAlign: '',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '9eaf4039-a35a-4129-bfdd-b33d60b4cb95',
						name: 'Contracts',
						valuePath: 'futureQuantity',
						minWidth: 110,
						cellComponent: CellComponents.IntlNumberFormat,
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: COLUMN_IDS.PHYSICAL_FILLS,
				name: 'Physical Fills',
				textAlign: '',
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '208542fb-6936-4382-97dd-a220e6a4b75a',
						name: 'Total Tons',
						valuePath: 'totalTons',
						minWidth: 150,
						cellComponent: CellComponents.IntlNumberFormat,
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: 'aa29e501-7406-4754-9c82-8c0c4807ee84',
						name: 'Contract Equivalent',
						valuePath: 'contractEquivalent',
						minWidth: 150,
						cellComponent: CellComponents.IntlNumberFormat,
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '5c3d06cc-35a8-413e-a54a-44099bc6142d',
				name: 'Long Puts',
				textAlign: '',
				cellComponent: CellComponents.String,
				isSortable: false,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '0d3eb2d3-8983-44ec-8dce-d8038caa951e',
						name: 'Avg. Strike',
						valuePath: 'longPutAvgStrike',
						minWidth: 110,
						cellComponent: CellComponents.PriceFormat,
						componentArgs: {
							displayFactorPath: 'optionSymbolGroup.displayFactor',
							fractionDigitsPath: 'optionSymbolGroup.fractionDigits',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '6c1cd02e-7ffa-4f29-948a-f48d07abe876',
						name: 'Contracts',
						valuePath: 'longPutQuantity',
						minWidth: 110,
						cellComponent: CellComponents.IntlNumberFormat,
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '8de55591-1238-4c75-b36e-c3a3418627cd',
				name: 'Short Puts',
				textAlign: '',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: 'e07d50b5-5d73-4052-a3f3-f59b4474738b',
						name: 'Avg. Strike',
						valuePath: 'shortPutAvgStrike',
						minWidth: 110,
						cellComponent: CellComponents.PriceFormat,
						componentArgs: {
							displayFactorPath: 'optionSymbolGroup.displayFactor',
							fractionDigitsPath: 'optionSymbolGroup.fractionDigits',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '20e4c7db-3140-4386-9bd4-f848adad68d5',
						name: 'Contracts',
						valuePath: 'shortPutQuantity',
						minWidth: 110,
						cellComponent: CellComponents.IntlNumberFormat,
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: 'ab957c90-498d-4368-8da3-a11893fe9ec0',
				name: 'Long Calls',
				textAlign: '',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: 'aeca9a13-a0f0-48c8-800e-452e5eafcfed',
						name: 'Avg. Strike',
						valuePath: 'longCallAvgStrike',
						minWidth: 110,
						cellComponent: CellComponents.PriceFormat,
						componentArgs: {
							displayFactorPath: 'optionSymbolGroup.displayFactor',
							fractionDigitsPath: 'optionSymbolGroup.fractionDigits',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '788489d8-fa80-4a43-b1ca-b4c804586397',
						name: 'Contracts',
						valuePath: 'longCallQuantity',
						minWidth: 110,
						cellComponent: CellComponents.IntlNumberFormat,
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '6aa5c3d9-b865-4277-ac89-4a0ee3a28849',
				name: 'Short Calls',
				textAlign: '',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '5fe9fe3d-7074-45e5-8825-536d0bd92516',
						name: 'Avg. Strike',
						valuePath: 'shortCallAvgStrike',
						minWidth: 110,
						cellComponent: CellComponents.PriceFormat,
						componentArgs: {
							displayFactorPath: 'optionSymbolGroup.displayFactor',
							fractionDigitsPath: 'optionSymbolGroup.fractionDigits',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '7614b88c-9a11-45db-8130-3d213391a9e9',
						name: 'Contracts',
						valuePath: 'shortCallQuantity',
						minWidth: 110,
						cellComponent: CellComponents.IntlNumberFormat,
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
		];

		const excludedColumnIds = new Set();

		if (!this.permissions.showInsurance || !isDrpProduct) {
			excludedColumnIds.add(COLUMN_IDS.DRP_INSURANCE);
		}

		if (!isLgmProduct) {
			excludedColumnIds.add(COLUMN_IDS.LRP_INSURANCE);
			excludedColumnIds.add(COLUMN_IDS.LGM_INSURANCE);
		}

		if (!isFeedProduct) {
			excludedColumnIds.add(COLUMN_IDS.PHYSICAL_FILLS);
		}

		if (isClassIII) {
			excludedColumnIds.add(COLUMN_IDS.LRP_INSURANCE);
		}

		return baseColumns.filter((column) => !excludedColumnIds.has(column.id));
	}

	get searchFilterQueryParams() {
		const obj: {
			[key: string]: any;
		} = {};

		const searchQueryParams = ['customerId', 'accountId'];
		searchQueryParams.forEach((param) => {
			//@ts-ignore
			const value = param === 'customerId' && this.applicationScope.globalCustomerId ? this.applicationScope.globalCustomerId : this[param];
			if (value) {
				obj[param] = {
					filterRule: 'equals',
					filterValue: value,
				};

				// set filterComponent property to specify component for custom display extended from search-filter
				const filterDisplayObj = getFilterDisplayProperty(param);
				if (filterDisplayObj && filterDisplayObj.customComponent) {
					obj[param].filterComponent = filterDisplayObj.customComponent;
				}
				// set filterLabel property to specify custom label for filter - default would be filterIdentifier (matches queryParam)
				if (filterDisplayObj && filterDisplayObj.label) {
					obj[param].filterLabel = filterDisplayObj.label;
				}
			}
		});

		return obj;
	}

	get scopeSearchFilterQueryParams() {
		if (this.applicationScope.globalCustomerId) {
			// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
			const { customerId, ...rest } = this.searchFilterQueryParams;
			return rest;
		} else {
			return this.searchFilterQueryParams;
		}
	}

	get exposureMonthQueryParam() {
		return {
			startDate: this.exposureMonthStartDate,
			endDate: this.exposureMonthEndDate,
		};
	}

	get product() {
		return this.model.Products[0];
	}

	get rows() {
		const startDate = this.exposureMonthStartDate
			? DateTime.fromISO(this.exposureMonthStartDate).startOf('month').toISODate()
			: '1900-01-01';
		const endDate = this.exposureMonthEndDate ? DateTime.fromISO(this.exposureMonthEndDate).startOf('month').toISODate() : '2999-12-31';
		const exposureRows: {
			[key: string]: ExposureRow;
		} = {};
		let date = startDate;

		while (date <= endDate) {
			exposureRows[date] = new ExposureRow(date);
			date = DateTime.fromISO(date).plus({ months: 1 }).toISODate();
		}

		this.model.Futures.forEach((future) => {
			if (exposureRows[future.displayExpiresAt]) {
				exposureRows[future.displayExpiresAt].futureInstrument = future;
			}
		});

		this.model.AggregateCurrentAllocationPositions.forEach((position: any) => {
			const row = exposureRows[position.effectiveHedgeDate];
			if (!row) return;

			if (position.optionType == 'Put' && position.positionSide == 'Long') {
				row.longPutQuantity = (row.longPutQuantity ?? 0) + position.sum.contractQuantity;
				row.longPutGrossProtection = (row.longPutGrossProtection ?? 0) + position.sum.grossProtection;
			}

			if (position.optionType == 'Put' && position.positionSide == 'Short') {
				row.shortPutQuantity = (row.shortPutQuantity ?? 0) + position.sum.contractQuantity;
				row.shortPutGrossProtection = (row.shortPutGrossProtection ?? 0) + position.sum.grossProtection;
			}

			if (position.optionType == 'Call' && position.positionSide == 'Long') {
				row.longCallQuantity = (row.longCallQuantity ?? 0) + position.sum.contractQuantity;
				row.longCallGrossProtection = (row.longCallGrossProtection ?? 0) + position.sum.grossProtection;
			}

			if (position.optionType == 'Call' && position.positionSide == 'Short') {
				row.shortCallQuantity = (row.shortCallQuantity ?? 0) + position.sum.contractQuantity;
				row.shortCallGrossProtection = (row.shortCallGrossProtection ?? 0) + position.sum.grossProtection;
			}

			if (position.instrumentType == 'Future' || position.instrumentType == 'Swap') {
				row.futureQuantity += position.sum.contractQuantity;
				row.futureGrossProtection += position.sum.grossProtection;
			}
		});

		this.model.AggregateDerivedDrpEndorsements.forEach((aggregate: any) => {
			const startDate = DateTime.fromISO(aggregate.quarterStartDate);
			const months = [aggregate.quarterStartDate, startDate.plus({ months: 1 }).toISODate(), startDate.plus({ months: 2 }).toISODate()];

			months.forEach((month) => {
				const row = exposureRows[month];

				if (!row) {
					return;
				}
				switch (this.product.slug) {
					case 'us-dairy-class-iii':
						row.insuredGrossProtection = aggregate.sum.classIiiGrossProtection / 3;
						row.insuredQuantity = aggregate.sum.classIiiPounds / 100 / 3;
						row.insuredContracts = aggregate.sum.classIiiPounds / 3 / 200000;
						row.estimatedIndemnity = aggregate.sum.indemnityEffectClassIii / 3;
						break;
					case 'us-dairy-class-iv':
						row.insuredGrossProtection = aggregate.sum.classIvGrossProtection / 3;
						row.insuredQuantity = aggregate.sum.classIvPounds / 100 / 3;
						row.insuredContracts = aggregate.sum.classIvPounds / 3 / 200000;
						row.estimatedIndemnity = aggregate.sum.indemnityEffectClassIv / 3;
						break;
					case 'us-dairy-cheese':
						row.insuredGrossProtection = aggregate.sum.cheeseGrossProtection / 3;
						row.insuredQuantity = aggregate.sum.excessCheesePounds / 3;
						row.insuredContracts = row.insuredQuantity / 20000;
						row.estimatedIndemnity = aggregate.sum.indemnityEffectCheese / 3;
						break;
					case 'us-dairy-butter':
						row.insuredGrossProtection = aggregate.sum.butterGrossProtection / 3;
						row.insuredQuantity = aggregate.sum.excessButterPounds / 3;
						row.insuredContracts = row.insuredQuantity / 20000;
						row.estimatedIndemnity = aggregate.sum.indemnityEffectButter / 3;
						break;
				}
			});
		});

		// LRP Insurance Endorsements
		const lrpEndorsementsMap = new Map<string, { price: number; contracts: number }[]>();
		this.model.LrpInsuranceEndorsements.forEach((allocation) => {
			if (!allocation.effectiveHedgeDate) return;
			const month = allocation.effectiveHedgeDate;
			const endorsement = allocation.RatioAdjustedInsuranceEndorsement as LrpInsuranceEndorsement;

			const endoresmentHedgedContractUnits = endorsement.headCount * (endorsement.targetWeightCwt * 100) * endorsement.declaredShare;
			const endorsementInsuredContracts = endoresmentHedgedContractUnits / this.product.StandardProductLotSpecification.lotSize;

			if (!lrpEndorsementsMap.get(month)) {
				lrpEndorsementsMap.set(month, []);
			}

			const mapMonth = lrpEndorsementsMap.get(month);

			mapMonth?.push({ contracts: endorsementInsuredContracts, price: endorsement.coveragePrice });
		});

		lrpEndorsementsMap.forEach((lrpEndorsements, month) => {
			const totalContracts = lrpEndorsements.reduce((acc, curr) => acc + curr.contracts, 0);
			const avgInsuredPrice = lrpEndorsements.length
				? lrpEndorsements.reduce((acc, curr) => acc + curr.price * (curr.contracts / totalContracts), 0)
				: null;

			const row = exposureRows[month];
			if (!row) return;

			row.lrpAvgInsuredPrice = avgInsuredPrice;
			row.lrpInsuredContracts = totalContracts;
		});

		// LGM Insurance Endorsements
		const productSlug = this.product.slug;
		const lgmEndorsementsMap = new Map<string, { contracts: number }>();
		this.model.LgmInsuranceEndorsements.forEach((allocation: InsuranceEndorsementAllocationRatio) => {
			const isLgmEndorsement = allocation.RatioAdjustedInsuranceEndorsement.type === TypeOfInsuranceEndorsement.Lgm;
			if (!isLgmEndorsement) return;

			const month = allocation?.effectiveHedgeDate;
			if (!month) return;

			const lgmEndorsement = allocation.RatioAdjustedInsuranceEndorsement as LgmInsuranceEndorsement;

			const totalTarget = lgmEndorsement.totalTarget;
			const commodityCode = lgmEndorsement.RmaType.commodityCode;
			const typeCode = lgmEndorsement.RmaType.typeCode;

			const contractUnits = lgmTotalTargetToContractUnit(totalTarget, commodityCode, typeCode);
			const contracts = lgmTotalTargetToContracts(totalTarget, commodityCode, typeCode, productSlug);
			if (contracts == null || contractUnits == null) {
				console.warn('Could not calculate contracts for LGM endorsement', lgmEndorsement);
				return;
			}

			const existing = lgmEndorsementsMap.get(month) || { contracts: 0 };
			lgmEndorsementsMap.set(month, {
				contracts: existing.contracts + contracts,
			});
		});

		lgmEndorsementsMap.forEach((lgmEndorsement, month) => {
			const row = exposureRows[month];
			if (!row) return;

			const contracts = lgmEndorsement.contracts;

			row.lgmContracts = contracts;
		});

		const transactionsGroupedByMonth = this.groupTransactionsByEndMonth(this.model.FeedTransactions);

		Object.entries(transactionsGroupedByMonth).forEach(([month, data]) => {
			const row = exposureRows[month];

			if (!row) {
				return;
			}

			row.totalTons = data.totalTons;
			row.contractEquivalent = data.totalTons / tonsPerFuturesContract(this.product.slug);
		});

		Object.values(exposureRows).forEach((period: any) => {
			period.product = this.product;
			period.slug = this.product.slug;
			period.optionSymbolGroup = this.product.InstrumentSymbolGroups.find(
				(group: InstrumentSymbolGroup) => group.type === TypeOfInstrumentSymbolGroup.Option,
			);
			period.futureSymbolGroup = this.product.InstrumentSymbolGroups.find(
				(group: InstrumentSymbolGroup) => group.type === TypeOfInstrumentSymbolGroup.Future,
			);
		});

		return Object.values(exposureRows).sortBy('date');
	}

	groupTransactionsByEndMonth(transactions: FeedTransaction[]): FeedTransactionGroupedByMonth {
		const groupedByMonth: FeedTransactionGroupedByMonth = {};

		transactions.forEach((transaction) => {
			transaction.perMonthData.forEach((monthData) => {
				const monthKey = monthData.date;

				if (!groupedByMonth[monthKey]) {
					groupedByMonth[monthKey] = {
						transactions: [],
						totalTons: 0,
					};
				}

				groupedByMonth[monthKey].transactions.push(transaction);
				groupedByMonth[monthKey].totalTons += monthData.tons;
			});
		});

		return groupedByMonth;
	}

	get searchPlaceholder() {
		return 'Filter by account...';
	}

	get searchPrompt() {
		return 'Type a search term to examine exposure by account.';
	}

	get query() {
		return query;
	}

	getInstrumentGroupForInstrumentType(instrumentType: string) {
		return this.product.InstrumentSymbolGroups.find((group: InstrumentSymbolGroup) => group.type === instrumentType);
	}

	@action
	async fetchSearchResults(searchText: any) {
		const variables: { query: string; limit: number; typesToInclude: string[] } = {
			query: searchText,
			limit: 20,
			typesToInclude: [],
		};
		let scopedCustomerAccounts: any[] = [];

		if (!this.applicationScope.globalCustomerId) {
			variables.typesToInclude = [...variables.typesToInclude, 'BrokerageAccount', 'Customer', 'SwapAccount'];
		} else {
			const accountVariables = {
				where: {
					customerId: { equals: this.applicationScope.globalCustomerId },
					OR: [
						{
							accountNumber: { contains: searchText },
						},
						{
							name: { contains: searchText },
						},
						{
							id: { contains: searchText },
						},
					],
				},
			};

			scopedCustomerAccounts = [
				...(
					await this.apollo.watchQuery({
						query: accountFuzzyQuery,
						variables: accountVariables,
					})
				).Accounts.map((account: any) => {
					const newObj = {
						id: account.id,
						name: account.name,
						type: account.type === ('Brokerage' || 'Swap') ? account.__typename : account.type,
						accountNumber: account.accountNumber,
					};
					return newObj;
				}),
			];
		}

		const searchApiResults =
			variables.typesToInclude.length > 0 ? (await this.apollo.watchQuery({ query: searchQuery, variables })).Search : [];

		return [...searchApiResults, ...scopedCustomerAccounts].sort((a, b) => {
			if (a.type < b.type) {
				return -1;
			} else if (a.type > b.type) {
				return 1;
			} else {
				return 0;
			}
		});
	}

	@action
	structureSearchResults(searchResults: any) {
		const map = new Map();

		searchResults.forEach((item: any) => {
			if (map.has(item.type)) {
				map.get(item.type).push({
					id: item.id,
					name: item.name,
					type: item.type,
					accountNumber: item.accountNumber ?? null,
				});
			} else {
				map.set(item.type, [
					{
						id: item.id,
						name: item.name,
						type: item.type,
						accountNumber: item.accountNumber ?? null,
					},
				]);
			}
		});

		return map;
	}

	@action
	setSearchFilterQueryParam(searchResult: any) {
		const mappedSearchFilter = this.mapSearchResult(searchResult);
		// @ts-ignore
		this[mappedSearchFilter.filterIdentifier] = mappedSearchFilter.filterValue;
		this.setTablePageState();
	}

	mapSearchResult(searchResult: any) {
		let filterIdentifier;

		switch (searchResult.type) {
			case 'BrokerageAccount':
				filterIdentifier = 'accountId';
				break;
			case 'SwapAccount':
				filterIdentifier = 'accountId';
				break;
		}

		return {
			filterIdentifier,
			filterValue: searchResult.id,
		};
	}

	@action
	clearSearchFilterQueryParam(filterIdentifier: any) {
		// @ts-ignore
		this[`${filterIdentifier}`] = null;
		this.setTablePageState();
	}

	@action
	setExposureMonthQueryParam(value: { startDate: string; endDate: string }) {
		this.exposureMonthStartDate = value.startDate ?? null;
		this.exposureMonthEndDate = value.endDate ?? null;
		this.setTablePageState();
	}

	@action
	setTablePageState() {
		resetVaultTableScroll('exposure-show-table');
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'exposure-show-controller': ExposureShowController;
	}
}
