import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { DateTime } from 'luxon';
import type BusinessesBusinessHarvestPnlRoute from 'vault-client/routes/businesses/business/harvest-pnl';
import { type ModelFrom } from 'vault-client/utils/type-utils';
import { CropModel } from 'vault-client/models/grain/crop';
import { CropGroupModel } from 'vault-client/models/grain/crop-group';
import type { ModelProcessorTransform } from 'vault-client/components/model-processor';
import type { CropGroup } from 'vault-client/utils/grain/crop';
import type { BusinessRevenueAndExpensesData } from 'vault-client/utils/grain/business';
import type { UiDateFilterOption } from 'vault-client/components/vault/ui-date-filter';
import { formatLastUpdatedAt } from 'vault-client/utils/format/last-updated-at';
import { safeSubNull } from 'vault-client/utils/precision-math';

export default class BusinessesBusinessHarvestPnlController extends Controller {
	queryParams = ['harvestYear'];
	declare model: ModelFrom<BusinessesBusinessHarvestPnlRoute>;

	@tracked harvestYear: number = DateTime.now().year;

	harvestYearOptions: UiDateFilterOption[] = Array.from({ length: 4 }, (_, i) => i).map((yearOffset) => {
		const startDateTime = DateTime.now().startOf('year').plus({ year: yearOffset });
		const harvestYear = startDateTime.year;
		return {
			displayName: 'Harvest Year '.concat(harvestYear.toString()),
			startDate: startDateTime.toISODate(),
			endDate: startDateTime.endOf('year').toISODate(),
		};
	});

	get currentHarvestYearOption() {
		const { harvestYear } = this;
		return this.harvestYearOptions.find((option) => DateTime.fromISO(option.startDate).year === harvestYear);
	}

	setHarvestYear = (harvestYearOption: UiDateFilterOption) => {
		this.harvestYear = DateTime.fromISO(harvestYearOption.startDate).year;
	};

	transformModel: ModelProcessorTransform<ModelFrom<BusinessesBusinessHarvestPnlRoute>> = ({ owner, model }) => {
		const { getHarvestPnlComponents, getAggregateCurrentAllocationPositions } = model;

		// Destructure with default values
		const { CropHarvestedAndSoldVolumes = [], CropTransactions = [] } = getHarvestPnlComponents.data ?? {};
		const { AggregateCurrentAllocationPositions = [] } = getAggregateCurrentAllocationPositions.data ?? {};
		const { harvestYear } = model;
		const crops = getHarvestPnlComponents.data?.Crops ?? [];

		// Create CropModel instances for each crop
		const cropModels = crops.map(
			(crop) =>
				new CropModel(owner, {
					crop,
					CropHarvestedAndSoldVolumes,
					CropTransactions,
					harvestYear,
				}),
		);

		// Group crops by crop group
		const cropGroupMap = cropModels.reduce((map, cropModel) => {
			const groupKey = cropModel.cropGroup;
			if (!map.has(groupKey)) {
				map.set(groupKey, []);
			}
			map.get(groupKey)?.push(cropModel);
			return map;
		}, new Map<CropGroup, CropModel[]>());

		// Create CropGroupModel instances
		const cropGroupModels = Array.from(cropGroupMap.entries()).map(
			([cropGroup, crops]) =>
				new CropGroupModel(owner, {
					cropGroup,
					crops,
					aggregateCurrentAllocationPositions: AggregateCurrentAllocationPositions,
				}),
		);

		return {
			cropGroups: cropGroupModels,
			businessData: this.getBusinessData(model),
			lastUpdatedAt: formatLastUpdatedAt(DateTime.now().toISO()),
		};
	};

	getBusinessData(model: ModelFrom<BusinessesBusinessHarvestPnlRoute>): BusinessRevenueAndExpensesData {
		const { AggregateBusinessLedgerEntriesRevenue, AggregateBusinessLedgerEntriesExpenses } = model.getHarvestPnlComponents.data ?? {};
		const businessRevenue = AggregateBusinessLedgerEntriesRevenue?.[0]?.sum?.amount ?? null;
		// Business expenses are returned as negative values. Flip them to match how we want to display them
		const businessExpenses = AggregateBusinessLedgerEntriesExpenses?.[0]?.sum?.amount
			? AggregateBusinessLedgerEntriesExpenses?.[0].sum.amount * -1
			: null;
		const businessNetPnl = safeSubNull(businessRevenue, businessExpenses);
		return {
			businessRevenue,
			businessExpenses,
			businessNetPnl,
			totalRevenue: businessRevenue,
			totalExpenses: businessExpenses,
			totalNetPnl: businessNetPnl,
		};
	}
}
