import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='p-8' ...attributes>\n\t<div>\n\t\t<span class='text-xl font-sans-semibold mr-2 {{this.totalPercentHedgedFontColorClass}}'>\n\t\t\t<IntlNumberFormat @style='percent' @value={{this.totalPercentHedged}} />\n\t\t</span>\n\t\t<span class='text-brand-gray-100'>\n\t\t\tHedged\n\t\t</span>\n\t</div>\n\t<div class='relative shadow border rounded-md {{@containerClasses}}' id='{{this.chartId}}-container'>\n\t\t<BarChart\n\t\t\t@chartData={{this.chartData}}\n\t\t\t@options={{this.chartOptions}}\n\t\t\t@data={{@data}}\n\t\t\t@id={{this.chartId}}\n\t\t\t@plugins={{this.chartPlugins}}\n\t\t\t@updateChart={{this.updateChart}}\n\t\t/>\n\t</div>\n\t<div class='mt-4' id={{this.legendId}}></div>\n</div>", {"contents":"<div class='p-8' ...attributes>\n\t<div>\n\t\t<span class='text-xl font-sans-semibold mr-2 {{this.totalPercentHedgedFontColorClass}}'>\n\t\t\t<IntlNumberFormat @style='percent' @value={{this.totalPercentHedged}} />\n\t\t</span>\n\t\t<span class='text-brand-gray-100'>\n\t\t\tHedged\n\t\t</span>\n\t</div>\n\t<div class='relative shadow border rounded-md {{@containerClasses}}' id='{{this.chartId}}-container'>\n\t\t<BarChart\n\t\t\t@chartData={{this.chartData}}\n\t\t\t@options={{this.chartOptions}}\n\t\t\t@data={{@data}}\n\t\t\t@id={{this.chartId}}\n\t\t\t@plugins={{this.chartPlugins}}\n\t\t\t@updateChart={{this.updateChart}}\n\t\t/>\n\t</div>\n\t<div class='mt-4' id={{this.legendId}}></div>\n</div>","moduleName":"vault-client/components/exposure-hedge-month-detail/percent-hedged-chart.hbs","parseOptions":{"srcName":"vault-client/components/exposure-hedge-month-detail/percent-hedged-chart.hbs"}});
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';
import {
	getChartData,
	getChartOptions,
	getChartPlugins,
	getPercentHedgedData,
	getTotalPercentHedgedFontColorClass,
	type PercentHedgedChartData,
	type PercentHedgedData,
	type PercentHedgedChartOptions,
	type PercentHedgedChartPlugins,
	type PercentHedgedChart,
	type InputData,
} from 'vault-client/utils/components/exposure-hedge-month-detail/percent-hedged-chart';

export interface ExposureHedgeMonthDetailPercentHedgedChartSignature {
	// The arguments accepted by the component
	Args: {
		// Data structured as object with label and percentage as keys and values
		// Example: { 'DRP': 0.5, 'Brokerage': 0.4 }
		data: InputData;
		// The total percent hedged
		totalPercentHedged: number;
		// Used to determine if unhedged should be 100% or 0% if all percentages are 0
		hasExposure: boolean;
		chartId?: string;
		containerClasses?: string;
	};
	// Any blocks yielded by the component
	Blocks: {
		default: [];
	};
	// The element to which `...attributes` is applied in the component template
	Element: HTMLDivElement;
}

export default class ExposureHedgeMonthDetailPercentHedgedChartComponent extends Component<ExposureHedgeMonthDetailPercentHedgedChartSignature> {
	guid = guidFor(this);

	get legendId(): string {
		return `percent-hedged-legend-${this.guid}`;
	}

	get chartId(): string {
		return this.args.chartId || `percent-hedged-chart-${this.guid}`;
	}

	get totalPercentHedged(): number {
		return this.args.totalPercentHedged;
	}

	get totalPercentHedgedFontColorClass(): string {
		return getTotalPercentHedgedFontColorClass(this.totalPercentHedged);
	}

	get percentHedgedData(): PercentHedgedData {
		return getPercentHedgedData({
			data: this.args.data,
			hasExposure: this.args.hasExposure,
			totalPercentHedged: this.totalPercentHedged,
		});
	}

	get chartData(): PercentHedgedChartData {
		return getChartData(this.percentHedgedData);
	}

	get chartOptions(): PercentHedgedChartOptions {
		return getChartOptions(this.totalPercentHedged);
	}

	get chartPlugins(): PercentHedgedChartPlugins {
		return getChartPlugins({ chartId: this.chartId, legendId: this.legendId });
	}

	@action
	updateChart(chart: PercentHedgedChart) {
		chart.data = this.chartData;
		chart.options = this.chartOptions;
	}
}
