import * as v from 'valibot';

/**
 * Validates that a string value is a valid float. Similar to `v.decimal`, but does not require a leading zero.
 * @param message - The message to display if the value is not a valid currency.
 * @returns A valibot validator that validates a currency value.
 */
export function float(message?: string) {
	return v.pipe(
		v.string(),
		v.trim(),
		v.transform((input) => {
			// Handle negative numbers without leading zero
			if (input.startsWith('-.')) {
				return `-0${input.slice(1)}`;
			}

			// Handle positive numbers without leading zero
			if (input.startsWith('.')) {
				return `0${input}`;
			}

			return input;
		}),
		v.decimal(message),
	);
}
