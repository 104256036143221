const MIN_END_WEIGHT_IN_LB = 0;
const MAX_END_WEIGHT_IN_LB = 10_000;
const DEFAULT_END_WEIGHT_IN_LB = MAX_END_WEIGHT_IN_LB;

const MIN_START_WEIGHT_IN_LB = 0;
const MAX_START_WEIGHT_IN_LB = 10_000;
const DEFAULT_START_WEIGHT_IN_LB = MIN_START_WEIGHT_IN_LB;

export {
	MIN_START_WEIGHT_IN_LB,
	MAX_START_WEIGHT_IN_LB,
	DEFAULT_START_WEIGHT_IN_LB,
	MIN_END_WEIGHT_IN_LB,
	MAX_END_WEIGHT_IN_LB,
	DEFAULT_END_WEIGHT_IN_LB,
};
